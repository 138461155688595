/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    id: 'Checkout.Fulfillment.Cancel',
    defaultMessage: 'Cancel'
  },
  submit: {
    id: 'Checkout.Fulfillment.submit',
    defaultMessage: 'Next'
  },
  orderInstructionsLabel: {
    id: 'Checkout.Fulfillment.orderInstructions',
    defaultMessage: 'Order Instructions'
  },
  orderInstructionsHintQuestion: {
    id: 'Checkout.Fulfillment.orderInstructions.hint.question',
    defaultMessage: 'Can’t find a part?',
    description:
      'This will combined with "Checkout.Fulfillment.orderInstructions.hint" as the preamble'
  },
  orderInstructionsRequirementsHint: {
    id: 'Checkout.Fulfillment.orderInstructions.hint.requirements',
    defaultMessage: 'Must be under 1,000 characters'
  },
  orderInstructionsHint: {
    id: 'Checkout.Fulfillment.orderInstructions.hint',
    defaultMessage:
      '{preamble} Inquire about additional parts and specify pickup/delivery needs in the instruction box above.'
  },
  orderInstructionsAdd: {
    id: 'Checkout.Fulfillment.orderInstructions.add',
    defaultMessage: 'Add Order Instructions'
  },
  orderInstructionsLengthInvalid: {
    id: 'Checkout.Fulfillment.orderInstructions.errors.length',
    defaultMessage: 'Must be under 1000 characters'
  },
  purchaseOrderNumberLengthInvalid: {
    id: 'Checkout.Fulfillment.purchaseOrderNumber.errors.length',
    defaultMessage: 'Must be under 20 characters'
  },
  fulfillmentMethodRequired: {
    id: 'Checkout.Fulfillment.Methods.required',
    defaultMessage: 'Fulfillment method is required'
  },
  generalError: {
    id: 'Checkout.Fulfillment.generalError',
    defaultMessage:
      'There was an error processing your request. Please check your info and try again.'
  },
  fetchDeliveryOptionsFailed: {
    id: 'Checkout.Fulfillment.fetchDeliveryOptionsFailed',
    defaultMessage:
      'There was an error processing your request. Please try again.'
  },
  chooseDelivery: {
    id: 'Checkout.Fulfillment.chooseDelivery',
    defaultMessage: 'Choose a Delivery Address'
  },
  useDifferentAddress: {
    id: 'Checkout.Fulfillment.useDifferentAddress',
    defaultMessage: 'Use a Different Address'
  },
  enterContactAddress: {
    id: 'Checkout.Fulfillment.enterContact',
    defaultMessage: 'Enter Contact Address'
  },
  caveat: {
    id: 'Checkout.Fulfillment.caveat',
    defaultMessage:
      '{dealerName} will use the order info to help differentiate customers and contact you to determine payment and pickup/delivery details.'
  },
  deliveryUnavailable: {
    id: 'Checkout.Fulfillment.deliveryUnavailable',
    defaultMessage: 'Delivery not available:\n'
  },
  pickupSelect: {
    id: 'Checkout.Fulfillment.pickup',
    defaultMessage: 'Pickup'
  },
  deliverySelect: {
    id: 'Checkout.Fulfillment.deliverySelect',
    defaultMessage: 'Delivery'
  },
  dealerAllowsDelivery: {
    id: 'Checkout.Fulfillment.Deny.dealerAllowsDelivery',
    defaultMessage:
      'Delivery is not available at this location. Please call the location at {phoneNumber} for alternative options.'
  },
  meetsOrderMinimumAmount: {
    id: 'Checkout.Fulfillment.Deny.meetsOrderMinimumAmount',
    defaultMessage:
      'Order does not meet the minimum order amount. Your total needs to be above {orderMinimum} {orderMinimumUnit}'
  },
  meetsOrderMinimumAmountMessage: {
    id: 'Checkout.Fulfillment.Deny.meetsOrderMinimumAmountMessage',
    defaultMessage:
      'Order does not meet the minimum order amount. Your total needs to be above '
  },
  itemsEligibleForDelivery: {
    id: 'Checkout.Fulfillment.Deny.itemsEligibleForDelivery',
    defaultMessage: 'One or more items are not eligible for delivery'
  },
  inDeliveryRadius: {
    id: 'Checkout.Fulfillment.Deny.inDeliveryRadius',
    defaultMessage: 'Provided address is outside of delivery radius'
  },
  deliveryRadiusFailed: {
    id: 'Checkout.Fulfillment.Deny.deliveryRadiusFailed',
    defaultMessage: 'Unable to determine distance from delivery radius'
  },
  modalTitle: {
    id: 'Checkout.Fulfillment.modalTitle',
    defaultMessage: 'Provided address is not available for delivery'
  },
  deliveryValidationInfoModalTitle: {
    id: 'Checkout.Fulfillment.deliveryValidationInfoModalTitle',
    defaultMessage: 'Delivery Option is Unavailable'
  },
  modifyPickup: {
    id: 'Checkout.Fulfillment.modifyPickup',
    defaultMessage: 'Set to pickup'
  },
  deliveryLegend: {
    id: 'Checkout.Fulfillment.deliveryLegend',
    defaultMessage: 'Delivery Method'
  },
  deliveryRadiusUnavailable: {
    id: 'Checkout.deliveryRadiusUnavailable',
    defaultMessage:
      'Your provided address is unavailable for delivery{breakLine}' +
      'You can:{breakLineWide}' +
      '1) Pickup your order and continue checking out{breakLine}' +
      '2) Go back and change your address{breakLine}' +
      '{breakLine}'
  },
  estimatedDaysSingle: {
    id: 'Checkout.Fulfillment.estimatedDays.single',
    defaultMessage: '{days} day(s)'
  },
  estimatedDaysRange: {
    id: 'Checkout.Fulfillment.estimatedDays.range',
    defaultMessage: '{minDays}-{maxDays} day(s)'
  },
  freeDelivery: {
    id: 'Checkout.Fulfillment.freeDelivery',
    defaultMessage: 'Free'
  },
  purchaseOrderNumberLabel: {
    id: 'Checkout.Fulfillment.purchaseOrderNumber',
    defaultMessage: 'PO Number'
  },
  purchaseOrderNumberAdd: {
    id: 'Checkout.Fulfillment.purchaseOrderNumber.Add',
    defaultMessage: 'Add PO Number'
  },
  purchaseOrderNumberHint: {
    id: 'Checkout.Fulfillment.purchaseOrderNumber.Hint',
    defaultMessage: 'PO numbers cannot be longer than 20 characters'
  },
  fulfillmentArrival: {
    id: 'Checkout.Fulfillment.Methods.arrival',
    defaultMessage: 'Arrives by {date}'
  },
  selectDeliveryMethod: {
    id: 'Checkout.Fulfillment.Methods.selectMethod',
    defaultMessage: 'Select a Delivery Method'
  },
  estimatedDateNotAvailable: {
    id: 'Checkout.Fulfillment.Methods.estimated',
    defaultMessage: 'Delivery Estimate Not Available'
  },
  fulfillmentType: {
    id: 'Address.fulfillment.type',
    defaultMessage: 'Use Delivery or Pickup?'
  },
  deliverLabel: {
    id: 'Address.fulfillment.type.deliver',
    defaultMessage: 'Delivery'
  },
  pickupLabel: {
    id: 'Address.fulfillment.type.pickup',
    defaultMessage: 'Pickup'
  },
  deliveryAddressLabel: {
    id: 'Checkout.Fulfillment.delivery.address.label.readOnly',
    defaultMessage: 'Contact at'
  },
  deliveryMethodLabel: {
    id: 'Checkout.Fulfillment.delivery.method.label.readOnly',
    defaultMessage: 'Deliver using'
  },
  deliveryMethodName: {
    id: 'Checkout.Fulfillment.delivery.method.name.readOnly',
    defaultMessage: '{name} Delivery'
  },
  deliveryMethodEta: {
    id: 'Checkout.Fulfillment.delivery.method.eta.readOnly',
    defaultMessage: 'Arriving by {date}'
  },
  pickupContactLabel: {
    id: 'Checkout.Fulfillment.pickup.contact.label.readOnly',
    defaultMessage: 'Pickup contact'
  },
  pickup: {
    id: 'Checkout.Fulfillment.pickup.location.label.readOnly',
    defaultMessage: 'Pickup from'
  },
  edit: {
    id: 'Checkout.Fulfillment.edit',
    defaultMessage: 'Edit'
  },
  primaryPhone: {
    id: 'Checkout.Fulfillment.delivery.method.primaryPhone.label.readOnly',
    defaultMessage: 'Primary ({kind}): {phone}'
  },
  secondaryPhone: {
    id: 'Checkout.Fulfillment.delivery.method.secondaryPhone.label.readOnly',
    defaultMessage: 'Secondary ({kind}): {phone}'
  },
  fax: {
    id: 'Checkout.Fulfillment.delivery.method.fax.label.readOnly',
    defaultMessage: 'Fax: {phone}'
  },
  orderInstructions: {
    id: 'Checkout.Fulfillment.delivery.method.orderInstructions',
    defaultMessage: 'Order Instructions'
  },
  communicationPreference: {
    id: 'Checkout.Fulfillment.delivery.method.communicationPreference',
    defaultMessage: 'Prefer to contact by'
  },
  deliveryMethod: {
    id: 'Checkout.Fulfillment.deliveryMethod',
    defaultMessage: 'Delivery Method'
  },
  deliveryMethodDeliver: {
    id: 'Checkout.Fulfillment.deliveryMethod.deliver',
    defaultMessage: 'Deliver'
  },
  deliveryMethodPickup: {
    id: 'Checkout.Fulfillment.deliveryMethod.pickup',
    defaultMessage: 'Pickup'
  },
  purchaseOrderNumber: {
    id: 'Checkout.Fulfillment.purchaseOrderNumber',
    defaultMessage: 'PO Number'
  },
  paymentMethod: {
    id: 'Checkout.Fulfillment.paymentMethod',
    defaultMessage: 'Payment Method'
  },
  title: {
    id: 'Checkout.Fulfillment.title',
    defaultMessage: 'Order Info'
  },
  deliveryTitle: {
    id: 'Checkout.Fulfillment.delivery.title',
    defaultMessage: 'Delivery'
  },
  fulfillmentCriteriaNotMet: {
    id: 'Checkout.Fulfillment.criteriaNotMet',
    defaultMessage: 'This option is not for the following reasons:'
  },
  close: {
    id: 'Checkout.Fulfillment.close',
    defaultMessage: 'Close'
  },
  exceedsWeightLimit: {
    id: 'Checkout.Fulfillment.exceedsWeightLimit',
    defaultMessage: 'Item exceeds weight limit of {weightLimit}'
  },
  exceedsDimensionLimit: {
    id: 'Checkout.Fulfillment.exceedsDimensionLimit',
    defaultMessage: 'Item exceeds dimension limit of {dimensionLimit}'
  },
  IN_STORE: {
    id: 'Checkout.Fulfillment.pickupFulfillment',
    defaultMessage: 'Pickup'
  },
  STANDARD: {
    id: 'Checkout.Fulfillment.standardFulfillment',
    defaultMessage: 'Standard Delivery'
  },
  SAME_DAY_DELIVERY: {
    id: 'Checkout.Fulfillment.sameDayDelivery',
    defaultMessage: 'Delivery'
  },
  NEXT_DAY: {
    id: 'Checkout.Fulfillment.nextDayDelivery',
    defaultMessage: 'Next Day Delivery'
  },
  CURBSIDE: {
    id: 'Checkout.Fulfillment.curbsidePickup',
    defaultMessage: 'Curbside Pickup'
  },
  EXPRESS: {
    id: 'Checkout.Fulfillment.expressDelivery',
    defaultMessage: 'Express Delivery'
  },
  EXPEDITED: {
    id: 'Checkout.Fulfillment.expeditedDelivery',
    defaultMessage: 'Expedited Delivery'
  },
  FREIGHT: {
    id: 'Checkout.Fulfillment.freightDelivery',
    defaultMessage: 'Freight'
  },
  unavailableAriaLabel: {
    id: 'Checkout.Fulfillment.unavailableAriaLabel',
    defaultMessage:
      'Delivery option is not available. Click for more information.'
  },
  fulfillmentFeesTitle: {
    id: 'Checkout.Fulfillment.fulfillmentFeesTitle',
    defaultMessage: 'Fulfillment Fees'
  },
  fulfillmentFees: {
    id: 'Checkout.Fulfillment.fulfillmentFees',
    defaultMessage: 'Fulfillment Price Breakdown'
  },
  fulfillmentFeesBase: {
    id: 'Checkout.Fulfillment.fulfillmentFeesBase',
    defaultMessage: 'Base Fulfillment Price'
  },
  fulfillmentFeesLabel: {
    id: 'Checkout.Fulfillment.fulfillmentFeesLabel',
    defaultMessage: 'Fees: '
  },
  fulfillmentFeesFulTotal: {
    id: 'Checkout.Fulfillment.fulfillmentFeesFulTotal',
    defaultMessage: 'Fulfillment Total'
  },
  paymentFailureModalTitle: {
    id: 'Checkout.Fulfillment.paymentFailureModalTitle',
    defaultMessage: 'Payment Processing Failure'
  },
  paymentFailureModalHeader: {
    id: 'Checkout.Fulfillment.paymentFailureModalHeader',
    defaultMessage: 'Payment Failure'
  },
  paymentFailureModalContent: {
    id: 'Checkout.Fulfillment.paymentFailureModalContent',
    defaultMessage:
      'Your payment was not successful. Please verify your payment details and try again or use a different payment method.'
  }
});
