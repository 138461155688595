/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { isEmpty, concat, size, find } from 'lodash';

const SEARCH_OBJECT = { label: 'Search Results', uri: '/search' };

/**
 * Retrieves the best possible breadcrumbs depending on the situation.
 * A search query will have three location state crumbs; root, search query, and product. In this case we keep the search query crumb and populate the primary categories.
 * A direct link will have two state crumbs; root and product. In this case we utilize the primary categories.
 * Otherwise we want default to the previous breadcrumb behavior.
 *
 * @param breadcrumbsFromLocationState location state breadcrumbs derived from the path the user used
 * @param breadcrumbs breadcrumbs derived from the application
 * @param hasPrimaryCategory if the current page has a product that has a primary category to get breadcrumbs
 * @returns {Array|*}
 */
function getBestBreadcrumbs(
  breadcrumbsFromLocationState,
  breadcrumbs,
  hasPrimaryCategory
) {
  if (
    canUseSearchPrimaryBreadcrumb(
      breadcrumbsFromLocationState,
      hasPrimaryCategory
    )
  ) {
    breadcrumbsFromLocationState.pop();
    return concat(breadcrumbsFromLocationState, breadcrumbs);
  } else if (
    canUseDirectPrimaryBreadcrumb(
      breadcrumbsFromLocationState,
      hasPrimaryCategory
    )
  ) {
    return breadcrumbs;
  } else if (!isEmpty(breadcrumbsFromLocationState)) {
    return breadcrumbsFromLocationState;
  } else {
    return breadcrumbs;
  }
}

function canUseSearchPrimaryBreadcrumb(
  breadcrumbsFromLocationState,
  hasPrimaryCategory
) {
  return (
    !isEmpty(breadcrumbsFromLocationState) &&
    hasPrimaryCategory &&
    size(breadcrumbsFromLocationState) === 3 &&
    find(breadcrumbsFromLocationState, SEARCH_OBJECT)
  );
}

function canUseDirectPrimaryBreadcrumb(
  breadcrumbsFromLocationState,
  hasPrimaryCategory
) {
  return (
    !isEmpty(breadcrumbsFromLocationState) &&
    hasPrimaryCategory &&
    size(breadcrumbsFromLocationState) <= 2
  );
}

export { getBestBreadcrumbs };
