/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
/**
 * Out of the box forms that a `ProductOption` can take.
 * @type {{
 *   ITEM_CHOICE: string,
 *   CART_ITEM_ATTRIBUTE: string,
 *   VARIANT_DISTINGUISHING: string,
 *   CART_ATTRIBUTE: string
 * }}
 */
const ProductOptionType = Object.freeze({
  /**
   * The user input flow into a global cart-level attribute when added to cart.
   * If there was already an entry for this attribute, it is overridden with the
   * latest value.
   */
  CART_ATTRIBUTE: 'CART_ATTRIBUTE',
  /**
   * The user value is intended to flow into an item attribute once this item is
   * added to the cart.
   */
  CART_ITEM_ATTRIBUTE: 'CART_ITEM_ATTRIBUTE',
  /**
   * Allows the user to select an additional item that is added to their cart
   */
  ITEM_CHOICE: 'ITEM_CHOICE',
  /**
   * Values selected by the customer will help distinguish a specific variant
   * directly on this Product that should be added to the cart. Example would be
   * to set up t-shirts with colors and sizes. This is similar to
   * `ITEM_ATTRIBUTE` in that the values the user selected are stored in the
   * item attributes.
   */
  VARIANT_DISTINGUISHING: 'VARIANT_DISTINGUISHING'
});

export default ProductOptionType;
