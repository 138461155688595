/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import { Icon } from 'app/common/components';

import { CheckboxInput } from './components';

const CheckboxField = ({
  className = 'mb-4',
  checked = false,
  children,
  disabled = false,
  field,
  required = false,
  value,
  checkboxClassName = '',
  checkboxSize = '1x',
  ...props
}) => (
  <label
    className={classNames('group relative flex items-center', {
      [className]: !!className,
      'hover:cursor-not-allowed': !!disabled,
      'hover:cursor-pointer': !disabled
    })}
  >
    <CheckboxInput
      field={field}
      value={value}
      checked={checked}
      disabled={disabled}
      required={required}
      {...props}
    />
    <Icon
      className={classNames('mr-3 bg-white', checkboxClassName, {
        'text-gray-600 group-hover:text-gray-700': !checked && !disabled,
        'text-gray-400 ': !checked && disabled,
        'text-primary-600': checked && !disabled,
        'text-primary-200': checked && disabled
      })}
      size={checkboxSize}
      name={checked ? ['far', 'check-square'] : ['far', 'square']}
    />
    <div
      className={classNames({
        'text-gray-600 group-hover:text-gray-700': !checked && !disabled,
        'text-gray-400': !checked && disabled,
        'text-gray-900': checked && !disabled,
        'text-gray-600': checked && disabled
      })}
    >
      {children}
    </div>
  </label>
);

/**
 * Helper component that wraps a `<CheckboxField>` in a formik `<Field>`.
 */
const ConnectedCheckboxField = ({ name, value, ...props }) => (
  <Field name={name}>
    {({ field }) => {
      const checked = !!field.value;
      return (
        <CheckboxField
          {...props}
          field={field}
          name={name}
          value={value}
          checked={checked}
        />
      );
    }}
  </Field>
);

ConnectedCheckboxField.Field = CheckboxField;

ConnectedCheckboxField.propTypes = {
  disabled: PropTypes.bool,
  /** The value for this radio. Other radios will have this same name. */
  name: PropTypes.string.isRequired,
  /** The value for this radio. This does not mean that this radio is checked */
  value: PropTypes.string.isRequired
};

export default ConnectedCheckboxField;
export { CheckboxField, CheckboxInput };
