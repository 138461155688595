/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { get, has } from 'lodash';

/**
 * We do this as an optimization for those using CRA. This ensures that when
 * `process.env` gets replaced with the environment properties they get assigned
 * to our variable `PROCESS_ENV`. This prevents CRA from having to replace multiple
 * instances of `process.env` and saves both space and bundle time.
 */
const PROCESS_ENV = import.meta?.env ? import.meta.env : {};

/**
 * Build-time property resolver that looks at `process.env` to find environment properties.
 *
 * @param  {String} propertyName the property name
 * @return {String}              the property value (or undefined)
 */
function buildTimeResolver(propertyName) {
  if (has(PROCESS_ENV, propertyName)) {
    return get(PROCESS_ENV, propertyName);
  }

  // handle the case where we are evaluating this in CRA, which requires prefixing VITE_APP_
  if (has(PROCESS_ENV, `VITE_APP_${propertyName}`)) {
    return get(PROCESS_ENV, `VITE_APP_${propertyName}`);
  }

  return undefined;
}

// generally, this resolver should be the last to run so we give it a large order number
buildTimeResolver.ORDER = 10000;

export default buildTimeResolver;
