/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { get, isEmpty, isEqual } from 'lodash';
import { Currency } from '../index';
import React from 'react';
import { useFormatMessage } from '../../hooks';

const CoreCost = ({ className, message, coreCost }) => {
  const formatMessage = useFormatMessage();

  return (
    !isEmpty(coreCost) &&
    !isEqual(get(coreCost, 'amount'), 0) && (
      <div className={className}>
        {!isEmpty(message) && formatMessage(message)}
        <Currency className="ml-4" {...coreCost} />
      </div>
    )
  );
};

export default CoreCost;
export { CoreCost };
