/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { Environment } from 'app/common/services';

/**
 * Name of the pricing fields on the entities used as the bases for the
 * `PriceableTargets` of `PriceInfo` requests, e.g., `Product#cost` is the
 * source of `PriceableTarget#baseCost`.
 *
 * @type {{
 *   product: {baseCost: string, basePrice: string},
 *   variant: {baseCost: string, basePrice: string}
 * }}
 */
const PriceInfoRequestPriceableFieldSourceName = Object.freeze({
  product: {
    baseCost: Environment.get('PRODUCT_BASE_COST_FIELD_NAME', 'cost'),
    basePrice: Environment.get('PRODUCT_BASE_PRICE_FIELD_NAME', 'defaultPrice')
  },
  variant: {
    baseCost: Environment.get('VARIANT_BASE_COST_FIELD_NAME', 'cost'),
    basePrice: Environment.get('VARIANT_BASE_PRICE_FIELD_NAME', 'defaultPrice')
  }
});

export default PriceInfoRequestPriceableFieldSourceName;
