/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

import { PrimaryButton } from 'app/common/components';
import {
  useCreatePaymentAccount,
  useFormatMessage,
  useUpdatePaymentAccount
} from 'app/common/hooks';

import messages from '../PaymentCard.messages';

const SavePaymentButton = props => {
  const { id, paymentAccount, refetchPayments, clearForm, isDefault } = props;
  const { sendCallback: sendEditCallback } = useUpdatePaymentAccount(
    id,
    paymentAccount,
    isDefault
  );
  const { sendCallback: sendAddCallback } =
    useCreatePaymentAccount(paymentAccount);
  const formatMessage = useFormatMessage();
  return !!id ? (
    <PrimaryButton
      onClick={() => {
        sendEditCallback().then(data => {
          clearForm();
          refetchPayments();
        });
      }}
    >
      {formatMessage(messages.updatePayment)}
    </PrimaryButton>
  ) : (
    <PrimaryButton
      onClick={() => {
        sendAddCallback().then(data => {
          clearForm();
          refetchPayments();
        });
      }}
    >
      {formatMessage(messages.addPayment)}
    </PrimaryButton>
  );
};

SavePaymentButton.propTypes = {
  /** The payment account's id **/
  id: PropTypes.string,
  /** The saved payment **/
  paymentAccount: PropTypes.object,
  /** Function to refetch saved payments **/
  refetchPayments: PropTypes.func
};

export default SavePaymentButton;
export { SavePaymentButton };
