/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

/**
 * Defines the types of prices that can be retrieved from the PricingService.
 *
 * @type {{
 *   SALE_PRICE: string,
 *   BASE_PRICE: string,
 *   STANDARD_PRICE: string,
 *   CONTRACT_PRICE: string
 * }}
 */
const PriceType = Object.freeze({
  BASE_PRICE: 'basePrice',
  CONTRACT_PRICE: 'contractPrice',
  SALE_PRICE: 'salePrice',
  STANDARD_PRICE: 'standardPrice'
});

export default PriceType;
export { PriceType };
