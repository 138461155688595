/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import PreviewHeader from './components/PreviewHeader';

/**
 * The component responsible for rendering the preview layout and its header.
 */
const PreviewLayout = ({ children }) => {
  return (
    <div className="absolute flex flex-col flex-no-wrap w-full h-full">
      <PreviewHeader />
      <div className="flex-1 overflow-auto">{children}</div>
    </div>
  );
};

export default PreviewLayout;
