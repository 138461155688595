const PENDING = 'PENDING';
const IN_PROGRESS = 'IN_PROGRESS';
const COMPLETE = 'COMPLETE';

function isCompleteStatus(status) {
  return status === COMPLETE;
}

function isNotCompleteStatus(status) {
  return !isCompleteStatus(status);
}

export {
  PENDING,
  IN_PROGRESS,
  COMPLETE,
  isCompleteStatus,
  isNotCompleteStatus
};
