/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  submitButton: {
    id: 'CSREditPrice.button.submit.label',
    defaultMessage: 'Submit'
  },
  originalPrice: {
    id: 'CSREditPrice.originalPrice',
    defaultMessage: 'Original Price'
  },
  overridePrice: {
    id: 'CSREditPrice.overridePrice',
    defaultMessage: 'Override Price'
  },
  overridePricePlaceholder: {
    id: 'CSREditPrice.overridePrice.placeholder',
    defaultMessage: '0.00'
  },
  overridePriceNaN: {
    id: 'CSREditPrice.overridePrice.nan',
    defaultMessage: 'Must be a number'
  },
  overridePriceNegative: {
    id: 'CSREditPrice.overridePrice.negative',
    defaultMessage: 'Must be non-negative'
  },
  overridePriceTitle: {
    id: 'CSREditPrice.modal.title',
    defaultMessage: 'Override Price'
  },
  editPriceButton: {
    id: 'CSREditPrice.button.editPrice.label',
    defaultMessage: 'Edit Price'
  },
  overridePriceRequired: {
    id: 'CSREditPrice.overridePriceRequired',
    defaultMessage: 'This field is required'
  },
  overridePriceInvalid: {
    id: 'CSREditPrice.overridePriceInvalid',
    defaultMessage: 'Must be a valid price'
  },
  reason: {
    id: 'CSREditPrice.reason',
    defaultMessage: 'Reason'
  },
  comment: {
    id: 'CSREditPrice.comment',
    defaultMessage: 'Comment'
  },
  reasonsPriceMatchLabel: {
    id: 'CSREditPrice.reasons.priceMatch.label',
    defaultMessage: 'Price Match'
  },
  reasonsOtherLabel: {
    id: 'CSREditPrice.reasons.other.label',
    defaultMessage: 'Other'
  }
});
