/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { get, isEmpty, isNumber } from 'lodash';
import { useApiIsLoaded } from '@vis.gl/react-google-maps';

import {
  DealerHours,
  DealerInfo,
  DealerMaps,
  DisplayBox
} from 'app/about/components/About/components';

const AboutDisplayGroup = ({ application }) => {
  const businessHours = get(application, 'businessHours');
  const longitude = !isNumber(application?.longitude)
    ? 0
    : application.longitude;
  const latitude = !isNumber(application?.latitude) ? 0 : application.latitude;
  const [isMapFailed, setIsMapFailed] = useState(false);
  const isApiLoaded = useApiIsLoaded();

  useEffect(() => {
    window.gm_authFailure = () => {
      setIsMapFailed(true);
    };
  }, [setIsMapFailed]);

  return (
    <div className="flex grow flex-col lg:flex-row justify-center">
      <DisplayBox className="lg:mr-3">
        <DealerInfo application={application}></DealerInfo>
      </DisplayBox>
      <DisplayBox
        className={classNames('lg:ml-3 lg:mr-3', {
          hidden: isEmpty(businessHours),
          flex: !isEmpty(businessHours)
        })}
      >
        <DealerHours businessHours={businessHours} />
      </DisplayBox>
      <DisplayBox
        className={classNames('hidden lg:ml-3', {
          'hidden lg:flex': !isMapFailed && isApiLoaded
        })}
      >
        <DealerMaps
          application={application}
          longitude={longitude}
          latitude={latitude}
        />
      </DisplayBox>
    </div>
  );
};

export default AboutDisplayGroup;
export { AboutDisplayGroup };
