/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useCallback, useContext, useMemo } from 'react';
import { has } from 'lodash';

import { CartContext } from 'app/common/contexts';
import { useRestApi } from 'app/common/hooks';
import { Environment } from 'app/common/services';

/**
 * Hooks to use when sending an API request that modifies the cart. This is
 * essentially a wrapper around {@link useRestApi} but adds some error handling
 * for stale cart errors. It will update the {@link CartContext} and set the
 * global {@code cartOperationException} so that any request errors can be
 * displayed just by accessing the {@link CartContext}.
 *
 * @param {string} url - url of the menu fetch endpoint
 * @param {object} config - Object containing additional request config such as
 *     the request params or request method
 * @param {boolean} [sendImmediate=true] - Whether to send the request
 *     immediately upon using the hook. If false, then the caller is responsible
 *     for triggering the `sendCallback`. Defaults to `true`.
 * @param {boolean} [rejectOnError=false] - Whether to reject the "send"
 *     promise on error response. Either way, the error will be part of a
 *     separate dispatch event.
 *
 * @return {{exception: Object, sendCallback: restApiCallback, error: boolean, loading: boolean}}
 */
function useModifyCartRequest(
  url,
  config,
  sendImmediate = true,
  rejectOnError = false
) {
  const {
    error,
    exception,
    loading,
    sendCallback: sendRequest
  } = useRestApi(url, config, sendImmediate, true);
  const cartVersionHeaderName = Environment.get(
    'cart.version.header',
    'X-Cart-Version'
  );
  const { setCart, setCartOperationException } = useContext(CartContext);
  const sendCallback = useCallback(
    async (conf, urlOverride) => {
      try {
        return await sendRequest(conf, urlOverride);
      } catch (err) {
        if (err.type === 'STALE_CART_ERROR') {
          setCart(err.newCart);
          console.log('Refreshing stale cart with id: ', err.newCart.id);
          if (config?.headers?.[cartVersionHeaderName]) {
            conf.headers[cartVersionHeaderName] = err.newCart.version;
          }
          return await sendRequest(conf, urlOverride);
        }

        setCartOperationException(
          has(err, 'type') ? { [err.type]: err } : { UNKNOWN: err }
        );

        if (rejectOnError) {
          return Promise.reject(err);
        }

        return [];
      }
    },
    // eslint-disable-next-line
    [rejectOnError, setCart, setCartOperationException, sendRequest]
  );

  return useMemo(
    () => ({ error, exception, loading, sendCallback }),
    [error, exception, loading, sendCallback]
  );
}

export default useModifyCartRequest;
