/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useCartInfo, useModifyCartRequest } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';
import { CSREditPrice } from 'app/csr/components';
import { CSRContext } from 'app/csr/context';

const overridePriceConfig = {
  method: 'patch',
  scope: 'CSR',
  params: {
    price: true
  }
};

const CSREditItemPrice = props => {
  const {
    cartItem: { id, unitPriceWithDependentItems }
  } = props;
  const { csrMode } = React.useContext(CSRContext);
  const { baseUrl, itemsContextPath, overridePriceContextPath } =
    useCartInfo().operations;
  const overridePriceUrl = `${baseUrl}${itemsContextPath}/${id}${overridePriceContextPath}`;
  const {
    error,
    exception,
    sendCallback: overrideCartItemPrice
  } = useModifyCartRequest(overridePriceUrl, overridePriceConfig, false, true);

  if (error) {
    logError({
      ...exception,
      when: `editing item ${id} price`
    });
  }

  if (!csrMode) {
    return null;
  }

  return (
    <CSREditPrice
      className={cx('CSREditItemPrice', props.className)}
      originalPrice={unitPriceWithDependentItems}
      handleSubmit={({ overridePrice, reason, comment }) =>
        overrideCartItemPrice({
          data: { overridePrice, reason, comment }
        })
      }
    />
  );
};

CSREditItemPrice.propTypes = {
  className: PropTypes.string,
  cartItem: PropTypes.object.isRequired
};

export default CSREditItemPrice;
