/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

/**
 * @typedef {Object} PreviewToken
 * @property {string} token - the token string
 * @property {string} author - the author whose data is to be previewed
 * @property {string} expirationTime - the ISO8601 expiration date-time
 * @property {Array.<string>} sandboxIds - the set of sandbox IDs
 * @property {string} tenantId - the tenant ID
 * @property {string} applicationId - the application ID
 */

export const ActionTypes = Object.freeze({
  SET_INITIAL_BY_PARAMS: 'Preview.SET_INITIAL_BY_PARAMS',
  SET_INITIAL_BY_CACHE: 'Preview.SET_INITIAL_BY_CACHE',
  SET_VALIDATING: 'Preview.SET_VALIDATING',
  SET_VALIDATED: 'Preview.SET_VALIDATED',
  SET_INVALIDATED: 'Preview.SET_INVALIDATED',
  SET_ERROR: 'Preview.SET_ERROR',
  SET_SANDBOX_ID: 'Preview.SET_SANDBOX_ID',
  SET_PREVIEW_DATE: 'Preview.SET_PREVIEW_DATE',
  CLEAR: 'Preview.CLEAR'
});

/**
 * Used to initialize the reducer state using the preview token parameter values.
 *
 * @param {string | null} [token] - the token string
 * @param {string | null} [sandboxId] - the sandbox ID
 * @return {{ payload: { [token]: string, [sandboxId]: string }, type: 'Preview.SET_INITIAL_BY_PARAMS' }}
 */
export function setInitialByParams(token, sandboxId) {
  return {
    type: ActionTypes.SET_INITIAL_BY_PARAMS,
    payload: { token, sandboxId }
  };
}

/**
 * Used to initialize the reducer state using the cached preview state.
 *
 * @param {PreviewState} cachedState the cached state
 * @returns {{ payload: PreviewState, type: 'Preview.SET_INITIAL_BY_CACHE' }}
 */
export function setInitialByCache(cachedState) {
  return {
    type: ActionTypes.SET_INITIAL_BY_CACHE,
    payload: cachedState
  };
}

/**
 * Action used to indicate whether token is being validated.
 *
 * @param {boolean} validating whether or not validating
 * @returns {{ payload: boolean, type: 'Preview.SET_VALIDATING' }}
 */
export function setValidating(validating) {
  return {
    type: ActionTypes.SET_VALIDATING,
    payload: validating
  };
}

/**
 * Action used to consume a validated token response.
 *
 * @param {{ valid: boolean, content: PreviewToken, sandboxes: Array.<Sandbox> }} validated the validated token response
 * @returns {{ payload: { valid: boolean, content: PreviewToken, sandboxes: Array.<Sandbox> }, type: 'Preview.SET_VALIDATED' }}
 */
export function setValidated(validated) {
  return {
    type: ActionTypes.SET_VALIDATED,
    payload: validated
  };
}

/**
 * Action used to consume an invalidated token response.
 *
 * @param {{ valid: boolean, reason: string }} invalidated the invalidated token response
 * @returns {{ payload: { valid: boolean, [reason]: string }, type: 'Preview.SET_INVALIDATED' }}
 */
export function setInvalidated(invalidated) {
  return {
    type: ActionTypes.SET_INVALIDATED,
    payload: invalidated
  };
}

/**
 * Action used to consume an error when making the validate request.
 *
 * @param {Error} error the error
 * @returns {{ payload: Error, type: 'Preview.SET_ERROR' }}
 */
export function setError(error) {
  return {
    type: ActionTypes.SET_ERROR,
    payload: error
  };
}

/**
 * Action used to set the current sandbox.
 *
 * @param {string | null} sandboxId the next sandbox ID
 * @returns {{ payload: string, type: 'Preview.SET_SANDBOX_ID' }}
 */
export function setSandboxId(sandboxId) {
  return {
    type: ActionTypes.SET_SANDBOX_ID,
    payload: sandboxId
  };
}

/**
 * Action used to set the future date.
 *
 * @param {string | null} previewDate the future date string in ISO8601 format
 * @returns {{ payload: string, type: 'Preview.SET_PREVIEW_DATE' }}
 */
export function setPreviewDate(previewDate) {
  return {
    type: ActionTypes.SET_PREVIEW_DATE,
    payload: previewDate
  };
}

/**
 * Action used to clear the state back to the default and exit previewing.
 *
 * @returns {{ payload: PreviewState | undefined, type: 'Preview.CLEAR' }}
 */
export function clear(nextInitialState) {
  return {
    type: ActionTypes.CLEAR,
    payload: nextInitialState
  };
}
