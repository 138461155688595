/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { addParams } from 'app/common/utils/PathUtils';
import React from 'react';
import * as PropTypes from 'prop-types';

const HomepageBanner = props => {
  const { mobileBanner, loading, response, desktopBanner } = props;
  return (
    <section className="block w-full">
      {loading || response === undefined ? (
        <div
          className="bg-gray-300"
          style={{
            width: '100%',
            paddingTop: `${(280 / 940) * 100}%`
          }}
        />
      ) : (
        <picture className="w-full">
          <source
            media="(max-width: 479px)"
            srcSet={`${addParams(mobileBanner.contentUrl, {
              'banner-xs': true
            })}`}
          />
          <source
            media="(min-width: 480px) and (max-width: 639px)"
            srcSet={mobileBanner.contentUrl}
          />
          <source
            media="(min-width: 640px) and (max-width: 1023px)"
            srcSet={`${addParams(desktopBanner.contentUrl, {
              'banner-lg': true
            })}`}
          />
          <source
            media="(min-width: 1024px)"
            srcSet={`${desktopBanner.contentUrl}`}
          />
          <img
            width="1280"
            height="381"
            className="block w-full"
            src={`${addParams(desktopBanner.contentUrl, {
              'banner-lg': true
            })}`}
            alt={desktopBanner.altText}
            title={desktopBanner.title}
          />
        </picture>
      )}
    </section>
  );
};

HomepageBanner.propTypes = {
  loading: PropTypes.bool,
  response: PropTypes.any,
  mobileBanner: PropTypes.shape({ title: PropTypes.any, tags: PropTypes.any }),
  desktopBanner: PropTypes.shape({ title: PropTypes.any, tags: PropTypes.any })
};

export default HomepageBanner;
export { HomepageBanner };
