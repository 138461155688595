import { Currency } from 'app/common/components';

const FulfillmentFee = ({ fee }) => {
  return (
    <tr>
      <td>{fee.partNumber}</td>
      <td>
        <Currency currency={fee.amount.currency} amount={fee.amount.amount} />
      </td>
    </tr>
  );
};

export default FulfillmentFee;
export { FulfillmentFee };
