/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty, map } from 'lodash';

import { SecondaryButton } from 'app/common/components';
import { useCartInfo, useFormatMessage, useRestApi } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import messages from './BulkRemoveListItemButton.messages';

const BulkRemoveListItemButton = ({
  disabled = false,
  list,
  selectedItems,
  handlePostSubmit
}) => {
  const formatMessage = useFormatMessage();
  const {
    listOperations: { baseUrl, bulkDeleteContextPath }
  } = useCartInfo();
  const config = React.useMemo(
    () => ({
      method: 'post',
      data: { itemIds: map(selectedItems, ({ id }) => id) }
    }),
    [selectedItems]
  );
  const {
    error,
    exception,
    sendCallback: deleteItems
  } = useRestApi(
    `${baseUrl}/${get(list, 'id')}${bulkDeleteContextPath}`,
    config,
    false
  );

  React.useEffect(() => {
    if (error) {
      logError({
        ...exception,
        when: `removing items from list ${list.id}`
      });
    }
  }, [error, exception, list]);

  return (
    <SecondaryButton
      disabled={disabled || isEmpty(selectedItems)}
      onClick={async () => {
        if (disabled || isEmpty(selectedItems)) {
          return;
        }

        await deleteItems();

        if (!!handlePostSubmit) {
          handlePostSubmit();
        }
      }}
      backgroundColor="bg-white hover:bg-red-100 focus:bg-red-100 active:bg-red-100 disabled:bg-white"
      borderColor="border-red-300 disabled:border-gray-200"
      textColor="text-red-700 hover:text-red-800 focus:text-red-800 disabled:text-gray-400"
      size={SecondaryButton.Size.SMALL}
    >
      {formatMessage(messages.label)}
    </SecondaryButton>
  );
};

export default BulkRemoveListItemButton;
export { BulkRemoveListItemButton };
