import { defineMessages } from 'react-intl';

export default defineMessages({
  modalTitle: {
    id: 'PreferredLocation.title',
    defaultMessage: 'Choose a Preferred Location'
  },
  changePreferred: {
    id: 'PreferredLocation.changePreferred',
    defaultMessage:
      '{hasSelected, select, true {Change} other {Select}} Preferred Location'
  },
  selectedDealer: {
    id: 'PreferredLocation.selected.title',
    defaultMessage: 'Your preferred location for ordering parts is'
  },
  cancel: {
    id: 'PreferredLocation.cancel',
    defaultMessage: 'Cancel'
  },
  myStore: {
    id: 'PreferredLocation.myStore',
    defaultMessage: 'My Store'
  },
  setAsStore: {
    id: 'PreferredLocation.setAsStore',
    defaultMessage: 'Set as Store'
  }
});
