/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { useCartInfo, useRestApi } from 'app/common/hooks';
import { CSRContext } from 'app/csr/context';
import CSREditPrice from 'app/csr/components/CSREditPrice';
import { logError } from 'app/common/utils/ApiErrorUtils';

const overridePriceConfig = {
  method: 'patch',
  scope: 'CSR',
  params: {
    price: true
  }
};

const CSREditFulfillmentPrice = props => {
  const {
    fulfillmentGroup: {
      referenceNumber: id,
      address,
      totalFulfillmentPrice,
      fulfillmentOption
    }
  } = props;
  const { csrMode } = React.useContext(CSRContext);
  const {
    baseUrl,
    fulfillmentGroupsContextPath,
    overrideFulfillmentPriceContextPath
  } = useCartInfo().operations;
  const overridePriceUrl = `${baseUrl}${fulfillmentGroupsContextPath}/${id}${overrideFulfillmentPriceContextPath}`;
  const {
    error,
    exception,
    sendCallback: overrideFulfillmentPrice
  } = useRestApi(overridePriceUrl, overridePriceConfig, false, true);

  if (error) {
    logError({
      ...exception,
      when: `editing fulfillment item ${id} price`
    });
  }

  const fulfillmentCompleted = !isEmpty(address) && !isEmpty(fulfillmentOption);

  if (!csrMode || !fulfillmentCompleted) {
    return null;
  }

  return (
    <CSREditPrice
      className={cx('CSREditFulfillmentPrice', props.className)}
      originalPrice={totalFulfillmentPrice}
      handleSubmit={({ overridePrice, reason, comment }) =>
        overrideFulfillmentPrice({
          data: {
            overridePrice,
            reason,
            comment
          }
        })
      }
    />
  );
};

CSREditFulfillmentPrice.propTypes = {
  className: PropTypes.string,
  fulfillmentGroup: PropTypes.object
};

export default CSREditFulfillmentPrice;
