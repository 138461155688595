/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { get, isEmpty } from 'lodash';

import { FulfillmentType } from 'app/common/constants';
import { useFormatDate, useFormatMessage } from 'app/common/hooks';

import messages from './OrderReviewProgressBar.messages';

const OrderReviewProgressBar = ({ className, order }) => {
  const formatMessage = useFormatMessage();
  const formatDate = useFormatDate();
  const fulfillmentGroup = get(order, 'fulfillmentGroups[0]', {});
  const status = get(order, 'status', 'SUBMITTED');
  const submitDate = get(order, 'submitDate');
  const isCancelStatus =
    status === 'CANCELLED' || status === 'CANCEL_REQUESTED';

  // todo: next phase: determine some way to know if the dealer can provide addl details besides submitted
  if (true) {
    return (
      <div className="flex flex-wrap items-center mb-4">
        <div className="font-bold mr-2">
          {formatMessage(messages.progressSubmitted)}:
        </div>
        {!!submitDate && <div>{formatDate(submitDate)}</div>}
      </div>
    );
  }

  return (
    <ul
      className={classNames('OrderReviewProgressBar', {
        [className]: !!className
      })}
    >
      <Step active={!isEmpty(status)}>
        <div>{formatMessage(messages.progressSubmitted)}</div>
        {!!submitDate && <div>{formatDate(submitDate)}</div>}
      </Step>
      {isCancelStatus ? (
        <>
          <Step active>
            {formatMessage(messages.progressCancellationRequest)}
          </Step>
          <Step active={status === 'CANCELLED'}>
            {formatMessage(messages.progressCancelled)}
          </Step>
        </>
      ) : fulfillmentGroup.type === FulfillmentType.PICKUP ? (
        <>
          <Step active={status === 'PICK_UP_READY' || status === 'PICKED_UP'}>
            {formatMessage(messages.progressPickUpReady)}
          </Step>
          <Step active={status === 'PICKED_UP'}>
            {formatMessage(messages.progressPickedUp)}
          </Step>
        </>
      ) : (
        <>
          <Step active={status === 'SHIPPED' || status === 'DELIVERED'}>
            {formatMessage(messages.progressShipped)}
          </Step>
          <Step active={status === 'DELIVERED'}>
            {formatMessage(messages.progressDelivered)}
          </Step>
        </>
      )}
    </ul>
  );
};

const Step = ({ children, active }) => {
  return (
    <li
      className={classNames('OrderReviewProgressBarStep', {
        'text-primary-600 border-primary-600 bg-primary-600': active
      })}
    >
      {children}
    </li>
  );
};

export default OrderReviewProgressBar;
export { OrderReviewProgressBar };
