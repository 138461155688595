/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty } from 'lodash';
import { default as qs } from 'query-string';

import { Environment } from 'app/common/services';
import { logError } from 'app/common/utils/ApiErrorUtils';

import useRestApi from '../useRestApi';
import useHistory from '../useHistory';
import useLocation from '../useLocation';

function useLoginToken() {
  const location = useLocation();
  const history = useHistory();
  const { sendCallback } = useRestApi(undefined, undefined, false, true);

  React.useEffect(() => {
    const params = qs.parse(location.search);
    const token = params.token;

    if (isEmpty(token)) {
      return;
    }

    const endpoint = Environment.get(
      'ADL_LOGIN_TOKEN_ENDPOINT',
      '/auth/dealer-locator/login/token'
    );
    const config = {
      method: 'get',
      params: { token }
    };

    (async () => {
      try {
        await sendCallback(config, endpoint);
        params.token = undefined;
        window.location.replace(`${location.pathname}?${qs.stringify(params)}`);
      } catch (err) {
        logError({ ...err, when: 'signing in with token' });
        params.token = undefined;
        history.replace({ ...location, search: qs.stringify(params) });
      }
    })();
    // eslint-disable-next-line
  }, [location.search, sendCallback]);
}

export default useLoginToken;
