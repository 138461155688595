import React from 'react';
import { useFormatMessage } from 'app/common/hooks/index.js';
import { SelectField } from 'app/common/components/index.js';
import messages from '../../Orders.messages';

const OrderLocationFilter = ({
  applicationId,
  setApplicationId,
  onChange,
  orderHistoricalInfo,
  numOrders
}) => {
  const formatMessage = useFormatMessage();
  const filters =
    orderHistoricalInfo?.filters?.['tracking.application.contextId'] || [];

  if (filters.length === 0 || !numOrders || numOrders <= 0) {
    return null;
  }
  const selectedApplication = filters.find(app => app.value === applicationId);
  const field = getSelectFieldProps(
    setApplicationId,
    onChange,
    selectedApplication
  );
  const valueOptions = getSelectFieldValues(filters);
  return (
    <SelectField
      field={field}
      valueOptions={valueOptions}
      value={selectedApplication ? selectedApplication.value : undefined}
      widths={'w-full md:w-80'}
      placeholderLabel={formatMessage(messages.filterByLocation)}
      resetLabel={formatMessage(messages.selectAllLocations)}
    />
  );
};

function getSelectFieldProps(setApplicationId, onChange, selectedApplication) {
  return {
    onChange: e => {
      setApplicationId(e.target.value);
      onChange({
        applicationId: e.target.value,
        type: 'appFilter'
      });
    },
    onBlur: () => {},
    name: 'applicationFilter',
    value: selectedApplication?.value
  };
}

function getSelectFieldValues(filters) {
  return filters.map(application => {
    const { name, value } = application;
    return {
      label: name,
      value
    };
  });
}

export default OrderLocationFilter;
