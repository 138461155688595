/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import cx from 'classnames';
import { values } from 'lodash';

import {
  clear,
  setPreviewDate,
  setSandboxId
} from 'app/common/actions/preview';
import { Icon } from 'app/common/components';
import {
  useFormatMessage,
  usePreviewDispatch,
  usePreviewState
} from 'app/common/hooks';

import useSandboxColors from '../hooks/useSandboxColors';
import PreviewCountdown from './PreviewCountdown';
import PreviewDatePicker from './PreviewDatePicker';
import PreviewSandboxDropdown from './PreviewSandboxDropdown';
import messages from './PreviewHeader.messages';

/**
 * The component responsible for rendering the preview header that provides the controls for a
 * user to change their sandbox, set a preview date, or exit preview.
 */
const PreviewHeader = () => {
  const [isMobileCollapsed, setIsMobileCollapsed] = React.useState(true);
  const formatMessage = useFormatMessage();
  const state = usePreviewState();
  const dispatch = usePreviewDispatch();
  const sandbox = state.sandboxById[state.sandboxId];
  const colors = useSandboxColors(sandbox);
  return (
    <header
      className="flex items-center justify-between flex-wrap p-3"
      style={{
        backgroundColor: colors.background.primary,
        color: colors.text.primary
      }}
    >
      <div className="flex items-baseline flex-shrink-0 mr-6">
        <span className="font-semibold text-xl tracking-tight mr-2">
          {formatMessage(messages.title)}
        </span>
        <PreviewCountdown expirationTime={state.expirationTime} />
      </div>
      <div className="block lg:hidden">
        <button
          className="flex items-center px-3 py-2 border rounded hover:opacity-75"
          style={{
            backgroundColor: colors.background.primary,
            borderColor: colors.text.primary,
            color: colors.text.primary
          }}
          onClick={() =>
            setIsMobileCollapsed(isMobileCollapsed => !isMobileCollapsed)
          }
          title={formatMessage(messages.menu)}
        >
          <Icon className="fill-current h-3 w-3" name="bars" />
        </button>
      </div>

      <div
        className={cx(
          'w-full flex-grow items-center flex-col lg:flex lg:flex-row lg:w-auto',
          {
            flex: !isMobileCollapsed,
            hidden: isMobileCollapsed
          }
        )}
      >
        <div className="lg:flex-grow flex flex-col items-center lg:justify-center lg:flex-row">
          <div className="mt-4 lg:mt-0">
            <PreviewDatePicker
              onChange={value => dispatch(setPreviewDate(value))}
              value={state.previewDate}
            />
          </div>
          <span className="px-2 hidden lg:block">{'•'}</span>
          <div className="mt-4 lg:mt-0">
            <span className="font-light">{state.author}</span>
          </div>
          <span className="px-2 hidden lg:block">{'•'}</span>
          <div className="mt-4 lg:mt-0">
            <PreviewSandboxDropdown
              activeColors={colors}
              onChange={sandbox => dispatch(setSandboxId(sandbox.id))}
              options={values(state.sandboxById)}
              value={sandbox}
            />
          </div>
        </div>
        <div>
          <button
            className="flex items-center rounded font-semibold hover:underline mt-4 lg:mt-0"
            onClick={() => dispatch(clear())}
            style={{
              backgroundColor: colors.background.primary,
              color: colors.text.primary
            }}
            type="button"
          >
            {formatMessage(messages.exit)}
            <Icon className="ml-2" name="sign-out-alt" />
          </button>
        </div>
      </div>
    </header>
  );
};

export default PreviewHeader;
