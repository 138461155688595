/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import {
  chunk,
  find,
  findIndex,
  get,
  isEmpty,
  join,
  map,
  reduce,
  set,
  size
} from 'lodash';
import TagManager from 'app/common/tagmanager';

import { Currency, LoadingIcon, PrimaryButton } from 'app/common/components';
import { CartContext } from 'app/common/contexts';
import { useCartInfo, useFormatMessage, useRestApi } from 'app/common/hooks';
import { Environment } from 'app/common/services';
import { logError } from 'app/common/utils/ApiErrorUtils';
import { ListDetailsContext } from 'app/my-account/components/ListDetails/contexts';
import {
  determineIncludedItemPricing,
  determineProductOrVariantPricing
} from 'app/product/utils/PricingUtils';
import messages from './ListSummary.messages';

const ListSummary = ({ resolving }) => {
  const formatMessage = useFormatMessage();
  const { listItems } = React.useContext(ListDetailsContext);
  const total = React.useMemo(
    () =>
      map(
        listItems,
        ({ itemSkuRef: { sku: itemSku, variantId }, product, quantity }) => {
          const { variants, includedProducts } = product;
          const productPricing = determineProductOrVariantPricing(
            product,
            get(product, 'priceInfo')
          );
          const selectedVariant = find(variants, ({ id, sku }) => {
            return id === variantId || sku === itemSku;
          });
          const selectedVariantPricing = determineProductOrVariantPricing(
            selectedVariant,
            get(selectedVariant, 'priceInfo')
          );
          const additionalAmount = reduce(
            includedProducts,
            (sum, item) => {
              const { p = { amount: 0.0 } } =
                determineIncludedItemPricing(item);
              return sum + p.amount * item.quantity;
            },
            0.0
          );
          const price =
            (isEmpty(selectedVariantPricing)
              ? productPricing.price
              : selectedVariantPricing.price) || {};
          set(price, 'amount', get(price, 'amount', 0.0));
          return {
            amount: (price.amount + additionalAmount) * quantity,
            currency: price.currency
          };
        }
      ).reduce(
        (acc, { amount, currency }) => ({
          amount: acc.amount + amount,
          currency: acc.currency || currency
        }),
        { amount: 0.0 }
      ),
    [listItems]
  );

  return (
    <section className="lg:w-3/10 xl:w-1/4">
      <section className="relative lg:sticky lg:top-32">
        <section className="bg-gray-100 rounded shadow lg:shadow-md">
          <header className="mb-4 p-4 bg-white rounded-t">
            <h2 className="text-gray-700 text-xl font-bold capitalize leading-none lg:text-2xl">
              {formatMessage(messages.title)}
            </h2>
          </header>
          <section>
            <ol className="flex flex-col list-none">
              <li id="cart-subtotal" className="mb-4">
                <em className="flex justify-between px-4 not-italic capitalize">
                  <div className="mr-2 capitalize">
                    {formatMessage(messages.subtotal)}
                  </div>
                  <Currency {...total} />
                </em>
              </li>
              <li id="cart-taxes" className="mb-4">
                <div className="flex justify-between px-4 capitalize">
                  <div className="mr-2 capitalize">
                    {formatMessage(messages.tax)}
                  </div>
                  {formatMessage(messages.taxTbd)}
                </div>
              </li>
              <li id="cart-total" className="bg-white rounded-b">
                <strong className="flex justify-between px-4 py-2 text-lg font-bold capitalize">
                  <div className="mr-2 capitalize">
                    {formatMessage(messages.total)}
                  </div>
                  <Currency {...total} />
                </strong>
              </li>
            </ol>
          </section>
        </section>
        <section className="my-4">
          <AddToCartButton disabled={resolving} />
        </section>
      </section>
    </section>
  );
};

const AddToCartButton = ({ disabled = false }) => {
  const formatMessage = useFormatMessage();
  const { list, setAddToCartState } = React.useContext(ListDetailsContext);
  const { cart, setCart, toggleMinicartOpen } = React.useContext(CartContext);
  const config = React.useMemo(
    () => ({
      method: 'post',
      data: { itemListIds: [get(list, 'id')] }
    }),
    [list]
  );
  const { baseUrl, listsBulkAddContextPath } = useCartInfo().listOperations;
  const { sendCallback: bulkAdd } = useRestApi(
    `${baseUrl}${listsBulkAddContextPath}`,
    config,
    false,
    true
  );
  const [submitting, setSubmitting] = React.useState(false);

  return (
    <PrimaryButton
      className="relative w-full p-3 text-base md:shadow-md"
      size={PrimaryButton.Size.CUSTOM}
      onClick={async () => {
        if (disabled || submitting) {
          return;
        }

        setSubmitting(true);

        try {
          const response = await bulkAdd();

          if (!isEmpty(response) && !isEmpty(response.cart)) {
            const responseCart = response.cart;
            pushAddToCartToDataLayer(responseCart, cart);
            setAddToCartState({
              success: true,
              quantity:
                size(responseCart.cartItems) -
                (!!cart ? size(cart.cartItems) : 0)
            });
            setCart(responseCart);
            toggleMinicartOpen(true);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          }
        } catch (err) {
          logError({ ...err, when: 'bulk adding items from list to cart' });
          setAddToCartState({
            success: false,
            reason: err.type
          });
        } finally {
          setSubmitting(false);
        }
      }}
      disabled={disabled || submitting}
    >
      {submitting && (
        <div className="absolute inset-0 flex justify-center items-center">
          <LoadingIcon className="text-blue-500" />
        </div>
      )}
      <div
        className={classNames({
          'text-transparent': submitting
        })}
      >
        {formatMessage(messages.addToCart)}
      </div>
    </PrimaryButton>
  );
};

function pushAddToCartToDataLayer(responseCart, oldCart) {
  const existingItems = !!oldCart
    ? map(oldCart.cartItems, ({ sku }) => sku)
    : [];
  const addedItems = responseCart.cartItems.filter(
    ({ sku }) => !existingItems.includes(sku)
  );
  const batches = chunk(
    addedItems,
    Environment.get('GTM_BULK_CART_OP_BATCH_SIZE', 50)
  );

  batches.forEach(items => {
    TagManager.dataLayer({
      event: 'addToCart',
      ecommerce: {
        currencyCode: get(items, '[0].unitPrice.currency', 'USD'),
        add: {
          products: items.map(item => {
            const cartItemPos = findIndex(responseCart.cartItems, [
              'sku',
              item.sku
            ]);
            const {
              quantity,
              sku,
              unitPrice,
              attributes: { categoryNames }
            } = item;
            const description = get(
              item,
              'attributes.description',
              get(item, 'description')
            );

            return {
              category: join(categoryNames, ', '),
              id: sku,
              name: description,
              position: cartItemPos,
              price: get(unitPrice, 'amount', 0.0),
              quantity
            };
          })
        }
      }
    });
  });
}

export default ListSummary;
export { ListSummary };
