/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, findIndex, isEmpty, map, noop } from 'lodash';

import { CartItemErrorProvider } from 'app/cart/helpers';
import {
  BulkAddToListButton,
  BulkRemoveCartItemButton,
  CheckboxField
} from 'app/common/components';
import { CartContext } from 'app/common/contexts';
import { useFormatMessage, useToggle } from 'app/common/hooks';

import { CartItem } from './components';
import messages from './CartItems.messages';

/**
 * Render component for the list of items in a cart.
 *
 * @visibleName Cart Page: Items List
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const CartItems = () => {
  const { cart, resolving } = React.useContext(CartContext);
  const cartItems = get(cart, 'cartItems');
  const quantity = get(cart, 'quantity', 0);
  const formatMessage = useFormatMessage();
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [selectAll, toggleSelectAll] = useToggle(false);

  React.useEffect(() => {
    if (selectAll) {
      setSelectedItems(cartItems.map((item, i) => ({ ...item, position: i })));
    } else {
      setSelectedItems([]);
    }
  }, [cartItems, selectAll]);

  return (
    <section className="bg-gray-100 rounded shadow lg:shadow-md">
      <header className="p-4 bg-white rounded-t">
        <h2 className="text-gray-700 text-xl font-bold capitalize leading-none lg:text-2xl">
          {formatMessage(messages.cartStartTitle, { quantity })}
        </h2>
      </header>
      <section>
        <header className="flex items-center px-4 py-2 bg-white border-t border-b border-gray-400">
          <CheckboxField.Field
            className="basis-1/12"
            checked={selectAll}
            disabled={resolving}
            value="ALL"
            field={{
              onBlur: noop,
              onChange: () => {
                toggleSelectAll();
              },
              name: 'select-all',
              value: 'ALL'
            }}
          />
          <div className="flex flex-col basis-11/12 flex-grow sm:flex-row sm:basis-full">
            <BulkAddToListButton
              disabled={resolving}
              selectedItems={selectedItems}
              handleSuccess={() => {
                setSelectedItems([]);
                toggleSelectAll(false);
              }}
            />
            <BulkRemoveCartItemButton
              disabled={resolving}
              selectedItems={selectedItems}
            />
          </div>
        </header>
        <section>
          <ul className="list-none" style={{ minHeight: '100px' }}>
            {isEmpty(cartItems) ? (
              <div className="flex items-center justify-center w-full mt-4 text-gray-600 text-lg font-medium capitalize leading-none sm:mt-6 sm:text-xl lg:mt-8">
                {formatMessage(messages.empty)}
              </div>
            ) : (
              map(cartItems, (ci, i) => (
                <CartItemErrorProvider key={ci.id}>
                  <CartItem
                    cartItem={ci}
                    position={i}
                    handleChecked={add => {
                      if (add) {
                        setSelectedItems([
                          ...selectedItems,
                          { ...ci, position: i }
                        ]);
                      } else {
                        setSelectedItems(
                          selectedItems.filter(({ id }) => id !== ci.id)
                        );
                      }
                    }}
                    selected={!!~findIndex(selectedItems, ['id', ci.id])}
                  />
                </CartItemErrorProvider>
              ))
            )}
          </ul>
        </section>
      </section>
    </section>
  );
};

export default CartItems;
export { CartItems, CartItem };
