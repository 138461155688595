import { isEmpty } from 'lodash';

const aboutUsInitialState = {
  error: null,
  storeAddressHoursSource: {},
  accountNumber: null,
  locationId: null,
  shouldFetch: true,
  isStoreDetailsPath: false,
  isFetching: false,
  noFetch: true,
  fetchComplete: false
};

const aboutUsReducer = (state = aboutUsInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_STATE':
      return {
        ...state,
        ...payload
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: payload
      };
    case 'SET_ADDRESS_HOURS_SOURCE':
      return {
        ...state,
        storeAddressHoursSource: payload
      };
    default:
      return state;
  }
};

const INITIATE_FETCH = (accountNumber, locationId) => ({
  type: 'SET_STATE',
  payload: {
    shouldFetch: true,
    accountNumber,
    locationId,
    storeAddressHoursSource: {},
    error: null,
    isStoreDetailsPath: !isEmpty(accountNumber) && !isEmpty(locationId)
  }
});

const FETCH_START = {
  type: 'SET_STATE',
  payload: {
    isFetching: true,
    fetchComplete: false
  }
};

const FETCH_COMPLETE = ({ storeAddressHoursSource }) => ({
  type: 'SET_STATE',
  payload: {
    shouldFetch: false,
    isFetching: false,
    fetchComplete: true,
    storeAddressHoursSource
  }
});

const NO_FETCH_NEEDED = ({ storeAddressHoursSource }) => ({
  type: 'SET_STATE',
  payload: {
    shouldFetch: false,
    isFetching: false,
    storeAddressHoursSource,
    noFetch: true,
    fetchComplete: true
  }
});

const SET_ADDRESS_HOURS_SOURCE = ({ storeAddressHoursSource }) => ({
  type: 'SET_ADDRESS_HOURS_SOURCE',
  payload: {
    storeAddressHoursSource
  }
});

const DID_FETCH = {
  type: 'SET_STATE',
  payload: {
    noFetch: false,
    isFetching: false,
    shouldFetch: false
  }
};

const SET_STORE_DETAILS_PATH = ({ isStoreDetailsPath }) => ({
  type: 'SET_STATE',
  payload: {
    isStoreDetailsPath
  }
});

export {
  aboutUsReducer,
  aboutUsInitialState,
  FETCH_COMPLETE,
  FETCH_START,
  INITIATE_FETCH,
  SET_ADDRESS_HOURS_SOURCE,
  NO_FETCH_NEEDED,
  DID_FETCH,
  SET_STORE_DETAILS_PATH
};
