/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import axios from 'axios';
import { merge, isEmpty, toLower } from 'lodash';

import {
  getBaseContextPath,
  getDefaultHeaders
} from 'app/common/utils/RequestUtils';

/**
 * Service responsible for provided request methods. This is a wrapper around
 * the configured request agent, [axios](https://github.com/axios/axios) by
 * default.
 *
 * @type {Readonly<{head(*): *, patch(*): *, request(AxiosRequestConfig): AxiosPromise<any>, post(*): *, get(*): *, del(*): *, put(*): *}>}
 */
class RequestService {
  static del(config, context) {
    return RequestService.request({ method: 'delete', ...config }, context);
  }

  static get(config, context) {
    return RequestService.request({ method: 'get', ...config }, context);
  }

  static head(config, context) {
    return RequestService.request({ method: 'head', ...config }, context);
  }

  static patch(config, context) {
    return RequestService.request({ method: 'patch', ...config }, context);
  }

  static post(config, context) {
    return RequestService.request({ method: 'post', ...config }, context);
  }

  static put(config, context) {
    return RequestService.request({ method: 'put', ...config }, context);
  }

  /**
   * Attaches a request interceptor that should take and return the request config. The provided
   * `onFulfilled` will be used before the request is sent. `onRejected` will be used if the request
   * returns with an error.
   *
   * @param {Function} onFulfilled - Function to do something before the request is sent
   * @param {Function} [onRejected] - Function to do something when the request errors
   *
   * @return {Function} Returns a method whereby to detach the interceptor.
   */
  static attachRequestInterceptor(onFulfilled, onRejected) {
    let isAttached = true;

    const interceptorId = axios.interceptors.request.use(
      onFulfilled,
      onRejected
    );

    return function detachInterceptor() {
      if (!isAttached) {
        return;
      }

      isAttached = false;
      axios.interceptors.request.eject(interceptorId);
    };
  }

  /**
   * Sends a web request with the given config. By default this means an
   * [axios request](https://github.com/axios/axios). This will also append the
   * default headers (`#getDefaultHeaders`).
   *
   * @param {AxiosRequestConfig} config - the request configuration
   * @param {Object} context - context info that can include things like current
   *     site ID and locale.
   *
   * @return {AxiosPromise<any>} the request promise
   */
  static request(config, context) {
    const baseURL = getBaseContextPath();
    const method = isEmpty(config.method) ? 'get' : toLower(config.method);
    const headers = merge(getDefaultHeaders(context), config.headers);
    return axios.request({ ...config, baseURL, headers, method });
  }

  static CancelToken = axios.CancelToken;

  static isCancel = axios.isCancel;
}

export default RequestService;
export { RequestService };
