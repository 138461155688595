/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

import { Environment } from 'app/common/services';

/**
 * Determines if the scope is a CSR scope.
 *
 * @param scope the scope to check
 * @returns {boolean} `true` if the scope is a CSR scope
 */
function isCsrScope(scope) {
  const csrScope = Environment.get('auth.csr.scope', 'CSR');
  return csrScope.includes(scope);
}

/**
 * Determines if the token represents a CSR authentication.
 *
 * @param token the token
 * @returns {boolean} true if the token represents a CSR authentication
 */
function isCsrToken(token) {
  return !!token.csr_sub;
}

/**
 * Determines if the token represents a CSR anonymous authentication.
 *
 * @param token the token
 * @returns {boolean} true if the token represents a CSR anonymous authentication
 */
function isAnonymousCsrToken(token) {
  return token.csr_anonymous;
}

export { isCsrScope, isCsrToken, isAnonymousCsrToken };
