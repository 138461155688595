/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import { Currency } from 'app/common/components';
import { useFormatDate, useFormatMessage } from 'app/common/hooks';
import OrderSummaryHeaderField from './OrderSummaryHeaderField';
import OrderSummaryActions from './OrderSummaryActions';
import messages from '../OrderSummary.messages';
import { get, isEmpty, isEqual } from 'lodash';
import cx from 'classnames';
import {
  getPaymentGatewayLabel,
  getPaymentGatewayType,
  getPaymentMethodLabel,
  isPayInStore,
  PaypalImage
} from 'app/checkout/utils/PaymentMethodUtil';

const OrderSummaryHeader = props => {
  const { order } = props;

  const status = get(order, 'status');
  const orderStatus = get(order, 'orderStatus');

  let finalStatus;
  if (isEqual(orderStatus, 'PENDING')) {
    finalStatus = status;
  } else {
    finalStatus = orderStatus;
  }
  const hasPurchaseOrderNumber = !isEmpty(get(order, 'purchaseOrderNumber'));
  const firstRowClass = hasPurchaseOrderNumber
    ? 'basis-1/3 md:basis-1/6'
    : 'basis-1/2 md:basis-1/5';
  const secondRowClass = hasPurchaseOrderNumber
    ? 'basis-1/3 pt-2 md:pt-0 md:basis-1/6'
    : 'basis-1/2 pt-2 md:pt-0 md:basis-1/5';
  const orderTotalClass = hasPurchaseOrderNumber
    ? firstRowClass
    : firstRowClass + ' pt-2 md:pt-0';
  const orderNumberClass =
    'basis-full pt-2 md:pt-0 ' +
    (hasPurchaseOrderNumber ? 'md:basis-1/6' : 'md:basis-1/5');
  return (
    <div className="bg-white">
      <div className="flex-col md:flex justify-between p-4">
        <div className="flex flex-row flex-wrap md:flex-no-wrap mb-1 md:mb-2">
          <OrderDate order={order} className={firstRowClass} />
          <OrderStatus finalStatus={finalStatus} className={firstRowClass} />
          <OrderTotal order={order} className={orderTotalClass} />
          <PurchaseOrderNumber order={order} className={secondRowClass} />
          <PaymentMethod order={order} className={secondRowClass} />
          <OrderNumber order={order} className={orderNumberClass} />
        </div>
      </div>
    </div>
  );
};

const OrderTotal = ({ order, className }) => {
  const formatMessage = useFormatMessage();
  return (
    <OrderSummaryHeaderField
      className={className}
      title={formatMessage(messages.orderPrice)}
    >
      <Currency {...order.orderPricing.total} />
    </OrderSummaryHeaderField>
  );
};

const OrderStatus = ({ finalStatus, className }) => {
  const formatMessage = useFormatMessage();
  return (
    <OrderSummaryHeaderField
      className={cx(className, 'md:ml-3')}
      title={formatMessage(messages.statusLabel)}
    >
      {formatMessage(messages.status, { finalStatus })}
    </OrderSummaryHeaderField>
  );
};

const OrderDate = ({ order, className }) => {
  const formatDate = useFormatDate();
  const formatMessage = useFormatMessage();
  return (
    <OrderSummaryHeaderField
      className={className}
      title={formatMessage(messages.orderDate)}
    >
      {formatDate(order.submitDate, {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      })}
    </OrderSummaryHeaderField>
  );
};

const PurchaseOrderNumber = ({ order, className }) => {
  const formatMessage = useFormatMessage();
  const purchaseOrderNumber = get(order, 'purchaseOrderNumber');
  const label = formatMessage(messages.purchaseOrderNumber);
  if (isEmpty(purchaseOrderNumber)) {
    return null;
  }

  return (
    <>
      <OrderSummaryHeaderField
        className={cx(className, 'hidden mr-0 lg:block')}
        title={label}
      >
        {purchaseOrderNumber}
      </OrderSummaryHeaderField>
      <OrderSummaryHeaderField
        className={cx(className, 'lg:hidden', 'mr-0 md:mr-2')}
        title={label}
      >
        {purchaseOrderNumber}
      </OrderSummaryHeaderField>
    </>
  );
};

const OrderNumber = ({ order, className }) => {
  const formatMessage = useFormatMessage();
  return (
    <div className={className}>
      <div className="flex flex-col mb-2">
        <div className="text-xs text-gray-700">
          <div className={'w-1/2'}>{formatMessage(messages.orderNumber)}</div>
          <div className={'w-1/2'}>{order.orderNumber}</div>
        </div>
      </div>
      <OrderSummaryActions order={order} />
    </div>
  );
};

const PaymentMethod = ({ order, className }) => {
  const formatMessage = useFormatMessage();
  if (!order) {
    return null;
  }

  const paymentGatewayType = getPaymentGatewayType(order);
  return (
    <OrderSummaryHeaderField
      className={className}
      title={formatMessage(getPaymentMethodLabel())}
    >
      {isPayInStore(paymentGatewayType) ? (
        formatMessage(getPaymentGatewayLabel(paymentGatewayType))
      ) : (
        <PaypalImage />
      )}
    </OrderSummaryHeaderField>
  );
};

export default OrderSummaryHeader;
export { OrderSummaryHeader };
