/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { assign, get as getField, isNil, pick } from 'lodash';

import { Environment } from 'app/common/services';

/**
 * Returns the default request headers.
 *
 * @param {Object} context - the tracking context info
 *
 * @return {{Accept: string, 'Accept-Language': string, 'X-Context-Request': string, 'X-Preview-Date'?: string, 'X-Preview-Token'?: string}}
 */
function getDefaultHeaders(context = {}) {
  const contextRequestHeader = getContextRequestHeader(context);
  const languageHeaders = getLanguageHeaders(context);
  const resolutionContextHeader = getResolutionContextHeader(context);
  const previewHeaders = getPreviewHeaders(context);
  const nationalSiteHeaders = getNationalSiteHeaders(context);
  return assign(
    {
      Accept: 'application/json'
    },
    contextRequestHeader,
    languageHeaders,
    previewHeaders,
    resolutionContextHeader,
    nationalSiteHeaders
  );
}

/**
 * Returns the configured context request header, usually "X-Context-Request".
 * This contains important tracking info like the site and catalog IDs.
 *
 * @param {Object} context - the tracking context info
 *
 * @return {Object} The configured context request header
 */
function getContextRequestHeader(context) {
  const name = getField(
    import.meta?.env,
    'CONTEXT_REQUEST_HEADER',
    'X-Context-Request'
  );
  return {
    [name]: JSON.stringify(
      pick(context, [
        'applicationId',
        'customerContextId',
        'sandboxId',
        'tenantId'
      ])
    )
  };
}

function getResolutionContextHeader(context) {
  const name = getField(
    import.meta?.env,
    'RESOLUTION_CONTEXT_HEADER',
    'X-Resolution-Context'
  );
  return {
    [name]: JSON.stringify(pick(context, ['resolutionContext']))
  };
}

/**
 * Returns the configured language headers, usually "Accept-Language" or
 * "X-Ignore-Translations".
 *
 * @param {Object} context - the tracking context info including the locale
 *
 * @return {Object} the configured language headers
 */
function getLanguageHeaders({ locale }) {
  if (!!locale) {
    // typical header is "Accept-Language", this will result in translations being
    // applied for the given locale
    const langHeader = getField(
      import.meta?.env,
      'ACCEPT_LANGUAGE_HEADER',
      'Accept-Language'
    );

    return { [langHeader]: locale };
  }

  // typical header is "X-Ignore-Translations", this will result in no
  // translations being applied
  const ignoreTranslationsHeader = getField(
    import.meta?.env,
    'IGNORE_TRANSLATIONS_HEADER',
    'X-Ignore-Translations'
  );

  return { [ignoreTranslationsHeader]: true };
}

/**
 * Returns the "X-Preview-Token" and "X-Preview-Date" headers if the expected context parameters are
 * provided.
 *
 * @param {string} previewDate the preview date as an ISO8601 date-time
 * @param {string} previewToken the preview token string
 * @returns {{'X-Preview-Date'?: string, 'X-Preview-Token'?: string}}
 */
function getPreviewHeaders({ previewDate, previewToken }) {
  if (!!previewToken) {
    const headers = {};
    const previewTokenHeaderName = Environment.get(
      'sandbox.preview.token.header',
      'X-Preview-Token'
    );
    headers[previewTokenHeaderName] = previewToken;
    if (!!previewDate) {
      const previewDateHeaderName = Environment.get(
        'sandbox.preview.date.header',
        'X-Preview-Date'
      );
      headers[previewDateHeaderName] = previewDate;
    }
    return headers;
  }

  return {};
}

function getNationalSiteHeaders({ nationalSiteHeaders }) {
  if (!nationalSiteHeaders) {
    return {};
  }
  return nationalSiteHeaders;
}

/**
 * The configured base servlet context path, e.g., `/api` in
 * `https://localhost:8456/api/menu/menu-hierarchy`. Set by the
 * `api.base.context.path` environment variable.
 *
 * @return {string} The configured base servlet context path, e.g., `/api` in
 * `https://localhost:8456/api/menu/menu-hierarchy`.
 */
function getBaseContextPath() {
  return Environment.get('api.base.context.path', '/api');
}

/**
 * Returns the "X-Cart-Version" and "X-Guest-Token" headers if the expected context parameters are
 * provided.
 *
 * @param {int} cartVersion the version of the cart
 * @param {Object} [guestToken] the current guest token
 * @returns {Object} the configured headers
 */
function getCartHeaders(cartVersion, guestToken) {
  const cartVersionHeaderName = Environment.get(
    'cart.version.header',
    'X-Cart-Version'
  );
  const cartGuestTokenHeaderName = Environment.get(
    'cart.token.header',
    'X-Guest-Token'
  );

  if (isNil(cartVersion)) {
    console.error(
      'A cart version is required, unable to find cart version to send in request header'
    );
  }

  if (!isNil(guestToken)) {
    return {
      [cartVersionHeaderName]: cartVersion,
      [cartGuestTokenHeaderName]: guestToken.tokenString
    };
  }

  return { [cartVersionHeaderName]: cartVersion };
}

export {
  getDefaultHeaders,
  getContextRequestHeader,
  getLanguageHeaders,
  getBaseContextPath,
  getCartHeaders
};
