/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useState } from 'react';
import { find, get, includes, merge } from 'lodash';

import { AuthContext } from 'app/auth/contexts';
import { Carousel, HTMLWidget } from 'app/common/components';
import { LocaleContext, PriceContext } from 'app/common/contexts';
import {
  useCatalogInfo,
  useGtmPageView,
  useNationalSiteContext,
  usePriceContextHeader,
  usePriceInfoContextHeader,
  useRestApi
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';
import LoginBox from './components/LoginBox';
import HomepageBanner from './components/HomepageBanner';
import * as PropTypes from 'prop-types';
import FeaturedParts from './components/FeaturedParts';

/**
 * Render component for the homepage.
 *
 * @visibleName Homepage
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const Homepage = () => {
  useGtmPageView('Homepage');

  return <RegisteredHomepage />;
};

const RegisteredHomepage = () => {
  const {
    categoryInfo: { detailsUrl }
  } = useCatalogInfo();
  const { applicationThemeSource: application, isWhiteLabel } =
    useNationalSiteContext();
  const { currentLocale: locale } = React.useContext(LocaleContext);
  const priceContextHeader = usePriceContextHeader();
  const priceInfoContextHeader = usePriceInfoContextHeader();
  const { hasAuthenticated, isAuthenticated } = React.useContext(AuthContext);
  const { hasLoadedPriceLists } = React.useContext(PriceContext);
  const [loginError, setLoginError] = useState(undefined);

  const config = React.useMemo(
    () =>
      merge({}, priceContextHeader, priceInfoContextHeader, {
        params: {
          categoryUrl: '/',
          offset: 0,
          forward: true,
          size: 4
        }
      }),
    [priceContextHeader, priceInfoContextHeader]
  );

  const {
    exception,
    error,
    loading,
    response,
    sendCallback: readCategoryDetails
  } = useRestApi(detailsUrl, config, false);
  const finishedRendering =
    !isAuthenticated && !loading && hasAuthenticated && hasLoadedPriceLists;

  React.useEffect(() => {
    if (hasAuthenticated && hasLoadedPriceLists) {
      readCategoryDetails();
    }
  }, [hasAuthenticated, hasLoadedPriceLists, readCategoryDetails]);

  if (error) {
    logError({
      ...exception,
      when: "fetching CategoryProducts for homepage's category"
    });

    return null;
  }

  const assets = get(response, 'assets', []);
  const desktopBanner = getDesktopBanner(
    assets,
    locale,
    isWhiteLabel,
    application
  );
  const mobileBanner = getMobileBanner(
    assets,
    locale,
    isWhiteLabel,
    application
  );

  return (
    <main className="container relative flex-grow">
      <div className="flex flex-col-reverse items-center lg:items-start lg:flex-row">
        {finishedRendering && (
          <LoginBox setLoginError={setLoginError} loginError={loginError} />
        )}

        <div className="flex-col m-3">
          <HomepageBanner
            loading={loading}
            response={response}
            mobileBanner={mobileBanner}
            desktopBanner={desktopBanner}
          />
          <section className="block w-full">
            <Carousel />
          </section>
        </div>
      </div>
      <InfoBanner
        loading={loading}
        response={response}
        whiteLabel={isWhiteLabel}
      />
      <FeaturedParts
        loading={loading}
        response={response}
        iteratee={({ promotionalProduct }) => promotionalProduct}
      />
    </main>
  );
};

InfoBanner.propTypes = {
  loading: PropTypes.bool,
  response: PropTypes.any,
  whiteLabel: PropTypes.any
};
function InfoBanner(props) {
  return (
    <section className="text-center">
      {props.loading || props.response === undefined ? (
        <div className="p-6 lg:pt-12">
          <h3 className="block w-48 mx-auto mb-2 text-2xl capitalize bg-gray-200">
            &nbsp;
          </h3>
          <p className="w-full mx-auto h-8 text-xs bg-gray-200">&nbsp;</p>
        </div>
      ) : (
        <div>
          {props.whiteLabel ? null : (
            <HTMLWidget content={get(props.response, 'description')} />
          )}
        </div>
      )}
    </section>
  );
}

function getBannerLocalePath(locale) {
  switch (locale) {
    case 'es':
      return 'spanishBanner';
    case 'fr':
      return 'frenchBanner';
    default:
      return 'defaultBanner';
  }
}

function getDesktopBanner(assets, locale, isWhiteLabel, application) {
  let desktopBanner = find(
    assets,
    ({ title, tags }) => title === 'Desktop Banner' && includes(tags, locale)
  );
  if (!desktopBanner)
    desktopBanner = find(
      assets,
      ({ title, tags }) =>
        title === 'Desktop Banner' &&
        (includes(tags, 'en') || tags.length === 0)
    );
  if (isWhiteLabel) {
    desktopBanner = getWhitelabelBanner(
      application,
      'desktopBanners',
      locale,
      desktopBanner
    );
  }
  return desktopBanner;
}

function getMobileBanner(assets, locale, isWhiteLabel, application) {
  let mobileBanner = find(
    assets,
    ({ title, tags }) => title === 'Mobile Banner' && includes(tags, locale)
  );
  if (!mobileBanner)
    mobileBanner = find(
      assets,
      ({ title, tags }) =>
        title === 'Mobile Banner' && (includes(tags, 'en') || tags.length === 0)
    );

  if (isWhiteLabel) {
    mobileBanner = getWhitelabelBanner(
      application,
      'mobileBanners',
      locale,
      mobileBanner
    );
  }
  return mobileBanner;
}

function getWhitelabelBanner(
  application,
  devicePlatformPath,
  locale,
  tenantBanner
) {
  const banners = get(application, devicePlatformPath, []);

  let banner = get(banners, getBannerLocalePath(locale));

  // Attempt to get the default banner if a banner is not found yet
  if (banner === undefined) {
    banner = get(banners, 'defaultBanner');
  }

  // Default to the tenant banner if no whitelabel banners are found
  if (banner === undefined) {
    banner = tenantBanner;
  }
  return banner;
}

export default Homepage;
export { Homepage };
