/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { noop } from 'lodash';

const defaultContext = Object.freeze({
  /** Whether to use the grid layout or list */
  useGrid: true,
  /** Method to update the layout in use */
  setUseGrid: noop
});

/**
 * Context used to hold data related to how to lay-out the results such as grid
 * vs list.
 * @type {React.Context<Readonly<{useGrid: boolean}>>}
 */
const ResultsLayoutContext = React.createContext(defaultContext);

ResultsLayoutContext.defaultContext = defaultContext;

export default ResultsLayoutContext;
