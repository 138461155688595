/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { connect, Form } from 'formik';
import { get, isEmpty } from 'lodash';

import { AuthContext } from 'app/auth/contexts';
import {
  CommunicationPreferences,
  DecoratedField,
  Icon,
  OptionalField,
  PrimaryButton,
  SelectField
} from 'app/common/components';
import {
  Phone,
  PostalCode,
  StateProvinceRegion
} from 'app/common/components/form/AddressForm/components';
import { useCountrySelectValues, useFormatMessage } from 'app/common/hooks';
import { useValidatePhone } from 'app/common/utils/AddressUtils';

import messages from './RegisterForm.messages';
import addressMessages from 'app/common/components/form/AddressForm/AddressForm.messages';
import CompanyField from './CompanyField';
import { ScrollToError } from 'app/common/helpers';

const RegisterForm = ({ disabled = false, formik }) => {
  const formatMessage = useFormatMessage();
  const countrySelectValues = useCountrySelectValues();
  const country = get(formik, 'values.address.country', 'US');
  const { clientId } = React.useContext(AuthContext);
  const validatePhone = useValidatePhone();

  return (
    <Form className="flex flex-col items-start p-4">
      <DecoratedField
        id="firstName"
        label={formatMessage(messages.firstName)}
        name="firstName"
        placeholder={formatMessage(messages.exampleFirstName)}
        required
        disabled={disabled}
        autoComplete="given-name"
      />
      <DecoratedField
        id="lastName"
        label={formatMessage(messages.lastName)}
        name="lastName"
        placeholder={formatMessage(messages.exampleLastName)}
        required
        disabled={disabled}
        autoComplete="family-name"
      />
      <DecoratedField
        id="email"
        label={formatMessage(messages.email)}
        placeholder={formatMessage(messages.exampleEmail)}
        name="email"
        required
        disabled={disabled}
        autoComplete="username"
      />
      <aside className="flex items-center my-4 px-2 py-1 text-sm text-blue-700 leading-snug border border-solid border-blue-300 bg-blue-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
        <Icon className="mr-2 md:mr-4" name="info-circle" />
        <span>{formatMessage(messages.formExplanation)}</span>
      </aside>
      <CompanyField
        id="companyName"
        label={formatMessage(addressMessages.companyLabel)}
        name="companyName"
        placeholder={formatMessage(addressMessages.companyPlaceholder)}
        required={true}
        disabled={disabled}
        autoComplete="organization"
        formik={formik}
      />
      <DecoratedField
        id="address1"
        label={formatMessage(addressMessages.address1Label)}
        name="address.addressLineOne"
        placeholder={formatMessage(addressMessages.address1Placeholder)}
        required
        disabled={disabled}
        autoComplete="address-line1"
      />
      <OptionalField
        name="address2"
        triggerLabel={formatMessage(addressMessages.address2Add)}
      >
        <DecoratedField
          id="address2"
          label={formatMessage(addressMessages.address2Label)}
          name="address.addressLineTwo"
          placeholder={formatMessage(addressMessages.address2Placeholder)}
          disabled={disabled}
          autoComplete="address-line2"
        />
      </OptionalField>
      <OptionalField
        name="address3"
        triggerLabel={formatMessage(addressMessages.address3Add)}
      >
        <DecoratedField
          id="address3"
          label={formatMessage(addressMessages.address3Label)}
          name="address.addressLineThree"
          placeholder={formatMessage(addressMessages.address3Placeholder)}
          disabled={disabled}
          autoComplete="address-line3"
        />
      </OptionalField>
      <DecoratedField
        id="country"
        label={formatMessage(addressMessages.countryLabel)}
        name="address.country"
        component={SelectField}
        valueOptions={countrySelectValues}
        required
        disabled={disabled}
        autoComplete="country"
      />
      <PostalCode
        country={country}
        id="postalCode"
        label={formatMessage(addressMessages.postalCodeLabel, { country })}
        name="address.postal"
        placeholder={formatMessage(addressMessages.postalCodePlaceholder, {
          country
        })}
        hint={formatMessage(addressMessages.postalCodeHint, { country })}
        required
        disabled={disabled}
        autoComplete="postal-code"
      />
      <DecoratedField
        id="city"
        label={formatMessage(addressMessages.cityLabel, { country })}
        name="address.city"
        required
        disabled={disabled}
        placeholder={formatMessage(addressMessages.cityPlaceholder)}
        autoComplete="address-level2"
      />
      <StateProvinceRegion
        country={country}
        id="state"
        label={formatMessage(addressMessages.stateLabel, { country })}
        name="address.state"
        required
        disabled={disabled}
        validate={value =>
          validateField({
            value,
            message: formatMessage(addressMessages.stateRequired)
          })
        }
        autoComplete="address-level1"
      />
      <Phone
        autoComplete="tel"
        disabled={disabled}
        hint={formatMessage(addressMessages.contactPhoneHint, { country })}
        id="phone1"
        isPrimary
        label={formatMessage(addressMessages.contactPhoneLabel)}
        name="address.phone[0].phoneNumber"
        placeholder={formatMessage(addressMessages.contactPhonePlaceholder, {
          country
        })}
        required
        type="tel"
        validate={value =>
          validatePhone(
            value,
            formatMessage(addressMessages.contactPhoneLabel),
            true
          )
        }
        phoneNamePrefix="address.phone[0]"
      />
      <OptionalField
        name="phone2"
        triggerLabel={formatMessage(addressMessages.contactPhoneSecondaryAdd)}
      >
        <Phone
          id="phone2"
          label={formatMessage(addressMessages.contactPhoneSecondaryLabel)}
          name="address.phone[1].phoneNumber"
          placeholder={formatMessage(addressMessages.contactPhonePlaceholder, {
            country
          })}
          hint={formatMessage(addressMessages.contactPhoneHint, { country })}
          disabled={disabled}
          type="tel"
          phoneNamePrefix="address.phone[1]"
          validate={value =>
            validatePhone(
              value,
              formatMessage(addressMessages.contactPhoneSecondaryLabel)
            )
          }
        />
      </OptionalField>
      <OptionalField
        name="phone3"
        triggerLabel={formatMessage(addressMessages.contactPhoneFaxAdd)}
      >
        <Phone
          id="phone3"
          label={formatMessage(addressMessages.contactPhoneFaxLabel)}
          name="address.phone[2].phoneNumber"
          placeholder={formatMessage(addressMessages.contactPhonePlaceholder, {
            country
          })}
          hint={formatMessage(addressMessages.contactPhoneHint, { country })}
          disabled={disabled}
          type="tel"
          phoneNamePrefix="address.phone[2]"
          validate={value =>
            validatePhone(
              value,
              formatMessage(addressMessages.contactPhoneFaxLabel)
            )
          }
        />
      </OptionalField>
      <CommunicationPreferences
        className="mt-2"
        legendClassName="flex mb-2 capitalize text-gray-700 text-sm font-bold leading-none disabled:text-gray-500"
        disabled={disabled}
      />
      {!!clientId && (
        <PrimaryButton className="self-end" type="submit" disabled={disabled}>
          {formatMessage(messages.submit)}
        </PrimaryButton>
      )}
      <ScrollToError />
    </Form>
  );
};

const validateField = ({ message, value, required = true }) => {
  if (isEmpty(value) && required) {
    return message;
  }
};

export default connect(RegisterForm);
export { RegisterForm };
