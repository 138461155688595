/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Button, Dropdown, Icon } from 'app/common/components';
import {
  useFormatDate,
  useFormatMessage,
  useFormatTime
} from 'app/common/hooks';

import messages from './PreviewDatePicker.messages';

/**
 * The component responsible for rendering the date picker that allows a user to select a future
 * date to preview data for.
 */
const PreviewDatePicker = ({ onChange, value }) => {
  const formatDate = useFormatDate();
  const formatTime = useFormatTime();
  const formatMessage = useFormatMessage();
  const valueAsDate = value ? new Date(value) : null;
  const nowAsDate = new Date();
  return (
    <Dropdown>
      <Dropdown.Menu.Trigger
        triggerClassName="flex items-center font-semibold focus:outline-none hover:underline"
        showIcon={false}
      >
        <Icon className="mr-2" name={['far', 'clock']} />
        {!!value
          ? `${formatDate(value)}, ${formatTime(value)}`
          : formatMessage(messages.today)}
      </Dropdown.Menu.Trigger>
      <Dropdown.Menu className="mt-2 border" openTo="CENTER">
        <li
          onClick={e => {
            e.preventDefault();
            // This is necessary to prevent the click-outside event from closing when the "previous"
            // month button is clicked. It is unclear why that triggers an event, since it is inside
            // of the dropdown, but there might be something `react-datepicker` is doing to that
            // element that causes the containment check to fail.
            e.nativeEvent.stopImmediatePropagation();
          }}
        >
          <DatePicker
            calendarClassName="flex"
            inline
            onChange={date => onChange(date.toISOString())}
            minDate={nowAsDate}
            openToDate={valueAsDate || nowAsDate}
            selected={valueAsDate}
            showTimeSelect
          />
          {!!value && (
            <Button
              className="w-full inline-flex items-center justify-center py-1 px-2 bg-gray-200 hover:bg-gray-300 active:bg-gray-400 disabled:bg-gray-500 font-semibold text-sm text-black focus:outline-none disabled:cursor-not-allowed"
              onClick={() => onChange(null)}
              type="button"
            >
              {formatMessage(messages.today)}
            </Button>
          )}
        </li>
      </Dropdown.Menu>
    </Dropdown>
  );
};

PreviewDatePicker.propTypes = {
  /**
   * Change event handler for when a new date is picked.
   *
   * @param {string} nextValue the ISO8601 date string input by the user
   */
  onChange: PropTypes.func,

  /**
   * The current ISO8601 date string to preview.
   */
  value: PropTypes.string
};

export default PreviewDatePicker;
