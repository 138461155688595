/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

/**
 * @typedef {Object} Sandbox
 * @property {string} id - the id of the sandbox
 * @property {string} name - the name of the sandbox
 * @property {string} [color] - the color of the sandbox
 */

/**
 * @typedef {Object} PreviewState
 * @property {boolean} isActive=false - whether or not preview is active, i.e. if a preview token is present
 * @property {boolean} isValidated=false - whether or not the preview token has been validated
 * @property {boolean} isValidating=false - whether or not the preview token is being validated
 * @property {string | null} author=null - the author whose changes are being previewed
 * @property {string | null} token=null - the token string to be used in API requests
 * @property {string | null} sandboxId=null - the current user sandbox ID
 * @property {Object.<string, Sandbox>} sandboxById={} - the table of user sandboxes
 * @property {string | null} previewDate=null - ISO8601 date-time used to preview data at a future date
 * @property {string | null} expirationTime=null - ISO8601 date-time used to indicate when the token expires
 * @property {string | null} errorMessage=null - an error message if a failure to validate
 */

/**
 * @typedef {Function} PreviewDispatch
 * @callback
 * @param {{ type: string, payload: * }} action - the action to dispatch
 */

/**
 * @type {PreviewState}
 */
export const defaultState = {
  isActive: false,
  isValidated: false,
  isValidating: false,
  token: null,
  author: null,
  sandboxId: null,
  sandboxById: {},
  previewDate: null,
  expirationTime: null,
  errorMessage: null
};

/**
 * @type {React.Context<[PreviewState]>}
 */
export const PreviewContext = React.createContext([defaultState, () => {}]);

export default PreviewContext;
