/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  subtotal: {
    id: 'Cart.Summary.subtotal',
    defaultMessage: 'Subtotal:'
  },
  adjustments: {
    id: 'Cart.Summary.adjustments',
    defaultMessage: 'Savings:'
  },
  fulfillmentAdjustments: {
    id: 'Cart.Summary.fulfillmentAdjustments',
    defaultMessage: 'Delivery Savings:'
  },
  delivery: {
    id: 'Cart.Summary.delivery',
    defaultMessage: 'Delivery:'
  },
  deliveryTbd: {
    id: 'Cart.Summary.deliveryTbd',
    defaultMessage: 'TBD'
  },
  taxes: {
    id: 'Cart.Summary.taxes',
    defaultMessage: 'Taxes & Fees:'
  },
  taxesTbd: {
    id: 'Cart.Summary.taxesTbd',
    defaultMessage: 'TBD'
  },
  total: {
    id: 'Cart.Summary.total',
    defaultMessage: 'Total:'
  },
  estimatedTotal: {
    id: 'Cart.Summary.estimatedTotal',
    defaultMessage: 'Estimated Total:'
  },
  totalCoreCost: {
    id: 'Cart.Summary.totalCoreCost',
    defaultMessage: 'Total Core Cost:'
  }
});
