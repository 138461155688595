/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import cx from 'classnames';
import { noop } from 'lodash';
import { Route, Switch } from 'react-router-dom';

import { AuthContext } from 'app/auth/contexts';
import { TertiaryButton } from 'app/common/components';
import { CartContext } from 'app/common/contexts';
import { useFormatMessage, useOnEventOutside } from 'app/common/hooks';

import { DialogContent } from './components';
import messages from './MinicartDialog.messages';

/**
 * Render component for the mini shopping cart dialog.
 *
 * @visibleName Minicart: Dialog
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const MinicartDialog = props => {
  const { isOpen } = props;
  const { toggleMinicartOpen = noop } = React.useContext(CartContext);
  const toggleMinicartClosed = React.useCallback(
    () => toggleMinicartOpen(false),
    [toggleMinicartOpen]
  );
  const containerRef = useOnEventOutside(toggleMinicartClosed);
  const formatMessage = useFormatMessage();
  const { isAuthenticated } = React.useContext(AuthContext);

  return (
    <div className="relative z-20">
      <article
        id="minicart-dialog"
        className={cx(
          'absolute right-0 flex flex-col w-72 bg-white shadow rounded-b',
          {
            hidden: !isOpen,
            flex: isOpen
          }
        )}
        ref={containerRef}
      >
        <Switch>
          <Route path={'/cart'} />
          <Route component={DialogContent} />
        </Switch>
        {isAuthenticated && (
          <Switch>
            <Route path="/my-account/lists" />
            <Route>
              <aside className="flex items-center justify-between px-4 py-2 border-t border-gray-200">
                <TertiaryButton to="/my-account/lists">
                  {formatMessage(messages.viewLists)}
                </TertiaryButton>
              </aside>
            </Route>
          </Switch>
        )}
      </article>
    </div>
  );
};

export default MinicartDialog;
export { MinicartDialog };
