/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { createContext } from 'react';
import { noop } from 'lodash';

/**
 * @type {Readonly<{activeFleet: String|undefined, setActiveFleet: function}>}
 */
const defaultValue = Object.freeze({
  activeFleet: undefined,
  setActiveFleet: noop
});

/**
 * @type {React.Context<Readonly<{activeFleet: (String|undefined), setActiveFleet: Function}>>}
 */
const FleetContext = createContext(defaultValue);

FleetContext.defaultValue = defaultValue;

export default FleetContext;
