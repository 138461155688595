/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  legend: {
    id: 'Checkout.Payment.Form.legend',
    defaultMessage: 'Enter Payment Info'
  },
  displayName: {
    id: 'Checkout.Payment.Form.name.displayName',
    defaultMessage: 'Payment Name'
  },
  nameLabel: {
    id: 'Checkout.Payment.Form.name.label',
    defaultMessage: "Cardholder's Name"
  },
  namePlaceholder: {
    id: 'Checkout.Payment.Form.name.placeholder',
    defaultMessage: 'First M Last'
  },
  nameHint: {
    id: 'Checkout.Payment.Form.name.hint',
    defaultMessage: 'The name exactly as printed on the front of the card.'
  },
  nameRequired: {
    id: 'Checkout.Payment.Form.name.required',
    defaultMessage: 'Name is required.'
  },
  numberLabel: {
    id: 'Checkout.Payment.Form.number.label',
    defaultMessage: 'Card Number'
  },
  numberHint: {
    id: 'Checkout.Payment.Form.number.hint',
    defaultMessage: '12-19 digits, spaces allowed'
  },
  numberRequired: {
    id: 'Checkout.Payment.Form.number.required',
    defaultMessage: 'Number is required.'
  }
});
