/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { get, values } from 'lodash';
import PropTypes from 'prop-types';

import { Button } from 'app/common/components';
import { AssetType } from 'app/common/constants';
import { useProductClick } from 'app/common/hooks';
import { addParams } from 'app/common/utils/PathUtils';
import notFound from 'app/common/img/image-not-found.jpg';

import { MiniProductGridContext } from '../../../../contexts';

/**
 * Render component for the product's primary asset and badges.
 *
 * @visibleName MiniProductGrid: MiniProduct: Asset
 * @author [Nathan Moore](https://github.com/nathandmoore)
 *
 * @todo: handle more than just images?
 */
const MiniProductAsset = ({
  productName,
  productUrl,
  primaryAsset = {
    contentUrl: notFound
  },
  gtmItem
}) => {
  const { altText, title, contentUrl: src = notFound } = primaryAsset;
  const sendProductClick = useProductClick(gtmItem);
  const [height, setHeight] = React.useState('auto');
  const [width, setWidth] = React.useState('100%');
  const [imgMissingPadding, setImgMissingPadding] = React.useState(undefined);
  const { breadcrumbs = [], usingGrid } = React.useContext(
    MiniProductGridContext
  );

  return (
    <figure
      className={classNames('relative block overflow-hidden', {
        'h-48 md:h-64 lg:h-56': usingGrid
      })}
    >
      <Button
        className={classNames(
          'flex items-start justify-center h-full w-full appearance-none focus:outline-none',
          { 'sm:items-center': usingGrid }
        )}
        to={location => ({
          ...location,
          pathname: productUrl,
          state: {
            ...get(location, 'state', {}),
            breadcrumbs: breadcrumbs.concat([{ label: productName }])
          }
        })}
        onClick={() => {
          sendProductClick();
        }}
      >
        <img
          onLoad={({ target: img }) => {
            const h = img.offsetHeight;
            const w = img.offsetWidth;
            setHeight(w >= h ? 'auto' : '100%');
            setWidth(w >= h ? '100%' : 'auto');
          }}
          onError={() => {
            setImgMissingPadding('1.75rem');
          }}
          className="block rounded"
          src={`${addParams(src, { browse: true })}`}
          alt={altText || productName}
          title={title}
          height="432"
          width="432"
          style={{ height, width, padding: imgMissingPadding }}
        />
      </Button>
    </figure>
  );
};

MiniProductAsset.propTypes = {
  primaryAsset: PropTypes.shape({
    altText: PropTypes.string,
    contentUrl: PropTypes.string,
    /** HTML to embed in place of having a source URL */
    embedCode: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    type: PropTypes.oneOf(values(AssetType))
  }),
  availableOnline: PropTypes.bool,
  productName: PropTypes.string,
  productUrl: PropTypes.string.isRequired
};

export default MiniProductAsset;
export { MiniProductAsset };
