/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  concat,
  flatMap,
  get,
  isEmpty,
  isEqual,
  keys,
  mapKeys,
  set,
  toPairs
} from 'lodash';

import { ThemeContext } from 'app/common/contexts';
import { useNationalSiteContext } from 'app/common/hooks';
import makeTemplate from '@silvermine/undertemplate';

const template = makeTemplate;

const ThemeProvider = ({ children }) => {
  const {
    resolving,
    useApplicationContent,
    applicationThemeSource: application
  } = useNationalSiteContext();

  const [theme, setTheme] = useState(ThemeContext.defaultContext);

  useEffect(() => {
    if (resolving) {
      return;
    }

    if (useApplicationContent && isEmpty(get(application, 'primaryColor'))) {
      setTheme(ThemeContext.defaultContext);
      return;
    }

    const primaryColors = mapKeys(
      get(application, 'primaryColor'),
      (value, key) => key.replace('color', '')
    );
    const nextState = {
      ...ThemeContext.defaultContext,
      colors: {
        ...ThemeContext.defaultContext.colors,
        primary: primaryColors
      }
    };
    // avoid unnecessary rerendering if it hasn't actually changed
    setTheme(prevState =>
      isEqual(prevState, nextState) ? prevState : nextState
    );
  }, [application, resolving, useApplicationContent]);

  return (
    <ThemeContext.Provider value={theme}>
      <ColorStyles />
      {children}
    </ThemeContext.Provider>
  );
};

const ColorStyles = () => {
  const styles = useStyles();

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: styles.join('\n\n')
      }}
    />
  );
};

const useStyles = () => {
  const primaryColors = useContext(ThemeContext).colors.primary;
  const selectors = useSelectors();
  return useMemo(() => {
    const variants = toPairs(primaryColors);
    const bgStyles = variants.map(([key, variant]) => {
      const style = BgTemplate({ variant });
      return get(selectors.bg, key)
        .map(selector => `${selector} ${style}`)
        .join('\n');
    });
    const textStyles = variants.map(([key, variant]) => {
      const style = TextTemplate({ variant });
      return get(selectors.text, key)
        .map(selector => `${selector} ${style}`)
        .join('\n');
    });
    const borderStyles = variants.map(([key, variant]) => {
      const style = BorderTemplate({ variant });
      return get(selectors.border, key)
        .map(selector => `${selector} ${style}`)
        .join('\n');
    });
    const placeholderStyles = variants.map(([key, variant]) => {
      const style = PlaceholderTemplate({ variant });
      return get(selectors.placeholder, key)
        .map(selector => `${selector} ${style}`)
        .join('\n');
    });

    return concat(bgStyles, textStyles, borderStyles, placeholderStyles);
  }, [primaryColors, selectors]);
};

const useSelectors = () => {
  const primaryColors = useContext(ThemeContext).colors.primary;
  const variants = useMemo(() => keys(primaryColors), [primaryColors]);
  return useMemo(() => {
    const baseSelectors = variants
      .map(variant =>
        BaseSelectorTemplates.map(selectorTemplate =>
          selectorTemplate({ variant })
        )
      )
      .reduce((acc, arr) => concat(acc, arr), []);
    const stateSelectors = baseSelectors
      .map(baseSelector =>
        StateSelectorTemplates.map(selectorTemplate =>
          selectorTemplate({ baseSelector: baseSelector.substring(1) })
        )
      )
      .reduce((acc, arr) => concat(acc, arr), []);

    return flatMap(concat(baseSelectors, stateSelectors), selector => {
      if (selector.indexOf('placeholder') === -1) {
        return [selector];
      }

      return PlaceholderSuffixTemplates.map(selectorTemplate =>
        selectorTemplate({ baseSelector: selector })
      );
    }).reduce((acc, selector) => {
      const key = getKey(selector);
      const value = get(acc, key);

      if (isEmpty(value)) {
        set(acc, key, [selector]);
        return acc;
      }

      set(acc, key, [...value, selector]);
      return acc;
    }, {});
  }, [variants]);
};

const getKey = selector => {
  const parts = selector.match(SelectorParts);
  return `${parts[1]}.${parts[2]}`;
};

const BaseSelectorTemplates = [
  template('.bg-primary-<%= variant %>'),
  template('.text-primary-<%= variant %>'),
  template('.border-primary-<%= variant %>'),
  template('.placeholder-primary-<%= variant %>')
];

const StateSelectorTemplates = [
  template('.hover\\:<%= baseSelector %>:hover'),
  template('.focus\\:<%= baseSelector %>:focus'),
  template('.active\\:<%= baseSelector %>:active'),
  template('.disabled\\:<%= baseSelector %>:disabled'),
  template('.group:hover .group-hover\\:<%= baseSelector %>')
];

const PlaceholderSuffixTemplates = [
  template('<%= baseSelector %>::-webkit-input-placeholder'),
  template('<%= baseSelector %>::-ms-input-placeholder'),
  template('<%= baseSelector %>::placeholder')
];

const BgTemplate = template('{ background-color: <%= variant %>; }');
const TextTemplate = template('{ color: <%= variant %>; }');
const BorderTemplate = template('{ border-color: <%= variant %>; }');
const PlaceholderTemplate = template('{ color: <%= variant %>; }');

const SelectorParts = /([A-Za-z]+)-primary-(\d{3})/;

export default ThemeProvider;
export { ThemeProvider };
