/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

const DefaultCategoryBrowseSkeleton = props => {
  return (
    <main className="container relative flex-grow min-h-screen p-4 sm:py-8 md:px-6 lg:px-8">
      <header className="text-center">
        <nav className="container w-full mb-4 sm:mb-8">
          <ol className="flex w-full text-xs md:text-sm lg:text-base">
            <li className="flex-1 overflow-hidden text-left text-gray-700 whitespace-no-wrap">
              <span className="w-12 inline-block bg-gray-200 rounded">
                &nbsp;
              </span>
              <span className="inline-block mx-1 md:mx-2 lg:mx-3">{'/'}</span>
              <span className="w-24 inline-block bg-gray-200 rounded">
                &nbsp;
              </span>
              <span className="inline-block mx-1 md:mx-2 lg:mx-3">{'/'}</span>
              <span className="w-24 inline-block bg-gray-200 rounded">
                &nbsp;
              </span>
            </li>
          </ol>
        </nav>
        <div className="flex justify-between items-baseline mb-3 border-b border-gray-400 sm:mb-6">
          <h1 className="w-48 block mb-2 bg-gray-200 text-2xl font-medium leading-tight rounded sm:text-3xl lg:text-4xl">
            &nbsp;
          </h1>
        </div>
      </header>
    </main>
  );
};

export default DefaultCategoryBrowseSkeleton;
export { DefaultCategoryBrowseSkeleton };
