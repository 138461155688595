/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  nonStocking: {
    id: 'common.CartOrListErrors.nonStocking',
    defaultMessage:
      'We apologize for the inconvenience, but {quantity, plural, one {part} other {parts} } {parts} {quantity, plural, one {is} other {are} } no longer sold from this site {requiresRemoval,select, other { } true { } false {and {quantity, plural, one {has} other {have} } been removed from your {isCart, select, true {cart} other {list}}}}. Please make changes as necessary.'
  },
  dismiss: {
    id: 'common.CartOrListErrors.dismiss',
    defaultMessage: 'Dismiss error'
  },
  generalError: {
    id: 'common.CartOrListErrors.generalError',
    defaultMessage:
      'We encountered an error processing your request. Please try again later.'
  }
});
