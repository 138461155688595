/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { chunk, findIndex, get, isEmpty, join, map, omit } from 'lodash';
import TagManager from 'app/common/tagmanager';

import { SecondaryButton } from 'app/common/components';
import {
  CartContext,
  CurrencyContext,
  LocaleContext,
  TenantContext
} from 'app/common/contexts';
import {
  useCartInfo,
  useFormatMessage,
  useModifyCartRequest
} from 'app/common/hooks';
import { Environment } from 'app/common/services';
import { logError } from 'app/common/utils/ApiErrorUtils';

import messages from './BulkAddToCartButton.messages';

const BulkAddToCartButton = ({
  disabled = false,
  handlePostSubmit,
  selectedItems
}) => {
  const formatMessage = useFormatMessage();
  const { currentLocale } = React.useContext(LocaleContext);
  const { application, tenant } = React.useContext(TenantContext);
  const applicationId = get(application, 'id');
  const tenantId = get(tenant, 'id');
  const { setCart, toggleMinicartOpen } = React.useContext(CartContext);
  const { currentCurrency: currency } = React.useContext(CurrencyContext);
  const config = React.useMemo(
    () => ({
      method: 'post',
      data: map(
        selectedItems,
        ({
          itemSkuRef: { sku, productId, variantId },
          product,
          quantity,
          attributes
        }) => {
          const catalogId = get(product, 'contextState.catalog.contextId');
          return {
            applicationId,
            tenantId,
            catalogId,
            currency,
            locale: currentLocale,
            variantId,
            productId,
            sku,
            quantity,
            dependentCartItems: get(attributes, 'dependentCartItems'),
            itemAttribute: omit(attributes, ['dependentCartItems'])
          };
        }
      ),
      params: { price: true }
    }),
    [applicationId, tenantId, currentLocale, selectedItems, currency]
  );
  const {
    operations: { baseUrl, bulkItemsContextPath }
  } = useCartInfo();
  const {
    error,
    exception,
    sendCallback: bulkAdd
  } = useModifyCartRequest(
    `${baseUrl}${bulkItemsContextPath}`,
    config,
    false,
    true
  );

  React.useEffect(() => {
    if (error) {
      logError({ ...exception, when: 'bulk adding items from list to cart' });
    }
  }, [exception, error]);

  return (
    <SecondaryButton
      className="mb-2 sm:mb-0 sm:mr-4"
      disabled={disabled || isEmpty(selectedItems)}
      onClick={async () => {
        if (disabled || isEmpty(selectedItems)) {
          return;
        }

        try {
          const response = await bulkAdd();

          if (!isEmpty(response) && !isEmpty(response.cart)) {
            const responseCart = response.cart;
            pushDataToLayerToGtm(selectedItems, responseCart);

            if (!!handlePostSubmit) {
              handlePostSubmit(true, response, selectedItems);
            }

            setCart(responseCart);
            toggleMinicartOpen(true);
          }
        } catch (err) {
          logError({ ...err, when: 'bulk adding items from list to cart' });

          if (!!handlePostSubmit) {
            handlePostSubmit(false, err, selectedItems);
          }
        }
      }}
      size={SecondaryButton.Size.SMALL}
    >
      {formatMessage(messages.label)}
    </SecondaryButton>
  );
};

function pushDataToLayerToGtm(selectedItems, responseCart) {
  const batches = chunk(
    selectedItems,
    Environment.get('GTM_BULK_CART_OP_BATCH_SIZE', 50)
  );

  batches.forEach(items => {
    TagManager.dataLayer({
      event: 'addToCart',
      ecommerce: {
        currencyCode: get(items, '[0].unitPrice.currency', 'USD'),
        add: {
          products: items.map(item => {
            const cartItemPos = findIndex(responseCart.cartItems, [
              'sku',
              item.sku
            ]);
            const {
              quantity,
              sku,
              unitPrice,
              attributes: { categoryNames }
            } = item;
            const description = get(
              item,
              'attribute.description',
              get(item, 'description')
            );

            return {
              category: join(categoryNames, ', '),
              id: sku,
              name: description,
              position: cartItemPos,
              price: get(unitPrice, 'amount', 0.0),
              quantity
            };
          })
        }
      }
    });
  });
}

export default BulkAddToCartButton;
export { BulkAddToCartButton };
