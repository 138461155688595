/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useReducer } from 'react';
import { get, isEmpty } from 'lodash';

import { AuthContext } from 'app/auth/contexts';
import { CheckoutContext } from 'app/checkout/contexts';
import { CartContext } from 'app/common/contexts';
import { checkoutReducer, initialState } from 'app/checkout/contexts/reducers';
import {
  setIsGuest as _setIsGuest,
  setIsSubmittingOrder as _setIsSubmittingOrder,
  setPromoCodeUpdated as _setPromoCodeUpdated
} from 'app/checkout/contexts/reducers/checkoutReducer';
import { useSetDeliveryAvailable } from 'app/checkout/components/CheckoutLayout/components/FulfillmentInfoWithOptions/hooks';

const CheckoutContextProvider = ({ children }) => {
  const { isAuthenticated } = React.useContext(AuthContext);
  const { cart, resolving } = React.useContext(CartContext);
  const resolved = !resolving && cart !== undefined;
  const emailAddress = get(cart, 'emailAddress');
  const status = get(cart, 'status');

  const [checkoutState, checkoutDispatch] = useReducer(checkoutReducer, {
    ...initialState,
    isAuthenticated,
    isGuest: !resolved,
    isCartResolved: resolved,
    isSubmittingOrder: false,
    promoCodeUpdated: false,
    cart,
    cartStatus: isEmpty(status) ? 'IN_PROCESS' : status
  });
  return (
    <CheckoutContext.Provider
      value={{
        ...checkoutState,
        setIsGuest: isGuest => {
          checkoutDispatch(_setIsGuest(isGuest));
        },
        setIsSubmittingOrder: isSubmittingOrder =>
          checkoutDispatch(_setIsSubmittingOrder(isSubmittingOrder)),
        setPromoCodeUpdated: promoCodeUpdated =>
          checkoutDispatch(_setPromoCodeUpdated(promoCodeUpdated)),
        checkoutDispatch
      }}
    >
      {children}
      {useSetIsGuest(resolved, isAuthenticated, emailAddress, checkoutDispatch)}
      {useSetDeliveryAvailable(checkoutDispatch)}
    </CheckoutContext.Provider>
  );
};

function useSetIsGuest(
  resolved,
  isAuthenticated,
  emailAddress,
  checkoutDispatch
) {
  React.useEffect(() => {
    if (!resolved) {
      return;
    }
    checkoutDispatch(_setIsGuest(!isAuthenticated && !isEmpty(emailAddress)));
    // eslint-disable-next-line
  }, [emailAddress, isAuthenticated, resolved]);
}

export default CheckoutContextProvider;
export { CheckoutContextProvider };
