/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import qs from 'query-string';

import authStateExports from './AuthState';
import HiddenIframe from './HiddenIframe';

class SilentAuthRenewal {
  constructor(options) {
    this.url = options.url;
    this.client_id = options.client_id;
    this.redirect_uri = options.redirect_uri;
    this.scope = options.scope;
    this.eventType = options.eventType;
    this.successCallback = options.successCallback;
    this.errorCallback = options.errorCallback;

    this.timeout = options.timeout || 3000;
    this.window = options.window || window;
    this.iframe = null;

    this.execute = this.execute.bind(this);
    this.handleTimeout = this.handleTimeout.bind(this);
    this.validateEvent = this.validateEvent.bind(this);
    this.destroy = this.destroy.bind(this);
    this.callback = this.callback.bind(this);
    this.buildAuthorizeUrl = this.buildAuthorizeUrl.bind(this);
  }

  execute() {
    this.state = authStateExports.generateUniqueState(this.scope);
    this.iframe = new HiddenIframe({
      id: this.state,
      url: this.buildAuthorizeUrl(),
      timeout: this.timeout,
      timeoutCallback: this.handleTimeout,
      validateEvent: this.validateEvent,
      callback: this.callback,
      window: this.window
    });

    this.iframe.initialize();
  }

  buildAuthorizeUrl() {
    const params = {
      client_id: this.client_id,
      redirect_uri: this.redirect_uri,
      scope: this.scope,
      state: this.state,
      response_type: 'code',
      prompt: 'none'
    };

    return `${this.url}?${qs.stringify(params)}`;
  }

  validateEvent(event) {
    return (
      event.origin === this.window.location.origin &&
      event.data.type === this.eventType &&
      event.data.response.state === this.state
    );
  }

  handleTimeout() {
    this.destroy();
    this.errorCallback({
      error: 'timeout',
      error_description: 'Silent authentication timed out'
    });
  }

  callback(event) {
    this.destroy();
    const { error, error_description, code } = event.data.response;
    if (code) {
      this.successCallback(code);
    } else {
      this.errorCallback({ error, error_description });
    }
  }

  destroy() {
    this.iframe = null;
    this.state = undefined;
  }
}

export default SilentAuthRenewal;
