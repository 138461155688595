/* eslint-disable */
import { useCallback, useRef } from 'react';
import { useOnEventOutside } from 'app/common/hooks';
import {
  AUTO_COMPLETE_CLOSE,
  STORE_SEARCH_CLOSE
} from 'app/layout/components/Header/components/StoreDropdown/reducer/storeDropdownReducer';
import { updateDropdownContextAndReducer } from 'app/layout/components/Header/components/StoreDropdown/util';

/**
 * Hook to manage the state of the store dropdown
 * @returns {StoreDropdownContainerRefs}
 */
const useStoreSearchContainerRefs = (state, dispatch, setOpenStoreDropdown) => {
  //refs
  const parentContainerRef = useRef(null);
  const suggestionContainerRef = useRef(null);

  // hooks
  const suggestionClickOutside = useSuggestionClickOutside(
    suggestionContainerRef,
    dispatch
  );
  const parentClickOutside = useParentClickOutside(
    parentContainerRef,
    suggestionContainerRef,
    dispatch,
    setOpenStoreDropdown
  );
  const clickOutsideContainerRef = useOnEventOutside(parentClickOutside);
  const clickOutsideSuggestionsContainerRef = useOnEventOutside(
    suggestionClickOutside
  );

  return new StoreDropdownContainerRefs(
    parentContainerRef,
    clickOutsideContainerRef,
    suggestionContainerRef,
    clickOutsideSuggestionsContainerRef
  );
};

const useParentClickOutside = function (
  parentContainerRef,
  suggestionContainerRef,
  dispatch,
  setOpenStoreDropdown
) {
  return useCallback(
    e => {
      const target = e?.target;
      const srcElement = e?.srcElement;
      const isSuggestedAddress =
        typeof srcElement?.className === 'string'
          ? srcElement.className.includes('suggested-address')
          : false;
      if (
        !isSuggestedAddress &&
        !!parentContainerRef.current &&
        !parentContainerRef.current.contains(target) &&
        !parentContainerRef.current.contains(srcElement)
      ) {
        updateDropdownContextAndReducer(
          dispatch,
          STORE_SEARCH_CLOSE,
          setOpenStoreDropdown
        );
      }
    },
    [parentContainerRef, dispatch, setOpenStoreDropdown]
  );
};

const useSuggestionClickOutside = function (suggestionContainerRef, dispatch) {
  return useCallback(
    e => {
      if (
        !!suggestionContainerRef.current &&
        !suggestionContainerRef.current.contains(e.target)
      ) {
        dispatch(AUTO_COMPLETE_CLOSE);
      }
    },
    [suggestionContainerRef, dispatch]
  );
};

class StoreDropdownContainerRefs {
  /**
   * Ref to the parent store dearch dropdown container
   */
  parentContainerRef;

  /**
   * Ref to the click outside container. Used to close the store search dropdown
   */
  clickOutsideContainerRef;

  /**
   * Ref to the suggestions container.
   */
  suggestionContainerRef;

  /**
   * Ref to the click outside suggestions container. Used to close the autocomplete suggestions
   */
  clickOutsideSuggestionsContainerRef;

  constructor(
    parentContainerRef,
    clickOutsideContainerRef,
    suggestionContainerRef,
    clickOutsideSuggestionsContainerRef
  ) {
    this.parentContainerRef = parentContainerRef;
    this.clickOutsideContainerRef = clickOutsideContainerRef;
    this.suggestionContainerRef = suggestionContainerRef;
    this.clickOutsideSuggestionsContainerRef =
      clickOutsideSuggestionsContainerRef;
  }
}

export { useStoreSearchContainerRefs };
