/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { isEmpty } from 'lodash';

import {
  VARIANT_ATTRIBUTE_CHOICE,
  CART_ITEM_ATTRIBUTE_CHOICE,
  ITEM_CHOICE
} from './configurableItemActionTypes';

const initialState = {
  /**
   * Map of the attribute names to the values selected for variant distinguishing options.
   */
  variantAttributeChoices: undefined,
  /**
   * Map of `ItemChoice#choiceKey` to the ID and SKU of the product or variant,
   * whether it's a product, and the quantity selected.
   */
  itemChoices: undefined,
  /**
   * Map of attribute names to the values selected for cart item attribute options.
   */
  cartItemAttributeChoices: undefined
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case VARIANT_ATTRIBUTE_CHOICE: {
      const variantAttributeChoices = state.variantAttributeChoices || {};
      const choice = action.variantAttributeChoice;
      return {
        ...state,
        variantAttributeChoices: {
          ...variantAttributeChoices,
          [choice.name]: choice.value
        }
      };
    }
    case ITEM_CHOICE: {
      const itemChoices = state.itemChoices || {};
      // there can only be 1 choice per key
      const { itemChoice } = action;
      const { choiceKey, id, isConfigurable, isMulti, productId } = itemChoice;
      let oldItemChoiceVal = itemChoices[choiceKey];
      // only use 1 variant per configurable product
      const specificChoiceKey = isConfigurable ? productId : id;
      const newSpecificVal = itemChoice;

      if (isEmpty(oldItemChoiceVal)) {
        return {
          ...state,
          itemChoices: {
            ...itemChoices,
            [choiceKey]: { [specificChoiceKey]: newSpecificVal }
          }
        };
      }

      if (isMulti) {
        // there can be multiple specific choices
        oldItemChoiceVal = {
          ...oldItemChoiceVal,
          [specificChoiceKey]: newSpecificVal
        };

        return {
          ...state,
          itemChoices: { ...itemChoices, [choiceKey]: oldItemChoiceVal }
        };
      }

      // there can only be 1 specific choice
      return {
        ...state,
        itemChoices: {
          ...itemChoices,
          [choiceKey]: { [specificChoiceKey]: newSpecificVal }
        }
      };
    }
    case CART_ITEM_ATTRIBUTE_CHOICE: {
      const cartItemAttributeChoices = state.cartItemAttributeChoices || {};
      const attribute = action.cartItemAttributeChoice;

      return {
        ...state,
        cartItemAttributeChoices: {
          ...cartItemAttributeChoices,
          [attribute.name]: attribute.value
        }
      };
    }
    default: {
      // todo log
      return state;
    }
  }
}

export { reducer, initialState };
