/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext } from 'react';
import cx from 'classnames';
import { isEmpty, map } from 'lodash';

import { Dropdown } from 'app/common/components';
import {
  CurrencyContext,
  CartContext,
  LocaleContext
} from 'app/common/contexts';
import { useCartInfo, useModifyCartRequest } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

const CurrencySelector = ({ className }) => {
  const { allowedCurrencies, currentCurrency, setCurrentCurrency } =
    useContext(CurrencyContext);
  const { cart, setCart, resolving } = useContext(CartContext);
  const { currentLocale } = useContext(LocaleContext);
  const {
    operations: { baseUrl, pricingContextPath }
  } = useCartInfo();
  const config = React.useMemo(
    () => ({ method: 'post', params: { price: true } }),
    []
  );
  const {
    error,
    exception,
    sendCallback: updateCurrency
  } = useModifyCartRequest(
    `${baseUrl}${pricingContextPath}`,
    config,
    false,
    true
  );

  const currenciesInfo = CurrencySelector.getCurrenciesInfo(allowedCurrencies);
  const [label, setLabel] = React.useState(
    <div className="w-6 h-5 bg-primary-800 rounded">&nbsp;</div>
  );

  React.useEffect(() => {
    const currentCurrencySymbol =
      CurrencySelector.getCurrencySymbol(currentCurrency);
    const currentCurrencyLabel = currentCurrency;

    setLabel(
      !!currentCurrencySymbol ? (
        <div className="flex items-center justify-start">
          <span className="text-sm">{currentCurrencyLabel}</span>
          <span className="ml-1 mr-2 lg:mr-0">{currentCurrencySymbol}</span>
        </div>
      ) : (
        currentCurrencyLabel
      )
    );
  }, [currentCurrency]);

  return (
    <Dropdown className={cx('lg:mr-1', className)} hoverable>
      <Dropdown.Menu.Trigger
        className="w-full lg:w-auto"
        triggerClassName="justify-between w-full px-4 py-2 lg:px-2 focus:outline-none focus:shadow-outline lg:w-auto lg:rounded"
      >
        {label}
      </Dropdown.Menu.Trigger>
      <Dropdown.Menu
        className="w-full text-primary-900 lg:w-32 lg:mt-1"
        openTo={Dropdown.Menu.OpenTo.RIGHT}
      >
        {currenciesInfo.map(currency => (
          <Dropdown.Menu.Item
            isActive={currency.value === currentCurrency}
            className="flex items-center justify-start"
            key={currency.value}
            onClick={async () => {
              if (resolving) {
                return;
              }

              setCurrentCurrency(currency.value);
              if (cart) {
                const response = await updateCurrency(
                  {
                    data: {
                      currency: currency.value,
                      locale: currentLocale
                    }
                  },
                  `${baseUrl}/${cart.id}${pricingContextPath}`
                );

                if (!isEmpty(response)) {
                  setCart(response);
                }

                if (error) {
                  logError({
                    ...exception,
                    when: 'updating cart currency'
                  });
                }
              }
            }}
          >
            <span>{currency.label}</span>
            <span className="ml-1 mr-2 lg:mr-0">{currency.symbol}</span>
          </Dropdown.Menu.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

CurrencySelector.getCurrenciesInfo = (allowedCurrencies = []) => {
  return map(allowedCurrencies, currency => getCurrencyInfo(currency));
};

function getCurrencyInfo(currency) {
  return {
    label: currency,
    value: currency,
    symbol: CurrencySelector.getCurrencySymbol(currency)
  };
}

CurrencySelector.getCurrencySymbol = currency => {
  switch (currency) {
    case 'CAD':
      return 'C$';
    case 'MXN':
      return 'MX$';
    case 'USD':
      return '$';
    default:
      return '';
  }
};

export default CurrencySelector;
export { CurrencySelector };
