/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get } from 'lodash';

import { CartItemOptions, Currency, Icon, Link } from 'app/common/components';
import { useProductUrl } from 'app/common/hooks';
import notFound from 'app/common/img/image-not-found.jpg';

const OrderSummaryItem = props => {
  const { item } = props;
  const { uri } = item;
  const imageAsset = get(item, 'imageAsset') || { contentUrl: notFound };
  const url = useProductUrl(uri);

  const getQuantity = cartItem => {
    if (cartItem.quantity === 1) {
      return null;
    }
    return (
      <>
        <Icon className="px-1" name="times" size="xs" />
        <span className="text-sm">{cartItem.quantity}</span>
      </>
    );
  };

  return (
    <li className="flex mt-4 first:mt-0">
      <figure>
        <img
          className="rounded"
          alt={get(imageAsset, 'altText', `${item.name} Image`)}
          title={imageAsset.title}
          src={`${imageAsset.contentUrl}?product-xs`}
          height="96"
          width="96"
        />
      </figure>
      <div className="flex flex-col px-4">
        <Link to={url}> {item.name}</Link>
        <div>
          <CartItemOptions
            attributeChoices={item.attributeChoices}
            dependentCartItems={item.dependentCartItems}
          />
          <div>
            <Currency className="text-sm" {...item.unitPrice} />
            {getQuantity(item)}
          </div>
          <div className="mt-4">{/* Add any item level actions here */}</div>
        </div>
      </div>
    </li>
  );
};

export default OrderSummaryItem;
export { OrderSummaryItem };
