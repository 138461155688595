/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { Environment } from '../../services';

const DefaultPagingParams = {
  /**
   * whether the page should be built by results that <strong>follow</strong>
   * the `offset` or precede it. Thus, if the `offset` is 10, the `size` is 10,
   * and `forward` is `false`, then the returned page would include items 1-9.
   * Or, if `forward` is `true`, then the returned page would include items
   * 11-20.
   *
   * @type {boolean}
   */
  forward: true,
  /**
   * The starting index for the results. If you want to get page 2 and the `
   * size` is 10, then this would be 10 (`currentPage * size`).
   *
   * @type {number}
   */
  offset: 0,
  /**
   * The number of results to return in a page.
   *
   * @type {number}
   */
  size: Environment.get('DEFAULT_PAGE_SIZE', 50)
};

export default DefaultPagingParams;
export { DefaultPagingParams };
