/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext, useMemo } from 'react';
import { CampaignContext } from 'app/common/contexts';
import useCampaignInfo from 'app/common/hooks/campaign/useCampaignInfo';

const EmbeddedCampaign = () => {
  const { isCampaignFound, javascript, loading, isCampaignPath } =
    useContext(CampaignContext);
  return useMemo(
    () => CampaignContent(isCampaignFound, javascript, loading, isCampaignPath),
    [isCampaignFound, javascript, loading, isCampaignPath]
  );
};

const CampaignContent = (
  isCampaignFound,
  javascript,
  loading,
  isCampaignPath
) => {
  if (!isCampaignPath || loading || !javascript) {
    return <EmbeddedJavascript />;
  }
  if (isCampaignFound) {
    return <EmbeddedJavascript javascript={javascript} />;
  }
};

const EmbeddedJavascript = props => {
  const { javascript } = props;
  const { jsUrl } = useCampaignInfo();
  if (javascript) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = jsUrl + javascript;
    document.head.appendChild(script);
  }
  return null;
};

export default EmbeddedCampaign;
