/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { concat, get, isArray, isEmpty } from 'lodash';
import { default as qs } from 'query-string';

import { AuthContext } from 'app/auth/contexts';
import { CustomerContext, TenantContext } from 'app/common/contexts';
import {
  useHistory,
  useLocation,
  useNationalSiteContext,
  useRestApi,
  useTenantInfo
} from 'app/common/hooks';
import { useCustomerService } from 'app/common/hooks/customer';
import { Environment } from 'app/common/services';
import { logError } from 'app/common/utils/ApiErrorUtils';
import log from 'app/common/utils/Log';

const logger = log.getLogger('core.helpers.CustomerProvider');

const CustomerProvider = ({ children }) => {
  const [customer, setCustomer] = useCustomer();
  const fleetsAndTheirCustomers = useFleetCustomer();
  const context = React.useMemo(
    () => ({ customer, setCustomer, ...fleetsAndTheirCustomers }),
    [customer, setCustomer, fleetsAndTheirCustomers]
  );

  return (
    <CustomerContext.Provider value={context}>
      {children}
    </CustomerContext.Provider>
  );
};

function useCustomer() {
  const { isAuthenticated, isAuthenticating, user } = useContext(AuthContext);
  const { serviceId: customerId, id: userId } = user || {};
  const {
    application,
    resolving,
    requestedActive: isRequestedActive
  } = useContext(TenantContext);
  const { identifierValue, nationalSiteOrDealerNetwork: currentStoreBnsOrDn } =
    application || {};
  const { dealerNetwork: inDealerNetworkContext } = useNationalSiteContext();
  const history = useHistory();
  const location = useLocation();
  const { baseUrl } = useCustomerService();

  const [customer, setCustomer] = useState(undefined);

  const {
    error,
    loading,
    sendCallback: fetch
  } = useRestApi(undefined, undefined, false, true);

  // don't keep making requests if there's an error
  const [stop, setStop] = useState(error);

  useEffect(() => {
    if (!isAuthenticated) {
      setCustomer(undefined);
    }

    // reset if authentication state changes
    setStop(false);
  }, [isAuthenticated]);

  useEffect(() => {
    setStop(error);
  }, [error]);

  useEffect(() => {
    if (
      !stop &&
      !isAuthenticating &&
      isAuthenticated &&
      isEmpty(customer) &&
      !resolving &&
      location.pathname !== '/callback' &&
      !loading
    ) {
      if (isEmpty(customerId)) {
        logger.error(`ServiceId on User ${userId} was blank`);
        return;
      }

      const url = `${baseUrl}/${customerId}`;
      (async () => {
        try {
          const response = await fetch(undefined, url);
          const params = qs.parse(location.search);
          const shouldSetPreferred = shouldSetPreferredStore(
            inDealerNetworkContext,
            response,
            currentStoreBnsOrDn,
            isRequestedActive,
            identifierValue
          );
          if (shouldSetPreferred) {
            // don't set customer because it may cause API calls downstream that will
            // just be cancelled when the application/dealerLocation changes
            params[getApplicationParameter()] = response.preferredLocation;
            history.push({ search: qs.stringify(params) });
          } else {
            setCustomer(response);
          }
        } catch (exception) {
          logError({
            ...exception,
            when: `fetching Customer info for ${customerId}`
          });
          setStop(true);
        }
      })();
    }
    // eslint-disable-next-line
  }, [
    baseUrl,
    customer,
    customerId,
    fetch,
    isAuthenticated,
    isAuthenticating,
    resolving,
    stop,
    userId,
    location.pathname
  ]);

  return [customer, setCustomer];
}

function useFleetCustomer() {
  const { isAuthenticated, isAuthenticating, user } =
    React.useContext(AuthContext);
  const customerId = get(user, 'serviceId');
  const { baseUrl, fleetCustomersContextPath } = useCustomerService();
  const {
    error,
    exception,
    sendCallback: fetchFleetCustomers
  } = useRestApi(undefined, undefined, false);
  const { baseUrl: fleetsBaseUrl, fleetUri, fleetParam } = useTenantInfo();
  const conf = React.useMemo(
    () => ({ params: { [fleetParam]: true } }),
    [fleetParam]
  );
  const { sendCallback: fetchFleets, ...fleetsState } = useRestApi(
    `${fleetsBaseUrl}${fleetUri}`,
    conf,
    false
  );

  const [parts, setParts] = useState(undefined);
  const [discounts, setDiscounts] = useState(undefined);
  const [fleets, setFleets] = useState(undefined);

  useEffect(() => {
    if (isAuthenticating || !isAuthenticated || isEmpty(customerId)) {
      setParts(undefined);
      setDiscounts(undefined);
      setFleets(undefined);
      return;
    }

    (async () => {
      const url = `${baseUrl}/${customerId}${fleetCustomersContextPath}`;
      const fleetCustomersResponse = await fetchFleetCustomers(undefined, url);

      const partsResponse = get(fleetCustomersResponse, 'parts', []);
      const discountsResponse = get(fleetCustomersResponse, 'discounts', []);
      const fleetIds = concat(partsResponse, discountsResponse).map(
        ({ fleetId }) => fleetId
      );

      let partsFleets;
      let discountFleets;

      if (isEmpty(fleetIds)) {
        partsFleets = [];
        discountFleets = [];
      } else {
        const fleetsTypeMap = await fetchFleets();
        partsFleets = fleetsTypeMap.PARTS || [];
        discountFleets = fleetsTypeMap.DISCOUNTS || [];

        partsFleets = isArray(partsFleets) ? partsFleets : [partsFleets];
        discountFleets = isArray(discountFleets)
          ? discountFleets
          : [discountFleets];
      }

      const allFleets = concat(partsFleets, discountFleets);
      const fleetsMap = allFleets.reduce((acc, fleet) => {
        acc[fleet.id] = fleet;
        return acc;
      }, {});

      setFleets(partsFleets);
      setParts(
        partsResponse.map(partCustomer => {
          const fleet = fleetsMap[partCustomer.fleetId];
          return { ...partCustomer, fleet };
        })
      );
      setDiscounts(
        discountsResponse.map(discountCustomer => {
          const fleet = fleetsMap[discountCustomer.fleetId];
          return { ...discountCustomer, fleet };
        })
      );
    })();
  }, [
    baseUrl,
    customerId,
    fetchFleetCustomers,
    fetchFleets,
    fleetCustomersContextPath,
    isAuthenticating,
    isAuthenticated
  ]);

  useEffect(() => {
    if (error) {
      logError({
        ...exception,
        when: `fetching Fleet Customers for ${customerId}`
      });
    }
  }, [error, exception, customerId]);

  useEffect(() => {
    if (fleetsState.error) {
      logError({
        ...fleetsState.exception,
        when: 'fetching Fleets'
      });
    }
  }, [fleetsState.error, fleetsState.exception]);

  return useMemo(
    () => ({
      parts,
      setParts,
      discounts,
      setDiscounts,
      fleets,
      setFleets,
      fetchFleets
    }),
    [parts, discounts, fleets, fetchFleets]
  );
}

const PREFERRED_STORE_LOGIN_COOKIE = 'blc-set-preferred-store';

function shouldSetPreferredStore(
  inDealerNetworkContext,
  response,
  currentStoreBnsOrDn,
  isRequestedActive,
  identifierValue,
  currentStoreDnEnabled
) {
  const hasPreferredLocation = !isEmpty(response.preferredLocation);
  const currentIsPreferred = identifierValue === response.preferredLocation;
  const shouldNotSetPreferred = !hasPreferredLocation && !currentIsPreferred;

  // User doesn't have preferred store or is already on their preferred store
  if (shouldNotSetPreferred) {
    return false;
  }

  // User is on DN, so check if we should set the preferred store after logging in
  if (inDealerNetworkContext) {
    return isDealerNetworkLogin() || currentStoreDnEnabled || false;
  }

  // User already has store selected, so don't change it
  if (!currentStoreBnsOrDn) {
    return false;
  }

  // Check if current is active
  return isRequestedActive !== false;
}

/**
 * Check to see if we should set the user's store after login when user is
 * on a dealer network site.
 * @return {boolean} - Should we set the user's store after login?
 */
function isDealerNetworkLogin() {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(`${PREFERRED_STORE_LOGIN_COOKIE}=`))
    ?.split('=')[1];

  // Update the cookie to false so we don't change the store again
  document.cookie = `${PREFERRED_STORE_LOGIN_COOKIE}=false;path=/;max-age=31536000`;
  return cookieValue === 'true';
}

function getApplicationParameter() {
  return Environment.get(
    'tenant.resolver.application.parameter',
    'application'
  );
}

export default CustomerProvider;
export { CustomerProvider };
