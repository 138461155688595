/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { find, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import {
  useStateProvinceRegionSelectValues,
  useFormatMessage
} from 'app/common/hooks';

import messages from '../../PaymentFormReadOnly.messages';

const Billing = ({ billingAddress, className, truncateAddress = false }) => {
  const formatMessage = useFormatMessage();
  const allStateProvinceRegions = useStateProvinceRegionSelectValues();
  if (isEmpty(billingAddress)) {
    return null;
  }

  const relevantStateProvinceRegions =
    allStateProvinceRegions[get(billingAddress, 'country')];
  const stateProvinceRegion = get(
    find(relevantStateProvinceRegions, [
      'value',
      billingAddress.stateProvinceRegion
    ]),
    'value'
  );

  return (
    <section className={className}>
      <h3 className="mt-2 mb-1 text-base text-gray-900 font-bold md:mt-0">
        {formatMessage(messages.billingAddressLabel)}
      </h3>
      <div>
        {billingAddress.firstName} {billingAddress.lastName}
      </div>
      {truncateAddress ? (
        <div>{billingAddress.addressLine1}</div>
      ) : (
        <>
          {!isEmpty(billingAddress.companyName) && (
            <div>{billingAddress.companyName}</div>
          )}
          <div className="flex flex-wrap">
            <span>
              {billingAddress.addressLine1}
              {!isEmpty(billingAddress.addressLine2) && ',\u00A0'}
            </span>
            {!isEmpty(billingAddress.addressLine2) && (
              <span>{billingAddress.addressLine2}</span>
            )}
          </div>
          <div>
            {billingAddress.city} {stateProvinceRegion}{' '}
            {billingAddress.postalCode}
          </div>
          <div>{get(billingAddress, 'country')}</div>
        </>
      )}
    </section>
  );
};

Billing.propTypes = {
  billingAddress: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    companyName: PropTypes.string,
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string,
    city: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    stateProvinceRegion: PropTypes.string.isRequired
  }),
  /**
   * Whether to show a truncated form of the address such as just the name and
   * addressLine1 only.
   */
  truncateAddress: PropTypes.bool
};

export default Billing;
export { Billing };
