/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'MyAccount.ListDetails.title',
    defaultMessage: 'List Details'
  },
  backButton: {
    id: 'MyAccount.ListDetails.backButton',
    defaultMessage: 'Back to Lists'
  },
  successDuplicatesIgnored: {
    id: 'MyAccount.ListDetails.addToCart.success.duplicatesIgnored',
    defaultMessage:
      '{duplicates} {quantity, plural, one {is} other {are} } already in your cart and were not re-added'
  },
  success: {
    id: 'MyAccount.ListDetails.addToCart.success',
    defaultMessage:
      '{quantity} {quantity, plural, =0 {items} one {item} other {items} } successfully added to cart.'
  },
  error: {
    id: 'MyAccount.ListDetails.addToCart.error',
    defaultMessage:
      'We were unable to add the {quantity, plural, one {item} other {items} } to cart. Please try again later.'
  },
  successOrErrorMessageClose: {
    id: 'MyAccount.ListDetails.addToCart.message.close',
    defaultMessage: 'Dismiss Message'
  },
  loadingItems: {
    id: 'MyAccount.ListDetails.items.loading',
    defaultMessage: 'Please wait while we load your items'
  }
});
