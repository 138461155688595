/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { filter, findIndex, get, isEmpty, some } from 'lodash';

import { ProductOptionType } from 'app/common/constants';

/**
 * Determines whether the product still requires configuration.
 *
 * @param {{
 *   options: [{}]|null|undefined,
 *   variants: [{}]|null|undefined
 * }} product - Product to check
 * @param {{}} activeOption - Selected option based on the selected
 *     AttributeChoice ProductOption values
 * @param {{}|undefined} configuredItemChoices - Already configured item choices
 *
 * @return {boolean} Whether the product still requires configuration
 */
function useProductRequiresConfiguration(
  product,
  activeOption,
  configuredItemChoices
) {
  const [requiresConfig, setRequiresConfig] = React.useState(true);
  const requiredItemChoices = React.useMemo(
    () =>
      filter(product.options, o => o.type === ProductOptionType.ITEM_CHOICE)
        .map(o => o.itemChoice)
        .filter(ic => ic.minimumQuantity > 0),
    [product]
  );

  React.useEffect(() => {
    if (!isEmpty(product.variants) && isEmpty(activeOption)) {
      if (some(product.options, option => option.label === 'UOM')) {
        setRequiresConfig(false);
        return;
      }
      setRequiresConfig(true);
      return;
    }

    if (isEmpty(requiredItemChoices)) {
      setRequiresConfig(false);
      return;
    }

    setRequiresConfig(
      // requiredItemChoices contains a choice that is not in configuredItemChoices
      !!~findIndex(requiredItemChoices, required => {
        const { choiceKey, minimumQuantity } = required;

        return (
          !get(configuredItemChoices, choiceKey) ||
          get(configuredItemChoices, `${choiceKey}.quantity`) < minimumQuantity
        );
      })
    );
  }, [
    activeOption,
    configuredItemChoices,
    product.variants,
    product.options,
    requiredItemChoices
  ]);

  return requiresConfig;
}

export default useProductRequiresConfiguration;
