/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';
import { merge } from 'lodash';

import usePriceContextHeader from '../../pricing/usePriceContextHeader';
import usePriceInfoContextHeader from '../../pricing/usePriceInfoContextHeader';
import useRestApi from '../../useRestApi';
import useSearchInfo from '../useSearchInfo';

/**
 * Hook to use to send a request to the TypeAhead suggestions API.
 *
 * @param {Object} config - Object containing additional request config such as
 *     the request params or request method
 * @param {string} suggestConfig - the name of type ahead configuration. Defaults to 'productTypeAhead'.
 * @param {boolean} sendImmediate - Whether to send the request immediately upon
 *     using the hook. If false, then the caller is responsible for triggering
 *     the `sendCallback`. Defaults to `false`.
 *
 * @return {{error: bool, exception: Object, loading: bool, response: Object, sendCallback: function}}
 *     The fetch response
 */
function useTypeAheadSuggestionApi(
  config,
  suggestConfig,
  sendImmediate = false
) {
  const priceContextHeader = usePriceContextHeader();
  const priceInfoContextHeader = usePriceInfoContextHeader();
  const { baseUrl, catalogUri, suggestUri, suggestProductConfig } =
    useSearchInfo();
  const fetchUrl = `${baseUrl}${catalogUri}${suggestUri}`;
  const finalConfig = useMemo(
    () =>
      merge(
        {
          params: {
            config: suggestConfig || suggestProductConfig
          }
        },
        priceContextHeader,
        priceInfoContextHeader,
        config
      ),
    [
      config,
      priceContextHeader,
      priceInfoContextHeader,
      suggestConfig,
      suggestProductConfig
    ]
  );

  return useRestApi(fetchUrl, finalConfig, sendImmediate);
}

export default useTypeAheadSuggestionApi;
