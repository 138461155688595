const prefix = 'store-dropdown';

const OPEN_STORE_SEARCH = `${prefix}/OPEN_STORE_SEARCH`;
const CLOSE_STORE_SEARCH = `${prefix}/CLOSE_STORE_SEARCH`;
const OPEN_AUTO_COMPLETE = `${prefix}/OPEN_AUTO_COMPLETE`;
const CLOSE_AUTO_COMPLETE = `${prefix}/CLOSE_AUTO_COMPLETE`;
const SET_QUERY = `${prefix}/SET_QUERY`;
const SET_STORES = `${prefix}/SET_STORES`;
const SET_DEALER_NUMBER = `${prefix}/SET_DEALER_NUMBER`;
const SET_AUTO_COMPLETE_RESULTS = `${prefix}/SET_AUTO_COMPLETE_RESULTS`;
const SET_USING_LOC_COORDS = `${prefix}/SET_USING_LOC_COORDS`;
const SET_CURRENT_STORE = `${prefix}/SET_CURRENT_STORE`;
const HANDLE_ADDRESS_SELECT = `${prefix}/HANDLE_ADDRESS_SELECT`;
const APP_LOADED = `${prefix}/APP_LOADED`;
const SET_STATE = `${prefix}/SET_STATE`;
const LOADING_LOCATIONS_ACTION = `${prefix}/LOADING_LOCATIONS`;
const LOADING_LOCATIONS_COMPLETE_ACTION = `${prefix}/LOADING_LOCATIONS_COMPLETE`;
const SET_ERROR = `${prefix}/SET_ERROR`;
const RESET_ERRORS = `${prefix}/RESET_ERRORS`;

export {
  CLOSE_AUTO_COMPLETE,
  CLOSE_STORE_SEARCH,
  OPEN_AUTO_COMPLETE,
  OPEN_STORE_SEARCH,
  SET_AUTO_COMPLETE_RESULTS,
  SET_CURRENT_STORE,
  SET_DEALER_NUMBER,
  SET_QUERY,
  SET_STORES,
  SET_USING_LOC_COORDS,
  HANDLE_ADDRESS_SELECT,
  APP_LOADED,
  SET_STATE,
  LOADING_LOCATIONS_ACTION,
  LOADING_LOCATIONS_COMPLETE_ACTION,
  SET_ERROR,
  RESET_ERRORS
};
