/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { values } from 'lodash';
import PropTypes from 'prop-types';

import Button from '../Button';

/**
 * Generic secondary button. Takes additional class names to add to the base
 * ones and any props that would normally be applied to a button or link.
 * Content like text and icons should be passed as children.
 */
const SecondaryButton = ({
  active,
  children,
  className,
  backgroundColor = 'bg-white hover:bg-primary-100 focus:bg-primary-100 active:bg-primary-100 disabled:bg-white',
  borderColor = 'border-primary-200 disabled:border-gray-200',
  borderStyle = 'border border-solid rounded',
  textColor = 'text-primary-500 hover:text-primary-700 focus:text-primary-700 disabled:text-gray-400',
  fontStyle = 'capitalize font-bold leading-none',
  size = SecondaryButton.Size.BASE,
  onClick = () => {},
  ...props
}) => (
  <Button
    onClick={onClick}
    className={classNames(
      'inline-flex items-center justify-center',
      'appearance-none',
      'focus:outline-none disabled:cursor-not-allowed',
      {
        [className]: !!className,
        [backgroundColor]: !!backgroundColor,
        [borderColor]: !!borderColor,
        [textColor]: !!textColor,
        [fontStyle]: !!fontStyle,
        [borderStyle]: !!borderStyle,
        'p-3 text-base md:text-lg lg:text-xl':
          size === SecondaryButton.Size.LARGE,
        'p-2 text-sm sm:p-3 lg:text-base': size === SecondaryButton.Size.BASE,
        'p-2 text-xs lg:text-sm': size === SecondaryButton.Size.SMALL
      }
    )}
    {...props}
  >
    {children}
  </Button>
);

SecondaryButton.Size = Object.freeze({
  SMALL: 'sm',
  BASE: 'base',
  LARGE: 'lg'
});

SecondaryButton.propTypes = {
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  textColor: PropTypes.string,
  size: PropTypes.oneOf(values(SecondaryButton.Size))
};

export default SecondaryButton;
export { SecondaryButton };
