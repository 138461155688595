/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { ERROR, LOADING, REQUEST_PENDING, SUCCESS } from './restApiActionTypes';
import * as RequestStatus from 'app/common/hooks/useRestApi/RequestStatus';

const initialState = {
  /** Whether there was an error */
  error: false,
  /** The exception payload */
  exception: undefined,
  /** Whether the data is loading */
  loading: false,
  /** Response payload */
  response: undefined,
  /** Type of the action */
  type: REQUEST_PENDING,
  /** the request status */
  requestStatus: RequestStatus.PENDING
};

const emptyAction = {
  error: false,
  payload: undefined,
  type: REQUEST_PENDING,
  requestStatus: RequestStatus.PENDING
};

function reducer(
  state = initialState,
  {
    error = false,
    payload = undefined,
    type = REQUEST_PENDING,
    requestStatus = RequestStatus.PENDING
  } = emptyAction
) {
  switch (type) {
    case ERROR:
      return {
        ...state,
        error: true,
        exception: payload,
        loading: false,
        response: undefined,
        requestStatus: RequestStatus.COMPLETE
      };
    case LOADING:
      return {
        ...state,
        exception: undefined,
        error: false,
        loading: true,
        response: undefined,
        requestStatus: RequestStatus.IN_PROGRESS
      };
    case SUCCESS:
      return {
        ...state,
        exception: undefined,
        error: false,
        loading: false,
        response: payload,
        requestStatus: RequestStatus.COMPLETE
      };
    default:
      return state;
  }
}

export { reducer, initialState };
