/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { AuthContext } from 'app/auth/contexts';
import { AuthService } from 'app/auth/services';

/***
 * A wrapper for Route component that causes a route to only resolve if the user is currently authenticated.
 *
 * @param props - any props that need to be passed to the underlying Route
 */
const PrivateRoute = props => {
  const { hasAuthenticated, isAuthenticated, isAuthenticating } =
    React.useContext(AuthContext);

  React.useEffect(() => {
    if (hasAuthenticated && !isAuthenticated) {
      AuthService.loginWithRedirect();
    }
  }, [hasAuthenticated, isAuthenticated]);

  if (isAuthenticating || !isAuthenticated) {
    return <Route {...props} component={props.skeletonComponent} />;
  }

  return <Route {...props} />;
};

PrivateRoute.propTypes = {
  skeletonComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.element
  ])
};

export default PrivateRoute;
export { PrivateRoute };
