/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { values } from 'lodash';
import PropTypes from 'prop-types';

import { PriceType } from '../../constants';
import Currency from '../Currency';

/**
 * Render component for a price. This is a more specialized type of `Currency`,
 * and allows you specify the kind of price such as a 'sale' or whether it has
 * a sibling price that is a 'sale', which can lead to specialized styling.
 *
 * @visibleName Price
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const Price = ({
  className,
  price,
  hasSalePrice = false,
  priceType = PriceType.BASE_PRICE
}) => {
  return (
    <Currency
      className={classNames({
        [className]: !!className,
        'has-sale': hasSalePrice,
        'is-sale': PriceType.SALE_PRICE === priceType,
        'is-contract': PriceType.CONTRACT_PRICE === priceType,
        'is-standard': PriceType.STANDARD_PRICE === priceType,
        'is-base': PriceType.BASE_PRICE === priceType
      })}
      {...price}
    />
  );
};

Price.propTypes = {
  /** Additional class names to add to the component */
  className: PropTypes.string,
  /** The price currency and value */
  price: PropTypes.shape({
    currency: PropTypes.string,
    amount: PropTypes.number.isRequired
  }).isRequired,
  /**
   * Whether this price has a sibling price that is a sale price. Default value
   * is false.
   */
  hasSalePrice: PropTypes.bool,
  /** Whether this is a sale price. Default value is false. */
  priceType: PropTypes.oneOf(values(PriceType))
};

Price.PriceType = PriceType;

export default Price;
export { Price };
