/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Portal = props => {
  const { target } = props;

  const elRef = useRef(
    constructor !== undefined
      ? document.createElement(props.element)
      : undefined
  );

  useEffect(
    () => {
      const targetElement =
        typeof target === 'string' ? document.getElementById(target) : target;
      const el = elRef.current;

      if (targetElement) {
        targetElement.appendChild(el);
      }

      return () => {
        if (targetElement) {
          targetElement.removeChild(el);
        }
      };
    },
    //eslint-disable-next-line
    []
  );

  return ReactDOM.createPortal(props.children, elRef.current);
};

Portal.propTypes = {
  /** The children below the icon and heading, this can be any renderable object
   (string, component, etc) or a render function. */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /** The element to be used for the mounting element within the target DOM node.
   This is defaulted to 'div', but can set to 'span' or any other element. */
  element: PropTypes.string,
  /** The container to render the children into. Must be a DOM node, or the ID of a DOM node */
  target: PropTypes.oneOfType([PropTypes.instanceOf(Element), PropTypes.string])
};

Portal.defaultProps = {
  children: undefined,
  element: 'span',
  /** Protection for server-side rendering */
  target: document ? document.body : undefined
};

export default Portal;
