/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
const PaymentGatewayType = Object.freeze({
  PAYPAL_CHECKOUT: 'PAYPAL_CHECKOUT', // v1
  PAYPAL_CHECKOUT_V2: 'PAYPAL_CHECKOUT_V2',
  STRIPE: 'STRIPE'
});

export default PaymentGatewayType;
