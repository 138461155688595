/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { get, isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

import { AuthContext } from 'app/auth/contexts';
import {
  Icon,
  Modal,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton
} from 'app/common/components';
import {
  useCartInfo,
  useFormatMessage,
  useRestApi,
  useToggle
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import messages from './AddToListButton.messages';

const AddToListButton = ({ className, item }) => {
  const formatMessage = useFormatMessage();
  const { isAuthenticated } = React.useContext(AuthContext);
  const [open, toggleOpen] = useToggle();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      <AddModal item={item} open={open} toggleOpen={toggleOpen} />
      <TertiaryButton
        className={classNames('ml-4 first:ml-0', {
          [className]: !!className
        })}
        onClick={() => {
          toggleOpen();
        }}
        size={TertiaryButton.Size.SMALL}
      >
        {formatMessage(messages.label)}
      </TertiaryButton>
    </>
  );
};

const AddModal = ({ item, open = false, toggleOpen }) => {
  const formatMessage = useFormatMessage();
  const { user } = React.useContext(AuthContext);
  const [isSaveDisabled, toggleIsSaveDisabled] = useToggle(true);
  const customerRef = React.useMemo(
    () => ({
      customerId: get(user, 'serviceId'),
      username: get(user, 'username'),
      isRegistered: true,
      accountId: get(user, 'attributes[account_id]')
    }),
    [user]
  );
  const { productId, quantity, sku, variantId } = item;
  const {
    listOperations: { baseUrl, itemsContextPath }
  } = useCartInfo();
  const createConfig = React.useMemo(
    () => ({ method: 'post', params: customerRef }),
    [customerRef]
  );
  const { sendCallback: handleCreateList } = useRestApi(
    baseUrl,
    createConfig,
    false,
    true
  );
  const updateConfig = React.useMemo(
    () => ({ method: 'post', params: customerRef }),
    [customerRef]
  );
  const { sendCallback: handleUpdateList } = useRestApi(
    undefined,
    updateConfig,
    false,
    true
  );
  const fetchConfig = React.useMemo(
    () => ({
      params: {
        ...customerRef,
        offset: 0,
        forward: true,
        size: 50
      }
    }),
    [customerRef]
  );
  const {
    error: fetchError,
    exception: fetchException,
    sendCallback: fetchLists
  } = useRestApi(baseUrl, fetchConfig, false);
  const [selectedList, setSelectedList] = React.useState(undefined);
  const [lists, setLists] = React.useState([]);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState({});

  React.useEffect(() => {
    if (open) {
      fetchLists().then(data => {
        const listOptions = map(data.content, ({ id, name }) => ({
          value: id,
          label: name
        }));

        setLists(listOptions || []);
      });
    }
  }, [fetchLists, open]);

  React.useEffect(() => {
    if (fetchError) {
      logError({
        ...fetchException,
        when: 'fetching all of the lists'
      });
    }
  }, [fetchError, fetchException]);

  return (
    <Modal size={Modal.Size.MEDIUM} isOpen={open}>
      <Modal.Header>
        <Modal.Header.Title>
          <span className="lg:text-xl font-medium">
            {formatMessage(messages.title)}
          </span>
        </Modal.Header.Title>
        <Modal.Close
          onClose={() => {
            toggleOpen(false);
          }}
        />
      </Modal.Header>
      <Modal.Body className="bg-gray-100 text-gray-700">
        {success ? (
          <p className="text-green-700">
            {formatMessage(messages.success, { listName: selectedList.name })}
          </p>
        ) : (
          <>
            {!isEmpty(error) && (
              <aside className="flex items-center mb-4 px-2 py-1 text-sm text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
                <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
                <span>
                  {formatMessage(error, {
                    listName: selectedList.name
                  })}
                </span>
              </aside>
            )}
            <p className="mb-4 leading-normal">
              {formatMessage(messages.description)}
            </p>
            <div className="w-full lg:w-64">
              <CreatableSelect
                autoFocus
                aria-label={formatMessage(messages.createNewSelectPlaceholder)}
                isClearable
                isSearchable
                formatCreateLabel={newValue => {
                  return (
                    <span>
                      {formatMessage(messages.createNewSelectLabel, {
                        name: newValue
                      })}
                    </span>
                  );
                }}
                placeholder={formatMessage(messages.createNewSelectPlaceholder)}
                onChange={(newValue, meta) => {
                  if (newValue != null) {
                    toggleIsSaveDisabled(false);
                  } else {
                    toggleIsSaveDisabled(true);
                  }
                  const val = get(newValue, 'value', '');
                  if (meta.action === 'create-option') {
                    setSelectedList({ name: val });
                  } else {
                    setSelectedList({
                      id: val,
                      name: lists
                        .filter(({ value }) => value === val)
                        .map(({ label }) => label)[0]
                    });
                  }
                }}
                options={lists}
              />
            </div>
            <em className="block mt-4 leading-normal not-italic font-bold">
              {formatMessage(messages.caveat)}
            </em>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="flex items-center lg:justify-between">
        {success ? (
          <></>
        ) : (
          <>
            <SecondaryButton
              className="mr-4"
              onClick={() => {
                toggleOpen(false);
              }}
            >
              {formatMessage(messages.cancel)}
            </SecondaryButton>
            <PrimaryButton
              disabled={isSaveDisabled}
              onClick={async () => {
                toggleIsSaveDisabled(true);

                if (isEmpty(selectedList)) {
                  return;
                }

                if (isEmpty(selectedList.id)) {
                  try {
                    await handleCreateList({
                      data: {
                        name: selectedList.name,
                        attributes: {},
                        items: [
                          {
                            quantity,
                            itemSkuRef: { productId, variantId, sku },
                            attributes: {}
                          }
                        ]
                      }
                    });

                    setSuccess(true);

                    setTimeout(() => {
                      toggleOpen(false);
                      setSuccess(false);
                    }, 2000);
                  } catch (err) {
                    logError({
                      ...err,
                      when: `adding item ${productId} to a new list`
                    });

                    toggleIsSaveDisabled(false);

                    setError(messages.error);
                  }

                  return;
                }

                try {
                  await handleUpdateList(
                    {
                      data: {
                        quantity,
                        itemSkuRef: { productId, variantId, sku },
                        attributes: {}
                      }
                    },
                    `${baseUrl}/${selectedList.id}${itemsContextPath}`
                  );

                  setSuccess(true);

                  setTimeout(() => {
                    toggleOpen(false);
                    setSuccess(false);
                  }, 2000);
                } catch (err) {
                  if (err.type === 'SIMILAR_ITEM_ALREADY_ADDED_ERROR') {
                    toggleIsSaveDisabled(false);
                    setError(messages.duplicateError);
                    return;
                  }

                  logError({
                    ...err,
                    when: `adding item ${productId} to an existing list`
                  });
                  toggleIsSaveDisabled(false);
                  setError(messages.error);
                }
              }}
            >
              {formatMessage(messages.submit)}
            </PrimaryButton>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

AddToListButton.propTypes = {
  /** Additional class names to add to the component */
  className: PropTypes.string,
  item: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    productId: PropTypes.string,
    variantId: PropTypes.string,
    sku: PropTypes.string.isRequired
  }).isRequired
};

export default AddToListButton;
export { AddToListButton };
