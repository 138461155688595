/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  closeLabel: {
    id: 'Nav.Minicart.Dialog.Actions.closeLabel',
    defaultMessage: 'Continue Shopping'
  },
  viewCartLabel: {
    id: 'Nav.Minicart.Dialog.Actions.viewCartLabel',
    defaultMessage: 'View Cart'
  },
  checkoutLabel: {
    id: 'Nav.Minicart.Dialog.Actions.checkoutLabel',
    defaultMessage: 'Checkout'
  }
});
