import { useFormatMessage } from 'app/common/hooks';
import { Icon } from 'app/common/components';
import React from 'react';

const GeneralFormError = ({ error, isSubmitting, errorMessage }) => {
  const formatMessage = useFormatMessage();
  return (
    <>
      {error && !isSubmitting && (
        <aside
          className="flex items-center mb-4 px-2 py-1 text-sm
        text-red-600 leading-snug
        border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2
        lg:text-base lg:leading-normal"
        >
          <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
          <span>{formatMessage(errorMessage)}</span>
        </aside>
      )}
    </>
  );
};

export default GeneralFormError;
