/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { CartClient, PaymentClient } from '@broadleaf/commerce-cart';
import { clone } from 'lodash/lang';

import { LocaleContext } from 'app/common/contexts';
import { usePreviewState, useTenantContext } from 'app/common/hooks';
import { getDefaultHeaders } from 'app/common/utils/RequestUtils';

const ClientContext = React.createContext({
  cartClient: undefined,
  paymentClient: undefined
});

const useClientContext = () => React.useContext(ClientContext);

const ClientProvider = ({ children }) => {
  const { application, resolutionContext, tenant } = useTenantContext();
  const { id: tenantId } = tenant || {};
  const previewState = usePreviewState();
  const { id: applicationId, customerContextId } = application || {};
  const { currentLocale: locale } = React.useContext(LocaleContext);
  //In the nextjs, the basehost is an env variable set at NEXT_PUBLIC_CLIENT_BASE_HOST but we don't have that information here so we get the base host from window
  const baseHost = window.location.origin;

  const clientContext = React.useMemo(() => {
    const defaults = {
      headers: getDefaultHeaders({
        applicationId,
        customerContextId,
        locale,
        previewDate: previewState.previewDate,
        previewToken: previewState.token,
        resolutionContext,
        sandboxId: previewState.sandboxId,
        tenantId
      })
    };

    const options = { defaults, baseHost, locale };
    const paymentClient = new PaymentClient(clone(options));
    const cartClient = new CartClient(clone(options));

    return {
      paymentClient,
      cartClient
    };
  }, [
    baseHost,
    locale,
    applicationId,
    customerContextId,
    previewState,
    resolutionContext,
    tenantId
  ]);

  return (
    <ClientContext.Provider value={clientContext}>
      {children}
    </ClientContext.Provider>
  );
};

export const usePaymentClient = () => {
  return useClientContext().paymentClient;
};

export const useCartClient = () => {
  return useClientContext().cartClient;
};

export default ClientProvider;
export { ClientProvider };
