/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import { CartEmailForm } from 'app/checkout/components';
import { useCreateGuestToken, useFormatMessage } from 'app/common/hooks';

import messages from './GuestForm.messages';

/**
 * Render component for the "continue as guest" option for checkout.
 */
const GuestForm = () => {
  const formatMessage = useFormatMessage();
  const { sendCallback: guestTokenCallback } = useCreateGuestToken();

  return (
    <div className="flex flex-col w-full bg-gray-100 rounded shadow lg:flex-1 lg:w-1/2 lg:shadow-md">
      <div>
        <h2 className="px-4 py-2 text-lg capitalize font-bold bg-white rounded-t lg:px-6 lg:text-xl">
          {formatMessage(messages.title)}
        </h2>
        <p className="px-4 py-2 text-sm lg:px-6 lg:py-4 lg:text-base">
          {formatMessage(messages.subtitle)}
        </p>
      </div>
      <CartEmailForm
        className="pt-4 px-4 pb-6 lg:pt-2 lg:px-6"
        submitLabel={messages.submitLabel}
        guestTokenCallback={guestTokenCallback}
      />
    </div>
  );
};

export default GuestForm;
export { GuestForm };
