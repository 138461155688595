/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { default as qs } from 'query-string';

import { Environment, RequestService } from 'app/common/services';

/**
 * @typedef {Object} Tenant
 * @property {string} id - the ID
 * @property {string} name - the name
 * @property {string} defaultLocale - the default locale code
 * @property {Array.<string>} allowedLocales - the allowed locales codes
 * @property {string} defaultCurrency - the default currency
 * @property {Array.<string>} allowedCurrencies - the allowed currencies
 */

/**
 * @typedef {Object} Application
 * @property {string} id - the ID
 * @property {string} name - the name of the application. displayName should be used for display purposes when on a dealer network.
 * @property {string} identifierType - the identifier type
 * @property {string} identifierValue - the identifier value
 * @property {string} customerContextId - the customer context ID
 * @property {boolean} deactivated - the deactivated status
 * @property {string} defaultLocale - the default locale code
 * @property {Array.<string>} allowedLocales - the allowed locales codes
 * @property {string} defaultCurrency - the default currency
 * @property {Array.<string>} allowedCurrencies - the allowed currencies
 * @property {Object} logoAsset - the logo asset
 * @property {Object} attributes - the attributes
 * @property {Array.<Object>} isolatedCatalogs - the isolated catalogs
 * @property {Object} contextState - the context state
 * @property {string} accountNumber - the account number
 * @property {string} locationNumber - the location number
 * @property {string} line1 - address line 1
 * @property {string} city - the city
 * @property {string} state - the state
 * @property {string} zip - the postal code
 * @property {string} country - the country
 * @property {string} phoneNumber - the phone number
 * @property {number} latitude - the latitude
 * @property {number} longitude - the longitude
 * @property {string} escalationContactName - the escalation contact name
 * @property {string} escalationContactPhone - the escalation contact phone
 * @property {string} timeZoneOffset - the time zone offset
 * @property {Object} businessHours - the business hours
 * @property {Array.<string>} notificationEmails - the notification emails
 * @property {Array.<string>} notificationSmsNumbers - the notification SMS numbers
 * @property {string} externalId - the external ID
 * @property {string} fullDomain - the full domain
 * @property {string} domainType - the domain type
 * @property {string} resolutionContext - the resolution context
 * @property {string} aboutSectionTitle - the about section title
 * @property {string} aboutSection1 - the about section 1
 * @property {string} aboutSection2 - the about section 2
 * @property {Object} primaryColor - the primary color
 * @property {boolean} nationalSite - the national site status
 * @property {boolean} dealerNetwork - the dealer network status
 * @property {string} dealerNetworkId - the dealer network ID
 * @property {boolean} dealerNetworkStoreEnabled - the dealer network store enabled status
 * @property {Object} favicon - the favicon
 * @property {string} siteTitle - the site title
 * @property {string} siteDescription - the site description
 * @property {string} customDisplayName - the custom display name
 * @property {string} displayName - the display name
 * @property {boolean} nationalSiteOrDealerNetwork - the national site or dealer network status
 * @property {boolean} marketplace - the marketplace status
 */

/**
 *
 */

/**
 * @typedef {Object} ApplicationResolverResponse
 * @property {Tenant} tenant - the resolved {@link Tenant}
 * @property {Application} application - the resolved {@link Application}
 */

/**
 * Retrieves the {@link ApplicationResolverResponse} details for the current browser location.
 *
 * ```js
 * try {
 *   const { application, tenant } = await resolveApplication();
 *
 *   // do stuff
 * } catch (error) {
 *   // handle error
 * }
 * ```
 *
 * @param {string|undefined} [identifier] - The site identifier if refreshing
 *     to avoid using the default
 * @param {string} [locale] - Locale to include for the request for translations
 * @param {string} [currency] - Currency to include for the request for conversions
 *
 * @returns {Promise<ApplicationResolverResponse>}
 */
export async function resolveApplication(
  identifier,
  locale = undefined,
  currency = undefined
) {
  const url = new URL(window.location.href);
  const search = qs.parse(window.location.search);
  search[getApplicationParameter()] = identifier;
  url.search = qs.stringify(search);

  const { data } = await RequestService.get(
    {
      params: { url: url.href },
      secure: false,
      url: getApplicationResolverUrl()
    },
    { locale, currency }
  );

  return data;
}

function getApplicationResolverUrl() {
  return Environment.get(
    'tenant.resolver.application.url',
    '/tenant/resolver/application'
  );
}

function getApplicationParameter() {
  return Environment.get(
    'tenant.resolver.application.parameter',
    'application'
  );
}

const exports = {
  resolveApplication
};

export default exports;
