/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  cvvLabel: {
    id: 'Checkout.Payment.Form.cvv.label',
    defaultMessage: 'Security Code (CVV, CVC)'
  },
  cvvHint: {
    id: 'Checkout.Payment.Form.cvv.hint',
    defaultMessage: `{activeCardType, select, 
                       AMEX {4 digit code, printed on the front of the card}
                       DISCOVER {3 digit code, printed on the back of the card}
                       MASTERCARD {3 digit code, printed on the back of the card}
                       VISA {3 digit code, printed on the back of the card}
                       other {3-4 digit code, usually printed on the back of the card}
                     }`
  },
  cvvRequired: {
    id: 'Checkout.Payment.Form.cvv.required',
    defaultMessage: 'Security code is required.'
  }
});
