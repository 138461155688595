/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';

import { AssetType } from 'app/common/constants';
import notFound from 'app/common/img/image-not-found.jpg';
import { addParams } from 'app/common/utils/PathUtils';
import { Modal, SecondaryButton } from 'app/common/components/index.js';
import { useFormatMessage, useToggle } from 'app/common/hooks/index.js';
import messages from 'app/common/components/ItemAvailability/components/NearestAvailableButton/NearestAvailableButton.messages.js';

/**
 * Render component for a product's primary asset.
 *
 * This includes the ability to zoom in on an image while dragging the mouse.
 * This is done by setting the asset's source URL as a background image. It's
 * important to make sure the background image is larger than the original,
 * which can be accomplished with either image effect tags (e.g.,
 * `/asset-url.jpg?zoomed` to create a scaled up version of the original) or by
 * adding another ProductAsset that can be retrieved by a predefined tag or URL
 * suffix. By default, the former option (image effect tag) is supported. When
 * the user mouses-down and drags, the background image will appear and its
 * position will change according to where the user is dragging the mouse.
 *
 * @visibleName Product Detail Page: Asset: Primary
 * @author [Nathan Moore](https://github.com/nathandmoore)
 *
 * @todo: handle more than just images
 */
const PrimaryAsset = props => {
  const { altText, title, contentUrl: src = notFound } = props;

  const [open, toggleOpen] = useToggle();

  return (
    <>
      <figure className="w-full rounded pdp-img-container">
        <picture className="group block rounded w-full">
          <source
            media="(max-width: 480px)"
            srcSet={`${addParams(src, { 'product-xs': true })} 240w`}
          />
          <source
            media="(min-width: 481px) and (max-width: 768px)"
            srcSet={`${addParams(src, { 'product-xs': true })} 240w,
                 ${addParams(src, { 'product-sm': true })} 480w`}
          />
          <source
            media="(min-width: 769px) and (max-width: 1215px)"
            srcSet={`${addParams(src, { 'product-xs': true })} 240w,
                 ${addParams(src, { 'product-sm': true })} 480w,
                 ${addParams(src, { 'product-md': true })} 768w`}
          />
          <source
            media="(min-width: 1216px) and (max-width: 1408px)"
            srcSet={`${addParams(src, { 'product-xs': true })} 240w,
                 ${addParams(src, { 'product-sm': true })} 480w,
                 ${addParams(src, { 'product-md': true })} 768w,
                 ${addParams(src, { 'product-lg': true })} 1024w`}
          />
          <source
            media="(min-width: 1408px)"
            srcSet={`${addParams(src, { 'product-xs': true })} 240w,
                 ${addParams(src, { 'product-sm': true })} 480w,
                 ${addParams(src, { 'product-md': true })} 768w,
                 ${addParams(src, { 'product-lg': true })} 1024w,
                 ${addParams(src, { 'product-xl': true })} 1216w`}
          />
          <a
            onClick={() => {
              toggleOpen();
            }}
            href="#"
          >
            <img
              alt={altText}
              className="h-auto rounded bg-white pdp-img mx-auto"
              id="primary"
              src={`${addParams(src, { 'product-xs': true })}`}
              title={title}
            />
          </a>
        </picture>
      </figure>
      <PdpImgModal
        imgSrc={src}
        title={title}
        altText={altText}
        open={open}
        toggleOpen={toggleOpen}
      />
    </>
  );
};

const PdpImgModal = ({ imgSrc, title, altText, open = false, toggleOpen }) => {
  const modalRef = useRef(null);
  return (
    <Modal ref={modalRef} size={Modal.Size.LARGE} isOpen={open}>
      <PdpImgModalHeader title={title} toggleOpen={toggleOpen} />
      <Modal.Body className="bg-gray-100 text-gray-700">
        <img src={imgSrc} alt={altText} title={title} className="mx-auto" />
      </Modal.Body>
      <PdpImgModalFooter
        onClick={() => {
          toggleOpen(false);
        }}
      />
    </Modal>
  );
};

const PdpImgModalHeader = ({ title, toggleOpen }) => {
  return (
    <Modal.Header className="flex">
      <Modal.Header.Title>{title}</Modal.Header.Title>
      <Modal.Close
        onClose={() => {
          toggleOpen(false);
        }}
      />
    </Modal.Header>
  );
};

const PdpImgModalFooter = ({ onClick }) => {
  const formatMessage = useFormatMessage();
  return (
    <Modal.Footer className="flex items-center lg:justify-between">
      <SecondaryButton className="mr-4" onClick={onClick}>
        {formatMessage(messages.modalCancel)}
      </SecondaryButton>
    </Modal.Footer>
  );
};

PrimaryAsset.propTypes = {
  altText: PropTypes.string,
  /** HTML to embed in place of having a source URL */
  embedCode: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(values(AssetType)),
  contentUrl: PropTypes.string
};

export default PrimaryAsset;
export { PrimaryAsset };
