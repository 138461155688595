/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { get, isEmpty, join } from 'lodash';
import PropTypes from 'prop-types';
import TagManager from 'app/common/tagmanager';

import { TertiaryButton } from 'app/common/components';
import { CartContext } from 'app/common/contexts';
import { useCartItemApi, useFormatMessage } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import messages from './RemoveItemButton.messages';

/**
 * Render component for the remove cart item button.
 *
 * @visibleName Cart: Item: Remove Button
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const RemoveItemButton = ({ className, id, cartItem, position }) => {
  const formatMessage = useFormatMessage();
  const removeConfig = useMemo(
    () => ({
      method: 'del',
      params: {
        price: true
      }
    }),
    []
  );
  const { cart, setCart } = useContext(CartContext);
  const {
    exception: rmvException,
    error: rmvErr,
    sendCallback: handleRemove
  } = useCartItemApi(id, removeConfig, false);

  if (rmvErr) {
    logError({ ...rmvException, when: `removing item ${id} from cart` });
  }

  return (
    <TertiaryButton
      className={classNames('ml-4 first:ml-0', {
        [className]: !!className
      })}
      colorClassName="text-red-700 hover:text-red-800 focus:text-red-800 active:text-red-600 disabled:text-gray-400"
      size={TertiaryButton.Size.SMALL}
      onClick={() =>
        handleRemove().then(data => {
          TagManager.dataLayer({
            event: 'removeFromCart',
            ecommerce: {
              currencyCode: get(cart, 'cartPricing.currency', 'USD'),
              remove: {
                products: [
                  {
                    brand: get(cartItem, 'attributes.productBrand.value'),
                    category: join(
                      get(cartItem, 'attributes.categoryNames', []),
                      ', '
                    ),
                    id: cartItem.sku,
                    name: get(cartItem, 'attributes.productDescription'),
                    position,
                    price: get(cartItem, 'unitPrice.amount', 0.0),
                    quantity: cartItem.quantity
                  }
                ]
              }
            }
          });

          if (!isEmpty(data)) {
            setCart(data);
          }
        })
      }
    >
      {formatMessage(messages.removeLabel)}
    </TertiaryButton>
  );
};

RemoveItemButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired
};

export default RemoveItemButton;
export { RemoveItemButton };
