/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
const prefix = 'configurableItem';
const VARIANT_ATTRIBUTE_CHOICE = `${prefix}/variantAttributeChoice`;
const ITEM_CHOICE = `${prefix}/itemChoice`;
const CART_ITEM_ATTRIBUTE_CHOICE = `${prefix}/cartItemAttributeChoice`;

export { VARIANT_ATTRIBUTE_CHOICE, ITEM_CHOICE, CART_ITEM_ATTRIBUTE_CHOICE };
