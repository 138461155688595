/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  accountCreationPrompt: {
    id: 'Auth.Register.accountCreationPrompt',
    defaultMessage: 'Create your account'
  },
  successMessage: {
    id: 'Auth.Register.successMessage',
    defaultMessage:
      'You will receive an email shortly with a link to set up your password.'
  },
  errorMessageGlobal: {
    id: 'Auth.Register.errorMessage.global',
    defaultMessage:
      'We encountered a problem processing your registration request. Please try again later.'
  },
  errorMessageValidation: {
    id: 'Auth.Register.errorMessage.validation',
    defaultMessage: 'Please correct the errors below.'
  }
});
