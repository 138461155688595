import { useFormatMessage } from 'app/common/hooks';
import React, { useContext, useEffect } from 'react';
import { AuthContext } from 'app/auth/contexts';
import {
  BulkAddToListButton,
  Icon,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton
} from 'app/common/components';
import messages from '../../NearestAvailableButton.messages';
import { AuthService } from 'app/auth/services';
import { SET_UNAVAILABLE_ITEMS } from '../../reducer/nearestInventoryReducer';

/**
 *
 * @param handleSwitch Handle the switch to the nearest available store
 * @param parentRef The parent element ref
 * @param {NearestInventoryState} state The nearest inventory state
 * @param dispatch The nearest inventory dispatch
 */
const UnavailableItemsWarning = ({
  handleSwitch,
  parentRef,
  state,
  dispatch
}) => {
  const formatMessage = useFormatMessage();
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (!!parentRef) {
      parentRef.current.scrollTop = 0;
    }
  }, [parentRef, state.unavailableItems]);

  return (
    <div className="flex items-center mt-0 mb-4 px-2 py-1 text-sm text-yellow-700 leading-snug border border-solid border-yellow-400 bg-yellow-100 rounded md:px-4 md:py-2 lg:mb-6 lg:text-base lg:leading-normal">
      <Icon className="mr-2 md:mr-4" name="exclamation-triangle" />
      <div className="w-full">
        <div className="flex items-center justify-between font-medium">
          <div>
            <div>
              {formatMessage(messages.modalOtherItemsUnavailableCaveat)}
            </div>
            <ol className="flex flex-col pl-6 mt-1 leading-normal list-disc">
              {state.unavailableItems.map(({ attributes: { sku } }) => (
                <li key={sku}>{sku}</li>
              ))}
            </ol>
          </div>
          {isAuthenticated && (
            <BulkAddToListButton
              autoClose={false}
              buttonComponent={PrimaryButton}
              buttonSize={PrimaryButton.Size.BASE}
              className="ml-2 whitespace-no-wrap"
              handleSuccess={() => {
                handleSwitch();
              }}
              selectedItems={state.unavailableItems}
            />
          )}
        </div>
        {!isAuthenticated && (
          <div>
            <div className="mb-4">
              {formatMessage(
                messages.modalSaveUnavailableItemsBeforeSwitchPrompt
              )}
            </div>
            <div className="flex justify-end">
              <SecondaryButton className="mr-4" to="/register">
                {formatMessage(messages.modalSaveUnavailableItemsRegisterLabel)}
              </SecondaryButton>
              <PrimaryButton
                onClick={() => {
                  AuthService.loginWithRedirect();
                }}
              >
                {formatMessage(messages.modalSaveUnavailableItemsSignInLabel)}
              </PrimaryButton>
            </div>
          </div>
        )}
        <div className="flex items-center mt-4 mb-2 uppercase text-center font-bold">
          <span className="flex-grow border-b border-yellow-400" />
          <span className="mx-4">
            {formatMessage(messages.modalUnavailableItemsOptionsOr)}
          </span>
          <span className="flex-grow border-b border-yellow-400" />
        </div>
        <div className="flex justify-end mb-2">
          <TertiaryButton
            colorClassName="text-red-700 hover:text-red-800 focus:text-red-800 active:text-red-600 disabled:text-gray-400"
            onClick={() => dispatch(SET_UNAVAILABLE_ITEMS(undefined))}
          >
            {formatMessage(messages.modalDontSwitchIfItemsNotAvailable)}
          </TertiaryButton>
          <SecondaryButton
            className="ml-4"
            onClick={() => {
              dispatch(SET_UNAVAILABLE_ITEMS(undefined));
              handleSwitch(false);
            }}
          >
            {formatMessage(messages.modalUnavailableItemsOptionsSwitchAnyway)}
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export { UnavailableItemsWarning };
