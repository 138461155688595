/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { ErrorMessage } from 'formik';

import { DecoratedField, SelectField } from 'app/common/components';
import { PhoneType } from 'app/common/constants';
import { useFormatMessage } from 'app/common/hooks';

import messages from './Phone.messages';

const Phone = ({ isPrimary = false, phoneNamePrefix, ...props }) => {
  const formatMessage = useFormatMessage();
  const phoneTypeName = phoneNamePrefix
    ? phoneNamePrefix + '.phoneType'
    : props.name + 'Type';
  const phoneValueOptions = isPrimary
    ? [PhoneType.LANDLINE, PhoneType.MOBILE]
    : [PhoneType.LANDLINE, PhoneType.MOBILE, PhoneType.FAX];
  return (
    <fieldset className="flex w-full mb-4 sm:mb-0">
      <legend className="sr-only">{props.label}</legend>
      <div className="flex flex-col flex-grow sm:flex-row">
        <DecoratedField
          {...props}
          containerWidths="flex-grow lg:w-auto lg:flex-grow-0"
          showErrors={false}
        />
        <ErrorMessage name={props.name}>
          {msg => (
            <div className="block -mt-3 mb-4 text-sm text-red-600 sm:hidden">
              {msg}
            </div>
          )}
        </ErrorMessage>
        <DecoratedField
          className="ml-0 sm:ml-4"
          component={SelectField}
          containerWidths="w-auto"
          disabled={props.disabled}
          id={`${props.id}-type`}
          label={formatMessage(messages.contactPhoneType)}
          labelProps={{
            textSizes: 'text-xs'
          }}
          name={phoneTypeName}
          widths="w-full sm:w-32"
          valueOptions={phoneValueOptions}
        />
      </div>
      <ErrorMessage name={props.name}>
        {msg => (
          <div className="hidden -mt-3 mb-4 text-sm text-red-600 sm:block lg:text-base">
            {msg}
          </div>
        )}
      </ErrorMessage>
    </fieldset>
  );
};

export default Phone;
export { Phone };
