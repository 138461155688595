/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  addToCartLabel: {
    id: 'AddToCartButton.label',
    defaultMessage: 'Add to Cart'
  },
  addToCartError: {
    id: 'AddToCartError',
    defaultMessage:
      'Something went wrong while trying to add this product to your cart.  Please wait and try again later.'
  },
  weightLabel: {
    id: 'DefaultPDP.weight.label',
    defaultMessage: 'Weight:'
  },
  weightValue: {
    id: 'DefaultPDP.weight.value',
    defaultMessage:
      '{value, number} {units, select, POUNDS {lb} KILOGRAMS {kg} OUNCES {oz} GRAMS {g} other { } }'
  },
  height: {
    id: 'DefaultPDP.height.label',
    defaultMessage: 'Height:'
  },
  width: {
    id: 'DefaultPDP.width.label',
    defaultMessage: 'Width:'
  },
  depth: {
    id: 'DefaultPDP.depth.label',
    defaultMessage: 'Depth:'
  },
  dimensionValue: {
    id: 'DefaultPDP.dimension.value',
    defaultMessage:
      '{value, number} {units, select, MILLIMETERS {mm} CENTIMETERS {cm} INCHES {in} FEET {ft} METERS {m} other { } }'
  },
  specifications: {
    id: 'DefaultPDP.specifications.label',
    defaultMessage: 'Specifications'
  },
  description: {
    id: 'DefaultPDP.description.label',
    defaultMessage: 'Description'
  },
  duplicateItemError: {
    id: 'AddToCartError.duplicate',
    defaultMessage: 'This item is already in the cart.'
  },
  selectOptionLabel: {
    id: 'AddToCartButton.selectLabel',
    defaultMessage: 'Select Options'
  },
  documents: {
    id: 'DefaultPDP.documents.label',
    defaultMessage: 'Documents'
  }
});
