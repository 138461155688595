/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty, join } from 'lodash';
import PropTypes from 'prop-types';
import TagManager from 'app/common/tagmanager';

import { CartContext } from 'app/common/contexts';
import { useCartItemApi, useFormatMessage } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import messages from './ItemActions.messages';

/**
 * Render component for cart item's action such as remove or edit.
 */
const ItemActions = ({ id, cartItem, position }) => {
  const formatMessage = useFormatMessage();
  const removeConfig = React.useMemo(
    () => ({
      method: 'del',
      params: {
        price: true
      }
    }),
    []
  );
  const { setCart } = React.useContext(CartContext);
  const {
    exception: rmvException,
    error: rmvErr,
    sendCallback: handleRemove
  } = useCartItemApi(id, removeConfig, false);

  if (rmvErr) {
    logError({ ...rmvException, when: `removing item ${id} from cart` });
  }

  return (
    <footer className="flex justify-between mt-1">
      <button
        className="text-sm text-gray-500 hover:text-gray-600"
        onClick={() =>
          handleRemove().then(data => {
            if (!isEmpty(data)) {
              TagManager.dataLayer({
                event: 'removeFromCart',
                ecommerce: {
                  currencyCode: get(
                    data,
                    'cartPricing.subtotal.currency',
                    'USD'
                  ),
                  remove: {
                    products: [
                      {
                        brand: get(cartItem, 'attributes.productBrand.value'),
                        category: join(
                          get(cartItem, 'attributes.categoryNames', []),
                          ', '
                        ),
                        id: cartItem.sku,
                        name: get(cartItem, 'attributes.productDescription'),
                        position,
                        price: get(cartItem, 'unitPrice.amount', 0.0),
                        quantity: cartItem.quantity
                      }
                    ]
                  }
                }
              });

              setCart(data);
            }
          })
        }
        type="button"
      >
        {formatMessage(messages.deleteLabel)}
      </button>
    </footer>
  );
};

ItemActions.propTypes = {
  /** ID of the cart item */
  id: PropTypes.string.isRequired
};

export default ItemActions;
export { ItemActions };
