/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext } from 'react';
import { get, isEmpty, isEqual, isNil } from 'lodash';

import { CoreCost, Currency } from 'app/common/components';
import { CartContext } from 'app/common/contexts';
import { CSREditFulfillmentPrice } from 'app/csr/components';
import { useFormatMessage } from 'app/common/hooks';
import { calculateFulfillmentGroupAdjustmentsTotal } from 'app/common/utils/CartPricingUtils';

import messages from './CartTotals.messages';
import * as PropTypes from 'prop-types';
import environment from 'app/common/services/Environment/Environment';

const CartTotals = ({ isCheckout = false }) => {
  const formatMessage = useFormatMessage();
  const { cart = {} } = useContext(CartContext);
  const { currency, totalTax, subtotal, total, totalCoreCost } = get(
    cart,
    'cartPricing',
    {}
  );
  const { displaySubtotal, displayAdjustmentsTotal } = cart;
  const fulfillmentGroup = get(cart, 'fulfillmentGroups[0]');
  const fulfillmentAdjustments =
    calculateFulfillmentGroupAdjustmentsTotal(fulfillmentGroup);
  const totalFulfillmentPrice = getTotalFulfillmentPrice(fulfillmentGroup);
  let taxCalculated = null;

  if (
    fulfillmentGroup &&
    (fulfillmentGroup.attributes || fulfillmentGroup.internalAttributes)
  ) {
    taxCalculated = get(fulfillmentGroup, 'attributes.taxProvider');

    if (!taxCalculated) {
      taxCalculated = get(cart, 'internalAttributes.calculationTaxProviderId');
    }
  }
  return (
    <ol className="flex flex-col list-none">
      <CartSubtotal
        label={formatMessage(messages.subtotal)}
        value={displaySubtotal}
        currency={currency}
      />
      <CoreCostTotal
        value={totalCoreCost}
        label={formatMessage(messages.totalCoreCost)}
      />
      <AdjustmentsTotal
        value={displayAdjustmentsTotal}
        label={formatMessage(messages.adjustments)}
      />
      <FulfillmentTotal
        deliveryLabel={formatMessage(messages.delivery)}
        deliveryTbdLabel={formatMessage(messages.deliveryTbd)}
        checkout={isCheckout}
        value={fulfillmentGroup}
        totalFulfillmentPrice={totalFulfillmentPrice}
      />
      <FulfillmentAdjustmentsTotal
        checkout={isCheckout}
        value={fulfillmentAdjustments}
        label={formatMessage(messages.fulfillmentAdjustments)}
      />
      <CartTaxes
        taxesLabel={formatMessage(messages.taxes)}
        taxCalculated={taxCalculated}
        value={totalTax}
        taxesLabelTbd={formatMessage(messages.taxesTbd)}
      />
      <CartTotal
        checkout={isCheckout}
        totalLabel={formatMessage(messages.total)}
        estimatedTotalLabel={formatMessage(messages.estimatedTotal)}
        value={total}
        value1={subtotal}
        currency={currency}
      />
    </ol>
  );
};

const CartTotal = props => {
  return (
    <li id="cart-total" className="bg-white rounded-b">
      <strong className="flex justify-between px-4 py-2 text-lg font-bold capitalize">
        <div className="mr-2 capitalize basis-3/5 flex-grow flex-shrink">
          {props.checkout ? props.totalLabel : props.estimatedTotalLabel}
        </div>
        <div className="basis-2/5 flex-grow flex-shrink text-right">
          {!isEmpty(props.value) && <Currency {...props.value} />}
          {isEmpty(props.value) && !isEmpty(props.value1) && (
            <Currency {...props.value1} />
          )}
          {isEmpty(props.value) && isEmpty(props.value1) && (
            <Currency amount={0} currency={props.currency} />
          )}
        </div>
      </strong>
    </li>
  );
};

const FulfillmentTotal = props => {
  const { totalFulfillmentPrice } = props;
  return (
    <li id="cart-fulfillment" className="mb-4">
      <div className="flex justify-between px-4 capitalize">
        <div className="mr-2 capitalize basis-3/5 flex-grow flex-shrink">
          {props.deliveryLabel}
        </div>
        <div className="basis-2/5 flex-grow flex-shrink text-right">
          {totalFulfillmentPrice ? (
            <Currency
              amount={totalFulfillmentPrice.amount}
              currency={totalFulfillmentPrice.currency}
            />
          ) : (
            props.deliveryTbdLabel
          )}
        </div>
      </div>
      {props.checkout && !isNil(props.value) && (
        <CSREditFulfillmentPrice
          className="flex justify-end py-2 mr-4"
          fulfillmentGroup={props.value}
        />
      )}
    </li>
  );
};

const FulfillmentAdjustmentsTotal = props => {
  return (
    <>
      {props.checkout && !isEmpty(props.value) && props.value.amount > 0 && (
        <li id="cart-fulfillment-adjustments" className="mb-4">
          <em className="flex justify-between px-4 not-italic capitalize text-red-600">
            <div className="mr-2 capitalize basis-3/5 flex-grow flex-shrink">
              {props.label}
            </div>
            <div className="basis-2/5 flex-grow flex-shrink text-right">
              {'-'}
              <Currency {...props.value} />
            </div>
          </em>
        </li>
      )}
    </>
  );
};

const CartTaxes = props => {
  return (
    <li id="cart-taxes" className="mb-4">
      <div className="flex justify-between px-4 capitalize">
        <div className="mr-2 capitalize basis-3/5 flex-grow flex-shrink">
          {props.taxesLabel}
        </div>
        <div className="basis-2/5 flex-grow flex-shrink text-right">
          {props.taxCalculated ? (
            isEmpty(props.value) ? (
              props.taxesLabelTbd
            ) : (
              <Currency {...props.value} />
            )
          ) : (
            props.taxesLabelTbd
          )}
        </div>
      </div>
    </li>
  );
};

const AdjustmentsTotal = props => {
  return (
    <>
      {!isEmpty(props.value) && props.value.amount > 0 && (
        <li id="cart-adjustments" className="mb-4">
          <em className="flex justify-between px-4 not-italic capitalize text-red-600">
            <div className="mr-2 capitalize basis-3/5 flex-grow flex-shrink">
              {props.label}
            </div>
            <div className="basis-2/5 flex-grow flex-shrink text-right">
              {'-'}
              <Currency {...props.value} />
            </div>
          </em>
        </li>
      )}
    </>
  );
};

const CartSubtotal = props => {
  return (
    <li id="cart-subtotal" className="mb-4">
      <em className="flex justify-between px-4 not-italic capitalize">
        <div className="mr-2 capitalize basis-3/5 flex-grow flex-shrink">
          {props.label}
        </div>
        <div className="basis-2/5 flex-grow flex-shrink text-right">
          {!isEmpty(props.value) && <Currency {...props.value} />}
          {isEmpty(props.value) && (
            <Currency amount={0} currency={props.currency} />
          )}
        </div>
      </em>
    </li>
  );
};

const CoreCostTotal = props => {
  return (
    <>
      {!isEmpty(props.value) && !isEqual(get(props.value, 'amount'), 0) && (
        <li id="cart-totalcorecost" className="mb-4">
          <em className="flex justify-between px-4 not-italic capitalize">
            <div className="mr-2 capitalize basis-3/5 flex-grow flex-shrink">
              {props.label}
            </div>
            <CoreCost
              className="basis-2/5 flex-grow flex-shrink text-right"
              coreCost={props.value}
            />
          </em>
        </li>
      )}
    </>
  );
};

function getTotalFulfillmentPrice(fulfillmentGroup) {
  if (!isFulfillmentServiceEnabled()) {
    return null;
  }
  return get(fulfillmentGroup, 'totalFulfillmentPrice');
}

function isFulfillmentServiceEnabled() {
  return environment.get('FULFILLMENT_SERVICE_ENABLED', 'true') === 'true';
}

CartTotal.propTypes = {
  checkout: PropTypes.bool,
  formatMessage: PropTypes.any,
  formatMessage1: PropTypes.any,
  value: PropTypes.any,
  value1: PropTypes.any,
  currency: PropTypes.any
};

FulfillmentTotal.propTypes = {
  formatMessage: PropTypes.any,
  formatMessage1: PropTypes.any,
  checkout: PropTypes.bool,
  value: PropTypes.any
};

FulfillmentAdjustmentsTotal.propTypes = {
  checkout: PropTypes.bool,
  value: PropTypes.any,
  formatMessage: PropTypes.any
};

CartTaxes.propTypes = {
  formatMessage: PropTypes.any,
  taxCalculated: PropTypes.any,
  value: PropTypes.any,
  formatMessage1: PropTypes.any
};

CartSubtotal.propTypes = {
  formatMessage: PropTypes.any,
  value: PropTypes.any,
  currency: PropTypes.any
};

AdjustmentsTotal.propTypes = {
  value: PropTypes.any,
  formatMessage: PropTypes.any
};

CoreCostTotal.propTypes = {
  value: PropTypes.any,
  formatMessage: PropTypes.any
};

export default CartTotals;
export { CartTotals };
