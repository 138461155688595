/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty } from 'lodash';

import {
  getVariantAttributeChoiceOptions,
  getVariantAttributeCombinations
} from '../../utils/AttributeChoiceUtils';

/**
 * Generates an array of all possible attribute choice combinations. This does
 * not take into account whether there is a matching variant for the combination.
 *
 * Combinations take the form:
 *
 * ```js
 * {
 *   attribute1Name: attribute1allowedValue1,
 *   attribute2Name: attribute2allowedValue1,
 *   attribute3Name: attribute3allowedValue1
 * }
 *
 * // eg
 *
 * {
 *    COLOR: 'black',
 *    STORAGE: '64GB',
 *    PROVIDER: 'Verizon'
 * }
 * ```
 *
 * @param {[{}]|*} options - All of the ProductOptions. This will be filtered down
 *     to only the attribute-choice ones.
 *
 * @return {{attributeChoiceOptions: [], possibleAttributeCombos: [] }}
 *     A list of all attribute choice options and all possible combinations of
 *     them
 */
function usePossibleAttributeChoiceCombos(options) {
  return React.useMemo(() => {
    return getPossibleAttributeChoiceCombos(options);
  }, [options]);
}

function getPossibleAttributeChoiceCombos(options) {
  if (isEmpty(options)) {
    return { attributeChoiceOptions: [], possibleAttributeCombos: [] };
  }

  const attributeChoiceOptions = getVariantAttributeChoiceOptions(options);
  return {
    attributeChoiceOptions,
    possibleAttributeCombos: getVariantAttributeCombinations(
      attributeChoiceOptions,
      attributeChoiceOptions.length
    )
  };
}

export default usePossibleAttributeChoiceCombos;
export { getPossibleAttributeChoiceCombos };
