const LOCATION_INFO_PATH = '/checkout/location-info';
const FULFILLMENT_INFO_PATH = '/checkout/fulfillment-info';
const PAYMENT_INFO_PATH = '/checkout/payment-info';
const REVIEW_PATH = '/checkout/review';
const FULFILLMENT_OPTIONS_PATH = '/checkout/fulfillment-option';
const CHECKOUT_SIGN_IN_PATH = '/checkout/sign-in';
const CHECKOUT_PATH = '/checkout';
const CHECKOUT_PATHS = [
  LOCATION_INFO_PATH,
  FULFILLMENT_INFO_PATH,
  PAYMENT_INFO_PATH,
  REVIEW_PATH,
  FULFILLMENT_OPTIONS_PATH
];

export {
  CHECKOUT_PATHS,
  LOCATION_INFO_PATH,
  FULFILLMENT_INFO_PATH,
  PAYMENT_INFO_PATH,
  REVIEW_PATH,
  FULFILLMENT_OPTIONS_PATH,
  CHECKOUT_SIGN_IN_PATH,
  CHECKOUT_PATH
};
