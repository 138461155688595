/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Button, Icon } from 'app/common/components';

import useDropdown from '../hooks/useDropdown';

/**
 * The trigger component used to open/close the menu.
 *
 * By default it uses the Button component internally, meaning it also accepts all the props the Button component accepts.
 * To use the custom trigger component use "triggerComponent" property.
 */
const DropdownTrigger = props => {
  const {
    className,
    triggerClassName,
    triggerComponent: Trigger = DefaultTrigger,
    ...rest
  } = props;
  const { disabled, isOpen, handleTrigger } = useDropdown();
  const triggerProps = {
    disabled,
    isOpen,
    handleTrigger,
    className: triggerClassName,
    ...rest
  };

  return (
    <div className={cx('relative inline-flex', { [className]: !!className })}>
      <Trigger {...triggerProps} />
    </div>
  );
};

const DefaultTrigger = props => {
  const {
    children,
    className,
    handleTrigger,
    isOpen,
    label,
    showIcon = true,
    ...rest
  } = props;
  return (
    <Button
      className={cx('flex inline-flex items-center', {
        [className]: !!className
      })}
      type="button"
      aria-expanded={isOpen}
      aria-haspopup="true"
      onClick={handleTrigger}
      {...rest}
    >
      {label || children}
      {showIcon && (
        <Icon
          name="angle-down"
          size="xs"
          className="inline ml-2 fill-current"
        />
      )}
    </Button>
  );
};

DropdownTrigger.propTypes = {
  /** Class names to pass to the trigger component */
  triggerClassName: PropTypes.string,
  /** Custom trigger component */
  triggerComponent: PropTypes.any,
  /** The label for the trigger button */
  children: PropTypes.any,
  /** The class name for the trigger's wrapper */
  className: PropTypes.string,
  /** Whether or not to render chevron icon */
  showIcon: PropTypes.bool
};

DropdownTrigger.Default = DefaultTrigger;

export default DropdownTrigger;
