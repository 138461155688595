/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { values } from 'lodash';
import { default as qs } from 'query-string';
import PropTypes from 'prop-types';

import { SecondaryButton } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from './PaginationControl.messages';

/**
 * Render component for a generic control for paging through browse and search
 * results, e.g., next or previous.
 *
 * For a control to an arbitrary page in the results, see `PageNumberControl`.
 *
 * @visibleName Browse and Search Results Pagination Control
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const PaginationControl = ({
  currentPage,
  disabled = false,
  type,
  onChange = () => {}
}) => {
  const formatMessage = useFormatMessage();
  const isNext = type === PaginationControl.Types.NEXT;

  if (disabled) {
    return (
      <SecondaryButton
        className={classNames('mx-1', {
          'is-next': isNext,
          'is-previous': !isNext
        })}
        disabled={disabled}
        size={SecondaryButton.Size.SMALL}
        type="button"
      >
        {isNext
          ? formatMessage(messages.next)
          : formatMessage(messages.previous)}
      </SecondaryButton>
    );
  }

  return (
    <SecondaryButton
      className={classNames('mx-1', {
        'is-next': isNext,
        'is-previous': !isNext
      })}
      rel={isNext ? 'next' : 'prev'}
      size={SecondaryButton.Size.SMALL}
      onClick={onChange}
      to={({ search, ...location }) => {
        const params = qs.parse(search);
        return {
          ...location,
          search: qs.stringify({
            ...params,
            page: isNext ? currentPage + 1 : currentPage - 1
          })
        };
      }}
    >
      {isNext ? formatMessage(messages.next) : formatMessage(messages.previous)}
    </SecondaryButton>
  );
};

/**
 * @type {Readonly<{NEXT: string, PREVIOUS: string}>}
 */
PaginationControl.Types = Object.freeze({
  NEXT: 'NEXT',
  PREVIOUS: 'PREVIOUS'
});

PaginationControl.propTypes = {
  /** The current page's number */
  currentPage: PropTypes.number.isRequired,
  /** Whether the control is disabled */
  disabled: PropTypes.bool,
  /** The type of the control */
  type: PropTypes.oneOf(values(PaginationControl.Types))
};

export default PaginationControl;
export { PaginationControl };
