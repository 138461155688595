/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const IconSizes = Object.freeze([
  'xs',
  'lg',
  'sm',
  '1x',
  '2x',
  '3x',
  '4x',
  '5x',
  '6x',
  '7x',
  '8x',
  '9x',
  '10x'
]);

/**
 * Basic element to use for icons. This wraps a `FontAwesomeIcon`.
 * The `FontAwesomeIcon` can take a number of additional props not explicitly
 * listed here (fixedWidth, inverse, spin, etc.). To make use of them, simply
 * add them to the `<Icon />` as `iconProps` and they will be passed down.
 */
const Icon = ({ className, containerProps, iconProps, name, size }) => {
  return (
    <span
      className={classNames('inline-flex items-center justify-center', {
        [className]: !!className
      })}
      {...containerProps}
    >
      <FontAwesomeIcon icon={name} size={size} {...iconProps} />
    </span>
  );
};

Icon.propTypes = {
  /**
   * Class names to add to the `FontAwesomeIcon` container.
   */
  className: PropTypes.string,
  /**
   * Additional props to add to the `FontAwesomeIcon` container.
   * This can be things like `aria-label`, `role`, etc.
   */
  containerProps: PropTypes.object,
  /**
   * Additional props to pass directly to the `FontAwesomeIcon`.
   * This can be things like `fixedWidth`, `spin`, `inverse`, etc.
   */
  iconProps: PropTypes.object,
  /**
   * The name of the icon to use.
   * Can be a simple string name matching a FontAwesome icon like
   * `shopping-cart`, an array the includes a prefix like `['fab', 'facebook']`
   * (where `fab` indicates that it's a "FontAwesome __brand__" icon.
   */
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  /**
   * Size of the actual icon.
   * Use [Bulma's docs](https://bulma.io/documentation/elements/icon/#sizes)
   * for reference, but note that the icon sizes are actually to be given as one
   * of the values supported by `FontAwesomeIcon`.
   */
  size: PropTypes.oneOf(IconSizes)
};

Icon.Sizes = IconSizes;

export default Icon;
export { Icon };
