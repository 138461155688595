/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isFunction, omit } from 'lodash';
import PropTypes from 'prop-types';
import { Link as ReactLink, withRouter } from 'react-router-dom';

/**
 * Render component for a link. This is a wrapper around
 * [react-router's Link](https://github.com/ReactTraining/react-router/blob/7ccbd7eb7ca603ba164ad75181a48038dd5f4321/packages/react-router-dom/docs/api/Link.md).
 *
 * @visibleName Link
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const Link = ({ children, innerRef, location, replace, to, ...props }) => {
  return (
    <ReactLink
      {...omit(props, ['history', 'match', 'staticContext'])}
      innerRef={innerRef}
      replace={replace}
      to={isFunction(to) ? to(location) : to}
    >
      {children}
    </ReactLink>
  );
};

Link.propTypes = {
  /** Ref to add to the component's underlying element */
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /**
   * Whether clicking the link will replace the current entry in the history
   * stack instead of adding a new one.
   */
  replace: PropTypes.bool,
  /** Representation of the location toward which to link. */
  to: PropTypes.oneOfType([
    PropTypes.shape({
      /** e.g., /search */
      pathname: PropTypes.string.isRequired,
      /** URL query params: ?sort=name */
      search: PropTypes.string,
      /** e.g., #section */
      hash: PropTypes.string,
      /** state to persist to the `location` */
      state: PropTypes.object
    }),
    PropTypes.string,
    /** Takes the same object as `to` */
    PropTypes.func
  ]).isRequired
};

export default withRouter(Link);
export { Link };
