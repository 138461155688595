/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useState } from 'react';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { FieldErrors } from 'app/common/components';
import { ConfigurableItemContext } from 'app/product/contexts';
import { cartItemAttributeChoice } from 'app/product/helpers/ConfigurableItem';

import { ChoiceGroup, InputAttribute } from '../attributeTypes';
import ProductOption from '../ProductOption';

const ItemAttributeChoice = ({ option }) => {
  const { dispatch } = React.useContext(ConfigurableItemContext);
  const { label, displayOrder, attributeChoice } = option;
  const {
    attributeName,
    type,
    required = true,
    allowedValues = []
  } = attributeChoice;
  const [activeChoice, setActiveChoice] = useState(
    type === 'BOOLEAN' ? get(allowedValues, '[0]') : undefined
  );

  if (
    ItemAttributeChoice.ChoiceGroupTypes.has(type) &&
    isEmpty(allowedValues)
  ) {
    return null;
  }

  return (
    <ProductOption
      activeChoice={activeChoice}
      displayOrder={displayOrder}
      label={label}
      required={required}
      type={type}
    >
      <section className="flex flex-col items-start justify-start w-full lg:flex-row lg:flex-wrap lg:items-baseline">
        {ItemAttributeChoice.ChoiceGroupTypes.has(type) ? (
          <ChoiceGroup
            allowedValues={allowedValues}
            activeChoice={activeChoice}
            handleSelect={(label, value) => {
              setActiveChoice({ label, value });
              dispatch(cartItemAttributeChoice(attributeName, value));
            }}
            type={type}
          />
        ) : (
          <InputAttribute
            handleBlur={value => {
              dispatch(cartItemAttributeChoice(attributeName, value));
            }}
            name={attributeName}
            type={type}
            required={required}
          />
        )}
        <FieldErrors name={attributeName} />
      </section>
    </ProductOption>
  );
};

ItemAttributeChoice.ChoiceGroupTypes = new Set([
  'COLOR',
  'BOOLEAN',
  'SELECT',
  'SIZE'
]);

ItemAttributeChoice.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string.isRequired,
    displayOrder: PropTypes.number,
    attributeChoice: PropTypes.shape({
      attributeName: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      required: PropTypes.bool,
      allowedValues: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          displayOrder: PropTypes.number
        })
      )
    }).isRequired
  }).isRequired
};

export default ItemAttributeChoice;
export { ItemAttributeChoice };
