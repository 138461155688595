/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { noop } from 'lodash';

/**
 * @type {Readonly<{
 *   listItems: [{
 *     id: string,
 *     itemListId: string,
 *     itemSkuRef: {
 *       productId: string,
 *       sku: string|undefined,
 *       variantId: string|undefined
 *     },
 *     quantity: number,
 *     attributes: {}
 *   }]|undefined,
 *   setListItems: function,
 *   list: { id: string, name: string, attributes: {} }|undefined,
 *   setAddToCartState: function,
 *   addToCartState: {}|undefined
 * }>}
 */
const defaultContext = Object.freeze({
  list: undefined,
  fetchList: noop,
  listItems: undefined,
  setListItems: noop,
  customerRef: {},
  addToCartState: undefined,
  setAddToCartState: noop
});

const ListDetailsContext = React.createContext(defaultContext);

ListDetailsContext.defaultContext = defaultContext;

export default ListDetailsContext;
