/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { Environment } from 'app/common/services';
import { useMemo } from 'react';

function useCatalogInfo() {
  return useMemo(
    () => ({
      categoryInfo: {
        fetchUrl: Environment.get('CATEGORY_ENDPOINT', 'catalog/categories'),
        routeBaseContext: Environment.get('CATEGORY_URL_PREFIX', '/browse'),
        assetContextPath: Environment.get('CATEGORY_ASSET_ENDPOINT', '/assets'),
        detailsUrl: Environment.get(
          'CATEGORY_DETAILS_ENDPOINT',
          'catalog-browse/categories/details'
        )
      },
      categoryProductInfo: {
        fetchContextPath: Environment.get(
          'CATEGORY_PRODUCT_ENDPOINT',
          'products'
        )
      },
      productInfo: {
        fetchUrl: Environment.get(
          'PRODUCT_ENDPOINT',
          'catalog-browse/products'
        ),
        routeBaseContext: Environment.get('PRODUCT_URL_PREFIX', '/details'),
        detailsUri: Environment.get('PRODUCT_DETAILS_ENDPOINT', '/details'),
        priceUri: Environment.get('PRODUCT_PRICE_ENDPOINT', '/price')
      },
      categoryPromoProductInfo: {
        fetchContextPath: Environment.get(
          'CATEGORY_PROMOTIONAL_PRODUCT_ENDPOINT',
          'promotional-products'
        )
      }
    }),
    []
  );
}

export default useCatalogInfo;
