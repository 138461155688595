/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import {
  LOCALE_CHANGED,
  RESOLVE,
  RESOLVED,
  RESOLVING,
  CURRENCY_CHANGED
} from './tenantStateActionTypes';

const resolve = (isLegacy, identifier) => ({
  type: RESOLVE,
  identifier,
  isLegacy
});
const resolved = () => ({ type: RESOLVED });
const resolving = () => ({ type: RESOLVING });
const localeChanged = identifier => ({ type: LOCALE_CHANGED, identifier });
const currencyChanged = identifier => ({ type: CURRENCY_CHANGED, identifier });

export { resolving, resolve, resolved, localeChanged, currencyChanged };
