/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { get, isEmpty } from 'lodash';

import { IncludedProductPricingModel, PriceType } from 'app/common/constants';
import { Environment } from 'app/common/services';

/**
 * Determines the pricing for an IncludedProduct or Variant.
 *
 * @param {{
 *   pricingModel: string,
 *   priceInfo: {
 *     price: { amount: number, currency: string },
 *     priceType: string
 *   },
 * }} includedItem
 *
 * @return {{
 *   price: { amount: number, currency: string }|undefined,
 *   isSale: boolean,
 *   isIncludedInParent: boolean
 * }}
 */
function determineIncludedItemPricing(includedItem) {
  const isIncludedInParent =
    includedItem.pricingModel ===
    IncludedProductPricingModel.INCLUDED_IN_PARENT;

  if (isIncludedInParent) {
    return { isIncludedInParent, isSale: false, price: undefined };
  }

  return {
    isIncludedInParent,
    isSale: includedItem.priceInfo.priceType === PriceType.SALE_PRICE,
    price: includedItem.priceInfo.price
  };
}

/**
 * Determine the pricing info for a Product or Variant.
 *
 * @param {{}} item A product or variant for which to determine pricing
 * @param {{}} priceInfo The price info for the product
 * @return {{
 *   price: {amount: number, currency: string},
 *   isSale: boolean,
 *   originalPrice: {amount: number, currency: string}|undefined
 * }|null}
 */
function determineProductOrVariantPricing(item, priceInfo = {}) {
  if (isEmpty(item)) {
    return null;
  }

  if (isEmpty(priceInfo)) {
    return determinePricingWithoutPriceInfo(item);
  }

  const { price, priceType, originalPrice, coreCost } = priceInfo;
  const isSale = priceType === PriceType.SALE_PRICE;
  const isStandard = priceType === PriceType.STANDARD_PRICE;
  const standardPrice = get(
    priceInfo,
    'priceTypeDetails.standardPrice.bestPrice',
    originalPrice
  );
  return {
    isSale,
    originalPrice: standardPrice,
    price,
    priceType,
    isStandard,
    coreCost
  };
}

/**
 * Determine the pricing info for a Product or Variant when neither has a price
 * info object.
 *
 * @param {{}} item A product or variant whose pricing to determine
 *
 * @return {{
 *   price: {amount: number, currency: string},
 *   isSale: boolean,
 *   originalPrice: {amount: number, currency: string}|undefined
 * }|null}
 */
function determinePricingWithoutPriceInfo(item) {
  if (!!item.defaultPrice) {
    let { defaultPrice, onSale: isSale, salePrice, price } = item;

    if (!!defaultPrice && !defaultPrice.currency) {
      defaultPrice = {
        amount: defaultPrice,
        currency: item.currency || Environment.get('DEFAULT_CURRENCY', 'USD')
      };
    }

    if (!!salePrice && !salePrice.currency) {
      salePrice = {
        amount: salePrice,
        currency: item.currency || Environment.get('DEFAULT_CURRENCY', 'USD')
      };
    }

    return {
      originalPrice: defaultPrice || price,
      isSale,
      price: isSale ? salePrice : defaultPrice || price
    };
  }

  if (!!item.price) {
    let { price } = item;

    if (!price.currency) {
      price = {
        amount: price,
        currency: item.currency || Environment.get('DEFAULT_CURRENCY', 'USD')
      };
    }

    return { isSale: false, price };
  }

  return null;
}

export { determineIncludedItemPricing, determineProductOrVariantPricing };
