/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useCallback, useEffect, useRef } from 'react';

/**
 * Hook used to create an event callback that will not change with every render.
 * This is necessary in situations where you want to pass an event callback as
 * a prop without resulting in re-renders of children components.
 *
 * @example
 * <pre class="code">
 * const MyComponent = props => {
 *   // the function `onChange` never mutates, but the function it internally reference does change
 *   const onChange = useEventCallback(e =>
 *     props.setFieldValue(e.target.name, e.target.value),
 *     // the internal function is recalculates anytime props.setFieldValue changes
 *     [props.setFieldValue]
 *   );
 *
 *   return <Input onChange={onChange}/>
 * };
 * </pre>
 */
function useEventCallback(fn, dependencies) {
  const ref = useRef(null);

  useEffect(
    () => {
      ref.current = fn;
    },
    // eslint-disable-next-line
    [fn, ...dependencies]
  );

  return useCallback(
    (...args) => {
      const fn = ref.current;
      return fn(...args);
    },
    [ref]
  );
}

export default useEventCallback;
