/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import { useFormatMessage } from 'app/common/hooks';

import { SavedPaymentForm } from '../../components/SavedPaymentForm';
import { SavedPaymentActions } from './components';
import messages from './PaymentCard.messages';
import { CreditCardIcons } from 'app/common/components';

const PaymentCard = props => {
  const { editPayment, isDefault, paymentAccount, refetchPayments, selected } =
    props;
  const formatMessage = useFormatMessage();

  const formattedExp = `Exp: ${paymentAccount.expirationMonth}/${paymentAccount.expirationYear}`;
  const CardType = CreditCardIcons.Types[paymentAccount.cardType];

  return (
    <div className="mb-4 text-gray-900 bg-white rounded shadow">
      <div className="px-4 py-4">
        {isDefault && (
          <span className="inline-block mb-1 px-2 py-1 bg-green-200 font-semibold uppercase tracking-wide text-xs text-green-800 rounded">
            {formatMessage(messages.isDefault)}
          </span>
        )}
        <div className="flex justify-between">
          <div>
            {paymentAccount.displayName}
            <div className="text-xs text-gray-700 flex items-center">
              <CardType small active />
              <span className="mr-2">
                ending in {paymentAccount.maskedAccountNumber}
              </span>
              <span>{formattedExp}</span>
            </div>
          </div>

          <div className="flex">
            {!selected && (
              <SavedPaymentActions
                editPayment={editPayment}
                isDefault={isDefault}
                paymentAccount={paymentAccount}
                refetchPayments={refetchPayments}
              />
            )}
          </div>
        </div>
      </div>
      <AnimateHeight duration={200} height={selected ? 'auto' : 0}>
        <SavedPaymentForm
          editPayment={editPayment}
          isActive={selected}
          paymentAccount={paymentAccount}
          refetchPayments={refetchPayments}
        />
      </AnimateHeight>
    </div>
  );
};

PaymentCard.propTypes = {
  /** Function to trigger editing of the payment **/
  editPayment: PropTypes.func.isRequired,
  /** Is the payment default **/
  isDefault: PropTypes.bool.isRequired,
  /** The saved payment **/
  paymentAccount: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
    paymentToken: PropTypes.string,
    nameOnAccount: PropTypes.string,
    expirationMonth: PropTypes.number,
    expirationYear: PropTypes.number,
    maskedAccountNumber: PropTypes.string,
    fullName: PropTypes.string,
    companyName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    stateProvinceRegion: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    phonePrimary: PropTypes.shape({ phoneNumber: PropTypes.string }),
    createdDate: PropTypes.number,
    updatedDate: PropTypes.number,
    activePaymentAccount: PropTypes.string
  }),
  /** Function to refetch saved payments **/
  refetchPayments: PropTypes.func.isRequired,
  /** Is this payment currently being edited **/
  selected: PropTypes.bool.isRequired
};

export default PaymentCard;
export { PaymentCard };
