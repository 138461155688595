/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useContext, useMemo } from 'react';
import { get } from 'lodash';

import { InventoryDistanceType } from 'app/common/constants';
import { TenantContext } from 'app/common/contexts';

import useInventoryInfo from '../useInventoryInfo';
import useRestApi from '../../useRestApi';

/**
 * Returns the nearest locations to the currently selected dealer location with
 * the distance between and sorted by nearest first.
 *
 * @param {String} partNumber - The part number for which to check availability
 *     at nearby locations
 * @param {Number} [radius=30] - The radius around the current location in which
 *     to check for nearby locations that have {@code partNumber} stocked
 * @param {String} [distanceType="MILES"] - The unit of measure for the radius
 * @param {String} latitude - Latitude override to use, only if a geolocation has been resolved
 * @param {String} longitude - Longitude override to use, only if a geolocation has been resolved
 *
 * @return {{
 *   error: Boolean,
 *   exception: {},
 *   loading: Boolean,
 *   response: [{}],
 *   sendCallback: Function
 * }}
 *    The response and state info about the request. The response will be in the
 *    format of a page
 */
function useNearestLocations(
  partNumber,
  radius = 30,
  distanceType = InventoryDistanceType.MILES,
  latitude,
  longitude
) {
  const { application } = useContext(TenantContext);
  const { baseUrl, nearCoordinatesContextPath } = useInventoryInfo().location;
  const config = useMemo(
    () => ({
      params: {
        latitude: latitude ? latitude : get(application, 'latitude'),
        longitude: longitude ? longitude : get(application, 'longitude'),
        distanceType,
        radius
      }
    }),
    [latitude, longitude, application, distanceType, radius]
  );

  return useRestApi(
    `${baseUrl}${nearCoordinatesContextPath}/${partNumber}`,
    config,
    false,
    true
  );
}

export default useNearestLocations;
