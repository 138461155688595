import { useFormatMessage } from 'app/common/hooks';
import React from 'react';
import { ConfigurableItemContext, PdpContext } from 'app/product/contexts';
import { get, isEmpty } from 'lodash';
import messages from '../../MerchandisingInfo.messages';
import { ProductCollapsible } from './ProductCollapsible';
import { styleHtml } from 'app/common/utils/HtmlUtils';

const ProductDescription = ({ className, open = false }) => {
  const formatMessage = useFormatMessage();
  const { product } = React.useContext(PdpContext);
  const { activeOption } = React.useContext(ConfigurableItemContext);

  const description = get(
    activeOption,
    'longDescription',
    product.longDescription
  );

  if (isEmpty(description)) {
    return null;
  }
  const args = {
    html: description,
    options: {
      elementClasses: {
        ul: {
          topLevelClasses: ['pl-4']
        },
        ol: {
          topLevelClasses: ['pl-4']
        }
      }
    }
  };
  return (
    <ProductCollapsible
      title={formatMessage(messages.description)}
      className={className}
      open={open}
    >
      <section
        className="p-4 html-field text-sm sm:text-base"
        dangerouslySetInnerHTML={{
          __html: styleHtml(args)
        }}
      ></section>
    </ProductCollapsible>
  );
};

export { ProductDescription };
