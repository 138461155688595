/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';
import { range } from 'lodash';

/**
 * Hook to use to generate an array with all the page numbers to display for
 * pagination based on the given pagination info. Array can include non-numeric
 * values such as when the number of pages in the set exceeds 5, resulting in
 * the following if there are 7 pages and the `currentPage` is 4:
 * `[1, '...', 3, 4, 5, '...', totalPages]`.
 *
 * @param {number} currentPage - The number of the current page (starting at 1)
 * @param {number} totalPages - Total number of pages in the result set
 *
 * @return {number[]} Array of page numbers to display based on the given
 *     pagination info
 */
function usePageNumbers(currentPage, totalPages) {
  return useMemo(
    () => build(currentPage, totalPages),
    [currentPage, totalPages]
  );
}

function build(currentPage, totalPages) {
  if (totalPages <= 5) {
    return range(1, totalPages + 1);
  }

  if (currentPage === 1 || currentPage === 2) {
    return [1, 2, 3, '\u2026', totalPages];
  }

  if (currentPage === 3) {
    return [1, 2, 3, 4, '\u2026', totalPages];
  }

  if (currentPage === totalPages || currentPage === totalPages - 1) {
    return [1, '\u2026', totalPages - 2, totalPages - 1, totalPages];
  }

  if (currentPage === totalPages - 2) {
    return [
      1,
      '\u2026',
      totalPages - 3,
      totalPages - 2,
      totalPages - 1,
      totalPages
    ];
  }

  return [
    1,
    '\u2026',
    currentPage - 1,
    currentPage,
    currentPage + 1,
    '\u2026',
    totalPages
  ];
}

export default usePageNumbers;
