/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { createContext } from 'react';

const defaultContext = Object.freeze({
  colors: {
    primary: {
      100: '#def4ff',
      200: '#a2d0e7',
      300: '#6baccc',
      400: '#3d8bb3',
      500: '#1a6e99',
      600: '#005581',
      700: '#00476b',
      800: '#003652',
      900: '#00293e'
    }
  }
});

const ThemeContext = createContext(defaultContext);

ThemeContext.defaultContext = defaultContext;

export default ThemeContext;
