import { Modal, SecondaryButton } from 'app/common/components';
import { FormattedMessage } from 'react-intl';
import messages from './PreferredStoreModal.messages';
import { useFormatMessage } from 'app/common/hooks';
import React, { useEffect } from 'react';
import {
  AUTO_COMPLETE_OPEN,
  CLOSE_MODAL,
  LOADING_LOCATIONS,
  LOADING_LOCATIONS_COMPLETE,
  RESET_LOCATION_QUERY,
  STORE_LOOKUP_ERROR
} from './reducer/preferredStoreReducer';
import {
  LocationQuery,
  NearbyStoreRowsSkeleton,
  NoLocationsFound,
  StoreLocationRow
} from 'app/store-locator/components';
import {
  CLOSE_AUTO_COMPLETE,
  HANDLE_ADDRESS_SELECT,
  SET_AUTO_COMPLETE_RESULTS,
  SET_CURRENT_PREFERRED_STORE,
  SET_NEW_PREFERRED_STORE,
  SET_QUERY,
  SET_USING_LOC_COORDS
} from './reducer/preferredStoreActions';
import { get, isEmpty, map } from 'lodash';
import storeDropdownMessages from 'app/layout/components/Header/components/StoreDropdown/StoreDropdown.messages';
import { usePreferredStoreModalRefs } from './hooks';
import { requestGeolocation } from 'app/common/utils/GeolocationUtil';
import {
  getStoreRowFromApplication,
  locationUnchanged
} from 'app/layout/components/Header/components/StoreDropdown/util/storeDropdownUtil';

/**
 *
 * @param {PreferredModalState} state The modal state
 * @param dispatch The dispatch function
 * @param modalRef The ref for the modal
 * @param preferredLocation The user's current preferred location (if any)
 * @returns {Element}
 * @constructor
 */
const PreferredStoreModal = ({
  state,
  dispatch,
  modalRef,
  preferredLocation
}) => {
  usePreferredLocationEffect(preferredLocation, state, dispatch);
  return (
    <Modal
      ref={modalRef}
      size={Modal.Size.LARGE}
      isOpen={state.preferredStoreModalOpen}
    >
      <div onClick={e => handleClickOutsideAutocomplete(e, dispatch)}>
        <PreferredModalHeader dispatch={dispatch} />
        <PreferredModalBody dispatch={dispatch} state={state} />
        <PreferredModalFooter dispatch={dispatch} />
      </div>
    </Modal>
  );
};

/**
 * Renders the body of the preferred store modal
 * @param {PreferredModalState} state The preferred store modal state
 * @param dispatch The dispatch function for the preferred store modal reducer
 * @returns {Element}
 * @constructor
 */
const PreferredModalBody = ({ state, dispatch }) => {
  const { isLoadingLocations, error, errorMessage } = state;
  const suggestionContainerRefs = usePreferredStoreModalRefs(state, dispatch);

  const queryInputProps = getLocationQueryInputProps({ state, dispatch });
  const suggestAddressProps = getSuggestAddressProps({ state, dispatch });
  const suggestAutoCompleteProps = getSuggestAutoCompleteProps(
    state,
    dispatch,
    suggestionContainerRefs
  );
  return (
    <Modal.Body className="bg-gray-100 text-gray-700">
      <div className="flex flex-col store-dropdown-container">
        <div className="flex flex-col store-dropdown-row divide-y-2">
          <LocationQuery
            locationQueryInputProps={queryInputProps}
            suggestAddressProps={suggestAddressProps}
            suggestionAutoCompleteProps={suggestAutoCompleteProps}
            getLocationOnClick={() => getCurrentLocation(state, dispatch)}
            isModalInput={true}
            error={error}
            errorMessage={errorMessage}
          />
          {isLoadingLocations ? (
            <NearbyStoreRowsSkeleton />
          ) : (
            <ModalNearbyStoreRows {...{ state, dispatch }} />
          )}
          <NoLocationsFound state={state} />
        </div>
      </div>
    </Modal.Body>
  );
};

function handleClickOutsideAutocomplete(e, dispatch) {
  const target = e?.target;
  const srcElement = e?.srcElement;
  const isSuggestedAddress =
    typeof srcElement?.className === 'string'
      ? srcElement.className.includes('suggested-address')
      : false;
  if (target?.id === 'preferredLocationQueryInput' || isSuggestedAddress) {
    return;
  }
  dispatch({ type: CLOSE_AUTO_COMPLETE });
}

function usePreferredLocationEffect(preferredLocation, state, dispatch) {
  useEffect(() => {
    if (isEmpty(preferredLocation)) {
      return;
    }
    const preferredLocationNumber = get(preferredLocation, 'identifierValue');
    if (preferredLocationNumber === state.preferredLocationNumber) {
      return;
    }
    dispatch({
      type: SET_CURRENT_PREFERRED_STORE,
      payload: {
        preferredLocation: getStoreRowFromApplication(preferredLocation),
        preferredLocationNumber
      }
    });
  }, [preferredLocation, state, dispatch]);
}

const ModalNearbyStoreRows = ({ state, dispatch }) => {
  const { stores = [], preferredLocation } = state;

  return (
    <>
      {!isEmpty(preferredLocation) && (
        <StoreLocationRow
          location={preferredLocation}
          selected={true}
          currentStoreButtonLabel={messages.myStore}
        />
      )}
      {map(stores, (location, index) => {
        const { locationNumber } = location;
        if (locationNumber === preferredLocation?.locationNumber) {
          return null;
        }
        return (
          <StoreLocationRow
            location={location}
            key={index}
            currentStoreButtonLabel={messages.myStore}
            selectStoreButtonLabel={messages.setAsStore}
            selectStoreOnClick={e => {
              e.preventDefault();
              setPreferredStore(locationNumber, state, dispatch);
            }}
          />
        );
      })}
    </>
  );
};

function setPreferredStore(locationNumber, state, dispatch) {
  dispatch({
    type: SET_NEW_PREFERRED_STORE,
    payload: {
      newPreferredLocationNumber: locationNumber
    }
  });
}

/**
 * @return {LocationQueryInputProps}
 */
const getLocationQueryInputProps = ({ state, dispatch }) => {
  const { query, isLoadingLocations, error, errorMessage } = state;
  return {
    id: 'preferredLocationQueryInput',
    formId: 'preferredLocationQueryForm',
    query: query?.q || '',
    disabled: isLoadingLocations,
    onChange: e => clearAddress(e, state, dispatch),
    onClickOrFocus: () => dispatch(AUTO_COMPLETE_OPEN),
    onSubmit: e => handleLocationQuerySubmit(e, state, dispatch),
    error: error,
    errorMessage: errorMessage
  };
};

/**
 * @return {SuggestAddressProps}
 */
const getSuggestAddressProps = ({ state, dispatch }) => {
  const { query } = state;
  return {
    q: query?.q || '',
    skipApiCall: query?.skipApiCall || false,
    onSuggestError: () =>
      dispatch(STORE_LOOKUP_ERROR(storeDropdownMessages.addressLookupError)),
    onSuggestSuccess: payload =>
      dispatch({ type: SET_AUTO_COMPLETE_RESULTS, payload }),
    clearSuggestions: () =>
      dispatch({ type: SET_AUTO_COMPLETE_RESULTS, payload: [] })
  };
};

/**
 * @return {SuggestionsAutoCompleteProps}
 */
const getSuggestAutoCompleteProps = (state, dispatch, containerRefs) => {
  const { autoCompleteResults, autoCompleteOpen } = state;
  const { clickOutsideSuggestionsContainerRef, suggestionContainerRef } =
    containerRefs || {};
  return {
    autoCompleteOpen,
    autoCompleteResults,
    clickOutsideSuggestionsContainerRef,
    suggestionContainerRef,
    onSelect: (e, suggestion, latitude, longitude) =>
      handleAddressSelect(e, suggestion, latitude, longitude, dispatch)
  };
};

const clearAddress = function (e, state, dispatch) {
  dispatch({
    type: SET_QUERY,
    payload: e.target.value
  });
  const { query } = state;
  if (isEmpty(query?.q)) {
    dispatch(RESET_LOCATION_QUERY);
  }
};

const handleLocationQuerySubmit = function (e, state, dispatch) {
  e.preventDefault();
  const { autoCompleteResults } = state;
  const { suggestions } = autoCompleteResults || {};
  if (suggestions && suggestions.length > 0) {
    const { suggestion, latLong } = suggestions[0];
    const { latitude, longitude } = latLong;
    handleAddressSelect(e, suggestion, latitude, longitude, dispatch);
  }
  document.getElementById('preferredLocationQueryInput')?.blur();
};

const handleAddressSelect = function (
  e,
  suggestion,
  latitude,
  longitude,
  dispatch
) {
  e.preventDefault();
  dispatch({
    type: HANDLE_ADDRESS_SELECT,
    payload: {
      q: suggestion,
      latitude,
      longitude
    }
  });
};

const getCurrentLocation = (state, dispatch) => {
  dispatch(LOADING_LOCATIONS);
  requestGeolocation({
    successCallback: position => {
      const { latitude, longitude } = position.coords;
      const { usingLocCoords, userSelectedAddress } = state;
      const locationNotChanged = locationUnchanged(
        latitude,
        longitude,
        usingLocCoords,
        userSelectedAddress
      );
      if (locationNotChanged) {
        dispatch(LOADING_LOCATIONS_COMPLETE);
        return;
      }
      dispatch({
        type: SET_USING_LOC_COORDS,
        payload: { latitude, longitude }
      });
    },
    errorCallback: () => {
      dispatch(LOADING_LOCATIONS_COMPLETE);
      dispatch(STORE_LOOKUP_ERROR(messages.useLocationError));
    }
  });
};

const PreferredModalHeader = ({ dispatch }) => {
  return (
    <Modal.Header className="flex">
      <Modal.Header.Title>
        <FormattedMessage
          className="lg:text-xl font-medium"
          {...messages.modalTitle}
        />
      </Modal.Header.Title>
      <Modal.Close
        onClose={() => {
          dispatch(CLOSE_MODAL);
        }}
      />
    </Modal.Header>
  );
};

const PreferredModalFooter = ({ dispatch }) => {
  const formatMessage = useFormatMessage();
  return (
    <Modal.Footer className="flex items-center lg:justify-between">
      <SecondaryButton
        className="mr-4"
        onClick={() => {
          dispatch(CLOSE_MODAL);
        }}
      >
        {formatMessage(messages.cancel)}
      </SecondaryButton>
    </Modal.Footer>
  );
};

export { PreferredStoreModal };
