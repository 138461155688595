import { Icon, Modal, PrimaryButton } from 'app/common/components';
import React from 'react';
import classNames from 'classnames';
import messages from 'app/checkout/components/CheckoutLayout/components/FulfillmentInfoWithOptions/FulfillmentInfoWithOptions.messages';
import { useFormatMessage } from 'app/common/hooks';
import { useHistory } from 'react-router-dom';

/**
 * Renders a modal with arbitrary content on the checkout page. This can be
 * rendered on any checkout step by adding the URL parameter `?modal={modalType}`.
 *
 * @example
 * const MyModalContent = () => {
 * return {
 *   modalTitle: formatMessage(messages.title),
 *   content: () => <MyComponent />,
 *   modalName: 'my-modal'
 * }
 * }
 *
 * @description
 * To register an informational modal, use the `registerModal` function. Add
 * your component in CheckoutLayout#registerCheckoutModals.
 *
 * @example
 * registerModal(MyModalContent);
 *
 * @param modalTitle {string} The title of the modal. Should be a formatted message.
 * @param content {Element} The body content of the modal. Should be a function returning JSX.
 * @param error {boolean} Whether the modal is an error modal. Default is true.
 * @param closeLabel {string} The label of the close button. Should be a formatted message. Optional.
 * @param setModalOpen {function} A state function to set the modal open state.
 * @param modalOpen {boolean} Whether the modal is open or not.
 * @param modalRef {React.RefObject} A ref object to the modal component.
 * @param removeUrlParam {function} Function to execute that removes the URL parameter. Optional.
 * @return {Element}
 * @constructor
 */
const CheckoutModal = ({
  modalTitle,
  content,
  error = true,
  closeLabel,
  modalOpen,
  setModalOpen,
  modalRef,
  removeUrlParam = () => {}
}) => {
  const formatMessage = useFormatMessage();
  const icon = error ? 'exclamation-triangle' : 'info-circle';
  const iconColor = error ? 'text-yellow-700' : 'text-blue-700';
  const fontColor = error ? 'text-red-700' : 'text-gray-700';
  const closeButtonLabel = closeLabel || formatMessage(messages.close);
  return (
    <Modal
      isOpen={modalOpen}
      ref={modalRef}
      dialogClassName="top-1/4"
      size={Modal.Size.LARGE}
    >
      <Modal.Header>
        <Modal.Header.Title className="flex items-center">
          <Icon
            className={classNames(iconColor, '700 mr-2 md:mr-4')}
            name={icon}
          />
          <span className={classNames(fontColor, 'font-medium lg:text-xl')}>
            {modalTitle}
          </span>
        </Modal.Header.Title>
      </Modal.Header>
      <Modal.Body className="text-gray-700 bg-gray-100">
        <div
          className={classNames(
            error ? 'text-red-700 border-red-300, bg-red-100' : 'text-gray-700',
            `items-center mb-4 px-2 py-1 text-sm leading-snug 
            border border-solid rounded
            md:px-4 md:py-2 lg:text-base lg:leading-normal`
          )}
        >
          {content()}
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center lg:justify-between">
        <PrimaryButton
          onClick={() => {
            removeUrlParam();
            setModalOpen(false);
          }}
        >
          {closeButtonLabel}
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

const registeredModals = {};

/**
 * Registers a modal component to be rendered on the checkout page.
 * Expects the component to have a `modalName`, `modalTitle`, and `content` field.
 * @example
 * {
 *   modalName: 'my-modal',
 *   modalTitle: formatMessage(messages.title),
 *   content: () => <ModalBodyContents />
 * }
 *
 * @param component {Object} The modal component to register.
 */
const registerModal = component => {
  const modalContents = component();
  const modalName = modalContents.modalName;
  registeredModals[modalName] = modalContents;
};

const CHECKOUT_MODAL_PARAM = 'modal';

const CheckoutModalRenderer = () => {
  const history = useHistory();
  const [modalOpen, setModalOpen] = React.useState(true);
  const modalRef = React.useRef(undefined);
  const searchParams = new URLSearchParams(history.location.search);
  const modalType = searchParams.get(CHECKOUT_MODAL_PARAM);
  if (!modalType) {
    return null;
  }
  const modal = registeredModals[modalType];
  if (!modal || !modal.modalTitle || !modal.content) {
    console.warn(`Checkout modal type ${modalType} not found.`);
    return null;
  }
  const { modalTitle, content: modalContent } = modal;
  if (!modal.modalTitle || !modal.content) {
    let missingFields = [];
    if (!modalTitle) missingFields.push('modalTitle');
    if (!modalContent) missingFields.push('modalContent');
    const missingFieldsString = missingFields.join(', ');
    console.warn(
      `Checkout modal type ${modalType} is missing required field(s) ${missingFieldsString}.`
    );
    return null;
  }
  return (
    <CheckoutModal
      modalTitle={modal.modalTitle}
      content={modalContent}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      modalRef={modalRef}
      removeUrlParam={() => {
        searchParams.delete(CHECKOUT_MODAL_PARAM);
        history.replace({
          search: searchParams.toString()
        });
      }}
    />
  );
};

export default CheckoutModal;
export {
  registerModal,
  registeredModals,
  CheckoutModalRenderer,
  CHECKOUT_MODAL_PARAM
};
