/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';

import { Environment } from 'app/common/services';
import log from 'app/common/utils/Log';

const logger = log.getLogger('common.components.Currency');

/**
 * Render component for currency values. This will properly format the given
 * `value` for the given `currency`.
 *
 * @visibleName Currency
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const Currency = ({ className, currency, amount }) => {
  const numberProps = {
    // This is a workaround since eslint rightly flags use of a "style" prop
    // when used incorrectly. ReactIntl's FormattedNumber uses this attribute
    // for a purpose it is not intended for and rather than disabling the rule
    // we are working around it.
    style: 'currency'
  };

  if (isNaN(amount)) {
    logger.error('Amount passed to currency was not a number');
  }

  return (
    <span
      className={classNames('Currency inline-flex flex-start', {
        [className]: !!className
      })}
    >
      {isNaN(amount) ? (
        <>&nbsp;</>
      ) : (
        <FormattedNumber {...numberProps} currency={currency} value={amount} />
      )}
    </span>
  );
};

Currency.propTypes = {
  /** Class names to add to the component */
  className: PropTypes.string,
  /** The currency code such as USD, EUR, GBP */
  currency: PropTypes.string,
  /** The currency value to be formatted */
  amount: PropTypes.number.isRequired
};

Currency.defaultProps = {
  currency: Environment.get('DEFAULT_CURRENCY', 'USD')
};

export default Currency;
export { Currency };
