/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get } from 'lodash';

import { useLocation } from 'app/common/hooks';

function useProductListImpressions(products, originListOverride) {
  const location = useLocation();
  const originList = originListOverride || getOriginList(location);

  return React.useMemo(
    () => ({
      ecommerce: {
        currencyCode: get(products, '[0].currency', 'USD'),
        impressions: products.map(p => ({ ...p, list: p.list || originList }))
      }
    }),
    [products, originList]
  );
}

function getOriginList(location) {
  if (location.pathname.startsWith('/search')) {
    return 'Search';
  }

  if (location.pathname.startsWith('/part-search')) {
    return 'Browse';
  }

  if (location.pathname.startsWith('/cart')) {
    return 'Cart';
  }

  if (location.pathname.startsWith('/checkout')) {
    if (location.pathname.includes('/confirmation')) {
      return 'Order Confirmation';
    }

    return 'Checkout';
  }

  if (location.pathname.startsWith('/my-account/orders')) {
    return 'Order History';
  }

  if (location.pathname.startsWith('/order-tracking')) {
    return 'Order Tracking';
  }

  if (location.pathname === '/') {
    return 'Homepage';
  }

  return 'Aurora Parts to Go';
}

export default useProductListImpressions;
