/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty } from 'lodash';

function useItem(activeOption, product, quantity, requiresConfiguration) {
  const [item, setItem] = React.useState({
    ...product,
    quantity,
    productId: product.id
  });

  React.useEffect(() => {
    if (requiresConfiguration) {
      return;
    }

    const isVariant = !isEmpty(activeOption);
    const baseItem = isVariant ? activeOption : product;

    setItem({
      ...baseItem,
      quantity,
      productId: isVariant ? baseItem.productId : baseItem.id,
      variantId: isVariant ? baseItem.id : undefined
    });
  }, [activeOption, product, quantity, requiresConfiguration]);

  return item;
}

export default useItem;
