/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    id: 'Cart.CartItems.CartItem.Details.Actions.AddToListButton.cancel',
    defaultMessage: 'Cancel'
  },
  submit: {
    id: 'Cart.CartItems.CartItem.Details.Actions.AddToListButton.submit',
    defaultMessage: 'Save'
  },
  label: {
    id: 'Cart.CartItems.CartItem.Details.Actions.AddToListButton.label',
    defaultMessage: 'Add to List'
  },
  title: {
    id: 'Cart.CartItems.CartItem.Details.Actions.AddToListButton.title',
    defaultMessage: 'Save Cart Items'
  },
  description: {
    id: 'Cart.CartItems.CartItem.Details.Actions.AddToListButton.description',
    defaultMessage:
      'Please select the list you wish to save selected parts to or enter a new list name.'
  },
  caveat: {
    id: 'Cart.CartItems.CartItem.Details.Actions.AddToListButton.caveat',
    defaultMessage:
      'Note: When adding to an existing list, any parts that already exist in the list will not be added.'
  },
  createNewSelectLabel: {
    id: 'Cart.CartItems.CartItem.Details.Actions.AddToListButton.select.createNew',
    defaultMessage: 'Create "{name}"'
  },
  createNewSelectPlaceholder: {
    id: 'Cart.CartItems.CartItem.Details.Actions.AddToListButton.select.placeholder',
    defaultMessage: 'Select or create a list'
  },
  success: {
    id: 'Cart.CartItems.CartItem.Details.Actions.AddToListButton.select.success',
    defaultMessage: 'Item successfully saved to "{listName}".'
  },
  error: {
    id: 'Cart.CartItems.CartItem.Details.Actions.AddToListButton.select.error',
    defaultMessage:
      'We were unable to save to "{listName}". Please try again later.'
  },
  duplicateError: {
    id: 'Cart.CartItems.CartItem.Details.Actions.AddToListButton.select.error.duplicate',
    defaultMessage: 'The selected item is already in "{listName}".'
  }
});
