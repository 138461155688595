/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';

import { AuthContext } from 'app/auth/contexts';
import {
  CommunicationPreferences,
  DecoratedField,
  Icon,
  PrimaryButton,
  TertiaryButton
} from 'app/common/components';
import { CommunicationPreferenceType } from 'app/common/constants';
import { CustomerContext } from 'app/common/contexts';
import {
  useCustomerService,
  useFormatMessage,
  useGtmPageView,
  useRestApi
} from 'app/common/hooks';
import { AuthService } from 'app/auth/services';
import { logError } from 'app/common/utils/ApiErrorUtils';

import { useValidationSchema } from './hooks';
import messages from './AccountInfo.messages';
import { useHeaderMetadata } from 'app/core/components/App';

/**
 *  Renders the Account Info page.
 */
const AccountInfo = () => {
  const { siteTitle } = useHeaderMetadata();
  const formatMessage = useFormatMessage();
  const { user } = React.useContext(AuthContext);
  const { customer, setCustomer } = React.useContext(CustomerContext);
  const {
    id: customerId,
    fullName,
    firstName,
    lastName,
    email,
    preferredCommunicationMethod = CommunicationPreferenceType.UNKNOWN.value
  } = customer || {};
  const { baseUrl } = useCustomerService();
  const updateConfig = React.useMemo(() => ({ method: 'put' }), []);
  const { sendCallback: updateCustomer } = useRestApi(
    `${baseUrl}/${customerId}`,
    updateConfig,
    false,
    true
  );
  const [editing, setEditing] = React.useState(false);
  const validationSchema = useValidationSchema(messages);

  useGtmPageView('Profile Details');

  return (
    <section>
      <Helmet titleTemplate={`%s - ${siteTitle}`}>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <header className="text-2xl text-bold flex justify-between pb-4">
        <h1>{formatMessage(messages.title)}</h1>
        {!editing && (
          <PrimaryButton
            onClick={() => {
              setEditing(true);
            }}
          >
            {formatMessage(messages.edit)}
          </PrimaryButton>
        )}
      </header>

      {editing && (
        <section className="p-4 shadow rounded bg-white">
          <Formik
            initialValues={{ ...customer }}
            onSubmit={async (values, actions) => {
              try {
                const response = await updateCustomer({
                  data: {
                    ...customer,
                    firstName: values.firstName || firstName,
                    lastName: values.lastName || lastName,
                    preferredCommunicationMethod:
                      values.preferredCommunicationMethod ||
                      preferredCommunicationMethod
                  }
                });

                if (
                  user?.email?.toUpperCase() !== values?.email?.toUpperCase()
                ) {
                  AuthService.logout(true);
                }

                if (!isEmpty(response)) {
                  actions.setSubmitting(false);
                  setEditing(false);
                  setCustomer(response);
                }
              } catch (err) {
                logError({
                  ...err,
                  when: `updating Customer info for ${customerId}`
                });
                actions.setSubmitting(false);
              }
            }}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize={true}
          >
            {({ isSubmitting }) => (
              <Form>
                <DecoratedField
                  id="firstName"
                  label={formatMessage(messages.firstName)}
                  name="firstName"
                  placeholder={formatMessage(messages.firstNamePlaceholder)}
                  autoComplete="given-name"
                />
                <DecoratedField
                  id="lastName"
                  label={formatMessage(messages.lastName)}
                  name="lastName"
                  placeholder={formatMessage(messages.lastNamePlaceholder)}
                  autoComplete="family-name"
                />
                <DecoratedField
                  id="email"
                  label={formatMessage(messages.email)}
                  name="email"
                  placeholder={formatMessage(messages.emailPlaceholder)}
                  autoComplete="email"
                  hint={formatMessage(messages.emailWarning)}
                  disabled
                />
                <TertiaryButton onClick={() => AuthService.changePassword()}>
                  {formatMessage(messages.changePassword)}
                  <Icon className="pl-2" name="external-link-alt" />
                </TertiaryButton>
                <em className="block mt-1 text-sm text-gray-600 leading-tight not-italic">
                  {formatMessage(messages.passwordWarning)}
                </em>
                <CommunicationPreferences
                  className="mt-6"
                  disabled={isSubmitting}
                  legendClassName="flex mb-2 leading-none text-sm font-bold capitalize text-gray-700 disabled:text-gray-500"
                />
                <div className="flex justify-between mt-4">
                  <TertiaryButton
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <div className="text-red-600">
                      {formatMessage(messages.cancel)}
                    </div>
                  </TertiaryButton>
                  <PrimaryButton disabled={isSubmitting} type="submit">
                    {formatMessage(messages.save)}
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </Formik>
        </section>
      )}

      {!editing && (
        <section className="p-4 shadow rounded bg-white">
          <div className="mb-4">
            <div className="mb-2 leading-none text-sm capitalize font-bold text-gray-700">
              {formatMessage(messages.name)}
            </div>
            <div>{fullName || `${firstName} ${lastName}`}</div>
          </div>
          <div className="mb-4">
            <div className="mb-2 leading-none text-sm capitalize font-bold text-gray-700">
              {formatMessage(messages.email)}
            </div>
            <div>{email}</div>
          </div>
          <div className="mb-4">
            <div className="mb-2 leading-none text-sm capitalize font-bold text-gray-700">
              {formatMessage(messages.password)}
            </div>
            <div>********</div>
          </div>
          <div className="mb-4">
            <div className="mb-2 leading-none text-sm capitalize font-bold text-gray-700">
              {formatMessage(messages.preferredCommunicationMethod)}
            </div>
            <div>
              {!!preferredCommunicationMethod ? (
                formatMessage(
                  CommunicationPreferenceType[preferredCommunicationMethod]
                    .label
                )
              ) : (
                <>&nbsp;</>
              )}
            </div>
          </div>
        </section>
      )}
    </section>
  );
};

const AccountInfoSkeleton = () => {
  const formatMessage = useFormatMessage();
  const { siteTitle } = useHeaderMetadata();
  return (
    <>
      <Helmet titleTemplate={`%s - ${siteTitle}`}>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <div className="text-2xl text-bold flex justify-between pb-4">
        <span>{formatMessage(messages.title)}</span>
      </div>
      <div className="p-4 shadow rounded bg-white">
        <div className="mb-4">
          <div className="mb-2 leading-none text-sm capitalize font-bold text-gray-700">
            {formatMessage(messages.name)}
          </div>
          <div className="w-24 bg-gray-200">&nbsp;</div>
        </div>
        <div className="mb-4">
          <div className="mb-2 leading-none text-sm capitalize font-bold text-gray-700">
            {formatMessage(messages.email)}
          </div>
          <div className="w-24 bg-gray-200">&nbsp;</div>
        </div>
        <div className="mb-4">
          <div className="mb-2 leading-none text-sm capitalize font-bold text-gray-700">
            {formatMessage(messages.password)}
          </div>
          <div className="w-24 bg-gray-200">&nbsp;</div>
        </div>
        <div className="mb-4">
          <div className="mb-2 leading-none text-sm capitalize font-bold text-gray-700">
            {formatMessage(messages.preferredCommunicationMethod)}
          </div>
          <div className="w-24 bg-gray-200">&nbsp;</div>
        </div>
      </div>
    </>
  );
};

export default AccountInfo;
export { AccountInfo, AccountInfoSkeleton };
