/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import {
  CommunicationPreferenceType,
  FulfillmentType
} from 'app/common/constants';
import React, { useContext } from 'react';
import { connect } from 'formik';
import { get, isEmpty, values } from 'lodash';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

import {
  CheckboxField,
  DecoratedField,
  OptionalField,
  SelectField
} from 'app/common/components';
import { useCountrySelectValues, useFormatMessage } from 'app/common/hooks';
import { useValidatePhone } from 'app/common/utils/AddressUtils';

import { Phone, PostalCode, StateProvinceRegion } from './components';
import messages from './AddressForm.messages';
import { CheckoutContext } from 'app/checkout/contexts';
import { setDeliveryOptionsStale } from 'app/checkout/contexts/reducers/checkoutReducer';

const AddressForm = props => {
  const { formik, type, legend, disabled = false } = props;
  const formatMessage = useFormatMessage();
  const countrySelectValues = useCountrySelectValues();
  const { checkoutDispatch, deliveryOptionsStale } =
    useContext(CheckoutContext);
  const country = get(formik, 'values.country', 'US');
  const useDeliveryAddress =
    get(formik, 'values.useDeliveryAddress', true) &&
    type === AddressForm.Type.BILLING;
  const hasDeliveryAddress = get(formik, 'values.hasDeliveryAddress', true);
  const isPickup =
    get(formik, 'values.fulfillmentType') === FulfillmentType.PHYSICAL_PICKUP;
  const validatePhone = useValidatePhone();
  const markDeliveryStale = e => {
    if (!deliveryOptionsStale && type === AddressForm.Type.FULFILLMENT) {
      setDeliveryOptionsStale(checkoutDispatch, true);
    }
    formik.handleChange(e);
  };
  const prefCommMeth = get(formik, 'values.preferredCommunicationMethod');
  return (
    <fieldset className="flex flex-col mb-4" disabled={disabled}>
      {!!legend ? (
        legend
      ) : (
        <legend className="mb-4 text-gray-900 font-bold lg:text-lg">
          <span>
            {formatMessage(messages.legend, {
              kind: type,
              isPickup
            })}
          </span>
          <span className="ml-1 text-red-500">*</span>
        </legend>
      )}
      {type === AddressForm.Type.BILLING && hasDeliveryAddress && (
        <UseDeliveryAddress disabled={disabled} />
      )}
      <AnimateHeight duration={200} height={useDeliveryAddress ? 0 : 'auto'}>
        <div
          className="flex flex-col items-start justify-start"
          aria-hidden={useDeliveryAddress}
        >
          {type === AddressForm.Type.SAVED && (
            <DecoratedField
              id={`${type}-addressName`}
              label={formatMessage(messages.addressNameLabel)}
              name="addressName"
              placeholder={formatMessage(messages.addressNamePlaceholder)}
              disabled={disabled}
              autoComplete={`${
                type === AddressForm.Type.BILLING
                  ? 'billing'
                  : type === AddressForm.Type.FULFILLMENT
                  ? 'shipping'
                  : ''
              }`}
            />
          )}
          <DecoratedField
            id={`${type}-firstName`}
            label={formatMessage(messages.firstNameLabel)}
            name="firstName"
            placeholder={formatMessage(messages.firstNamePlaceholder)}
            required
            disabled={disabled}
            validate={value =>
              AddressForm.validateField({
                value,
                message: formatMessage(messages.firstNameRequired)
              })
            }
            autoComplete={`${
              type === AddressForm.Type.BILLING
                ? 'billing'
                : type === AddressForm.Type.FULFILLMENT
                ? 'shipping'
                : ''
            } given-name`}
          />
          <DecoratedField
            id={`${type}-lastName`}
            label={formatMessage(messages.lastNameLabel)}
            name="lastName"
            placeholder={formatMessage(messages.lastNamePlaceholder)}
            required
            disabled={disabled}
            validate={value =>
              AddressForm.validateField({
                value,
                message: formatMessage(messages.lastNameRequired)
              })
            }
            autoComplete={`${
              type === AddressForm.Type.BILLING
                ? 'billing'
                : type === AddressForm.Type.FULFILLMENT
                ? 'shipping'
                : ''
            } family-name`}
          />
          <DecoratedField
            id={`${type}-companyName`}
            label={formatMessage(messages.companyLabel)}
            name="companyName"
            disabled={disabled}
            placeholder={formatMessage(messages.companyPlaceholder)}
            autoComplete={`${
              type === AddressForm.Type.BILLING
                ? 'billing'
                : type === AddressForm.Type.FULFILLMENT
                ? 'shipping'
                : ''
            } organization`}
          />
          {(!isPickup || type !== AddressForm.Type.FULFILLMENT) && (
            <>
              <DecoratedField
                id={`${type}-addressLine1`}
                label={formatMessage(messages.address1Label)}
                name="addressLine1"
                placeholder={formatMessage(messages.address1Placeholder)}
                required
                disabled={disabled}
                onChange={markDeliveryStale}
                validate={value =>
                  AddressForm.validateField({
                    value,
                    message: formatMessage(messages.address1Required)
                  })
                }
                autoComplete={`${
                  type === AddressForm.Type.BILLING
                    ? 'billing'
                    : type === AddressForm.Type.FULFILLMENT
                    ? 'shipping'
                    : ''
                } address-line1`}
              />
              <OptionalField
                name="addressLine2"
                triggerLabel={formatMessage(messages.address2Add)}
              >
                <DecoratedField
                  id={`${type}-addressLine2`}
                  label={formatMessage(messages.address2Label)}
                  name="addressLine2"
                  placeholder={formatMessage(messages.address2Placeholder)}
                  disabled={disabled}
                  onChange={markDeliveryStale}
                  autoComplete={`${
                    type === AddressForm.Type.BILLING
                      ? 'billing'
                      : type === AddressForm.Type.FULFILLMENT
                      ? 'shipping'
                      : ''
                  } address-line2`}
                />
              </OptionalField>
              <OptionalField
                name="addressLine3"
                triggerLabel={formatMessage(messages.address3Add)}
              >
                <DecoratedField
                  id={`${type}-addressLine3`}
                  label={formatMessage(messages.address3Label)}
                  name="addressLine3"
                  placeholder={formatMessage(messages.address3Placeholder)}
                  disabled={disabled}
                  onChange={markDeliveryStale}
                  autoComplete={`${
                    type === AddressForm.Type.BILLING
                      ? 'billing'
                      : type === AddressForm.Type.FULFILLMENT
                      ? 'shipping'
                      : ''
                  } address-line3`}
                />
              </OptionalField>
              <DecoratedField
                id={`${type}-country`}
                label={formatMessage(messages.countryLabel)}
                name="country"
                component={SelectField}
                valueOptions={countrySelectValues}
                required
                disabled={disabled}
                onChange={markDeliveryStale}
                validate={value =>
                  AddressForm.validateField({
                    value,
                    message: formatMessage(messages.countryRequired)
                  })
                }
                autoComplete={`${
                  type === AddressForm.Type.BILLING
                    ? 'billing'
                    : type === AddressForm.Type.FULFILLMENT
                    ? 'shipping'
                    : ''
                } country`}
              />
              <PostalCode
                country={country}
                id={`${type}-postalCode`}
                label={formatMessage(messages.postalCodeLabel, { country })}
                name="postalCode"
                placeholder={formatMessage(messages.postalCodePlaceholder, {
                  country
                })}
                hint={formatMessage(messages.postalCodeHint, { country })}
                required
                onChange={markDeliveryStale}
                disabled={disabled}
                autoComplete={`${
                  type === AddressForm.Type.BILLING
                    ? 'billing'
                    : type === AddressForm.Type.FULFILLMENT
                    ? 'shipping'
                    : ''
                } postal-code`}
              />
              <DecoratedField
                id={`${type}-city`}
                label={formatMessage(messages.cityLabel, { country })}
                name="city"
                required
                disabled={disabled}
                onChange={markDeliveryStale}
                validate={value =>
                  AddressForm.validateField({
                    value,
                    message: formatMessage(messages.cityRequired)
                  })
                }
                placeholder={formatMessage(messages.cityPlaceholder)}
                autoComplete={`${
                  type === AddressForm.Type.BILLING
                    ? 'billing'
                    : type === AddressForm.Type.FULFILLMENT
                    ? 'shipping'
                    : ''
                } address-level2`}
              />
              <StateProvinceRegion
                country={country}
                id={`${type}-stateProvinceRegion`}
                label={formatMessage(messages.stateLabel, { country })}
                name="stateProvinceRegion"
                required
                disabled={disabled}
                onChange={markDeliveryStale}
                validate={value =>
                  AddressForm.validateField({
                    value,
                    message: formatMessage(messages.stateRequired)
                  })
                }
                autoComplete={`${
                  type === AddressForm.Type.BILLING
                    ? 'billing'
                    : type === AddressForm.Type.FULFILLMENT
                    ? 'shipping'
                    : ''
                } address-level1`}
              />
            </>
          )}
          {/* todo: auto format number */}
          {type !== AddressForm.Type.BILLING && (
            <>
              {/* contact phone 1 */}
              <Phone
                autoComplete={`${
                  type === AddressForm.Type.FULFILLMENT ? 'shipping' : ''
                } tel`}
                disabled={disabled}
                hint={formatMessage(messages.contactPhoneHint, { country })}
                id={`${type}-phonePrimary`}
                isPrimary
                label={formatMessage(messages.contactPhoneLabel)}
                name="phonePrimary"
                placeholder={formatMessage(messages.contactPhonePlaceholder, {
                  country
                })}
                required={
                  type === AddressForm.Type.FULFILLMENT &&
                  prefCommMeth !== CommunicationPreferenceType.Email.value
                }
                type="tel"
                validate={value =>
                  validatePhone(
                    value,
                    formatMessage(messages.contactPhoneLabel),
                    type === AddressForm.Type.FULFILLMENT &&
                      prefCommMeth !== CommunicationPreferenceType.Email.value
                  )
                }
              />
              <OptionalField
                name="phoneSecondary"
                triggerLabel={formatMessage(messages.contactPhoneSecondaryAdd)}
              >
                <Phone
                  id={`${type}-phoneSecondary`}
                  label={formatMessage(messages.contactPhoneSecondaryLabel)}
                  name="phoneSecondary"
                  placeholder={formatMessage(messages.contactPhonePlaceholder, {
                    country
                  })}
                  hint={formatMessage(messages.contactPhoneHint, { country })}
                  disabled={disabled}
                  type="tel"
                  validate={value =>
                    validatePhone(
                      value,
                      formatMessage(messages.contactPhoneSecondaryLabel)
                    )
                  }
                  autoComplete={
                    type === AddressForm.Type.FULFILLMENT ? 'shipping' : ''
                  }
                />
              </OptionalField>
              <OptionalField
                name="phoneFax"
                triggerLabel={formatMessage(messages.contactPhoneFaxAdd)}
              >
                <Phone
                  id={`${type}-phoneFax`}
                  label={formatMessage(messages.contactPhoneFaxLabel)}
                  name="phoneFax"
                  placeholder={formatMessage(messages.contactPhonePlaceholder, {
                    country
                  })}
                  hint={formatMessage(messages.contactPhoneHint, { country })}
                  disabled={disabled}
                  type="tel"
                  validate={value =>
                    validatePhone(
                      value,
                      formatMessage(messages.contactPhoneFaxLabel)
                    )
                  }
                  autoComplete={
                    type === AddressForm.Type.FULFILLMENT ? 'shipping' : ''
                  }
                />
              </OptionalField>
            </>
          )}
        </div>
      </AnimateHeight>
    </fieldset>
  );
};

AddressForm.validateField = ({ message, value, required = true }) => {
  if (isEmpty(value) && required) {
    return message;
  }
};

AddressForm.Type = Object.freeze({
  BILLING: 'BILLING',
  FULFILLMENT: 'FULFILLMENT',
  SAVED: 'SAVED'
});

AddressForm.propTypes = {
  disabled: PropTypes.bool,
  legend: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func
  ]),
  type: PropTypes.oneOf(values(AddressForm.Type)).isRequired
};

const UseDeliveryAddress = props => {
  const formatMessage = useFormatMessage();
  return (
    <CheckboxField
      id="useDeliveryAddress"
      name="useDeliveryAddress"
      value="TRUE"
      {...props}
    >
      {formatMessage(messages.useDeliveryAddress)}
    </CheckboxField>
  );
};

export default connect(AddressForm);
export { AddressForm, UseDeliveryAddress };
