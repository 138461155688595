/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useCallback, useEffect, useState } from 'react';

import { Button, Icon } from 'app/common/components';
import { useOnEventOutside, useToggle } from 'app/common/hooks';

const FieldTip = ({ children }) => {
  const [active, toggleActive] = useToggle();
  const onClickOutside = useCallback(() => toggleActive(false), [toggleActive]);
  const containerRef = useOnEventOutside(onClickOutside, 'click');
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const self = containerRef.current;

    if (!!self) {
      setHeight(self.getBoundingClientRect().height);
    }
  }, [containerRef]);

  return (
    <div
      className="group relative flex items-center ml-3 lg:ml-2"
      ref={containerRef}
    >
      <Button
        className="flex items-center text-2xl text-blue-500 leading-none bg-white rounded-full"
        onClick={() => toggleActive()}
        aria-haspopup="true"
        aria-expanded={active}
      >
        <Icon name={['far', 'question-circle']} />
      </Button>
      {children({ active, containerHeight: height })}
    </div>
  );
};

export default FieldTip;
export { FieldTip };
