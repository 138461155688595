/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { values } from 'lodash';
import PropTypes from 'prop-types';

import Button from '../Button';

/**
 * Generic primary button. Takes additional class names to add to the base ones
 * and any props that would normally be applied to a button or link.
 * Content like text and icons should be passed as children.
 */
const PrimaryButton = ({
  children,
  className,
  backgroundColor = 'bg-primary-500 border border-primary-500 hover:bg-primary-700 hover:border-primary-700 focus:bg-primary-700 focus:border-primary-700 active:bg-primary-500 active:border-primary-500',
  borderColor = 'shadow focus:shadow-outline',
  rounded = true,
  textColor = 'text-white hover:text-white',
  size = PrimaryButton.Size.BASE,
  ...props
}) => (
  <Button
    className={classNames(
      'inline-flex items-center justify-center',
      'capitalize font-bold leading-none',
      'appearance-none text-center',
      'focus:outline-none disabled:cursor-not-allowed',
      {
        [className]: !!className,
        [backgroundColor]: !!backgroundColor && !props.disabled,
        [borderColor]: !!borderColor,
        [textColor]: !!textColor,
        'disabled:bg-gray-400 disabled:border-gray-400': !!props.disabled,
        rounded,
        'p-3 text-base md:text-lg lg:text-xl':
          size === PrimaryButton.Size.LARGE,
        'p-2 text-sm sm:p-3 lg:text-base': size === PrimaryButton.Size.BASE,
        'p-2 text-xs lg:text-sm': size === PrimaryButton.Size.SMALL
      }
    )}
    {...props}
  >
    {children}
  </Button>
);

PrimaryButton.Size = Object.freeze({
  SMALL: 'sm',
  BASE: 'base',
  LARGE: 'lg',
  CUSTOM: 'custom'
});

PrimaryButton.propTypes = {
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  textColor: PropTypes.string,
  size: PropTypes.oneOf(values(PrimaryButton.Size))
};

export default PrimaryButton;
export { PrimaryButton };
