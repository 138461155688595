/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext, useEffect, useState } from 'react';
import { areAddressesEquivalent } from '@broadleaf/payment-js';
import { find, isEmpty } from 'lodash';

import { AddressForm, LoadingIcon } from 'app/common/components';
import { useReadCustomerAddressesApi } from 'app/common/hooks';

import { AddressFormLegend, UserAddresses } from './components';
import { CheckoutContext } from 'app/checkout/contexts';
import { setSelectedAddress } from 'app/checkout/contexts/reducers/checkoutReducer';
import { FulfillmentType } from 'app/common/constants';

const AuthenticatedAddressForm = props => {
  const { address, disabled, type } = props;
  const [customerAddresses, setCustomerAddresses] = useState(undefined);
  const [useSavedAddress, setUseSavedAddress] = useState(false);
  const {
    checkoutDispatch,
    selectedAddress,
    showAddressErrors,
    selectedFulfillmentType
  } = useContext(CheckoutContext);

  useEffect(() => {
    setUseSavedAddress(!showAddressErrors);
  }, [showAddressErrors]);

  const { loading, sendCallback: sendReadAddressCallback } =
    useReadCustomerAddressesApi();

  useEffect(() => {
    (async () => {
      const { content = [] } = await sendReadAddressCallback();
      setCustomerAddresses(content);
      setUseSavedAddress(content.length > 0);
    })();
  }, [sendReadAddressCallback]);

  useEffect(() => {
    if (
      selectedFulfillmentType === FulfillmentType.SHIP &&
      type === AddressForm.Type.FULFILLMENT &&
      useSavedAddress
    ) {
    }
  }, [selectedFulfillmentType, type, useSavedAddress]);

  useEffect(() => {
    if (isEmpty(customerAddresses)) {
      return;
    }

    setUseSavedAddress(true);
    const selected = find(customerAddresses, sa =>
      areAddressesEquivalent(sa, address)
    );

    if (!isEmpty(selected)) {
      setSelectedAddress(checkoutDispatch, selected);
    } else if (!isEmpty(address)) {
      setUseSavedAddress(false);
    } else {
      let defaultProp;
      if (type === AddressForm.Type.FULFILLMENT) {
        defaultProp = 'defaultShippingAddress';
      } else if (type === AddressForm.Type.BILLING) {
        defaultProp = 'defaultBillingAddress';
      }

      const defaultAddress = find(customerAddresses, [defaultProp, true]);
      if (defaultAddress) {
        setSelectedAddress(checkoutDispatch, defaultAddress);
      } else {
        setSelectedAddress(checkoutDispatch, customerAddresses[0]);
      }
    }
    // eslint-disable-next-line
  }, [address, customerAddresses, type]);

  if (loading || !customerAddresses) {
    return (
      <div className="text-center">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <>
      {useSavedAddress && (
        <UserAddresses
          customerAddresses={customerAddresses}
          selectedAddress={selectedAddress}
          setUseSavedAddress={setUseSavedAddress}
          type={type}
        />
      )}

      {!useSavedAddress && (
        <AddressForm
          type={type}
          disabled={disabled}
          legend={
            <AddressFormLegend
              customerAddresses={customerAddresses}
              setUseSavedAddress={setUseSavedAddress}
              type={type}
            />
          }
        />
      )}
    </>
  );
};

export default AuthenticatedAddressForm;
export { AuthenticatedAddressForm };
