/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { concat, get, isEmpty, join } from 'lodash';
import PropTypes from 'prop-types';
import { Helmet as ReactHelmet } from 'react-helmet';

import { useGtmPageView, useProductUrl } from 'app/common/hooks';
import { useHeaderMetadata } from 'app/core/components/App';
import { CurrencyContext } from 'app/common/contexts';

/**
 * Helper component that updates the `<head>` content with appropriate metadata.
 *
 * @visibleName Default Product Detail Helmet
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const Helmet = ({ product }) => {
  const {
    description,
    interchangePartNumbers,
    keywords,
    metaDescription,
    metaTitle,
    name,
    promotionalProducts: { ALTERNATE_PART: alternateProducts },
    sku,
    uri
  } = product;
  const descr = metaDescription || description;
  const title = metaTitle || name;
  const { siteTitle } = useHeaderMetadata();
  const url = useProductUrl(uri);
  const canonical = `${window.location.origin}${url}`;
  const { currentCurrency: currencyCode } = React.useContext(CurrencyContext);
  const gtmEcomData = React.useMemo(() => {
    const category = get(product, 'parentCategories[0].name');
    return {
      ecommerce: {
        currencyCode: currencyCode,
        detail: {
          products: [
            {
              brand: get(product, 'attributes.BRAND.value'),
              category: category,
              id: product.sku,
              name: product.description,
              price: get(product, 'priceInfo.price.amount', 0.0),
              position: 0
            }
          ]
        },
        impressions: isEmpty(alternateProducts)
          ? undefined
          : alternateProducts
              .map(({ relatedProduct }) => relatedProduct)
              .map((p, i) => {
                return {
                  brand: get(p, 'attributes.BRAND.value'),
                  category: get(p, 'parentCategories[0].name', category),
                  id: p.sku,
                  name: p.description,
                  position: i,
                  price: get(p, 'priceInfo.price.amount', 0.0),
                  quantity: 1,
                  list: `${product.name}'s Alternate Parts`
                };
              })
      }
    };
  }, [alternateProducts, product, currencyCode]);

  useGtmPageView(title, gtmEcomData);

  return (
    <ReactHelmet titleTemplate={`%s - ${siteTitle}`}>
      <title>{title}</title>
      <meta name="description" content={descr} />
      <meta name="canonical" content={canonical} />
      <meta name="robots" content="index,follow" />
      {!isEmpty(keywords) && (
        <meta
          name="keywords"
          content={join(concat(sku, interchangePartNumbers, keywords), ', ')}
        />
      )}
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={descr} />
      <link rel="canonical" href={canonical} />
    </ReactHelmet>
  );
};

Helmet.propTypes = {
  product: PropTypes.shape({
    /** The Product's description. Used if no `metaDescription` provided. */
    description: PropTypes.string,
    /** The Product's meta keywords */
    keywords: PropTypes.arrayOf(PropTypes.string),
    /** The Product's meta description */
    metaDescription: PropTypes.string,
    /** The Product's meta title */
    metaTitle: PropTypes.string,
    /** The Product's name. Used if no `metaTitle` provided. */
    name: PropTypes.string,
    /** The Product's SEO URL */
    uri: PropTypes.string.isRequired
  }).isRequired
};

export default Helmet;
export { Helmet };
