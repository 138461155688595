/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

import { PaymentInfo } from 'app/checkout/components/CheckoutLayout/components';
import { OrderReviewPayment, OrderReviewPricing } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';
import { parseToken } from 'app/common/utils/PaymentInfoUtils';

import messages from './PaymentSummary.messages';

/**
 * Render component for summarizing payment info for an order's confirmation
 * page.
 */
const PaymentSummary = ({ loading, order, pricingPath }) => {
  const formatMessage = useFormatMessage();

  if (loading) {
    return <PaymentSummary.Skeleton />;
  }

  return (
    <section className="relative py-4 border-t-2 border-solid border-gray-200 lg:py-6">
      <header className="mb-4">
        <h3 className="text-base text-gray-900 font-bold lg:text-lg">
          {formatMessage(messages.totals)}
        </h3>
      </header>
      <section className="flex flex-col">
        <OrderReviewPricing order={order} pricingPath={pricingPath} />
        <OrderReviewPayment order={order} />
      </section>
    </section>
  );
};

PaymentSummary.renderPaymentInfo = ({ paymentInfo }) => {
  let attributes;

  if (
    PaymentInfo.Passthrough.Editable.GatewayType === paymentInfo.gatewayType
  ) {
    const {
      paymentGatewayProperties: { paymentToken }
    } = paymentInfo;
    attributes = parseToken(paymentToken);
  } else if (
    PaymentInfo.PayPal.Editable.GatewayType === paymentInfo.gatewayType
  ) {
    attributes = paymentInfo.attributes;
  } else {
    attributes = {
      creditCardNumber: paymentInfo.attributes.last4,
      creditCardExpDateMonth: paymentInfo.attributes.expMonth.padStart(2, '0'),
      creditCardExpDateYear: paymentInfo.attributes.expYear.slice(-2),
      creditCardType: paymentInfo.attributes.cardType
    };
  }

  return (
    <>
      <PaymentInfo.Stripe.ReadOnly.Method
        className="w-full text-sm md:w-1/2"
        messages={messages}
        attributes={attributes}
      />
      <PaymentInfo.Stripe.ReadOnly.Billing
        className="w-full text-sm md:w-1/2"
        messages={messages}
        billingAddress={paymentInfo.billingAddress}
      />
    </>
  );
};

PaymentSummary.propTypes = {
  order: PropTypes.object.isRequired
};

PaymentSummary.Skeleton = () => (
  <section className="relative py-4 border-t-2 border-solid border-gray-200 lg:py-6">
    <header className="mb-4">
      <h3 className="w-32 text-base text-gray-900 font-bold bg-gray-200 lg:text-lg">
        &nbsp;
      </h3>
    </header>
    <section className="flex flex-col">
      <OrderReviewPricing.Skeleton />
      <OrderReviewPayment.Skeleton />
    </section>
  </section>
);

export default PaymentSummary;
export { PaymentSummary };
