import { useState } from 'react';

/**
 * Disables input zoom on iOS devices
 * @returns {null}
 */
const useIosZoomDisable = () => {
  const [loaded, setStatus] = useState(false);

  document.onreadystatechange = () => {
    setStatus(document.readyState === 'complete');
  };
  if (!isIos() || !loaded) {
    return null;
  }
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content = el.getAttribute('content');
    let re = /maximum-scale=[0-9.]+/g;

    if (re.test(content)) {
      content = content.replace(re, 'maximum-scale=1.0');
    } else {
      content = [content, 'maximum-scale=1.0'].join(', ');
    }

    el.setAttribute('content', content);
  }
};

function isIos() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export default useIosZoomDisable;
