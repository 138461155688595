/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import { AuthContext } from 'app/auth/contexts';
import { AuthService } from 'app/auth/services';
import DefaultOAuthProvider from 'app/auth/services/AuthService/providers/DefaultOAuthProvider';
import { useCurrentApplication, useCurrentTenant } from 'app/common/hooks';
import { Environment } from 'app/common/services';
import { logError } from 'app/common/utils/ApiErrorUtils';

const DiscoveryState = Object.freeze({
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
});

/**
 * Provisions the AuthContext for the application.
 *
 * @author [Nick Crum](https://github.com/ncrum)
 */
const AuthProvider = props => {
  const [discoveryState, setDiscoveryState] = React.useState(
    DiscoveryState.LOADING
  );
  const { id: tenantId } = useCurrentTenant() || {};
  const { id: applicationId } = useCurrentApplication() || {};
  const {
    history: { replace }
  } = props;

  const getAccessToken = React.useCallback(async (options = {}) => {
    return AuthService.getToken(DefaultOAuthProvider.getUserScope());
  }, []);

  const [state, setState] = React.useState({
    ...AuthService.getState(),
    getAccessToken,
    isAuthenticating: discoveryState === DiscoveryState.LOADING
  });

  React.useEffect(() => {
    if (isEmpty(tenantId)) {
      return;
    }

    (async function initialize() {
      try {
        const { clientId, serverId } = await getClientDiscovery(
          tenantId,
          applicationId
        );

        // we set this to true to allow the rest of the app to render while we check the authentication
        setDiscoveryState(DiscoveryState.SUCCESS);

        try {
          const { referrer } = await AuthService.initialize({
            clientId,
            serverId
          });
          if (referrer) {
            replace(referrer);
          }
        } catch (authenticationError) {
          if (window.location.href.indexOf('/callback') > -1) {
            // Error occurred. Redirect to homepage and refresh.
            logError({
              exception: authenticationError,
              when: 'Validating authentication',
              loggerName: 'AuthProvider'
            });
            replace('/');
            window.location.reload();
          }
        }
      } catch (discoveryError) {
        if (window.location.href.indexOf('/callback') > -1) {
          logError({
            exception: discoveryError,
            when: 'Validating authentication',
            loggerName: 'AuthProvider'
          });
          setDiscoveryState(DiscoveryState.ERROR);
          replace('/');
          window.location.reload();
        }
      }
    })();
  }, [applicationId, replace, tenantId]);

  // attach auth interceptor
  React.useEffect(() => AuthService.attachInterceptor(axios), []);

  // subscribe to authentication state
  React.useEffect(() => AuthService.subscribe(setState), []);

  return (
    <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>
  );
};

async function getClientDiscovery(tenantId, applicationId) {
  const { data } = await axios.get(getClientDiscoveryUrl(), {
    headers: {
      'X-Context-Request': JSON.stringify({ applicationId, tenantId })
    },
    params: { type: 'APPLICATION' },
    secure: false
  });
  return data;
}

function getClientDiscoveryUrl() {
  return Environment.get(
    'auth.provider.discovery.url',
    '/auth/client-discovery'
  );
}

export default withRouter(AuthProvider);
export { AuthProvider };
