/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

/**
 * Type used to keep track of which payment is being used at checkout. This does not get sent with the checkout request
 * and only is used within the checkout process itself.
 *
 */
const SelectedPaymentType = Object.freeze({
  /** Payment type selected at checkout is paypal */
  PAYPAL_CHECKOUT_V2: 'PAYPAL_CHECKOUT_V2',
  /** Payment type selected at checkout is pay in store */
  PAY_IN_STORE: 'PAY_IN_STORE'
});

export default SelectedPaymentType;
