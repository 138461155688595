/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';
import { range } from 'lodash';

import messages from './useCardExpirationYearValues.messages';

function useCardExpirationYearValues() {
  const currentYear = new Date().getUTCFullYear();

  return useMemo(() => {
    return [{ label: messages.placeholder, value: '' }].concat(
      range(currentYear, currentYear + 21).map(year => {
        const value = (year + '').slice(-2);
        return { label: value, value };
      })
    );
  }, [currentYear]);
}

export default useCardExpirationYearValues;
