/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Render component for an input field. Accepts any props appropriate for an
 * `<input>`.
 */
const Input = ({
  className,
  handleBlur,
  hasErrors = false,
  onBlur = () => {},
  touched = false,
  widths = 'w-full lg:w-64',
  size = Input.Size.BASE,
  ...props
}) => (
  <input
    className={classNames(
      'text-gray-900 placeholder-gray-500 leading-none',
      'appearance-none bg-white border border-solid rounded',
      'focus:shadow-outline focus:outline-none disabled:bg-gray-200 disabled:border-gray-300 disabled:cursor-not-allowed',
      {
        [className]: !!className,
        [widths]: !!widths,
        'p-3 text-base md:text-lg lg:text-xl': size === Input.Size.LARGE,
        'p-2 text-sm sm:p-3 lg:text-base': size === Input.Size.BASE,
        'p-2 text-xs lg:text-sm': size === Input.Size.SMALL,
        'border-gray-400': !touched,
        'border-green-500': !hasErrors && touched,
        'border-red-500 field-error': hasErrors && touched
      }
    )}
    onBlur={e => {
      if (!!handleBlur) {
        // use override
        return handleBlur(e);
      }

      // use default
      return onBlur(e);
    }}
    type="text"
    {...props}
  />
);

Input.Size = Object.freeze({
  SMALL: 'sm',
  BASE: 'base',
  LARGE: 'lg',
  CUSTOM: 'custom'
});

Input.propTypes = {
  /** Way to override the default onBlur if using formik */
  handleBlur: PropTypes.func,
  hasErrors: PropTypes.bool,
  name: PropTypes.string.isRequired,
  touched: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Class name(s) to affect the input's width.
   *
   * Added because not styles can be overridden via the `className` prop.
   */
  widths: PropTypes.string
};

export default Input;
export { Input };
