/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import ProductOptionHeader from '../ProductOptionHeader';

const ProductOption = ({
  activeChoice,
  children,
  displayOrder = 0,
  error,
  label,
  required,
  type
}) => {
  return (
    <li className="w-full mb-4 last:mb-0" style={{ order: displayOrder }}>
      <ProductOptionHeader label={label} required={required}>
        {!isEmpty(error) && <span className="mb-1 text-red-600"> {error}</span>}
        {isEmpty(error) && !isEmpty(activeChoice) && type === 'COLOR' && (
          <span className="font-normal">
            {': '}
            {activeChoice.label}
          </span>
        )}
      </ProductOptionHeader>
      {children}
    </li>
  );
};

ProductOption.propTypes = {
  activeChoice: PropTypes.shape({
    label: PropTypes.string
  }),
  displayOrder: PropTypes.number,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string
};

export default ProductOption;
export { ProductOption };
