/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import { AuthContext } from 'app/auth/contexts';
import { AuthService } from 'app/auth/services';
import useInterval from 'app/common/hooks/useInterval';
import { Environment, RequestService } from 'app/common/services';

// interval at which we check to see if activity has happened and the session should be refreshed
const activityInterval = Environment.getInteger(
  'session.expiration.activity.interval',
  5 * 60 * 1000
);

function useExpireAuthSession() {
  const { isAuthenticated } = React.useContext(AuthContext);
  const [hasActivityHappened, setHasActivityHappened] = React.useState(false);
  const [expDate, setExpDate] = React.useState(null);
  const [maxExpDate, setMaxExpDate] = React.useState(null);

  React.useEffect(() => {
    if (isAuthenticated) {
      extendSession(setExpDate, setMaxExpDate, setHasActivityHappened);
    }
  }, [isAuthenticated]);

  React.useEffect(
    () =>
      RequestService.attachRequestInterceptor(config => {
        setHasActivityHappened(true);
        return config;
      }),
    []
  );

  useInterval(
    () => {
      const timeLeftInSession = Math.max(expDate.getTime() - Date.now(), 0);
      if (timeLeftInSession <= 0) {
        setExpDate(null);
        setMaxExpDate(null);

        // log the user out (in case there is a logged in user)
        AuthService.logout();

        // reload the page
        window.location.reload();
      }
    },
    expDate ? 1000 : null
  );

  useInterval(
    () => {
      // extend session if activity occurred
      const isSessionExtendable =
        !!expDate && expDate.getTime() < maxExpDate.getTime();
      if (hasActivityHappened && isSessionExtendable && isAuthenticated) {
        extendSession(setExpDate, setMaxExpDate, setHasActivityHappened);
      }
    },
    expDate ? activityInterval : null
  );

  return {};
}

async function extendSession(
  setExpDate,
  setMaxExpDate,
  setHasActivityHappened
) {
  const { exp, max } = await AuthService.getUserExpiration();
  setExpDate(toDate(exp));
  setMaxExpDate(toDate(max));
  setHasActivityHappened(false);
}

function toDate(expString) {
  // ISO8601 date time string
  if (!expString) {
    return null;
  }

  return new Date(expString);
}

export default useExpireAuthSession;
