/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import useDropdown from '../hooks/useDropdown';
import DropdownTrigger from './DropdownTrigger';
import DropdownMenuItem from './DropdownMenuItem';

const OpenTo = Object.freeze({
  AUTO: 'AUTO',
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT',
  CUSTOM: 'CUSTOM'
});

/**
 * The wrapper component for the drop down items.
 *
 */
const DropdownMenu = props => {
  const {
    children,
    className,
    containerClassName = 'relative',
    openTo = OpenTo.AUTO,
    roundness = 'rounded',
    style,
    ...rest
  } = props;
  const { isOpen } = useDropdown();
  const [autoOpenTo, setAutoOpenTo] = useState(OpenTo.LEFT);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      const bounds = ref.current.getBoundingClientRect();
      if (bounds.x <= window.innerWidth / 2) {
        setAutoOpenTo(OpenTo.RIGHT);
      } else {
        setAutoOpenTo(OpenTo.LEFT);
      }
    }
  }, []);

  return (
    <div
      {...rest}
      className={classNames({ [containerClassName]: !!containerClassName })}
      ref={ref}
    >
      {isOpen && (
        <ul
          className={classNames(
            'absolute overflow-hidden shadow z-10',
            className,
            getPositionClassNames(openTo, autoOpenTo),
            { [roundness]: !!roundness }
          )}
          style={style}
        >
          {children}
        </ul>
      )}
    </div>
  );
};

function getPositionClassNames(openTo, autoOpenTo) {
  switch (openTo) {
    case OpenTo.CUSTOM: {
      return '';
    }
    case OpenTo.RIGHT: {
      return 'right-0 origin-top-right';
    }
    case OpenTo.LEFT: {
      return 'left-0 origin-top-left';
    }
    case OpenTo.CENTER: {
      return 'left-half right-auto text-center transform -translate-x-1/2 translate-y-0';
    }
    case OpenTo.AUTO:
    default: {
      return getPositionClassNames(autoOpenTo);
    }
  }
}

DropdownMenu.propTypes = {
  className: PropTypes.string,
  openTo: PropTypes.oneOf([
    OpenTo.AUTO,
    OpenTo.LEFT,
    OpenTo.RIGHT,
    OpenTo.CENTER,
    OpenTo.CUSTOM
  ])
};

DropdownMenu.Item = DropdownMenuItem;
DropdownMenu.Trigger = DropdownTrigger;
DropdownMenu.Divider = ({ className }) => (
  <li className={classNames('p-1 bg-white', { [className]: !!className })}>
    <hr />
  </li>
);
DropdownMenu.OpenTo = OpenTo;

export default DropdownMenu;
