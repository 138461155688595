/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'app/common/components';

import useSandboxColors from '../hooks/useSandboxColors';

/**
 * The component responsible for rendering the preview sandbox dropdown that allows a user to change
 * the current sandbox.
 */
const PreviewSandboxDropdown = ({ activeColors, onChange, options, value }) => {
  return (
    <Dropdown>
      <Dropdown.Menu.Trigger
        triggerClassName="font-semibold focus:outline-none hover:underline"
        label={value.name}
      />
      <Dropdown.Menu
        className="mt-2 border"
        openTo="CENTER"
        style={{
          backgroundColor: activeColors.background.primary,
          borderColor: activeColors.text.primary
        }}
      >
        {options.map(option => (
          <PreviewSandboxDropdownItem
            key={option.id}
            activeColors={activeColors}
            isActive={option.id === value.id}
            onClick={() => onChange(option)}
            sandbox={option}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const SandboxColorsShape = PropTypes.shape({
  /** The background sandbox colors */
  background: PropTypes.shape({
    primary: PropTypes.string
  }),
  /** The text sandbox colors */
  text: PropTypes.shape({
    primary: PropTypes.string
  })
});

const SandboxShape = PropTypes.shape({
  /** The ID of the sandbox */
  id: PropTypes.string.isRequired,

  /** The name of the sandbox */
  name: PropTypes.string.isRequired,

  /** The color of the sandbox, usually a hex value */
  color: PropTypes.string
});

PreviewSandboxDropdown.propTypes = {
  /** Colors of the active sandbox selected */
  activeColors: SandboxColorsShape.isRequired,

  /**
   * Change handler that triggers when the user selects a sandbox.
   *
   * @param {Sandbox} nextSandbox the selected sandbox
   */
  onChange: PropTypes.func,

  /**
   * The set of available sandboxes to choose from.
   */
  options: PropTypes.arrayOf(SandboxShape).isRequired,

  /**
   * The currently active sandbox.
   */
  value: SandboxShape
};

const PreviewSandboxDropdownItem = ({
  activeColors,
  isActive,
  onClick,
  sandbox
}) => {
  const colors = useSandboxColors(sandbox);
  return (
    <Dropdown.Menu.Item
      activeClassName="font-bold"
      className="flex items-center hover:underline"
      inactiveClassName="font-normal"
      isActive={isActive}
      onClick={onClick}
      padding="px-2 py-2"
    >
      <div
        className="w-5 h-5 mr-2 border rounded"
        style={{
          backgroundColor: colors.background.primary,
          borderColor: activeColors.text.primary,
          color: colors.text.primary
        }}
      />
      {sandbox.name}
    </Dropdown.Menu.Item>
  );
};

PreviewSandboxDropdownItem.propTypes = {
  /** Colors of the active sandbox selected */
  activeColors: SandboxColorsShape.isRequired,

  /** Whether or not this item is the active item */
  isActive: PropTypes.bool,

  /** Click handler triggered when this item is clicked by a user */
  onClick: PropTypes.func,

  /** The sandbox used for rendering the content of this item */
  sandbox: SandboxShape
};

export default PreviewSandboxDropdown;
