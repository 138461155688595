/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext, useMemo } from 'react';
import { get, isEmpty } from 'lodash';

import { Icon, TertiaryButton } from 'app/common/components';

import messages from './CSRTransferAnonymousCart.messages';
import { logError } from 'app/common/utils/ApiErrorUtils';
import qs from 'query-string';
import { useCartInfo, useFormatMessage, useRestApi } from 'app/common/hooks';
import { Redirect } from 'react-router-dom';
import { CartContext } from 'app/common/contexts';

const transferCartConfig = {
  method: 'put'
};

/**
 * Render component for the receiving transfer of an anonymous CSR cart.
 *
 * @visibleName CSR Transfer Anonymous Cart page
 */
const CSRTransferAnonymousCart = props => {
  const formatMessage = useFormatMessage();
  const { setCart } = useContext(CartContext);
  const { baseUrl, transferAnonymousCartUrl } = useCartInfo().operations;
  const transferCartToken = useMemo(
    () => get(qs.parse(props.location.search), 'transferCartToken'),
    [props.location.search]
  );
  const url = `${baseUrl}${transferAnonymousCartUrl}?transferCartToken=${transferCartToken}`;
  const { error, exception, loading, response } = useRestApi(
    url,
    transferCartConfig
  );

  const [redirectToHome, setRedirectToHome] = React.useState(false);
  React.useEffect(() => {
    if (!loading) {
      if (!isEmpty(response)) {
        setCart(response);
        setRedirectToHome(true);
      } else {
        logError({
          ...exception,
          when: `transferring anonymous CSR cart from token ${transferCartToken}`
        });
      }
    }
  }, [exception, loading, response, transferCartToken, setCart]);

  if (redirectToHome) {
    return <Redirect push={true} to="/" />;
  }

  return (
    <main className="CSRTransferAnonymousCart flex-grow">
      <header className="py-12 px-6 text-center bg-gray-100 border-b border-gray-300">
        <div className="container">
          <h1 className="text-gray-900 text-4xl font-medium leading-tight">
            {formatMessage(messages.title)}
          </h1>
        </div>
      </header>
      <section className="py-12 px-6 text-center">
        {loading && <CSRTransferAnonymousCartLoading />}
        {error && <CSRTransferAnonymousCartError />}
      </section>
    </main>
  );
};

const CSRTransferAnonymousCartLoading = () => {
  const formatMessage = useFormatMessage();
  return (
    <div className="container flex flex-col items-center">
      <h2 className="flex items-center justify-center text-gray-700 text-xl leading-tight">
        {formatMessage(messages.subtitle)}
      </h2>
    </div>
  );
};

const CSRTransferAnonymousCartError = () => {
  const formatMessage = useFormatMessage();
  return (
    <div className="container flex flex-col items-center">
      <div className="flex items-center mb-4 px-2 py-1 text-sm text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
        <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
        <span>{formatMessage(messages.error.generalError)}</span>
      </div>
      <p>
        {formatMessage(messages.returnHome.preamble)}{' '}
        <TertiaryButton to="/">
          {formatMessage(messages.returnHome.homepage)}
        </TertiaryButton>{' '}
        {formatMessage(messages.returnHome.postamble)}
      </p>
    </div>
  );
};

export default CSRTransferAnonymousCart;
export {
  CSRTransferAnonymousCart,
  CSRTransferAnonymousCartLoading,
  CSRTransferAnonymousCartError
};
