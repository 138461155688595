import {
  nearestInventoryCommonReducer,
  nearestInventoryCommonState
} from 'app/common/components/ItemAvailability/components/NearestAvailableButton/reducer';
import { useReducer } from 'react';
import { NearestInventoryContext } from 'app/common/contexts';
import { useNationalSiteContext } from 'app/common/hooks';

const NearestInventoryProvider = ({ children }) => {
  const { dealerNetwork } = useNationalSiteContext();
  const [commonState, commonDispatch] = useReducer(
    nearestInventoryCommonReducer,
    {
      ...nearestInventoryCommonState,
      searchRadius: dealerNetwork ? 100 : 30
    }
  );

  return (
    <NearestInventoryContext.Provider value={{ commonState, commonDispatch }}>
      {children}
    </NearestInventoryContext.Provider>
  );
};

export default NearestInventoryProvider;
