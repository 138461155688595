import { TertiaryButton } from 'app/common/components/buttons';
import classNames from 'classnames';
import messages from './NearestAvailableButton.messages';
import React from 'react';
import { useFormatMessage } from 'app/common/hooks';
import { LoadingIcon } from 'app/common/components';

/**
 * Component that renders a load more button for a nearby inventory modal.
 * @param {boolean} loading - Flag indicating whether the button is currently loading.
 * @param {function} handleError - Function to handle errors.
 * @param {function} onClick - Function to handle click event.
 * @returns {React.Component} - A React component representing the load more button.
 */
const LoadMoreButton = ({ loading, handleError, onClick }) => {
  const formatMessage = useFormatMessage();
  return (
    <TertiaryButton
      className="relative w-full"
      disabled={loading}
      onClick={onClick}
    >
      {loading && (
        <div className="absolute inset-0 flex justify-center items-center">
          <LoadingIcon className="text-blue-500" />
        </div>
      )}
      <span className={classNames({ 'text-transparent': loading })}>
        {formatMessage(messages.modalShowMoreLocations)}
      </span>
    </TertiaryButton>
  );
};

export { LoadMoreButton };
