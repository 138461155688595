/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { get, isEmpty, mapValues, omitBy, set, toPairs } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { CheckoutContext } from 'app/checkout/contexts';
import {
  determineDefaultCountry,
  determineDefaultStateProvinceRegion
} from 'app/checkout/utils/AddressUtils';
import {
  AddressForm,
  AuthenticatedAddressForm,
  CommunicationPreferences,
  Icon,
  LoadingIcon,
  PrimaryButton,
  SecondaryButton
} from 'app/common/components';
import {
  CommunicationPreferenceType,
  IsoCountries
} from 'app/common/constants';
import { CartContext, LocaleContext } from 'app/common/contexts';
import {
  useCurrentApplication,
  useFormatMessage,
  useFulfillmentInfoApi
} from 'app/common/hooks';
import { normalizePhoneNumber } from 'app/common/utils/AddressUtils';

import messages from './FulfillmentInfoWithOptions.messages';
import {
  useFulfillmentInfoValidationSchema,
  useGtmFulfillmentOptions
} from 'app/checkout/components/CheckoutLayout/components/FulfillmentInfoWithOptions/hooks';
import {
  setFormAddress,
  setShowAddressErrors
} from 'app/checkout/contexts/reducers/checkoutReducer';
import {
  goToCheckoutStep,
  REVIEW_PATH
} from 'app/checkout/components/CheckoutLayout';
import {
  OrderInstructions,
  PurchaseOrderNumber
} from 'app/checkout/components/CheckoutLayout/components/FulfillmentInfoWithOptions/components';
import { Environment } from 'app/common/services';
import { AuthContext } from 'app/auth/contexts';
import { GeneralFormError } from './';

function DealerCaveat({ dealerDisplayName }) {
  return (
    <aside className="flex items-center mb-4 px-2 py-1 text-sm text-blue-700 leading-snug border border-solid border-blue-300 bg-blue-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
      <Icon className="mr-2 md:mr-4" name="info-circle" />
      {/*Div is needed to prevent bolded text from getting flexed as a row*/}
      <div>
        <FormattedMessage
          {...messages.caveat}
          values={{ dealerName: <b>{dealerDisplayName}</b> }}
        />
      </div>
    </aside>
  );
}

DealerCaveat.propTypes = { values: PropTypes.func };

/**
 * Render component for the fulfillment info stage of checkout, including
 * getting the delivery address and delivery method.
 */
const FulfillmentInfoWithOptionsEdit = ({
  active,
  completed,
  history,
  nextStepPath
}) => {
  const formatMessage = useFormatMessage();
  const { cart, setCart } = useContext(CartContext);
  const validationSchema = useFulfillmentInfoValidationSchema(messages);
  const { currentLocale } = useContext(LocaleContext);
  const dealer = useCurrentApplication() || {};
  const checkoutContext = useContext(CheckoutContext);
  const { isAuthenticated } = useContext(AuthContext);
  const {
    formAddress,
    selectedAddress,
    checkoutDispatch,
    selectedFulfillmentType
  } = checkoutContext;

  const goToReviewStep = goToCheckoutStep(
    history,
    REVIEW_PATH,
    {
      editing: false
    },
    0
  );
  const goToNextStepAfterSubmit = goToCheckoutStep(
    history,
    nextStepPath,
    {
      editing: false
    },
    0
  );

  // Cart fields
  const {
    orderInstructions,
    communicationPreference,
    fulfillmentGroup,
    items,
    purchaseOrderNumber
  } = getCartFormFields(cart);

  // App fields
  const dealerName = get(dealer, 'displayName');

  // Address fields
  const countryDefault = determineDefaultCountry(currentLocale);
  const stateProvinceRegionDefault =
    determineDefaultStateProvinceRegion(countryDefault);
  const address = fulfillmentGroup.address;

  const { error, sendCallback: updateFulfillmentGroup } = useFulfillmentInfoApi(
    cart.id,
    fulfillmentGroup.referenceNumber
  );

  useEffect(() => {
    setFormAddress(checkoutDispatch, selectedAddress || address);
    // eslint-disable-next-line
  }, [address, selectedAddress]);

  useGtmFulfillmentOptions(active, items);

  return (
    <>
      <Formik
        initialValues={initialFormValues(
          selectedFulfillmentType,
          formAddress,
          countryDefault,
          stateProvinceRegionDefault,
          orderInstructions,
          communicationPreference,
          purchaseOrderNumber
        )}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          await onFormSubmit({
            checkoutDispatch,
            active,
            updateFulfillmentGroup,
            setCart,
            selectedFulfillmentType,
            goToReviewStep,
            goToNextStepAfterSubmit,
            cart,
            setSubmitting,
            values,
            setErrors
          }).finally(() => setSubmitting(false));
        }}
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ isSubmitting, values }) => (
          <Form className="flex flex-col">
            <GeneralFormError
              error={error}
              isSubmitting={isSubmitting}
              errorMessage={messages.generalError}
            />
            <DealerCaveat dealerDisplayName={dealerName} />
            {isAuthenticated && (
              <AuthenticatedAddressForm
                type={AddressForm.Type.FULFILLMENT}
                address={address}
                isSubmitting={isSubmitting}
                disabled={isSubmitting || !active}
              />
            )}
            {!isAuthenticated && (
              <AddressForm
                type={AddressForm.Type.FULFILLMENT}
                disabled={isSubmitting || !active}
              />
            )}
            <Divider />
            <PurchaseOrderNumber active={active} isSubmitting={isSubmitting} />
            <Divider />
            <CommunicationPreferences disabled={isSubmitting || !active} />
            <Divider />
            <OrderInstructions
              active={active}
              isSubmitting={isSubmitting}
              values={values}
            />
            {isSubmitting && (
              <div className="absolute inset-0 flex justify-center items-center">
                <LoadingIcon className="text-blue-500" />
              </div>
            )}
            <div className="flex justify-end mt-4">
              {completed && (
                <SecondaryButton
                  className="mr-2"
                  disabled={isSubmitting || !active}
                  onClick={goToReviewStep}
                >
                  {formatMessage(messages.cancel)}
                </SecondaryButton>
              )}
              <PrimaryButton type="submit" disabled={isSubmitting || !active}>
                {formatMessage(messages.submit)}
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

function Divider() {
  return <hr className="-mt-3 mb-4 bg-gray-400 lg:mt-0 lg:mb-6" />;
}

function getCartFormFields(cart) {
  const orderInstructions = get(cart, 'attributes[ORDER_INSTRUCTIONS]');
  const communicationPreference = get(cart, 'attributes[COMMUNICATION_PREF]');
  const fulfillmentGroup = get(cart, 'fulfillmentGroups[0]', {});
  const items = get(cart, 'cartItems', []);
  const purchaseOrderNumber = get(cart, 'purchaseOrderNumber');
  return {
    orderInstructions,
    communicationPreference,
    fulfillmentGroup,
    items,
    purchaseOrderNumber
  };
}

function handleFormSubmitFailure({ setShowAddressErrors, setErrors }) {
  return err => {
    const fieldErrors = err.fieldErrors;
    if (isEmpty(fieldErrors)) {
      return;
    }
    // Map the errors to the correct field names so they match the form.
    const errors = toPairs(
      mapValues(fieldErrors, errorArray => errorArray[0].reason)
    ).reduce((acc, [key, value]) => {
      set(
        acc,
        key
          .replace('address.', '')
          .replace('phonePrimary.phoneNumber', 'phonePrimary')
          .replace('phoneSecondary.phoneNumber', 'phoneSecondary')
          .replace('phoneFax.phoneNumber', 'phoneFax')
          .replace(
            'attributes[COMMUNICATION_PREF]',
            'preferredCommunicationMethod'
          ),
        value
      );
      return acc;
    }, {});
    setErrors(errors);
    setShowAddressErrors(true);
  };
}

async function onFormSubmit({
  checkoutDispatch,
  active,
  updateFulfillmentGroup,
  setCart,
  goToNextStepAfterSubmit,
  cart,
  setSubmitting,
  values,
  setErrors
}) {
  const setAddressErrors = show => setShowAddressErrors(checkoutDispatch, show);
  setAddressErrors(false);
  try {
    if (!active) {
      return;
    }
    setSubmitting(true);
    const data = await handleSubmit({
      values,
      updateFulfillmentGroup,
      cart
    });
    if (isEmpty(data)) {
      return;
    }
    setCart(data);
    goToNextStepAfterSubmit();
  } catch (error) {
    handleFormSubmitFailure({ setErrors, setAddressErrors });
  }
}

const handleSubmit = ({
  values,
  updateFulfillmentGroup,
  fulfillmentOption = undefined,
  cart = undefined,
  type = undefined
}) => {
  const {
    preferredCommunicationMethod,
    orderInstructions,
    purchaseOrderNumber
  } = values;
  const cartVersionHeaderName = Environment.get(
    'cart.version.header',
    'X-Cart-Version'
  );
  const cartVersion = get(cart, 'version');
  let configOverride = cartVersion
    ? { headers: { [cartVersionHeaderName]: cartVersion } }
    : {};
  let data = {
    attributes: {
      COMMUNICATION_PREF: preferredCommunicationMethod,
      ORDER_INSTRUCTIONS: orderInstructions
    },
    address: normalizeAddress(values),
    purchaseOrderNumber: purchaseOrderNumber,
    pricedFulfillmentOption: isEmpty(fulfillmentOption)
      ? undefined
      : fulfillmentOption
  };
  if (type) {
    data.type = type;
  }
  const pricedOption = get(
    cart,
    'fulfillmentGroups[0].pricedFulfillmentOption'
  );

  if (!fulfillmentOption && pricedOption) {
    data.pricedFulfillmentOption = pricedOption;
  }
  return updateFulfillmentGroup({
    method: 'patch',
    data,
    ...configOverride
  });
};

function initialFormValues(
  fulfillmentType,
  formAddress,
  countryDefault,
  stateProvinceRegionDefault,
  orderInstructions,
  communicationPreference,
  purchaseOrderNumber
) {
  return {
    fulfillmentType: fulfillmentType,
    fullName: formAddress.fullName || '',
    firstName: formAddress.firstName || '',
    lastName: formAddress.lastName || '',
    addressLine1: formAddress.addressLine1 || '',
    addressLine2: formAddress.addressLine2 || '',
    addressLine3: formAddress.addressLine3 || '',
    country: get(formAddress, 'country') || countryDefault,
    stateProvinceRegion:
      formAddress.stateProvinceRegion || stateProvinceRegionDefault,
    city: formAddress.city || '',
    companyName: formAddress.companyName || '',
    orderInstructions: orderInstructions || '',
    postalCode: formAddress.postalCode || '',
    phonePrimary: get(formAddress, 'phonePrimary.phoneNumber') || '',
    phonePrimaryType: get(formAddress, 'phonePrimary.type') || 'MOBILE',
    phoneSecondary: get(formAddress, 'phoneSecondary.phoneNumber') || '',
    phoneSecondaryType: get(formAddress, 'phoneSecondary.type') || 'MOBILE',
    phoneFax: get(formAddress, 'phoneFax.phoneNumber') || '',
    phoneFaxType: get(formAddress, 'phoneFax.type') || 'FAX',
    preferredCommunicationMethod:
      communicationPreference || CommunicationPreferenceType.Email.value,
    purchaseOrderNumber: purchaseOrderNumber || ''
  };
}

const normalizePhone = (phoneNumber, type) => {
  return isEmpty(phoneNumber)
    ? undefined
    : {
        phoneNumber: normalizePhoneNumber(phoneNumber),
        type: type
      };
};

const normalizeAddress = address => {
  const {
    fullName,
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    companyName,
    country,
    stateProvinceRegion,
    postalCode,
    phonePrimary: phonePrimaryNumber,
    phonePrimaryType,
    phoneSecondary: phoneSecondaryNumber,
    phoneSecondaryType,
    phoneFax: phoneFaxNumber,
    phoneFaxType
  } = address;

  const isoCountry = IsoCountries[country];

  return omitBy(
    {
      fullName,
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      companyName,
      country: isoCountry.alpha2,
      stateProvinceRegion,
      postalCode,
      phonePrimary: normalizePhone(phonePrimaryNumber, phonePrimaryType),
      phoneSecondary: normalizePhone(phoneSecondaryNumber, phoneSecondaryType),
      phoneFax: normalizePhone(phoneFaxNumber, phoneFaxType)
    },
    isEmpty
  );
};

FulfillmentInfoWithOptionsEdit.propTypes = {
  active: PropTypes.bool
};

export const FulfillmentInfoWithOptionsEditable = withRouter(
  FulfillmentInfoWithOptionsEdit
);
