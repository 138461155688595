/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { noop } from 'lodash';

/**
 * This hook can be used to delay a hover action
 *
 * @param {boolean} value - whether the hover is active
 * @param {function} setValue - function to set the hover active state
 * @param {number} [openDelay=500] - the delay before the active state is triggered
 * @param {number} [closeDelay=500] - the delay before the inactive state is triggered
 * @param {boolean} [includeEvent=false] - whether to include the enter or leave
 *    event as the 1st parameter passed to `setValue`
 *
 * @returns {{
 *   handleMouseEnter: function,
 *   handleMouseLeave: function
 * }} callback functions for handling mouse enter and leave
 */
function useDelayHover(
  value,
  setValue,
  openDelay = 500,
  closeDelay = 500,
  includeEvent = false
) {
  const timeout = React.useRef(noop);
  const handleMouseEnter = React.useCallback(
    e => {
      if (value) {
        clearTimeout(timeout.current);
        return;
      }

      e.persist();

      timeout.current = setTimeout(() => {
        setValue(includeEvent ? e : true, includeEvent ? true : undefined);
      }, openDelay);
    },
    [includeEvent, openDelay, setValue, value]
  );
  const handleMouseLeave = React.useCallback(
    e => {
      if (!value) {
        clearTimeout(timeout.current);
        return;
      }

      e.persist();

      timeout.current = setTimeout(() => {
        setValue(includeEvent ? e : false, includeEvent ? false : undefined);
      }, closeDelay);
    },
    [includeEvent, closeDelay, setValue, value]
  );

  return { handleMouseEnter, handleMouseLeave };
}

export default useDelayHover;
