/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useMemo } from 'react';
import { get, merge } from 'lodash';

import { CartContext } from 'app/common/contexts';
import { useRestApi } from 'app/common/hooks';
import { Environment } from 'app/common/services';

import useCartInfo from '../useCartInfo';

function useCreateGuestToken(config, sendImmediate = false) {
  const { baseUrl, guestTokenPath } = useCartInfo().checkoutOperations;
  const { cart } = React.useContext(CartContext);
  const cartId = get(cart, 'id');

  const cartVersionHeaderName = Environment.get(
    'cart.version.header',
    'X-Cart-Version'
  );

  const finalConfig = useMemo(
    () =>
      merge(
        {},
        {
          method: 'post',
          data: {},
          headers: { [cartVersionHeaderName]: cart.version }
        },
        config
      ),
    [config, cartVersionHeaderName, cart.version]
  );

  return useRestApi(
    `${baseUrl}/${cartId}${guestTokenPath}`,
    finalConfig,
    sendImmediate
  );
}

export default useCreateGuestToken;
