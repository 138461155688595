/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';
import { merge } from 'lodash';

import useRestApi from 'app/common/hooks/useRestApi';
import useOrderInfo from 'app/common/hooks/order/useOrderInfo';

/**
 * A representation of a customer's order history by date ranges.
 * @typedef {Object} HistoricalOrderInfo
 * @property {Array.<OrderCountInfo>}} orderInfo
 */

/**
 * An entry in a customer's order history.
 * @typedef {Object} OrderCountInfo
 * @property {String} label - The label for the date range
 * @property {String} groupName - The name of this group
 * @property {Number} count - The number of orders in this date range
 * @property {Number} year - The year of this date range
 * @property {Number} days - The number of days in this date range
 * @property {boolean} allTime - Whether this date range is all time
 */

/**
 * Hook to use to fetch a customer's cart.
 * Response is a page of customer carts
 * @param  {Object}  config - Object containing additional request config such as
 *     the request params or request method
 * @param {String} orderNumber - The order number to request
 * @param  {Boolean} [sendImmediate=false] - Whether to send the request immediately upon
 *     using the hook. If false, then the caller is responsible for triggering
 *     the `sendCallback`. Defaults to `false`.
 * @return {{error: bool, exception: Object, loading: bool, response: Object, sendCallback: function, requestStatus: Object}}
 */
function useReadCustomerOrders(config, orderNumber, sendImmediate = false) {
  const { orderHistoryContextPath } = useOrderInfo().operations;
  const requestPath = `${orderHistoryContextPath}${
    orderNumber ? '/' + orderNumber : ''
  }`;
  const finalConfig = useMemo(
    () =>
      merge(
        {},
        {
          method: 'get'
        },
        config
      ),
    [config]
  );

  return useRestApi(requestPath, finalConfig, sendImmediate);
}

/**
 *
 * @returns {{error: boolean, exception: Object, loading: boolean, response: HistoricalOrderInfo, sendCallback: restApiCallback}|*}
 */
function useReadHistoricalCustomerInfo(applicationId) {
  const { orderHistoryContextPath } = useOrderInfo().operations;

  const requestPath = `${orderHistoryContextPath}/historical-order-info`;
  const finalConfig = useMemo(
    () =>
      merge(
        {},
        {
          method: 'get'
        },
        {
          params: (applicationId && { applicationId }) || {}
        }
      ),
    [applicationId]
  );

  return useRestApi(requestPath, finalConfig, false);
}

export default useReadCustomerOrders;
export { useReadHistoricalCustomerInfo };
