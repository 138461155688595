/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { DecoratedField, SelectField } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import { useCardExpirationMonthValues } from './hooks';
import messages from './ExpirationMonth.messages';

/**
 * Render component for displaying the payment method's expiration date's month.
 *
 * @visibleName Checkout: Payment Info Form: Expiration Date: Month
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const ExpirationMonth = ({ disabled = false }) => {
  const formatMessage = useFormatMessage();
  const expirationMonths = useCardExpirationMonthValues();

  return (
    <DecoratedField
      className="first:m-0"
      containerWidths="w-auto"
      component={SelectField}
      valueOptions={expirationMonths}
      id="creditCardExpDateMonth"
      label={formatMessage(messages.expirationMonthLabel)}
      labelProps={{
        textSizes: 'text-xs'
      }}
      name="creditCardExpDateMonth"
      widths="w-20"
      disabled={disabled}
      required
      showErrors={false}
      validate={value => {
        if (isEmpty(value)) {
          return formatMessage(messages.expirationMonthRequired);
        }
      }}
      autoComplete="cc-exp-month"
    />
  );
};

ExpirationMonth.propTypes = {
  disabled: PropTypes.bool
};

export default ExpirationMonth;
export { ExpirationMonth };
