/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { noop } from 'lodash';

/**
 * @type {Readonly<{
 *   activeOption: ({}|undefined),
 *   variantAttributeChoices: ({}|undefined),
 *   cartItemAttributeChoices: ({}|undefined),
 *   dispatch: Function,
 *   attributeValueToRelatedCombinationsMap: ({}|undefined),
 *   itemChoices: ({}|undefined),
 *   requiresConfiguration: boolean,
 *   unusedAttributeNames: ({}|undefined),
 *   unusedAttributeValues: ({}|undefined),
 * }>}
 */
const initialContext = Object.freeze({
  activeOption: undefined,
  variantAttributeChoices: undefined,
  cartItemAttributeChoices: undefined,
  dispatch: noop,
  attributeValueToRelatedCombinationsMap: undefined,
  itemChoices: undefined,
  requiresConfiguration: false,
  unusedAttributeNames: undefined,
  unusedAttributeValues: undefined
});

/**
 * Contains the configuration for an item.
 *
 * @type {React.Context<Readonly<{
 *   activeOption: ({}|undefined),
 *   variantAttributeChoices: ({}|undefined),
 *   cartItemAttributeChoices: ({}|undefined),
 *   dispatch: Function,
 *   invalidAttributeCombos: ({}|undefined),
 *   itemChoices: ({}|undefined),
 *   requiresConfiguration: boolean,
 *   unusedAttributeNames: ({}|undefined),
 *   unusedAttributeValues: ({}|undefined),
 * }>>}
 */
const ConfigurableItemContext = React.createContext(initialContext);

ConfigurableItemContext.defaultContext = initialContext;

export default ConfigurableItemContext;
