import useRestApi from 'app/common/hooks/useRestApi';
import { useSuggestLocationInfo } from './index';

/**
 * Hook to call the suggest location API. This returns a list of suggested addresses based
 * on the supplied query.
 * @param config
 * @param sendImmediate
 * @returns {{error: boolean, exception: Object, loading: boolean, response: Object, sendCallback: restApiCallback}|*}
 */
function useSuggestLocationApi(config = {}, sendImmediate = false) {
  const { baseUrl, addressSuggest } = useSuggestLocationInfo().suggestStores;
  const fetchUrl = `${baseUrl}${addressSuggest}`;
  return useRestApi(fetchUrl, config, sendImmediate);
}

/**
 * Hook to call the find store API. This returns a list of stores based on the supplied lat/long.
 * @param config
 * @param sendImmediate
 * @returns {{error: boolean, exception: Object, loading: boolean, response: Object, sendCallback: restApiCallback}|*}
 */
function useFindStoreApi(config = {}, sendImmediate = false) {
  const { baseUrl, findStores } = useSuggestLocationInfo().suggestStores;
  const fetchUrl = `${baseUrl}${findStores}`;
  return useRestApi(fetchUrl, config, sendImmediate, true);
}

export default useSuggestLocationApi;
export { useFindStoreApi, useSuggestLocationApi };
