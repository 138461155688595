/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { get, map } from 'lodash';
import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Breadcrumbs,
  MiniProductGrid,
  MiniProductSkeleton
} from 'app/common/components';

const FeaturedParts = props => {
  const { loading, response, iteratee } = props;
  return (
    <section className="HomepageTopSellers relative p-4 md:py-6 lg:py-8">
      <header className="text-center mb-4">
        {loading || response === undefined ? (
          <h2 className="w-48 mx-auto text-2xl font-medium leading-snug bg-gray-200 md:text-4xl">
            &nbsp;
          </h2>
        ) : (
          <h2 className="text-2xl font-medium leading-snug md:text-4xl">
            {get(response, 'name')}
          </h2>
        )}
      </header>
      {loading || response === undefined ? (
        <ul className={'flex-wrap sm:-m-3 sm:flex'}>
          <MiniProductSkeleton usingGrid />
          <MiniProductSkeleton usingGrid />
          <MiniProductSkeleton usingGrid />
          <MiniProductSkeleton usingGrid />
        </ul>
      ) : (
        <MiniProductGrid
          products={map(
            get(response, 'promotionalProducts[FEATURED]', []),
            iteratee
          )}
          containerName="Homepage"
          breadcrumbs={[Breadcrumbs.RootBreadcrumb]}
        />
      )}
    </section>
  );
};

FeaturedParts.propTypes = {
  loading: PropTypes.bool,
  response: PropTypes.any,
  iteratee: PropTypes.func
};

export default FeaturedParts;
export { FeaturedParts };
