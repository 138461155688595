import { get } from 'lodash';

const getWeightLimit = deliveryValidation => {
  const hasWeightLimit = get(
    deliveryValidation,
    'deliverySettings.weightLimit',
    false
  );
  if (!hasWeightLimit) {
    return null;
  }
  const weightLimit = get(
    deliveryValidation,
    'deliverySettings.weightLimitAmount'
  );
  const weightLimitUnit = get(
    deliveryValidation,
    'deliverySettings.weightUnit'
  );
  return `${weightLimit}${convertWeightUnit(weightLimitUnit)}`;
};

const getDimensionLimit = deliveryValidation => {
  const hasDimensionLimit = get(
    deliveryValidation,
    'deliverySettings.dimensionLimit',
    false
  );
  if (!hasDimensionLimit) {
    return null;
  }
  const dimensionLimit = get(
    deliveryValidation,
    'deliverySettings.dimensionLimitAmount'
  );
  const dimensionLimitUnit = get(
    deliveryValidation,
    'deliverySettings.dimensionUnit'
  );
  return `${dimensionLimit}${convertDimensionUnit(dimensionLimitUnit)}`;
};

function convertWeightUnit(unit) {
  if (unit === 'LB') {
    return 'lb';
  } else if (unit === 'KG') {
    return 'kg';
  }
}

function convertDimensionUnit(unit) {
  if (unit === 'IN') {
    return '"';
  } else if (unit === 'CM') {
    return 'cm';
  } else if (unit === 'FT') {
    return "'";
  } else if (unit === 'M') {
    return 'm';
  } else if (unit === 'MM') {
    return 'mm';
  }
}

export { getWeightLimit, getDimensionLimit };
