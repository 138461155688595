/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';

import { useCatalogInfo } from 'app/common/hooks/catalog';
import { Link } from 'app/common/components';

import MenuItem from '../MenuItem';

const Submenu = menuItem => {
  const { label, submenu, type, url } = menuItem;
  const { categoryInfo, productInfo } = useCatalogInfo();
  const { routeBaseContext: categoryPrefix } = categoryInfo;
  const { routeBaseContext: productPrefix } = productInfo;
  let heading = null;

  if (type === 'TEXT') {
    heading = (
      <h5 className="pb-2 text-lg text-center font-bold lg:text-left">
        {label}
      </h5>
    );
  } else {
    let to = url;

    if (type === 'CATEGORY') {
      to = categoryPrefix + url;
    } else if (type === 'PRODUCT') {
      to = productPrefix + url;
    }

    heading = (
      <Link to={to}>
        <h5 className="pb-2 text-lg text-gray-700 text-center font-bold hover:text-primary-600 focus:text-primary-600 active:text-primary-400 lg:text-left">
          {label}
        </h5>
      </Link>
    );
  }

  return (
    <li className="flex flex-col items-center w-full py-6 first:pt-0 last:pb-0 border-b-2 border-solid border-white last:border-b-0 lg:flex-1 lg:items-start lg:py-0 lg:border-b-0">
      {heading}
      <ul className="flex flex-col flex-shrink flex-wrap items-center justify-start list-none lg:items-start lg:mr-6">
        {map(submenu, item => (
          <MenuItem {...item} key={item.label} />
        ))}
      </ul>
    </li>
  );
};

Submenu.propTypes = {
  label: PropTypes.string.isRequired,
  submenu: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string
};

export default Submenu;
export { Submenu };
