/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext } from 'react';
import classNames from 'classnames';
import { toInteger } from 'lodash';

import { SecondaryButton } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from '../../NearestAvailableButton.messages';
import { SET_SEARCH_RADIUS, SET_USING_GEO_LOCATION } from '../../reducer';
import { LocationPin } from 'app/store-locator/components/LocationPin';
import { NearestInventoryContext } from 'app/common/contexts';

/**
 *
 * @param onSubmit On radius change submit callback
 * @param {NearestInventoryState} state State of the nearest inventory reducer
 * @param dispatch Dispatch function for nearest inventory reducer
 */
const RadiusSelector = ({ onSubmit, state, dispatch }) => {
  const formatMessage = useFormatMessage();
  const { commonState, commonDispatch } = useContext(NearestInventoryContext);
  const { usingGeoLocation, usingStoreLocation, searchRadius } = commonState;
  let milesFromLabel = formatMessage(messages.modalSearchRadiusUnits);
  let searchLabel;
  let orLabel;
  if (usingGeoLocation) {
    searchLabel = formatMessage(messages.modalSearchRadiusCurrentLocation);
    orLabel = formatMessage(messages.modalSearchOrUseSelectedLocation);
  } else {
    searchLabel = formatMessage(messages.modalSearchRadiusSelectedLocation);
    orLabel = formatMessage(messages.modalSearchOrUseCurrentLocation);
  }
  return (
    <>
      <div className="flex items-center flex-wrap pb-4">
        <span className="inline-flex mr-0 md:mr-2 font-bold text-base md:text-lg lg:text-xl">
          {formatMessage(messages.modalLocationSearchRadiusLabel)}
        </span>
        <input
          autoComplete="off"
          className={classNames(
            'ml-2 p-2 sm:p-3',
            'text-base text-gray-900 placeholder-gray-500 leading-none',
            'appearance-none bg-white border border-solid border-gray-400 rounded',
            'focus:shadow-outline focus:outline-none disabled:bg-gray-200 disabled:border-gray-300 disabled:cursor-not-allowed',
            'lg:text-base'
          )}
          min={30}
          max={99999}
          step={5}
          name="radius"
          onChange={e => {
            const radiusVal = toInteger(e.target.value);
            if (isNaN(radiusVal)) {
              return;
            }
            commonDispatch(SET_SEARCH_RADIUS(radiusVal));
          }}
          value={searchRadius}
          type="number"
          onKeyUp={e => {
            e.key === 'Enter' && onSubmit(searchRadius);
          }}
        />
        <div
          className={
            'inline-flex mt-2 sm:mt-0 sm:ml-2 text-base md:text-lg lg:text-xl'
          }
        >
          <span className="inline-flex pt-1 sm:pt-2">
            {milesFromLabel}
            <span className={'font-bold'}>&nbsp;{searchLabel}</span>
          </span>
          <SecondaryButton
            className="ml-2 md:ml-4"
            id="search-radius-button"
            onClick={() => onSubmit(searchRadius)}
          >
            {formatMessage(messages.modalUpdateSearchRadius)}
          </SecondaryButton>
        </div>
      </div>
      <div className="flex items-center flex-wrap mb-4 pb-4 border-b border-gray-400">
        <LocationOriginButton
          usingGeoLocation={usingGeoLocation}
          usingStoreLocation={usingStoreLocation}
          orLabel={orLabel}
          commonDispatch={commonDispatch}
          dispatch={dispatch}
        />
      </div>
    </>
  );
};

const LocationOriginButton = ({
  usingGeoLocation,
  usingStoreLocation,
  orLabel,
  commonDispatch
}) => {
  return (
    <div className="flex items-center flex-wrap mb-0 pb-0 ">
      <span>Or&nbsp;</span>
      <button
        onClick={() => {
          commonDispatch(SET_USING_GEO_LOCATION(!usingGeoLocation));
        }}
        className={'flex'}
      >
        <div className={'flex text-primary-500'}>{orLabel}</div>
        {usingStoreLocation && (
          <div className={'flex h-full'}>
            <LocationPin className={'pl-1 pt-1'} color={'#1A6E99'} />
          </div>
        )}
      </button>
    </div>
  );
};

export default RadiusSelector;
export { RadiusSelector };
