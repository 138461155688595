/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

const AttributeOption = ({ optionLabel, label, value }) => {
  const displayValue = label ? label : value;

  return (
    <div className="text-s font-bold text-gray-800 md:text-sm">
      {optionLabel}
      {': '}
      {displayValue}
    </div>
  );
};

AttributeOption.propTypes = {
  optionLabel: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default AttributeOption;
export { AttributeOption };
