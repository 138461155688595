/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty } from 'lodash';

import { useFormatMessage } from 'app/common/hooks';

import Currency from '../../Currency';
import messages from './OrderReviewPricing.messages';

const OrderReviewPricing = ({ order, pricingPath }) => {
  const formatMessage = useFormatMessage();
  const orderPricingPath = pricingPath ? pricingPath : 'orderPricing';
  const {
    currency,
    totalTax,
    fulfillmentTotal,
    subtotal,
    adjustmentsTotal,
    total
  } = get(order, orderPricingPath, {});
  let coreCost = get(order, 'coreCostTotal', {});
  if (isEmpty(coreCost)) {
    coreCost = get(order, orderPricingPath + '.totalCoreCost', {});
  }
  return (
    <section className="flex flex-col mb-8 pt-2 px-4 bg-white border border-solid border-gray-200 rounded">
      <div className="flex justify-between not-italic capitalize">
        <div className="mr-8 capitalize">
          {formatMessage(messages.subtotal)}
        </div>
        {!isEmpty(subtotal) && <Currency {...subtotal} />}
        {isEmpty(subtotal) && <Currency amount={0} currency={currency} />}
      </div>
      {!isEmpty(coreCost) && coreCost.amount > 0 && (
        <div className="flex justify-between capitalize">
          <div className="mr-8 capitalize">
            {formatMessage(messages.coreCost)}
          </div>
          <div>
            <Currency {...coreCost} />
          </div>
        </div>
      )}
      {!isEmpty(adjustmentsTotal) && adjustmentsTotal.amount > 0 && (
        <div className="flex justify-between not-italic capitalize text-red-600">
          <div className="mr-8 capitalize">
            {formatMessage(messages.adjustments)}
          </div>
          <div>
            {'-'}
            <Currency {...adjustmentsTotal} />
          </div>
        </div>
      )}
      <div className="flex justify-between capitalize">
        <div className="mr-8 capitalize">
          {formatMessage(messages.delivery)}
        </div>
        {!isEmpty(fulfillmentTotal) ? (
          <Currency {...fulfillmentTotal} />
        ) : (
          <Currency amount={0} currency={currency} />
        )}
      </div>
      <div className="flex justify-between capitalize">
        <div className="mr-8 capitalize">{formatMessage(messages.taxes)}</div>
        {!isEmpty(totalTax) && <Currency {...totalTax} />}
        {isEmpty(totalTax) && <Currency amount={0} currency={currency} />}
      </div>
      <div className="flex justify-between py-2 text-lg font-bold capitalize">
        <div className="mr-8 capitalize">{formatMessage(messages.total)}</div>
        {!isEmpty(total) && <Currency {...total} />}
        {isEmpty(total) && <Currency amount={0} currency={currency} />}
      </div>
    </section>
  );
};

OrderReviewPricing.Skeleton = () => (
  <section className="flex flex-col mb-8 pt-2 px-4 border border-solid border-gray-200 bg-white rounded">
    <div className="flex justify-between mb-px capitalize">
      <div className="w-20 mr-8 capitalize bg-gray-200">&nbsp;</div>
      <div className="w-10 mr-8 capitalize bg-gray-200">&nbsp;</div>
    </div>
    <div className="flex justify-between mb-px capitalize">
      <div className="w-20 mr-8 capitalize bg-gray-200">&nbsp;</div>
      <div className="w-10 mr-8 capitalize bg-gray-200">&nbsp;</div>
    </div>
    <div className="flex justify-between mb-px capitalize">
      <div className="w-20 mr-8 capitalize bg-gray-200">&nbsp;</div>
      <div className="w-10 mr-8 capitalize bg-gray-200">&nbsp;</div>
    </div>
    <div className="flex justify-between py-2 text-lg font-bold capitalize">
      <div className="w-20 mr-8 capitalize bg-gray-200">&nbsp;</div>
      <div className="w-10 mr-8 capitalize bg-gray-200">&nbsp;</div>
    </div>
  </section>
);

export default OrderReviewPricing;
export { OrderReviewPricing };
