/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Render component for a checkbox input.
 */
const CheckboxInput = ({ disabled, field, value, ...props }) => (
  <input
    {...props}
    {...field}
    className={classNames('absolute opacity-0', {
      'hover:cursor-not-allowed': !!disabled,
      'hover:cursor-pointer': !disabled
    })}
    type="checkbox"
    value={value}
    disabled={disabled}
  />
);

CheckboxInput.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any
  }).isRequired,
  required: PropTypes.bool,
  value: PropTypes.string.isRequired
};

export default CheckboxInput;
export { CheckboxInput };
