/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';
import { merge } from 'lodash';

import useRestApi from '../../useRestApi';
import usePriceContextHeader from '../usePriceContextHeader';
import DefaultConfig from './DefaultConfig';

/**
 * Sends requests to get the PriceLists for the current context.
 *
 * @param {String } fetchUrl - URL of the price lists endpoint
 * @param {Object} config - optional object with request config including params
 *     to be merged with the default set.
 * @param {boolean} sendImmediate - Whether to send the request immediately upon
 *     using the hook. If false, then the caller is responsible for triggering
 *     the `sendCallback`. Defaults to `true`.
 *
 * @return {{error: bool, exception: Object, loading: bool, response: Object, sendCallback: function}}
 */
function usePriceListApi(
  fetchUrl,
  config = DefaultConfig,
  sendImmediate = true
) {
  const priceContextHeader = usePriceContextHeader();
  const finalConfig = useMemo(
    () => merge({}, DefaultConfig, config, priceContextHeader),
    [config, priceContextHeader]
  );
  return useRestApi(fetchUrl, finalConfig, sendImmediate);
}

export default usePriceListApi;
