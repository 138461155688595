/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
/**
 * The possible types of targets a `ProductOption's` `ItemChoice` can have.
 *
 * @type {{
 *   CATEGORY: string,
 *   SPECIFIC_VARIANTS: string,
 *   SPECIFIC_PRODUCTS: string
 * }}
 */
const ItemChoiceTargetType = Object.freeze({
  /**
   * The item choice is among `Products` from a predetermined list.
   */
  SPECIFIC_PRODUCTS: 'SPECIFIC_PRODUCTS',
  /**
   * The item choice is among `Variants` from a predetermined list.
   */
  SPECIFIC_VARIANTS: 'SPECIFIC_VARIANTS',
  /**
   * The item choice is among `Products` within a `Category`. Used as a
   * convenience over specifying an explicit set of products with
   * `SPECIFIC_PRODUCTS` and an `SpecificItemChoice`.
   */
  CATEGORY: 'CATEGORY'
});

export default ItemChoiceTargetType;
