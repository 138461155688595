/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  emptyMessage: {
    id: 'Nav.Minicart.Dialog.emptyMessage',
    defaultMessage: 'Your cart is empty'
  },
  moreItemsInCartSingular: {
    id: 'Nav.Minicart.Dialog.moreItemsInCartSingular',
    defaultMessage: 'There is 1 more item in your cart'
  },
  moreItemsInCartPlural: {
    id: 'Nav.Minicart.Dialog.moreItemsInCartPlural',
    defaultMessage: 'There are {count, number} more items in your cart'
  },
  closeMinicart: {
    id: 'Nav.Minicart.Dialog.close',
    defaultMessage: 'Dismiss Minicarrt'
  }
});
