/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { isEmpty, omit, set, toString } from 'lodash';
import { default as qs } from 'query-string';

/**
 * Custom parse method that can handle the complex format of our filters.
 *
 * @param {string} search - The encoded URL search queries to parse
 *
 * @return {{value: *, key: *}|{}} parsed and decoded search queries
 */
function parseSearch(search) {
  if (isEmpty(search)) {
    return {};
  }

  return search
    .slice(1)
    .split('&')
    .map(param => {
      let [key, value] = param.split('=');
      key = decodeURIComponent(key);
      value = decodeURIComponent(value);
      return { key, value };
    })
    .reduce((accumulator, { key, value }) => {
      set(accumulator, key, value);
      return accumulator;
    }, {});
}

/**
 * Custom stringify method that can handle the complex format of our filters.
 *
 * @param {Object} params - parsed URL search query params
 *
 * @return {string} stringified and encoded params
 */
function stringifyParams(params) {
  const { filters = [] } = params;
  const newFilters = filters.reduce(
    (accumulator, { name, ranges, values }, i) => {
      accumulator[`filters[${i}].name`] = name;

      if (!isEmpty(ranges)) {
        ranges.forEach(({ maxValue, minValue }, j) => {
          if (!isEmpty(toString(maxValue))) {
            accumulator[`filters[${i}].ranges[${j}].maxValue`] = maxValue;
          }

          if (!isEmpty(toString(minValue))) {
            accumulator[`filters[${i}].ranges[${j}].minValue`] = minValue;
          }
        });
      }

      if (!isEmpty(values)) {
        accumulator[`filters[${i}].values`] = values;
      }

      return accumulator;
    },
    {}
  );

  return qs.stringify({ ...omit(params, ['filters']), ...newFilters });
}

export { parseSearch, stringifyParams };
