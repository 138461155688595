/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    id: 'Pdp.ProductOptions.ItemChoice.Choice.QuantitySelect.label',
    defaultMessage: 'Quantity'
  },
  hintMin: {
    id: 'Pdp.ProductOptions.ItemChoice.Choice.QuantitySelect.hint.min',
    defaultMessage: 'Must be at least {min, number}'
  },
  hintMax: {
    id: 'Pdp.ProductOptions.ItemChoice.Choice.QuantitySelect.hint.max',
    defaultMessage: 'Cannot be more than {max, number}'
  }
});
