/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { get, isEmpty, isInteger, map, range, toNumber } from 'lodash';
import PropTypes from 'prop-types';

import { AuthContext } from 'app/auth/contexts';
import { Button, Dropdown } from 'app/common/components';
import {
  useCartInfo,
  useFormatNumber,
  useRestApi,
  useToggle
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';
import { ListDetailsContext } from 'app/my-account/components/ListDetails/contexts';

/**
 * Render component for the cart item's quantity. Can be made to use as a way
 * to adjust the quantity.
 *
 * @visibleName Cart: Item: Pricing: Quantity
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const ListItemQuantity = ({ currentQuantity = 1, id, product }) => {
  const formatNumber = useFormatNumber();
  const [quantity, setQuantity] = React.useState(currentQuantity);
  const options = React.useMemo(
    () => ListItemQuantity.options(formatNumber),
    [formatNumber]
  );
  const {
    listOperations: { baseUrl, itemsContextPath }
  } = useCartInfo();
  const { user } = React.useContext(AuthContext);
  const customerRef = React.useMemo(
    () => ({
      customerId: get(user, 'serviceId'),
      username: get(user, 'username'),
      isRegistered: true,
      accountId: get(user, 'attributes[account_id]')
    }),
    [user]
  );
  const config = React.useMemo(
    () => ({
      method: 'patch',
      params: customerRef
    }),
    [customerRef]
  );
  const {
    listItems,
    setListItems,
    list: { id: listId }
  } = React.useContext(ListDetailsContext);
  const {
    exception,
    error,
    sendCallback: updateQuantity
  } = useRestApi(
    `${baseUrl}/${listId}${itemsContextPath}/${id}`,
    config,
    false
  );
  const [useSelect, toggleUseSelect] = useToggle(false);

  React.useEffect(() => setQuantity(currentQuantity), [currentQuantity]);

  React.useEffect(() => {
    if (error) {
      logError({ ...exception, when: `updating quantity for item ${id}` });
    }
  }, [error, exception, id]);

  if (useSelect) {
    return (
      <Dropdown className="CartItemQuantity relative w-18 align-top lg:flex-grow-0 lg:flex-shrink-0 lg:basis-1/4">
        <Dropdown.Menu.Trigger
          className="w-full"
          triggerClassName="justify-between w-full pr-4 py-2 pl-3 text-sm font-bold leading-none bg-white rounded shadow focus:outline-none focus:shadow-outline lg:text-base"
        >
          <span>{formatNumber(quantity)}</span>
        </Dropdown.Menu.Trigger>
        <Dropdown.Menu containerClassName="w-full" className="left-0">
          {options.map(option => {
            const { label, value } = option;

            return (
              <Dropdown.Menu.Item
                className="text-sm leading-none lg:text-base"
                isActive={quantity === value}
                key={value}
                onClick={async () => {
                  const data = await updateQuantity({
                    data: { quantity: value }
                  });

                  const newItems = listItems.filter(
                    listItem => listItem.id !== id
                  );

                  if (!isEmpty(data)) {
                    newItems.push({ ...data, product });
                  }

                  setListItems(newItems);
                }}
              >
                {label}
              </Dropdown.Menu.Item>
            );
          })}
          <Dropdown.Menu.Divider />
          <Dropdown.Menu.Item
            className="CartItemQuantityToggleSelect"
            padding=""
          >
            <Button
              className="w-full pr-4 py-2 pl-3 text-left text-sm leading-none focus:outline-none lg:text-base"
              onClick={() => {
                toggleUseSelect(false);
              }}
            >
              {`${formatNumber(10)}+`}
            </Button>
          </Dropdown.Menu.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  return (
    <div className="relative w-18 align-top lg:flex-grow-0 lg:flex-shrink-0 lg:basis-1/4">
      <div className="relative clear-both box-border text-base text-left">
        <input
          autoComplete="off"
          className={classNames(
            'w-full p-2',
            'text-sm text-gray-900 placeholder-gray-500 leading-none',
            'appearance-none bg-white border border-solid rounded',
            'focus:shadow-outline focus:outline-none disabled:bg-gray-200 disabled:border-gray-300 disabled:cursor-not-allowed',
            'lg:px-3 lg:text-base'
          )}
          maxLength={9}
          onChange={async e => {
            const value = e.currentTarget.value;
            const number = toNumber(value);

            if (value === '') {
              // ensure that only typing in 0 results in removal
              setQuantity('');
            } else if (isInteger(number) && number >= 0) {
              setQuantity(number);
              const data = await updateQuantity({ data: { quantity: number } });
              const newItems = listItems.filter(listItem => listItem.id !== id);

              if (!isEmpty(data)) {
                newItems.push({ ...data, product });
              }

              setListItems(newItems);
            }
          }}
          type="text"
          value={quantity}
        />
      </div>
    </div>
  );
};

ListItemQuantity.options = formatNumber => {
  return map(range(1, 11, 1), opt => ({
    label: formatNumber(opt),
    value: opt
  }));
};

ListItemQuantity.propTypes = {
  /** Current quantity of the item in the cart */
  currentQuantity: PropTypes.number,
  /** ID of the item */
  id: PropTypes.string.isRequired
};

export default ListItemQuantity;
export { ListItemQuantity };
