import messages from './PreferredLocation.messages';
import {
  useApplicationParameter,
  useCurrentApplication,
  useCustomerService,
  useFormatMessage,
  useNationalSiteContext,
  useRestApi
} from 'app/common/hooks';
import { PreferredStoreModal } from '../PreferredStoreModal';
import { PrimaryButton } from 'app/common/components';
import {
  LOADING_LOCATIONS,
  LOADING_LOCATIONS_COMPLETE,
  OPEN_MODAL,
  STORE_LOOKUP_ERROR
} from '../PreferredStoreModal/reducer/preferredStoreReducer';
import { get, isEmpty } from 'lodash';
import React, { useContext, useEffect, useMemo } from 'react';
import { useFindStores } from 'app/store-locator/hooks';
import {
  SET_STATE,
  SET_STORES
} from '../PreferredStoreModal/reducer/preferredStoreActions';
import { CustomerContext } from 'app/common/contexts';
import { AuthContext } from 'app/auth/contexts';
import { getStoreRowFromApplication } from 'app/layout/components/Header/components/StoreDropdown/util/storeDropdownUtil';

/**
 * A button that opens the {@link PreferredStoreModal} and allows the user to choose a preferred store.
 * @param preferredLocation The user's current preferred location
 * @param {PreferredModalState} state The preferred store modal state
 * @param dispatch The dispatch function for the preferred store modal reducer
 * @param {boolean} loadingPreferredApplication Is the user's current preferred application loading?
 * @returns {Element}
 * @constructor
 */
const ChoosePreferredButton = ({
  preferredLocation = {},
  state,
  dispatch,
  loadingPreferredApplication = false
}) => {
  const formatMessage = useFormatMessage();
  const { dealerNetwork } = useNationalSiteContext();
  useFindStores(
    findStoreArgs({
      state,
      dispatch,
      maxDistanceMiles: dealerNetwork ? 5000 : undefined
    })
  );
  useSetPreferredStore(state, dispatch);
  const modalRef = React.useRef(undefined);
  if (loadingPreferredApplication) {
    return null;
  }
  return (
    <>
      <PreferredStoreModal
        state={state}
        dispatch={dispatch}
        modalRef={modalRef}
        preferredLocation={preferredLocation}
      />
      <PrimaryButton
        size={PrimaryButton.Size.SMALL}
        onClick={e => {
          e.preventDefault();
          dispatch(OPEN_MODAL);
        }}
        className={'mt-2'}
      >
        {formatMessage(messages.changePreferred, {
          hasSelected: !isEmpty(preferredLocation)
        })}
      </PrimaryButton>
    </>
  );
};

function useSetPreferredStore(state, dispatch) {
  const [, setApplicationParam] = useApplicationParameter();
  const application = useCurrentApplication();
  const { identifierValue: applicationParam } = application || {};
  const [updating, setUpdating] = React.useState(false);
  const { customer, setCustomer } = React.useContext(CustomerContext);
  const { baseUrl } = useCustomerService();
  const { user } = useContext(AuthContext);
  const customerId = get(user, 'serviceId');
  const config = useMemo(() => ({ method: 'put' }), []);
  const { sendCallback } = useRestApi(
    `${baseUrl}/${customerId}`,
    config,
    false,
    true
  );
  const { newPreferredLocationNumber, preferredLocationNumber } = state;

  useEffect(() => {
    if (updating || !customer || isEmpty(newPreferredLocationNumber)) {
      return;
    }
    if (newPreferredLocationNumber === preferredLocationNumber) {
      return;
    }
    setUpdating(true);
    (async () => {
      try {
        const response = await sendCallback({
          data: { ...customer },
          params: { preferredLocation: newPreferredLocationNumber }
        });
        setCustomer({
          ...response,
          preferredLocation: newPreferredLocationNumber
        });
        if (applicationParam !== newPreferredLocationNumber) {
          setApplicationParam(newPreferredLocationNumber);
        } else {
          dispatch({
            type: SET_STATE,
            payload: {
              preferredLocation: getStoreRowFromApplication(application),
              preferredLocationNumber: newPreferredLocationNumber,
              newPreferredLocationNumber: undefined,
              preferredStoreModalOpen: false
            }
          });
        }
      } catch (e) {
        dispatch(STORE_LOOKUP_ERROR(messages.setPreferredStoreError));
      } finally {
        setUpdating(false);
      }
    })();
  }, [
    customer,
    dispatch,
    setApplicationParam,
    setCustomer,
    newPreferredLocationNumber,
    preferredLocationNumber,
    sendCallback,
    updating,
    setUpdating,
    applicationParam,
    application
  ]);
}

function findStoreArgs({ state, dispatch, maxDistanceMiles }) {
  const { userSelectedAddress, dealerNumber } = state;
  const onStartRequest = () => {
    dispatch(LOADING_LOCATIONS);
  };
  const onSuccess = ({ currentLocation, stores }) => {
    dispatch({
      type: SET_STORES,
      payload: {
        currentLocation,
        stores,
        autoCompleteOpen: false,
        autoCompleteResults: undefined,
        isLoadingLocations: false
      }
    });
  };
  const onFailure = () => {
    dispatch(STORE_LOOKUP_ERROR(messages.storeLookupError));
  };
  const onComplete = () => {
    dispatch(LOADING_LOCATIONS_COMPLETE);
  };
  const params = {
    latLong: userSelectedAddress,
    dealerNumber,
    maxDistanceMiles
  };
  return {
    onStartRequest,
    onSuccess,
    onFailure,
    onComplete,
    params
  };
}

export { ChoosePreferredButton };
