/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { values } from 'lodash';
import PropTypes from 'prop-types';

import { AssetType } from 'app/common/constants';
import notFound from 'app/common/img/image-not-found.jpg';
import { addParams } from 'app/common/utils/PathUtils';

/**
 * Render component for the cart item's primary asset.
 */
const ItemAsset = ({ imageAsset, productName }) => {
  const {
    altText,
    title,
    contentUrl: src
  } = imageAsset || {
    contentUrl: notFound
  };
  return (
    <figure
      className="relative block basis-1/2 flex-grow flex-shrink mr-2"
      style={{ maxWidth: '8rem' }}
    >
      <img
        className="block rounded w-full h-auto mx-auto"
        style={{ maxHeight: '6rem' }}
        src={`${addParams(src, { thumbnail: true })}`}
        alt={altText || productName}
        title={title}
        height="96"
        width="96"
      />
    </figure>
  );
};

ItemAsset.propTypes = {
  primaryAsset: PropTypes.shape({
    altText: PropTypes.string,
    contentUrl: PropTypes.string,
    /** HTML to embed in place of having a source URL */
    embedCode: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    type: PropTypes.oneOf(values(AssetType))
  }),
  productName: PropTypes.string
};

export default ItemAsset;
export { ItemAsset };
