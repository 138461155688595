/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty, join } from 'lodash';
import PropTypes from 'prop-types';
import { Helmet as ReactHelmet } from 'react-helmet';

import { useFormatMessage, useGtmPageView } from 'app/common/hooks';
import { useHeaderMetadata } from '../../../../../../core/components/App';
import { TopBrandContext } from '../../../../../../common/contexts';
import {
  buildMetaDescription,
  buildMetaTitle
} from '../../../../../shared/utils/MetaTitleUtils';
import messages from '../../../../../shared/utils/MetaTitleUtils/MetaTitleUtils.messages';

const CategoryListHelmet = ({
  description,
  keywords = [],
  metaDescription,
  metaTitle,
  name: categoryName,
  url,
  rootsOnly
}) => {
  const { siteTitle } = useHeaderMetadata();
  const baseUrl = `${window.location.origin}${url}`;
  const canonical = baseUrl;

  const activeTopBrand = React.useContext(TopBrandContext).activeTopBrand;

  const title = buildMetaTitle(
    activeTopBrand,
    categoryName,
    metaTitle,
    siteTitle,
    rootsOnly
  );

  const formatMessage = useFormatMessage();
  const descr = buildMetaDescription(
    activeTopBrand,
    categoryName,
    metaDescription,
    siteTitle,
    formatMessage(messages.metaDescriptionTemplate),
    rootsOnly
  );

  useGtmPageView(title);

  return (
    <ReactHelmet>
      <title>{title}</title>
      <meta name="description" content={descr} />
      <meta name="canonical" content={canonical} />
      <meta name="robots" content="index,follow" />
      {!isEmpty(keywords) && (
        <meta name="keywords" content={join(keywords, ', ')} />
      )}
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={descr} />
      <link rel="canonical" href={canonical} />
    </ReactHelmet>
  );
};

CategoryListHelmet.propTypes = {
  /** The page's description. Used if no `metaDescription` provided. */
  description: PropTypes.string,
  /** The page's meta keywords */
  keywords: PropTypes.arrayOf(PropTypes.string),
  /** The page's meta description */
  metaDescription: PropTypes.string,
  /** The page's meta title */
  metaTitle: PropTypes.string,
  /** The page's name. Used if no `metaTitle` provided. */
  name: PropTypes.string,
  /** The page's SEO URI */
  url: PropTypes.string.isRequired
};

export default CategoryListHelmet;
export { CategoryListHelmet };
