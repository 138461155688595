/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Icon, Portal } from 'app/common/components';

import messages from './Modal.messages';

/**
 * Renders a modal within a Portal on `document.body`.
 */
const Modal = React.forwardRef(
  (
    {
      className,
      centerDialog,
      dialogClassName,
      children,
      closeOnClickOutside,
      isOpen,
      onClose,
      size,
      targetDOMNode
    },
    ref
  ) => {
    if (!isOpen) {
      return null;
    }

    return (
      <Portal element="div" target={targetDOMNode}>
        <div
          ref={ref}
          className={cx('Modal', { 'Modal-open': isOpen }, className)}
          onClick={closeOnClickOutside ? onClose : undefined}
        >
          <Modal.Dialog
            centered={centerDialog}
            dialogClassName={dialogClassName}
            size={size}
          >
            {children}
          </Modal.Dialog>
        </div>
        <div className="Modal__backdrop" />
      </Portal>
    );
  }
);

Modal.Size = Object.freeze({
  EXTRA_SMALL: 'xs',
  SMALL: 'sm',
  MEDIUM: 'md',
  LARGE: 'lg',
  EXTRA_LARGE: 'xl'
});

Modal.propTypes = {
  /** whether or not to vertically center the dialog */
  centerDialog: PropTypes.bool,
  /** Whether or not this modal should close when we click outside */
  closeOnClickOutside: PropTypes.bool,
  /** Whether or not this modal is open */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Handler that is called when the modal is closed.
   */
  onClose: PropTypes.func,
  /** optional prop for setting the target (if not document.body) for the modal */
  targetDOMNode: PropTypes.oneOfType([
    PropTypes.instanceOf(Element),
    PropTypes.string
  ])
};

Modal.defaultProps = {
  centerDialog: false,
  closeOnClickOutside: true,
  targetDOMNode: document ? document.body : undefined,
  size: Modal.Size.LARGE
};

Modal.Dialog = ({ centered, dialogClassName: className, children, size }) => (
  <div
    className={cx('Modal__dialog', {
      [className]: !!className,
      'Modal__dialog--centered': centered,
      'Modal__dialog--xs': size === Modal.Size.EXTRA_SMALL,
      'Modal__dialog--sm': size === Modal.Size.SMALL,
      'Modal__dialog--md': size === Modal.Size.MEDIUM,
      'Modal__dialog--lg': size === Modal.Size.LARGE,
      'Modal__dialog--xl': size === Modal.Size.EXTRA_LARGE
    })}
  >
    <div
      className="Modal__content"
      onClick={e => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      {children}
    </div>
  </div>
);

Modal.Header = ({ children, className }) => (
  <div className={cx('Modal__header', { [className]: !!className })}>
    {children}
  </div>
);

Modal.Header.Title = ({ children, className }) => (
  <div className={cx('Modal__header__title', { [className]: !!className })}>
    {children}
  </div>
);

Modal.Body = ({ children, className }) => (
  <div className={cx('Modal__body', { [className]: !!className })}>
    {children}
  </div>
);

Modal.Footer = ({ children, className }) => (
  <div className={cx('Modal__footer', { [className]: !!className })}>
    {children}
  </div>
);

Modal.Close = ({ onClose }) => (
  <FormattedMessage {...messages.closeAriaLabel}>
    {closeAriaLabel => (
      <button
        type="button"
        className="Modal__close"
        aria-label={closeAriaLabel}
        onClick={onClose}
      >
        <Icon
          className="mx-2 text-center"
          name="times"
          style={{ flex: '0 1 5%' }}
        />
      </button>
    )}
  </FormattedMessage>
);

export default Modal;
