/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    id: 'ItemAvailability.NearestAvailableButton.label',
    defaultMessage: 'Check Nearby Locations'
  },
  modalTitle: {
    id: 'ItemAvailability.NearestAvailableButton.modal.title',
    defaultMessage: 'Find part {name} in stock nearby'
  },
  modalCancel: {
    id: 'ItemAvailability.NearestAvailableButton.modal.cancel',
    defaultMessage: 'Cancel'
  },
  modalSelectLocation: {
    id: 'ItemAvailability.NearestAvailableButton.modal.location.select',
    defaultMessage: 'Select'
  },
  modalLocationName: {
    id: 'ItemAvailability.NearestAvailableButton.modal.location.label',
    defaultMessage: 'Location'
  },
  modalLocationDistance: {
    id: 'ItemAvailability.NearestAvailableButton.modal.location.distance.label',
    defaultMessage: 'Distance'
  },
  modalShowMoreLocations: {
    id: 'ItemAvailability.NearestAvailableButton.modal.location.showMore',
    defaultMessage: 'Show More'
  },
  modalPricingCaveat: {
    id: 'ItemAvailability.NearestAvailableButton.modal.pricingCaveat',
    defaultMessage:
      'Changing locations may cause prices to change for items in your cart.'
  },
  modalNoResults: {
    id: 'ItemAvailability.NearestAvailableButton.modal.noResults',
    defaultMessage:
      'No locations found nearby with part in stock. Try expanding your search.'
  },
  modalSearchRadius: {
    id: 'ItemAvailability.NearestAvailableButton.modal.searchRadius',
    defaultMessage: '{radius, number} miles'
  },
  modalSearchRadiusUnits: {
    id: 'ItemAvailability.NearestAvailableButton.modal.searchRadius.units',
    defaultMessage: 'miles of '
  },
  modalSearchRadiusCurrentLocation: {
    id: 'ItemAvailability.NearestAvailableButton.modal.searchRadius.currentLocation',
    defaultMessage: ' My Location'
  },
  modalSearchRadiusSelectedLocation: {
    id: 'ItemAvailability.NearestAvailableButton.modal.searchRadius.selectedLocation',
    defaultMessage: ' Selected Dealer'
  },
  modalSearchOrUseCurrentLocation: {
    id: 'ItemAvailability.NearestAvailableButton.modal.searchRadius.useCurrentLocation',
    defaultMessage: 'Use My Location'
  },
  modalSearchOrUseSelectedLocation: {
    id: 'ItemAvailability.NearestAvailableButton.modal.searchRadius.useSelectedLocation',
    defaultMessage: 'Use Selected Dealer'
  },
  modalUpdateSearchRadius: {
    id: 'ItemAvailability.NearestAvailableButton.modal.searchRadius.update',
    defaultMessage: 'Search'
  },
  modalLocationSearchRadiusLabel: {
    id: 'ItemAvailability.NearestAvailableButton.modal.searchRadius.label',
    defaultMessage: 'Locations within'
  },
  modalOtherItemsUnavailableCaveat: {
    id: 'ItemAvailability.NearestAvailableButton.modal.otherItemsUnavailableCaveat',
    defaultMessage:
      'Some of the items in your cart are not available at the selected location.'
  },
  modalSaveUnavailableItemsBeforeSwitchPrompt: {
    id: 'ItemAvailability.NearestAvailableButton.modal.saveUnavailableItemsBeforeSwitchPrompt',
    defaultMessage:
      'To save these items for later, please sign in or register an account.'
  },
  modalSaveUnavailableItemsSignInPrompt: {
    id: 'ItemAvailability.NearestAvailableButton.modal.saveUnavailableItemsSignInPrompt',
    defaultMessage: 'Locations within'
  },
  modalSaveUnavailableItemsSignInLabel: {
    id: 'ItemAvailability.NearestAvailableButton.modal.saveUnavailableItemsSignInLabel',
    defaultMessage: 'Sign In'
  },
  modalSaveUnavailableItemsRegisterLabel: {
    id: 'ItemAvailability.NearestAvailableButton.modal.saveUnavailableItemsRegisterLabel',
    defaultMessage: 'Create Account'
  },
  modalDontSwitchIfItemsNotAvailable: {
    id: 'ItemAvailability.NearestAvailableButton.modal.dontSwitchIfItemsNotAvailable',
    defaultMessage: "Don't switch locations"
  },
  modalUnavailableItemsOptionsOr: {
    id: 'ItemAvailability.NearestAvailableButton.modal.unavailableItemsOptionsOr',
    defaultMessage: 'or'
  },
  modalUnavailableItemsOptionsSwitchAnyway: {
    id: 'ItemAvailability.NearestAvailableButton.modal.unavailableItemsOptionsSwitchAnyway',
    defaultMessage: 'Switch anyway'
  },
  modalGenericError: {
    id: 'ItemAvailability.NearestAvailableButton.modal.genericError',
    defaultMessage:
      'We encountered an error processing your request. Please try again later.'
  },
  modalGeolocationPermissionError: {
    id: 'ItemAvailability.NearestAvailableButton.modal.geolocationPermissionError',
    defaultMessage:
      'Location permissions are disabled for this site. Please enable location services to use your current location.'
  },
  modalGeolocationUnavailableError: {
    id: 'ItemAvailability.NearestAvailableButton.modal.geolocationUnavailableError',
    defaultMessage:
      'We were unable to resolve your location. Try again later or set a location to find nearby locations.'
  },
  modalGeolocationTimeoutError: {
    id: 'ItemAvailability.NearestAvailableButton.modal.geolocationTimeoutError',
    defaultMessage:
      'Location services timed out while trying to use your current location. Try again later or set a location to find nearby locations.'
  },
  modalAddToCartDuplicateError: {
    id: 'ItemAvailability.NearestAvailableButton.select.error.duplicate',
    defaultMessage: '{partNumber} is already in your cart.'
  }
});
