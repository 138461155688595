/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty } from 'lodash';
import { default as qs } from 'query-string';
import { withRouter } from 'react-router-dom';

import { AuthContext } from 'app/auth/contexts';
import { useCartInfo, useRestApi, useToggle } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import { ListsContext } from '../../contexts';

const ListsProvider = ({ children, location }) => {
  const { user } = React.useContext(AuthContext);
  const fetchConfig = React.useMemo(() => {
    const params = qs.parse(location.search);
    const q = params.q;
    return {
      params: {
        customerId: get(user, 'serviceId'),
        username: get(user, 'username'),
        isRegistered: true,
        accountId: get(user, 'attributes[account_id]'),
        cq: isEmpty(q) ? undefined : `name=eqic='*${q}*'`,
        offset: 0,
        forward: true,
        size: 50,
        sort: params.sort
      },
      paramsSerializer: p => qs.stringify(p, { indices: false })
    };
  }, [user, location.search]);
  const {
    listOperations: { baseUrl }
  } = useCartInfo();
  const {
    error,
    exception,
    loading,
    response,
    sendCallback: refetchLists
  } = useRestApi(baseUrl, fetchConfig);
  const [lists, setLists] = React.useState(undefined);
  const [isAdding, setIsAdding] = React.useState(false);
  const [selectAll, toggleSelectAll] = useToggle(false);
  const [selectedLists, setSelectedLists] = React.useState([]);

  React.useEffect(() => {
    setIsAdding(false);
    setLists(get(response, 'content'));
  }, [response]);

  React.useEffect(() => {
    if (error) {
      logError({
        ...exception,
        when: "fetching customer's saved lists"
      });
    }
  }, [error, exception]);

  React.useEffect(() => {
    if (selectAll) {
      setSelectedLists([...lists]);
    } else {
      setSelectedLists([]);
    }
  }, [lists, selectAll]);

  const context = React.useMemo(
    () => ({
      isAdding,
      setIsAdding,
      lists,
      setLists,
      selectAll,
      toggleSelectAll,
      selectedLists,
      setSelectedLists,
      refetchLists,
      resolvingLists: !error && (loading || response === undefined)
    }),
    [
      error,
      isAdding,
      lists,
      refetchLists,
      loading,
      selectAll,
      selectedLists,
      toggleSelectAll,
      response
    ]
  );
  return (
    <ListsContext.Provider value={context}>{children}</ListsContext.Provider>
  );
};

export default withRouter(ListsProvider);
export { ListsProvider };
