/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import useIntl from '../useIntl';

/**
 * Useful hook when translating messages in a functional component.
 *
 * <pre>
 * {@code
 * const formatMessage = useFormatMessage();
 * const label = formatMessage({ id: 'message.id', defaultMessage: 'default message' });
 * }
 * </pre>
 *
 * @TODO: refactor when https://github.com/yahoo/react-intl/pull/1186 is merged in react-intl
 * @return {Function} the intl formatMessage function, or a noop
 */
function useFormatMessage() {
  const intl = useIntl();
  return intl.formatMessage || defaultMessageOrId;
}

/**
 * Defaults to the message descriptor's default message or ID. This will only be used if there is
 * no IntlContext to consume.
 *
 * @param {{ defaultMessage: *, id: * }} input the message descriptor
 * @returns {string}}
 */
function defaultMessageOrId(input) {
  return input.defaultMessage || input.id;
}

export default useFormatMessage;
