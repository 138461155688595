/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

import React from 'react';
import { map } from 'lodash';

import { OrderReviewItems } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from './OrderReviewFulfillments.messages';

const OrderReviewFulfillments = ({
  isReturns = false,
  orderItems,
  loading
}) => {
  const formatMessage = useFormatMessage();
  let key = 0;
  return (
    <div>
      {loading ? (
        <OrderReviewFulfillments.ItemsSkeleton />
      ) : (
        <section className="relative flex flex-col pb-8 border-t-2 border-solid border-gray-200 rounded-b">
          <header className="mb-4 pt-4 lg:pt-6">
            <h3 className="flex items-center capitalize font-bold lg:text-lg">
              {isReturns
                ? formatMessage(messages.returns)
                : formatMessage(messages.items)}
            </h3>
          </header>
          {map(orderItems, (items, status) => (
            <OrderReviewItems orderItems={items} status={status} key={key++} />
          ))}
        </section>
      )}
    </div>
  );
};

OrderReviewFulfillments.ItemsSkeleton = () => (
  <section className="relative flex flex-col pb-8 border-t-2 border-solid border-gray-200 rounded-b">
    <header className="mb-4 pt-4 lg:pt-6">
      <h3 className="w-32 flex items-center capitalize font-bold bg-gray-200 lg:text-lg">
        &nbsp;
      </h3>
    </header>
    <section className="w-full py-2 px-4 bg-white border border-solid border-gray-200 rounded">
      <div className="flex justify-start py-2 border-b border-solid border-gray-400 last:border-b-0">
        <div className="relative block w-24 h-24 mr-2 bg-gray-200" />
        <section className="flex flex-wrap flex-auto items-start justify-between w-7/10 pr-2">
          <div className="w-40 mb-2 pr-4 bg-gray-200">&nbsp;</div>
          <div className="w-32 mb-2 pr-4 bg-gray-200">&nbsp;</div>
        </section>
      </div>
    </section>
  </section>
);

export default OrderReviewFulfillments;
export { OrderReviewFulfillments };
