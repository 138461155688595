/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import cx from 'classnames';
import sanitize from 'sanitize-html';

import { Button, Icon } from 'app/common/components';
import { useContentTargeterApi, useTargeterContext } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

const MaintenanceBanner = () => {
  const [visible, setVisible] = React.useState(true);
  const targeterContext = useTargeterContext('Maintenance Banner');
  const { error, exception, loading, response } =
    useContentTargeterApi(targeterContext);

  if (error) {
    logError({
      ...exception,
      when: 'fetching a Content Targeter / Item for the Targeter named Maintenance Banner'
    });

    return null;
  }

  if (loading || response === undefined) {
    return null;
  }

  const { content } = response;

  return (
    <>
      {content && (
        <div
          className={cx(
            'flex items-center justify-center py-2 bg-red-500 text-red-100',
            {
              hidden: !visible
            }
          )}
        >
          <div className="container">
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(content, {
                  allowedAttributes: {
                    '*': ['class']
                  }
                })
              }}
            />
          </div>
          <Button
            className="absolute right-0 flex items-center justify-center p-2 mr-6"
            onClick={() => setVisible(false)}
            type="button"
          >
            <Icon name="times" style={{ flex: '0 1 5%' }} />
          </Button>
        </div>
      )}
    </>
  );
};

export default MaintenanceBanner;
export { MaintenanceBanner };
