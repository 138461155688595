/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    id: 'Nav.AccountDropdown.label',
    defaultMessage: 'My Account'
  },
  changePassword: {
    id: 'Nav.AccountDropdown.Menu.changePassword',
    defaultMessage: 'Change Password'
  },
  dashboard: {
    id: 'Nav.AccountDropdown.Menu.dashboard',
    defaultMessage: 'Profile Details'
  },
  orders: {
    id: 'Nav.AccountDropdown.Menu.orders',
    defaultMessage: 'Your Orders'
  },
  addresses: {
    id: 'Nav.AccountDropdown.Menu.addresses',
    defaultMessage: 'Your Addresses'
  },
  payment: {
    id: 'Nav.AccountDropdown.Menu.payment',
    defaultMessage: 'Your Payment Methods'
  },
  communication: {
    id: 'Nav.AccountDropdown.Menu.communication',
    defaultMessage: 'Your Communication Preferences'
  },
  preferredLocation: {
    id: 'Nav.AccountDropdown.Menu.preferredLocation',
    defaultMessage: 'Your Preferred Location'
  },
  logout: {
    id: 'Nav.AccountDropdown.Menu.logout',
    defaultMessage: 'Sign Out'
  },
  lists: {
    id: 'Nav.AccountDropdown.Menu.lists',
    defaultMessage: 'Your Lists'
  }
});
