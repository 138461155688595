/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { Price } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from './DialogSummary.messages';

/**
 * Render component for the mini shopping cart dialog summary.
 *
 * @visibleName Minicart: Dialog: Summary
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const DialogSummary = props => {
  const { subtotal = { amount: 0 }, quantity } = props;
  const amount = get(subtotal, 'amount', 0);
  const currency = get(subtotal, 'currency');
  const formatMessage = useFormatMessage();
  return (
    <div className="border-t border-gray-200">
      <div className="flex items-center justify-between px-4 py-2 font-bold text-lg text-center">
        <span>{formatMessage(messages.subtotalLabel, { quantity })}</span>
        <Price className="ml-1" price={{ amount, currency }} />
      </div>
    </div>
  );
};

DialogSummary.propTypes = {
  subtotal: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired
  })
};

export default DialogSummary;
export { DialogSummary };
