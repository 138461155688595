/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEqual } from 'lodash';

import {
  determineDefaultCountry,
  determineDefaultStateProvinceRegion
} from 'app/checkout/utils/AddressUtils';
import {
  CartContext,
  LocaleContext,
  PaymentContext
} from 'app/common/contexts';

import useBillingAddress from '../useBillingAddress';

/**
 * Generates the initial form values for the StripePaymentInfo form.
 *
 * @param {string} defaultPaymentGatewayType - The type of payment gateway to
 *     fallback to if there is no previous billing info to use
 *
 * @return {{}}
 */
function useInitialFormValues(defaultPaymentGatewayType) {
  const { currentLocale } = React.useContext(LocaleContext);
  const { cart } = React.useContext(CartContext);
  const { payments } = React.useContext(PaymentContext);
  const countryDefault = determineDefaultCountry(currentLocale);
  const stateProvinceRegionDefault =
    determineDefaultStateProvinceRegion(countryDefault);
  const addressInfo = useBillingAddress(cart);

  return React.useMemo(() => {
    const { useFulfillmentAddress, hasFulfillmentAddress } = addressInfo;
    const activePayment = get(payments, 'content[0]', []);
    const { gatewayType, type } = activePayment;

    const isSameGateway = isEqual(defaultPaymentGatewayType, gatewayType);

    const address = addressInfo.address || {};

    return {
      fullName: address.fullName || '',
      firstName: address.firstName || '',
      lastName: address.lastName || '',
      addressLine1: address.addressLine1 || '',
      addressLine2: address.addressLine2 || '',
      country: get(address, 'country') || countryDefault,
      stateProvinceRegion:
        address.stateProvinceRegion || stateProvinceRegionDefault,
      city: address.city || '',
      companyName: address.companyName || '',
      postalCode: address.postalCode || '',
      useDeliveryAddress: useFulfillmentAddress,
      hasDeliveryAddress: hasFulfillmentAddress,
      paymentType: (isSameGateway && type) || 'CREDIT_CARD',
      gatewayType: (isSameGateway && gatewayType) || defaultPaymentGatewayType,
      creditCardNumber: '',
      creditCardHolder: '',
      creditCardSecCode: '',
      creditCardExpMonth: '',
      creditCardExpYear: '',
      shouldSavePaymentToCustomer: false,
      paymentGatewayProperties:
        (isSameGateway && activePayment.paymentGatewayProperties) || {},
      attributes: (isSameGateway && activePayment.attributes) || {}
    };
  }, [
    addressInfo,
    countryDefault,
    stateProvinceRegionDefault,
    defaultPaymentGatewayType,
    payments
  ]);
}

export default useInitialFormValues;
