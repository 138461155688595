/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';

import { useMenuApi } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import { MenuItem } from './components';

/**
 * Render component for the site footer nav.
 *
 * @visibleName Site Footer Nav
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const FooterMenu = ({ fetchUrl, name }) => {
  const {
    exception,
    error,
    loading,
    response: menu
  } = useMenuApi(fetchUrl, name);

  if (error) {
    logError({ ...exception, when: `fetching Menu ${name}` });

    return null;
  }

  const { label, submenu = [] } = menu || {};
  // whether the menu fetched has nested submenus vs just a list of simple items
  // const hasNestedSubmenus = !isEmpty(get(submenu, '[0].submenu', []));

  return (
    <div className="flex w-full py-4 border-b-2 border-solid border-white md:py-6 lg:pt-8 lg:pb-0 lg:border-b-0">
      <div className="flex flex-col items-center justify-center w-full">
        {loading || menu === undefined ? (
          <h4 className="w-48 mb-2 text-lg text-center font-bold bg-gray-200">
            &nbsp;
          </h4>
        ) : (
          !isEmpty(label) && (
            <h4 className="pb-2 text-lg text-center font-bold">{label}</h4>
          )
        )}
        <ul className="flex flex-col items-center justify-center w-full list-none md:flex-row md:items-start">
          {loading || menu === undefined ? (
            <>
              <li className="SkeletonMenuItem w-24 mb-1 bg-gray-200 md:mb-0 md:mr-4">
                &nbsp;
              </li>
              <li className="SkeletonMenuItem w-24 mb-1 bg-gray-200 md:mb-0 md:mr-4">
                &nbsp;
              </li>
              <li className="SkeletonMenuItem w-24 mb-1 bg-gray-200 md:mb-0 md:mr-4">
                &nbsp;
              </li>
              <li className="SkeletonMenuItem w-24 mb-1 bg-gray-200 md:mb-0 md:mr-4">
                &nbsp;
              </li>
              <li className="SkeletonMenuItem w-24 mb-1 bg-gray-200 md:mb-0 md:mr-4">
                &nbsp;
              </li>
              <li className="SkeletonMenuItem w-24 bg-gray-200">&nbsp;</li>
            </>
          ) : (
            map(submenu, item => <MenuItem {...item} key={item.label} />)
          )}
        </ul>
      </div>
    </div>
  );
};

FooterMenu.propTypes = {
  /** Endpoint from which to fetch the menu data */
  fetchUrl: PropTypes.string.isRequired,
  /** Name of the menu to fetch */
  name: PropTypes.string.isRequired
};

export default FooterMenu;
export { FooterMenu };
