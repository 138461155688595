/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { isEmpty, isString } from 'lodash';

function parseToken(paymentToken) {
  return isEmpty(paymentToken) ? {} : JSON.parse(atob(paymentToken));
}

function createToken(info) {
  return isEmpty(info) ? undefined : btoa(JSON.stringify(info));
}

function determineCreditCardType(number) {
  number = isString(number) ? number.replace(/\D/g, '') : number;

  if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(number)) {
    return 'VISA';
  }

  if (
    /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/.test(
      number
    )
  ) {
    return 'MASTERCARD';
  }

  if (/^3[47][0-9]{13}$/.test(number)) {
    return 'AMEX';
  }

  if (/^6(?:011|5[0-9]{2})[0-9]{12}$/.test(number)) {
    return 'DISCOVER';
  }

  return undefined;
}

export { createToken, determineCreditCardType, parseToken };
