/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { isEmpty, map, range, toNumber, isInteger } from 'lodash';
import PropTypes from 'prop-types';

import { CartItemErrorContext } from 'app/cart/context';
import { Button, Dropdown } from 'app/common/components';
import { useCartItemApi, useFormatNumber, useToggle } from 'app/common/hooks';
import { CartContext } from 'app/common/contexts';
import { logError } from 'app/common/utils/ApiErrorUtils';

/**
 * Render component for the cart item's quantity. Can be made to use as a way
 * to adjust the quantity.
 *
 * @visibleName Cart: Item: Pricing: Quantity
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const CartItemQuantity = ({ currentQuantity = 1, id }) => {
  const formatNumber = useFormatNumber();
  const [quantity, setQuantity] = React.useState(currentQuantity);
  const options = React.useMemo(
    () => CartItemQuantity.options(formatNumber),
    [formatNumber]
  );
  const config = React.useMemo(
    () => ({
      method: 'patch',
      params: {
        price: true
      }
    }),
    []
  );
  const { setCart } = React.useContext(CartContext);
  const { setException } = React.useContext(CartItemErrorContext);
  const [qtyUpdateTimer, setQtyUpdateTimer] = React.useState();
  const { exception, error, sendCallback } = useCartItemApi(id, config, false);
  const [useSelect, toggleUseSelect] = useToggle(false);

  React.useEffect(() => setQuantity(currentQuantity), [currentQuantity]);

  if (error) {
    logError({ ...exception, when: `updating quantity for item ${id}` });
    setException && setException(exception);
  } else {
    setException && setException();
  }

  if (useSelect) {
    return (
      <Dropdown className="CartItemQuantity relative w-18 align-top lg:flex-grow-0 lg:flex-shrink-0 lg:basis-1/4">
        <Dropdown.Menu.Trigger
          className="w-full"
          triggerClassName="justify-between w-full pr-4 py-2 pl-3 text-sm font-bold leading-none bg-white rounded shadow focus:outline-none focus:shadow-outline lg:text-base"
        >
          <span>{formatNumber(quantity)}</span>
        </Dropdown.Menu.Trigger>
        <Dropdown.Menu containerClassName="w-full" className="left-0">
          {options.map(option => {
            const { label, value } = option;

            return (
              <Dropdown.Menu.Item
                className="text-sm leading-none lg:text-base"
                isActive={quantity === value}
                key={value}
                onClick={() =>
                  sendCallback({ data: { quantity: value } }).then(data => {
                    if (!isEmpty(data)) {
                      setCart(data);
                    }
                  })
                }
              >
                {label}
              </Dropdown.Menu.Item>
            );
          })}
          <Dropdown.Menu.Divider />
          <Dropdown.Menu.Item
            className="CartItemQuantityToggleSelect"
            padding=""
          >
            <Button
              className="w-full pr-4 py-2 pl-3 text-left text-sm leading-none focus:outline-none lg:text-base"
              onClick={() => {
                toggleUseSelect(false);
              }}
            >
              {`${formatNumber(10)}+`}
            </Button>
          </Dropdown.Menu.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  const delay = 750; // Update cart after 3/4 second
  return (
    <div className="relative w-18 align-top lg:flex-grow-0 lg:flex-shrink-0 lg:basis-1/4">
      <div className="relative clear-both box-border text-base text-left">
        <input
          autoComplete="off"
          className={classNames(
            'w-full p-2',
            'text-sm text-gray-900 placeholder-gray-500 leading-none',
            'appearance-none bg-white border border-solid rounded',
            'focus:shadow-outline focus:outline-none disabled:bg-gray-200 disabled:border-gray-300 disabled:cursor-not-allowed',
            'lg:px-3 lg:text-base',
            'cart-item-quantity-input'
          )}
          maxLength={9}
          onChange={e => {
            clearTimeout(qtyUpdateTimer);
            const value = e.currentTarget.value;
            const number = toNumber(value);
            if (value === '') {
              // ensure that only typing in 0 results in removal
              setQuantity('');
            } else if (isInteger(number) && number >= 0) {
              setQuantity(number);
              setQtyUpdateTimer(
                setTimeout(() => {
                  quantityInputEnabled(false);
                  sendCallback({ data: { quantity: number } })
                    .then(data => {
                      if (!isEmpty(data)) {
                        setCart(data);
                      }
                    })
                    .finally(() => {
                      quantityInputEnabled(true);
                    });
                }, delay)
              );
            }
          }}
          type="text"
          value={quantity}
        />
      </div>
    </div>
  );
};

function quantityInputEnabled(isEnabled) {
  const quantityInputs = document.getElementsByClassName(
    'cart-item-quantity-input'
  );
  for (let i = 0; i < quantityInputs.length; i++) {
    quantityInputs[i].disabled = !isEnabled;
  }
}

CartItemQuantity.options = formatNumber => {
  return map(range(1, 11, 1), opt => ({
    label: formatNumber(opt),
    value: opt
  }));
};

CartItemQuantity.propTypes = {
  /** Current quantity of the item in the cart */
  currentQuantity: PropTypes.number,
  /** ID of the item */
  id: PropTypes.string.isRequired
};

export default CartItemQuantity;
export { CartItemQuantity };
