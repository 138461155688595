/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { isNil } from 'lodash';

import { useCartInfo, useModifyCartRequest } from 'app/common/hooks';
import { Environment } from 'app/common/services';

function useCheckoutApi(cart, guestToken, additionalContextPath = '') {
  const { baseUrl, processCheckoutPath } = useCartInfo().checkoutOperations;
  const url = `${baseUrl}/${cart.id}${processCheckoutPath}${additionalContextPath}`;

  const cartVersionHeaderName = Environment.get(
    'cart.version.header',
    'X-Cart-Version'
  );
  const cartGuestTokenHeaderName = Environment.get(
    'cart.token.header',
    'X-Guest-Token'
  );

  const config = {
    headers: !isNil(guestToken)
      ? {
          [cartVersionHeaderName]: cart.version,
          [cartGuestTokenHeaderName]: guestToken.tokenString
        }
      : {
          [cartVersionHeaderName]: cart.version
        }
  };

  return useModifyCartRequest(url, config, false, true);
}

export default useCheckoutApi;
