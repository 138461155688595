/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Render component for a radio input.
 */
const RadioInput = ({
  disabled = false,
  field,
  value,
  skipHoverNotAllowed = false,
  ...props
}) => {
  const hoverAllowed = skipHoverNotAllowed || !disabled;
  return (
    <input
      {...props}
      {...field}
      className={classNames('absolute opacity-0', {
        'hover:cursor-not-allowed': !hoverAllowed,
        'hover:cursor-pointer': hoverAllowed
      })}
      type="radio"
      value={value}
      disabled={disabled}
    />
  );
};

RadioInput.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string
  }).isRequired,
  required: PropTypes.bool,
  value: PropTypes.string.isRequired
};

export default RadioInput;
export { RadioInput };
