/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { map, range } from 'lodash';

import { Button } from 'app/common/components/buttons';
import Dropdown from 'app/common/components/Dropdown';
import { useFormatNumber, useToggle } from 'app/common/hooks';

const ItemQuantitySelect = ({ className, quantity, setQuantity }) => {
  const formatNumber = useFormatNumber();
  const options = React.useMemo(() => getOptions(formatNumber), [formatNumber]);
  const [useSelect, toggleUseSelect] = useToggle(false);

  if (useSelect) {
    return (
      <Dropdown
        className={classNames('relative flex flex-col', {
          [className]: !!className
        })}
      >
        <Dropdown.Menu.Trigger
          className="w-full basis-full"
          triggerClassName="justify-between w-full pr-4 py-2 pl-3 text-sm font-bold leading-none bg-white rounded shadow focus:outline-none focus:shadow-outline lg:text-base"
        >
          <span>{formatNumber(quantity)}</span>
        </Dropdown.Menu.Trigger>
        <Dropdown.Menu containerClassName="w-full" className="left-0">
          {options.map(option => {
            const { label, value } = option;

            return (
              <Dropdown.Menu.Item
                className="text-sm leading-none lg:text-base"
                isActive={quantity === value}
                key={value}
                onClick={() => {
                  setQuantity(value);
                }}
              >
                {label}
              </Dropdown.Menu.Item>
            );
          })}
          <Dropdown.Menu.Divider />
          <Dropdown.Menu.Item
            className="CartItemQuantityToggleSelect"
            padding=""
          >
            <Button
              className="w-full pr-4 py-2 pl-3 text-left text-sm leading-none focus:outline-none lg:text-base"
              onClick={() => {
                toggleUseSelect(false);
              }}
            >
              {`${formatNumber(10)}+`}
            </Button>
          </Dropdown.Menu.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  return (
    <div
      className={classNames('relative inline-flex flex-grow lg:max-w-48', {
        [className]: !!className
      })}
    >
      <div className="relative flex w-full clear-both box-border text-base text-left">
        <input
          autoComplete="off"
          className={classNames(
            'block w-full p-2',
            'text-sm text-gray-900 placeholder-gray-500 leading-none',
            'appearance-none bg-white border border-solid border-gray-400 rounded',
            'focus:shadow-outline focus:outline-none disabled:bg-gray-200 disabled:border-gray-300 disabled:cursor-not-allowed',
            'lg:px-3 lg:text-base'
          )}
          max={999999999}
          min={1}
          name="quantity"
          onChange={e => {
            const value = e.currentTarget.value;
            setQuantity(value);
          }}
          type="number"
          value={quantity}
        />
      </div>
    </div>
  );
};

const getOptions = formatNumber => {
  return map(range(1, 11, 1), opt => ({
    label: formatNumber(opt),
    value: opt
  }));
};

export default ItemQuantitySelect;
export { ItemQuantitySelect };
