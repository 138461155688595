/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { get, isEmpty, join, noop } from 'lodash';
import PropTypes from 'prop-types';

import { CartItemErrorContext } from 'app/cart/context';
import { CheckboxField } from 'app/common/components';
import { useProductUrl } from 'app/common/hooks';
import notFound from 'app/common/img/image-not-found.jpg';
import { CSREditItemPrice } from 'app/csr/components';

import { CartItemAsset, CartItemDetails, CartItemPricing } from './components';

const CartItem = ({ cartItem, selected = false, handleChecked, position }) => {
  const { attributes, name, uri } = cartItem;
  const imageAsset = cartItem.imageAsset || { contentUrl: notFound };
  const detailsUrl = useProductUrl(uri);
  const gtmItem = React.useMemo(
    () => ({
      brand: get(attributes, 'productBrand.value'),
      category: join(get(attributes, 'categoryNames', []), ', '),
      id: cartItem.sku,
      name: get(attributes, 'productDescription'),
      price: get(cartItem, 'unitPrice.amount', 0.0),
      position,
      quantity: cartItem.quantity
    }),
    [attributes, cartItem, position]
  );
  const { exception } = React.useContext(CartItemErrorContext);

  return (
    <li className="flex flex-wrap items-start justify-between w-full p-4 border-b border-solid border-gray-400 last:border-b-0 sm:flex-no-wrap sm:items-start">
      <CheckboxField.Field
        className="basis-full sm:basis-1/12"
        checked={selected}
        field={{
          onBlur: noop,
          onChange: () => {
            if (handleChecked) {
              handleChecked(!selected);
            }
          },
          name: 'cart-item',
          value: cartItem.id
        }}
        value={cartItem.id}
      />
      {!isEmpty(imageAsset) && (
        <div className="flex items-center basis-3/10 pr-4 sm:items-start sm:basis-1/5">
          <CartItem.CartItemAsset
            {...imageAsset}
            detailsUrl={detailsUrl}
            itemName={name}
            gtmItem={gtmItem}
          />
        </div>
      )}
      <div
        className={classNames('sm:pr-4', {
          'basis-6/10 flex-grow sm:basis-2/5': !isEmpty(imageAsset),
          'basis-10/12 flex-grow sm:basis-3/5': isEmpty(imageAsset)
        })}
      >
        <CartItem.CartItemDetails
          cartItem={{ ...cartItem, detailsUrl }}
          position={position}
          gtmItem={gtmItem}
        />
      </div>
      <div className="w-full mt-4 sm:basis-2/5 sm:mt-0">
        <CartItem.CartItemPricing cartItem={cartItem} />
        <CSREditItemPrice
          className="flex justify-end py-2"
          cartItem={cartItem}
        />
        {!!exception && (
          <ul className="flex flex-col mb-4 mt-2 list-none">
            {!isEmpty(exception.globalConfigErrors) &&
              exception.globalConfigErrors.map(
                ({ errorCode, errorMessage }) => (
                  <li className="mb-2 text-lg text-red-600" key={errorCode}>
                    {errorMessage}
                  </li>
                )
              )}
          </ul>
        )}
      </div>
    </li>
  );
};

CartItem.propTypes = {
  cartItem: PropTypes.shape({
    /** total adjustments to apply against the subtotal */
    adjustmentsTotalWithDependentItems: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired
    }),
    attributes: PropTypes.shape({
      productBrand: PropTypes.string,
      categoryNames: PropTypes.arrayOf(PropTypes.string),
      productDescription: PropTypes.string
    }),
    /** ID of the cart item */
    id: PropTypes.string.isRequired,
    /** display name of the item */
    name: PropTypes.string,
    /** item's image asset */
    imageAsset: PropTypes.shape({
      altText: PropTypes.string,
      contentUrl: PropTypes.string,
      title: PropTypes.string
    }),
    /** quantity of this item in the cart */
    quantity: PropTypes.number.isRequired,
    totalWithDependentItems: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired
    }).isRequired,
    /** price per unit of the item */
    unitPriceWithDependentItems: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired
    }),
    /** URI to the item's details page */
    uri: PropTypes.string
  }).isRequired
};

CartItem.CartItemAsset = CartItemAsset;
CartItem.CartItemDetails = CartItemDetails;
CartItem.CartItemPricing = CartItemPricing;

const CartItemPropTypes = CartItem.propTypes;
CartItemPricing.propTypes = CartItemPropTypes;

export default CartItem;
export { CartItem, CartItemPropTypes, CartItemPricing };
