import {
  useCartInfo,
  useCurrentApplication,
  useRestApi
} from 'app/common/hooks';
import { useEffect, useState } from 'react';
import { COMPLETE } from 'app/common/hooks/useRestApi/RequestStatus';
import { setDeliveryOptionAvailable } from 'app/checkout/contexts/reducers/checkoutReducer';

function useSetDeliveryAvailable(checkoutDispatch) {
  const { id } = useCurrentApplication() || {};
  const { error, exception, response, sendCallback, requestStatus } =
    useFetchDeliveryAvailable(false);
  const [needsFetch, setNeedsFetch] = useState(true);
  useEffect(() => {
    if (!id || !needsFetch) {
      return;
    }
    if (needsFetch && requestStatus === 'PENDING') {
      setNeedsFetch(false);
      sendCallback();
    }
  }, [
    id,
    requestStatus,
    sendCallback,
    checkoutDispatch,
    needsFetch,
    setNeedsFetch,
    checkoutDispatch
  ]);

  useEffect(() => {
    if (!id || needsFetch) {
      return;
    }
    setNeedsFetch(true);
  }, [id, setNeedsFetch, needsFetch, checkoutDispatch]);

  useEffect(() => {
    // TODO: Handle error
    if (error) {
      console.error('Error fetching delivery options', exception);
    }
  });
  useEffect(() => {
    if (response && requestStatus === COMPLETE) {
      setDeliveryOptionAvailable(checkoutDispatch, response);
    }
    // eslint-disable-next-line
  }, [response, requestStatus]);
}

const useFetchDeliveryAvailable = function (sendImmediate = false) {
  const { fetchDeliveryOptions } = useCartInfo().checkoutOperations;

  return useRestApi(`${fetchDeliveryOptions}`, {}, sendImmediate);
};

function useFetchFulfillmentOptions(cart, sendImmediate = false) {
  const { baseUrl, fulfillmentOptionsContextPath } =
    useCartInfo().checkoutOperations;

  return useRestApi(
    `${baseUrl}/${cart.id}${fulfillmentOptionsContextPath}`,
    null,
    sendImmediate
  );
}

function mapFulfillmentOptionsResponse(response) {
  const optionMap = response.groupFulfillmentOptions;
  const failedOptionMap = response.deliveryValidationResponse;
  let fOptions = [];
  for (const key in optionMap) {
    fOptions = fOptions.concat(optionMap[key]);
  }
  let failedOptions = [];
  if (failedOptionMap) {
    for (const key in failedOptionMap) {
      failedOptions = failedOptions.concat(failedOptionMap[key]);
    }
  }
  return {
    fulfillmentOptions: fOptions.sort(
      (a, b) => a.price.amount - b.price.amount
    ),
    failedOptions
  };
}

export {
  useSetDeliveryAvailable,
  useFetchFulfillmentOptions,
  mapFulfillmentOptionsResponse
};
