/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { get, has } from 'lodash';

/**
 * Run-time property resolver that looks at `window.__env` and `global.__env` to find environment properties.
 *
 * @param  {String} propertyName the property name
 * @return {String}              the property value (or undefined)
 */
function runTimeResolver(propertyName) {
  if (has(window, `__env.${propertyName}`)) {
    return get(window, `__env.${propertyName}`);
  }

  if (has(global, `__env.${propertyName}`)) {
    return get(global, `__env.${propertyName}`);
  }

  // handle the case where we are evaluating this in CRA, which requires prefixing VITE_APP_
  if (has(window, `__env.VITE_APP_${propertyName}`)) {
    return get(window, `__env.VITE_APP_${propertyName}`);
  }

  if (has(global, `__env.VITE_APP_${propertyName}`)) {
    return get(global, `__env.VITE_APP_${propertyName}`);
  }

  return undefined;
}

// generally, this resolver should run before the build-time one, but we give room for custom resolvers
runTimeResolver.ORDER = 1000;

export default runTimeResolver;
