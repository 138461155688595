import { useMemo } from 'react';
import { get } from 'lodash';
import {
  useCurrentTenant,
  useFormatMessage,
  useNationalSiteContext,
  useTenantContext
} from 'app/common/hooks';
import messages from './App.messages';

function useHeaderMetadata() {
  const tenant = useCurrentTenant();
  const { resolving } = useTenantContext();
  const { applicationThemeSource } = useNationalSiteContext();
  const formatMessage = useFormatMessage();
  const defaultDescription = formatMessage(messages.defaultDescription);
  const headerMetadata = useMemo(() => {
    if (resolving) {
      return {};
    }
    const keywords = get(applicationThemeSource, 'keywords');

    const favicon = get(applicationThemeSource, 'favicon.contentUrl');

    // Site title, with fallback to tenant name
    const tenantName = get(tenant, 'name');
    const siteTitle = get(applicationThemeSource, 'siteTitle', tenantName);

    // Description, with fallback to tenant description, then default description
    const tenantDesc = get(tenant, 'description', defaultDescription);
    const siteDescription = get(
      applicationThemeSource,
      'siteDescription',
      tenantDesc
    );

    return {
      siteTitle,
      siteDescription,
      keywords,
      favicon
    };
  }, [tenant, applicationThemeSource, defaultDescription, resolving]);

  return headerMetadata || {};
}

export { useHeaderMetadata };
