/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { capitalize, get, isEmpty, isNil, map } from 'lodash';
import PropTypes from 'prop-types';
import { Redirect, useHistory, withRouter } from 'react-router-dom';

import {
  Breadcrumbs,
  Button,
  FleetFilter,
  TopBrandsFilter
} from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';
import notFound from 'app/common/img/image-not-found.jpg';
import { addParams } from 'app/common/utils/PathUtils';
import { ResultsFilterPanel } from 'app/search-and-browse/shared/components/ResultsBody/components';
import TopBrandContext from 'app/common/contexts/TopBrandContext';

import { CategoryListHelmet } from './helpers';
import { useCategoryList } from './hooks';
import messages from './CategoryList.messages';
import { Environment } from 'app/common/services';
import useTopBrandState from 'app/common/hooks/asset/useTopBrandState';
import {
  removeZeroQuantitySelectedFacets,
  TopBrandHeader
} from 'app/search-and-browse/shared/components';

function FacetContainer(props) {
  return (
    <section
      className={classNames(isFeaturedBrandsEnabled() ? 'sticky top-48' : '')}
    >
      {props.filtersVisible && (
        <aside className="pb-2 lg:basis-1/4 lg:pb-0">
          <ResultsFilterPanel
            categoryList
            filters={props.filters}
            searchableFacets={props.searchableFacets}
            selectedFirst={isFeaturedBrandsEnabled()}
            facetValueLimit={
              isFeaturedBrandsEnabled() ? getFacetValueLimit() : undefined
            }
            sticky={isFeaturedBrandsEnabled()}
            showHeader={!isFeaturedBrandsEnabled()}
          />
        </aside>
      )}
    </section>
  );
}

FacetContainer.propTypes = {
  filtersVisible: PropTypes.bool,
  filters: PropTypes.any,
  searchableFacets: PropTypes.any
};
const CategoryList = props => {
  const formatMessage = useFormatMessage();
  const searchableFacets = isFeaturedBrandsEnabled() ? ['attr-BRAND'] : [];
  const history = useHistory();

  const { getTopBrandCategoryLink } = useTopBrandState();
  const { rootsOnly = false } = props;
  const { location } = props;
  const path = location.pathname;
  const { search: searchParams } = location;

  const {
    breadcrumbs,
    category,
    facets,
    routeBaseContext,
    loading,
    response,
    subCategories,
    url
  } = useCategoryList(props);

  removeZeroQuantityBrandFacets(facets, location, history, loading);

  const { name } = category;
  const { activeTopBrand } = React.useContext(TopBrandContext);

  if (!loading && response !== undefined) {
    const { redirect: redirectData = {} } = response;

    if (!isEmpty(redirectData) && !isNil(redirectData.redirectUri)) {
      return <Redirect push={true} to={redirectData.redirectUri} />;
    }
  }

  const filtersVisible =
    (isFeaturedBrandsEnabled() || !rootsOnly) && !props.isBrandsPath;

  return (
    <main className="container relative flex-grow p-4 sm:py-8 md:px-6 lg:px-8">
      <CategoryListHelmet {...category} rootsOnly={rootsOnly} url={url} />
      <header className="text-center">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <TopBrandHeader activeTopBrand={activeTopBrand} />
        <div className="flex justify-between items-baseline mb-2 border-b border-gray-400">
          <h1 className="block mb-2 capitalize text-gray-700 text-2xl font-medium leading-tight sm:text-3xl lg:text-4xl">
            {capitalize(name)}
          </h1>
        </div>
        <div className={classNames({ 'lg:ml-4': filtersVisible })}>
          <FleetFilter />
          {!props.isBrandsPath && <TopBrandsFilter />}
        </div>
      </header>
      <section
        className={
          'relative flex flex-col justify-between mt-4 lg:px-6 sm:mt-8 lg:flex-row'
        }
      >
        <section
          className={classNames(
            filtersVisible
              ? 'relative lg:basis-1/5 xl:basis-1/5 h-auto'
              : 'hidden'
          )}
        >
          <FacetContainer
            filtersVisible={filtersVisible}
            filters={facets}
            searchableFacets={searchableFacets}
          />
        </section>
        <section
          className={classNames(
            'h-auto lg:ml-5',
            filtersVisible
              ? 'lg:basis-4/5 xl:basis-4/5'
              : 'lg:flex-grow lg:flex-shrink'
          )}
        >
          <CategoriesList
            loading={loading}
            subCategories={subCategories}
            filtersVisible={filtersVisible}
            path={path}
            searchParams={searchParams}
            routeBaseContext={routeBaseContext}
            getTopBrandCategoryLink={getTopBrandCategoryLink}
            isBrandsPath={props.isBrandsPath}
          />
        </section>
      </section>
    </main>
  );
};

const CategoriesList = ({
  loading,
  subCategories,
  filtersVisible,
  path,
  searchParams,
  routeBaseContext,
  getTopBrandCategoryLink,
  isBrandsPath
}) => {
  const formatMessage = useFormatMessage();

  return (
    <section className={classNames('flex flex-col mb-6 lg:mb-0', {})}>
      {loading ? null : isEmpty(subCategories) ? (
        <em className="block w-full text-center text-gray-600 text-2xl capitalize not-italic">
          {formatMessage(messages.noResults)}
        </em>
      ) : (
        <ul className="flex flex-wrap -m-3">
          {map(subCategories, subCategory => {
            const subCatName = capitalize(subCategory.name);
            const catLink = isBrandsPath
              ? getBrandCategoryLink(path, subCategory.url)
              : getTopBrandCategoryLink(
                  routeBaseContext + subCategory.url,
                  path
                );
            return (
              <li
                key={subCategory.id}
                className={classNames(
                  'flex flex-col basis-1/2 p-3 mb-2 sm:basis-1/3 md:basis-1/4',
                  {
                    'lg:basis-1/5 xl:basis-1/6': !filtersVisible,
                    'lg:basis-1/4 xl:basis-1/5': filtersVisible,
                    'flex-grow': subCategories.length < 4,
                    'flex-grow-0': subCategories.length >= 4
                  }
                )}
              >
                <Button
                  to={`${catLink}` + searchParams}
                  className="group flex flex-col appearance-none focus:outline-none"
                >
                  <figure className="block rounded">
                    <img
                      className="block w-auto h-20 my-0 mx-auto rounded hover:shadow-outline active:shadow-outline sm:h-40"
                      src={`${addParams(
                        get(subCategory, 'assets[0].contentUrl', notFound),
                        { browse: true }
                      )}`}
                      alt={subCatName}
                      title={subCatName}
                      height="432"
                      width="432"
                    />
                  </figure>
                  <header className="flex flex-col flex-grow flex-shrink-0 basis-0">
                    <h3 className="mt-2 capitalize text-xs text-gray-700 font-medium text-center group-hover:text-gray-900 sm:text-base">
                      {subCatName}
                    </h3>
                  </header>
                </Button>
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
};

/**
 * If multiple facets are selected on a category list page and the user
 * clicks into a category, it's possible that some of the selected
 * facets will no longer be available. In this case, we have to update
 * the URL to remove the unavailable facets.
 * @param facets The facets to check for zero quantity
 * @param location The current location object
 * @param history
 */
function removeZeroQuantityBrandFacets(facets, location, history, loading) {
  if (loading || !facets) {
    return;
  }
  removeZeroQuantitySelectedFacets(facets, location, history, 'attr-BRAND');
}

function isFeaturedBrandsEnabled() {
  return Environment.get('FEATURED_BRANDS_ENABLED') === 'true';
}

/**
 *
 * @return {number|number} The number of facets to show before overflow
 */
function getFacetValueLimit() {
  const facetValueLimit = parseInt(
    Environment.get('FACET_VALUE_OVERFLOW_THRESHOLD', 20)
  );
  return facetValueLimit || 20;
}

/**
 * Replace the category segment with the subcategory URL. /brands/{brand}/{categoryUrl}
 * @param {string} path The current path
 * @param {string} subcatUrl The selected subcategory's url
 * @returns {string} subcategory browse link
 */
function getBrandCategoryLink(path, subcatUrl) {
  const urlParts = path.split('/');
  if (urlParts.length < 4) {
    return path + subcatUrl;
  }

  return path.slice(0, path.lastIndexOf('/')) + subcatUrl;
}

CategoryList.propTypes = {
  category: PropTypes.shape({
    breadcrumbs: PropTypes.array,
    id: PropTypes.string,
    name: PropTypes.string,
    subCategoryDetails: PropTypes.array,
    url: PropTypes.string
  }),
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    hash: PropTypes.string,
    status: PropTypes.object
  }).isRequired,
  /**
   * Whether this page will list only root categories. A pseudo category details
   * wrapper is used in this case and a name and description should be provided
   * here as the backend doesn't return those.
   */
  rootsOnly: PropTypes.bool,
  isBrandsPath: PropTypes.bool
};

export default withRouter(CategoryList);
export { CategoryList };
