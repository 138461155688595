/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';
import { merge } from 'lodash';

import useRestApi from '../../useRestApi';

/**
 * Hook to use in order to fetch offer marketing messages for a particular item.
 *
 * @param {string} fetchUrl - URL of the endpoint to hit
 * @param {Object} itemMarketingMessageRequest - `ItemMarketingMessageRequest` payload
 * @param {Object} config - Object containing additional request config such as the request params
 *     or request method
 * @param {boolean} sendImmediate - Whether to send the request immediately upon
 *     using the hook. If false, then the caller is responsible for triggering
 *     the `sendCallback`. Defaults to `true`.
 *
 * @return {{error: bool, exception: Object, loading: bool, response: Object, sendCallback: function}}
 */
function useItemMarketingMessageApi(
  fetchUrl,
  itemMarketingMessageRequest,
  config,
  sendImmediate = true
) {
  const finalConfig = useMemo(
    () =>
      merge({}, config, { method: 'post', data: itemMarketingMessageRequest }),
    [config, itemMarketingMessageRequest]
  );
  return useRestApi(fetchUrl, finalConfig, sendImmediate);
}

export default useItemMarketingMessageApi;
