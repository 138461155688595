/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';
import { merge } from 'lodash';

import useRestApi from '../../useRestApi';
import useCustomerService from '../useCustomerService';

/**
 * Hook to use to send an add-item-to-cart request to the cart operations API.
 * Response should contain the up-to-date, in-process cart.
 *
 * @param {Object} customerAddress - CustomerAddress to add
 * @param {Object} config - Object containing additional request config  such as
 *     the request params or request method
 * @param {boolean} sendImmediate - Whether to send the request immediately upon
 *     using the hook. If false, then the caller is responsible for triggering
 *     the `sendCallback`. Defaults to `false`.
 *
 * @return {{error: bool, exception: Object, loading: bool, response: Object, sendCallback: function}}
 */
function useAddCustomerAddressApi(
  customerAddress,
  config,
  sendImmediate = false
) {
  const { baseUrl, addressesBasePath } = useCustomerService();
  const finalConfig = useMemo(
    () =>
      merge(
        {},
        {
          data: customerAddress,
          method: 'post'
        },
        config
      ),
    [customerAddress, config]
  );
  const requestUrl = baseUrl + addressesBasePath;
  return useRestApi(requestUrl, finalConfig, sendImmediate);
}

export default useAddCustomerAddressApi;
