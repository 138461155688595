/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import {
  isEmpty,
  isNil,
  isString,
  isUndefined,
  merge,
  size,
  split
} from 'lodash';
import { default as qs } from 'query-string';

/**
 * Parses the path fragment at the provided index (defaults to 0). Additionally,
 * the path will be prefixed with a '/' by default to make the fragment still
 * a proper path.
 *
 * <caption>Usage:</caption>
 * <code>
 * // returns "/products"
 * fragment('/products/1', 0);
 *
 * // returns "1"
 * fragment('/products/1', 1, false);
 *
 * // returns "_products"
 * fragment('/products/1', 0, "_");
 *
 * // returns undefined
 * fragment('/products/1', 2);
 * </code>
 *
 * @param  {String} path                 the path
 * @param  {Number} [index=0]            the index of the fragment, negative will go backwards from the end
 * @param  {String|Boolean} [prefix='/'] the prefix for the returned fragment, or false if no prefix
 * @return {String}                      the fragment
 */
function fragment(path, index = 0, prefix = '/') {
  // we trim off the starting '/' if it exists
  if (path.startsWith('/')) {
    path = path.substring(1);
  }

  const fragments = split(path, '/');
  const numFragments = size(fragments);
  const fragmentIndex =
    index >= 0 ? index % numFragments : numFragments + (index % numFragments);
  const fragment = fragments[fragmentIndex];

  if (isUndefined(fragment)) {
    return undefined;
  }

  if (prefix === false) {
    return `${fragment}`;
  }

  if (prefix === true) {
    return `/${fragment}`;
  }

  if (isString(prefix)) {
    return `${prefix}${fragment}`;
  }

  return `${fragment}`;
}

const ABSOLUTE_REGEX = new RegExp('^(?:[a-z]+:)?//', 'i');

/**
 * Tests whether the provided url is an absolute url.
 *
 * Source of implementation: https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative
 *
 * @param  {String}  url the url to assess
 * @return {Boolean}     whether or not the url is an absolute path
 */
function isAbsoluteUrl(url) {
  return url.startsWith('blob:http') || ABSOLUTE_REGEX.test(url);
}

/**
 * Adds the given params to the URL taking into account any preexisting params.
 *
 * @param {string} [url] - URL onto which to add params
 * @param {{}} params - Map of param names to values
 *
 * @return {string} URL with new params
 */
function addParams(url, params) {
  if (isEmpty(url)) {
    return `?${qs.stringify(params)}`;
  }

  const parsedUrl = qs.parseUrl(url);

  return `${parsedUrl.url}?${qs.stringify(merge(parsedUrl.query, params))}`;
}

/**
 * Joins the provided set of paths together while merging slashes.
 *
 * @param {...string} paths - the set of paths
 *
 * @returns {string}
 */
function join(...paths) {
  return paths.reduce((joined, path) => {
    if (isNil(joined)) {
      return path;
    }

    if (isNil(path)) {
      return joined;
    }

    const isEndSlash = joined.endsWith('/');
    const isStartSlash = path.startsWith('/');
    if (isEndSlash && isStartSlash) {
      return `${joined}${path.substring(1)}`;
    } else if (!isEndSlash && !isStartSlash) {
      return `${joined}/${path}`;
    } else {
      return `${joined}${path}`;
    }
  }, undefined);
}

export { addParams, fragment, isAbsoluteUrl, join };
