/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { Form, Formik } from 'formik';
import { get, isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import {
  useFormatMessage,
  useNationalSiteContext,
  usePersonalizationInfo
} from 'app/common/hooks';
import { AuthContext } from 'app/auth/contexts';
import { AuthService } from 'app/auth/services';
import {
  DecoratedField,
  PrimaryButton,
  SecondaryButton
} from 'app/common/components';
import messages from 'app/homepage/components/Homepage/Homepage.messages';
import { TargetedContentRenderer } from 'app/personalization/helpers';
import classNames from 'classnames';

// See AuroraEmbeddedLoginAuthenticationFailureHandler in auth.
// This maps the error keys to the message keys.
const LOGIN_ERROR_MAP = {
  'auth.login-failed.account-locked-inactivity': 'accountLockedInactivity',
  'auth.login-failed.generic': 'loginFailedGeneric'
};

const LoginBox = ({ setLoginError, loginError }) => {
  const formatMessage = useFormatMessage();
  const {
    applicationThemeSource: application,
    resolving,
    isWhiteLabel
  } = useNationalSiteContext();
  const { isAuthenticating } = React.useContext(AuthContext);
  const isLoginBoxMobileDisabled =
    get(application, 'attributes.loginMobileBoxDisabled', false) &&
    isWhiteLabel;

  const errorKey = useMemo(() => {
    if (loginError) {
      return LOGIN_ERROR_MAP[loginError];
    }
    return null;
  }, [loginError]);
  return (
    <section
      className={classNames('loginBox', {
        'hidden lg:block': isLoginBoxMobileDisabled
      })}
    >
      <div className="flex max-w-xs bg-gray-300 ml-3 mt-3 mb-3 mr-3 lg:mr-0">
        <div className="loginPrompt text-center">
          <div className="p-3">
            <b>
              <LoginTextPrompt
                application={application}
                resolving={isAuthenticating || resolving}
                isWhiteLabel={isWhiteLabel}
                promptType={'guestRegisterPrompt'}
                contextName={'REGISTER'}
              />
            </b>
          </div>
          <div className="p-1 text-sm">
            <LoginTextPrompt
              application={application}
              resolving={resolving}
              isWhiteLabel={isWhiteLabel}
              promptType={'guestSignInPrompt'}
              contextName={'SIGN_IN'}
            />
            <br />
            <LoginTextPrompt
              application={application}
              resolving={resolving}
              isWhiteLabel={isWhiteLabel}
              promptType={'guestContinuePrompt'}
              contextName={'GUEST'}
            />
          </div>

          <Formik
            initialValues={{
              username: '',
              password: ''
            }}
            onSubmit={async (values, actions) => {
              await AuthService.loginWithCredentials(
                {
                  username: get(values, 'username'),
                  password: get(values, 'password')
                },
                actions.setSubmitting,
                setLoginError
              );
            }}
          >
            {({ isSubmitting }) => (
              <Form id="loginForm" className="p-3">
                <div className="mr-1 w-full">
                  {loginError && (
                    <div className="flex items-center mb-4 px-4 py-2 bg-red-100 text-xs text-red-500 border border-red-500 rounded">
                      {errorKey
                        ? formatMessage(messages[errorKey])
                        : loginError}
                    </div>
                  )}

                  <div className="flex p-2 items-center">
                    <DecoratedField
                      id="username"
                      type="text"
                      name="username"
                      className="appearance-none w-full pl-2 bg-white px-2 py-2 rounded-md font-bold"
                      placeholder={formatMessage(messages.emailAddress)}
                      required={true}
                      autoComplete="off"
                      disabled={isSubmitting}
                    />
                  </div>
                </div>

                <div className="mr-1 w-full">
                  <div className="flex p-2 items-center">
                    <DecoratedField
                      id="password"
                      type="password"
                      name="password"
                      className="appearance-none w-full pl-2 bg-white border border-grey-400 px-2 py-2 rounded-md outline-none font-bold"
                      placeholder={formatMessage(messages.password)}
                      required={true}
                      autoComplete="off"
                      disabled={isSubmitting}
                    />
                  </div>
                </div>

                <div className="flex flex-col m-2 ml-5 mr-5 max-w-xs content-center">
                  <PrimaryButton disabled={isSubmitting} type="submit">
                    {formatMessage(messages.signInButton)}
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </Formik>

          <div className="text-gray-600 text-sm p-1">
            <button onClick={() => AuthService.forgotPassword()}>
              <u>{formatMessage(messages.forgotPassword)}</u>
            </button>
          </div>

          <div className="flex flex-col mb-4 mt-4 ml-5 mr-5 max-w-xs content-center">
            <SecondaryButton to="/register">
              {formatMessage(messages.createAccount)}
            </SecondaryButton>
          </div>
        </div>
      </div>
    </section>
  );
};

const DefaultLoginTextPrompt = ({ contextName }) => {
  const PromptContext = Object.freeze({ zone: { name: contextName } });
  const formatMessage = useFormatMessage();
  const personalizationInfo = usePersonalizationInfo();
  const targeterName = get(personalizationInfo, 'homepage.guest.targeterName');
  return (
    <TargetedContentRenderer
      name={targeterName}
      contextAttributes={PromptContext}
    >
      <p>
        {formatMessage(get(LoginPromptDefaults[contextName], 'messagePath'))}
      </p>
    </TargetedContentRenderer>
  );
};

const LoginTextPrompt = ({
  application,
  resolving,
  isWhiteLabel,
  promptType,
  contextName
}) => {
  if (resolving) {
    return null;
  }

  if (isEmpty(get(application, promptType)) || !isWhiteLabel) {
    return <DefaultLoginTextPrompt contextName={contextName} />;
  }

  return <p>{get(application, promptType)}</p>;
};

// FormatMessage doesn't like being sent a message object through lodash. This is a workaround to send the message path.
const LoginPromptDefaults = Object.freeze({
  REGISTER: { messagePath: messages.alreadyRegistered },
  SIGN_IN: { messagePath: messages.signInMessage },
  GUEST: { messagePath: messages.additionalSignIn }
});

export default LoginBox;
export { LoginBox };
