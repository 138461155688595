import messages from 'app/checkout/components/CheckoutLayout/components/FulfillmentInfoWithOptions/FulfillmentInfoWithOptions.messages';
import { DecoratedField, OptionalField } from 'app/common/components';
import React from 'react';
import { useFormatMessage } from 'app/common/hooks';

const PurchaseOrderNumber = ({ active, isSubmitting }) => {
  const formatMessage = useFormatMessage();
  return (
    <div className="flex flex-col" style={{ paddingBottom: 15 }}>
      <fieldset
        className="flex flex-col items-start justify-start"
        disabled={isSubmitting || !active}
      >
        <legend className="flex mb-4 text-gray-900 font-bold lg:text-lg">
          <span>{formatMessage(messages.purchaseOrderNumberLabel)}</span>
        </legend>
        <OptionalField
          name="purchaseOrderNumber"
          triggerLabel={formatMessage(messages.purchaseOrderNumberAdd)}
        >
          <DecoratedField
            disabled={isSubmitting || !active}
            id="purchaseOrderNumber"
            name="purchaseOrderNumber"
            placeholder={formatMessage(messages.purchaseOrderNumberLabel)}
            maxLength={20}
            hint={formatMessage(messages.purchaseOrderNumberHint)}
          />
        </OptionalField>
      </fieldset>
    </div>
  );
};

export default PurchaseOrderNumber;
