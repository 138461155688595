/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';

import { useNationalSiteContext } from 'app/common/hooks';

/**
 * Reusable box which creates a border around the inner child components
 *
 * @param children the child components to border
 * @param className additional classname changes
 * @returns {JSX.Element}
 * @constructor
 */
const DisplayBox = ({ children, className }) => {
  const { nationalSite } = useNationalSiteContext();

  return (
    <div
      className={classNames(
        'flex border-4 shadow-md grow mb-3 lg:mb-8 lg:w-1/3',
        {
          [className]: !!className,
          'border-customGray': !!nationalSite,
          'border-primary-600': !nationalSite
        }
      )}
    >
      {children}
    </div>
  );
};

export default DisplayBox;
export { DisplayBox };
