/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { find, isEmpty, isEqual } from 'lodash';

/**
 * Hook to use to determine the active option (e.g., Variant) for a configurable
 * item (e.g., Product).
 *
 * @param {[{}]} variants - The variants of the item
 * @param {{}} variantAttributeChoices - The selected variant distinguishing attribute values
 * @param {string} sku - The item's SKU code that should match the SKU of a
 *     Variant designating it as the default
 *
 * @return {{}|undefined}
 */
function useActiveOption(variants, variantAttributeChoices, sku) {
  const [activeOption, setActiveOption] = React.useState(undefined);

  React.useEffect(() => {
    let match = find(variants, variant =>
      isEqual(variant.optionValues, variantAttributeChoices)
    );

    if (isEmpty(match) && !isEmpty(sku)) {
      match = find(variants, { sku });
    }

    setActiveOption(match);
  }, [setActiveOption, variantAttributeChoices, sku, variants]);

  return activeOption;
}

export default useActiveOption;
