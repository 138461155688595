/**
 * Responsible for setting the search origin based on the user's selection
 * @param {NearestInventoryState} state The nearest inventory state
 * @param dispatch The nearest inventory dispatch
 * @param handleError Error handler
 */
import { useCurrentApplication } from 'app/common/hooks';
import { useContext, useEffect, useRef } from 'react';
import { get } from 'lodash';
import {
  LOADING_GEOLOCATION_COMPLETE,
  LOADING_GEOLOCATION_START,
  SET_LAT_LONG,
  SET_USING_STORE_LOCATION,
  START_FETCH_STORES
} from '../reducer';
import {
  isLatLongEqual,
  isLatLongValid,
  requestGeolocation
} from 'app/common/utils/GeolocationUtil';
import { NearestInventoryContext } from 'app/common/contexts';

/**
 *
 * @param {NearestInventoryState} state
 * @param dispatch
 * @param handleError
 */
function useSetSearchOrigin(state, dispatch, handleError) {
  const application = useCurrentApplication();
  const { commonState, commonDispatch } = useContext(NearestInventoryContext);

  const { usingStoreLocation, usingGeoLocation } = commonState;

  const latLongRef = useRef(commonState.latLong);
  useEffect(() => {
    latLongRef.current = commonState.latLong;
  }, [commonState.latLong]);

  useEffect(() => {
    if (usingStoreLocation) {
      const newLatLong = {
        latitude: get(application, 'latitude'),
        longitude: get(application, 'longitude')
      };
      if (!isLatLongValid(newLatLong)) {
        commonDispatch(SET_LAT_LONG(undefined));
        return;
      }
      // Only update the lat/long if it has changed
      if (isLatLongEqual(newLatLong, latLongRef.current)) {
        return;
      }
      commonDispatch(SET_LAT_LONG(newLatLong));
      dispatch(START_FETCH_STORES);
    }

    const geoLocationSuccess = position => {
      commonDispatch(
        SET_LAT_LONG({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        })
      );
      dispatch(LOADING_GEOLOCATION_COMPLETE);
      dispatch(START_FETCH_STORES);
    };

    if (usingGeoLocation) {
      dispatch(LOADING_GEOLOCATION_START);
      requestGeolocation({
        successCallback: geoLocationSuccess,
        errorCallback: error => {
          handleError({ code: error, message: 'GEOLOCATION_ERROR' });
          dispatch(LOADING_GEOLOCATION_COMPLETE);
          commonDispatch(SET_USING_STORE_LOCATION(true));
        }
      });
    }
    // eslint-disable-next-line
  }, [usingStoreLocation, usingGeoLocation, application, handleError]);
}

export { useSetSearchOrigin };
