import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Brands.title',
    defaultMessage: 'Brands'
  },
  description: {
    id: 'Brands.description',
    defaultMessage: 'Available Brands'
  }
});
