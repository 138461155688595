/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';

import { Environment } from 'app/common/services';

function useMenuInfo() {
  return useMemo(
    () => ({
      fetchUrl: Environment.get(
        'MENU_HIERARCHY_ENDPOINT',
        'menu/menu-hierarchy'
      ),
      footerMenuName: Environment.get('FOOTER_MENU_NAME', 'FOOTER_MENU'),
      socialSharingMenuName: Environment.get(
        'FOOTER_SOCIAL_MENU_NAME',
        'FOOTER_SOCIAL_MENU'
      ),
      checkoutFooterMenuName: Environment.get(
        'FOOTER_MENU_NAME_CHECKOUT',
        'CHECKOUT_FOOTER_MENU'
      ),
      headerMenuName: Environment.get('HEADER_MENU_NAME', 'HEADER_MENU'),
      siteHelpMenuName: Environment.get(
        'SITE_COURTESY_MENU_NAME',
        'SITE_COURTESY_MENU'
      )
    }),
    []
  );
}

export default useMenuInfo;
