/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { createContext } from 'react';
import { noop } from 'lodash';

// todo: use this?
const defaultPageState = {
  /** Whether the result set was empty */
  empty: true,
  /** List of available ways to filter the results and which are active currently */
  facets: [],
  /** Number of elements in the current page */
  numberOfElements: 0,
  /** Additional page info */
  pageable: {
    /**
     * Offset applied to the beginning of the result set. This can be used
     * instead of page number to change the displayed results.
     */
    offset: 0
  },
  /** The current page number */
  currentPage: 0,
  /** Search results for the current page */
  results: [],
  /** Search redirect provided in response to a search keyword */
  redirect: {
    /** Location where to redirect */
    redirectUri: null,
    /** HTTP status code for the redirect type */
    redirectType: '307'
  },
  /** List of possible ways to sort the results and which are active currently */
  sorts: [],
  /** Total number of results in all pages */
  totalElements: 0,
  /** Total number of pages */
  totalPages: 0
};

/**
 * Context holding search responses for category browsing and catalog searching.
 *
 * @type {React.Context<{updatePage: function, nextPage: {redirect, numberOfElements, totalPages, pageable, currentPage, results, sorts, empty, facets, totalElements}, prevPage: {redirect, numberOfElements, totalPages, pageable, currentPage, results, sorts, empty, facets, totalElements}, currentPage: {redirect, numberOfElements, totalPages, pageable, currentPage, results, sorts, empty, facets, totalElements}, setPage: function}>}
 */
const SearchResponseContext = createContext({
  /** Method to update specified values for one of the pages */
  updatePage: (pageKey, pageValue) => {
    // just by way of example for implementing
    switch (pageKey) {
      case 'prevPage':
      case 'currentPage':
      case 'nextPage':
      default:
        noop(pageValue);
    }
  },
  /** Method to overwrite all values for one of the pages */
  setPage: (pageKey, pageValue) => {
    // just by way of example for implementing
    switch (pageKey) {
      case 'prevPage':
      case 'currentPage':
      case 'nextPage':
      default:
        noop(pageValue);
    }
  },
  /**
   * state for the previous page of results
   * todo: prefetch previous page?
   */
  prevPage: { ...defaultPageState },
  /** state for the current page of results */
  currentPage: { ...defaultPageState },
  /**
   * state for the next page of results
   * todo: prefetch next page?
   */
  nextPage: { ...defaultPageState }
});

SearchResponseContext.defaultPageState = defaultPageState;

export default SearchResponseContext;
export { SearchResponseContext };
