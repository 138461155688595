/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { find, get } from 'lodash';
import AnimateHeight from 'react-animate-height';

import {
  useStateProvinceRegionSelectValues,
  useFormatMessage
} from 'app/common/hooks';

import SavedAddressForm from '../SavedAddressForm';
import messages from './AddressCard.messages';
import { DefaultBadge, SavedAddressActions } from './components';

const AddressCard = ({
  customerAddress,
  refetchAddresses,
  editAddress,
  selected
}) => {
  const formatMessage = useFormatMessage();
  const allStateProvinceRegions = useStateProvinceRegionSelectValues();
  const relevantStateProvinceRegions =
    allStateProvinceRegions[customerAddress.country];
  const stateProvinceRegion = get(
    find(relevantStateProvinceRegions, [
      'value',
      customerAddress.stateProvinceRegion
    ]),
    'value'
  );
  const formattedAddress = React.useMemo(
    () =>
      [
        customerAddress.addressLine1,
        !!customerAddress.addressLine2 && customerAddress.addressLine2,
        !!customerAddress.addressLine3 && customerAddress.addressLine3,
        !!customerAddress.city && `${customerAddress.city},`,
        !!stateProvinceRegion && stateProvinceRegion,
        !!customerAddress.postalCode && customerAddress.postalCode,
        !!customerAddress.country && customerAddress.country
      ]
        .filter(v => !!v)
        .join(' '),
    [customerAddress, stateProvinceRegion]
  );

  return (
    <li className="my-8 text-gray-900 bg-white rounded shadow first:my-0">
      <div className="p-4">
        {customerAddress.defaultShippingAddress && (
          <DefaultBadge isShipping>
            {formatMessage(messages.isDefaultShipping)}
          </DefaultBadge>
        )}
        {customerAddress.defaultBillingAddress && (
          <DefaultBadge isBilling>
            {formatMessage(messages.isDefaultBilling)}
          </DefaultBadge>
        )}
        <div className="flex justify-between">
          <div>
            {customerAddress.name}
            <div className="mt-1 text-xs text-gray-700">
              <span className="block">
                {customerAddress.fullName ||
                  `${customerAddress.firstName} ${customerAddress.lastName}`}
              </span>
              <span className="block">{formattedAddress}</span>
              {!!customerAddress.phonePrimary && (
                <span className="block">
                  {formatMessage(messages.phoneNumber)}:{' '}
                  {customerAddress.phonePrimary.phoneNumber}
                </span>
              )}
            </div>
          </div>

          <div className="flex">
            {!selected && (
              <SavedAddressActions
                customerAddress={customerAddress}
                editAddress={editAddress}
                refetchAddresses={refetchAddresses}
              />
            )}
          </div>
        </div>
      </div>
      <AnimateHeight duration={200} height={selected ? 'auto' : 0}>
        <SavedAddressForm
          refetchAddresses={refetchAddresses}
          selectedAddress={customerAddress}
          isActive={selected}
          setSelectedAddress={editAddress}
        />
      </AnimateHeight>
    </li>
  );
};

export default AddressCard;
export { AddressCard };
