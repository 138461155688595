/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import {
  filter,
  flatten,
  get,
  has,
  intersectionWith,
  isEmpty,
  isEqual,
  keys,
  map,
  merge,
  toPairs,
  values
} from 'lodash';

/**
 * Finds all variant distinguishing attribute-choice ProductOptions then reduces them down to arrays
 * of `attributeName: allowedValue` pairs for each allowedValue.
 *
 * Example:
 *
 * ```js
 * // given
 * const options = [
 *   {
 *     type: 'VARIANT_DISTINGUISHING',
 *     attributeChoice: {
 *       attributeName: 'COLOR',
 *       allowedValues: [
 *         { label: 'Red', value: 'red' },
 *         { label: 'Blue', value: 'blue' }
 *       ]
 *     }
 *   }
 * ];
 * // expect
 * const result = [
 *  [{ COLOR: 'red' }, { COLOR: 'blue' }]
 * ]
 * ```
 *
 * @param {[{}]} options - Array of ProductOptions
 *
 * @return {[[{}]]} Array of arrays of only attribute-choice ProductOptions
 *     reduced to pairs `attributeName: allowedValue` for each `allowedValue`
 */
function getVariantAttributeChoiceOptions(options) {
  return options
    .filter(option => option.type === 'VARIANT_DISTINGUISHING')
    .map(option => option.attributeChoice)
    .reduce((acc, { allowedValues = [], attributeName }) => {
      acc.push(allowedValues.map(({ value }) => ({ [attributeName]: value })));
      return acc;
    }, []);
}

/**
 * Accumulates attribute combinations by merging the next set of attribute
 * name-value pairs into the thus-far-accumulated set.
 *
 * Example:
 *
 * ```js
 * // given
 * const accumulator = [ { COLOR: 'blue' }, { COLOR: 'red' } ];
 * const nextAttributePairs = [ { STORAGE: '32GB'} , { STORAGE: '64GB'} ];
 * // expect
 * const result = [
 *    { COLOR: 'blue', STORAGE: '32GB' },
 *    { COLOR: 'blue', STORAGE: '64GB' },
 *    { COLOR: 'red', STORAGE: '32GB' },
 *    { COLOR: 'red', STORAGE: '64GB' },
 * ];
 * ```
 *
 * This is used by `ProductOptions#getAttributeCombinations`.
 *
 * @param {[{}]} accumulator - Array containing all of the combinations created
 *     so far as part of `ProductOptions#getAttributeCombinations`
 * @param {[{}]} nextAttributePairs - Array of the attributeName-value pairs
 *     for a single attribute
 *
 * @return {[{}]} Updated accumulator
 */
function accumulateAttributeCombinations(accumulator, nextAttributePairs) {
  return accumulator.reduce((combinations, prevCombo) => {
    return nextAttributePairs.reduce((acc, attrPair) => {
      acc.push(merge({}, prevCombo, attrPair));
      return acc;
    }, combinations);
  }, []);
}

/**
 * Gets the combinations of the values of all variant distinguishing attribute-choice
 * ProductOptions.
 *
 * Given:
 *
 * ```js
 * // given
 * const allOptions = [
 *   [{ STORAGE: '32GB' }, { STORAGE: '64GB' }],
 *   [{ COLOR: 'red' }, { COLOR: 'blue' }, { COLOR: 'green' }],
 *   [{ CARRIER: 'Verizon' }, { CARRIER: 'Sprint' }]
 * ];
 * const l = 3;
 * // expect
 * const result = [
 *   { STORAGE: '32GB', COLOR: 'red', CARRIER: 'Verizon' },
 *   { STORAGE: '32GB', COLOR: 'red', CARRIER: 'Sprint' },
 *   { STORAGE: '32GB', COLOR: 'blue', CARRIER: 'Verizon' },
 *   { STORAGE: '32GB', COLOR: 'blue', CARRIER: 'Sprint' },
 *   { STORAGE: '32GB', COLOR: 'green', CARRIER: 'Verizon' },
 *   { STORAGE: '32GB', COLOR: 'green', CARRIER: 'Sprint' },
 *   { STORAGE: '64GB', COLOR: 'red', CARRIER: 'Verizon' },
 *   { STORAGE: '64GB', COLOR: 'red', CARRIER: 'Sprint' },
 *   { STORAGE: '64GB', COLOR: 'blue', CARRIER: 'Verizon' },
 *   { STORAGE: '64GB', COLOR: 'blue', CARRIER: 'Sprint' },
 *   { STORAGE: '64GB', COLOR: 'green', CARRIER: 'Verizon' },
 *   { STORAGE: '64GB', COLOR: 'green', CARRIER: 'Sprint' }
 * ];
 * ```
 *
 * @param {[[{}]]} allOptions - array of the arrays of the attributeName-value
 *     pairs of all variant distinguishing attribute-choice ProductOptions.
 * @param {number} l - Length of `allOptions`
 *
 * @return {*[]|[{}]} The combinations of the values of all variant distinguishing attribute-choice
 *     ProductOptions.
 */
function getVariantAttributeCombinations(allOptions, l) {
  if (l === 0) {
    return [];
  }

  if (l === 1) {
    return allOptions[0];
  }

  let acc = [...allOptions[0]];

  for (let i = 1; i < l; i++) {
    const next = allOptions[i];
    acc = accumulateAttributeCombinations(acc, next);
  }

  return acc;
}

/**
 * Returns a set of the names of all attributes that are never used to
 * distinguish a Product's actual Variants and a map where the keys are
 * attribute names and the values are the set of allowedValues that are not
 * used for any of a Product's actual Variants.
 *
 * @param {[{}]} actualCombinations -  All the combinations that match actual
 *     Variants.
 * @param {[[{}]]} attributeChoiceOptions - An array with nested a array for
 *     each distinct attribute that contains a pair of the name of that
 *     attribute to an allowedValue for each value.
 *
 * @return {{unusedAttributeNames: Set<String>, unusedAttributeValuesMap: {}}}
 */
function compileUnusedAttributeInfo(
  actualCombinations,
  attributeChoiceOptions
) {
  const actualAttributeValuesMap =
    getActualAttributeValuesMap(actualCombinations);
  const possibleAttributeValuesMap = getPossibleAttributeValuesMap(
    attributeChoiceOptions
  );

  const unusedAttributeNames = getUnusedAttributeNames(
    possibleAttributeValuesMap,
    actualAttributeValuesMap
  );
  const unusedAttributeValuesMap = getUnusedAttributeValuesMap(
    possibleAttributeValuesMap,
    actualAttributeValuesMap
  );

  return { unusedAttributeNames, unusedAttributeValuesMap };
}

/**
 * Creates a map where the keys are attribute names and the values are a set of
 * all of the values for that attribute that are used to distinguish a
 * Product's actually existing Variants.
 *
 * Example:
 * ```js
 * // given
 * const actualCombinations = [
 *   { COLOR: 'red', STORAGE: '32GB' },
 *   { COLOR: 'blue', STORAGE: '32GB' }
 * ];
 * // expect
 * const result = {
 *   COLOR: Set {'red', 'blue'},
 *   STORAGE: Set {'32GB'}
 * }
 * ```
 *
 * @param {[{}]} actualCombinations - All the combinations that match actual
 *     Variants.
 *
 * @return {{}}
 */
function getActualAttributeValuesMap(actualCombinations) {
  return actualCombinations.reduce((acc, combo) => {
    if (!combo) return acc;
    toPairs(combo).forEach(([key, value]) => {
      if (has(acc, key)) {
        acc[key] = acc[key].add(value);
        return;
      }

      acc[key] = new Set([value]);
    });

    return acc;
  }, {});
}

/**
 * Creates a map where the keys are attribute names and the values are a set of
 * all of the values for that attribute that are possible.
 *
 * Example:
 * ```js
 * // given
 * const attributeChoiceOptions = [
 *   [{ COLOR: 'red' }, { COLOR: 'black' }],
 *   [{ STORAGE: '32GB' }, { STORAGE: '64GB' }]
 * ];
 * // expect
 * const result = {
 *   COLOR: Set {'red', 'black'},
 *   STORAGE: Set {'32GB', '64GB'}
 * };
 * ```
 *
 * @param {[[{}]]} attributeChoiceOptions - An array with nested a array for
 *     each distinct attribute that contains a pair of the name of that
 *     attribute to an allowedValue for each value.
 *
 * @return {{}}
 */
function getPossibleAttributeValuesMap(attributeChoiceOptions) {
  return flatten(attributeChoiceOptions).reduce((acc, combo) => {
    const [key, value] = toPairs(combo)[0];
    if (has(acc, key)) {
      acc[key] = acc[key].add(value);
      return acc;
    }

    acc[key] = new Set([value]);
    return acc;
  }, {});
}

/**
 * Get a set of all of the names of attributes that are never used to
 * distinguish a Product's actually existing Variants.
 *
 * Example:
 *
 * ```js
 * // given
 * const possibleAttributeValuesMap = {
 *   COLOR: Set {'red', 'black'},
 *   STORAGE: Set {'32GB', '64GB'},
 *   UNUSED: Set {'value1', 'value2'}
 * };
 * const actualAttributeValuesMap = {
 *   COLOR: Set {'red', 'black'},
 *   STORAGE: Set {'32GB'}
 * };
 * //expect
 * const result = Set {'UNUSED'};
 * ```
 *
 * @param {{}} possibleAttributeValuesMap - see
 *     {@link #getPossibleAttributeValuesMap}
 * @param {{}} actualAttributeValuesMap - see
 *     {@link #getActualAttributeValuesMap}
 *
 * @return {Set<string>}
 */
function getUnusedAttributeNames(
  possibleAttributeValuesMap,
  actualAttributeValuesMap
) {
  const actualKeys = keys(actualAttributeValuesMap);
  return new Set(
    keys(possibleAttributeValuesMap).filter(
      possibleKey => actualKeys.indexOf(possibleKey) < 0
    )
  );
}

/**
 * Get a map where the keys are attribute names and the values are sets of
 * values that never used to distinguish a Product's actually existing Variants.
 *
 * Example:
 * ```
 * // given
 * const possibleAttributeValuesMap = {
 *  COLOR: Set {'red', 'black'},
 *  STORAGE: Set {'32GB', '64GB'},
 *  UNUSED: Set {'value1', 'value2'}
 * };
 * const actualAttributeValuesMap = {
 *  COLOR: Set {'red', 'black'},
 *  STORAGE: Set {'32GB'}
 * };
 * // expect
 * const result = {
 *  COLOR: Set {},
 *  STORAGE: Set {'64GB'},
 *  UNUSED: Set {'value1', 'value2'}
 * };
 * ```
 *
 * @param {{}} possibleAttributeValuesMap - see
 *     {@link #getPossibleAttributeValuesMap}
 * @param {{}} actualAttributeValuesMap - see
 *     {@link #getActualAttributeValuesMap}
 *
 * @return {{}}
 */
function getUnusedAttributeValuesMap(
  possibleAttributeValuesMap,
  actualAttributeValuesMap
) {
  return toPairs(possibleAttributeValuesMap).reduce(
    (acc, [attrName, attrVals]) => {
      const actualVals = actualAttributeValuesMap[attrName];

      if (isEmpty(actualVals)) {
        acc[attrName] = attrVals;
        return acc;
      }

      acc[attrName] = new Set(
        [...attrVals].filter(possibleVal => !actualVals.has(possibleVal))
      );

      return acc;
    },
    {}
  );
}

/**
 * Looks through the `variants` to determine which attribute combinations are
 * actually in use. This will filter out all non-available variants and return
 * a list of all of the `optionValues` that can be used to filter down the
 * possible combinations.
 *
 * Example:
 * ```js
 * // given
 * const variants = [
 *   {
 *     id: 'variant-1',
 *     sku: 'SKU-variant-1',
 *     availableOnline: false,
 *     optionValues: {
 *       COLOR: '#F00',
 *       STORAGE: '32GB',
 *       PROVIDER: 'VERIZON'
 *     }
 *   },
 *   {
 *     id: 'variant-1',
 *     sku: 'SKU-variant-1',
 *     availableOnline: true,
 *     optionValues: {
 *       COLOR: '#F00',
 *       STORAGE: '64GB',
 *       PROVIDER: 'VERIZON'
 *     }
 *   },
 *   {
 *     id: 'variant-1',
 *     sku: 'SKU-variant-1',
 *     optionValues: {
 *       COLOR: '#FFF',
 *       STORAGE: '64GB',
 *       PROVIDER: 'VERIZON'
 *     }
 *   }
 * ];
 * // expect
 * const result = [
 *   {
 *     COLOR: '#F00',
 *     STORAGE: '64GB',
 *     PROVIDER: 'VERIZON'
 *   },
 *   {
 *     COLOR: '#FFF',
 *     STORAGE: '64GB',
 *     PROVIDER: 'VERIZON'
 *   }
 * ];
 * ```
 *
 * @param {[{}]} variants - List of a Product's Variants representing the actual
 *     AttributeChoice ProductOptions in use.
 *
 * @return {[{}]}
 */
function getActualVariantAttributeCombinations(variants) {
  return map(
    filter(variants, variant => get(variant, 'availableOnline', true)),
    variant => variant.optionValues
  );
}

/**
 * Creates a map of attribute names and values to the actually-used combinations
 * of attributes that use those values.
 *
 * Example:
 *
 * ```js
 * // given
 * const actualCombinations = [
 *   { COLOR: 'blue', STORAGE: '32GB', PROVIDER: 'VERIZON' },
 *   { COLOR: 'red', STORAGE: '32GB', PROVIDER: 'AT&T' },
 *   { COLOR: 'blue', STORAGE: '32GB', PROVIDER: 'AT&T' },
 *   { COLOR: 'red', STORAGE: '64GB', PROVIDER: 'VERIZON' },
 *   { COLOR: 'blue', STORAGE: '64GB', PROVIDER: 'VERIZON' }
 * ];
 * // expect
 * const result = {
 *  COLOR: {
 *    red: [
 *      { COLOR: 'red', STORAGE: '32GB', PROVIDER: 'AT&T' },
 *     { COLOR: 'red', STORAGE: '64GB', PROVIDER: 'VERIZON' },
 *    ],
 *    blue: [
 *      { COLOR: 'blue', STORAGE: '32GB', PROVIDER: 'VERIZON' },
 *      { COLOR: 'blue', STORAGE: '32GB', PROVIDER: 'AT&T' },
 *      { COLOR: 'blue', STORAGE: '64GB', PROVIDER: 'VERIZON' },
 *    ]
 *  },
 *  STORAGE: {
 *    '32GB': [
 *      { COLOR: 'blue', STORAGE: '32GB', PROVIDER: 'VERIZON' },
 *      { COLOR: 'red', STORAGE: '32GB', PROVIDER: 'AT&T' },
 *      { COLOR: 'blue', STORAGE: '32GB', PROVIDER: 'AT&T' }
 *    ],
 *    '64GB': [
 *      { COLOR: 'red', STORAGE: '64GB', PROVIDER: 'VERIZON' },
 *      { COLOR: 'blue', STORAGE: '64GB', PROVIDER: 'VERIZON' },
 *    ]
 *  },
 *  PROVIDER: {
 *    VERIZON: [
 *      { COLOR: 'blue', STORAGE: '32GB', PROVIDER: 'VERIZON' },
 *      { COLOR: 'red', STORAGE: '64GB', PROVIDER: 'VERIZON' },
 *      { COLOR: 'blue', STORAGE: '64GB', PROVIDER: 'VERIZON' }
 *    ],
 *    'AT&T': [
 *      { COLOR: 'red', STORAGE: '32GB', PROVIDER: 'AT&T' },
 *      { COLOR: 'blue', STORAGE: '32GB', PROVIDER: 'AT&T' }
 *    ]
 *  }
 * };
 * ```
 *
 * @param actualCombinations
 *
 * @return {{{[]}}}
 */
function getAttributeValueToRelatedCombinationsMap(actualCombinations) {
  const actualValuesMap = getActualAttributeValuesMap(actualCombinations);
  const allAttrNames = keys(actualValuesMap);

  return allAttrNames.reduce((attrAcc, attrName) => {
    attrAcc[attrName] = [...actualValuesMap[attrName]].reduce(
      (valAcc, attrVal) => {
        valAcc[attrVal] = actualCombinations
          .filter(combo => combo && combo[attrName])
          .filter(combo => combo[attrName] === attrVal);

        return valAcc;
      },
      {}
    );

    return attrAcc;
  }, {});
}

/**
 * Returns the set of allowable values for {@code attributeName} given the
 * already-selected attribute choices.
 *
 * Example:
 *
 * ```js
 * // given
 * const attributeName = 'PROVIDER';
 * const attributeChoices = {
 *   STORAGE: '64GB'
 * };
 * const attrValToRelatedCombosMap = {
 *  COLOR: {
 *    red: [
 *      { COLOR: 'red', STORAGE: '32GB', PROVIDER: 'AT&T' },
 *      { COLOR: 'red', STORAGE: '64GB', PROVIDER: 'VERIZON' }
 *    ],
 *    blue: [
 *      { COLOR: 'blue', STORAGE: '32GB', PROVIDER: 'VERIZON' },
 *      { COLOR: 'blue', STORAGE: '32GB', PROVIDER: 'AT&T' },
 *      { COLOR: 'blue', STORAGE: '64GB', PROVIDER: 'VERIZON' }
 *    ]
 *  },
 *  STORAGE: {
 *    '32GB': [
 *      { COLOR: 'blue', STORAGE: '32GB', PROVIDER: 'VERIZON' },
 *      { COLOR: 'red', STORAGE: '32GB', PROVIDER: 'AT&T' },
 *      { COLOR: 'blue', STORAGE: '32GB', PROVIDER: 'AT&T' }
 *    ],
 *    '64GB': [
 *      { COLOR: 'red', STORAGE: '64GB', PROVIDER: 'VERIZON' },
 *      { COLOR: 'blue', STORAGE: '64GB', PROVIDER: 'VERIZON' }
 *    ]
 *  },
 *  PROVIDER: {
 *    VERIZON: [
 *      { COLOR: 'blue', STORAGE: '32GB', PROVIDER: 'VERIZON' },
 *      { COLOR: 'red', STORAGE: '64GB', PROVIDER: 'VERIZON' },
 *      { COLOR: 'blue', STORAGE: '64GB', PROVIDER: 'VERIZON' }
 *    ],
 *    'AT&T': [
 *      { COLOR: 'red', STORAGE: '32GB', PROVIDER: 'AT&T' },
 *      { COLOR: 'blue', STORAGE: '32GB', PROVIDER: 'AT&T' }
 *    ]
 *  }
 * };
 * // expect
 * const result = Set { 'VERIZON' };
 * ```
 *
 * @param {string} attributeName - Name of the attribute for which to find
 *     allowable values given the selected {@code attributeChoices}
 * @param {{}} attributeChoices - The selected attribute values
 * @param {{}} attributeValueToRelatedCombinationsMap -
 *
 * @return {Set<*>} Set of allowable values for {@code attributeName}.
 */
function getAllowableValuesInCombinationWithAlreadySelected(
  attributeName,
  attributeChoices,
  attributeValueToRelatedCombinationsMap
) {
  if (isEmpty(attributeChoices)) {
    // get all the combos for all values of attributeName
    return new Set(
      flatten(
        values(attributeValueToRelatedCombinationsMap[attributeName])
      ).map(combo => combo[attributeName])
    );
  }

  const possibleCombos = toPairs(attributeChoices).map(
    ([selectedName, selectedValue]) => {
      if (selectedName === attributeName) {
        return flatten(
          values(attributeValueToRelatedCombinationsMap[selectedName])
        );
      }

      return attributeValueToRelatedCombinationsMap[selectedName][
        selectedValue
      ];
    }
  );
  const actualCombos = intersectionWith(...possibleCombos, isEqual);
  const allowableValues = actualCombos.map(
    actualCombo => actualCombo[attributeName]
  );

  return new Set(allowableValues);
}

export {
  getVariantAttributeChoiceOptions,
  getVariantAttributeCombinations,
  accumulateAttributeCombinations,
  getActualVariantAttributeCombinations,
  getActualAttributeValuesMap,
  getPossibleAttributeValuesMap,
  getUnusedAttributeNames,
  getUnusedAttributeValuesMap,
  compileUnusedAttributeInfo,
  getAttributeValueToRelatedCombinationsMap,
  getAllowableValuesInCombinationWithAlreadySelected
};
