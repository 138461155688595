/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { Helmet } from 'react-helmet';
import AnimateHeight from 'react-animate-height';

import { PrimaryButton } from 'app/common/components';
import {
  useFormatMessage,
  useGtmPageView,
  useReadCustomerAddressesApi
} from 'app/common/hooks';

import { SavedAddressForm, UserAddresses } from './components';
import messages from './Addresses.messages';
import { useHeaderMetadata } from 'app/core/components/App';

const Addresses = () => {
  const { siteTitle } = useHeaderMetadata();
  const formatMessage = useFormatMessage();
  const { sendCallback: sendReadCallback } = useReadCustomerAddressesApi();
  const [isAdding, setIsAdding] = React.useState(false);
  const [selectedAddress, setSelectedAddress] = React.useState(undefined);
  const [addresses, setAddresses] = React.useState(undefined);
  const refetchAddresses = React.useCallback(async () => {
    setIsAdding(false);
    sendReadCallback().then(data => {
      setAddresses(data.content);
    });
  }, [sendReadCallback]);

  React.useEffect(() => {
    refetchAddresses();
  }, [refetchAddresses]);

  useGtmPageView('Saved Addresses');

  return (
    <section>
      <Helmet titleTemplate={`%s - ${siteTitle}`}>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <header className="text-2xl text-bold flex justify-between pb-4">
        <h1>{formatMessage(messages.title)}</h1>
        {!isAdding && (
          <PrimaryButton
            onClick={() => {
              setSelectedAddress(undefined);
              setIsAdding(true);
            }}
          >
            {formatMessage(messages.addAddressButton)}
          </PrimaryButton>
        )}
      </header>
      <AnimateHeight duration={200} height={isAdding ? 'auto' : 0}>
        <section className="mb-4 rounded overflow-hidden">
          <SavedAddressForm
            refetchAddresses={refetchAddresses}
            selectedAddress={undefined}
            setSelectedAddress={() => {
              setSelectedAddress(undefined);
              setIsAdding(false);
            }}
          />
        </section>
      </AnimateHeight>
      {!isAdding && (
        <UserAddresses
          addresses={addresses}
          refetchAddresses={refetchAddresses}
          editAddress={setSelectedAddress}
          selectedId={!!selectedAddress ? selectedAddress.id : undefined}
        />
      )}
    </section>
  );
};

const AddressesSkeleton = () => {
  const formatMessage = useFormatMessage();
  const { siteTitle } = useHeaderMetadata();

  return (
    <>
      <Helmet titleTemplate={`%s - ${siteTitle}`}>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <div className="text-2xl text-bold flex justify-between pb-4">
        <span>{formatMessage(messages.title)}</span>
      </div>
      <div className="w-full text-bold text-gray-500 text-xl sm:mt-10 sm:ml-10 sm:text-2xl lg:mt-20 lg:ml-20">
        {formatMessage(messages.empty)}
      </div>
    </>
  );
};

Addresses.Skeleton = AddressesSkeleton;

export default Addresses;
export { Addresses, AddressesSkeleton };
