/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';

import {
  CartItemQuantity,
  CoreCost,
  Currency,
  Icon
} from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from './CartItemPricing.messages';

/**
 * Render component for the cart item's pricing information.
 *
 * @visibleName Cart: Item: Pricing
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const CartItemPricing = ({ cartItem }) => {
  const formatMessage = useFormatMessage();
  const {
    displayTotal,
    displayAdjustmentsTotal,
    displayUnitPrice,
    discounted,
    displayOriginalUnitPrice,
    id,
    quantity,
    coreCost
  } = cartItem;

  return (
    <section className="flex flex-col items-end justify-end w-full">
      <div className="flex flex-no-wrap items-center justify-end w-full">
        <div className="relative">
          <Currency
            className={classNames('unit-price font-medium text-base', {
              'text-red-600': discounted
            })}
            {...displayUnitPrice}
          />
          {discounted && (
            <Currency
              className="ml-1 text-gray-500 text-sm line-through sm:absolute sm:top-5 sm:left-0 sm:ml-0"
              {...displayOriginalUnitPrice}
            />
          )}
        </div>
        <div className="inline-flex items-center">
          <Icon className="mx-2 text-center" name="times" />
        </div>
        <CartItemQuantity currentQuantity={quantity} id={id} />
        <Currency className="final-price ml-2 font-bold" {...displayTotal} />
      </div>
      <CoreCost
        className="ml-1 text-gray-600"
        coreCost={coreCost}
        message={messages.coreCost}
      />
      {discounted && displayAdjustmentsTotal && (
        <div className="flex items-baseline justify-between mt-2 text-red-600 sm:text-sm">
          <em
            className="flex-1 font-normal text-right not-italic"
            style={{ flex: '0 1 60%' }}
          >
            {formatMessage(messages.adjustmentsAmountLabel)}
          </em>
          <Currency
            className="adjustment-price flex-shrink ml-2 font-bold"
            style={{ flex: '0 1 40%' }}
            {...displayAdjustmentsTotal}
          />
        </div>
      )}
    </section>
  );
};

export default CartItemPricing;
export { CartItemPricing };
