const prefix = 'preferred-store';

const OPEN_PREFERRED_STORE_MODAL = `${prefix}/OPEN_PREFERRED_STORE_MODAL`;
const CLOSE_PREFERRED_STORE_MODAL = `${prefix}/CLOSE_PREFERRED_STORE_MODAL`;

const OPEN_AUTO_COMPLETE = `${prefix}/OPEN_AUTO_COMPLETE`;
const CLOSE_AUTO_COMPLETE = `${prefix}/CLOSE_AUTO_COMPLETE`;

const SET_QUERY = `${prefix}/SET_QUERY`;
const SET_STORES = `${prefix}/SET_STORES`;
const SET_USING_LOC_COORDS = `${prefix}/SET_USING_LOC_COORDS`;

const SET_AUTO_COMPLETE_RESULTS = `${prefix}/SET_AUTO_COMPLETE_RESULTS`;
const HANDLE_ADDRESS_SELECT = `${prefix}/HANDLE_ADDRESS_SELECT`;
const SET_CURRENT_PREFERRED_STORE = `${prefix}/SET_CURRENT_PREFERRED_STORE`;

const SET_NEW_PREFERRED_STORE = `${prefix}/SET_NEW_PREFERRED_STORE`;

const APP_LOADED = `${prefix}/APP_LOADED`;

const LOADING_LOCATIONS_ACTION = `${prefix}/LOADING_LOCATIONS`;
const LOADING_LOCATIONS_COMPLETE_ACTION = `${prefix}/LOADING_LOCATIONS_COMPLETE`;

const SET_ERROR = `${prefix}/SET_ERROR`;
const RESET_ERRORS = `${prefix}/RESET_ERRORS`;

const SET_STATE = `${prefix}/SET_STATE`;

export {
  OPEN_PREFERRED_STORE_MODAL,
  CLOSE_PREFERRED_STORE_MODAL,
  OPEN_AUTO_COMPLETE,
  CLOSE_AUTO_COMPLETE,
  SET_QUERY,
  SET_STORES,
  SET_USING_LOC_COORDS,
  SET_CURRENT_PREFERRED_STORE,
  APP_LOADED,
  LOADING_LOCATIONS_ACTION,
  LOADING_LOCATIONS_COMPLETE_ACTION,
  SET_ERROR,
  RESET_ERRORS,
  HANDLE_ADDRESS_SELECT,
  SET_AUTO_COMPLETE_RESULTS,
  SET_STATE,
  SET_NEW_PREFERRED_STORE
};
