/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

const TextWidget = ({ className, content }) => {
  return <div className={className}>{content}</div>;
};

TextWidget.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string.isRequired
};

export default TextWidget;
export { TextWidget };
