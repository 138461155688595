/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import { AuthService } from 'app/auth/services';
import {
  LoadingIcon,
  PrimaryButton,
  TertiaryButton
} from 'app/common/components';
import { useFormatMessage, useMenuInfo } from 'app/common/hooks';

import messages from '../../Header.messages';
import AccountDropdown from '../AccountDropdown';
import LocaleSelector from '../LocaleSelector';
import CurrencySelector from '../CurrencySelector';
import Menu from '../Menu';

const MobileNav = props => {
  const { isAuthenticated, isAuthenticating, isMenuOpen, setMenuIsOpen } =
    props;
  const formatMessage = useFormatMessage();
  const { fetchUrl: menuFetchUrl, headerMenuName } = useMenuInfo();

  return (
    <>
      <div
        className="fixed inset-0 bg-gray-900 lg:hidden"
        style={{ opacity: '0.75' }}
        onClick={() => setMenuIsOpen(false)}
      />
      <nav
        role="navigation"
        aria-label={formatMessage(messages.navAriaLabel)}
        className="fixed left-0 top-0 w-64 h-screen bg-white shadow lg:hidden"
      >
        <div className="flex flex-col">
          {!isAuthenticating ? (
            <>
              {!isAuthenticated && (
                <>
                  <PrimaryButton
                    onClick={() => AuthService.loginWithRedirect()}
                    rounded={false}
                    size={PrimaryButton.Size.SMALL}
                  >
                    {formatMessage(messages.signIn)}
                  </PrimaryButton>
                  <TertiaryButton
                    className="p-2 border-t-4 border-primary-500 focus:shadow-outline focus:bg-primary-100"
                    size={TertiaryButton.Size.SMALL}
                    to="/register"
                  >
                    {formatMessage(messages.register)}
                  </TertiaryButton>
                </>
              )}
              {isAuthenticated && <AccountDropdown isActive={isMenuOpen} />}
            </>
          ) : (
            <>
              <LoadingIcon />
            </>
          )}
        </div>
        <div className="mb-4 border-t border-b border-solid bg-gray-100">
          <LocaleSelector />
          <CurrencySelector />
        </div>
        <Menu
          className="flex-col"
          expanded={true}
          fetchUrl={menuFetchUrl}
          isActive={true}
          name={headerMenuName}
        />
      </nav>
    </>
  );
};

export default MobileNav;
export { MobileNav };
