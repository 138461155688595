/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { useFormatMessage } from 'app/common/hooks';
import { ResolutionContextType } from 'app/common/constants';
import { TenantContext } from 'app/common/contexts';

import { NavItem } from './components';
import messages from './LeftNav.messages';

/**
 *  Renders all of the navigation items for the my accounts dashboard.
 */
const LeftNav = () => {
  const formatMessage = useFormatMessage();
  const { resolutionContext } = React.useContext(TenantContext);
  return (
    <ul className="flex flex-row flex-wrap mb-6 bg-white rounded shadow lg:flex-col lg:flex-shrink lg:w-1/5 lg:mb-0 lg:mr-6">
      <NavItem
        first
        label={formatMessage(messages.accountInfo)}
        url="/my-account"
      />
      {resolutionContext !== ResolutionContextType.DEALER && (
        <NavItem
          label={formatMessage(messages.preferredLocation)}
          url="/my-account/preferred-location"
        />
      )}
      <NavItem
        label={formatMessage(messages.addresses)}
        url="/my-account/addresses"
      />
      <NavItem label={formatMessage(messages.lists)} url="/my-account/lists" />
      <NavItem
        label={formatMessage(messages.orders)}
        url="/my-account/orders"
      />
      <NavItem
        label={formatMessage(messages.fleets)}
        url="/my-account/fleets"
      />
      <NavItem
        label={formatMessage(messages.discounts)}
        url="/my-account/discounts"
      />
    </ul>
  );
};

const LeftNavSkeleton = () => (
  <ul className="flex flex-row flex-wrap mb-6 bg-white rounded shadow lg:flex-col lg:flex-shrink lg:w-1/5 lg:mb-0 lg:mr-6">
    <li className="flex flex-wrap flex-grow items-center h-8 overflow-hidden lg:h-12 lg:flex-grow-0 lg:last:rounded-b lg:first:rounded-t">
      &nbsp;
    </li>
    <li className="flex flex-wrap flex-grow items-center h-8 overflow-hidden lg:h-12 lg:flex-grow-0 lg:last:rounded-b lg:first:rounded-t">
      &nbsp;
    </li>
    <li className="flex flex-wrap flex-grow items-center h-8 overflow-hidden lg:h-12 lg:flex-grow-0 lg:last:rounded-b lg:first:rounded-t">
      &nbsp;
    </li>
    <li className="flex flex-wrap flex-grow items-center h-8 overflow-hidden lg:h-12 lg:flex-grow-0 lg:last:rounded-b lg:first:rounded-t">
      &nbsp;
    </li>
    <li className="flex flex-wrap flex-grow items-center h-8 overflow-hidden lg:h-12 lg:flex-grow-0 lg:last:rounded-b lg:first:rounded-t">
      &nbsp;
    </li>
    <li className="flex flex-wrap flex-grow items-center h-8 overflow-hidden lg:h-12 lg:flex-grow-0 lg:last:rounded-b lg:first:rounded-t">
      &nbsp;
    </li>
    <li className="flex flex-wrap flex-grow items-center h-8 overflow-hidden lg:h-12 lg:flex-grow-0 lg:last:rounded-b lg:first:rounded-t">
      &nbsp;
    </li>
  </ul>
);

export default LeftNav;
export { LeftNav, LeftNavSkeleton };
