/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import { PriceContext } from 'app/common/contexts';
import { usePricingInfo } from 'app/common/hooks';

/**
 * Adds the `X-Price-Info-Context` header to the user-provided request `config`.
 *
 * @param {boolean} skipDetails - Flag that determines whether `PriceDetails`
 *     for all candidate price lists are not returned in addition to the best
 *     price. Default is true.
 *
 * @return {{headers: {}}}
 */
function usePriceInfoContextHeader(skipDetails = true) {
  const { priceLists } = React.useContext(PriceContext);
  const { priceInfoContextHeader } = usePricingInfo();

  return React.useMemo(
    () => ({
      headers: {
        [priceInfoContextHeader]: JSON.stringify({
          priceLists,
          skipDetails
        })
      }
    }),
    [priceInfoContextHeader, priceLists, skipDetails]
  );
}

export default usePriceInfoContextHeader;
