/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty } from 'lodash';
import { Helmet as ReactHelmet } from 'react-helmet';

import { AuthContext } from 'app/auth/contexts';
import { Icon, PrimaryButton, SecondaryButton } from 'app/common/components';
import { CustomerContext } from 'app/common/contexts';
import {
  useCurrentApplication,
  useCustomerService,
  useFormatMessage,
  useGtmPageView,
  useNationalSiteContext,
  useRestApi
} from 'app/common/hooks';

import messages from './StoreLocation.messages';
import { useHeaderMetadata } from '../../core/components/App';
import { ChooseLocationButton } from './ChooseLocationButton';

const StoreLocation = () => {
  const dealer = useCurrentApplication() || {};
  const { dealerNetwork } = useNationalSiteContext();
  const nameKey = dealerNetwork ? 'displayName' : 'name';
  const currentAppName = get(dealer, nameKey);
  const { isAuthenticated } = React.useContext(AuthContext);
  const preferredLocation = get(
    React.useContext(CustomerContext),
    'customer.preferredLocation'
  );
  const formatMessage = useFormatMessage();
  const { siteTitle } = useHeaderMetadata();
  const canonical = `${window.location.origin}/store-location`;
  const title = formatMessage(messages.title);
  const {
    dealerSelected,
    nationalSiteOrDealerNetworkContext: canChangeLocation
  } = useNationalSiteContext();
  const selectedDealerMessage = formatMessage(messages.selectedDealer, {
    name: !dealerSelected ? '' : currentAppName
  });
  const noDealerSelectedMessage = formatMessage(messages.noDealerSelected);
  const buttonLabel = dealerSelected
    ? formatMessage(messages.changeLocation, {
        hasSelected: !isEmpty(preferredLocation)
      })
    : formatMessage(messages.selectDealer);
  const subtitle = !dealerSelected
    ? noDealerSelectedMessage
    : selectedDealerMessage;
  useGtmPageView('Store Locator');

  return (
    <main className="container flex flex-col flex-grow p-4 sm:py-8 md:px-6 lg:px-8">
      <ReactHelmet titleTemplate={`%s - ${siteTitle}`}>
        <title>{title}</title>
        <meta
          name="description"
          content={formatMessage(messages.description)}
        />
        <meta name="canonical" content={canonical} />
        <meta name="robots" content="index,follow" />
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content={siteTitle} />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={formatMessage(messages.description)}
        />
        <link rel="canonical" href={canonical} />
      </ReactHelmet>
      <header className="mb-4 pb-2 border-b border-gray-400 md:mb-8">
        <h1 className="block text-gray-700 text-2xl font-medium leading-none md:text-4xl">
          {title}
        </h1>
      </header>
      <section className="flex flex-col md:flex-row">
        <section className="basis-full flex-shrink lg:basis-1/2">
          <header className="mb-4 pb-1 border-b border-gray-400">
            <h3 className="text-gray-700 font-medium text-lg lg:text-2xl">
              {subtitle}
            </h3>
          </header>
          <section className="flex flex-col items-start">
            {!dealerSelected ? (
              <>
                <p className="text-sm mb-4">
                  {formatMessage(messages.noDealerCaveat)}
                </p>
                {canChangeLocation && (
                  <ChooseLocationButton label={buttonLabel} />
                )}
              </>
            ) : (
              <>
                <Address {...dealer} companyName={currentAppName} />
                {isAuthenticated && (
                  <>
                    {preferredLocation !== dealer.identifierValue ? (
                      <MakePreferred dealer={dealer} />
                    ) : preferredLocation === undefined ? null : (
                      <div className="mt-2 text-sm text-green-600 sm:text-base">
                        {formatMessage(messages.isDealerPrimary)}
                      </div>
                    )}
                  </>
                )}
                {canChangeLocation && (
                  <ChooseLocationButton label={buttonLabel} />
                )}
              </>
            )}
          </section>
        </section>
      </section>
    </main>
  );
};

const Address = ({
  line1,
  city,
  companyName,
  deactivated = false,
  emailAddress,
  country,
  state,
  phoneNumber,
  zip
}) => {
  const formatMessage = useFormatMessage();
  return (
    <div className="flex flex-col-reverse items-start sm:flex-row">
      <address className="flex flex-col items-start text-gray-700 appearance-none not-italic">
        <h4 className="mb-2 font-bold lg:text-lg">{companyName}</h4>
        <div className="flex flex-wrap">
          <span>{line1}</span>
        </div>
        <div className="flex flex-wrap">
          <span>
            {city}
            {!isEmpty(state) ? ',\u00A0' : !isEmpty(zip) && '\u00A0'}
          </span>
          {!isEmpty(state) && (
            <span>
              {state}
              {!isEmpty(zip) && '\u00A0'}
            </span>
          )}
          {!isEmpty(zip) && <span>{zip}</span>}
        </div>
        {!isEmpty(country) && <div>{country}</div>}
        <div className="my-1" />
        {!isEmpty(phoneNumber) && (
          <a
            className="hover:text-primary-600 focus:text-primary-700 active:text-primary-400"
            href={`tel:${phoneNumber}`}
          >
            {phoneNumber}
          </a>
        )}
        {!isEmpty(emailAddress) && (
          <a
            className="hover:text-primary-600 focus:text-primary-700 active:text-primary-400"
            href={`mailto:${emailAddress}`}
          >
            {emailAddress}
          </a>
        )}
      </address>
      {deactivated && (
        <div className="flex items-center mb-2 px-2 py-1 text-sm text-red-600 leading-snug bg-red-100 border border-solid border-red-200 rounded sm:ml-4 md:px-4 md:py-2 lg:text-base lg:leading-normal">
          <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
          <span>{formatMessage(messages.primaryDealerInactive)}</span>
        </div>
      )}
    </div>
  );
};

const MakePreferred = ({ dealer }) => {
  const makePreferred = useMakePreferredLocation();
  const { customer, setCustomer } = React.useContext(CustomerContext);
  const formatMessage = useFormatMessage();
  const [error, setError] = React.useState(undefined);
  const { dealerNetwork } = useNationalSiteContext();
  const nameKey = dealerNetwork ? 'displayName' : 'name';

  return (
    <>
      {!!error && (
        <div className="mb-2 text-red-600">
          {formatMessage(messages.primaryDealerError, {
            dealer: get(dealer, nameKey)
          })}
        </div>
      )}
      <PrimaryButton
        className="mt-2"
        size={SecondaryButton.Size.SMALL}
        onClick={async () => {
          try {
            const response = await makePreferred({
              data: {
                ...customer
              },
              params: {
                preferredLocation: dealer.identifierValue
              }
            });
            setCustomer(response);
          } catch (err) {
            setError(true);
            setTimeout(() => {
              setError(false);
            }, 5000);
          }
        }}
      >
        {formatMessage(messages.makeDealerPrimary)}
      </PrimaryButton>
    </>
  );
};

function useMakePreferredLocation() {
  const { baseUrl } = useCustomerService();
  const {
    user: { serviceId: customerId }
  } = React.useContext(AuthContext);
  const config = React.useMemo(() => ({ method: 'put' }), []);
  const { sendCallback } = useRestApi(
    `${baseUrl}/${customerId}`,
    config,
    false,
    true
  );
  return sendCallback;
}

StoreLocation.Address = Address;

export default StoreLocation;
export { StoreLocation };
