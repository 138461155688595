/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import StageHeader from '../StageHeader';

/**
 * Layout element encapsulating the shared features of each checkout stage.
 *
 * @visibleName Checkout: Layout: Stage
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const Stage = ({
  active = false,
  completed = false,
  children,
  className,
  number,
  heading
}) => {
  const selfRef = useRef(null);

  useEffect(() => {
    const self = selfRef.current;

    if (!!self && active) {
      const rect = self.getBoundingClientRect();
      const header = document.getElementById('checkout-header');
      // make sure not to overlap the header
      const headerHeight = !!header ? header.scrollHeight : 0;
      // add a little padding between the top of the component and the header
      const padding = 8;
      window.scrollTo({
        top: Math.max(
          0,
          window.pageYOffset + rect.top - headerHeight - padding
        ),
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [active, completed]);

  return (
    <section
      ref={selfRef}
      className={classNames(
        'relative flex flex-col mb-4 bg-gray-100 rounded shadow-md lg:flex-row lg:mb-8',
        {
          [className]: !!className,
          'opacity-50': !active && !completed
        }
      )}
    >
      <Stage.Header active={active} completed={completed} number={number}>
        {heading}
      </Stage.Header>
      <section
        className={classNames('w-full p-4 lg:w-2/3', {
          'lg:py-6': !completed || active,
          flex: completed && !active
        })}
      >
        {children}
      </section>
    </section>
  );
};

Stage.propTypes = {
  /** Whether the stage is active */
  active: PropTypes.bool,
  /** Child components */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func
  ]),
  /** Additional class name(s) to add to the Stage */
  className: PropTypes.string
};

Stage.Header = StageHeader;

export default Stage;
export { Stage };
