/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon /*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { forEach, get, has, isEmpty, isNil, omit, startsWith } from 'lodash';
import PropTypes from 'prop-types';
import { default as qs } from 'query-string';

import { AuthContext } from 'app/auth/contexts';
import { AuthService } from 'app/auth/services';
import useResolveCart, { CartCache } from 'app/cart/hooks/useResolveCart';
import { CartContext } from 'app/common/contexts';
import {
  useCartInfo,
  useHistory,
  useLocation,
  useToggle
} from 'app/common/hooks';
import { Environment, RequestService } from 'app/common/services';
import { logError } from 'app/common/utils/ApiErrorUtils';

/**
 * Helper component that sets up the global `CartContext`.
 *
 * @visibleName Cart Provider
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const CartProvider = ({ children, ...props }) => {
  const isServer = typeof window === 'undefined';
  if (isServer) {
    return <>{children}</>;
  }

  return <CartProviderInner {...props}>{children}</CartProviderInner>;
};

function CartProviderInner({ children }) {
  const { isAuthenticated, isCsrAuthenticated } = useContext(AuthContext);
  const [cart, setCart] = useState(undefined);
  const [guestToken, setGuestToken] = useState(undefined);
  const [cartMessages, setCartMessages] = useState(undefined);
  const [isLocked, setIsLocked] = useState(false);
  const [isMinicartOpen, toggleMinicartOpen] = useToggle();
  const [itemErrors, setItemErrors] = useState({});
  const [cartOperationException, setCartOperationException] =
    useState(undefined);
  const { baseUrl } = useCartInfo().operations;

  const updateCacheAndSetGuestToken = useCallback(newToken => {
    setGuestToken(() => {
      if (isNil(newToken)) {
        CartCache.clear();
        return null;
      }

      // update the guestToken in local storage
      CartCache.put('guestToken', newToken);
      return newToken;
    });
  }, []);

  const { resolvedCart, resolvedGuestToken, isFetching, error } =
    useResolveCart();

  const updateCacheAndSetCart = useCallback(
    newCart => {
      setCart(() => {
        if (isNil(newCart)) {
          console.warn('CartProviderClear', new Error().stack);
          CartCache.clear();
          return null;
        }

        // update the cartId in local storage
        CartCache.put('cartId', newCart.id);
        CartCache.put('cartVersion', newCart.version);
        CartCache.put('isGuest', !isAuthenticated);

        return newCart;
      });
    },
    [isAuthenticated]
  );

  const putCartMessage = useCallback(
    (messageType, message) => {
      setCartMessages({ ...cartMessages, [messageType]: message });
    },
    [cartMessages]
  );

  const getCartMessage = useCallback(
    messageType => get(cartMessages, messageType),
    [cartMessages]
  );

  const removeCartMessage = useCallback(
    messageType => {
      setCartMessages(omit(cartMessages, messageType));
    },
    [cartMessages]
  );

  const location = useLocation();
  const history = useHistory();

  useEffect(
    () =>
      RequestService.attachRequestInterceptor(config => {
        const cartVersionHeaderName = Environment.get(
          'cart.version.header',
          'X-Cart-Version'
        );
        if (
          startsWith(config.url, baseUrl) &&
          !has(config, `headers.${cartVersionHeaderName}`)
        ) {
          if (cart && Number.isInteger(cart.version)) {
            config.headers[cartVersionHeaderName] = cart.version;
          }
        }

        return config;
      }),
    [baseUrl, cart]
  );

  useEffect(
    () =>
      RequestService.attachRequestInterceptor(config => {
        const cartGuestTokenHeaderName = Environment.get(
          'cart.token.header',
          'X-Guest-Token'
        );
        const cartVersionHeaderName = Environment.get(
          'cart.version.header',
          'X-Cart-Version'
        );
        if (startsWith(config.url, baseUrl)) {
          if (
            !has(config, `headers.${cartGuestTokenHeaderName}`) &&
            guestToken
          ) {
            config.headers[cartGuestTokenHeaderName] =
              CartCache.get('guestToken');
          }

          if (has(config, `headers.${cartVersionHeaderName}`)) {
            // If we are doing a cart operations call with a cart version, we want to make sure the correct newest grabbed cart version is being attached in certain cases.
            config.headers[cartVersionHeaderName] =
              CartCache.get('cartVersion');
          }
        }

        return config;
      }),
    [baseUrl, guestToken]
  );

  useEffect(
    () =>
      RequestService.attachRequestInterceptor(async config => {
        if (startsWith(config.url, baseUrl)) {
          if (isCsrAuthenticated && !isAuthenticated) {
            config = await AuthService.addBearerToken(config, 'CSR');
          }
        }

        return config;
      }),
    [baseUrl, isCsrAuthenticated, isAuthenticated]
  );

  useEffect(() => {
    if (error) {
      logError({ ...error, when: 'fetching the current cart' });
      setCart(null);
    }
  }, [error]);

  useEffect(() => {
    const items = get(cart, 'cartItems');
    setItemErrors(CartProvider.collectItemErrors(items));
  }, [cart]);

  useEffect(() => {
    const search = qs.parse(location.search);
    if (!search.openMinicart) {
      return;
    }

    if (search.openMinicart) {
      toggleMinicartOpen(true);
      history.replace({
        ...location,
        search: qs.stringify(omit(search, ['openMinicart']))
      });
    }
  }, [history, location, toggleMinicartOpen]);

  useEffect(() => {
    if (!error && !isFetching && resolvedCart !== null) {
      setCart(resolvedCart);
      if (resolvedGuestToken !== null) {
        setGuestToken(resolvedGuestToken);
      }
    }
  }, [error, isFetching, resolvedCart, resolvedGuestToken]);

  useEffect(() => {
    // prevent displaying the error on page changing since it should be dismissed
    if (cartOperationException !== undefined) {
      setCartOperationException(undefined);
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const context = useMemo(
    () => ({
      cart,
      setCart: updateCacheAndSetCart,
      isMinicartOpen,
      toggleMinicartOpen,
      ...itemErrors,
      resolving: isFetching,
      isLocked,
      setIsLocked,
      cartOperationException,
      setCartOperationException,
      cartMessages,
      putCartMessage,
      getCartMessage,
      removeCartMessage,
      guestToken,
      setGuestToken: updateCacheAndSetGuestToken
    }),
    [
      cart,
      updateCacheAndSetCart,
      isMinicartOpen,
      toggleMinicartOpen,
      itemErrors,
      isFetching,
      isLocked,
      setIsLocked,
      cartOperationException,
      setCartOperationException,
      cartMessages,
      putCartMessage,
      getCartMessage,
      removeCartMessage,
      guestToken,
      updateCacheAndSetGuestToken
    ]
  );

  return (
    <CartContext.Provider value={context}>{children}</CartContext.Provider>
  );
}

CartProvider.propTypes = {
  /** Child components to render within the component  */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func
  ])
};

CartProvider.collectItemErrors = cartItems => {
  let itemConfigErrorMap = {};
  let itemAttributeErrorMap = {};
  let hasItemConfigErrors = false;
  let hasItemAttributeErrors = false;

  forEach(cartItems || [], cartItem => {
    const { name, itemConfigErrors, attributeConfigErrors } = cartItem;

    if (!isEmpty(itemConfigErrors)) {
      itemConfigErrorMap[name] = itemConfigErrors;

      if (!hasItemConfigErrors) {
        hasItemConfigErrors = true;
      }
    }

    if (!isEmpty(attributeConfigErrors)) {
      itemAttributeErrorMap[name] = attributeConfigErrors;

      if (!hasItemAttributeErrors) {
        hasItemAttributeErrors = true;
      }
    }
  });

  return {
    itemConfigErrorMap,
    itemAttributeErrorMap,
    hasItemConfigErrors,
    hasItemAttributeErrors
  };
};

export default CartProvider;
export { CartProvider };
