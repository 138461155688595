/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import {
  VARIANT_ATTRIBUTE_CHOICE,
  ITEM_CHOICE,
  CART_ITEM_ATTRIBUTE_CHOICE
} from './configurableItemActionTypes';

const variantAttributeChoice = (attributeName, attributeValue) => ({
  variantAttributeChoice: { name: attributeName, value: attributeValue },
  type: VARIANT_ATTRIBUTE_CHOICE
});
const itemChoice = choice => ({ itemChoice: choice, type: ITEM_CHOICE });
const cartItemAttributeChoice = (attributeName, attributeValue) => ({
  cartItemAttributeChoice: { name: attributeName, value: attributeValue },
  type: CART_ITEM_ATTRIBUTE_CHOICE
});

export { variantAttributeChoice, cartItemAttributeChoice, itemChoice };
