/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import log from 'loglevel';
import { padStart, upperCase } from 'lodash';

/**
 * Decorates the log statements with a prefix pattern including the timestamp,
 * level, and moduleName.
 *
 * @param  {Object} log the loglevel logger
 */
function decorateLogger(log) {
  const originalFactory = log.methodFactory;
  log.methodFactory = function (methodName, logLevel, loggerName) {
    const originalMethod = originalFactory(methodName, logLevel, loggerName);
    return function (...args) {
      const timestamp = new Date(Date.now())
        .toJSON()
        .replace('T', ' ')
        .replace('Z', '');
      const level = padStart(upperCase(methodName), 5);
      const moduleName = loggerName || 'global';
      originalMethod(`${timestamp} ${level} --- ${moduleName} :`, ...args);
    };
  };
  log.setLevel(log.getLevel());
}

decorateLogger(log);

window.log = log;

export default log;
