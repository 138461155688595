import React from 'react';
import { useFormatMessage } from 'app/common/hooks';
import messages from '../FulfillmentInfoWithOptions.messages';

const PaymentFailureError = () => {
  const formatMessage = useFormatMessage();
  return {
    modalTitle: formatMessage(messages.paymentFailureModalTitle),
    content: () => <ModalContent />,
    modalName: 'payment-failure'
  };
};

const ModalContent = () => {
  const formatMessage = useFormatMessage();
  return (
    <div>
      <div>
        <h2>{formatMessage(messages.paymentFailureModalHeader)}</h2>
        <p>{formatMessage(messages.paymentFailureModalContent)}</p>
      </div>
    </div>
  );
};

export default PaymentFailureError;
