import React, { useEffect, useState } from 'react';
import {
  defaultRefreshEffectCondition,
  useCatalogInfo,
  useRefreshEffect,
  useRestApi,
  useToggle
} from 'app/common/hooks';
import {
  COMPLETE,
  IN_PROGRESS
} from 'app/common/hooks/useRestApi/RequestStatus.js';
import { merge } from 'lodash';
import { logError } from 'app/common/utils/ApiErrorUtils';

function useFetchCategoryApi(
  categoryUrl,
  rootsOnly,
  priceContextHeader,
  priceInfoContextHeader,
  hasLoadedPriceLists
) {
  const [category, setCategory] = useState(null);
  const [fetching, toggleFetching] = useToggle(false);

  const {
    categoryInfo: { detailsUrl }
  } = useCatalogInfo();

  const config = React.useMemo(
    () =>
      merge(
        {
          params: {
            categoryUrl: categoryUrl,
            rootsOnly: rootsOnly,
            offset: 0,
            forward: true,
            // don't need any products
            size: 0
          }
        },
        priceContextHeader,
        priceInfoContextHeader
      ),
    [categoryUrl, rootsOnly, priceContextHeader, priceInfoContextHeader]
  );

  const {
    error,
    exception,
    response,
    sendCallback: fetchCategory,
    requestStatus
  } = useRestApi(detailsUrl, config, false);

  useRefreshEffect(
    () => {
      fetchCategory();
      toggleFetching(true);
    },
    { config, hasLoadedPriceLists },
    refreshCondition,
    hasLoadedPriceLists
  );

  function refreshCondition(dependencies, prevDependencies) {
    return (
      defaultRefreshEffectCondition(dependencies, prevDependencies) &&
      dependencies.hasLoadedPriceLists
    );
  }

  useEffect(() => {
    if (requestStatus === IN_PROGRESS) {
      return;
    }

    if (error) {
      logError({
        ...exception,
        when: `fetching a Category matching the path ${categoryUrl}`
      });
      return;
    }

    if (requestStatus === COMPLETE && fetching) {
      setCategory(response);
      toggleFetching(false);
    }
  }, [
    categoryUrl,
    error,
    exception,
    response,
    requestStatus,
    category,
    setCategory,
    fetching,
    toggleFetching
  ]);

  return { category, error };
}

export default useFetchCategoryApi;
