/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { find, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { PhoneType } from 'app/common/constants';
import {
  useFormatMessage,
  useStateProvinceRegionSelectValues
} from 'app/common/hooks';

const FulfillmentAddressReadOnly = ({
  address,
  className,
  isPickup = false,
  messages
}) => {
  const formatMessage = useFormatMessage();
  const allStateProvinceRegions = useStateProvinceRegionSelectValues();
  const relevantStateProvinceRegions =
    allStateProvinceRegions[get(address, 'country')];
  const stateProvinceRegion = get(
    find(relevantStateProvinceRegions, ['value', address.stateProvinceRegion]),
    'value'
  );
  const primaryPhone = get(address, 'phonePrimary') || {};
  const secondaryPhone = get(address, 'phoneSecondary') || {};
  const fax = get(address, 'phoneFax') || {};
  const hasCompanyName =
    !isEmpty(address.companyName) && get(address, 'companyName') !== 'N/A';
  return (
    <address className={classNames('not-italic', { [className]: !!className })}>
      <h3 className="mb-1 text-base text-gray-900 font-bold">
        {isPickup
          ? formatMessage(messages.pickupContactLabel)
          : formatMessage(messages.deliveryAddressLabel)}
      </h3>
      <div>
        {address.fullName || `${address.firstName} ${address.lastName}`}
      </div>
      {hasCompanyName && <div>{address.companyName}</div>}
      <>
        <div className="flex flex-wrap">
          <span>
            {address.addressLine1}
            {!isEmpty(address.addressLine2) && ',\u00A0'}
          </span>
          {!isEmpty(address.addressLine2) && (
            <span>{address.addressLine2}</span>
          )}
        </div>
        {!isEmpty(address.addressLine3) && <div>{address.addressLine3}</div>}
        <div>
          {address.city}, {stateProvinceRegion} {address.postalCode}
        </div>
        <div>{get(address, 'country')}</div>
      </>
      {!isEmpty(primaryPhone.phoneNumber) && (
        <div className="mt-2">
          {formatMessage(messages.primaryPhone, {
            phone: primaryPhone.phoneNumber,
            kind: formatMessage(PhoneType[primaryPhone.type].label)
          })}
        </div>
      )}
      {!isEmpty(secondaryPhone.phoneNumber) && (
        <div>
          {formatMessage(messages.secondaryPhone, {
            phone: secondaryPhone.phoneNumber,
            kind: formatMessage(PhoneType[secondaryPhone.type].label)
          })}
        </div>
      )}
      {!isEmpty(fax.phoneNumber) && (
        <div>
          {formatMessage(messages.fax, {
            phone: fax.phoneNumber
          })}
        </div>
      )}
    </address>
  );
};

FulfillmentAddressReadOnly.propTypes = {
  address: PropTypes.shape({
    fullName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    companyName: PropTypes.string,
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string,
    city: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    stateProvinceRegion: PropTypes.string.isRequired,
    phonePrimary: PropTypes.shape({
      phoneNumber: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  className: PropTypes.string,
  messages: PropTypes.object.isRequired
};

export default FulfillmentAddressReadOnly;
export { FulfillmentAddressReadOnly };
