/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import DropdownMenu from 'app/common/components/Dropdown/components/DropdownMenu';
import React from 'react';
import { map } from 'lodash';

import { Button, Dropdown, Icon } from 'app/common/components';
import {
  useCatalogInfo,
  useFormatMessage,
  useMenuApi,
  useMenuInfo
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import messages from '../../Header.messages';

const CourtesyDropdown = () => {
  const formatMessage = useFormatMessage();
  const { fetchUrl, siteHelpMenuName: name } = useMenuInfo();
  const {
    exception,
    error,
    loading,
    response: menu
  } = useMenuApi(fetchUrl, name);

  if (error) {
    logError({ ...exception, when: `fetching Menu ${name}` });
    return null;
  }

  const { label, submenu = [] } = menu || {};

  return (
    <Dropdown hoverable>
      <Dropdown.Menu.Trigger
        triggerComponent={props => (
          <Button
            className="p-2 flex self-end items-center justify-end flex-1 px-4 lg:px-2 xl:px-4 text-gray-700 hover:text-gray-800 hover:bg-gray-100"
            onClick={props.handleTrigger}
            title={label || formatMessage(messages.siteHelp)}
          >
            <span className="flex">
              <Icon name={['far', 'question-circle']} size="lg" />
            </span>
          </Button>
        )}
      />
      {(!loading || menu !== undefined) && (
        <Dropdown.Menu
          className="mt-1 text-gray-900"
          openTo={DropdownMenu.OpenTo.RIGHT}
        >
          {map(submenu, item => (
            <MenuLink {...item} key={item.label} />
          ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

const MenuLink = ({ label, type, url }) => {
  const { categoryInfo, productInfo } = useCatalogInfo();
  const { routeBaseContext: categoryPrefix } = categoryInfo;
  const { routeBaseContext: productPrefix } = productInfo;

  let to = url;

  if (type === 'CATEGORY') {
    to = categoryPrefix + url;
  } else if (type === 'PRODUCT') {
    to = productPrefix + url;
  }

  return (
    <Dropdown.Menu.Item padding="">
      <Button className="block px-4 py-2" to={to}>
        {label}
      </Button>
    </Dropdown.Menu.Item>
  );
};

export default CourtesyDropdown;
export { CourtesyDropdown };
