/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';

import { Environment } from '../../../services';

function useOrderInfo() {
  return useMemo(
    () => ({
      baseUrl: Environment.get('ORDER_BASE_ENDPOINT', '/order-operations'),
      operations: {
        orderHistoryContextPath: Environment.get(
          'ORDER_OPS_HISTORY_ENDPOINT',
          '/order-operations/order-history'
        ),
        guestOrderHistoryContextPath: Environment.get(
          'ORDER_OPS_HISTORY_ENDPOINT',
          '/order-operations/guest-order-history'
        )
      }
    }),
    []
  );
}

export default useOrderInfo;
