/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty, map, values } from 'lodash';
import PropTypes from 'prop-types';

import { ItemChoiceTargetType } from 'app/common/constants';
import { ConfigurableItem } from 'app/product/helpers';
import { itemChoice as itemChoiceAction } from 'app/product/helpers/ConfigurableItem';

import { getChoices } from '../../utils/ChoiceUtils';
import { Choice } from './components';

/**
 * @todo for now ignoring minimumQuantity for choose_multiple since the quantity
 *   can be spread across choices; ergo, any single choice could have quantity 0
 *   although the total quantity across all choices has to be the minimum. For
 *   now we leave this validation to the backend only.
 */
const ChooseMultiple = ({
  dispatchChoice,
  itemChoice,
  selectedItemChoices
}) => {
  const choices = getChoices(itemChoice);
  const { choiceKey } = itemChoice;
  const selectedItemChoice = get(selectedItemChoices, choiceKey);
  const handleChange = React.useCallback(
    newChoice => {
      dispatchChoice(itemChoiceAction(newChoice));
    },
    [dispatchChoice]
  );

  return (
    <ol className="flex flex-col w-full list-none">
      {map(choices, (choice, i) => {
        return (
          <ConfigurableItem
            key={i}
            item={isEmpty(choice.product) ? choice : choice.product}
          >
            <Choice
              choice={choice}
              itemChoice={itemChoice}
              handleChange={handleChange}
              selectItemChoice={selectedItemChoice}
            />
          </ConfigurableItem>
        );
      })}
    </ol>
  );
};

ChooseMultiple.propTypes = {
  /** Dispatch for item choice selection */
  dispatchChoice: PropTypes.func.isRequired,
  itemChoice: PropTypes.shape({
    choiceKey: PropTypes.string.isRequired,
    maximumQuantity: PropTypes.number,
    minimumQuantity: PropTypes.number,
    targetType: PropTypes.oneOf(values(ItemChoiceTargetType))
  }).isRequired,
  selectedItemChoices: PropTypes.object
};

export default ChooseMultiple;
export { ChooseMultiple };
