/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { Form, Formik } from 'formik';
import { get, isEmpty } from 'lodash';

import { AuthContext } from 'app/auth/contexts';
import {
  determineDefaultCountry,
  determineDefaultStateProvinceRegion
} from 'app/checkout/utils/AddressUtils';
import {
  AddressForm,
  PrimaryButton,
  TertiaryButton
} from 'app/common/components';
import { LocaleContext } from 'app/common/contexts';
import {
  useAddCustomerAddressApi,
  useFormatMessage,
  useWriteCustomerAddressApi
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';
import { normalizePhoneNumber } from 'app/common/utils/AddressUtils';

import messages from '../../Addresses.messages';

const SavedAddressForm = props => {
  const { refetchAddresses, selectedAddress, setSelectedAddress } = props;
  const formatMessage = useFormatMessage();
  const { currentLocale } = React.useContext(LocaleContext);
  const countryDefault = determineDefaultCountry(currentLocale);
  const stateProvinceRegionDefault =
    determineDefaultStateProvinceRegion(countryDefault);
  const { sendCallback: updatedAddress } = useWriteCustomerAddressApi(
    selectedAddress || {}
  );
  const { sendCallback: addAddress } = useAddCustomerAddressApi(
    selectedAddress || {}
  );
  const { user } = React.useContext(AuthContext);
  const customerId = get(user, 'serviceId');
  const formRef = React.useRef();
  return (
    <div className="w-full p-4 bg-gray-100">
      <Formik
        innerRef={formRef}
        initialValues={
          !!selectedAddress
            ? {
                addressName: selectedAddress.name || '',
                ...selectedAddress,
                country: get(selectedAddress, 'country') || countryDefault,
                phonePrimary:
                  get(selectedAddress, 'phonePrimary.phoneNumber') || '',
                phonePrimaryType:
                  get(selectedAddress, 'phonePrimary.phoneType') || 'MOBILE',
                phoneSecondary:
                  get(selectedAddress, 'phoneSecondary.phoneNumber') || '',
                phoneSecondaryType:
                  get(selectedAddress, 'phoneSecondary.phoneType') || 'MOBILE',
                phoneFax: get(selectedAddress, 'phoneFax.phoneNumber') || '',
                phoneFaxType:
                  get(selectedAddress, 'phoneFax.phoneType') || 'MOBILE'
              }
            : {
                addressName: formatMessage(messages.newAddressDefaultName),
                fullName: '',
                firstName: '',
                lastName: '',
                addressLine1: '',
                addressLine2: '',
                country: countryDefault,
                stateProvinceRegion: stateProvinceRegionDefault,
                city: '',
                companyName: '',
                postalCode: '',
                phonePrimary: '',
                phonePrimaryType: 'LANDLINE',
                phoneSecondary: '',
                phoneSecondaryType: 'MOBILE',
                phoneFax: '',
                phoneFaxType: 'FAX'
              }
        }
        onSubmit={async (values, actions) => {
          const addressId = get(selectedAddress, 'id');
          const newAddress = {
            fullName: values.fullName,
            firstName: values.firstName,
            lastName: values.lastName,
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            country: values.country,
            stateProvinceRegion: values.stateProvinceRegion,
            city: values.city,
            companyName: values.companyName,
            postalCode: values.postalCode,
            phonePrimary: {
              phoneNumber: normalizePhoneNumber(values.phonePrimary),
              phoneType: values.phonePrimaryType
            },
            phoneSecondary: isEmpty(values.phoneSecondary)
              ? undefined
              : {
                  phoneNumber: normalizePhoneNumber(values.phoneSecondary),
                  phoneType: values.phoneSecondaryType
                },
            phoneFax: isEmpty(values.phoneFax)
              ? undefined
              : {
                  phoneNumber: normalizePhoneNumber(values.phoneFax),
                  phoneType: values.phoneFaxType
                }
          };
          try {
            if (!isEmpty(addressId)) {
              await updatedAddress({
                data: {
                  ...selectedAddress,
                  name: values.addressName,
                  ...newAddress
                }
              });
              actions.setSubmitting(false);
              setSelectedAddress(undefined);
            } else {
              await addAddress({
                data: {
                  name: values.addressName,
                  ...newAddress,
                  customerRef: {
                    id: customerId
                  }
                }
              });
            }
            actions.setSubmitting(false);
            refetchAddresses();
          } catch (err) {
            logError({ ...err, when: 'updated customer address' });
            actions.setSubmitting(false);
          }
        }}
        validateOnChange={false}
        validateOnBlur
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col">
            <AddressForm
              type={AddressForm.Type.SAVED}
              disabled={isSubmitting}
            />
            <div className="flex justify-between w-full">
              <TertiaryButton
                id={'cancelEditAddressButton'}
                onClick={() => {
                  if (formRef.current) {
                    formRef.current.resetForm();
                  }
                  setSelectedAddress(undefined);
                }}
              >
                <div className="text-red-600">
                  {formatMessage(messages.cancelAddButton)}
                </div>
              </TertiaryButton>
              <PrimaryButton
                type="submit"
                disabled={isSubmitting}
                id={'submitEditAddressButton'}
              >
                {!isEmpty(get(selectedAddress, 'id'))
                  ? formatMessage(messages.updateAddress)
                  : formatMessage(messages.addAddress)}
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SavedAddressForm;
export { SavedAddressForm };
