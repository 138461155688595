/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useContext, useMemo } from 'react';
import * as Yup from 'yup';

import { LocaleContext } from 'app/common/contexts';
import { useFormatMessage } from 'app/common/hooks';

import messages from './registrationSchemas.messages';

function usePasswordSchemas() {
  return useMemo(
    () => ({
      length: Yup.string().min(8),
      digit: Yup.string().matches(/(?=.*\d)/),
      uppercase: Yup.string().matches(/(?=.*[A-Z])/),
      lowercase: Yup.string().matches(/(?=.*[a-z])/),
      symbol: Yup.string().matches(/(?=.*[●!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/),
      whitespace: Yup.string().matches(/(^\S*$)/)
    }),
    []
  );
}

function useValidationSchema() {
  const { currentLocale } = useContext(LocaleContext);
  const formatMessage = useFormatMessage();
  return useMemo(() => {
    // trick react's linter to let current locale be used to trigger recreating
    // this object
    return (
      !!currentLocale &&
      Yup.object().shape({
        firstName: Yup.string().required(
          formatMessage(messages.firstNameRequired)
        ),
        lastName: Yup.string().required(
          formatMessage(messages.lastNameRequired)
        ),
        email: Yup.string()
          .email(formatMessage(messages.validEmail))
          .required(formatMessage(messages.emailRequired)),
        address: Yup.object().shape({
          addressLineOne: Yup.string().required(
            formatMessage(messages.addressLineOneRequired)
          ),
          addressLineTwo: Yup.string().nullable(),
          addressLineThree: Yup.string().nullable(),
          city: Yup.string().required(formatMessage(messages.cityRequired)),
          country: Yup.string().required(
            formatMessage(messages.countryRequired)
          ),
          postal: Yup.string().required(formatMessage(messages.postalRequired)),
          state: Yup.string().required(formatMessage(messages.stateRequired))
        }),
        companyName: Yup.string().required(
          formatMessage(messages.companyNameRequired)
        )
      })
    );
  }, [currentLocale, formatMessage]);
}

export { useValidationSchema, usePasswordSchemas };
