/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';

import {
  Icon,
  Link,
  OrderReviewFulfillments,
  OrderReviewProgressBar,
  TertiaryButton
} from 'app/common/components';
import {
  useFormatMessage,
  useGtmPageView,
  useReadCustomerOrders
} from 'app/common/hooks';

import {
  FulfillmentSummary,
  PaymentSummary
} from 'app/checkout/components/CheckoutLayout';
import messages from './OrderDetailsView.messages';
import { useHeaderMetadata } from '../../../../../core/components/App';
import { useLocation } from 'react-router-dom';

const OrderDetailsView = props => {
  const { id } = props.match.params;
  const [order, setOrder] = useState(undefined);
  const formatMessage = useFormatMessage();
  const location = useLocation();
  const { loading, response, sendCallback } = useReadCustomerOrders(
    undefined,
    id
  );
  const { siteTitle } = useHeaderMetadata();

  useGtmPageView('Historical Order Details');

  useEffect(() => {
    (async () => setOrder(await sendCallback()))();
  }, [sendCallback]);

  if (loading || response === undefined) {
    return <OrderDetailsViewSkeleton />;
  }

  if (!order) {
    return null;
  }
  let backToOrders = '/my-account/orders';
  if (location.search) {
    backToOrders += location.search;
  }

  return (
    <div>
      <Helmet titleTemplate={`%s - ${siteTitle}`}>
        <title>{formatMessage(messages.detailsViewTitle)}</title>
      </Helmet>
      <div className="text-bold flex justify-between pb-4">
        <div className="text-2xl">
          {formatMessage(messages.detailsViewTitle)}
        </div>
        <TertiaryButton to={backToOrders}>
          <Icon className="mr-1" name="angle-left" />
          <span>{formatMessage(messages.backToOrders)}</span>
        </TertiaryButton>
      </div>
      <div className="p-4 shadow rounded bg-white">
        <div className="flex flex-wrap items-center mt-2 mb-4">
          <div className="font-bold mr-2">
            {formatMessage(messages.details)}
          </div>
          <div className="text-primary-600 text-sm sm:text-base">
            {order.orderNumber}
          </div>
        </div>
        <OrderReviewProgressBar className="pt-4 pb-8" order={order} />
        <FulfillmentSummary order={order} />
        <OrderReviewFulfillments
          orderItems={order.orderItems || []}
          loading={loading}
        />
        {!isEmpty(order.returnItems) && (
          <OrderReviewFulfillments
            isReturns
            orderItems={order.returnItems || []}
            loading={loading}
          />
        )}
        <PaymentSummary order={order} />
      </div>
    </div>
  );
};

const OrderDetailsViewSkeleton = () => {
  const formatMessage = useFormatMessage();
  const { siteTitle } = useHeaderMetadata();

  return (
    <div>
      <Helmet titleTemplate={`%s - ${siteTitle}`}>
        <title>{formatMessage(messages.detailsViewTitle)}</title>
      </Helmet>
      <div className="text-bold flex justify-between pb-4">
        <div className="text-2xl">
          {formatMessage(messages.detailsViewTitle)}
        </div>
        <Link
          to="/my-account/orders"
          className="px-4 text-primary-600 hover:text-primary-700 focus:text-primary-700"
        >
          {formatMessage(messages.backToOrders)}
        </Link>
      </div>
      <div className="p-4 shadow rounded bg-white">
        <div className="text-lg py-2">
          <div className="w-64 mb-4 text-primary-600 bg-gray-200 sm:w-80">
            &nbsp;
          </div>
          <div className="w-48 mb-2 text-primary-600 bg-gray-200">&nbsp;</div>
        </div>
        <FulfillmentSummary.Skeleton />
        <OrderReviewFulfillments.ItemsSkeleton />
        <PaymentSummary.Skeleton />
      </div>
    </div>
  );
};

OrderDetailsView.Skeleton = OrderDetailsViewSkeleton;

export default OrderDetailsView;
export { OrderDetailsView, OrderDetailsViewSkeleton };
