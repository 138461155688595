/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  empty: {
    id: 'Orders.empty',
    defaultMessage: 'No previous orders'
  },
  listViewTitle: {
    id: 'Orders.listViewTitle',
    defaultMessage: 'Your Orders'
  },
  loading: {
    id: 'Orders.loading',
    defaultMessage: 'Loading...'
  },
  lastThirtyDays: {
    id: 'Orders.lastThirtyDays',
    defaultMessage: 'in Last 30 Days'
  },
  lastNinetyDays: {
    id: 'Orders.lastNinetyDays',
    defaultMessage: 'in Last 3 Months'
  },
  thisYear: {
    id: 'Orders.thisYear',
    defaultMessage: 'This Year'
  },
  allTime: {
    id: 'Orders.allTime',
    defaultMessage: 'All Time'
  },
  ordersPlacedIn: {
    id: 'Orders.ordersPlacedIn',
    defaultMessage: 'orders placed'
  },
  year: {
    id: 'Orders.year',
    defaultMessage: 'in {year}'
  },
  filterByLocation: {
    id: 'Orders.filterByLocation',
    defaultMessage: 'Filter by Order Location'
  },
  selectAllLocations: {
    id: 'Orders.selectAllLocations',
    defaultMessage: 'All Locations'
  }
});
