/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext, useMemo } from 'react';
import { noop } from 'lodash';

import { AuthContext } from 'app/auth/contexts';
import { AuthService } from 'app/auth/services';

import CSRContext from './CSRContext';

const CSRProvider = props => {
  const { isCsrAuthenticated, isAnonymousCsr } = useContext(AuthContext);

  const context = useMemo(() => {
    if (!isCsrAuthenticated) {
      return { csrAnonymous: false, csrMode: false, exitCsrMode: noop };
    }
    return {
      csrAnonymous: isAnonymousCsr,
      csrMode: isCsrAuthenticated,
      exitCsrMode
    };
  }, [isCsrAuthenticated, isAnonymousCsr]);

  return (
    <CSRContext.Provider value={context}>{props.children}</CSRContext.Provider>
  );
};

function exitCsrMode() {
  AuthService.logout();
}

export default CSRProvider;
