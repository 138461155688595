import messages from 'app/checkout/components/CheckoutLayout/components/FulfillmentInfoWithOptions/FulfillmentInfoWithOptions.messages';
import {
  DecoratedField,
  OptionalField,
  TextAreaField
} from 'app/common/components';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { get, size } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useFormatMessage, useFormatNumber } from 'app/common/hooks';

const MAX_INSTRUCTIONS_LENGTH = 1000;

function OrderInstructions({ isSubmitting, active, values }) {
  const formatMessage = useFormatMessage();
  return (
    <fieldset
      className="flex flex-col items-start justify-start"
      disabled={isSubmitting || !active}
    >
      <legend className="mb-4 text-gray-900 font-bold lg:text-lg">
        {formatMessage(messages.orderInstructionsLabel)}
      </legend>
      <OptionalField
        name="orderInstructions"
        triggerLabel={formatMessage(messages.orderInstructionsAdd)}
      >
        <DecoratedField
          containerWidths="w-full"
          id="order-instructions"
          name="orderInstructions"
          component={TextAreaField}
          label={formatMessage(messages.orderInstructionsLabel)}
          labelProps={{ className: 'sr-only' }}
          renderHint={() => OrderInstructionsHint(values)}
          disabled={isSubmitting || !active}
          autoComplete="off"
          widths="w-full h-24 lg:h-28"
          handleChange={e => {
            if (size(e.target.value) > MAX_INSTRUCTIONS_LENGTH) {
              e.target.value = e.target.value.slice(0, MAX_INSTRUCTIONS_LENGTH);
            }
          }}
        />
      </OptionalField>
    </fieldset>
  );
}

const OrderInstructionsHint = values => {
  const formatMessage = useFormatMessage();
  const formatNumber = useFormatNumber();
  return (
    <>
      <div className="flex items-start justify-between p-2 leading-snug">
        <em className="block flex-grow text-xs text-gray-600 not-italic sm:text-sm">
          <FormattedMessage
            {...messages.orderInstructionsHint}
            values={{
              preamble: (
                <b>{formatMessage(messages.orderInstructionsHintQuestion)}</b>
              )
            }}
          />{' '}
          <span className="italic">
            {formatMessage(messages.orderInstructionsRequirementsHint)}
          </span>
        </em>
        <div
          className={classNames(
            'flex flex-no-wrap items-center justify-end pl-1 text-xs font-bold sm:text-sm',
            {
              'text-green-700':
                size(get(values, 'orderInstructions')) <
                MAX_INSTRUCTIONS_LENGTH,
              'text-yellow-600':
                size(get(values, 'orderInstructions')) ===
                MAX_INSTRUCTIONS_LENGTH,
              'text-red-700':
                size(get(values, 'orderInstructions')) > MAX_INSTRUCTIONS_LENGTH
            }
          )}
        >
          <div>{formatNumber(size(get(values, 'orderInstructions')))}</div>
          <div className="mx-1">{'/'}</div>
          <div>{formatNumber(MAX_INSTRUCTIONS_LENGTH)}</div>
        </div>
      </div>
    </>
  );
};

OrderInstructions.propTypes = {
  submitting: PropTypes.bool,
  active: PropTypes.any,
  values: PropTypes.object
};

export default OrderInstructions;
