/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Price } from 'app/common/components';

/**
 * Render component for cart item's pricing
 */
const ItemPricing = ({ quantity, unitPrice }) => {
  return (
    <section className="flex items-center text-gray-700">
      <Price price={unitPrice} />
      <Icon className="mx-2" name="times" size="xs" />
      <span className="MinicartDialogItemPricing__quantity">{quantity}</span>
    </section>
  );
};

ItemPricing.propTypes = {
  quantity: PropTypes.number.isRequired,
  unitPrice: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired
  }).isRequired
};

export default ItemPricing;
export { ItemPricing };
