/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  metaTitle: {
    id: 'SearchResultsPage.metaTitle',
    defaultMessage: '"{query}" Search Results'
  },
  metaDescription: {
    id: 'SearchResultsPage.metaDescription',
    defaultMessage: 'Search Results for "{query}"'
  },
  title: {
    id: 'SearchResultsPage.title',
    defaultMessage: 'Search Results for "{query}"'
  },
  breadcrumbLabel: {
    id: 'SearchResultsPage.breadcrumbLabel',
    defaultMessage: 'Search Results'
  }
});
