/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { map, slice, size } from 'lodash';

import { TertiaryButton } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import { OrderSummaryHeader, OrderSummaryItem } from './components';
import messages from './OrderSummary.messages';

const OrderSummary = props => {
  const { order, viewDetails } = props;
  const formatMessage = useFormatMessage();
  return (
    <div className="text-gray-900 bg-white overflow-hidden rounded shadow">
      <OrderSummaryHeader order={order} viewDetails={viewDetails} />
      <div className="p-4 bg-gray-100">
        <ul>
          {map(slice(order.orderItems, 0, 3), item => (
            <OrderSummaryItem key={item.id} item={item} />
          ))}
          {size(order.orderItems) > 3 && (
            <li className="mt-4">
              <TertiaryButton
                to={`/my-account/orders/${order.orderNumber}`}
                size={TertiaryButton.Size.SMALL}
              >
                {formatMessage(messages.seeMoreItems, {
                  quantity: size(order.orderItems) - 3
                })}
              </TertiaryButton>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default OrderSummary;
