/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { Currency } from 'app/common/components';

const ProductSuggestionPrices = ({ pricing }) => {
  if (isEmpty(pricing)) {
    return null;
  }
  const { isSale, isStandard, priceType, price, originalPrice } = pricing;
  const standardPrice =
    priceType === 'standardPrice' || priceType === 'basePrice' || isStandard;
  const isSpecialPrice = isSale || !standardPrice;
  return (
    <div className="flex flex-wrap flex-grow items-center my-1">
      {isSpecialPrice ? (
        <>
          <Currency
            className="text-red-600 text-lg"
            amount={price.amount}
            currency={price.currency}
          />
          {!isEmpty(originalPrice) && (
            <Currency
              className="ml-2 text-sm text-gray-500 line-through"
              amount={originalPrice.amount}
              currency={originalPrice.currency}
            />
          )}
        </>
      ) : (
        !isEmpty(price) && (
          <Currency
            className="text-gray-900 text-lg"
            amount={price.amount}
            currency={price.currency}
          />
        )
      )}
    </div>
  );
};

ProductSuggestionPrices.propTypes = {
  pricing: PropTypes.shape({
    price: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      currency: PropTypes.string
    }).isRequired,
    isSale: PropTypes.bool,
    originalPrice: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      currency: PropTypes.string
    })
  })
};

export default ProductSuggestionPrices;
export { ProductSuggestionPrices };
