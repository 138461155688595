/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'MyAccount.Lists.title',
    defaultMessage: 'Your Lists'
  },
  addButton: {
    id: 'MyAccount.Lists.add.label',
    defaultMessage: 'Add List'
  },
  noLists: {
    id: 'MyAccount.Lists.noLists.label',
    defaultMessage: 'You have not created any lists yet.'
  },
  edit: {
    id: 'MyAccount.Lists.noLists.edit',
    defaultMessage: 'View'
  },
  delete: {
    id: 'MyAccount.Lists.noLists.delete',
    defaultMessage: 'Delete'
  },
  name: {
    id: 'MyAccount.Lists.columns.name',
    defaultMessage: 'Name'
  },
  updatedDate: {
    id: 'MyAccount.Lists.columns.updatedDate',
    defaultMessage: 'Last Modified'
  },
  selectAll: {
    id: 'MyAccount.Lists.selectAll',
    defaultMessage: 'Select All'
  },
  deleteAll: {
    id: 'MyAccount.Lists.deleteAll',
    defaultMessage: 'Delete'
  },
  addAllToCart: {
    id: 'MyAccount.Lists.addAllToCart',
    defaultMessage: 'Add to Cart'
  },
  searchLabel: {
    id: 'MyAccount.Lists.search.label',
    defaultMessage: 'Search Lists'
  },
  searchPlaceholder: {
    id: 'MyAccount.Lists.search.placeholder',
    defaultMessage: 'My List'
  },
  searchSubmit: {
    id: 'MyAccount.Lists.search.submit',
    defaultMessage: 'Search'
  },
  listActions: {
    id: 'MyAccount.Lists.actions.mobile.dropdown.trigger.label',
    defaultMessage: 'Actions'
  }
});
