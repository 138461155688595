/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import cx from 'classnames';

import useDropdown from '../hooks/useDropdown';

/**
 * The drop down menu item.
 */
const DropdownMenuItem = ({
  activeClassName = 'text-white bg-primary-500',
  inactiveClassName = 'bg-white hover:bg-gray-200',
  children,
  className,
  isActive,
  onClick = noop,
  padding = 'px-4 py-2',
  ...itemProps
}) => {
  const { toggleOpen } = useDropdown();

  return (
    <li
      className={cx('text-sm whitespace-no-wrap cursor-pointer', {
        [className]: !!className,
        [padding]: !!padding,
        [activeClassName]: isActive,
        [inactiveClassName]: !isActive
      })}
      onClick={e => {
        toggleOpen(false);
        onClick(e);
      }}
      {...itemProps}
    >
      {children}
    </li>
  );
};

DropdownMenuItem.propTypes = {
  /** The class name for the trigger wrapper */
  className: PropTypes.string
};

export default DropdownMenuItem;
