/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  required: {
    id: 'Pdp.ProductOptions.InputAttribute.required',
    defaultMessage: 'Required'
  },
  invalidDecimal: {
    id: 'Pdp.ProductOptions.InputAttribute.invalid.decimal',
    defaultMessage: 'Must be a valid number'
  },
  invalidInteger: {
    id: 'Pdp.ProductOptions.InputAttribute.invalid.integer',
    defaultMessage: 'Must be a valid integer (1, 2, 3, etc.)'
  },
  invalidDate: {
    id: 'Pdp.ProductOptions.InputAttribute.invalid.date',
    defaultMessage: 'Must be a valid date (mm/dd/yyyy)'
  }
});
