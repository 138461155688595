import React from 'react';
import { get, isEmpty } from 'lodash';

import { useFormatMessage } from 'app/common/hooks';

import messages from './DealerHours.messages';

const DealerHours = ({ businessHours }) => {
  const serviceHours = get(
    businessHours,
    'SERVICE',
    get(businessHours, 'PARTS', {})
  );
  const formatMessage = useFormatMessage();

  return (
    <div className="flex mb-5 mr-2 w-full flex-col">
      <h4 className="mt-3 mb-3 underline text-gray-700 font-semibold text-center text-lg">
        {formatMessage(messages.title)}
      </h4>
      <Day day={'monday'} serviceHours={serviceHours} />
      <Day day={'tuesday'} serviceHours={serviceHours} />
      <Day day={'wednesday'} serviceHours={serviceHours} />
      <Day day={'thursday'} serviceHours={serviceHours} />
      <Day day={'friday'} serviceHours={serviceHours} />
      <Day day={'saturday'} serviceHours={serviceHours} />
      <Day day={'sunday'} serviceHours={serviceHours} />
    </div>
  );
};

/**
 * Format time from 24 hour to 12 hour. Example: 13:00 -> 1:00 PM
 * @param timeString - 24 hour time string. May be null or undefined.
 * @return {string} - 12 hour time string. Example: 13:00 -> 1:00 PM
 */
function formatTime(timeString) {
  if (isEmpty(timeString) || !timeString.includes(':')) {
    return undefined;
  }
  const time = timeString.split(':');
  const hour = parseInt(time[0]);
  const minute = time[1];
  const isPM = hour >= 12;
  if (!isPM) {
    if (hour === 0) {
      return '12:' + minute + ' AM';
    }
    return hour + ':' + minute + ' AM';
  }
  const formattedHour = isPM ? hour - 12 : hour;
  if (formattedHour < 10) {
    return '0' + formattedHour + ':' + minute + ' PM';
  }
  return formattedHour + ':' + minute + ' PM';
}

const Day = ({ day, serviceHours }) => {
  const formatMessage = useFormatMessage();
  const hours = get(serviceHours, day, {});
  const open = formatTime(get(hours, 'open'));
  const close = formatTime(get(hours, 'close'));
  const isEmptyHours = isEmpty(open) && isEmpty(close);
  const displayedTime = !isEmptyHours ? open + ' - ' + close : 'Closed';
  return (
    <div className="flex flex-row ml-5">
      <div className="mb-1 w-2/5">{formatMessage(messages.day, { day })}</div>
      <div className={'mb-1 w-1/12 xs:flex lg:hidden xl:flex'}></div>
      <div className="mb-1 w-3/5">{displayedTime}</div>
    </div>
  );
};

export default DealerHours;
export { DealerHours };
