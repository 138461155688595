/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty, values } from 'lodash';
import PropTypes from 'prop-types';

import { AssetType } from 'app/common/constants';
import log from 'app/common/utils/Log';

const logger = log.getLogger('personalization.AssetWidget');

const AssetWidget = ({ asset, className, innerProps, fallbackSrc }) => {
  const { altText, contentUrl, title } = asset;
  const [src, setSrc] = React.useState(contentUrl);
  const [error, setError] = React.useState(false);

  return (
    <div className={className}>
      <img
        {...innerProps}
        alt={altText}
        src={src}
        title={title}
        onError={() => {
          if (!error && !isEmpty(fallbackSrc)) {
            logger.error(
              `Could not resolve source ${src}—falling back to ${fallbackSrc}`
            );
            setSrc(fallbackSrc);
            setError(true);
          }
        }}
      />
    </div>
  );
};

AssetWidget.propTypes = {
  asset: PropTypes.shape({
    altText: PropTypes.string,
    url: PropTypes.string,
    contentUrl: PropTypes.string.isRequired,
    type: PropTypes.oneOf(values(AssetType))
  }),
  className: PropTypes.string,
  fallbackSrc: PropTypes.string,
  innerProps: PropTypes.object
};

export default AssetWidget;
export { AssetWidget };
