/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isNil } from 'lodash';

import { AuthContext } from 'app/auth/contexts';
import { CartContext } from 'app/common/contexts';
import { logError } from 'app/common/utils/ApiErrorUtils';
import { PrimaryButton } from 'app/common/components';
import { useCartInfo, useRestApi } from 'app/common/hooks';
import { useFormatMessage } from 'app/common/hooks';
import { useRefreshEffect } from 'app/common/hooks';

import messages from '../../CSRRibbon.messages';

const CloneCustomerCart = props => {
  const formatMessage = useFormatMessage();
  const { user } = React.useContext(AuthContext);
  const customerId = get(user, 'serviceId');
  const { cart: csrCart, setCart } = React.useContext(CartContext);

  const { baseUrl, customerInProcessCart, cloneCartFromCustomerContextPath } =
    useCartInfo().operations;

  const customerInProcessCartUrl = `${baseUrl}${customerInProcessCart}`;

  const getCartConfig = React.useMemo(
    () => ({ scope: 'CSR', params: { customerId } }),
    [customerId]
  );
  const {
    error: getCustomerInProcessCartError,
    exception: customerInProcessCartException,
    response: inProcessCustomerCart,
    sendCallback: getInProcessCustomerCart
  } = useRestApi(customerInProcessCartUrl, getCartConfig);
  // after transferring the cart to the customer, we need to show the button for cloning the customer cart
  useRefreshEffect(
    getInProcessCustomerCart,
    csrCart,
    (cart, prevCart) =>
      isNil(cart) && !isNil(prevCart) && prevCart.status === 'CSR_OWNED'
  );

  if (getCustomerInProcessCartError) {
    logError({
      ...customerInProcessCartException,
      when: 'getting the customer cart with status IN_PROCESS'
    });
  }

  const cloneCustomerCartUrl = `${baseUrl}${cloneCartFromCustomerContextPath}`;
  const cloneConfig = React.useMemo(
    () => ({ method: 'put', scope: 'CSR' }),
    []
  );
  const {
    error: cloneError,
    exception: cloneException,
    sendCallback: cloneCustomerCart
  } = useRestApi(cloneCustomerCartUrl, cloneConfig, false);

  React.useEffect(() => {
    if (cloneError) {
      logError({
        ...cloneException,
        when: 'cloning customer cart'
      });
    }
  }, [cloneError, cloneException]);

  if (!inProcessCustomerCart) {
    return null;
  }

  return (
    <PrimaryButton
      className={props.className}
      size={PrimaryButton.Size.SMALL}
      onClick={() => {
        cloneCustomerCart({
          data: csrCart
        }).then(newCart => {
          setCart(newCart);
        });
      }}
    >
      {formatMessage(messages.cloneCustomerCart)}
    </PrimaryButton>
  );
};

export default CloneCustomerCart;
