/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import {
  Button,
  FleetFilter,
  Icon,
  TopBrandsFilter
} from 'app/common/components';
import { useFormatMessage, useLocation } from 'app/common/hooks';

import { ResultsLayoutContext } from '../../contexts';
import messages from './ResultsHeader.messages';
import { useBrandsInfo } from 'app/brands/components/Brands/hooks';

/**
 * Render component for the header of browse and search results views.
 *
 * @visibleName Browse and Search Results Header
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const ResultsHeader = ({ searchResponse, title }) => {
  const formatMessage = useFormatMessage();
  const { useGrid, setUseGrid } = React.useContext(ResultsLayoutContext);
  const { pathname } = useLocation();
  const { brandsRootPath } = useBrandsInfo();
  const isBrandsPath = pathname.startsWith(brandsRootPath);

  return (
    <>
      <section className="flex flex-col items-start pb-2 border-b border-gray-400 sm:flex-row sm:justify-between sm:items-end">
        <h1
          id="results-title"
          className="block mb-1 mr-2 text-gray-700 text-xl font-medium leading-tight text-left sm:mb-0 sm:text-2xl md:text-3xl lg:text-4xl"
        >
          {title}
        </h1>
        {searchResponse !== undefined && (
          <PaginationTotals
            searchResponse={searchResponse}
            formatMessage={formatMessage}
          />
        )}
      </section>
      <aside className="flex items-center justify-between">
        <div>
          <FleetFilter />
          {!isBrandsPath && <TopBrandsFilter />}
        </div>
        <div className="flex items-center justify-end">
          <Button
            className={classNames(
              'hidden items-center mr-1 p-2 appearance-none focus:outline-none sm:flex',
              {
                'text-gray-600 hover:text-gray-700 focus:text-gray-700':
                  useGrid,
                'text-primary-500 focus:text-primary-600': !useGrid
              }
            )}
            onClick={() => {
              if (useGrid) {
                setUseGrid(false);
              }
            }}
            title={formatMessage(messages.useList)}
          >
            <Icon name="th-list" />
          </Button>
          <Button
            className={classNames(
              'hidden items-center p-2 appearance-none focus:outline-none sm:flex',
              {
                'text-gray-600 hover:text-gray-700 focus:text-gray-700':
                  !useGrid,
                'text-primary-500 focus:text-primary-600': useGrid
              }
            )}
            onClick={() => {
              if (!useGrid) {
                setUseGrid(true);
              }
            }}
            title={formatMessage(messages.useGrid)}
          >
            <Icon name="th" />
          </Button>
        </div>
      </aside>
    </>
  );
};

const PaginationTotals = ({ searchResponse }) => {
  const formatMessage = useFormatMessage();
  const {
    empty = false,
    numberOfElements = 0,
    pageable,
    totalElements = 0
  } = searchResponse;
  const offset = get(pageable, 'offset', 0);

  if (empty) {
    return null;
  }

  return (
    <div className="text-sm text-gray-700 sm:text-base">
      {formatMessage(messages.resultsTotals, {
        start: offset + 1,
        end: offset + numberOfElements,
        total: totalElements
      })}
    </div>
  );
};

ResultsHeader.Totals = PaginationTotals;

ResultsHeader.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      /** not required for last breadcrumb in list */
      uri: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      label: PropTypes.string.isRequired
    })
  ),
  searchResponse: PropTypes.shape({
    /** Number of results on the current page */
    numberOfElements: PropTypes.number,
    /** Additional pagination information */
    pageable: PropTypes.shape({
      /**
       * A number by which the starting index of the result set is adjusted. 10
       * would indicate that the first result in the set is actually the 11th
       * element. Thus, we would display (11 - 25 of 30) instead of
       * (1 - 15 of 30).
       */
      offset: PropTypes.number
    }),
    /** Whether to display the summary of the total results: (1 - 15 of 30) */
    empty: PropTypes.bool,
    /** The total number of results on all pages */
    totalElements: PropTypes.number
  }),
  /** The title for the results such as a category name */
  title: PropTypes.string.isRequired
};

export default ResultsHeader;
export { ResultsHeader };
