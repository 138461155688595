/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

import { useFormatMessage } from 'app/common/hooks';

import messages from './OrderReviewItems.messages';
import OrderReviewItem from '../OrderReviewItem';

const OrderReviewItems = ({ orderItems, status }) => {
  const formatMessage = useFormatMessage();

  if (status === undefined || StatusLabel[status] === undefined) {
    return null;
  }

  return (
    <section className="relative flex flex-col pb-8 border-b-2 border-solid border-gray-200 rounded-b last:border-b-0">
      <header className="mb-4 pt-4 lg:pt-6">
        <h4 className="flex items-center capitalize font-medium text-sm lg:text-base">
          {formatMessage(StatusLabel[status])}
        </h4>
      </header>
      <section className="w-full py-2 px-4 bg-white border border-solid border-gray-200 rounded">
        <ul className="flex flex-col w-full list-none">
          {orderItems.map((orderItem, i) => (
            <OrderReviewItem key={orderItem.id} {...orderItem} position={i} />
          ))}
        </ul>
      </section>
    </section>
  );
};

OrderReviewItems.propTypes = {
  orderItems: PropTypes.arrayOf(PropTypes.object).isRequired
};

const StatusLabel = Object.freeze({
  NEW: messages.new,
  CANCELLED: messages.cancelled,
  FULFILLED: messages.fulfilled,
  RETURN_PENDING: messages.returnPending,
  RETURN_CANCELLED: messages.returnCancelled,
  RETURN_CONFIRMED: messages.returnConfirmed
});

export default OrderReviewItems;
export { OrderReviewItems };
