/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

/**
 * Defines the locations where a marketing message can appear.
 *
 * @type {Readonly<{PRODUCT_DETAIL: string,
 * BROWSE: string,
 * BROWSE_ITEM: string,
 * CART: string,
 * CART_ITEM: string,
 * ADD_TO_CART_RESPONSE: string,
 * SEARCH: string,
 * EVERYWHERE: string, }>}
 */
const MarketingMessageLocationType = Object.freeze({
  PRODUCT_DETAIL: 'PRODUCT_DETAIL',
  BROWSE: 'BROWSE',
  BROWSE_ITEM: 'BROWSE_ITEM',
  CART: 'CART',
  CART_ITEM: 'CART_ITEM',
  ADD_TO_CART_RESPONSE: 'ADD_TO_CART_RESPONSE',
  SEARCH: 'SEARCH',
  EVERYWHERE: 'EVERYWHERE'
});

export default MarketingMessageLocationType;
export { MarketingMessageLocationType };
