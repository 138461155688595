/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  addAddress: {
    id: 'Addresses.addAddress',
    defaultMessage: 'Add Address'
  },
  addAddressButton: {
    id: 'Addresses.addAddressButton',
    defaultMessage: 'Add New Address'
  },
  cancelAddButton: {
    id: 'Addresses.cancelAdd',
    defaultMessage: 'Cancel'
  },
  title: {
    id: 'Addresses.title',
    defaultMessage: 'Addresses'
  },
  updateAddress: {
    id: 'Addresses.updateAddress',
    defaultMessage: 'Save Address'
  },
  newAddressDefaultName: {
    id: 'Addresses.newAddress.defaultName',
    defaultMessage: 'New Address'
  },
  empty: {
    id: 'Addresses.empty',
    defaultMessage: 'No Addresses Found'
  }
});
