/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { get, isEmpty, values } from 'lodash';
import PropTypes from 'prop-types';

import { Icon } from 'app/common/components';
import { ItemChoiceTargetType } from 'app/common/constants';
import { ConfigurableItemContext } from 'app/product/contexts';
import { ConfigurableItem } from 'app/product/helpers';
import { itemChoice as itemChoiceAction } from 'app/product/helpers/ConfigurableItem';

import { useChoiceAsset, useDeterminePricing } from '../../hooks';
import {
  calculatePriceIncrease,
  getChoices,
  getDefaultChoice,
  getId,
  getInitialQuantity,
  getSku,
  isProduct as checkIsProduct
} from '../../utils/ChoiceUtils';
import { ChoiceAsset, ChoiceOptions, ChoicePrice, QuantitySelect } from '../';
import { ChoiceSelect } from './components';

const ChooseOne = ({ choice, setChoice, dispatchChoice, itemChoice }) => {
  const {
    activeOption,
    variantAttributeChoices,
    cartItemAttributeChoices,
    requiresConfiguration
  } = React.useContext(ConfigurableItemContext);
  const {
    choiceKey,
    maximumQuantity: maxQty,
    minimumQuantity: minQty = 0
  } = itemChoice;
  const choices = getChoices(itemChoice);
  const [quantity, setQuantity] = React.useState(
    getInitialQuantity(minQty, choice)
  );
  const isProduct = checkIsProduct(itemChoice.targetType);
  const pricing = useDeterminePricing(choice, itemChoice);
  const asset = useChoiceAsset(choice, isProduct);
  const handleSelectChoice = React.useCallback(
    (newChoice, newQuantity = quantity) => {
      setChoice(newChoice);

      if (requiresConfiguration) {
        return;
      }

      if (isEmpty(activeOption)) {
        dispatchChoice(
          itemChoiceAction({
            choiceKey,
            id: getId(newChoice),
            isProduct,
            itemAttributeChoices: cartItemAttributeChoices || {},
            priceIncrease: calculatePriceIncrease(pricing, newQuantity),
            productId: isProduct ? undefined : newChoice.productId,
            quantity: newQuantity,
            sku: getSku(newChoice)
          })
        );

        return;
      }

      dispatchChoice(
        itemChoiceAction({
          choiceKey,
          id: activeOption.id,
          isProduct: false,
          itemAttributeChoices: {
            ...(variantAttributeChoices || {}),
            ...(cartItemAttributeChoices || {})
          },
          priceIncrease: calculatePriceIncrease(pricing, newQuantity),
          productId: activeOption.productId,
          quantity: newQuantity,
          sku: activeOption.sku
        })
      );
    },
    [
      activeOption,
      variantAttributeChoices,
      cartItemAttributeChoices,
      choiceKey,
      dispatchChoice,
      isProduct,
      pricing,
      quantity,
      requiresConfiguration,
      setChoice
    ]
  );
  const handleSelectQuantity = React.useCallback(
    newQuantity => {
      setQuantity(newQuantity);
      handleSelectChoice(choice, newQuantity);
    },
    [choice, handleSelectChoice]
  );

  React.useEffect(() => {
    if (maxQty === minQty || (minQty > 0 && quantity >= minQty)) {
      handleSelectChoice(choice);
    }
  }, [choice, handleSelectChoice, maxQty, minQty, quantity]);

  return (
    <div className="flex flex-col w-full mb-4">
      <div className="flex flex-col items-center justify-between w-full sm:flex-row">
        <div
          className={classNames('flex items-center w-full sm:w-3/5 sm:mb-0', {
            'mb-2': !isEmpty(asset),
            'mb-4': isEmpty(asset)
          })}
        >
          {!isEmpty(asset) && <ChoiceAsset asset={asset} />}
          <ChoiceSelect
            activeChoice={choice}
            choices={choices}
            handleSelectChoice={handleSelectChoice}
          />
        </div>
        <div className="flex items-center justify-end w-full sm:w-2/5">
          <ChoicePrice pricing={pricing} />
          <Icon className="mx-4 lg:mx-2 xl:mx-4" name="times" />
          {maxQty === minQty ? (
            <div>{minQty}</div>
          ) : (
            <QuantitySelect
              handleChange={handleSelectQuantity}
              initialQuantity={quantity}
              maximumQuantity={maxQty}
              minimumQuantity={minQty}
            />
          )}
        </div>
      </div>
      {isProduct && (
        <ChoiceOptions
          options={
            isEmpty(choice.product) ? choice.options : choice.product.options
          }
        />
      )}
    </div>
  );
};

ChooseOne.propTypes = {
  /** A _SpecificItemChoice_ or _Product_ */
  choice: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    product: PropTypes.object,
    sku: PropTypes.string,
    variant: PropTypes.object
  }).isRequired,
  setChoice: PropTypes.func.isRequired,
  /** Dispatch for item choice selection */
  dispatchChoice: PropTypes.func.isRequired,
  itemChoice: PropTypes.shape({
    choiceKey: PropTypes.string.isRequired,
    maximumQuantity: PropTypes.number,
    minimumQuantity: PropTypes.number,
    targetType: PropTypes.oneOf(values(ItemChoiceTargetType))
  }).isRequired
};

const ChooseOneContainer = ({
  dispatchChoice,
  itemChoice,
  selectedItemChoices
}) => {
  const { choiceKey } = itemChoice;
  const [choice, setChoice] = React.useState(
    getDefaultChoice(itemChoice, get(selectedItemChoices, choiceKey))
  );

  return (
    <ConfigurableItem item={isEmpty(choice.product) ? choice : choice.product}>
      <ChooseOne
        choice={choice}
        setChoice={setChoice}
        dispatchChoice={dispatchChoice}
        itemChoice={itemChoice}
      />
    </ConfigurableItem>
  );
};

ChooseOneContainer.propTypes = {
  /** Dispatch for item choice selection */
  dispatchChoice: PropTypes.func.isRequired,
  itemChoice: PropTypes.shape({
    choiceKey: PropTypes.string.isRequired,
    maximumQuantity: PropTypes.number,
    minimumQuantity: PropTypes.number,
    targetType: PropTypes.oneOf(values(ItemChoiceTargetType))
  }).isRequired,
  selectedItemChoices: PropTypes.object
};

export default ChooseOneContainer;
export { ChooseOne };
