/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  heading: {
    id: 'OrderTracking.OrderReview.Items.itemsSummary.heading',
    defaultMessage: 'Items Summary'
  },
  new: {
    id: 'OrderTracking.OrderReview.Items.itemsPending',
    defaultMessage: 'Items Pending'
  },
  fulfilled: {
    id: 'OrderTracking.OrderReview.Items.itemsFulfilled',
    defaultMessage: 'Items Fulfilled'
  },
  cancelled: {
    id: 'OrderTracking.OrderReview.Items.itemsCancelled',
    defaultMessage: 'Items Cancelled'
  },
  returnPending: {
    id: 'OrderTracking.OrderReview.Items.itemsPendingReturn',
    defaultMessage: 'Items Pending Return'
  },
  returnCancelled: {
    id: 'OrderTracking.OrderReview.Items.cancelledReturns',
    defaultMessage: 'Cancelled Returns'
  },
  returnConfirmed: {
    id: 'OrderTracking.OrderReview.Items.itemsReturned',
    defaultMessage: 'Items Returned'
  }
});
