/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { get, isString, map, toLower } from 'lodash';
import PropTypes from 'prop-types';

import { useFormatMessage, useLocation } from 'app/common/hooks';
import { getBestBreadcrumbs } from 'app/common/utils/BreadcrumbUtils';

import Link from '../Link';
import messages from './Breadcrumbs.messages';

/**
 * @todo: https://github.com/BroadleafCommerce/MicroPM/issues/978
 */
const Breadcrumbs = ({
  breadcrumbs = [],
  className,
  includeRoot = true,
  productView = false,
  hasPrimaryCategory
}) => {
  const location = useLocation();
  const [crumbs, setCrumbs] = React.useState([...breadcrumbs]);

  React.useEffect(() => {
    const breadcrumbsFromLocationState = get(location.state, 'breadcrumbs', []);
    const tempCrumbs = getBestBreadcrumbs(
      breadcrumbsFromLocationState,
      breadcrumbs,
      hasPrimaryCategory
    );

    if (includeRoot && tempCrumbs[0].uri !== '/') {
      tempCrumbs.unshift(Breadcrumbs.RootBreadcrumb);
    }
    if (productView) {
      // Add preserveCase to last element of breadcrumbs
      tempCrumbs[tempCrumbs.length - 1].preserveCase = true;
    }
    setCrumbs(tempCrumbs);
  }, [
    breadcrumbs,
    includeRoot,
    location.state,
    productView,
    hasPrimaryCategory
  ]);

  return (
    <nav
      className={classNames('w-full mb-4 sm:mb-8', {
        [className]: !!className
      })}
      aria-label="breadcrumbs"
    >
      <ol className="flex w-full text-xs md:text-sm lg:text-base">
        {map(
          crumbs,
          (
            { label, uri, searchQuery = '', preserveCase = false },
            index,
            list
          ) => {
            return (
              <Crumb
                index={index}
                label={label}
                uri={uri}
                searchQuery={searchQuery}
                preserveCase={preserveCase}
                list={list}
                key={index}
              />
            );
          }
        )}
      </ol>
    </nav>
  );
};

const Crumb = props => {
  const { label, uri, searchQuery, preserveCase, index, list } = props;
  const isLink = index !== list.length - 1;
  const liClasses = isLink
    ? 'overflow-hidden whitespace-no-wrap'
    : 'flex-1 overflow-hidden text-left text-gray-700 whitespace-no-wrap';
  return (
    <li key={index} className={liClasses} style={{ textOverflow: 'ellipsis' }}>
      {index !== 0 && <Breadcrumbs.Separator />}
      <CrumbLabel
        label={label}
        preserveCase={preserveCase}
        uri={uri}
        searchQuery={searchQuery}
        isLink={isLink}
      />
    </li>
  );
};

const CrumbLabel = ({ label, preserveCase, uri, searchQuery = '', isLink }) => {
  const formatMessage = useFormatMessage();
  const labelClasses = preserveCase ? '' : 'capitalize';
  const toLink = {
    pathname: uri,
    search: searchQuery
  };

  let crumbLabel = '';
  if (isString(label)) {
    crumbLabel = preserveCase ? label : toLower(label);
  } else {
    crumbLabel = formatMessage(label);
  }

  if (isLink) {
    return (
      <Link className={labelClasses} to={toLink}>
        {crumbLabel}
      </Link>
    );
  } else {
    return <span className={labelClasses}>{crumbLabel}</span>;
  }
};

Breadcrumbs.Separator = props => (
  <span {...props} className="inline-block mx-1 md:mx-2 lg:mx-3">
    {'/'}
  </span>
);

Breadcrumbs.RootBreadcrumb = {
  uri: '/',
  label: messages.rootLabel
};

Breadcrumbs.PartsSearchBreadcrumb = {
  uri: '/part-search',
  label: messages.partsSearchBreadcrumb
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      /** not required for last breadcrumb in list */
      uri: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          defaultMessage: PropTypes.string.isRequired
        })
      ]).isRequired,
      preserveCase: PropTypes.bool
    })
  )
};

export default Breadcrumbs;
export { Breadcrumbs };
