/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { find, isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';

import { Icon } from 'app/common/components';

import { getId, getName, getSku } from '../../../../utils/ChoiceUtils';

/**
 * @todo this should render the product options for the active choice
 *   we'll have to go through and figure out the valid permutations
 *   we'll have to not call handleSelectChoice on change until we've configured
 *       any product options
 */
const ChoiceSelect = ({ activeChoice, choices, handleSelectChoice }) => {
  const [value, setValue] = React.useState(
    getId(activeChoice) || getSku(activeChoice)
  );

  React.useEffect(() => {
    setValue(getId(activeChoice) || getSku(activeChoice));
  }, [activeChoice]);

  return (
    <div className="relative flex-grow w-2/3 md:mr-4 lg:mr-2 xl:mr-4">
      <select
        className={classNames(
          'block',
          'w-full p-1',
          'text-sm text-gray-900 leading-tight',
          'appearance-none bg-white border border-solid border-gray-400 rounded',
          'hover:cursor-pointer focus:shadow-outline focus:outline-none disabled:bg-gray-200 disabled:border-gray-300 disabled:cursor-not-allowed',
          'lg:text-base'
        )}
        onChange={e => {
          const val = e.target.value;
          const newChoice = find(choices, choice => {
            if (!isEmpty(choice.variant)) {
              return choice.variant.id === val || choice.variant.sku === val;
            }

            if (!isEmpty(choice.product)) {
              return choice.product.id === val || choice.product.sku === val;
            }

            return choice.id === val || choice.sku === val;
          });

          setValue(val);
          handleSelectChoice(newChoice);
        }}
        value={value}
      >
        {map(choices, (choice, i) => (
          <option key={i} value={getId(choice) || getSku(choice)}>
            {getName(choice)}
          </option>
        ))}
      </select>
      <div className="absolute flex items-center justify-center inset-y-0 right-0 w-6 pt-px pl-px bg-white border border-l-0 border-solid border-gray-400 rounded-r pointer-events-none">
        <Icon name="angle-down" size="sm" />
      </div>
    </div>
  );
};

ChoiceSelect.propTypes = {
  /**
   * Function to call when a selection is made. This doesn't necessarily happen
   * at the same time as `onChange` because the item may need configuration.
   */
  handleSelectChoice: PropTypes.func.isRequired
};

export default ChoiceSelect;
export { ChoiceSelect };
