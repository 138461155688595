/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useFormatMessage } from 'app/common/hooks';
import { ErrorTrace } from 'app/core/components';

import messages from './RouteStatus.messages';

/**
 * Render component for displaying route statuses when the route cannot be
 * resolved.
 *
 * @visibleName Route Status
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const RouteStatus = ({
  children,
  className,
  headerColor = 'bg-gray-200',
  headerTextColor = 'text-gray-800',
  isLoading = false,
  subtitle,
  title
}) => (
  <main
    className={classNames('RouteStatus', {
      [className]: !!className
    })}
  >
    <header
      className={classNames('flex flex-col items-stretch justify-between', {
        [headerColor]: !!headerColor,
        [headerTextColor]: !!headerTextColor
      })}
    >
      <div className="flex-grow flex-shrink-0 py-12 px-6">
        <div className="container">
          <h1
            className={classNames('relative text-4xl font-medium', {
              'has-animated-ellipsis': isLoading
            })}
          >
            {title}
          </h1>
          {!!subtitle && <h2 className="text-2xl font">{subtitle}</h2>}
        </div>
      </div>
    </header>
    {children}
  </main>
);

RouteStatus.propTypes = {
  /** Children components to display as the body of the status */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func
  ]),
  /** Additional class names to add to the component */
  className: PropTypes.string,
  /** Color to add as the background of the header */
  headerColor: PropTypes.string,
  /** Subtitle component or text */
  subtitle: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func
  ]),
  /** Title comopnent or text */
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func
  ]).isRequired
};

const RouteNotFound = ({ message, subtitle, title }) => {
  const formatMessage = useFormatMessage();
  return (
    <RouteStatus
      headerColor="bg-yellow-400"
      subtitle={subtitle || formatMessage(messages.notFoundSubtitle) || ''}
      title={title || formatMessage(messages.notFoundTitle) || ''}
    >
      <section className="py-12 px-6">
        <div className="container">
          <h3 className="text-lg">
            {message || formatMessage(messages.notFoundBody)}
          </h3>
        </div>
      </section>
    </RouteStatus>
  );
};

RouteStatus.NotFound = RouteNotFound;

const RouteError = ({ error }) => {
  const formatMessage = useFormatMessage();
  return (
    <RouteStatus
      headerColor="bg-red-600"
      headerTextColor="text-gray-100"
      subtitle={formatMessage(messages.errorSubtitle)}
      title={formatMessage(messages.errorTitle)}
    >
      <ErrorTrace error={error} />
    </RouteStatus>
  );
};

RouteStatus.Error = RouteError;

export default RouteStatus;
export { RouteStatus };
