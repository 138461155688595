/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  empty: {
    id: 'Cart.empty',
    defaultMessage: 'Your cart is currently empty'
  },
  continueShopping: {
    id: 'Cart.continueShopping',
    defaultMessage: 'Continue Shopping'
  },
  cartTitle: {
    id: 'Cart.title',
    defaultMessage: 'Shopping Cart'
  },
  orderInstructionsLegend: {
    id: 'Cart.orderInstructions.legend',
    defaultMessage: 'Order Instructions'
  },
  orderInstructionsHintQuestion: {
    id: 'Cart.orderInstructions.hint.question',
    defaultMessage: 'Can’t find a part?',
    description:
      'This will combined with "Cart.orderInstructions.hint" as the preamble'
  },
  orderInstructionsHint: {
    id: 'Cart.orderInstructions.hint',
    defaultMessage:
      '{preamble} Inquire about additional parts and specify pickup/delivery needs in the instruction box above.'
  },
  orderInstructionsRequirementsHint: {
    id: 'Cart.orderInstructions.hint.requirements',
    defaultMessage: 'Must be under 1,000 characters'
  },
  orderInstructionsUpdate: {
    id: 'Cart.orderInstructions.update',
    defaultMessage: 'Save Instructions'
  },
  orderInstructionsLengthInvalid: {
    id: 'Cart.orderInstructions.errors.length',
    defaultMessage: 'Must be under 1000 characters'
  },
  orderInstructionsUnsavedWarningTitle: {
    id: 'Cart.orderInstructions.unsavedWarning.title',
    defaultMessage: 'Before you go...'
  },
  orderInstructionsUnsavedWarning: {
    id: 'Cart.orderInstructions.unsavedWarning',
    defaultMessage: `You have not saved your order instructions yet. If you navigate to another page, your instructions will be lost.
      
      Are you sure you want to leave?`
  },
  orderInstructionsUnsavedWarningCancel: {
    id: 'Cart.orderInstructions.unsavedWarning.cancel',
    defaultMessage: 'No'
  },
  orderInstructionsUnsavedWarningConfirm: {
    id: 'Cart.orderInstructions.unsavedWarning.confirm',
    defaultMessage: 'Yes'
  },
  orderInstructionsSavedMessage: {
    id: 'Cart.orderInstructions.saved.confirm',
    defaultMessage: 'Success!'
  }
});
