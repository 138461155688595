/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get } from 'lodash';

import { Icon, Link, TertiaryButton } from 'app/common/components';
import { CartContext } from 'app/common/contexts';
import { useFormatMessage } from 'app/common/hooks';

import DialogActions from '../DialogActions';
import DialogItem from '../DialogItem';
import DialogSummary from '../DialogSummary';
import messages from './DialogContent.messages';

/**
 * Render component for the mini shopping cart dialog content.
 *
 * @visibleName Minicart: Dialog: Content
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const DialogContent = () => {
  const formatMessage = useFormatMessage();
  const { cart, toggleMinicartOpen } = React.useContext(CartContext);
  const cartItems = get(cart, 'cartItems');
  const cartPricing = get(cart, 'cartPricing', {});
  const quantity = get(cart, 'quantity', 0);

  const countNotShown = cartItems ? cartItems.length - 3 : 0;

  if (quantity === 0) {
    return (
      <div className="MinicartDialogContent is-empty px-4 py-2">
        {formatMessage(messages.emptyMessage)}
      </div>
    );
  }

  return (
    <div className="flex flex-col items-end h-full">
      <TertiaryButton
        className="px-4 pt-2"
        onClick={() => {
          toggleMinicartOpen(false);
        }}
        title={formatMessage(messages.closeMinicart)}
      >
        <Icon name="times" style={{ flex: '0 1 5%' }} />
      </TertiaryButton>
      <ul className="px-4 py-2">
        {cartItems
          .filter((cartItem, i) => i < 3)
          .map((cartItem, i) => (
            <DialogItem key={cartItem.id} cartItem={cartItem} position={i} />
          ))}
        {countNotShown > 0 && (
          <li className="my-2 px-2 py-2 bg-primary-100 text-primary-700 text-sm text-center rounded cursor-pointer hover:bg-primary-200">
            <Link to="/cart" onClick={() => toggleMinicartOpen(false)}>
              {countNotShown === 1 &&
                formatMessage(messages.moreItemsInCartSingular)}
              {countNotShown > 1 &&
                formatMessage(messages.moreItemsInCartPlural, {
                  count: countNotShown
                })}
            </Link>
          </li>
        )}
      </ul>
      <DialogSummary {...cartPricing} quantity={quantity} />
      <DialogActions />
    </div>
  );
};

export default DialogContent;
export { DialogContent };
