import { useProductPricingApi } from 'app/common/hooks';
import { get, isEmpty } from 'lodash';
import { determineProductOrVariantPricing } from 'app/product/utils/PricingUtils';

function usePriceProducts(products) {
  const prices = new Map();

  const priceInfoResponse = useProductPricingApi(products);

  if (!isEmpty(priceInfoResponse)) {
    for (let product of products) {
      const priceInfoContainer = get(priceInfoResponse, product.id);
      if (priceInfoContainer) {
        const priceInfo = priceInfoContainer.priceInfo;
        const productPricing = determineProductOrVariantPricing(
          product,
          priceInfo
        );
        prices.set(product.id, productPricing || {});
      }
    }
  }

  return prices;
}

export default usePriceProducts;
