/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useEffect } from 'react';

/**
 * Hook to use to cause the window to scroll based on the options. This will
 * re-run when `inputs` change.
 *
 * @param {Array} inputs - inputs to use to trigger the effect
 */
function useScrollToTop(inputs) {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [...inputs]); // eslint-disable-line
}

export default useScrollToTop;
