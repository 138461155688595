/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { Formik } from 'formik';
import { get, isEmpty, mapValues, omitBy, set, toPairs } from 'lodash';
import { Redirect } from 'react-router-dom';

import { AuthContext } from 'app/auth/contexts';
import { UserRegistrationService } from 'app/auth/services';
import {
  determineDefaultCountry,
  determineDefaultStateProvinceRegion
} from 'app/checkout/utils/AddressUtils';
import { Icon } from 'app/common/components';
import { CommunicationPreferenceType } from 'app/common/constants';
import { LocaleContext, TenantContext } from 'app/common/contexts';
import { useFormatMessage, useNationalSiteContext } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';
import { normalizePhoneNumber } from 'app/common/utils/AddressUtils';

import { RegisterForm } from './components';
import { RegisterHelmet } from './helpers';
import { useValidationSchema } from './hooks';
import messages from './Register.messages';

const Register = () => {
  const { clientId, isAuthenticated } = React.useContext(AuthContext);
  const { application, tenant } = React.useContext(TenantContext);
  const context = {
    applicationId: get(application, 'id'),
    customerContextId: get(application, 'customerContextId'),
    tenantId: get(tenant, 'id')
  };
  const formatMessage = useFormatMessage();
  const registrationValidationSchema = useValidationSchema();
  const { currentLocale } = React.useContext(LocaleContext);
  const countryDefault = determineDefaultCountry(currentLocale);
  const subdivisionDefault =
    determineDefaultStateProvinceRegion(countryDefault);
  const { isWhiteLabel, applicationThemeSource: applicationSource } =
    useNationalSiteContext();
  const [error, setError] = React.useState(undefined);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className="flex-grow bg-gray-200">
      <RegisterHelmet />
      <main className="container py-6 px-2 md:py-12">
        <section className="mx-auto max-w-sm bg-gray-100 shadow rounded sm:max-w-lg">
          <header className="px-2 py-4 bg-white rounded-t lg:px-4 lg:py-2">
            <h2 className="capitalize font-bold text-2xl text-center">
              {formatMessage(messages.accountCreationPrompt)}
            </h2>
          </header>
          {error === false ? (
            <div className="p-4">
              <div className="flex items-center px-2 py-1 text-primary-600 leading-snug border border-solid border-primary-200 bg-primary-100 rounded md:px-4 md:py-2 lg:leading-normal">
                <Icon className="mr-2 md:mr-4" name="info-circle" />
                <span>{formatMessage(messages.successMessage)}</span>
              </div>
            </div>
          ) : (
            <Formik
              initialValues={{
                clientId,
                email: '',
                fullName: '',
                firstName: '',
                lastName: '',
                companyName: '',
                address: {
                  addressLineOne: '',
                  addressLineTwo: '',
                  addressLineThree: '',
                  city: '',
                  state: subdivisionDefault,
                  postal: '',
                  country: countryDefault,
                  phone: [
                    {
                      phoneNumber: '',
                      phoneType: 'LANDLINE'
                    },
                    {
                      phoneNumber: '',
                      phoneType: 'MOBILE'
                    },
                    {
                      phoneNumber: '',
                      phoneType: 'FAX'
                    }
                  ]
                },
                preferredCommunicationMethod:
                  CommunicationPreferenceType.Email.value
              }}
              onSubmit={(values, formikBag) => {
                if (isEmpty(clientId)) {
                  return;
                }

                return Register.handleSubmit(
                  { values, formikBag },
                  context,
                  setError,
                  isWhiteLabel,
                  applicationSource
                );
              }}
              validationSchema={registrationValidationSchema}
              validateOnChange={false}
              validateOnBlur={true}
              enableReinitialize
            >
              {({ isSubmitting }) => (
                <>
                  {!isEmpty(error) && (
                    <div className="p-4">
                      <div className="flex items-center px-2 py-1 text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2 lg:leading-normal">
                        <Icon
                          className="mr-2 md:mr-4"
                          name="exclamation-circle"
                        />
                        <span>{formatMessage(error)}</span>
                      </div>
                    </div>
                  )}
                  <Register.Form disabled={isSubmitting || !clientId} />
                </>
              )}
            </Formik>
          )}
        </section>
      </main>
    </div>
  );
};

Register.handleSubmit = async (
  { values, formikBag },
  context,
  setError,
  isWhitelabel,
  applicationSource
) => {
  const address = values.address;
  const phone = get(address, 'phone', []);
  const identifierLocation = get(applicationSource, 'identifierValue');

  function determinePreferredLocation() {
    if (isWhitelabel && identifierLocation) {
      if (applicationSource.dealerNetwork) {
        return values.clientId;
      }
      // While clientId seems to be the same value, using the identifier value from the current application is safer
      return identifierLocation;
    } else {
      return null;
    }
  }

  try {
    await UserRegistrationService.register(
      {
        ...omitBy(values, isEmpty),
        address: {
          ...address,
          phone: isEmpty(phone)
            ? []
            : phone
                .map(({ phoneNumber, phoneType }) =>
                  isEmpty(phoneNumber)
                    ? undefined
                    : {
                        phoneNumber: normalizePhoneNumber(phoneNumber),
                        phoneType
                      }
                )
                .filter(p => p !== undefined)
        },
        preferredLocation: determinePreferredLocation()
      },
      context
    );
    setError(false);
    formikBag.setSubmitting(false);
  } catch (error) {
    const fieldErrors = error.response.data.fieldErrors;
    if (!isEmpty(fieldErrors)) {
      const errorPairs = toPairs(
        mapValues(
          error.response.data.fieldErrors,
          errorArray => errorArray[0].reason
        )
      );
      const errors = errorPairs.reduce((acc, [key, value]) => {
        if (key === 'phoneType') {
          if (value.startsWith('First')) {
            key = 'address.phone[0].phoneNumber';
          } else {
            // can't map it
            return acc;
          }
        }
        set(acc, key, value);
        return acc;
      }, {});
      setError(messages.errorMessageValidation);
      formikBag.setErrors(errors);
    } else {
      logError({
        ...error,
        when: 'Submitting registration form',
        loggerName: 'Auth.Register'
      });
      setError(messages.errorMessageGlobal);
    }

    formikBag.setSubmitting(false);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
};
Register.Form = RegisterForm;

export default Register;
export { Register };
