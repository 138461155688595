import {
  buildStoreAddressForDisplay,
  findStoreHoursToday
} from '../../layout/components/Header/components/StoreDropdown/util';
import { Link, PrimaryButton } from 'app/common/components';
import React from 'react';
import { useFormatMessage, useNationalSiteContext } from 'app/common/hooks';
import messages from '../../layout/components/Header/components/StoreDropdown/StoreDropdown.messages';
import { isEmpty } from 'lodash';

/**
 *
 * Renders a single store location row. This component is used to render the list of stores in find store
 * dropdown.
 *
 */
const StoreLocationRow = ({
  location,
  selected,
  currentStoreButtonLabel,
  currentStoreOnClick,
  selectStoreButtonLabel,
  selectStoreOnClick,
  showStoreDetailsLink = false,
  storeDetailsOnClick = () => {}
}) => {
  return (
    <div className={'flex store-dropdown-row w-full pt-4 pb-4'}>
      <div className={'flex flex-row w-full'}>
        <div
          className={
            'flex flex-col sm:flex-row store-dropdown-address-hours w-full sm:w-3/4 text-sm pr-2'
          }
        >
          <StoreAddress location={location} />
          <StoreHoursAndDetails
            location={location}
            showStoreDetailsLink={showStoreDetailsLink}
            storeDetailsOnClick={storeDetailsOnClick}
          />
        </div>
        <div className={'flex w-auto sm:w-1/4 justify-end'}>
          <div
            className={
              'flex flex-col w-auto store-select items-end justify-center pl-2'
            }
          >
            <SelectStoreButton
              {...{
                selected,
                currentStoreButtonLabel,
                currentStoreOnClick,
                selectStoreButtonLabel,
                selectStoreOnClick
              }}
            />
            <StoreDistance location={location} />
          </div>
        </div>
      </div>
    </div>
  );
};

const StoreAddress = ({ location }) => {
  const { dealerNetwork } = useNationalSiteContext();
  const {
    locationName,
    phoneNumber,
    city,
    state: locationState,
    displayName
  } = location;
  const dealerAddress = buildStoreAddressForDisplay(location);
  const dealerCityState = `${city}, ${locationState}`;

  let nameForDisplay = locationName;
  if (dealerNetwork && !isEmpty(displayName)) {
    nameForDisplay = displayName;
  }
  return (
    <div className={'flex flex-col sm:w-2/3 store-details sm:pr-2'}>
      <div className={'flex store-name font-bold'}>{nameForDisplay}</div>
      <div className={'flex store-address'}>{dealerAddress}</div>
      <div className={'flex store-city'}>{dealerCityState}</div>
      <div className={'flex store-phone pt-3'}>{phoneNumber || ''}</div>
    </div>
  );
};

const StoreHoursAndDetails = ({
  location,
  showStoreDetailsLink,
  storeDetailsOnClick
}) => {
  const { businessHours } = location;
  const storeHoursToday = findStoreHoursToday(businessHours || {});
  return (
    <div className={'flex flex-col sm:w-1/3 store-hours justify-center w-auto'}>
      <div
        className={
          'flex store-hours-title text-green-600 font-bold pt-2 sm:pt-0'
        }
      >
        {storeHoursToday}
      </div>
      <StoreDetailsAndDirections
        location={location}
        showStoreDetailsLink={showStoreDetailsLink}
        storeDetailsOnClick={storeDetailsOnClick}
      />
    </div>
  );
};

const StoreDistance = ({ location }) => {
  const { distanceMiles } = location;

  const distanceTrimmed = distanceMiles ? distanceMiles.toFixed(1) : null;
  const distanceDisplay = distanceTrimmed ? `${distanceTrimmed} Mi` : '';
  return (
    <div
      className={
        'flex store-distance m-auto justify-center items-center font-bold'
      }
    >
      {distanceDisplay}
    </div>
  );
};

const SelectStoreButton = ({
  selected,
  currentStoreButtonLabel,
  currentStoreOnClick,
  selectStoreButtonLabel,
  selectStoreOnClick
}) => {
  const formatMessage = useFormatMessage();
  return (
    <div className={'flex flex-col justify-center items-center m-auto'}>
      {selected && !currentStoreOnClick ? (
        <PrimaryButton
          backgroundColor={'bg-primary-0 text-primary-500'}
          className={
            'w-32 h-12 sm:h-auto pointer-events-none border-primary-500 border-2'
          }
          pointerEvents={'none'}
        >
          {formatMessage(currentStoreButtonLabel)}
        </PrimaryButton>
      ) : (
        <PrimaryButton
          className={'store-select-button w-32 h-12 sm:h-auto'}
          onClick={selectStoreOnClick}
        >
          {formatMessage(selectStoreButtonLabel)}
        </PrimaryButton>
      )}
    </div>
  );
};

/**
 * Renders the store details and directions links.
 * @param location The store location
 * @param showStoreDetailsLink Should the store details link show?
 * @return {Element}
 * @constructor
 */
const StoreDetailsAndDirections = ({
  location,
  showStoreDetailsLink,
  storeDetailsOnClick
}) => {
  const { latitude, longitude, locationNumber } = location;
  return (
    <div className={'flex store-directions w-auto'}>
      <div className={'flex store-details w-auto text-primary-500 font-medium'}>
        <StoreDetailsLink
          locationNumber={locationNumber}
          showStoreDetailsLink={showStoreDetailsLink}
          storeDetailsOnClick={storeDetailsOnClick}
        />
      </div>
      <div className={'flex store-details-directions-divider'} />
      <div className={'flex store-directions w-auto pl-1'}>
        <StoreDirectionsLink latitude={latitude} longitude={longitude} />
      </div>
    </div>
  );
};

const StoreDirectionsLink = ({ latitude, longitude }) => {
  const formatMessage = useFormatMessage();
  return (
    <a
      href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${latitude},${longitude}`}
      className={'text-primary-500 font-medium'}
      target={'_blank'}
      rel={'noopener noreferrer'}
    >
      {formatMessage(messages.directions)}
    </a>
  );
};

const StoreDetailsLink = ({
  locationNumber,
  showStoreDetailsLink,
  storeDetailsOnClick
}) => {
  const formatMessage = useFormatMessage();
  if (!showStoreDetailsLink) {
    return null;
  }
  const { accountNumber, locationId } = parseLocationNumber(locationNumber);
  if (!accountNumber || !locationId) {
    return null;
  }
  return (
    <Link
      className={'flex store-details w-auto pr-2'}
      to={`/about/${accountNumber}/${locationId}`}
      onClick={() => {
        storeDetailsOnClick();
      }}
    >
      {formatMessage(messages.details)}
    </Link>
  );
};

function parseLocationNumber(locationNumber) {
  const locationSplit = locationNumber.split('-');
  if (locationSplit.length !== 3) {
    return {};
  }
  const accountNumber = locationSplit[1];
  const locationId = locationSplit[2];
  return { accountNumber, locationId };
}

export { StoreLocationRow };
