/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const TextArea = React.forwardRef((props, ref) => {
  const {
    className,
    handleBlur,
    hasErrors = false,
    onBlur,
    onChange,
    handleChange,
    touched = false,
    widths = 'w-full lg:w-64',
    ...passthroughProps
  } = props;
  return (
    <textarea
      {...passthroughProps}
      ref={ref}
      className={classNames(
        'p-2',
        'text-sm text-gray-900 placeholder-gray-500 leading-tight',
        'bg-white border border-solid rounded',
        'focus:shadow-outline focus:outline-none disabled:bg-gray-200 disabled:border-gray-300 disabled:cursor-not-allowed',
        'lg:p-3 lg:text-base',
        {
          [className]: !!classNames,
          [widths]: !!widths,
          'border-gray-400': !touched,
          'border-green-500': !hasErrors && touched,
          'border-red-500 field-error': hasErrors && touched
        }
      )}
      onBlur={e => {
        if (!!handleBlur) {
          // use override
          return handleBlur(e);
        }

        if (!!onBlur) {
          // use default
          return onBlur(e);
        }
      }}
      onChange={e => {
        if (!!handleChange) {
          handleChange(e);
        }

        onChange(e);
      }}
    />
  );
});

TextArea.propTypes = {
  /** Way to override the default onBlur if using formik */
  handleBlur: PropTypes.func,
  hasErrors: PropTypes.bool,
  name: PropTypes.string.isRequired,
  touched: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Class name(s) to affect the input's width.
   *
   * Added because not styles can be overridden via the `className` prop.
   */
  widths: PropTypes.string
};

export default TextArea;
export { TextArea };
