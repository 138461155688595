import { defineMessages } from 'react-intl';

export default defineMessages({
  selectedLocation: {
    id: 'StoreDropdown.selectedLocation',
    defaultMessage: 'Selected Location:'
  },
  selectedLocationSmall: {
    id: 'StoreDropdown.selectedLocationSmall',
    defaultMessage: 'Location:'
  },
  deliveringTo: {
    id: 'StoreDropdown.deliveringTo',
    defaultMessage: 'Delivering To:'
  },
  enterZipCode: {
    id: 'StoreDropdown.enterZipCode',
    defaultMessage: 'Enter City, State, Zip Code'
  },
  enterZip: {
    id: 'StoreDropdown.enterZip',
    defaultMessage: 'Enter City, State, Zip'
  },
  noNearbyStoresFound: {
    id: 'StoreDropdown.noNearbyStoresFound',
    defaultMessage: 'No Nearby Stores Found'
  },
  findOtherStores: {
    id: 'StoreDropdown.findOtherStores',
    defaultMessage: 'Find Other Stores'
  },
  useMyLocation: {
    id: 'StoreDropdown.useMyLocation',
    defaultMessage: 'Use My Location'
  },
  myStore: {
    id: 'StoreDropdown.myStore',
    defaultMessage: 'My Store'
  },
  setAsStore: {
    id: 'StoreDropdown.setAsStore',
    defaultMessage: 'Set as Store'
  },
  findNearbyStoresAriaLabel: {
    id: 'StoreDropdown.findNearbyStoresAriaLabel',
    defaultMessage: 'Find a nearby store by zip, city, state or address'
  },
  closeLocationSearch: {
    id: 'StoreDropdown.closeLocationSearch',
    defaultMessage: 'Close location search'
  },
  directions: {
    id: 'StoreDropdown.directions',
    defaultMessage: 'Directions'
  },
  storeLookupError: {
    id: 'StoreDropdown.storeLookupError',
    defaultMessage:
      'An error has occurred finding nearby stores. Please try again.'
  },
  useLocationError: {
    id: 'StoreDropdown.useLocationError',
    defaultMessage:
      'An error occurred finding your location. Please try again or input a city, state, or zip code.'
  },
  addressLookupError: {
    id: 'StoreDropdown.addressLookupError',
    defaultMessage:
      'An error occurred retrieving addresses. Try again or use your current location.'
  },
  unknownError: {
    id: 'StoreDropdown.unknownError',
    defaultMessage: 'An unknown error occurred. Please try again.'
  }
});
