/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  modalTitle: {
    id: 'CheckoutButton.modalTitle',
    defaultMessage: 'Some items may not be available immediately'
  },
  inventoryUnavailable: {
    id: 'CheckoutButton.inventoryUnavailable',
    defaultMessage:
      'Some of the items in your cart may not be immediately available at the selected location. It can take 2-5 days to restock them.{breakLine}' +
      'You can:{breakLineWide}' +
      '1) Contact the dealer {phoneNumber, select, NA { } other {at {phoneNumber} }}to confirm when the items will be available{breakLine}' +
      '2) Accept any delays and continue checking out{breakLine}' +
      '3) Modify your cart, then checkout again.{breakLine}' +
      '{breakLine}' +
      'Below are the affected items:{breakLine}'
  },
  itemUnavailable: {
    id: 'CheckoutButton.itemUnavailable',
    defaultMessage: '{partNumber}'
  },
  checkoutAnyway: {
    id: 'CheckoutButton.checkoutAnyway',
    defaultMessage: 'Accept delays'
  },
  modifyCart: {
    id: 'CheckoutButton.modifyCart',
    defaultMessage: 'Modify your cart'
  }
});
