/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { isString, isUndefined, reduce } from 'lodash';

import buildTimeResolver from './resolvers/buildTimeResolver';
import runTimeResolver from './resolvers/runTimeResolver';

/**
 * Utility class that provides methods for resolving environment properties.
 *
 * @see `packages/components/docs/EnvironmentProperties.adoc` for further documentation on property resolution
 */
const Environment = Object.freeze({
  /**
   * The ordered set of property resolvers.
   * @type {Array}
   */
  __propertyResolvers: [runTimeResolver, buildTimeResolver],

  /**
   * Attempts to resolve a property with the `propertyName` as the first parameter.
   * The second parameter is a default value that is returned if the property is
   * not successfully resolved.
   *
   * By default, this method will resolve properties using built-time or run-time
   * property resolves, unless additional custom resolvers have been registered.
   *
   * @param  {String} propertyName - the property name in constant or dot syntax
   * @param  {*} [defaultValue] - the default value
   *
   * @return {String} The value (or undefined)
   */
  get(propertyName, defaultValue) {
    if (!propertyName || !isString(propertyName)) {
      throw new Error(
        `First parameter to Environment#get must be a non-empty string, but instead received: "${propertyName}".`
      );
    }

    // if using dot syntax, we convert the property name to the CONSTANT version for lookup
    propertyName = propertyName.toUpperCase().split('.').join('_');

    // define a local variable that is easier to work with
    const resolvers = Environment.__propertyResolvers;

    // we reduce through the set of resolvers until we find one that resolves the property
    const propertyValue = reduce(
      resolvers,
      (result, resolver) => {
        // if the property has already been resolved, simply return the value
        if (!isUndefined(result)) {
          return result;
        }

        return resolver(propertyName);
      },
      undefined
    );

    // if the property is still undefined, return the default value
    return isUndefined(propertyValue) ? defaultValue : propertyValue;
  },

  /**
   * Attempts to resolve a Boolean property. This delegates to `#get` for
   * property resolution.
   *
   * @param  {String}  propertyName - the property name in constant or dot syntax
   * @param  {Boolean} [defaultValue] - the default value
   *
   * @return {Boolean} the value (or undefined)
   */
  getBoolean(propertyName, defaultValue) {
    const stringValue = Environment.get(propertyName);

    if (isUndefined(stringValue)) {
      return defaultValue;
    }

    if (stringValue === 'true') {
      return true;
    }

    if (stringValue === 'false') {
      return false;
    }

    throw new Error(
      `Unable to resolve Boolean property, expected a value of "true" or "false", but instead received: "${stringValue}".`
    );
  },

  /**
   * Attempts to resolve an Integer property. This delegates to `#get` for
   * property resolution.
   *
   * @param  {String} propertyName - the property name in constant or dot syntax
   * @param  {Number} [defaultValue] - the default value
   *
   * @return {Number} the value (or undefined)
   */
  getInteger(propertyName, defaultValue) {
    const stringValue = Environment.get(propertyName);

    if (isUndefined(stringValue)) {
      return defaultValue;
    }

    const integerValue = parseInt(stringValue);

    if (isNaN(integerValue)) {
      throw new Error(
        `Unable to resolve Integer property, expected a valid integer, but instead received: "${stringValue}".`
      );
    }

    return integerValue;
  },

  /**
   * Attempts to resolve a Float property. This delegates to `#get` for
   * property resolution.
   *
   * @param  {String} propertyName - the property name in constant or dot syntax
   * @param  {Number} [defaultValue] - the default value
   *
   * @return {Number} the value (or undefined)
   */
  getFloat(propertyName, defaultValue) {
    const stringValue = Environment.get(propertyName);

    if (isUndefined(stringValue)) {
      return defaultValue;
    }

    const floatValue = parseFloat(stringValue);

    if (isNaN(floatValue)) {
      throw new Error(
        `Unable to resolve Float property, expected a valid number, but instead received: "${stringValue}".`
      );
    }

    return floatValue;
  }
});

export default Environment;
