/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';
import { find, get } from 'lodash';

import {
  useStateProvinceRegionSelectValues,
  useFormatMessage
} from 'app/common/hooks';

import messages from '../../SavedPaymentForm.messages';

const ReadOnlyBillingAddress = props => {
  const { billingAddress, changeBillingAddress } = props;
  const formatMessage = useFormatMessage();
  const allStateProvinceRegions = useStateProvinceRegionSelectValues();

  const relevantStateProvinceRegions =
    allStateProvinceRegions[billingAddress.country];
  const stateProvinceRegion = get(
    find(relevantStateProvinceRegions, [
      'value',
      billingAddress.stateProvinceRegion
    ]),
    'value'
  );

  return (
    <>
      <div className="mb-4 text-gray-900 font-bold lg:text-lg">
        {formatMessage(messages.billingAddress)}
      </div>
      <div>{billingAddress.fullName}</div>
      <div className="text-gray-700">
        <div>{billingAddress.addressLine1}</div>
        {!!billingAddress.addressLine2 && (
          <div>{billingAddress.addressLine2}</div>
        )}
        {!!billingAddress.addressLine3 && (
          <div>{billingAddress.addressLine3}</div>
        )}
        <div>{`${
          !!billingAddress.city ? billingAddress.city : ''
        } ${stateProvinceRegion} ${
          !!billingAddress.postalCode ? billingAddress.postalCode : ''
        }`}</div>
        {!!billingAddress.country && <div>{billingAddress.country}</div>}
        {!!billingAddress.phonePrimary && (
          <div>{billingAddress.phonePrimary.phoneNumber}</div>
        )}
      </div>
      <button
        type="button"
        className="mt-4 px-4 py-2 text-sm text-gray-900 bg-white rounded shadow hover:bg-gray-200 focus:outline-none focus:shadow-outline"
        onClick={changeBillingAddress}
      >
        {formatMessage(messages.change)}
      </button>
    </>
  );
};

ReadOnlyBillingAddress.propTypes = {
  /** The billing address **/
  billingAddress: PropTypes.shape({
    fullName: PropTypes.string,
    companyName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    stateProvinceRegion: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    phonePrimary: PropTypes.shape({ phoneNumber: PropTypes.string }),
    isActive: PropTypes.bool
  }).isRequired,
  /** Function to trigger change of the address **/
  changeBillingAddress: PropTypes.func.isRequired
};

export default ReadOnlyBillingAddress;
export { ReadOnlyBillingAddress };
