/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { createContext } from 'react';

const defaultValue = Object.freeze({
  id: undefined,
  /* The URL of the campaign*/
  url: undefined,
  /* The URL of the campaign to be used on the site. This is "/_cp" + url. This is used in routing*/
  fullUrl: undefined,
  /* The javascript to be embedded */
  javascript: undefined,
  /* Was a campaign found? */
  isCampaignFound: false,
  /* The name of the campaign */
  name: undefined,
  /* Is the campaign still loading? */
  loading: true,
  /* Is this a valid campaign path? All campaign paths start with /_cp */
  isCampaignPath: false,
  /*
  The target page. e.g. / is homepage, /details/part-number is a product page, etc.
  Useful when rendering pages with indeterminate URLs (e.g. product or category), as this must be used instead of
  the actual path in the address bar
  */
  targetPage: undefined
});

const CampaignContext = createContext(defaultValue);
CampaignContext.defaultValue = defaultValue;

export default CampaignContext;
