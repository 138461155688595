/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef
} from 'react';
import { get, isEmpty, map, size, take } from 'lodash';
import {
  Icon,
  LoadingIcon,
  Modal,
  SecondaryButton,
  TertiaryButton
} from 'app/common/components';
import { ResolutionContextType } from 'app/common/constants';
import { TenantContext } from 'app/common/contexts';
import {
  useFormatMessage,
  useNearestLocations,
  useToggle
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import { NearbyLocation, RadiusSelector } from './components';
import messages from './NearestAvailableButton.messages';
import { FormattedMessage } from 'react-intl';
import { GeoLocationError } from './GeolocationError';
import { LoadMoreButton } from './LoadMoreButton';
import {
  CLEAR_ERRORS,
  nearestInventoryReducer,
  nearestInventoryState,
  RESET_ON_CLOSE,
  SET_ERROR,
  SET_RESULTS_NUM,
  SET_SEARCH_RADIUS,
  SET_SELECTED_LOCATION,
  SET_UNAVAILABLE_ITEMS,
  START_FETCH_STORES
} from './reducer';
import { UnavailableItemsWarning } from './components/UnavailableItemsWarning';
import { useHandleSwitch, useLoadLocations, useSetSearchOrigin } from './hooks';

const NearestAvailableButton = ({ product, nearbyButtonSize }) => {
  const formatMessage = useFormatMessage();
  const [open, toggleOpen] = useToggle();
  const { resolutionContext } = useContext(TenantContext);
  const isDealerContext = resolutionContext === ResolutionContextType.DEALER;

  if (isDealerContext) {
    return null;
  }

  return (
    <>
      <NearestAvailableModal
        product={product}
        open={open}
        toggleOpen={toggleOpen}
      />
      <TertiaryButton
        alignment="justify-start"
        className="mt-2"
        onClick={() => {
          toggleOpen();
        }}
        size={nearbyButtonSize}
        textStyleClassName="font-normal underline"
      >
        {formatMessage(messages.label)}
      </TertiaryButton>
    </>
  );
};

const NearestAvailableModal = ({ product, open = false, toggleOpen }) => {
  const formatMessage = useFormatMessage();
  const [state, dispatch] = useReducer(
    nearestInventoryReducer,
    nearestInventoryState
  );

  const handleError = useCallback(
    (err, errType) => doHandleError(err, errType, dispatch),
    []
  );

  const {
    loading,
    response,
    sendCallback: fetchLocations
  } = useNearestLocations(product.sku);
  const modalRef = useRef(null);

  const handleSwitch = useHandleSwitch(product, handleError);

  useOnClose(open, dispatch);
  useLoadLocations(
    open,
    state,
    dispatch,
    handleError,
    loading,
    response,
    fetchLocations
  );

  useSetSearchOrigin(state, dispatch, handleError);

  return (
    <Modal ref={modalRef} size={Modal.Size.LARGE} isOpen={open}>
      <NearestAvailableModalHeader {...{ product, toggleOpen }} />
      <Modal.Body className="bg-gray-100 text-gray-700">
        {isEmpty(state.error) ? (
          <>
            {!isEmpty(state.unavailableItems) ? (
              <UnavailableItemsWarning
                handleSwitch={(removeUnavailableItems = true) => {
                  handleSwitch({
                    inventoryLocation: state.selectedLocation,
                    itemsToRemove: removeUnavailableItems
                      ? state.unavailableItems
                      : undefined
                  });
                }}
                parentRef={modalRef}
                state={state}
                dispatch={dispatch}
              />
            ) : (
              <div className="flex items-center mt-0 mb-4 px-2 py-1 text-sm text-yellow-700 leading-snug border border-solid border-yellow-400 bg-yellow-100 rounded md:px-4 md:py-2 lg:mb-6 lg:text-base lg:leading-normal">
                <Icon className="mr-2 md:mr-4" name="exclamation-triangle" />
                <span>{formatMessage(messages.modalPricingCaveat)}</span>
              </div>
            )}
          </>
        ) : (
          <div className="flex items-center mt-0 mb-4 px-2 py-1 text-sm text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2 lg:mb-6 lg:text-base lg:leading-normal">
            <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
            <GeoLocationError code={get(state.error, 'code')} />
          </div>
        )}
        <RadiusSelector
          onSubmit={radius =>
            onRadiusChangeSubmit({ radius, state, dispatch, fetchLocations })
          }
          state={state}
          dispatch={dispatch}
        />
        <div className="my-4">
          {loading || state.loadingGeolocation ? (
            <LoadingIcon className="ml-4" />
          ) : (
            <>
              <StoreLocationRows
                {...{
                  state,
                  dispatch,
                  handleSwitch,
                  loading
                }}
              />
              {!isEmpty(state.stores) &&
                state.resultsNum < size(state.stores) && (
                  <div className="flex w-full justify-end mt-4">
                    <LoadMoreButton
                      loading={loading}
                      onClick={() => {
                        dispatch(SET_RESULTS_NUM(state.resultsNum + 5));
                      }}
                      handleError={handleError}
                    />
                  </div>
                )}
            </>
          )}
        </div>
      </Modal.Body>
      <NearestAvailableModalFooter
        onClick={() => {
          toggleOpen(false);
        }}
      />
    </Modal>
  );
};

function onRadiusChangeSubmit({ radius, dispatch }) {
  dispatch(CLEAR_ERRORS);
  dispatch(SET_SEARCH_RADIUS(radius));
  dispatch(START_FETCH_STORES);
}

function doHandleError(error, errType, dispatch) {
  const errorMsg = {
    ...error,
    when: `Switching locations for inventory: ${errType}`
  };
  logError(errorMsg);
  dispatch(SET_ERROR(error));
}
const StoreLocationRows = ({ state, dispatch, handleSwitch, loading }) => {
  const formatMessage = useFormatMessage();
  const { stores, resultsNum } = state;
  return (
    <ol className="flex flex-col w-full">
      {isEmpty(stores) ? (
        <li>
          <em className="block w-full pl-4 text-gray-600 capitalize not-italic">
            {formatMessage(messages.modalNoResults)}
          </em>
        </li>
      ) : (
        map(take(stores, resultsNum), inventoryLocation => (
          <NearbyLocation
            key={inventoryLocation.id}
            inventoryLocation={inventoryLocation}
            loadingLocations={loading}
            handleSwitch={() => {
              handleSwitch({ inventoryLocation });
            }}
            handleWarning={unavailableItems => {
              dispatch(SET_UNAVAILABLE_ITEMS(unavailableItems));
              dispatch(SET_SELECTED_LOCATION(inventoryLocation));
            }}
          />
        ))
      )}
    </ol>
  );
};

const NearestAvailableModalHeader = ({ product, toggleOpen }) => {
  return (
    <Modal.Header className="flex">
      <Modal.Header.Title>
        <FormattedMessage
          className="lg:text-xl font-medium"
          {...messages.modalTitle}
          values={{ name: <b>{product.name}</b> }}
        />
      </Modal.Header.Title>
      <Modal.Close
        onClose={() => {
          toggleOpen(false);
        }}
      />
    </Modal.Header>
  );
};

const NearestAvailableModalFooter = ({ onClick }) => {
  const formatMessage = useFormatMessage();
  return (
    <Modal.Footer className="flex items-center lg:justify-between">
      <SecondaryButton className="mr-4" onClick={onClick}>
        {formatMessage(messages.modalCancel)}
      </SecondaryButton>
    </Modal.Footer>
  );
};

/**
 * Resets the nearest inventory state to the initial state when the modal is closed.
 * @param open Is the modal open?
 * @param dispatch Dispatch function for the nearestInventoryReducer
 */
function useOnClose(open, dispatch) {
  useEffect(() => {
    // reset on close
    if (!open) {
      dispatch(RESET_ON_CLOSE());
    }
  }, [open, dispatch]);
}

export default NearestAvailableButton;
export { NearestAvailableButton };
