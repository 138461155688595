/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { Stage } from 'app/checkout/elements';
import { FulfillmentType } from 'app/common/constants';
import { CartContext, PaymentContext } from 'app/common/contexts';
import { useFormatMessage, useNationalSiteContext } from 'app/common/hooks';

import { ReviewInfoEditable, ReviewInfoReadOnly } from './components';
import { ReviewContext } from './contexts';
import messages from './Review.messages';
import environment from 'app/common/services/Environment';
import {
  FULFILLMENT_INFO_PATH,
  LOCATION_INFO_PATH,
  PAYMENT_INFO_PATH
} from 'app/checkout/components/CheckoutLayout/CheckoutPaths';

/**
 * Render component for the review stage of checkout.
 */
const Review = ({ active = false, stageNumber = 3 }) => {
  const formatMessage = useFormatMessage();
  const [editing, setEditing] = React.useState(false);
  const { cart, resolving } = React.useContext(CartContext);
  const { dealerSelected } = useNationalSiteContext();

  const resolved = !resolving && cart !== undefined;
  const {
    address = {},
    type: fulfillmentType,
    pricedFulfillmentOption
  } = get(cart, 'fulfillmentGroups[0]', {});

  const isNotVirtualFulfillment = fulfillmentType !== FulfillmentType.VIRTUAL;
  const reviewContextValue = React.useMemo(
    () => ({ editing, setEditing }),
    [editing, setEditing]
  );
  const { paypalEnabled, payments: activePayments } =
    React.useContext(PaymentContext);
  const payInStore = get(cart, 'attributes.PAY_IN_STORE', false);

  const needsFulfillmentOption =
    isFulfillmentServiceEnabled() && isEmpty(pricedFulfillmentOption);
  if (active && resolved) {
    if (!dealerSelected) {
      return <Redirect to={LOCATION_INFO_PATH} push />;
    }

    if (isNotVirtualFulfillment && isEmpty(address)) {
      return <Redirect to={FULFILLMENT_INFO_PATH} push />;
    }
    if (needsFulfillmentOption) {
      return <Redirect to={FULFILLMENT_INFO_PATH} push />;
    }

    if (
      !payInStore &&
      isEmpty(get(activePayments, 'content')) &&
      paypalEnabled
    ) {
      return <Redirect to={PAYMENT_INFO_PATH} push />;
    }
  }

  return (
    <Stage
      active={active}
      heading={formatMessage(messages.title)}
      number={stageNumber}
    >
      <ReviewContext.Provider value={reviewContextValue}>
        {!editing && (
          <Review.ReadOnly
            handleEdit={() => {
              if (active) {
                setEditing(true);
              }
            }}
            disabled={!active}
          />
        )}
        {editing && <Review.Editable disabled={!active} />}
      </ReviewContext.Provider>
    </Stage>
  );
};

function isFulfillmentServiceEnabled() {
  return environment.get('FULFILLMENT_SERVICE_ENABLED', 'true') === 'true';
}

Review.ReadOnly = ReviewInfoReadOnly;
Review.Editable = ReviewInfoEditable;

Review.propTypes = {
  active: PropTypes.bool
};

export default Review;
export { Review };
