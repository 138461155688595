/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty, merge } from 'lodash';

import { AuthContext } from 'app/auth/contexts';
import { LocaleContext, TenantContext } from 'app/common/contexts';
import { useNationalSiteContext, usePreviewState } from 'app/common/hooks';
import { RequestService } from 'app/common/services';
import log from 'app/common/utils/Log';

import useCancelTokenFactory from '../useCancelTokenFactory';
import { error, loading, success } from './restApiActions';
import { initialState, reducer } from './restApiReducer';

const logger = log.getLogger('common.hooks.useRestApi');

/**
 * @callback
 * @typedef {Function} restApiCallback
 * @param {Object} [configOverride] - Additional config to pass into the request
 * @param {String} [urlOverride] - URL to use instead of the base configured
 */

/**
 * @typedef {Object} RestApiConfig
 * @property {Object} [params] - Request params. e.g. { customer: "123" }
 * @property {String} [method] - Request method (e.g., "get", "post")
 * @property {Object} [data] - Request body.
 * @property {Object} [headers] - Request headers as key-value pairs e.g. { "X-Header": "value" }. Context related
 *    headers (e.g. siteId, locale, etc.) are automatically added.
 */

/**
 * Hook that sends a request to a REST API. This takes the endpoint's URL,
 * request config (e.g., request params, method, body) and returns the status
 * (error or loading), the response body, and the actual send-request function
 * as a memoized callback. Optionally, the caller can specify whether the
 * request is sent immediately upon using the hook or is triggered manually
 * later (such as when tying the request to the add-to-cart button).
 * <p>
 * Response contains a callback method to send the request that can be triggered
 * manually. This method can take in a new config that will be merged with the
 * original and any defaults.
 *
 * @param {string} url - url of the menu fetch endpoint
 * @param {RestApiConfig} config - Object containing additional request config such as
 *     the request params or request method
 * @param {boolean} [sendImmediate=true] - Whether to send the request
 *     immediately upon using the hook. If false, then the caller is responsible
 *     for triggering the `sendCallback`. Defaults to `true`.
 * @param {boolean} [rejectOnError=false] - Whether to reject the "send"
 *     promise on error response. Either way, the error will be part of a
 *     separate dispatch event.
 *
 * @return {{
 *   error: boolean,
 *   exception: Object,
 *   loading: boolean,
 *   response: Object,
 *   sendCallback: restApiCallback,
 *   requestStatus: string: 'PENDING' | 'IN_PROGRESS' | 'COMPLETE'
 * }} The fetch response
 */
function useRestApi(url, config, sendImmediate = true, rejectOnError = false) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    application,
    resolving: resolvingTenant,
    resolutionContext,
    tenant
  } = React.useContext(TenantContext);
  const { id: applicationId, customerContextId } = application || {};
  const { id: tenantId } = tenant || {};
  const { isAuthenticating } = React.useContext(AuthContext);
  const { currentLocale: locale } = React.useContext(LocaleContext);
  const previewState = usePreviewState();
  const [getCancelToken] = useCancelTokenFactory();
  const { nationalSiteHeaders } = useNationalSiteContext();

  const sendCallback = React.useCallback(
    (conf, urlOverride) => {
      const finalConfig = merge({}, config, conf);

      return send({
        cancelToken: getCancelToken(),
        config: finalConfig,
        context: {
          applicationId,
          customerContextId,
          locale,
          previewDate: previewState.previewDate,
          previewToken: previewState.token,
          resolutionContext,
          sandboxId: previewState.sandboxId,
          tenantId,
          nationalSiteHeaders
        },
        dispatch,
        rejectOnError,
        url: urlOverride || url
      });
    },
    [
      applicationId,
      config,
      getCancelToken,
      customerContextId,
      locale,
      previewState.previewDate,
      previewState.token,
      previewState.sandboxId,
      rejectOnError,
      resolutionContext,
      tenantId,
      url,
      nationalSiteHeaders
    ]
  );

  React.useEffect(() => {
    if (
      sendImmediate &&
      !resolvingTenant &&
      !isAuthenticating &&
      (!previewState.isActive || previewState.isValidated)
    ) {
      sendCallback().catch(() => {});
    }
  }, [
    isAuthenticating,
    sendCallback,
    sendImmediate,
    resolvingTenant,
    previewState.isActive,
    previewState.isValidated
  ]);

  return { ...state, sendCallback };
}

/**
 * Sends request to get the menu info and updates the Menu state with the
 * resolved items.
 *
 * @param {CancelToken} cancelToken - cancel token to cancel the request if the
 *     component unmounts
 * @param {RestApiConfig} config - Object containing additional request config such as
 *     the request params or request method
 * @param {Object} context - context info such as current site ID and locale
 * @param {function} dispatch - Method to update the Menu state
 * @param {boolean} [rejectOnError=false] - Whether to reject the "send"
 *     promise on error response. Either way, the error will be part of a
 *     separate dispatch event.
 * @param {string} url - URL to hit to fetch the data
 * @param {function} setRequestStatus - Method to update the request status
 *
 * @return {Promise<*>}
 */
const send = ({
  cancelToken,
  config,
  context,
  dispatch,
  rejectOnError,
  url
}) => {
  dispatch(loading());

  const { method = 'get', ...otherConfig } = config;

  return RequestService[method](
    {
      cancelToken,
      url,
      ...otherConfig
    },
    context
  )
    .then(response => response.data)
    .then(data => {
      dispatch(success(data));
      return data;
    })
    .catch(err => {
      if (RequestService.isCancel(err)) {
        return [];
      }

      const { config, response = {} } = err;
      const { data = {} } = response;

      if (isEmpty(data)) {
        logger.error(
          `An error occurred with the API request but was not formatted as expected!
           \tConfig: ${JSON.stringify(config)}
           \tResponse: ${JSON.stringify(response)}`
        );
      }

      dispatch(error(data));

      if (rejectOnError) {
        return Promise.reject(data);
      }

      return [];
    });
};

export default useRestApi;
export { error, send, initialState, loading, reducer, success, useRestApi };
