/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty, startCase, toLower } from 'lodash';
import classNames from 'classnames';

import { GetDirectionsButton } from 'app/about/components/About/components/index';
import { TenantContext } from 'app/common/contexts';
import { useFormatMessage, useNationalSiteContext } from 'app/common/hooks';
import { normalizePhoneNumberWithDashes } from 'app/common/utils/AddressUtils';

import messages from './DealerInfo.messages';

const DealerInfo = ({ application }) => {
  const formatMessage = useFormatMessage();
  const phoneNumber = get(application, 'phoneNumber');
  const { nationalSite, dealerNetwork, applicationThemeSource } =
    useNationalSiteContext();
  const { tenant } = React.useContext(TenantContext);

  // Fallback to tenant logo if no application logo is available
  const { contentUrl, altText, title } =
    get(application, 'logoAsset') ||
    get(applicationThemeSource, 'logoAsset') ||
    get(tenant, 'logoAsset', {});

  const displayName = getAppDisplayName(application, dealerNetwork);

  return (
    <div className="flex flex-col justify-between h-full w-full">
      <img
        className="m-5 h-24 object-scale-down"
        alt={altText || formatMessage(messages.logoAlt)}
        title={title}
        src={contentUrl}
      />
      <div
        className={classNames('text-white flex-grow', {
          'bg-customGray': !!nationalSite,
          'bg-primary-500': !nationalSite
        })}
      >
        <div className="flex flex-row justify-between">
          <h4 className="mt-3 mb-3 ml-4 text-lg font-bold">{displayName}</h4>
          <GetDirectionsButton
            className="p-3 m-2 flex lg:hidden"
            application={application}
          />
        </div>
        <DisplayAddress
          line1={application.line1}
          city={application.city}
          state={application.state}
          zip={application.zip}
        />
        {!isEmpty(phoneNumber) && (
          <p className="ml-4 mt-1 mb-3">
            {formatMessage(messages.callUs)}{' '}
            <a
              href={'tel:' + phoneNumber}
              className="inline-flex text-primary-100 hover:text-primary-200"
            >
              {normalizePhoneNumberWithDashes(phoneNumber)}
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

/**
 * Capitalizes the first letter of every word in a string without allowing the string to be in all caps.
 *
 * @param string to capitalize
 * @returns {*} modified string with capitalizations
 */
function titleCase(string) {
  return startCase(toLower(string));
}

/**
 * Puts address information into an address format of
 *
 * STREET
 * CITY, STATE ZIP
 *
 * @param className Parent classname information
 * @param line1 Street information of the address
 * @param city City of the address
 * @param state State of the address
 * @param zip Zipcode of the address
 * @returns {JSX.Element}
 * @constructor
 */
const DisplayAddress = ({ className, line1, city, state, zip }) => {
  return (
    <p
      className={classNames('ml-4', {
        [className]: !!className
      })}
    >
      {titleCase(line1)} <br /> {titleCase(city) + ', ' + state + ' ' + zip}
    </p>
  );
};

function getAppDisplayName(application, dealerNetwork) {
  const displayName = dealerNetwork
    ? get(application, 'displayName')
    : get(application, 'name');
  if (displayName === get(application, 'name')) {
    return titleCase(displayName);
  }
  return displayName;
}

export default DealerInfo;
export { DealerInfo };
