/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  ariaLabel: {
    id: 'Cart.Promotions.code.ariaLabel',
    defaultMessage: 'Promotion Code Input'
  },
  placeholder: {
    id: 'Cart.Promotions.code.placeholder',
    defaultMessage: 'Promotion Code'
  },
  submitLabel: {
    id: 'Cart.Promotions.code.submitLabel',
    defaultMessage: 'Apply'
  },
  maxUsesError: {
    id: 'Cart.Promotions.code.maxUsesError',
    defaultMessage: 'That code is no longer usable. Please try another.'
  },
  invalidError: {
    id: 'Cart.Promotions.code.invalidError',
    defaultMessage: 'The code you entered is not valid. Please try another.'
  },
  otherError: {
    id: 'Cart.Promotions.code.otherError',
    defaultMessage:
      'We were unable to add that code at this time. Please try again later.'
  }
});
