import { useRestApi } from 'app/common/hooks';
import { useEffect, useState } from 'react';
import { logError } from 'app/common/utils/ApiErrorUtils';
import { isEmpty } from 'lodash';
import {
  COMPLETE,
  IN_PROGRESS,
  PENDING
} from 'app/common/hooks/useRestApi/RequestStatus.js';
import useBrandsInfo from './useBrandsInfo.js';

function useMinCategoriesApi() {
  const [minCategories, setMinCategories] = useState([]);
  const { minCategoriesPath } = useBrandsInfo();

  const {
    error,
    exception,
    response,
    sendCallback: fetchMinCategories,
    requestStatus
  } = useRestApi(minCategoriesPath, {}, false);

  useEffect(() => {
    if (requestStatus === IN_PROGRESS || !isEmpty(minCategories)) {
      return;
    }

    if (error) {
      logError({ ...exception, when: 'Fetching Categories' });
      return;
    }

    if (requestStatus === COMPLETE) {
      setMinCategories(response);
      return;
    }

    if (requestStatus === PENDING) {
      fetchMinCategories();
    }
  }, [
    error,
    exception,
    response,
    requestStatus,
    minCategories,
    setMinCategories,
    fetchMinCategories
  ]);

  return minCategories;
}

export default useMinCategoriesApi;
