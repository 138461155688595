/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebook,
  faFacebookF,
  faGooglePlusG,
  faInstagram,
  faLinkedinIn,
  faPinterestP,
  faTwitter,
  faYoutube,
  faCcAmex,
  faCcDiscover,
  faCcMastercard,
  faCcVisa
} from '@fortawesome/free-brands-svg-icons';
import {
  faAngleDoubleLeft as fasAngleDoubleLeft,
  faAngleDoubleRight as fasAngleDoubleRight,
  faAngleDown as fasAngleDown,
  faAngleRight as fasAngleRight,
  faAngleLeft as fasAngleLeft,
  faAngleUp as fasAngleUp,
  faBars as fasBars,
  faCheck as fasCheck,
  faCheckCircle as fasCheckCircle,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faChevronUp as fasChevronUp,
  faChevronDown as fasChevronDown,
  faCircle as fasCircle,
  faDotCircle as fasDotCircle,
  faEllipsisV as fasEllipsisV,
  faEnvelope as fasEnvelope,
  faExchangeAlt as fasExchangeAlt,
  faExclamationCircle as fasExclamationCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faExternalLinkAlt as fasExternalLinkAlt,
  faHeart as fasHeart,
  faInfoCircle as fasInfoCircle,
  faMinus as fasMinus,
  faPlus as fasPlus,
  faSearch as fasSearch,
  faSearchPlus as fasSearchPlus,
  faSignOutAlt as fasSignOutAlt,
  faShoppingCart as fasShoppingCart,
  faSortAlphaUp as fasSortAlphaUp,
  faSortAlphaDown as fasSortAlphaDown,
  faSortNumericUp as fasSortNumericUp,
  faSortNumericDown as fasSortNumericDown,
  faTh as fasTh,
  faThList as fasThList,
  faTimes as fasTimes,
  faTimesCircle as fasTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import {
  faCheckCircle as farCheckCircle,
  faCheckSquare as farCheckSquare,
  faCircle as farCircle,
  faHeart as farHeart,
  faQuestionCircle as farQuestionCircle,
  faSquare as farSquare,
  faClock as farClock,
  faCopy as farCopy
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faFacebook,
  faFacebookF,
  faGooglePlusG,
  faInstagram,
  faLinkedinIn,
  faPinterestP,
  faTwitter,
  faYoutube,
  faCcAmex,
  faCcDiscover,
  faCcMastercard,
  faCcVisa,
  farCheckCircle,
  farCheckSquare,
  fasChevronLeft,
  fasChevronRight,
  fasChevronUp,
  fasChevronDown,
  farCircle,
  farClock,
  farCopy,
  farHeart,
  farQuestionCircle,
  farSquare,
  fasAngleDoubleLeft,
  fasAngleDoubleRight,
  fasAngleDown,
  fasAngleRight,
  fasAngleLeft,
  fasAngleUp,
  fasBars,
  fasCircle,
  fasCheck,
  fasCheckCircle,
  fasDotCircle,
  fasEllipsisV,
  fasEnvelope,
  fasExchangeAlt,
  fasExclamationCircle,
  fasExclamationTriangle,
  fasExternalLinkAlt,
  fasHeart,
  fasInfoCircle,
  fasMinus,
  fasPlus,
  fasSearch,
  fasSearchPlus,
  fasSignOutAlt,
  fasShoppingCart,
  fasSortAlphaDown,
  fasSortAlphaUp,
  fasSortNumericDown,
  fasSortNumericUp,
  fasTh,
  fasThList,
  fasTimes,
  fasTimesCircle
);
