import { get } from 'lodash';

/**
 * Builds cart/fulfillment/item totals for the payment request API.
 */
const getPaymentRequestValues = function (cart) {
  return getCartTotals(cart);
};

function getCartTotals(cart) {
  const cartPricing = get(cart, 'cartPricing');
  let subtotal = get(cartPricing, 'subtotal');
  const totalCoreCost = get(cartPricing, 'totalCoreCost');
  if (totalCoreCost) {
    subtotal.amount = subtotal.amount + totalCoreCost.amount;
  }
  const adjustmentsTotal = get(cartPricing, 'adjustmentsTotal');
  const fulfillmentTotal = get(cartPricing, 'fulfillmentTotal');
  const totalTax = get(cartPricing, 'totalTax');
  const total = get(cartPricing, 'total');
  return {
    adjustmentsTotal,
    fulfillmentTotal,
    subtotal,
    taxTotal: totalTax,
    amount: total
  };
}

export { getPaymentRequestValues };
