/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useReducer } from 'react';

import { Minicart, SiteLogo } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from './CheckoutHeader.messages';
import { BottomHeader } from 'app/layout/components/Header';
import {
  initialState as searchInitialState,
  reducer as searchReducer
} from 'app/layout/components/Header/components/Search/searchReducer';
import {
  MobileSearchInput,
  Search
} from 'app/layout/components/Header/components';

/**
 * Render component for the checkout layout's header.
 *
 * @visibleName Checkout Header
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const CheckoutHeader = () => {
  const formatMessage = useFormatMessage();
  const [isMobileSearchOpen, setMobileSearchOpen] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const searchReducerProps = useReducer(searchReducer, searchInitialState);
  return (
    <header
      id="checkout-header"
      className="sticky top-0 bg-white border-b border-solid border-gray-400 print:hidden"
      style={{ zIndex: 39 }}
    >
      <nav className="container flex justify-between pl-2 py-2 md:pl-6 md:pr-2 lg:px-8">
        <SiteLogo
          className="block flex-grow w-1/4 text-left"
          widgetProps={{
            className: 'block',
            innerProps: {
              className: 'block w-full h-auto md:w-auto md:h-9'
            }
          }}
        />
        <h1 className="self-center flex-grow w-1/2 h-full text-center text-xl font-medium leading-none md:text-2xl">
          {formatMessage(messages.title)}
        </h1>
        <Search
          {...{
            mobile: true,
            query,
            setQuery,
            setMobileSearchOpen,
            isMobileSearchOpen,
            searchReducerProps
          }}
        />
        <Minicart className="flex-shrink w-1/4 justify-end -my-2" disabled />
      </nav>
      <BottomHeader
        {...{
          query,
          setQuery,
          setMobileSearchOpen,
          isMobileSearchOpen,
          searchReducerProps
        }}
      />
      <MobileSearchInput
        {...{
          query,
          setQuery,
          isMobileSearchOpen,
          setMobileSearchOpen,
          searchReducerProps
        }}
      />
    </header>
  );
};

export default CheckoutHeader;
export { CheckoutHeader };
