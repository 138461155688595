/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useContext, useMemo } from 'react';
import { get } from 'lodash';
import { CartContext } from 'app/common/contexts';

import { useLineItemBuilderForItems } from '../useLineItemBuilder';

/**
 * Hook to use to create an `ItemMarketingMessageRequest` for fetching
 * offer marketing messages related to an item.
 *
 * @param {Object[]} items - Items to use to build the line item from
 * @param {number} maxMessageCount - The maximum number of messages to return
 * @param {string} categoryId - The category ID to use to fetch the messages
 * @param {string} itemType - the type of the item. Either CART_ITEM or PRODUCT
 *
 * @return {{ lineItems: [{}],
 * maxMessageCount: number,
 * categoryId: string,
 * codes: {} }}
 */
function useItemMarketingMessageRequest({
  items = [],
  maxMessageCount = 10,
  categoryId = undefined,
  itemType
}) {
  const { cart } = useContext(CartContext);
  const offerCodes = get(cart, 'offerCodes');
  const lineItems = useLineItemBuilderForItems(items, itemType);

  return useMemo(
    () => ({
      lineItems,
      maxMessageCount,
      categoryId,
      codes: { offerCodes }
    }),
    [categoryId, maxMessageCount, lineItems, offerCodes]
  );
}

export default useItemMarketingMessageRequest;
export { useItemMarketingMessageRequest };
