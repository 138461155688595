/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useMemo } from 'react';
import {
  findIndex,
  get,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  omitBy,
  pick
} from 'lodash';

import { FleetContext, TopBrandContext } from 'app/common/contexts';
import { useSearchInfo } from 'app/common/hooks/search';

import { parseSearch, stringifyParams } from '../../utils/FilterUtils';

/**
 * Hook to use to create the config for a search or browse request. This will
 * setup the default and usual params and handle serializing them in a way that
 * axios can handle correctly.
 *
 * If there are default filters to apply, pass them as `defaultFilters`. They
 * should take the form:
 *
 * ```
 * [{
 *  name: 'name',
 *  values: 'value1, value2, etc.'
 * }]
 * ```
 *
 * @param {string} searchString - URL query-string to use as the base. Used to
 *     glean current page and sort.
 * @param {Object} defaultFilters - Map of filters to add as params
 * @param {number} [sizeOverride] - Override the default result size
 *     param
 * @param {string} [sortOverride] - Override the default sort of the result set
 * @param {Array} [includeFacets] - Facets to include in the response
 *
 * @return {{paramsSerializer: (function(*=): string), params: *}}
 */
function useSearchRequestConfig(
  searchString,
  defaultFilters,
  sizeOverride,
  sortOverride,
  includeFacets
) {
  const { searchResultsPageSize: size, searchResultsDefaultSort: defaultSort } =
    useSearchInfo();
  const [params, setParams] = React.useState(
    pick(parseSearch(searchString), ['filters', 'page', 'q', 'sort'])
  );
  const { activeFleet } = React.useContext(FleetContext);
  const { activeTopBrand } = React.useContext(TopBrandContext);

  const memoizedIncludeFacets = useMemo(() => includeFacets, [includeFacets]);

  React.useEffect(() => {
    const ps = pick(parseSearch(searchString), [
      'filters',
      'page',
      'q',
      'sort'
    ]);
    setParams(prev => {
      if (!isEqual(prev, ps)) {
        return ps;
      }

      return prev;
    });
  }, [searchString]);

  return React.useMemo(() => {
    const {
      filters: currentFilters = [],
      page = 1,
      q,
      sort = sortOverride || defaultSort
    } = params;
    let filters = isEmpty(defaultFilters)
      ? currentFilters.concat([])
      : currentFilters.concat(
          // creates a new list of all the default filters missing
          defaultFilters.filter(filter => currentFilters.indexOf(filter) < 0)
        );
    const fleetFilterIndex = findIndex(filters, ['name', 'fleetIds']);

    if (fleetFilterIndex >= 0 && isEmpty(activeFleet)) {
      // no active fleet so remove
      filters = filters.filter(f => f.name !== 'fleetIds');
    } else if (!isEmpty(activeFleet)) {
      const fleetFilter = { name: 'fleetIds', values: activeFleet.id };

      if (fleetFilterIndex < 0) {
        filters.push(fleetFilter);
      } else {
        filters[fleetFilterIndex] = fleetFilter;
      }
    }

    if (!isEmpty(activeTopBrand)) {
      filters = filters.filter(p => p.name !== 'attr-BRAND');
      filters.push({
        name: 'attr-BRAND',
        values: get(activeTopBrand, 'brandName')
      });
    }

    return {
      params: omitBy(
        {
          filters,
          // we display 1-indexed paging in the URL, but the backend uses 0-indexed
          page: page - 1,
          query: q,
          size: isNumber(sizeOverride) ? sizeOverride : size,
          sort,
          type: 'PRODUCT',
          includedFacets: memoizedIncludeFacets
        },
        isNil
      ),
      paramsSerializer: ps => stringifyParams(ps)
    };
  }, [
    defaultFilters,
    defaultSort,
    activeFleet,
    params,
    size,
    sizeOverride,
    sortOverride,
    activeTopBrand,
    memoizedIncludeFacets
  ]);
}

export default useSearchRequestConfig;
