/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'MyAccount.ListDetails.ListSummary.title',
    defaultMessage: 'List Summary'
  },
  subtotal: {
    id: 'MyAccount.ListDetails.ListSummary.subtotal',
    defaultMessage: 'Subtotal'
  },
  tax: {
    id: 'MyAccount.ListDetails.ListSummary.tax',
    defaultMessage: 'Tax'
  },
  taxTbd: {
    id: 'MyAccount.ListDetails.ListSummary.tax.tbd',
    defaultMessage: 'TBD'
  },
  total: {
    id: 'MyAccount.ListDetails.ListSummary.total',
    defaultMessage: 'Est. Total'
  },
  addToCart: {
    id: 'MyAccount.ListDetails.ListSummary.addToCart',
    defaultMessage: 'Add everything to Cart'
  }
});
