/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

/**
 * Defines the types of assets that can be retrieved from the AssetService.
 *
 * @type {Readonly<{WORD: string, EXCEL: string, IMAGE: string, PDF: string, VIDEO: string, CSV: string, EMBED: string, TEXT: string, UNKNOWN: string, AUDIO: string}>}
 */
const AssetType = Object.freeze({
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  EMBED: 'EMBED',
  PDF: 'PDF',
  WORD: 'WORD',
  AUDIO: 'AUDIO',
  CSV: 'CSV',
  EXCEL: 'EXCEL',
  TEXT: 'TEXT',
  UNKNOWN: 'UNKNOWN'
});

export default AssetType;
export { AssetType };
