import { useFormatMessage, useGtmPageView } from 'app/common/hooks/index.js';
import { useLocation } from 'react-router-dom';
import { useHeaderMetadata } from 'app/core/components/App/index.js';
import { Helmet as ReactHelmet } from 'react-helmet';
import React, { useMemo, useState } from 'react';
import messages from './Brands.messages';
import { useAllBrandsApi, useFeaturedBrandsApi } from './hooks/index.js';
import {
  BrandsCategoryFilters,
  BrandList,
  BrandSectionLinks,
  FeaturedBrands
} from './components/index.js';
import { Breadcrumbs } from 'app/common/components/index.js';
import { isEmpty } from 'lodash';

const Brands = () => {
  const formatMessage = useFormatMessage();
  const [filteredBrandNames, setFilteredBrandNames] = useState([]);
  const breadcrumbs = [
    { uri: '/brands', label: formatMessage(messages.title), preserveCase: true }
  ];

  const brandsMap = useAllBrandsApi();
  const featuredBrands = useFeaturedBrandsApi();

  const filteredFeaturedBrands = useMemo(
    () => filterBrands(filteredBrandNames, featuredBrands),
    [filteredBrandNames, featuredBrands]
  );
  const filteredAllBrands = useMemo(
    () => filterBrandsMap(filteredBrandNames, brandsMap),
    [filteredBrandNames, brandsMap]
  );

  return (
    <main className="container relative flex-grow mx-auto p-4 sm:py-8 md:px-6 lg:px-8">
      <BrandsHelmet />

      <header className="text-center">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <div className="flex justify-between items-baseline mb-2 border-b border-gray-400">
          <h1 className="block mb-2 capitalize text-gray-700 text-2xl font-medium leading-tight sm:text-3xl lg:text-4xl">
            {formatMessage(messages.title)}
          </h1>
        </div>
        <div>
          <BrandsCategoryFilters setFilteredBrands={setFilteredBrandNames} />
        </div>
      </header>
      <BrandSectionLinks brandsMap={filteredAllBrands} />
      <FeaturedBrands featuredBrands={filteredFeaturedBrands} />
      <BrandList brandsMap={filteredAllBrands} />
    </main>
  );
};

/**
 *
 * @param {Array<String>} filteredBrandNames
 * @param {Array<MinimalBrand>} unfilteredBrands
 */
function filterBrands(filteredBrandNames, unfilteredBrands) {
  const filteredFeaturedBrands = [];
  if (isEmpty(filteredBrandNames)) {
    return unfilteredBrands;
  }

  for (const brand of unfilteredBrands) {
    if (filteredBrandNames.includes(brand.brandName)) {
      filteredFeaturedBrands.push(brand);
    }
  }

  return filteredFeaturedBrands;
}

/**
 *
 * @param {Map<Number, Array<MinimalBrand>>} brandsMap
 * @param {Array<String>} filteredBrandNames
 */
function filterBrandsMap(filteredBrandNames, brandsMap) {
  const filteredBrandsMap = [];
  if (isEmpty(filteredBrandNames)) {
    return brandsMap;
  }

  Object.entries(brandsMap).map((entry, i) => {
    const key = entry[0];
    const values = entry[1];
    const filteredList = values.filter(minBrand =>
      filteredBrandNames.includes(minBrand.brandName)
    );
    if (filteredList.length > 0) {
      filteredBrandsMap[key] = filteredList;
    }
  });

  return filteredBrandsMap;
}

const BrandsHelmet = () => {
  const formatMessage = useFormatMessage();
  const { pathname } = useLocation();
  const { siteTitle, siteDescription } = useHeaderMetadata();

  const title = formatMessage(messages.title);
  const canonical = `${window.location.origin}${pathname}`;
  useGtmPageView('Brands');

  return (
    <ReactHelmet titleTemplate={`%s - ${siteTitle}`}>
      <title>{title}</title>
      <meta name="description" content={formatMessage(messages.description)} />
      <meta name="canonical" content={canonical} />
      <meta name="robots" content="index,follow" />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={siteDescription} />
      <link rel="canonical" href={canonical} />
    </ReactHelmet>
  );
};

export default Brands;
export { Brands };
