/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { get, isEmpty, throttle, toLower, values } from 'lodash';
import PropTypes from 'prop-types';

import { Button, CoreCost, TertiaryButton } from 'app/common/components';
import { AssetType, PriceType } from 'app/common/constants';
import {
  useProductClick,
  useProductUrl,
  useWindowEventListener
} from 'app/common/hooks';

import { MiniProductGridContext } from '../../contexts';
import {
  MiniProductAddToCart,
  MiniProductAsset,
  MiniProductPrices
} from './components';
import ItemAvailability from 'app/common/components/ItemAvailability';
import messages from './MiniProduct.messages';

/**
 * Render component for a single Product card.
 *
 * @visibleName Mini Product Grid: Mini Product
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const MiniProduct = ({ product, position, pricing }) => {
  const { containerName, usingGrid = true } = React.useContext(
    MiniProductGridContext
  );
  const gtmItem = React.useMemo(
    () => ({
      brand: get(product, 'attributes.BRAND.value'),
      category: get(product, 'parentCategories[0].name'),
      id: product.sku,
      name: product.description,
      price: get(product, 'priceInfo.price.amount', 0.0),
      position,
      quantity: 1,
      containerName
    }),
    [containerName, product, position]
  );

  return (
    <li
      className={classNames(
        'MiniProduct group flex-grow-0 mb-4 pb-4 border-b border-solid border-gray-300 last:border-b-0 sm:flex sm:flex-wrap sm:justify-end sm:p-3 overflow-hidden',
        {
          'basis-0 sm:flex-col sm:flex-shrink-0 sm:basis-1/2 sm:border-b-0 md:basis-1/3':
            usingGrid,
          'sm:flex-row sm:flex-no-wrap sm:items-start sm:border-b sm:border-solid sm:last:border-b-0':
            !usingGrid
        }
      )}
    >
      <MiniProduct.Asset
        className={classNames('relative float-left w-2/5 pr-4', {
          'sm:w-full': usingGrid,
          'sm:w-1/5': !usingGrid
        })}
        gtmItem={gtmItem}
        product={product}
      />
      <MiniProduct.Body
        className={classNames('flex flex-col flex-grow w-3/5', {
          'flex-grow flex-shrink-0 basis-0 sm:w-full sm:mt-4': usingGrid,
          'sm:w-2/5 sm:pr-2': !usingGrid
        })}
        gtmItem={gtmItem}
        product={product}
      />
      <MiniProduct.Footer
        className={classNames('flex flex-col w-3/5 ml-auto', {
          'sm:w-full': usingGrid,
          'sm:w-1/3': !usingGrid
        })}
        product={product}
        gtmItem={gtmItem}
        pricing={pricing}
      />
    </li>
  );
};

const Asset = ({ className, gtmItem, product }) => {
  const url = useProductUrl(product.uri);
  const primaryAsset = get(product, 'primaryAsset', {
    altText: product.primaryAssetAltText,
    contentUrl: product.primaryAssetContentUrl,
    tags: product.primaryAssetTags,
    title: product.primaryAssetTitle,
    type: product.primaryAssetType
  });
  return (
    <div className={className}>
      <MiniProductAsset
        availableOnline={product.availableOnline}
        productName={product.name}
        primaryAsset={primaryAsset}
        productUrl={url}
        gtmItem={gtmItem}
      />
    </div>
  );
};

const Body = ({ className, gtmItem, product }) => {
  const { promotionMessage } = product;
  const url = useProductUrl(product.uri);
  const sendProductClick = useProductClick(gtmItem);
  const { breadcrumbs = [] } = React.useContext(MiniProductGridContext);

  // -- this block is to fix a safari bug when using basis-0 or basis-% for children of flex-col
  const [minHeight, setMinHeight] = React.useState('0');
  const nameRef = React.useRef(null);
  const descriptionRef = React.useRef(null);
  const promoRef = React.useRef(null);
  const updateMinHeight = React.useCallback(
    () =>
      throttle(() => {
        const newHeight =
          get(nameRef, 'current.offsetHeight', 0) +
          get(descriptionRef, 'current.offsetHeight', 0) +
          get(promoRef, 'current.offsetHeight', 0);

        setMinHeight(newHeight);
      }, 100),
    []
  );

  useWindowEventListener('resize', updateMinHeight);

  React.useEffect(() => {
    const newHeight =
      get(nameRef, 'current.offsetHeight', 0) +
      get(descriptionRef, 'current.offsetHeight', 0) +
      get(promoRef, 'current.offsetHeight', 0);

    setMinHeight(newHeight);
  }, []);
  // -- this block is to fix a safari bug when using basis-0 or basis-% for children of flex-col

  return (
    <header className={className} style={{ minHeight: `${minHeight + 16}px` }}>
      <h4
        ref={nameRef}
        className="mb-2 font-bold leading-snug text-base text-gray-700 uppercase"
      >
        <Button
          className="text-gray-700 appearance-none group-hover:text-gray-700 focus:text-gray-700 focus:outline-none"
          to={location => ({
            ...location,
            pathname: url,
            state: {
              ...get(location, 'state', {}),
              breadcrumbs: breadcrumbs.concat([{ label: product.name }])
            }
          })}
          onClick={() => {
            sendProductClick();
          }}
        >
          {product.name}
        </Button>
      </h4>
      <h5
        ref={descriptionRef}
        className="mb-2 leading-snug text-sm uppercase md:text-base"
      >
        <Button
          className="text-gray-600 appearance-none break-normal focus:text-gray-700 focus:outline-none"
          to={location => ({
            ...location,
            pathname: url,
            state: {
              ...get(location, 'state', {}),
              breadcrumbs: breadcrumbs.concat([{ label: product.name }])
            }
          })}
          onClick={() => {
            sendProductClick();
          }}
        >
          {toLower(product.description)}
        </Button>
      </h5>
      {!isEmpty(promotionMessage) && <p ref={promoRef}>{promotionMessage}</p>}
    </header>
  );
};

const Footer = ({ className, product, gtmItem, pricing }) => {
  const sendProductClick = useProductClick(gtmItem);
  const { breadcrumbs = [] } = React.useContext(MiniProductGridContext);

  return (
    <footer className={className}>
      <MiniProductPrices pricing={pricing} />
      <CoreCost
        className="text-gray-700"
        coreCost={get(pricing, 'coreCost', get(product, 'coreCost'))}
        message={messages.coreCost}
      />
      <ItemAvailability
        product={product}
        textSize={'text-sm'}
        nearbyButtonSize={TertiaryButton.Size.SMALL}
      />
      <MiniProductAddToCart
        product={product}
        sendProductClick={sendProductClick}
        breadcrumbs={breadcrumbs}
      />
    </footer>
  );
};

MiniProduct.Asset = Asset;
MiniProduct.Body = Body;
MiniProduct.Footer = Footer;

MiniProduct.propTypes = {
  product: PropTypes.shape({
    priceInfo: PropTypes.shape({
      price: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired
      }).isRequired,
      priceType: PropTypes.oneOf(values(PriceType)).isRequired,
      priceTypeDetails: PropTypes.shape(
        values(PriceType).reduce((acc, type) => {
          acc[type] = PropTypes.shape({
            bestPrice: PropTypes.shape({
              amount: PropTypes.number.isRequired,
              currency: PropTypes.string.isRequired
            }).isRequired,
            type: PropTypes.string.isRequired
          });
          return acc;
        }, {})
      )
    }),
    /** The ID of the product */
    id: PropTypes.string.isRequired,
    /** The display name of the product */
    name: PropTypes.string.isRequired,
    primaryAsset: PropTypes.shape({
      altText: PropTypes.string,
      contentUrl: PropTypes.string,
      /** HTML to embed in place of having a source URL */
      embedCode: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
      type: PropTypes.oneOf(values(AssetType))
    }),
    /* the search service will return these 4 fields instead of a `primaryAsset` */
    primaryAssetAltText: PropTypes.string,
    primaryAssetContentUrl: PropTypes.string,
    primaryAssetTags: PropTypes.arrayOf(PropTypes.string),
    primaryAssetTitle: PropTypes.string,
    primaryAssetType: PropTypes.oneOf(values(AssetType)),
    /** The inventory details of the product*/
    availableOnline: PropTypes.bool,
    /** The URI of the details page related to this product */
    uri: PropTypes.string
  }),
  /** Whether grid view is in use or if it's list */
  usingGrid: PropTypes.bool
};

export default MiniProduct;
export { MiniProduct };
