/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { values } from 'lodash';
import PropTypes from 'prop-types';

import { AssetType } from 'app/common/constants';
import { addParams } from 'app/common/utils/PathUtils';

const ChoiceAsset = ({ asset }) => {
  return (
    <figure className="block w-1/3 pr-4 lg:pr-2 xl:pr-4">
      <img
        className="block w-full h-auto mx-auto rounded"
        src={addParams(asset.contentUrl, { thumbnail: true })}
        alt={asset.altText}
        title={asset.title}
        height="96"
        width="96"
      />
    </figure>
  );
};

ChoiceAsset.propTypes = {
  asset: PropTypes.shape({
    altText: PropTypes.string,
    contentUrl: PropTypes.string,
    /** HTML to embed in place of having a source URL */
    embedCode: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    type: PropTypes.oneOf(values(AssetType))
  }).isRequired
};

export default ChoiceAsset;
export { ChoiceAsset };
