/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { OrderReviewHeader, PrimaryButton } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from './ConfirmationHeader.messages';

/**
 * Render component for the order confirmation page's header.
 */
const ConfirmationHeader = ({ emailAddress, loading, order, orderNumber }) => {
  const formatMessage = useFormatMessage();
  const email = emailAddress || order.emailAddress;

  return (
    <OrderReviewHeader
      orderNumber={orderNumber}
      order={order}
      title={messages.title}
    >
      {!isEmpty(email) && (
        <>
          {loading ? (
            <div className="w-full bg-gray-200">&nbsp;</div>
          ) : (
            <p className="text-sm">
              <FormattedMessage
                {...messages.receiptCaveat}
                values={{
                  emailAddress: <b className="font-bold">{email}</b>
                }}
              />
            </p>
          )}
        </>
      )}
      <div className="flex justify-end mt-4 lg:mt-2">
        <PrimaryButton className="print:hidden" to="/">
          {formatMessage(messages.continueShopping)}
        </PrimaryButton>
      </div>
    </OrderReviewHeader>
  );
};

ConfirmationHeader.propTypes = {
  emailAddress: PropTypes.string,
  loading: PropTypes.bool,
  order: PropTypes.shape({
    emailAddress: PropTypes.string,
    orderNumber: PropTypes.string
  }),
  orderNumber: PropTypes.string
};

export default ConfirmationHeader;
export { ConfirmationHeader };
