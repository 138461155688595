/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  billingAddressLabel: {
    id: 'OrderTracking.OrderReview.Payment.address.label.readOnly',
    defaultMessage: 'Billed to'
  },
  paymentMethodLabel: {
    id: 'OrderTracking.OrderReview.Payment.method.label.readOnly',
    defaultMessage: 'Payed using'
  },
  cardNumber: {
    id: 'OrderTracking.OrderReview.Payment.cardNumber',
    defaultMessage: 'Ending in {number}'
  },
  cardExpiration: {
    id: 'OrderTracking.OrderReview.Payment.cardExpiration',
    defaultMessage: 'Expires {expMonth}/{expYear}'
  },
  cardIconAlt: {
    id: 'OrderTracking.OrderReview.Payment.cardIconAlt',
    defaultMessage: 'Card',
    description:
      "If there's no matching card icon for the card number provided, then show this text instead"
  },
  payInStore: {
    id: 'OrderTracking.OrderReview.Payment.pay-in-store',
    defaultMessage: 'Paying In Store at'
  }
});
