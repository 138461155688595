import { useContext, useEffect } from 'react';
import { isEmpty } from 'lodash';
import {
  FETCH_STORES_COMPLETE,
  LOADING_STORES,
  SET_STORES
} from 'app/common/components/ItemAvailability/components/NearestAvailableButton/reducer/nearestInventoryReducer';
import { NearestInventoryContext } from 'app/common/contexts';

/**
 *
 * Hook to handle loading/setting inventory locations. Locations are loaded if the modal is open and
 * shouldLoadStores is true. shouldLoadStores state is modified if the latLong or searchRadius values change.
 * See
 * nearestInventoryReducer#START_FETCH_STORES
 * nearestInventoryCommonReducer#SET_SEARCH_RADIUS
 * nearestInventoryCommonReducer#SET_LAT_LONG.
 *
 * @param open Is the modal open?
 * @param {NearestInventoryState} state The state of the current nearest inventory modal
 * @param dispatch Dispatch function for the nearestInventoryReducer
 * @param {NearestInventoryCommonState} commonState Common/shared state for nearest inventory modals
 * @param handleError Error handler
 * @param loading Is fetch stores in progress?
 * @param response Response from the fetch stores request
 * @param fetchLocations Callback to fetch locations
 */
function useLoadLocations(
  open,
  state,
  dispatch,
  handleError,
  loading,
  response,
  fetchLocations
) {
  const { commonState } = useContext(NearestInventoryContext);

  const { shouldLoadStores } = state;
  const { latLong, searchRadius } = commonState;
  useEffect(() => {
    if (!open || loading || !shouldLoadStores || isEmpty(latLong)) {
      return;
    }

    dispatch(LOADING_STORES(true));
    try {
      fetchLocations({
        params: {
          latitude: latLong.latitude,
          longitude: latLong.longitude,
          radius: searchRadius
        }
      });
    } catch (err) {
      handleError(err, 'FETCH_LOCATIONS');
    } finally {
      dispatch(LOADING_STORES(false));
      dispatch(FETCH_STORES_COMPLETE);
    }
    // eslint-disable-next-line
  }, [
    open,
    latLong,
    loading,
    fetchLocations,
    handleError,
    shouldLoadStores,
    searchRadius
  ]);

  useEffect(() => {
    if (loading) {
      return;
    }
    dispatch(SET_STORES(response));
  }, [response, loading, dispatch]);
}

export { useLoadLocations };
