/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useCallback, useRef, useState } from 'react';
import { isEmpty, throttle } from 'lodash';

import { DecoratedField } from 'app/common/components';
import { useFormatMessage, useWindowEventListener } from 'app/common/hooks';

import CVVHelp from './cvv_help.svg';
import messages from './SecurityCode.messages';
import classNames from 'classnames';

const SecurityCode = ({ activeCardType, disabled = false }) => {
  const formatMessage = useFormatMessage();
  return (
    <DecoratedField
      id="creditCardSecCode"
      hint={formatMessage(messages.cvvHint, { activeCardType })}
      label={formatMessage(messages.cvvLabel)}
      name="creditCardSecCode"
      widths="w-20"
      disabled={disabled}
      tip={Tip}
      required
      validate={value => {
        if (isEmpty(value)) {
          return formatMessage(messages.cvvRequired);
        }
      }}
      autoComplete="cc-csc"
    />
  );
};

const Tip = ({ active = false, containerHeight }) => {
  const selfRef = useRef(null);
  const showAboveRef = useRef(true);
  const [showAbove, setShowAbove] = useState(showAboveRef.current);
  const updateShowAbove = useCallback(
    () =>
      throttle(() => {
        if (!!selfRef.current) {
          const { top, height } = selfRef.current.getBoundingClientRect();
          const header = document.getElementById('checkout-header');
          // make sure not to overlap the header
          const headerHeight = !!header ? header.scrollHeight : 0;
          // add a little padding between the top of the component and the header
          const padding = 8;

          if (showAboveRef.current) {
            showAboveRef.current = top > headerHeight + padding;
          } else {
            showAboveRef.current =
              top > height + headerHeight + containerHeight + 2 * padding;
          }

          setShowAbove(showAboveRef.current);
        }
      }, 100),
    [containerHeight, selfRef, setShowAbove, showAboveRef]
  );
  useWindowEventListener('scroll', updateShowAbove);

  return (
    <div
      ref={selfRef}
      aria-hidden={!active}
      className={classNames(
        'SecurityCode',
        'absolute z-10',
        'w-64 h-64 p-3',
        'bg-white border border-solid border-gray-400 rounded shadow',
        'md:-right-64 md:left-full md:w-64 md:h-64',
        {
          'invisible group-hover:block': !active,
          visible: active,
          'show-above': showAbove
        }
      )}
    >
      <CVVHelp className="w-full h-full" />
    </div>
  );
};

export default SecurityCode;
export { SecurityCode };
