/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { noop, omit } from 'lodash';

import { Environment } from 'app/common/services';
import { isAnonymousCsrToken, isCsrScope, isCsrToken } from 'app/csr/utils';

import DefaultOAuthProvider from './providers/DefaultOAuthProvider';

/**
 * @typedef {Object} AuthServiceProvider
 */

/**
 * @typedef {Object} User
 * @property {string} id - the user's ID
 * @property {string} [fullName] - the full name of the user
 * @property {string} [firstName] - the given name of the user
 * @property {string} [lastName] - the family name of the user
 * @property {string} username - the username of the user
 * @property {string} email - the email of the user
 * @property {Object} [attributes={}] - the set of user attributes
 */

/**
 * @typedef {Object} AuthServiceState
 * @property {string} [clientId] - the client ID
 * @property {boolean} [hasAuthenticated=false] - whether authentication has been checked
 * @property {boolean} [isAuthenticated=false] - whether the user is authenticated
 * @property {boolean} [isCsrAuthenticated=false] - whether authenticated as CSR
 * @property {boolean} [isAuthenticating=false] - whether currently authenticating
 * @property {string} [serverId] - the authorization server ID
 * @property {User} [user] - the current user profile
 * @property {string[]} [userTokens] - the current user profile
 * @property {function} [getAccessToken] - the current user profile
 * @property {boolean} [didAuthenticationCheck=false] - whether an authentication check has happened
 * @property {boolean} [isAnonymousCsr] - whether the current CSR is anonymous
 */

/**
 * @typedef {Object} InitializationConfig
 * @property {string} [clientId] - the client ID
 * @property {string} [serverId] - the authorization server ID
 */

/**
 *
 * @type {Readonly<AuthServiceState>}
 */
export const initialState = Object.freeze({
  clientId: null,
  hasAuthenticated: false,
  isAuthenticated: false,
  isAuthenticating: false,
  isCsrAuthenticated: false,
  serverId: null,
  user: null,
  userTokens: {},
  getAccessToken: noop,
  didAuthenticationCheck: false,
  isAnonymousCsr: false
});

/**
 * Service singleton used for various authentication functions.
 *
 * @author [Nick Crum](https://github.com/ncrum)
 */
class AuthService {
  constructor() {
    if (!AuthService.instance) {
      // the provider that handles the execution of authorization requests
      this.setProvider(DefaultOAuthProvider);

      // a map of promises for tokens by scope
      this._tokenPromises = {};

      /**
       * Any listeners subscribed to changes in the {@link AuthServiceState}
       *
       * @private
       * @type {Array}
       */
      this._listeners = [];

      /**
       * The current {@link AuthServiceState}
       *
       * @private
       * @type {AuthServiceState}
       */
      this._state = { ...initialState };

      AuthService.instance = this;
    }
    return AuthService.instance;
  }

  /**
   * Override the default provider with a custom implementation
   *
   * @param {AuthServiceProvider} nextProvider the custom {@link AuthServiceProvider}
   */
  setProvider(nextProvider) {
    if (typeof nextProvider.initialize !== 'function') {
      throw new Error(
        'Expected authentication provider to implement #initialize function'
      );
    }

    if (typeof nextProvider.getToken !== 'function') {
      throw new Error(
        'Expected authentication provider to implement #getToken function'
      );
    }

    if (typeof nextProvider.getUser !== 'function') {
      throw new Error(
        'Expected authentication provider to implement #getUser function'
      );
    }

    if (typeof nextProvider.getUserExpiration != 'function') {
      throw new Error(
        'Expected authentication provider to implement #getUserExpiration function'
      );
    }

    if (typeof nextProvider.getUserScope !== 'function') {
      throw new Error(
        'Expected authentication provider to implement #getUserScope function'
      );
    }

    if (typeof nextProvider.isLoginRequired !== 'function') {
      throw new Error(
        'Expected authentication provider to implement #isLoginRequired function'
      );
    }

    if (typeof nextProvider.loginWithRedirect !== 'function') {
      throw new Error(
        'Expected authentication provider to implement #loginWithRedirect function'
      );
    }

    if (typeof nextProvider.logout !== 'function') {
      throw new Error(
        'Expected authentication provider to implement #logout function'
      );
    }

    if (typeof nextProvider.changePassword !== 'function') {
      throw new Error(
        'Expected authentication provider to implement #changePassword function'
      );
    }

    this._provider = nextProvider;
  }

  /**
   * Returns the current state.
   *
   * @return {AuthServiceState}
   */
  getState() {
    return this._state;
  }

  /**
   * Returns the current provider.
   *
   * @returns {DefaultOAuthProvider}
   */
  getProvider() {
    return this._provider;
  }

  /**
   * Function used to update the {@link AuthServiceState}.
   *
   * @param  {Function} updater a function that receives current state as parameter and returns the next state
   * @return {AuthServiceState}        the next state
   */
  updateState(updater) {
    if (typeof updater !== 'function') {
      throw new Error('Expected updater to be a function');
    }
    const prevState = this._state;
    const nextState = updater(prevState);

    if (nextState && prevState !== nextState) {
      this._state = nextState;

      this._listeners.forEach(listener => listener(this._state));
    }

    return this._state;
  }

  /**
   * Adds a listener that subscribes to any changes within the {@link AuthServiceState}.
   *
   * ```
   * // subscribe
   * const unsubscribe = AuthService.subscribe(nextState => ...);
   *
   * // unsubscribe
   * unsubscribe();
   * ```
   *
   * @param  {Function} listener the listener
   * @return {Function}          a function that unsubscribes the listener
   */
  subscribe(listener) {
    if (typeof listener !== 'function') {
      throw new Error('Expected listener to be a function');
    }

    let isSubscribed = true;

    const __self = this;
    __self._listeners = [...__self._listeners, listener];
    return function unsubscribe() {
      if (!isSubscribed) {
        return;
      }

      isSubscribed = false;
      __self._listeners = __self._listeners.filter(
        _listener => _listener !== listener
      );
    };
  }

  clearSubscribers() {
    this._listeners = [];
  }

  /**
   * Initializes the session for the current user. Typically this involves verifying
   * the user is authenticated by retrieving a token for the USER scope.
   *
   * @param {InitializationConfig} [config={}] the InitializationConfig
   * @returns {Promise<{referrer: *}>}
   */
  async initialize(config = {}) {
    const { clientId, serverId } = config;

    this.updateState(prevState => ({
      ...prevState,
      clientId,
      serverId,
      isAuthenticating: true,
      didAuthenticationCheck: true
    }));

    const userScope = this.getUserScope();
    try {
      this._tokenPromises[userScope] = this.getProvider().initialize(
        config,
        userScope
      );
      const { referrer, ...token } = await this._tokenPromises[userScope];

      this.storeToken(userScope, token);

      const isCsr = isCsrToken(token);
      const isAnonymousCsr = isCsr && isAnonymousCsrToken(token);
      if (isAnonymousCsr) {
        this.updateState(prevState => ({
          ...prevState,
          hasAuthenticated: true,
          isAuthenticated: false,
          isAuthenticating: false,
          isCsrAuthenticated: true,
          isAnonymousCsr: isAnonymousCsr
        }));
      } else {
        const user = await this.getUser();
        this.updateState(prevState => ({
          ...prevState,
          hasAuthenticated: true,
          isAuthenticated: true,
          isAuthenticating: false,
          isCsrAuthenticated: isCsr,
          isAnonymousCsr: isAnonymousCsr,
          user
        }));
      }

      return { referrer };
    } catch (error) {
      if (this.isLoginRequired() && error.error === 'login_required') {
        this.loginWithRedirect();
      }

      this.updateState(prevState => ({
        ...prevState,
        hasAuthenticated: true,
        isAuthenticated: false,
        isAuthenticating: false,
        userTokens: {}
      }));

      throw error;
    } finally {
      this._tokenPromises[userScope] = null;
    }
  }

  /**
   * Attaches the auth interceptor to the Axios instance and returns a function allowing detachment of the interceptor.
   *
   * ```
   * // attach
   * const detachInterceptor = AuthService.attachInterceptor(axios);
   *
   * // detach
   * detachInterceptor();
   * ```
   *
   * @param  {Axios} axios the Axios instance
   * @return {Function}    function to detach the interceptor
   */
  attachInterceptor(axios) {
    let isAttached = true;
    const __self = this;

    const interceptorId = axios.interceptors.request.use(async config => {
      if (config.secure === false || config.scope === false) {
        // if the request is marked as NOT secure, then return config as is
        return config;
      }

      const { hasAuthenticated, isAuthenticated, isCsrAuthenticated } =
        __self.getState();
      if (
        hasAuthenticated &&
        !isAuthenticated &&
        !(isCsrAuthenticated && isCsrScope(config.scope))
      ) {
        return config;
      }

      // if the request should be secured, then we need a token for either the provided scope, or the user scope
      const scope = config.scope || __self.getUserScope();
      return await this.addBearerToken(config, scope);
    });

    return function detachInterceptor() {
      if (!isAttached) {
        return;
      }

      isAttached = false;
      axios.interceptors.request.eject(interceptorId);
    };
  }

  /**
   * Retrieves and attaches a bearer token header to the provided request configuration.
   *
   * @param {Object} config the request configuration
   * @param {string} scope the scope(s) to retrieve a token for
   * @returns {Promise<object>}
   */
  async addBearerToken(config, scope) {
    try {
      const token = await this.getToken(scope);
      const bearerToken = `${getAuthorizationPrefix()} ${token.access_token}`;
      return {
        ...config,
        headers: {
          ...config.headers,
          [getAuthorizationHeader()]: bearerToken
        }
      };
    } catch (error) {
      if (this.isLoginRequired() && error.error === 'login_required') {
        this.loginWithRedirect();
      }

      return config;
    }
  }

  /**
   * Retrieves a token for the provided scope.
   *
   * @param scope the scope to authorize a token for
   * @returns {Promise<{ access_token: string }>}
   */
  async getToken(scope) {
    const cachedToken = this.getState().userTokens[scope];
    if (!!cachedToken) {
      if (!isTokenExpired(cachedToken)) {
        // if we find an unexpired token, use that
        return cachedToken;
      }

      // if the token was expired, remove from state
      this.storeToken(scope, null);
    }

    // If a USER scope token request is ongoing to check authentication, wait on that to finish
    const userScope = this.getProvider().getUserScope();
    if (!!this._tokenPromises[userScope] && scope !== userScope) {
      await this._tokenPromises[userScope];
    }

    try {
      // If a token request is already out for this scope, then this will wait on
      // that same request to complete. If not, this will initiate a new request.
      if (!this._tokenPromises[scope]) {
        this._tokenPromises[scope] = this.getProvider().getToken(scope);
      }

      const token = await this._tokenPromises[scope];

      this.storeToken(scope, token);

      return token;
    } finally {
      this._tokenPromises[scope] = null;
    }
  }

  /**
   * Helper method used to store a token for a given scope.
   *
   * @param scope the scope
   * @param token the token
   * @returns {*|null}
   */
  storeToken(scope, token) {
    if (!token) {
      this.updateState(prevState => ({
        ...prevState,
        userTokens: omit(prevState.userTokens, [scope])
      }));
      return null;
    }

    token = {
      ...token,
      // set the expiry to the time at which this token will expire
      expiry: token.expires_in
        ? Date.now() + token.expires_in * 1000
        : undefined
    };

    this.updateState(prevState => ({
      ...prevState,
      userTokens: {
        ...prevState.userTokens,
        [scope]: token
      }
    }));

    return token;
  }

  /**
   * Returns a Promise that resolves with the user information.
   *
   * @returns {Promise<{fullName: string, firstName: string, lastName: string, username: string, email: string}>}
   */
  async getUser() {
    return await this.getProvider().getUser();
  }

  /**
   * Returns a Promise that resolves with the user expiration information.
   *
   * @returns {Promise<{exp: string, max: string}>}
   */
  async getUserExpiration() {
    return await this.getProvider().getUserExpiration();
  }

  /**
   * Returns the user scope, typically `USER`.
   *
   * @returns {String}
   */
  getUserScope() {
    return this.getProvider().getUserScope();
  }

  /**
   * Whether or not login is required. This is delegated to the provider, and
   * influences whether an unsuccessful #initialize will cause a login redirect
   * when login is required in order to authenticated.
   *
   * @returns {boolean}
   */
  isLoginRequired() {
    return this.getProvider().isLoginRequired() === true;
  }

  /**
   * Redirects the user to the change password page.
   */
  changePassword() {
    this.getProvider().changePassword();
  }

  forgotPassword() {
    this.getProvider().forgotPassword();
  }

  /**
   * Redirects the user to the login page.
   */
  loginWithRedirect() {
    this.getProvider().loginWithRedirect();
  }

  async loginWithCredentials(request, setSubmitting, setLoginErrorMessage) {
    return await this.getProvider().loginWithCredentials(
      request,
      setSubmitting,
      setLoginErrorMessage
    );
  }

  /**
   * Logs out the user.
   *
   * @param {boolean} [redirectToLogin=false] - Whether to redirect to login
   *     rather than reloading the location.
   */
  logout(redirectToLogin = false) {
    // Clear set preferred store cookie, so user will have it set when they login again
    // See CustomerProvider#shouldSetPreferredStore
    document.cookie = 'blc-set-preferred-store=;path=/;max-age=0';
    this.getProvider().logout(redirectToLogin);
  }
}

function getAuthorizationHeader() {
  return Environment.get('auth.headers.authorization', 'Authorization');
}

function getAuthorizationPrefix() {
  return Environment.get('auth.headers.authorization.prefix', 'Bearer');
}

function isTokenExpired(token) {
  return token.expiry && token.expiry <= now();
}

/**
 * @return {number} the current time using Date.now
 */
function now() {
  if (!Date.now) {
    return new Date().getTime();
  }

  return Date.now();
}

const instance = new AuthService();

export function getInstance() {
  return instance;
}

export default getInstance();
