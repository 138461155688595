/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { get, isNumber } from 'lodash';

/**
 * @typedef {Object} LatLong Represents a latitude/longitude pair
 * @property {number} latitude The latitude
 * @property {number} longitude The longitude
 */

/**
 *
 * @param {Function} errorCallback Callback to handle errors
 * @param {string} errorCallback.errorCode The error code string
 * @param {Function} successCallback Callback to handle successful geolocation
 * @param {GeolocationPosition} successCallback.position The geolocation position
 * @param {Object} options Optional. Additional options to pass to the geolocation API
 */
function requestGeolocation({
  successCallback,
  errorCallback,
  options = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 30000
  }
}) {
  if (!navigator.geolocation) {
    errorCallback('GEOLOCATION_NOT_SUPPORTED');
    return;
  }
  navigator.geolocation.getCurrentPosition(
    successCallback,
    positionError => {
      let geolocationErrorCode = '';
      switch (get(positionError, 'code')) {
        case 1:
          geolocationErrorCode = 'GEOLOCATION_PERMISSION_DENIED';
          break;
        case 2:
          geolocationErrorCode = 'GEOLOCATION_POSITION_UNAVAILABLE';
          break;
        case 3:
          geolocationErrorCode = 'GEOLOCATION_TIMEOUT';
          break;
        default:
          geolocationErrorCode = 'GEOLOCATION_UNKNOWN';
      }
      errorCallback(geolocationErrorCode);
    },
    options
  );
}

/**
 * Are the two lat/long objects equal? Accepts nulls.
 * @param {LatLong} latLong1
 * @param {LatLong} latLong2
 * @return {boolean} True if the lat/longs are equal, false otherwise
 */
function isLatLongEqual(latLong1, latLong2) {
  const lat1 = get(latLong1, 'latitude');
  const long1 = get(latLong1, 'longitude');
  const lat2 = get(latLong2, 'latitude');
  const long2 = get(latLong2, 'longitude');
  return lat1 === lat2 && long1 === long2;
}

/**
 * Is the lat/long object valid? A valid lat/long object has a latitude and longitude that are both numbers.
 * @param {LatLong} latLong
 * @return {boolean} True if the lat/long is valid, false otherwise
 */
function isLatLongValid(latLong) {
  const lat = get(latLong, 'latitude');
  const long = get(latLong, 'longitude');
  return isNumber(lat) && isNumber(long);
}

export { requestGeolocation, isLatLongEqual, isLatLongValid };
