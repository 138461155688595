import React from 'react';
import classNames from 'classnames';

import { useFormatMessage } from 'app/common/hooks';

import messages from './GetDirectionsButton.messages';

const GetDirectionsButton = ({ className, application }) => {
  const formatMessage = useFormatMessage();
  const GOOGLE_MAPS_DIRECTION_BASE_URL =
    'https://www.google.com/maps/search/?api=1';
  const fullUrl =
    GOOGLE_MAPS_DIRECTION_BASE_URL +
    '&query=' +
    encodeURI(
      [
        application.line1,
        application.city,
        application.state,
        application.zip
      ].join(' ')
    );

  return (
    <a
      href={fullUrl}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(
        'bg-primary-700 hover:bg-primary-800 active:bg-primary-900 shadow focus:shadow-outline ',
        'text-white inline-flex items-center justify-center capitalize font-bold leading-none',
        'appearance-none text-center',
        'focus:outline-none disabled:cursor-not-allowed rounded',
        {
          [className]: !!className
        }
      )}
    >
      {formatMessage(messages.title)}
    </a>
  );
};

export default GetDirectionsButton;
export { GetDirectionsButton };
