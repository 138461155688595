/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import { CheckoutButton, TertiaryButton } from 'app/common/components';
import { CartContext } from 'app/common/contexts';
import { useFormatMessage } from 'app/common/hooks';

import messages from './DialogActions.messages';

/**
 * Render component for the mini shopping cart dialog actions.
 *
 * @visibleName Minicart: Dialog: Actions
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const DialogActions = () => {
  const { toggleMinicartOpen } = React.useContext(CartContext);
  const formatMessage = useFormatMessage();
  return (
    <footer className="w-full border-t border-gray-200">
      <div className="flex items-center justify-between px-4 py-2">
        <TertiaryButton
          onClick={() => {
            toggleMinicartOpen(false);
          }}
          to="/cart"
        >
          {formatMessage(messages.viewCartLabel)}
        </TertiaryButton>
        <CheckoutButton>{formatMessage(messages.checkoutLabel)}</CheckoutButton>
      </div>
    </footer>
  );
};

export default DialogActions;
export { DialogActions };
