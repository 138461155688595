/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { cloneDeep, get, isEqual, isNil } from 'lodash';
import TagManager from 'app/common/tagmanager';

import { useLocation } from 'app/common/hooks';

function useGtmPageView(pageTitle, addlData = undefined, wait = false) {
  const location = useLocation();
  const pathname = get(location, 'pathname');
  const search = get(location, 'search');
  const previousPathname = React.useRef(undefined);
  const previousTitle = React.useRef(undefined);
  const previousSearch = React.useRef(undefined);
  const previousAddlData = React.useRef(undefined);
  const previousDataLayer = React.useRef(undefined);
  const [dataLayer, setDataLayer] = React.useState(undefined);

  React.useEffect(() => {
    const didPathChange = previousPathname.current !== pathname;
    const didTitleChange = previousTitle.current !== pageTitle;
    const didSearchChange = previousSearch.current !== search;
    const didAddlDataChange = !isEqual(previousAddlData.current, addlData);

    if (wait) {
      return;
    }

    if (didPathChange && !didTitleChange) {
      return;
    }

    if (didSearchChange && !didAddlDataChange && !isNil(addlData)) {
      return;
    }

    previousPathname.current = pathname;
    previousTitle.current = pageTitle;
    previousAddlData.current = addlData;
    previousSearch.current = search;

    const url = `${pathname}${search}`;

    const d = {
      event: 'pageview',
      url,
      pageTitle,
      ...addlData
    };

    setDataLayer(prev => (isEqual(prev, d) ? prev : d));
  }, [addlData, pathname, search, pageTitle, wait]);

  React.useEffect(() => {
    if (!isNil(dataLayer) && !isEqual(previousDataLayer.current, dataLayer)) {
      // seems to cause an infinite loop without deep cloning
      TagManager.dataLayer(cloneDeep(dataLayer));
    }
  }, [dataLayer]);
}

export default useGtmPageView;
