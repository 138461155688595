/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';

import { Environment } from 'app/common/services';

function useSearchInfo() {
  return useMemo(
    () => ({
      baseUrl: Environment.get('SEARCH_BASE_URL', 'catalog-browse/search'),
      catalogUri: Environment.get('SEARCH_CATALOG_ENDPOINT', '/catalog'),
      searchResultsPageSize: Environment.getInteger(
        'SEARCH_RESULTS_PAGE_SIZE',
        15
      ),
      searchResultsDefaultSort: Environment.get(
        'SEARCH_RESULTS_DEFAULT_SORT',
        undefined
      ),
      suggestUri: Environment.get('SEARCH_CATALOG_SUGGEST_URI', '/suggest'),
      suggestProductConfig: Environment.get(
        'SEARCH_CATALOG_SUGGEST_PRODUCT_CONFIG',
        'productTypeAhead'
      )
    }),
    []
  );
}

export default useSearchInfo;
