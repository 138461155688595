/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

import { DecoratedField, SelectField } from 'app/common/components';
import { useStateProvinceRegionSelectValues } from 'app/common/hooks';

/**
 * Render component for the address form's `stateProvinceRegion` select
 * component. Takes in standard props for a `<DecoratedField>` in addition to
 * formik props.
 *
 * StateProvinceRegion will be automatically filtered by the current value of
 * `country`.
 */
const StateProvinceRegion = ({ country, ...props }) => {
  const stateProvinceRegionSelectValues = useStateProvinceRegionSelectValues();
  return (
    <DecoratedField
      {...props}
      component={SelectField}
      valueOptions={stateProvinceRegionSelectValues[country]}
    />
  );
};

StateProvinceRegion.propTypes = {
  /** Value of the country field. Used to drive selectable values */
  country: PropTypes.string.isRequired
};

export default StateProvinceRegion;
export { StateProvinceRegion };
