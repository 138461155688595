/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { has, join, words } from 'lodash';
import PropTypes from 'prop-types';

import { useToggle } from 'app/common/hooks';

/**
 * Render component to display an error stack trace.
 *
 * @visibleName Error Trace
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const ErrorTrace = ({ error }) => {
  const [isActive, toggleActive] = useToggle(true);
  const title = getErrorTitle(error);
  const exception = getExceptionTrace(error);
  const message = getErrorMessage(error);

  return (
    <section className="py-12 px-6">
      <details
        className={classNames('container bg-red-100 rounded', {
          'is-active': isActive
        })}
        open={isActive}
      >
        <summary
          className="relative flex items-center justify-start py-3 px-4 text-gray-100 text-2xl font-medium bg-red-600 rounded-t"
          onClick={() => toggleActive()}
        >
          <h3 className="font-xl font-bold">{title}</h3>
        </summary>
        <div className="py-5 px-6 text-gray-800 border-b-4 border-red-400 rounded">
          <p className="font-semibold">{message}</p>
          {!!exception && (
            <pre className="font-mono bg-red-100">{exception}</pre>
          )}
        </div>
      </details>
    </section>
  );
};

ErrorTrace.propTypes = {
  /** Object representing the error */
  error: PropTypes.oneOfType([
    PropTypes.shape({
      columnNumber: PropTypes.number,
      fileName: PropTypes.string,
      lineNumber: PropTypes.number,
      message: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      stack: PropTypes.string
    }),
    PropTypes.shape({
      /**
       * A machine-readable representation of the type of error being returned
       */
      type: PropTypes.string,
      /**
       * A human-readable representation of the type of error being returned
       */
      title: PropTypes.string.isRequired,
      /**
       * Stack trace
       */
      exception: PropTypes.string,
      /**
       * When the error occurred
       */
      timestamp: PropTypes.string,
      /**
       * Should always match exactly the status in the response header.
       */
      status: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          value: PropTypes.number,
          reasonPhrase: PropTypes.string
        })
      ]),
      /**
       * Numerical representation of `status`
       */
      statusCode: PropTypes.number,
      /**
       * Gives additional information about what was wrong with the request.
       */
      globalErrors: PropTypes.array
    }),
    PropTypes.object
  ]).isRequired
};

function getErrorTitle(error) {
  if (has(error, 'error')) {
    return error.error;
  }

  if (has(error, 'status.reasonPhrase')) {
    return error.status.reasonPhrase;
  }

  if (has(error, 'status')) {
    return join(words(error.status), ' ');
  }

  return error.name;
}

function getErrorMessage(error) {
  if (has(error, 'message')) {
    return error.message;
  }

  return error.title;
}

function getExceptionTrace(error) {
  if (has(error, 'exception')) {
    return error.exception;
  }

  if (has(error, 'stack')) {
    return error.stack;
  }

  return null;
}

export default ErrorTrace;
export { ErrorTrace };
