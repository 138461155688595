/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { createContext } from 'react';
import { noop } from 'lodash';

/**
 * @typedef {Object} Cart
 * @property {string} id - the ID
 * @property {string} name - the name of the cart
 * @property {string} status - the status of the cart
 * @property {CustomerRef} customerRef - the customer reference
 * @property {string} emailAddress - the email address of the customer
 * @property {Object} locale - the locale of the cart
 * @property {CartPricing} cartPricing - the pricing of the cart
 * @property {CartItem[]} cartItems - the items in the cart
 * @property {string[]} offerCodes - the offer codes on the cart
 * @property {Adjustment[]} adjustments - the adjustments on the cart
 */

/**
 * @typedef {Object} CartItem
 * @property {string} id - the ID
 * @property {string} name - the name of the item
 * @property {string} uri - the URI of the item
 * @property {number} quantity - the quantity of the item
 * @property {MonetaryAmount} adjustmentsTotal - the adjustments total of the item
 * @property {MonetaryAmount} subtotal - the subtotal of the item
 * @property {MonetaryAmount} total - the total of the item
 * @property {string} variantId - the variant ID of the item
 * @property {string} productId - the product ID of the item
 * @property {string} sku - the SKU of the item
 * @property {string[]} categoryIds - the category IDs of the item
 */

/**
 * @typedef {Object} CartPricing
 * @property {string} currency - the currency of the item
 * @property {MonetaryAmount} totalTax - the total tax of the items
 * @property {MonetaryAmount} fulfillmentTotal - the fulfillment total of the items
 * @property {MonetaryAmount} subtotal - the subtotal of the items
 * @property {MonetaryAmount} adjustmentsTotal - the adjustments total of the items
 * @property {MonetaryAmount} total - the total of the items
 */

/**
 * @typedef {Object} CustomerRef
 * @property {string} customerId - the customer ID
 * @property {string} username - the username of the customer
 * @property {string} fullName - the full name of the customer
 */

/**
 * @typedef {Object} MonetaryAmount
 * @property {number} amount - the amount
 * @property {string} currency - the currency
 */

/**
 * @typedef {Object} Adjustment
 * @property {Object} offerRef - the offer reference
 * @property {string} offerCodeRef - the offer code reference
 * @property {MonetaryAmount} amount - the amount of the adjustment
 */

/**
 * @typedef {Object} CartContext
 * @property {Cart} cart - the current cart
 */
const defaultValue = Object.freeze({
  /**
   * @type {Cart}
   */
  cart: undefined,
  setCart: noop,
  isMinicartOpen: false,
  toggleMinicartOpen: noop,
  hasItemConfigErrors: false,
  itemConfigErrors: undefined,
  hasItemAttributeErrors: false,
  itemAttributeErrors: undefined,
  resolving: false,
  isLocked: false,
  setIsLocked: noop,
  cartOperationException: undefined,
  setCartOperationException: noop,
  cartMessages: undefined,
  putCartMessage: noop,
  getCartMessage: noop,
  removeCartMessage: noop,
  guestToken: undefined,
  setGuestToken: noop
});

const CartContext = createContext(defaultValue);

CartContext.defaultValue = defaultValue;

export default CartContext;
