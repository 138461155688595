/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  phoneRequired: {
    id: 'Address.phone.validation.required',
    defaultMessage: '{fieldName} is required'
  },
  phoneFormat: {
    id: 'Address.phone.validation.format',
    defaultMessage:
      'Number must be a valid format, e.g., 100-100-1000, 1001001000'
  },
  phoneComposition: {
    id: 'Address.phone.validation.composition',
    defaultMessage: 'Number may only contain numbers, -, +, (, ), or spaces'
  }
});
