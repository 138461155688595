/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty } from 'lodash';

import {
  ItemChoicePricingModel,
  ItemChoiceTargetType,
  PriceType
} from 'app/common/constants';

/**
 * Determines `pricing` for a `<Choice>`.
 *
 * @param {{}} choice - A `Product` from `Category` or `SpecificItemChoice`.
 * @param {{}} itemChoice - The parent `ItemChoice`
 *
 * @return {{
 *   price: {amount: number, currency: string}|undefined,
 *   isSale: boolean,
 *   isIncludedInParent: boolean
 * }}
 */
function useDeterminePricing(choice, itemChoice) {
  return React.useMemo(() => {
    const pricingModel = choice.pricingModel || itemChoice.pricingModel;
    const isIncludedInParent =
      pricingModel === ItemChoicePricingModel.INCLUDED_IN_PARENT;

    if (pricingModel !== ItemChoicePricingModel.ADD_TO_PARENT) {
      return { isIncludedInParent, isSale: false, price: undefined };
    }

    if (
      itemChoice.targetType === ItemChoiceTargetType.CATEGORY &&
      !isEmpty(itemChoice.priceInfo)
    ) {
      return {
        isIncludedInParent,
        isSale: itemChoice.priceInfo.priceType === PriceType.SALE_PRICE,
        price: itemChoice.priceInfo.price
      };
    }

    return {
      isIncludedInParent,
      isSale: choice.priceInfo.priceType === PriceType.SALE_PRICE,
      price: choice.priceInfo.price
    };
  }, [choice, itemChoice]);
}

export default useDeterminePricing;
