import React from 'react';
import { useFormatMessage } from 'app/common/hooks';
import messages from './BrandsComponents.messages.js';
import { Link } from 'app/common/components';
import { capitalizeBrand } from '../utils';
import { useBrandsCategoryUrlParams } from '../hooks';

const BrandList = ({ brandsMap }) => {
  return (
    <section className="mt-8">
      {Object.entries(brandsMap).map((entry, i) => (
        <BrandSection sectionKey={entry[0]} brandList={entry[1]} key={i} />
      ))}
    </section>
  );
};

const BrandSection = ({ sectionKey, brandList }) => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <div
        id={sectionKey}
        className={
          'flex justify-between border-solid border-2 border-blue-900 p-2 brands-section '
        }
      >
        <span className="brands-sectionKey text-primary-500 font-semibold text-lg">
          {sectionKey}
        </span>
        <span className="brands-back-to-top mr-4">
          <a
            href="#"
            className="text-primary-500 text-sm underline font-semibold"
          >
            {formatMessage(messages.backToTop)}
          </a>
        </span>
      </div>
      <div className={'brands-section-list grid gap-x-8 gap-y-4 mb-8 mt-8'}>
        {brandList.map((entry, i) => (
          <BrandLink entry={entry} key={i} />
        ))}
      </div>
    </>
  );
};

/**
 *
 * @param {MinimalBrand} entry
 * @returns {Element}
 * @constructor
 */
const BrandLink = ({ entry }) => {
  const { brandName, brandsPath } = entry;
  const { categoryUrl, subcategoryUrl } = useBrandsCategoryUrlParams();

  return (
    <Link
      to={`${brandsPath}${subcategoryUrl ? subcategoryUrl : categoryUrl}`}
      className={'text-primary-400 underline px-2'}
    >
      {capitalizeBrand(brandName)}
    </Link>
  );
};

export default BrandList;
export { BrandList };
