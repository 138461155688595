/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
/**
 * Describes restrictions placed on the customer's ability to select items from
 * the targeted set. Generally, this is a restriction on how many choices can be
 * selected for a single add-to-cart request and, potentially, how quantity is
 * distributed among multiple selected choices.
 *
 * @type {{CHOOSE_ONE: string, CHOOSE_MULTIPLE: string}}
 */
const ItemChoiceSelectionType = Object.freeze({
  /**
   * The customer can only choose one of the specified target items. All
   * quantity conditions (whether variable or fixed) will only be applied to the
   * selected choice so that only 1 choice will be included in the add-to-cart
   * request.
   */
  CHOOSE_ONE: 'CHOOSE_ONE',
  /**
   * The customer can choose multiple of the specified target items. Quantity
   * can be spread across multiple options leading to multiple of the choices
   * being added to cart at once.
   */
  CHOOSE_MULTIPLE: 'CHOOSE_MULTIPLE'
});

export default ItemChoiceSelectionType;
