import { range } from 'lodash';

/**
 * Renders the skeleton for the nearby store rows. This is used when the nearby stores are loading.
 * @returns {Element}
 * @constructor
 */
const NearbyStoreRowsSkeleton = ({ rows = 3 }) => {
  return (
    <>
      {range(rows).map((location, index) => {
        return <StoreLocationRowSkeleton key={index} />;
      })}
    </>
  );
};

/**
 * Skeleton component for the store location row. This is used to render a skeleton while the store
 * locations are loading.
 * @returns {Element}
 * @constructor
 */
const StoreLocationRowSkeleton = () => {
  return (
    <div className={'flex store-dropdown-row w-full pt-4 pb-4'}>
      <div className={'flex flex-row w-full'}>
        <div
          className={
            'flex flex-col sm:flex-row store-dropdown-address-hours w-full sm:w-3/4 text-sm pr-2'
          }
        >
          <div className={'flex flex-col sm:w-2/3 sm:pr-2'}>
            <div
              className={
                'flex bg-gray-200 rounded-full dark:bg-gray-700 max-w-12 h-2 mb-2'
              }
            ></div>
            <div
              className={
                'flex bg-gray-200 rounded-full dark:bg-gray-700  max-w-12 h-2 mb-2'
              }
            ></div>
            <div
              className={
                'flex bg-gray-200 rounded-full dark:bg-gray-700 max-w-12 h-2 mb-2'
              }
            ></div>
            <div
              className={
                'flex bg-gray-200 rounded-full dark:bg-gray-700 max-w-12 h-2 mb-2'
              }
            ></div>
          </div>
        </div>
        <div
          className={
            'flex w-auto sm:w-1/4 justify-end  bg-gray-200 rounded dark:bg-gray-700 max-w-12 h-full mb-2'
          }
        ></div>
      </div>
    </div>
  );
};

export { NearbyStoreRowsSkeleton };
