/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import messages from './IsoCountries.messages';

const IsoCountries = Object.freeze({
  CA: { alpha2: 'CA', alpha3: 'CAN', numericCode: 124, name: messages.ca },
  MX: { alpha2: 'MX', alpha3: 'MEX', numericCode: 484, name: messages.mx },
  US: { alpha2: 'US', alpha3: 'USA', numericCode: 840, name: messages.us }
});

export default IsoCountries;
