/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext } from 'react';
import { map } from 'lodash';

import { AddressForm, Button } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from '../../AuthenticatedAddressForm.messages';
import { UserAddress } from './components';
import { CheckoutContext } from 'app/checkout/contexts';
import { setSelectedAddress } from 'app/checkout/contexts/reducers/checkoutReducer';

const UserAddresses = props => {
  const { customerAddresses, setUseSavedAddress, type } = props;
  const formatMessage = useFormatMessage();
  const { checkoutDispatch, selectedAddress } = useContext(CheckoutContext);

  return (
    <>
      <div className="mb-4 text-gray-900 font-bold lg:text-lg">
        {formatMessage(messages.chooseSaved, { type })}
      </div>

      {map(customerAddresses, (customerAddress, index) => (
        <UserAddress
          key={index}
          address={customerAddress}
          addressName={customerAddress.name}
          isDefault={
            (type === AddressForm.Type.FULFILLMENT &&
              customerAddress.defaultShippingAddress) ||
            (type === AddressForm.Type.BILLING &&
              customerAddress.defaultBillingAddress)
          }
          isSelected={selectedAddress === customerAddress}
          onSelected={address => setSelectedAddress(checkoutDispatch, address)}
        />
      ))}

      <Button
        type="button"
        className="w-full mb-4 p-4 text-gray-900 bg-white text-left rounded shadow hover:bg-gray-200 focus:outline-none focus:shadow-outline"
        onClick={() => {
          setSelectedAddress(checkoutDispatch, undefined);
          setUseSavedAddress(false);
        }}
      >
        {formatMessage(messages.useDifferentAddress)}
      </Button>
    </>
  );
};

export default UserAddresses;
export { UserAddresses };
