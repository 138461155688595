/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useCallback, useMemo, useState } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

import { useDelayHover, useOnEventOutside } from 'app/common/hooks';
import DropdownContext from './context/DropdownContext';
import DropdownMenu from './components/DropdownMenu';

/**
 * The Dropdown component used for displaying list of menu items.
 * The basic use example:
 *
 * ```js
 * <Dropdown>
 *  <Dropdown.Menu.Trigger label='Open Menu'/>
 *  <Dropdown.Menu>
 *    <Dropdown.Menu.Item onClick={() => {}}>Item 1</Dropdown.Menu.Item>
 *    <Dropdown.Menu.Item onClick={() => {}}>Item 2</Dropdown.Menu.Item>
 *    <Dropdown.Menu.Divider/>
 *    <Dropdown.Menu.Item onClick={() => {}}>Item 3</Dropdown.Menu.Item>
 *  </Dropdown.Menu>
 * </Dropdown>
 * ```
 */
const Dropdown = ({
  children,
  className,
  disabled,
  handleTrigger,
  hoverable,
  style,
  alwaysOpen = false
}) => {
  const [isOpen, setIsOpen] = useState(alwaysOpen);
  const toggleOpen = useCallback(
    val => !disabled && setIsOpen(val),
    [disabled, setIsOpen]
  );
  const containerRef = useOnEventOutside(() => toggleOpen(false), 'click');
  const handleTriggerInternal = React.useCallback(
    (e, openOverride) => {
      !!e && e.preventDefault();
      !!e && e.stopPropagation();
      !!handleTrigger && handleTrigger(e);

      if (alwaysOpen) {
        toggleOpen(true);
      } else if (openOverride === undefined) {
        toggleOpen(prevIsOpen => !prevIsOpen);
      } else {
        toggleOpen(openOverride);
      }
    },
    // eslint-disable-next-line
    [handleTrigger, toggleOpen]
  );
  const { handleMouseEnter, handleMouseLeave } = useDelayHover(
    isOpen,
    handleTriggerInternal,
    undefined,
    undefined,
    true
  );
  const context = useMemo(
    () => ({ isOpen, toggleOpen, handleTrigger: handleTriggerInternal }),
    [isOpen, toggleOpen, handleTriggerInternal]
  );

  return (
    <DropdownContext.Provider value={context}>
      <div
        className={className}
        onMouseEnter={e => {
          if (hoverable) {
            handleMouseEnter(e, true);
          }
        }}
        onMouseLeave={e => {
          if (hoverable) {
            handleMouseLeave(e, false);
          }
        }}
        ref={containerRef}
        style={style}
      >
        {children}
      </div>
    </DropdownContext.Provider>
  );
};

Dropdown.Menu = DropdownMenu;

Dropdown.propTypes = {
  /** The class name for the dropdown wrapper */
  className: PropTypes.string,
  /** Whether or not the list grid is disabled */
  disabled: PropTypes.bool,
  /**
   * Handler for when the Trigger component is triggered. Passed in to the
   * Dropdown instead of the Trigger to allow the method to be called when the
   * Dropdown is hovered.
   */
  handleTrigger: PropTypes.func,
  /** Whether to trigger the dropdown on hover */
  hoverable: PropTypes.bool,
  style: PropTypes.object,
  /** The dropdown should always be open. Useful for debugging */
  alwaysOpen: PropTypes.bool
};

Dropdown.defaultProps = {
  disabled: false,
  handleTrigger: noop,
  hoverable: false,
  alwaysOpen: false
};

export default Dropdown;
export { Dropdown };
