/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Currency } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from './ChoicePrice.messages';

const ChoicePrice = ({ pricing }) => {
  const formatMessage = useFormatMessage();
  const { isIncludedInParent, isSale, price } = pricing;

  if (isIncludedInParent) {
    return (
      <div className="text-gray-900">
        {formatMessage(messages.includedInParent)}
      </div>
    );
  }

  return (
    <Currency
      className={classNames('block', {
        'text-gray-900': !isSale,
        'text-red-600': isSale
      })}
      {...price}
    />
  );
};

ChoicePrice.propTypes = {
  pricing: PropTypes.shape({
    /** if not included in parent's price */
    price: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired
    }),
    isSale: PropTypes.bool.isRequired,
    isIncludedInParent: PropTypes.bool.isRequired
  }).isRequired
};

export default ChoicePrice;
export { ChoicePrice };
