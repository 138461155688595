/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useCallback, useEffect, useRef } from 'react';

/**
 * Hook used to manage state based on a DOM mouse event.
 * This will create a `ref` that can be placed on a container component so that
 * whenever the `triggerEvent` occurs a callback (`onEventOutside`) will be
 * triggered.
 * This is typically used to toggle state on the container such as to collapse a
 * dropdown when a click occurs outside of it.
 *
 * @param {function} onEventOutside function to trigger when the `triggerEvent`
 *     occurs
 * @param {string} triggerEvent the mouse event to listen (click, mouseup,
 *     mousedown)
 * @return {React.MutableRefObject<null>}
 */
function useOnEventOutside(onEventOutside, triggerEvent = 'click') {
  const containerRef = useRef(null);
  const handleClickOutside = useCallback(
    e => {
      if (!!containerRef.current && !containerRef.current.contains(e.target)) {
        // click is outside the nav
        onEventOutside(e);
      }
    },
    [containerRef, onEventOutside]
  );

  useEffect(() => {
    document.addEventListener(triggerEvent, handleClickOutside, false);

    return () => {
      document.removeEventListener(triggerEvent, handleClickOutside, false);
    };
  }, [triggerEvent, handleClickOutside]);

  return containerRef;
}

export default useOnEventOutside;
