/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  partNumber: {
    id: 'DefaultPDP.Header.partNumber',
    defaultMessage: 'Part #'
  },
  weightLabel: {
    id: 'DefaultPDP.Header.weight.label',
    defaultMessage: 'Weight:'
  },
  weightValue: {
    id: 'DefaultPDP.Header.weight.value',
    defaultMessage:
      '{weight, number} {units, select, POUNDS {lb} KILOGRAMS {kg} OUNCES {oz} GRAMS {g} other { } }'
  },
  height: {
    id: 'DefaultPDP.Header.height.label',
    defaultMessage: 'Height:'
  },
  width: {
    id: 'DefaultPDP.Header.width.label',
    defaultMessage: 'Width:'
  },
  depth: {
    id: 'DefaultPDP.Header.depth.label',
    defaultMessage: 'Depth:'
  },
  dimensionValue: {
    id: 'DefaultPDP.Header.dimension.value',
    defaultMessage:
      '{dimension, number} {units, select, MILLIMETERS {mm} CENTIMETERS {cm} INCHES {in} FEET {ft} METERS {m} other { } }'
  },
  interchangeParts: {
    id: 'DefaultPDP.Header.interchangeParts',
    defaultMessage: 'Interchange Part #'
  },
  toggleTruncateInterchangeParts: {
    id: 'DefaultPDP.Header.interchangeParts.toggleTruncation',
    defaultMessage:
      '{truncateInterchange, select, false {Show less} other {Show more}}'
  },
  unavailable: {
    id: 'DefaultPDP.Header.unavailable',
    defaultMessage: 'This product is no longer available.'
  }
});
