/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { map, values } from 'lodash';
import PropTypes from 'prop-types';

import { AssetType } from 'app/common/constants';

import { AlternateAsset } from './components';

/**
 * Render component for a list of assets on the PDP page to be used as
 * alternates to the primary.
 *
 * @visibleName Product Detail Page: Assets: Alternates
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const AlternateAssets = ({ activeAsset, assets, setActiveAsset }) => (
  <div className="mt-4">
    <ol className="flex flex-wrap -m-1 list-none">
      {map(assets, asset => {
        const { id } = asset;
        return (
          <AlternateAsset
            asset={asset}
            key={`asset-${id}`}
            isActive={activeAsset === id}
            setActiveAsset={setActiveAsset}
          />
        );
      })}
    </ol>
  </div>
);

AlternateAssets.propTypes = {
  /** The ID of the active Asset */
  activeAsset: PropTypes.string.isRequired,
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      altText: PropTypes.string,
      contentUrl: PropTypes.string.isRequired,
      /** HTML to embed in place of having a source URL */
      embedCode: PropTypes.string,
      id: PropTypes.string.isRequired,
      productId: PropTypes.string,
      primary: PropTypes.bool,
      tags: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
      type: PropTypes.oneOf(values(AssetType))
    })
  ).isRequired,
  /** Method to change the active Asset */
  setActiveAsset: PropTypes.func.isRequired
};

export default AlternateAssets;
export { AlternateAssets };
