/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useState, useMemo } from 'react';

import { CartItemErrorContext } from 'app/cart/context';

/**
 * Provisions the CartItemErrorContext for the {@link CartItem}.
 */
const CartItemErrorContextProvider = props => {
  const [exception, setException] = useState();
  const context = useMemo(
    () => ({
      exception,
      setException
    }),
    [exception, setException]
  );
  return (
    <CartItemErrorContext.Provider value={context}>
      {props.children}
    </CartItemErrorContext.Provider>
  );
};

export default CartItemErrorContextProvider;
