/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';

import { Environment } from '../../../services';

function usePricingInfo() {
  return useMemo(
    () => ({
      priceContextHeader: Environment.get(
        'PRICE_CONTEXT_HEADER',
        'X-Price-Context'
      ),
      priceInfoContextHeader: Environment.get(
        'PRICE_INFO_CONTEXT_HEADER',
        'X-Price-Info-Context'
      ),
      priceDataInfo: {
        fetchUrl: Environment.get('PRICE_DATA_ENDPOINT', 'pricing/price-data')
      },
      priceInfoInfo: {
        fetchUrl: Environment.get('PRICE_INFOS_ENDPOINT', 'pricing/price-infos')
      },
      priceListInfo: {
        fetchAllUrl: Environment.get(
          'PRICE_LISTS_ENDPOINT',
          'pricing/price-lists'
        ),
        fetchStandardsUrl: Environment.get(
          'STANDARD_PRICE_LISTS_ENDPOINT',
          'pricing/price-lists/standard'
        ),
        fetchSalesUrl: Environment.get(
          'SALE_PRICE_LISTS_ENDPOINT',
          'pricing/price-lists/sale'
        ),
        fetchContractsUrl: Environment.get(
          'CONTRACT_PRICE_LISTS_ENDPOINT',
          'pricing/price-lists/contract'
        ),
        fetchPriceDataContextPath: Environment.get(
          'PRICE_LISTS_PRICE_DATA_CONTEXT_PATH',
          'pricing/price-data'
        )
      }
    }),
    []
  );
}

export default usePricingInfo;
