import {
  APP_LOADED,
  CLOSE_AUTO_COMPLETE,
  CLOSE_PREFERRED_STORE_MODAL,
  HANDLE_ADDRESS_SELECT,
  LOADING_LOCATIONS_ACTION,
  LOADING_LOCATIONS_COMPLETE_ACTION,
  OPEN_AUTO_COMPLETE,
  OPEN_PREFERRED_STORE_MODAL,
  RESET_ERRORS,
  SET_AUTO_COMPLETE_RESULTS,
  SET_CURRENT_PREFERRED_STORE,
  SET_ERROR,
  SET_NEW_PREFERRED_STORE,
  SET_QUERY,
  SET_STATE,
  SET_STORES,
  SET_USING_LOC_COORDS
} from './preferredStoreActions';

/**
 *
 * @typedef {Object} PreferredModalState
 * @property {{q: string, skipApiCall: boolean}} query - The current query string in store search input
 * @property {Array} stores - The list of stores to display in the store search dropdown
 * @property {Array} autoCompleteResults - The autocomplete address suggestions
 * @property {Object} userSelectedAddress - The user's selected address, chosen from an autoCompleteResult.
 * @property {boolean} preferredStoreModalOpen - Is the preferred store modal open?
 * @property {boolean} autoCompleteOpen - Is the autocomplete suggestions popover open?
 * @property {{lat: number, lng: number}} usingLocCoords - The user's lat/long if using browser location
 * @property {Object} preferredLocation - The user's current preferred store in a format that can be used by the modal.
 * @property {string} preferredLocationNumber - The user's current preferred location number
 * @property {boolean} isLoadingLocations - Is the modal loading stores?
 * @property {boolean} error - Has an error occurred in the store search?
 * @property {string} errorMessage - Error message to display
 * @property {string} newPreferredLocationNumber - The user's new preferred location number
 * */

/**
 * @type {PreferredModalState}
 * */
const initialState = {
  // data
  query: { q: '', skipApiCall: false },
  stores: undefined,
  preferredLocation: {},
  preferredLocationNumber: undefined,
  newPreferredLocationNumber: undefined,
  autoCompleteResults: [],
  userSelectedAddress: null,
  // UI state
  preferredStoreModalOpen: false,
  autoCompleteOpen: false,
  // location data
  usingLocCoords: undefined,
  currentStore: null,
  // resolving state
  isLoadingLocations: false,
  error: false,
  errorMessage: undefined
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case OPEN_PREFERRED_STORE_MODAL:
      return {
        ...state,
        preferredStoreModalOpen: true,
        ...resetErrorState
      };
    case CLOSE_PREFERRED_STORE_MODAL:
      return { ...state, ...payload, ...resetErrorState };
    case OPEN_AUTO_COMPLETE:
      return { ...state, autoCompleteOpen: true };
    case CLOSE_AUTO_COMPLETE:
      return { ...state, autoCompleteOpen: false, ...resetErrorState };
    case SET_QUERY:
      return {
        ...state,
        query: { q: payload, skipApiCall: false },
        autoCompleteOpen: true,
        ...resetErrorState
      };
    case HANDLE_ADDRESS_SELECT:
      return {
        ...state,
        query: { q: payload.q, skipApiCall: true },
        userSelectedAddress: {
          latitude: payload.latitude,
          longitude: payload.longitude,
          suggestion: payload.q
        },
        ...resetErrorState,
        autoCompleteOpen: false
      };
    case SET_CURRENT_PREFERRED_STORE:
      return { ...state, ...payload, ...resetErrorState };
    case SET_NEW_PREFERRED_STORE:
      return { ...state, ...payload, ...resetErrorState };
    case SET_AUTO_COMPLETE_RESULTS:
      return { ...state, autoCompleteResults: payload };
    case SET_USING_LOC_COORDS:
      return {
        ...state,
        query: {},
        stores: undefined,
        usingLocCoords: payload,
        userSelectedAddress: payload,
        ...resetErrorState
      };
    case LOADING_LOCATIONS_ACTION:
      return { ...state, isLoadingLocations: true, ...resetErrorState };
    case LOADING_LOCATIONS_COMPLETE_ACTION:
      return { ...state, isLoadingLocations: false };
    case RESET_ERRORS:
      return {
        ...state,
        ...resetErrorState
      };
    case SET_ERROR:
      return { ...state, errorMessage: payload, error: true };
    case APP_LOADED:
      const { currentStore: preferredLocation } = payload;
      const { stores: currentStores } = state || undefined;
      const filteredStores = currentStores?.filter(
        s => s.locationNumber !== preferredLocation?.locationNumber
      );
      return { ...state, ...payload, stores: filteredStores };
    case SET_STATE:
      return { ...state, ...payload };
    case SET_STORES:
      return { ...state, ...payload, ...resetErrorState };
    default:
      return state;
  }
};

const resetErrorState = {
  error: false,
  errorMessage: undefined
};

const LOADING_LOCATIONS = {
  type: LOADING_LOCATIONS_ACTION
};

const LOADING_LOCATIONS_COMPLETE = {
  type: LOADING_LOCATIONS_COMPLETE_ACTION
};

const OPEN_MODAL = {
  type: OPEN_PREFERRED_STORE_MODAL
};
const CLOSE_MODAL = {
  type: CLOSE_PREFERRED_STORE_MODAL,
  payload: {
    preferredStoreModalOpen: false,
    autoCompleteOpen: false,
    isLoadingLocations: false
  }
};

const AUTO_COMPLETE_OPEN = {
  type: OPEN_AUTO_COMPLETE
};
const AUTO_COMPLETE_CLOSE = {
  type: CLOSE_AUTO_COMPLETE
};

const RESET_LOCATION_QUERY = {
  type: SET_STATE,
  payload: {
    userSelectedAddress: undefined,
    autoCompleteResults: []
  }
};

const STORE_LOOKUP_ERROR = errorMessage => {
  return {
    type: SET_ERROR,
    payload: {
      ...errorMessage
    },
    isLoadingLocations: false,
    stores: undefined
  };
};

export {
  reducer,
  initialState,
  LOADING_LOCATIONS,
  LOADING_LOCATIONS_COMPLETE,
  AUTO_COMPLETE_OPEN,
  AUTO_COMPLETE_CLOSE,
  RESET_LOCATION_QUERY,
  STORE_LOOKUP_ERROR,
  OPEN_MODAL,
  CLOSE_MODAL
};
