/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext } from 'react';
import { isEmpty, join, map } from 'lodash';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';

import { useExpireAuthSession } from 'app/auth/hooks';
import { CheckoutLayout } from 'app/checkout/components';
import { CSRProvider } from 'app/csr/context';
import { LocaleContext } from 'app/common/contexts';
import { useLoginToken } from 'app/common/hooks';
import { BaseLayout } from 'app/layout/components';

import {
  CartProvider,
  ClientProvider,
  FleetProvider,
  PaymentProvider,
  PricingProvider,
  SiteProvider,
  TopBrandProvider
} from '../../helpers';
import CampaignProvider from 'app/core/helpers/CampaignProvider';
import useIosZoomDisable from './useIosZoomDisable';
import { useHeaderMetadata } from './useHeaderMetadata';
import NearestInventoryProvider from 'app/core/helpers/NearestInventoryProvider';
import { APIProvider } from '@vis.gl/react-google-maps';
import { Environment } from 'app/common/services';

const App = () => {
  useExpireAuthSession();
  useLoginToken();
  useIosZoomDisable();
  return (
    <div className="App">
      <AppHelmet />
      <FleetProvider>
        <ClientProvider>
          <PricingProvider>
            <CartProvider>
              <PaymentProvider>
                <CSRProvider>
                  <CampaignProvider>
                    <TopBrandProvider>
                      <SiteProvider>
                        <NearestInventoryProvider>
                          <APIProvider
                            apiKey={Environment.get('GOOGLE_MAPS_API_KEY')}
                          >
                            <Switch>
                              <Route
                                exact
                                path="/checkout/:section?/:orderNumber?"
                                component={CheckoutLayout}
                              />
                              <Route component={BaseLayout} />
                            </Switch>
                          </APIProvider>
                        </NearestInventoryProvider>
                      </SiteProvider>
                    </TopBrandProvider>
                  </CampaignProvider>
                </CSRProvider>
              </PaymentProvider>
            </CartProvider>
          </PricingProvider>
        </ClientProvider>
      </FleetProvider>
    </div>
  );
};

/**
 * Helper component for modifying the `<head>` with info relevant to the current
 * view.
 */
const AppHelmet = () => {
  const { allowedLocales, currentLocale } = useContext(LocaleContext);

  const { siteTitle, siteDescription, keywords, favicon } = useHeaderMetadata();
  const canonical = `${window.location.origin}${window.location.pathname}`;
  return (
    <Helmet>
      <title>{siteTitle}</title>
      <meta name="description" content={siteDescription} />
      <meta name="robots" content="index,follow" />
      <meta name="canonical" content={canonical} />
      {!isEmpty(keywords) && (
        <meta name="keywords" content={join(keywords, ', ')} />
      )}
      <meta property="og:title" content={siteTitle} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:description" content={siteDescription} />
      <meta property="og:locale" content={currentLocale} />
      {favicon ? (
        <link rel="shortcut icon" href={favicon} />
      ) : (
        <link rel="shortcut icon" href="/favicon.ico" />
      )}
      {map(allowedLocales, locale => (
        <meta key={locale} property="og:locale:alternate" content={locale} />
      ))}
      <link rel="canonical" href={canonical} />
    </Helmet>
  );
};

export default App;
export { App, AppHelmet };
