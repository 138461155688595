/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { connect } from 'formik';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { CreditCardIcons } from 'app/common/components';
import { DecoratedField } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';
import { determineCreditCardType } from 'app/common/utils/PaymentInfoUtils';

import { ExpirationDate, SecurityCode } from './components';
import messages from './PaymentForm.messages';

const PaymentForm = ({ formik, isEdit = false, disabled = false }) => {
  const formatMessage = useFormatMessage();
  const activeCardNumber = get(formik, 'values.creditCardNumber');
  const activeCardType = determineCreditCardType(activeCardNumber);

  return (
    <fieldset className="flex flex-col" disabled={disabled}>
      <legend className="mb-4 text-gray-900 font-bold lg:text-lg">
        {formatMessage(messages.legend)}
      </legend>
      <DecoratedField
        id="displayName"
        label={formatMessage(messages.displayName)}
        name="displayName"
        disabled={disabled}
        required
        validate={value =>
          PaymentForm.validateField({
            value,
            message: formatMessage(messages.nameRequired)
          })
        }
      />
      <DecoratedField
        id="creditCardHolder"
        hint={formatMessage(messages.nameHint)}
        label={formatMessage(messages.nameLabel)}
        name="creditCardHolder"
        placeholder={formatMessage(messages.namePlaceholder)}
        disabled={disabled}
        required
        validate={value =>
          PaymentForm.validateField({
            value,
            message: formatMessage(messages.nameRequired)
          })
        }
        autoComplete="cc-name"
      />
      {!isEdit && (
        <>
          <DecoratedField
            id="creditCardNumber"
            hint={formatMessage(messages.numberHint)}
            label={formatMessage(messages.numberLabel)}
            name="creditCardNumber"
            disabled={disabled}
            required
            validate={value =>
              PaymentForm.validateField({
                value,
                message: formatMessage(messages.numberRequired)
              })
            }
            autoComplete="cc-number"
          />
          <CreditCardIcons activeName={activeCardType} />
        </>
      )}
      <ExpirationDate disabled={disabled} />
      {!isEdit && (
        <SecurityCode activeCardType={activeCardType} disabled={disabled} />
      )}
    </fieldset>
  );
};

PaymentForm.validateField = ({ message, value, required = true }) => {
  if (isEmpty(value) && required) {
    return message;
  }
};

PaymentForm.propTypes = {
  disabled: PropTypes.bool
};

export default connect(PaymentForm);
export { PaymentForm };
