/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import { CartPromotions, CartTotals } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from './Summary.messages';

/**
 * Render component for the checkout sticky-summary.
 */
const Summary = () => {
  const formatMessage = useFormatMessage();

  return (
    <section className="relative lg:sticky lg:top-36">
      <section className="rounded shadow bg-gray-100 lg:shadow-md">
        <header className="mb-4 py-2 text-gray-700 bg-white rounded-t">
          <h2 className="px-4 capitalize text-xl font-bold">
            {formatMessage(messages.title)}
          </h2>
        </header>
        <section>
          <CartTotals isCheckout />
        </section>
      </section>
      <CartPromotions />
    </section>
  );
};

export default Summary;
export { Summary };
