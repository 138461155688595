/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { Form, Formik } from 'formik';
import { get, isEmpty, map, mapValues } from 'lodash';
import { Helmet } from 'react-helmet';

import {
  DecoratedField,
  Icon,
  Input,
  PrimaryButton
} from 'app/common/components';
import { CustomerContext } from 'app/common/contexts';
import {
  useCustomerService,
  useFormatDate,
  useFormatMessage,
  useGtmPageView,
  useRestApi,
  useTenantInfo
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import messages from './Discounts.messages';
import { useHeaderMetadata } from 'app/core/components/App';

const Discounts = () => {
  const { siteTitle } = useHeaderMetadata();
  const formatMessage = useFormatMessage();
  const formatDate = useFormatDate();
  const { customer, discounts, setDiscounts, fetchFleets } =
    React.useContext(CustomerContext);
  const customerId = get(customer, 'id');
  const { baseUrl, fleetCustomersContextPath } = useCustomerService();
  const { baseUrl: fleetsBaseUrl, fleetUri, fleetParam } = useTenantInfo();
  const config = React.useMemo(() => ({ method: 'post' }), []);
  const { sendCallback: authorize } = useRestApi(
    undefined,
    config,
    false,
    true
  );
  const revokeConfig = React.useMemo(() => ({ method: 'del' }), []);
  const { sendCallback: revoke } = useRestApi(
    undefined,
    revokeConfig,
    false,
    true
  );
  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const hasError = !isEmpty(errorMessage);
  const { sendCallback: fetchFleetCustomers } = useRestApi(
    `${baseUrl}/${customerId}${fleetCustomersContextPath}`,
    undefined,
    false
  );

  useGtmPageView('Discount Group Associations');

  return (
    <section>
      <Helmet titleTemplate={`%s - ${siteTitle}`}>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <header className="text-2xl text-bold flex flex-col justify-between mb-6">
        <h1>{formatMessage(messages.title)}</h1>
      </header>
      <section>
        <section className="mb-4">
          {hasError && (
            <aside className="flex items-center mb-4 px-2 py-1 text-sm text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
              <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
              <span>{formatMessage(errorMessage)}</span>
            </aside>
          )}
          <Formik
            initialValues={{ authorizationCode: '', programCode: '' }}
            onSubmit={async (values, actions) => {
              try {
                const newDiscount = await authorize(
                  {
                    data: {
                      ...values,
                      type: 'DISCOUNT'
                    }
                  },
                  `${baseUrl}/${customerId}${fleetCustomersContextPath}`
                );

                const response = await fetchFleets(
                  { params: { [fleetParam]: true } },
                  `${fleetsBaseUrl}${fleetUri}/${newDiscount.fleetId}`
                );

                setDiscounts(prevParts => [
                  ...prevParts,
                  { ...newDiscount, fleet: response }
                ]);
                setErrorMessage(undefined);
                actions.resetForm({ authorizationCode: '', programCode: '' });
              } catch (err) {
                const reason = err.type;

                if ('FLEET_DISCOUNT_ALREADY_AUTHORIZED' === reason) {
                  if (isEmpty(discounts)) {
                    await fetchExistingDiscount({
                      fetchFleetCustomers,
                      fetchFleets,
                      fleetParam,
                      fleetsBaseUrl,
                      fleetUri,
                      setDiscounts,
                      setErrorMessage,
                      actions
                    });
                    return;
                  }
                  setErrorMessage(messages.alreadyInAnotherProgram);
                } else if ('FLEET_CUSTOMER_ALREADY_AUTHORIZED' === reason) {
                  setErrorMessage(messages.authOrProgramCodeAlreadyAuthed);
                } else if (
                  'FLEET_CUSTOMER_AUTHORIZATION_INVALID' === reason ||
                  'NOT_PERMITTED' === reason
                ) {
                  setErrorMessage(messages.authOrProgramCodeInvalid);
                } else if (!isEmpty(err.fieldErrors)) {
                  const errors = mapValues(err.fieldErrors, errorArray =>
                    formatMessage(messages[errorArray[0].code])
                  );
                  actions.setErrors(errors);
                } else {
                  logError({
                    ...err,
                    when: `authorizing discount association for ${customerId}`
                  });
                  setErrorMessage(messages.authOrProgramCodeGenericError);
                }
              } finally {
                actions.setSubmitting(false);
              }
            }}
            validateOnChange={false}
            validateOnBlur={true}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col w-full">
                <DecoratedField
                  disabled={isSubmitting}
                  id="fleet-auth-code"
                  label={formatMessage(messages.programCodeLabel)}
                  name="programCode"
                  placeholder={formatMessage(messages.programCodePlaceholder)}
                  required
                  size={Input.Size.SMALL}
                  validate={value => {
                    if (isEmpty(value)) {
                      return formatMessage(messages.programCodeRequired);
                    }
                  }}
                />
                <DecoratedField
                  disabled={isSubmitting}
                  id="fleet-auth-code"
                  label={formatMessage(messages.authCodeLabel)}
                  name="authorizationCode"
                  placeholder={formatMessage(messages.authCodePlaceholder)}
                  required
                  size={Input.Size.SMALL}
                  validate={value => {
                    if (isEmpty(value)) {
                      return formatMessage(messages.authCodeRequired);
                    }
                  }}
                />
                <div className="flex justify-end w-full lg:w-64">
                  <PrimaryButton
                    type="submit"
                    disabled={isSubmitting}
                    size={PrimaryButton.Size.SMALL}
                  >
                    {formatMessage(messages.authCodeSubmit)}
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </Formik>
        </section>
        {isEmpty(discounts) ? (
          <div className="w-full text-bold text-gray-500 text-xl text-center sm:text-left sm:mt-8 sm:ml-8 sm:text-2xl">
            {formatMessage(messages.noDiscounts)}
          </div>
        ) : (
          <section className="mt-8">
            <section>
              <ul className="list-none">
                {map(discounts, discount => {
                  const { fleet = {} } = discount;
                  return (
                    <li
                      className="flex flex-col flex-wrap items-start justify-between my-8 p-4 shadow rounded bg-white first:my-0 sm:flex-row"
                      key={discount.id}
                    >
                      <div className="mb-2 sm:basis-1/2">
                        <div className="text-sm text-gray-600 font-semibold">
                          {formatMessage(messages.fleetName)}
                        </div>
                        <div>{fleet.name}</div>
                      </div>
                      <div className="mb-2 sm:basis-1/2">
                        <div className="text-sm text-gray-600 font-semibold">
                          {formatMessage(messages.programCode)}
                        </div>
                        <div>
                          {get(discount, 'programCode') || 'Program Name'}
                        </div>
                      </div>
                      <div className="mb-2 sm:basis-1/2">
                        <div className="text-sm text-gray-600 font-semibold">
                          {formatMessage(messages.authCode)}
                        </div>
                        <div>
                          {get(discount, 'authorizationCode') || 'A123'}
                        </div>
                      </div>
                      <div className="mb-2 sm:basis-1/2">
                        <div className="text-sm text-gray-600 font-semibold">
                          {formatMessage(messages.authedDate)}
                        </div>
                        <div>
                          {formatDate(
                            get(discount, 'dateAuthorized', new Date()),
                            {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric'
                            }
                          )}
                        </div>
                      </div>
                      <div className="flex justify-end w-full">
                        <PrimaryButton
                          className="self-end"
                          onClick={async () => {
                            try {
                              await revoke(
                                undefined,
                                `${baseUrl}/${customerId}${fleetCustomersContextPath}/${discount.id}`
                              );
                              setDiscounts(prev =>
                                prev.filter(({ id }) => id !== discount.id)
                              );
                              setErrorMessage(undefined);
                            } catch (err) {
                              logError({
                                ...err,
                                when: `revoking discount association for ${customerId}`
                              });
                              setErrorMessage(
                                messages.authOrProgramCodeGenericError
                              );
                            }
                          }}
                          size={PrimaryButton.Size.SMALL}
                        >
                          {formatMessage(messages.removeProgram)}
                        </PrimaryButton>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </section>
          </section>
        )}
      </section>
    </section>
  );
};

async function fetchExistingDiscount(props) {
  try {
    const {
      fetchFleetCustomers,
      fetchFleets,
      fleetParam,
      fleetsBaseUrl,
      fleetUri,
      setDiscounts,
      setErrorMessage,
      actions
    } = props;
    const discount = await fetchFleetCustomers();
    const response = await fetchFleets(
      { params: { [fleetParam]: true } },
      `${fleetsBaseUrl}${fleetUri}/${discount.fleetId}`
    );
    const newFleet = response.content[0];

    setDiscounts(prevParts => [...prevParts, { ...discount, fleet: newFleet }]);
    setErrorMessage(undefined);
    actions.resetForm({ authorizationCode: '', programCode: '' });
  } catch (err) {
    // couldn't fetch for some reason so just reload
    window.location.reload();
  }
}

export default Discounts;
export { Discounts };
