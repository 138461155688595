/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { CampaignContext, TenantContext } from 'app/common/contexts';
import { useLocation } from 'react-router-dom';
import useCampaignInfo from 'app/common/hooks/campaign/useCampaignInfo';
import { useRestApi } from 'app/common/hooks';
import { get, merge } from 'lodash';
import { AuthContext } from 'app/auth/contexts';

const CampaignProvider = ({ children }) => {
  const { resolving: resolvingTenant } = React.useContext(TenantContext);
  const { isAuthenticating } = React.useContext(AuthContext);
  const [stop, setStop] = useState(false);
  const [campaign, setCampaign] = useState(undefined);
  const { loading, sendCallback, fetchCampaign } = useCampaignUrl();
  useEffect(() => {
    async function doWork() {
      if (!stop && !resolvingTenant && !isAuthenticating && !loading) {
        const resp = await fetchCampaign(sendCallback);
        if (!get(resp, 'loading')) {
          setStop(true);
          setCampaign(resp);
        }
      }
    }
    doWork();
  }, [
    isAuthenticating,
    resolvingTenant,
    sendCallback,
    loading,
    stop,
    setStop,
    setCampaign,
    fetchCampaign
  ]);
  return (
    <CampaignContextProvider
      isLoadedCampaign={loading}
      initialCampaign={campaign}
    >
      {children}
    </CampaignContextProvider>
  );
};

CampaignProvider.propTypes = {
  /** Child components to render within the component  */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func
  ])
};

function CampaignContextProvider({
  initialCampaign,
  isLoadedCampaign,
  children
}) {
  const [campaign, setCampaign] = useState(
    get(initialCampaign, 'loading', true)
      ? CampaignContext.defaultValue
      : initialCampaign
  );

  useMemo(() => {
    if (initialCampaign && isLoadedCampaign) {
      setCampaign(initialCampaign);
    }
  }, [initialCampaign, isLoadedCampaign]);
  return (
    <CampaignContext.Provider value={campaign}>
      {children}
    </CampaignContext.Provider>
  );
}

CampaignContextProvider.propTypes = {
  campaign: PropTypes.any,
  loadedCampaign: PropTypes.bool,
  children: PropTypes.node
};

function useCampaignUrl() {
  const { exception, error, loading, response, sendCallback } =
    useCampaignByUrlPath();
  const fetchCampaign = useResponseCallback(sendCallback);

  return {
    exception,
    error,
    loading,
    response,
    sendCallback,
    fetchCampaign
  };
}

function useCampaignByUrlPath() {
  const path = usePath();
  const { findByUriPath } = useCampaignInfo();
  return useRestApi(findByUriPath, getConfig(path), false, true);
}

function getConfig(path) {
  let parsedPath;
  if (path.startsWith('/')) {
    parsedPath = path.substring(4);
  } else {
    parsedPath = path.substring(3);
  }
  if (parsedPath?.length === 0) {
    parsedPath = '/';
  }
  return {
    method: 'get',
    params: {
      url: parsedPath
    }
  };
}

function useResponseCallback(sendRequest) {
  const path = usePath();
  return useCallback(
    async conf => {
      if (path.startsWith('/callback')) {
        return { loading: true };
      }
      try {
        if (isCampaignPath(path)) {
          const resp = await sendRequest(conf, null);
          const { url } = resp;
          return merge(resp, {
            isCampaignFound: true,
            isCampaignPath: true,
            loading: false,
            fullUrl: url ? '/_cp' + url : url
          });
        }
        return { isCampaignFond: false, isCampaignPath: false, loading: false };
      } catch (err) {
        return { isCampaignFound: false, isCampaignPath: true, loading: false };
      }
    },
    [sendRequest, path]
  );
}

function usePath() {
  const location = useLocation();
  return location.pathname;
}

function isCampaignPath(path) {
  return path && path.startsWith('/_cp');
}

export default CampaignProvider;
