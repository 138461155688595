import { FulfillmentType } from 'app/common/constants';
import { get } from 'lodash';

const initialState = {
  /**
   * Is the user authenticated?
   * @type {boolean}
   */
  isAuthenticated: false,
  /**
   * Is the user a guest?
   * @type {boolean}
   */
  isGuest: false,
  /**
   * Is the cart resolved?
   * @type {boolean}
   */
  isCartResolved: false,
  /**
   * Is the order being submitted?
   * @type {boolean}
   */
  isSubmittingOrder: false,
  /**
   * @type {string}
   */
  cartStatus: 'IN_PROCESS',
  /**
   * Has the promo code been updated?
   * @type {boolean}
   */
  promoCodeUpdated: false,
  /**
   * The current cart
   * @type {Cart}
   */
  cart: {},
  /**
   * The fulfillment options
   * @type {Array<FulfillmentOption>}
   */
  fulfillmentOptions: [],
  /**
   * The failed fulfillment options
   * @type {Array<DeliveryValidationFailure>}
   */
  failedOptions: [],
  /**
   * Form address for fulfillment address
   * @type {Object}
   */
  formAddress: {},
  /**
   * Is delivery available?
   * @type {boolean}
   */
  deliveryOptionAvailable: false,
  /**
   * Are there multiple delivery options?
   * @type {boolean}
   */
  multipleDeliveryOptions: false,
  /**
   * The selected address
   */
  selectedAddress: undefined,
  /**
   * The selected fulfillment type
   */
  selectedFulfillmentType: FulfillmentType.IN_STORE,
  /**
   * The selected fulfillment option
   */
  selectedFulfillmentOption: {},
  /**
   * Is the cart eligible for delivery?
   */
  cartEligibleForDelivery: true,
  /**
   * Show address errors
   */
  showAddressErrors: false,
  /**
   * Should delivery options be fetched?
   */
  fetchDeliveryOptions: false,
  /**
   * Are the delivery options stale? This should be set to true when:
   * 1. The cart changes
   * 2. The address changes
   * 3. The store changes
   */
  deliveryOptionsStale: true,
  /**
   * Show delivery option fetch failed
   */
  fetchDeliveryOptionsFailed: false
};

const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';
const SET_IS_GUEST = 'SET_IS_GUEST';
const SET_IS_CART_RESOLVED = 'SET_IS_CART_RESOLVED';
const SET_IS_SUBMITTING_ORDER = 'SET_IS_SUBMITTING_ORDER';
const SET_PROMO_CODE_UPDATED = 'SET_PROMO_CODE_UPDATED';
const SET_FULFILLMENT_OPTIONS = 'SET_FULFILLMENT_OPTIONS';
const SET_FORM_ADDRESS = 'SET_FORM_ADDRESS';
const SET_SELECT_ADDRESS = 'SET_SELECT_ADDRESS';
const SET_FULFILLMENT_TYPE = 'SET_FULFILLMENT_TYPE';
const SET_FULFILLMENT_OPTION = 'SET_FULFILLMENT_OPTION';
const SET_DELIVERY_OPTION_AVAILABLE = 'SET_DELIVERY_OPTION_AVAILABLE';
const SET_SHOW_DELIVERY_NOT_AVAILABLE_ERROR =
  'SET_SHOW_DELIVERY_NOT_AVAILABLE_ERROR';
const SET_DELIVERY_NOT_AVAILABLE_REASON = 'SET_DELIVERY_NOT_AVAILABLE_REASON';
const SET_CART_ELIGIBLE_FOR_DELIVERY = 'SET_CART_ELIGIBLE_FOR_DELIVERY';
const SET_SHOW_ADDRESS_ERRORS = 'SET_SHOW_ADDRESS_ERRORS';
const SET_FETCH_DELIVERY_OPTIONS = 'SET_FETCH_DELIVERY_OPTIONS';
const SET_DELIVERY_OPTIONS_STALE = 'SET_DELIVERY_OPTIONS_STALE';
const SET_FETCH_DELIVERY_OPTIONS_FAILED = 'SET_FETCH_DELIVERY_OPTIONS_FAILED';
const checkoutReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: payload
      };
    case SET_IS_GUEST:
      return {
        ...state,
        isGuest: payload
      };
    case SET_IS_CART_RESOLVED:
      return {
        ...state,
        isCartResolved: payload
      };
    case SET_IS_SUBMITTING_ORDER:
      return {
        ...state,
        isSubmittingOrder: payload
      };
    case SET_PROMO_CODE_UPDATED:
      return {
        ...state,
        ...payload
      };
    case SET_FULFILLMENT_OPTIONS:
      return {
        ...state,
        fulfillmentOptions: payload.fulfillmentOptions,
        failedOptions: payload.failedOptions,
        deliveryOptionsStale: false,
        fetchDeliveryOptionsFailed: false
      };
    case SET_FORM_ADDRESS:
      return {
        ...state,
        formAddress: payload
      };
    case SET_SELECT_ADDRESS:
      return {
        ...state,
        selectedAddress: payload,
        formAddress: payload || {},
        deliveryOptionsStale: true
      };
    case SET_FULFILLMENT_TYPE:
      return {
        ...state,
        selectedFulfillmentType: payload
      };
    case SET_FULFILLMENT_OPTION:
      const existingFulfillmentType = get(state, 'selectedFulfillmentType');
      const fulfillmentType = get(
        payload,
        'fulfillmentType',
        existingFulfillmentType
      );
      const selected =
        fulfillmentType === FulfillmentType.IN_STORE ||
        fulfillmentType === FulfillmentType.PICKUP
          ? FulfillmentType.PICKUP
          : FulfillmentType.SHIP;
      return {
        ...state,
        selectedFulfillmentOption: payload,
        selectedFulfillmentType: selected
      };
    case SET_DELIVERY_OPTION_AVAILABLE:
      return {
        ...state,
        ...payload
      };
    case SET_SHOW_DELIVERY_NOT_AVAILABLE_ERROR:
      return {
        ...state,
        showDeliveryNotAvailable: payload
      };
    case SET_DELIVERY_NOT_AVAILABLE_REASON:
      return {
        ...state,
        deliveryNotAvailableReason: payload
      };
    case SET_CART_ELIGIBLE_FOR_DELIVERY:
      return {
        ...state,
        cartEligibleForDelivery: payload
      };
    case SET_SHOW_ADDRESS_ERRORS:
      return {
        ...state,
        showAddressErrors: payload
      };
    case SET_FETCH_DELIVERY_OPTIONS:
      return {
        ...state,
        fetchDeliveryOptions: payload
      };
    case SET_DELIVERY_OPTIONS_STALE:
      return {
        ...state,
        deliveryOptionsStale: payload
      };
    case SET_FETCH_DELIVERY_OPTIONS_FAILED:
      return {
        ...state,
        fetchDeliveryOptionsFailed: payload
      };
    default:
      return state;
  }
};

const setIsGuest = guest => ({ type: SET_IS_GUEST, payload: guest });
const setIsSubmittingOrder = submitting => ({
  type: SET_IS_SUBMITTING_ORDER,
  payload: submitting
});
const setPromoCodeUpdated = updated => {
  if (updated) {
    return {
      type: SET_PROMO_CODE_UPDATED,
      payload: {
        promoCodeUpdated: updated,
        deliveryOptionsStale: true
      }
    };
  }
  return {
    type: SET_PROMO_CODE_UPDATED,
    payload: updated
  };
};
const setFulfillmentOptions = (dispatch, options) =>
  dispatch({
    type: SET_FULFILLMENT_OPTIONS,
    payload: options
  });
const setFormAddress = (dispatch, formAddress) =>
  dispatch({
    type: SET_FORM_ADDRESS,
    payload: formAddress || {}
  });
const setSelectedAddress = (dispatch, selectedAddress) => {
  dispatch({ type: SET_SELECT_ADDRESS, payload: selectedAddress });
};
const setSelectedFulfillmentType = (dispatch, fulfillmentType) =>
  dispatch({
    type: SET_FULFILLMENT_TYPE,
    payload: fulfillmentType
  });
const setSelectedFulfillmentOption = (dispatch, fulfillmentOption) => {
  dispatch({
    type: SET_FULFILLMENT_OPTION,
    payload: fulfillmentOption
  });
};
const setDeliveryOptionsStale = (dispatch, stale) =>
  dispatch({
    type: SET_DELIVERY_OPTIONS_STALE,
    payload: stale
  });
const setFetchDeliveryOptionsFailed = (dispatch, failed) => ({
  type: SET_FETCH_DELIVERY_OPTIONS_FAILED,
  payload: failed
});

/**
 *
 * @param dispatch Checkout reducer dispatch
 * @param response Delivery option availability response
 */
const setDeliveryOptionAvailable = (dispatch, response) =>
  dispatch({
    type: SET_DELIVERY_OPTION_AVAILABLE,
    payload: response
  });
const setShowAddressErrors = (dispatch, show) => ({
  type: SET_SHOW_ADDRESS_ERRORS,
  payload: show
});
const setFetchDeliveryOptions = (dispatch, fetch) => {
  dispatch({
    type: SET_FETCH_DELIVERY_OPTIONS,
    payload: fetch
  });
};

export {
  initialState,
  checkoutReducer,
  setIsGuest,
  setIsSubmittingOrder,
  setPromoCodeUpdated,
  setFulfillmentOptions,
  setFormAddress,
  setSelectedAddress,
  setSelectedFulfillmentType,
  setSelectedFulfillmentOption,
  setDeliveryOptionAvailable,
  setShowAddressErrors,
  setFetchDeliveryOptions,
  setDeliveryOptionsStale,
  setFetchDeliveryOptionsFailed
};
