/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect } from 'react';
import { get, isEmpty, isEqual } from 'lodash';
import { useResolvePayments } from '@broadleaf/payment-react';
import { DefaultPaymentOwnerType } from '@broadleaf/commerce-cart';

import {
  CartContext,
  CurrencyContext,
  PaymentContext,
  TenantContext
} from 'app/common/contexts';
import {
  usePaymentAuthState,
  usePaymentInfo,
  useRestApi
} from 'app/common/hooks';
import { Environment, LocalStorageCache } from 'app/common/services';
import { logError } from 'app/common/utils/ApiErrorUtils';

import { usePaymentClient } from '../ClientProvider/ClientProvider';

const PaypalCache = new LocalStorageCache('PaypalCache', {
  disablePersist: !paypalCacheEnabled(),
  enableSession: true,
  ttl: 1000 * 60 * 60
});

/**
 * Helper component for setting up the payment context. This determines what
 * payment gateways are setup and fetches any public keys that may be stored on
 * the backend for the current location.
 *
 * @author Nathan Moore (nathandmoore)
 */
const PaymentProvider = ({ children, ...props }) => {
  const isServer = typeof window === 'undefined';
  if (isServer) {
    return <>{children}</>;
  }

  return <PaymentProviderInner {...props}>{children}</PaymentProviderInner>;
};

function usePayPalContext() {
  const { application, tenant, resolving } = React.useContext(TenantContext);
  const { baseUrl, paypalAccountContextPath } =
    usePaymentInfo().merchant.properties;
  const { id: applicationId } = application || {};
  const cachedPaypalAccount = PaypalCache.get(applicationId);
  let { error, exception, response, sendCallback } = useRestApi(
    `${baseUrl}${paypalAccountContextPath}`,
    undefined,
    false
  );

  // Cache the paypal information for the current dealer application
  useEffect(() => {
    (async () => {
      if (!cachedPaypalAccount && !resolving) {
        const paypalResponse = await sendCallback(
          `${baseUrl}${paypalAccountContextPath}`,
          undefined
        );

        if (paypalResponse) {
          PaypalCache.put(applicationId, paypalResponse);
        }
      }
    })();
  }, [
    applicationId,
    baseUrl,
    resolving,
    paypalAccountContextPath,
    cachedPaypalAccount,
    sendCallback
  ]);

  const [state, setState] = React.useState({
    paypalEnabled: undefined,
    paypalAccount: undefined
  });

  React.useEffect(() => {
    if (error) {
      logError({
        ...exception,
        when: 'fetching PayPal account ID for the location'
      });
    }
  }, [error, exception]);

  const paypalState = usePayPal(state);

  React.useEffect(() => {
    const usedPaypalInfo = cachedPaypalAccount || response;
    const account = get(usedPaypalInfo, 'accountId');
    if (isEmpty(account) && (paypalState.loading || !paypalState.loaded)) {
      return;
    }
    setState(prev => {
      const next = {
        paypalEnabled:
          paymentsEnabled() && !isEmpty(tenant) && !isEmpty(account),
        paypalAccount: account
      };

      return isEqual(prev, next) ? prev : next;
    });
  }, [
    response,
    cachedPaypalAccount,
    tenant,
    paypalState.loading,
    paypalState.loaded
  ]);

  return state;
}

function usePayPal(paypalContext) {
  const [paypalState, setPayPalState] = React.useState({
    loading: true,
    loaded: false
  });

  const { currentCurrency: currency } = React.useContext(CurrencyContext);

  React.useEffect(() => {
    setPayPalState({ loading: false, loaded: false });
  }, [currency]);

  React.useEffect(() => {
    if (
      !paypalContext.paypalEnabled ||
      paypalState.loading ||
      paypalState.loaded
    ) {
      return;
    }

    setPayPalState({ loading: true, loaded: false });
    const script = document.createElement('script');
    script.src = `https://www.paypal.com/sdk/js?&client-id=${paypalClientId()}&merchant-id=${
      paypalContext.paypalAccount
    }&intent=authorize&currency=${currency}`;
    script['data-csp-nonce'] = '__CSP_NONCE__';
    script.addEventListener('load', () =>
      setPayPalState({ loading: false, loaded: true })
    );
    document.body.appendChild(script);
  }, [paypalContext, paypalState, currency]);
  return { paypalState };
}

function PaymentProviderInner({ children }) {
  const paypalContext = usePayPalContext();
  const { cart } = React.useContext(CartContext);
  const paymentClient = usePaymentClient();
  const authState = usePaymentAuthState();
  const ownerId = get(cart, 'id');
  const [payments, setPayments] = React.useState(undefined);
  React.useEffect(() => {});
  const { resolving, refetchPayments } = useResolvePayments({
    ownerId,
    paymentClient,
    setPayments,
    ownerType: DefaultPaymentOwnerType.BLC_CART,
    authState
  });
  const [isStale, setIsStale] = React.useState(false);
  const context = React.useMemo(
    () => ({
      ...PaymentContext.defaultValue,
      ...paypalContext,
      isStale,
      setIsStale,
      resolving,
      payments,
      setPayments,
      refetchPayments
    }),
    [
      paypalContext,
      resolving,
      payments,
      setPayments,
      refetchPayments,
      isStale,
      setIsStale
    ]
  );

  return (
    <PaymentContext.Provider value={context}>
      {children}
    </PaymentContext.Provider>
  );
}

function paymentsEnabled() {
  return Environment.get('PAYMENTS_ENABLED', false) === 'true';
}

function paypalClientId() {
  return Environment.get('PAYMENT_PAYPALCHECKOUT_CLIENT_ID');
}

function paypalCacheEnabled() {
  return Environment.get('PAYMENT_PAYPALCHECKOUT_CACHE_ENABLED') === 'true';
}

export default PaymentProvider;
export { PaymentProvider };
