/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import { TertiaryButton } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from './NotFound.messages';

/**
 * Render component for the 404/Not Found/Error page.
 *
 * @visibleName Not Found/404/Error Page
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const NotFound = () => {
  const formatMessage = useFormatMessage();

  return (
    <main className="flex-grow">
      <header className="py-12 px-6 text-center bg-gray-100 border-b border-gray-300">
        <div className="container">
          <h1 className="text-gray-900 text-4xl font-medium leading-tight">
            {formatMessage(messages.title)}{' '}
            <span className="font-bold">
              {formatMessage(messages.titleMessage)}
            </span>
          </h1>
        </div>
      </header>
      <section className="py-12 px-6 text-center">
        <div className="container flex flex-col items-center">
          <h2 className="flex items-center justify-center text-gray-700 text-xl leading-tight">
            {formatMessage(messages.subtitle)}
          </h2>
          <ul className="block my-4 bg-white rounded shadow">
            <li className="block py-2 px-4 border-b border-gray-300">
              {formatMessage(messages.reasons.removed)}
            </li>
            <li className="block py-2 px-4 border-b border-gray-300">
              {formatMessage(messages.reasons.neverExisted)}
            </li>
            <li className="block py-2 px-4 border-b border-gray-300">
              {formatMessage(messages.reasons.error)}
            </li>
            <li className="block my-2 mx-4">
              {formatMessage(messages.reasons.other)}
            </li>
          </ul>
          <p>
            {formatMessage(messages.returnHome.preamble)}{' '}
            <TertiaryButton to="/">
              {formatMessage(messages.returnHome.homepage)}
            </TertiaryButton>{' '}
            {formatMessage(messages.returnHome.postamble)}
          </p>
        </div>
      </section>
    </main>
  );
};

export default NotFound;
export { NotFound };
