/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { createContext } from 'react';
import { noop } from 'lodash';

const FilterContext = createContext({
  /**
   * List of existing filters represented by the complete FilterValue DTO—not to
   * be translated into the URL search queries. This is required to keep track
   * of the other possible max and min values for ranged Filters so that if one
   * range is removed, the next best max and min can be used.
   */
  activeFilters: [],
  /**
   * When a filter is being activate or deactivated, this allows us to lock the
   * other filters so that the previous operation can complete first.
   */
  lockFilters: false,
  /** Method to update `lockFilters` */
  setLockFilters: noop
});

export default FilterContext;
export { FilterContext };
