/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
/**
 * Specifies how an `IncludedProduct` is priced in relation to its parent.
 *
 * @type {{ADD_TO_PARENT: string, INCLUDED_IN_PARENT: string}}
 */
const ItemChoicePricingModel = Object.freeze({
  /**
   * The price of the option/included product will be added to the parent
   * product's price.
   */
  ADD_TO_PARENT: 'ADD_TO_PARENT',
  /**
   * The price of the addon/option is considered to be already included in the
   * parent product's price.
   */
  INCLUDED_IN_PARENT: 'INCLUDED_IN_PARENT'
});

export default ItemChoicePricingModel;
