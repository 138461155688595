/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

import { ColorAttributeChoice, DefaultAttributeChoice } from '../index';
/*
!!allowedWithOtherSelectedAttrs &&
          allowedWithOtherSelectedAttrs.has(value)
 */
const ChoiceGroup = ({ activeChoice, allowedValues, handleSelect, type }) => (
  <ol className="flex flex-wrap w-full -m-1">
    {allowedValues.map(allowedValue => {
      if (type === 'COLOR') {
        return (
          <ColorAttributeChoice
            key={allowedValue.value}
            allowedValue={allowedValue}
            activeChoice={activeChoice}
            handleSelect={handleSelect}
          />
        );
      }

      return (
        <DefaultAttributeChoice
          key={allowedValue.value}
          allowedValue={allowedValue}
          activeChoice={activeChoice}
          handleSelect={handleSelect}
        />
      );
    })}
  </ol>
);

ChoiceGroup.propTypes = {
  /** The currently selected value */
  activeChoice: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any
  }),
  allowedValues: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      displayOrder: PropTypes.number,
      disabled: PropTypes.bool
    })
  ).isRequired,
  /** Method to call when this value is selected. Takes the label and value */
  handleSelect: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default ChoiceGroup;
export { ChoiceGroup };
