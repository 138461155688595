/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useProductClick } from 'app/common/hooks/gtm';
import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { Button } from 'app/common/components';
import { addParams } from 'app/common/utils/PathUtils';

import messages from '../../CartItem.messages';

/**
 * Render component for the cart item's main asset.
 *
 * @visibleName Cart: Item: Main Asset
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const CartItemAsset = ({
  altText,
  contentUrl: src,
  detailsUrl,
  itemName,
  title,
  gtmItem
}) => {
  const sendProductClick = useProductClick(gtmItem);
  return (
    <figure className="block w-full">
      <Button
        to={location => ({
          ...location,
          pathname: detailsUrl,
          state: {
            ...get(location, 'state', {}),
            breadcrumbs: [
              {
                uri: '/cart',
                label: messages.cartBreadcrumbLabel
              },
              {
                label: itemName
              }
            ]
          }
        })}
        onClick={() => {
          sendProductClick();
        }}
      >
        <img
          className="block mx-auto"
          alt={altText || `${itemName} Image`}
          title={title}
          src={`${addParams(src, { 'product-xs': true })}`}
          height="240"
          width="240"
        />
      </Button>
    </figure>
  );
};

CartItemAsset.propTypes = {
  /** Image alt text */
  altText: PropTypes.string,
  /** URL to use as the asset's source */
  contentUrl: PropTypes.string,
  /** URL of the item's details page */
  detailsUrl: PropTypes.string,
  /** Name of the cart item to which this asset belongs */
  itemName: PropTypes.string,
  /** Title to use for the asset's title attribute */
  title: PropTypes.string
};

export default CartItemAsset;
export { CartItemAsset };
