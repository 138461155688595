/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { useSubmitPaymentRequest } from '@broadleaf/payment-react';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { AuthContext } from 'app/auth/contexts';
import {
  AddressForm,
  AuthenticatedAddressForm,
  Icon,
  LoadingIcon,
  PrimaryButton,
  SecondaryButton
} from 'app/common/components';
import { PaymentGatewayType } from 'app/common/constants';
import { CartContext, PaymentContext } from 'app/common/contexts';
import { useFormatMessage, usePaymentAuthState } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';
import { usePaymentClient } from 'app/core/helpers/ClientProvider/ClientProvider';

import { useBillingAddress, useInitialFormValues } from '../../hooks';
import messages from './PaymentForm.messages';
import { goToCheckoutStep } from 'app/checkout/components/CheckoutLayout/components/hooks';
import { REVIEW_PATH } from 'app/checkout/components/CheckoutLayout/CheckoutPaths';

const PaymentForm = ({
  active,
  children,
  completed,
  handleSubmit,
  history,
  paymentGatewayType
}) => {
  const formatMessage = useFormatMessage();
  const { cart } = React.useContext(CartContext);
  const initialValues = useInitialFormValues(paymentGatewayType);
  const { address } = useBillingAddress(cart);
  const { isAuthenticated } = React.useContext(AuthContext);
  const [selectedAddress, setSelectedAddress] = React.useState(undefined);
  // const { owningUserType, owningUserId } = usePaymentOwner();
  const authState = usePaymentAuthState();
  const paymentClient = usePaymentClient();
  // const savedPaymentMethodClient = useSavedPaymentMethodClient();
  const cartTotal = get(cart, 'cartPricing.total');
  const { payments, refetchPayments, isStale } =
    React.useContext(PaymentContext);

  const {
    error,
    exception,
    handleSubmitPaymentInfo: sendPaymentRequest
  } = useSubmitPaymentRequest({
    authState,
    payments,
    ownerId: cart.id,
    paymentClient,
    multiplePaymentsAllowed: true,
    rejectOnError: true
  });

  if (error) {
    logError({
      ...exception,
      when: 'submitting payment info'
    });
  }

  const goToReviewStep = goToCheckoutStep(history, REVIEW_PATH, {
    paymentComplete: true,
    editing: false
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          if (!active) {
            actions.setSubmitting(false);
            return;
          }

          const submitValues = PaymentForm.getFormValuesToSubmit(
            cart,
            selectedAddress,
            values
          );

          let updatedPayment;

          try {
            updatedPayment = await handleSubmit(submitValues, actions, {
              sendPaymentRequest,
              paymentAmount: cartTotal,
              existingPayment: get(payments, 'content[0]')
            });
          } catch (err) {
            console.error('There was an error adding payment information', err);
          } finally {
            await refetchPayments();
            actions.setSubmitting(false);
          }

          if (!isEmpty(updatedPayment)) {
            if (error) {
              return;
            }

            goToReviewStep();
          }
        }}
        validateOnBlur={true}
        validateOnChange={false}
        enableReinitialize={true}
      >
        {formik => {
          const { isSubmitting, values } = formik;

          const disableSubmit =
            paymentGatewayType === PaymentGatewayType.PAYPAL_CHECKOUT_V2 &&
            isEmpty(get(values, 'paymentGatewayProperties'));

          return (
            <Form className="flex flex-col">
              {error && !isSubmitting && (
                <div className="flex items-center mb-4 px-2 py-1 text-sm text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
                  <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
                  <span>{formatMessage(messages.generalError)}</span>
                </div>
              )}
              {children(formik)}
              {paymentGatewayType !== PaymentGatewayType.PAYPAL_CHECKOUT_V2 && (
                <>
                  <hr className="my-4 bg-gray-400 lg:my-6" />
                  {isAuthenticated && (
                    <AuthenticatedAddressForm
                      type={AddressForm.Type.BILLING}
                      address={address}
                      isSubmitting={isSubmitting}
                      disabled={isSubmitting || !active}
                      selectedAddress={selectedAddress}
                      setSelectedAddress={setSelectedAddress}
                    />
                  )}
                  {!isAuthenticated && (
                    <AddressForm
                      type={AddressForm.Type.BILLING}
                      disabled={isSubmitting || !active}
                    />
                  )}
                </>
              )}
              <div className="flex justify-end mt-4">
                {completed && (
                  <SecondaryButton
                    className="mr-2"
                    disabled={isSubmitting || !active}
                    onClick={goToReviewStep}
                  >
                    {formatMessage(messages.cancel)}
                  </SecondaryButton>
                )}
                <PrimaryButton
                  className="relative"
                  type="submit"
                  disabled={disableSubmit || isSubmitting || !active || isStale}
                >
                  <div
                    className={classNames({ 'text-transparent': isSubmitting })}
                  >
                    {formatMessage(messages.submit)}
                  </div>
                  {isSubmitting && (
                    <div className="absolute inset-0 flex justify-center items-center">
                      <LoadingIcon className="text-blue-500" />
                    </div>
                  )}
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

/**
 * Form errors render component. The specific errors should be passed in as
 * children.
 */
PaymentForm.Errors = ({ children }) => (
  <section className="flex items-center mb-4 px-2 py-1 text-sm text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
    <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
    {children}
  </section>
);

/**
 * Returns a form values object updated with the delivery address or selected
 * saved-address if applicable.
 *
 * @param {{}} cart - The current cart state
 * @param {{}} selectedAddress - the selected saved address if any
 * @param {{}} values - form values
 *
 * @return {{}} The values updated to include the delivery address or selected
 *     saved-address.
 */
PaymentForm.getFormValuesToSubmit = (cart, selectedAddress, values) => {
  if (!!selectedAddress) {
    return {
      ...values,
      ...selectedAddress,
      country: selectedAddress.country,
      stateProvinceRegion: selectedAddress.stateProvinceRegion
    };
  }

  if (values.useDeliveryAddress) {
    const fulfillmentGroup = get(cart, 'fulfillmentGroups[0]', {});
    const { address: fulfillmentAddress = {} } = fulfillmentGroup;

    return {
      ...values,
      ...fulfillmentAddress,
      country: get(fulfillmentAddress, 'country')
    };
  }

  return { ...values };
};

PaymentForm.propTypes = {
  /**
   * Whether the payment info stage is active. Affects whether the form is
   * disabled.
   */
  active: PropTypes.bool,
  /**
   * Function to handle the PaymentForm's submission. This will be passed the
   * form values, formik actions, and additional info containing payment amount,
   * request method (determined by whether an "add" or "update" should take
   * place), and the base request callback function to hit the cart payment API.
   *
   * Arguments:
   * ```js
   * values: {}
   * actions: { setSubmitting: function },
   * addlInfo: {
   *   paymentAmount: number,
   *   method: 'post'|'patch' ,
   *   sendPaymentRequest: function(config: {}|*, url: string|undefined)
   * }
   * ```
   */
  handleSubmit: PropTypes.func.isRequired
};

export default withRouter(PaymentForm);
export { PaymentForm };
