/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty } from 'lodash';
import qs from 'query-string';

import { ResolutionContextType } from 'app/common/constants';
import { CurrencyContext, LocaleContext } from 'app/common/contexts';
import { useLocation, useRefreshEffect } from 'app/common/hooks';
import { Environment, LocalStorageCache } from 'app/common/services';
import log from 'app/common/utils/Log';
import { TenantService } from 'app/core/services';

import * as actions from './tenantStateActions';
import { initialState, reducer } from './tenantStateReducer';
import { useHistory } from 'react-router-dom';

const logger = log.getLogger('core.components.TenantGateway.useTenantState');
const version = `${import.meta.env.VITE_APP_VERSION}`;

function useTenantState() {
  const [resolverState, dispatch] = React.useReducer(reducer, initialState);
  const [state, setState] = React.useState(getInitialState());
  const location = useLocation();
  const history = useHistory();
  const { currentLocale, setAllowedLocales, setCurrentLocale } =
    React.useContext(LocaleContext);
  const { currentCurrency, setAllowedCurrencies, setCurrentCurrency } =
    React.useContext(CurrencyContext);

  React.useEffect(() => {
    TenantCache.put(TENANT_KEY, state);
  }, [state]);

  React.useEffect(() => {
    const currentIdentifier = get(state.application, 'identifierValue');
    const prevLocale = TenantCache.get(LOCALE_KEY);
    const prevCurrency = TenantCache.get(CURRENCY_KEY);

    if (
      resolverState.resolving ||
      isEmpty(currentIdentifier) ||
      currentLocale === prevLocale
    ) {
      return;
    }

    if (currentLocale !== prevLocale) {
      logger.debug('localeChanged', currentIdentifier);
      dispatch(actions.localeChanged(currentIdentifier));
    }

    if (currentCurrency !== prevCurrency) {
      logger.debug('currencyChanged', currentIdentifier);
      dispatch(actions.currencyChanged(currentIdentifier));
    }

    // eslint-disable-next-line
  }, [currentLocale, currentCurrency]);

  React.useEffect(() => {
    if (resolverState.shouldResolve && !resolverState.resolving) {
      (async () => {
        logger.debug(
          'resolving...',
          resolverState.identifier,
          currentLocale,
          currentCurrency
        );
        dispatch(actions.resolving());
        await resolveApplication(
          setState,
          resolverState.identifier,
          currentLocale,
          currentCurrency
        );
        logger.debug('...resolved');
        dispatch(actions.resolved());

        const prevLocale = TenantCache.get(LOCALE_KEY);

        if (currentLocale !== prevLocale) {
          TenantCache.put(LOCALE_KEY, currentLocale);
        }

        const prevCurrency = TenantCache.get(CURRENCY_KEY);

        if (currentCurrency !== prevCurrency) {
          TenantCache.put(CURRENCY_KEY, currentCurrency);
        }
      })();
    }
  }, [
    currentLocale,
    currentCurrency,
    location,
    resolverState.shouldResolve,
    resolverState.resolving,
    resolverState.identifier,
    resolverState.isLegacy
  ]);

  React.useEffect(() => {
    if (resolverState.resolving) {
      return;
    }

    const params = qs.parse(location.search);
    const { identifier: newIdentifier, isLegacy } = getIdentifier(params);
    const currentIdentifier = get(
      state,
      `application.${isLegacy ? 'externalId' : 'identifierValue'}`
    );

    // question: do I need to try to resolve?
    // yes when:
    // -> !state.application
    // -> if dealerLocation has changed since the last resolution attempt
    // -> cache has expired and current app needs a refresh
    // no when:
    // -> there is no new identifier
    // -> new identifier is the same as previously requested site that didn't exist
    // -> tenant cache version mismatch

    const requiresRefresh = state.requiresRefresh;
    const dealerChanged =
      state.resolutionContext !== ResolutionContextType.DEALER &&
      !isEmpty(newIdentifier) &&
      newIdentifier !== currentIdentifier &&
      newIdentifier !== state.requestedIdentifier;
    const shouldFetchApp =
      isEmpty(state.application) || requiresRefresh || dealerChanged;
    if (shouldFetchApp) {
      logger.debug(
        requiresRefresh ? 'do refresh' : 'do resolve',
        currentIdentifier
      );
      let identifier = requiresRefresh ? currentIdentifier : newIdentifier;
      if (!isEmpty(state.versionMismatchCurrentDealer)) {
        identifier = state.versionMismatchCurrentDealer;
      }
      dispatch(
        actions.resolve(
          isLegacy,
          // fallback to default if identifier is empty
          identifier ? identifier : 'default'
        )
      );
    }
    // after processing, clear the identifier from the URL
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has(getApplicationParameter())) {
      queryParams.delete(getApplicationParameter());
      history.replace({
        search: queryParams.toString()
      });
    }
    // eslint-disable-next-line
  }, [
    state.application,
    state.requestedIdentifier,
    state.resolutionContext,
    state.versionMismatchCurrentDealer,
    location.search
  ]);

  useRefreshEffect(
    ({ application, tenant }) => {
      const allowedLocales = new Set(
        get(application, 'allowedLocales', get(tenant, 'allowedLocales', []))
      );
      const allowedCurrencies = new Set(
        get(
          application,
          'allowedCurrencies',
          get(tenant, 'allowedCurrencies', [])
        )
      );

      if (!isEmpty(application) && !allowedLocales.has(currentLocale)) {
        TenantCache.put(
          LOCALE_KEY,
          application.defaultLocale || tenant.defaultLocale
        );
        setCurrentLocale(application.defaultLocale || tenant.defaultLocale);
      }

      if (!isEmpty(application) && allowedCurrencies.has(currentCurrency)) {
        TenantCache.put(
          CURRENCY_KEY,
          application.defaultCurrency || tenant.defaultCurrency
        );
        setCurrentCurrency(
          application.defaultCurrency || tenant.defaultCurrency
        );
      }
    },
    { application: state.application, tenant: state.tenant },
    undefined,
    false
  );

  React.useEffect(() => {
    if (!isEmpty(state.application)) {
      if (!isEmpty(state.application.allowedLocales)) {
        setAllowedLocales(state.application.allowedLocales);
      } else {
        setAllowedLocales(state.tenant.allowedLocales);
      }

      if (!isEmpty(state.application.allowedCurrencies)) {
        setAllowedCurrencies(state.application.allowedCurrencies);
      } else {
        setAllowedCurrencies(state.tenant.allowedCurrencies);
      }
    }
  }, [
    setAllowedLocales,
    setCurrentLocale,
    setAllowedCurrencies,
    setCurrentCurrency,
    state.application,
    state.tenant
  ]);

  return state;
}

const TenantCache = new LocalStorageCache('TenantCache', {
  enableLocal: true,
  enableSession: true,
  // 60 minutes
  ttl: 1000 * 60 * 60
});

const TENANT_KEY = `tenant_resolver_${window.location.hostname}`;
const LOCALE_KEY = `${TENANT_KEY}_locale`;
const CURRENCY_KEY = `${TENANT_KEY}_currency`;

/**
 * @type {{
 *   resolutionContext: string|undefined,
 *   application: {}|undefined,
 *   resolving: boolean,
 *   error: {},
 *   isRequestedActive: boolean|undefined,
 *   requestedIdentifier: string|undefined,
 *   tenant: {}|undefined,
 *   version: string,
 *   versionMismatchCurrentDealer: string|undefined,
 *   isDealerNetwork: boolean|undefined,
 *   isNationalSite: boolean|undefined,
 *   isDealerNetworkOrNationalSite: boolean|undefined,
 * }}
 */
const defaultState = {
  application: undefined,
  error: undefined,
  requestedActive: undefined,
  resolving: true,
  resolutionContext: ResolutionContextType.DEFAULT,
  requestedIdentifier: undefined,
  tenant: undefined,
  defaultApplication: null,
  requested: null,
  version,
  versionMismatchCurrentDealer: undefined,
  isDealerNetwork: undefined,
  isNationalSite: undefined,
  isDealerNetworkOrNationalSite: undefined
};

/**
 * @return {{
 *  resolutionContext: string|undefined,
 *  application: {}|undefined,
 *  resolving: boolean,
 *  error: {},
 *  isRequestedActive: boolean|undefined,
 *  requestedIdentifier: string|undefined,
 *  tenant: {}|undefined,
 *  version: string,
 *  versionMismatchCurrentDealer: string|undefined,
 *  requiresRefresh: boolean
 *  isDealerNetwork: boolean|undefined,
 *  isNationalSite: boolean|undefined,
 *  isDealerNetworkOrNationalSite: boolean|undefined,
 * }}
 */
function getInitialState() {
  const [cachedState, didExpire] = TenantCache.getWithDidExpire(
    TENANT_KEY,
    false
  );

  if (didExpire) {
    logger.debug('Did expire', cachedState);
  }

  const noCacheOrError = !cachedState || cachedState.error;
  if (noCacheOrError) {
    return defaultState;
  }

  const cacheVersionMismatch = cachedState?.version !== version;
  if (cacheVersionMismatch) {
    // If cache version mismatch, this is a new app version so we need to
    // re-fetch the app data. If the user previously had an app selected, we want to
    // preserve that.
    const currentDealer = cachedState?.requestedIdentifier;
    if (currentDealer) {
      return {
        ...defaultState,
        versionMismatchCurrentDealer: currentDealer,
        requiresRefresh: true
      };
    } else {
      return defaultState;
    }
  }

  const params = window.location.search && qs.parse(window.location.search);
  if (!!params && !!params[getApplicationParameter()]) {
    // if we detect an application parameter, then we will ignore the cache, as we are trying to resolve a new app
    return defaultState;
  }
  const isDealerNetwork = get(cachedState, 'application.dealerNetwork', false);
  const isNationalSite = get(cachedState, 'application.nationalSite', false);
  const isDealerNetworkOrNationalSite = isDealerNetwork || isNationalSite;

  return {
    ...cachedState,
    error: null,
    requiresRefresh: didExpire,
    resolving: false,
    resolutionContext:
      cachedState.resolutionContext || ResolutionContextType.DEFAULT,
    versionMismatchCurrentDealer: undefined,
    isDealerNetwork: isDealerNetwork,
    isNationalSite: isNationalSite,
    isDealerNetworkOrNationalSite: isDealerNetworkOrNationalSite
  };
}

/**
 * Get the identifier and whether it is the legacy ADL mobile app one.
 *
 * @param {{}} params - Parsed search params
 *
 * @return {{identifier: string|undefined, isLegacy: boolean}}
 */
function getIdentifier(params) {
  const legacy = params[getLegacyApplicationParameter()];

  if (!isEmpty(legacy)) {
    return { identifier: legacy, isLegacy: true };
  }

  return { identifier: params[getApplicationParameter()], isLegacy: false };
}

function getApplicationParameter() {
  return Environment.get(
    'tenant.resolver.application.parameter',
    'application'
  );
}

function getLegacyApplicationParameter() {
  return Environment.get(
    'tenant.resolver.application.parameter.legacy',
    'locationId'
  );
}

async function resolveApplication(setState, identifier, locale, currency) {
  setState({ resolving: true });

  try {
    const resolution = await TenantService.resolveApplication(
      identifier,
      locale,
      currency
    );
    const isNationalSite = get(resolution, 'application.nationalSite', false);
    const isDealerNetwork = get(resolution, 'application.dealerNetwork', false);
    const isDealerNetworkOrNationalSite = isDealerNetwork || isNationalSite;

    setState({
      ...resolution,
      error: null,
      resolving: false,
      requested: resolution.requested || identifier,
      requestedIdentifier: !resolution.isRequestedActive && identifier,
      resolutionContext:
        get(resolution, 'application.resolutionContext') ||
        ResolutionContextType.DEFAULT,
      version,
      isDealerNetwork: isDealerNetwork,
      isNationalSite: isNationalSite,
      isDealerNetworkOrNationalSite: isDealerNetworkOrNationalSite
    });
  } catch (error) {
    setState({
      error: error,
      resolving: false,
      version
    });
  }
}

export default useTenantState;
