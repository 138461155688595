/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';

import { addParams } from 'app/common/utils/PathUtils';

import { ResultsLayoutContext } from '../../contexts';
import { parseSearch } from '../../utils/FilterUtils';

/**
 * Sets up the {@link ResultsLayoutContext} for browse or search. This will
 * look at the URL params to determine whether to activate the grid or list.
 */
const ResultsLayoutProvider = ({
  breadcrumbs,
  children,
  history,
  location
}) => {
  const [useGrid, setUseGrid] = React.useState(
    get(parseSearch(location.search), 'gallery', 'true') === 'true'
  );
  const updateUseGridParam = React.useCallback(
    value => {
      const newParams = addParams(location.search, { gallery: value });
      history.replace({ ...location, search: newParams });
    },
    [location, history]
  );

  React.useEffect(() => {
    setUseGrid(get(parseSearch(location.search), 'gallery', 'true') === 'true');
  }, [location.search]);

  const context = React.useMemo(
    () => ({ breadcrumbs, useGrid, setUseGrid: updateUseGridParam }),
    [breadcrumbs, useGrid, updateUseGridParam]
  );
  return (
    <ResultsLayoutContext.Provider value={context}>
      {children}
    </ResultsLayoutContext.Provider>
  );
};

export default withRouter(ResultsLayoutProvider);
export { ResultsLayoutProvider };
