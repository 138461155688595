/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'app/common/components';
import {
  useDeletePaymentAccount,
  useFormatMessage,
  useUpdatePaymentAccount
} from 'app/common/hooks';

import messages from '../PaymentCard.messages';

const SavedPaymentActions = props => {
  const { editPayment, isDefault, paymentAccount, refetchPayments } = props;
  const formatMessage = useFormatMessage();
  const { sendCallback: sendDeleteCallback } = useDeletePaymentAccount(
    paymentAccount.id
  );
  // TODO: change to endpoint once we know what it is (editPaymentAccount endpoint or specialized endpoint)
  const { sendCallback: makeDefaultCallback } = useUpdatePaymentAccount(
    paymentAccount.id,
    paymentAccount,
    true
  );

  return (
    <Dropdown className="self-end">
      <Dropdown.Menu.Trigger className="px-4 py-2 bg-white text-sm text-gray-800 rounded hover:bg-gray-200 focus:outline-none focus:shadow-outline">
        Actions
      </Dropdown.Menu.Trigger>
      <Dropdown.Menu>
        <Dropdown.Menu.Item onClick={() => editPayment(paymentAccount)}>
          {formatMessage(messages.editButton)}
        </Dropdown.Menu.Item>
        {!isDefault && (
          <Dropdown.Menu.Item
            onClick={() => makeDefaultCallback().then(() => refetchPayments())}
          >
            {formatMessage(messages.makeDefault)}
          </Dropdown.Menu.Item>
        )}
        <Dropdown.Menu.Item
          onClick={() => sendDeleteCallback().then(() => refetchPayments())}
        >
          {formatMessage(messages.deleteButton)}
        </Dropdown.Menu.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

SavedPaymentActions.propTypes = {
  /** Function to trigger editing of the payment **/
  editPayment: PropTypes.func,
  /** Is this payment the default **/
  isDefault: PropTypes.bool,
  /** The saved payment**/
  paymentAccount: PropTypes.object,
  /** Function to refetch the saved payments **/
  refetchPayments: PropTypes.func
};

export default SavedPaymentActions;
export { SavedPaymentActions };
