/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty } from 'lodash';
import { default as qs } from 'query-string';
import { Redirect, withRouter } from 'react-router-dom';

import { AuthContext } from 'app/auth/contexts';
import { AuthService } from 'app/auth/services';
import { useFormatMessage, useRestApi, useScrollToTop } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import Icon from '../../Icon';
import { PrimaryButton } from '../../buttons';
import { GuestForm, OrderReview } from './components';
import { OrderTrackingContext } from './contexts';
import { useOrderInfo } from '../../../hooks/order';
import messages from './OrderTracking.messages';

const OrderTracking = () => {
  const formatMessage = useFormatMessage();
  const { error, notFound } = React.useContext(OrderTrackingContext);

  useScrollToTop([]);

  return (
    <main className="container relative flex flex-col flex-grow mx-auto px-2 py-4 md:px-6 lg:flex-row lg:px-8 lg:py-8">
      <section className="flex flex-col items-start justify-between w-full mb-8 lg:flex-row">
        <div className="flex flex-col w-full bg-gray-100 rounded shadow lg:flex-1 lg:w-1/2 lg:shadow-md">
          <div>
            <h2 className="px-4 py-2 text-lg capitalize font-bold bg-white rounded-t lg:px-6 lg:text-xl">
              {formatMessage(messages.guestFormTitle)}
            </h2>
            <p className="px-4 py-2 text-sm lg:px-6 lg:py-4 lg:text-base">
              {formatMessage(messages.guestFormSubtitle)}
            </p>
          </div>
          {error && (
            <div className="flex items-center m-4 px-2 py-1 text-sm text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
              <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
              {notFound ? (
                <span>{formatMessage(messages.notFound)}</span>
              ) : (
                <span>{formatMessage(messages.generalError)}</span>
              )}
            </div>
          )}
          <GuestForm />
        </div>
        <div className="my-2 lg:mx-2 lg:my-0" />
        <div className="flex flex-col w-full bg-gray-100 rounded shadow lg:flex-1 lg:w-1/2 lg:shadow-md">
          <div>
            <h2 className="px-4 py-2 text-lg capitalize font-bold bg-white rounded-t lg:px-6 lg:text-xl">
              {formatMessage(messages.signInFormTitle)}
            </h2>
            <p className="px-4 py-2 text-sm lg:px-6 lg:py-4 lg:text-base">
              {formatMessage(messages.signInFormSubtitle)}
            </p>
          </div>
          <div className="self-end pt-4 px-4 pb-6 lg:pt-2 lg:px-6">
            <PrimaryButton onClick={() => AuthService.loginWithRedirect()}>
              {formatMessage(messages.signInFormSubmit)}
            </PrimaryButton>
          </div>
        </div>
      </section>
    </main>
  );
};

const OrderTrackingProvider = ({ location, match }) => {
  const [orderNumber, setOrderNumber] = React.useState(
    get(match, 'params.orderNumber', qs.parse(location.search).orderNumber)
  );
  const [emailAddress, setEmailAddress] = React.useState(
    qs.parse(location.search).emailAddress
  );
  const [order, setOrder] = React.useState(undefined);
  const { guestOrderHistoryContextPath } = useOrderInfo().operations;
  const {
    error,
    exception,
    loading,
    sendCallback: lookupOrder
  } = useRestApi(undefined, undefined, false, true);
  const { isAuthenticated } = React.useContext(AuthContext);

  React.useEffect(() => {
    setOrderNumber(
      get(match, 'params.orderNumber', qs.parse(location.search).orderNumber)
    );
    setEmailAddress(
      get(
        location.state,
        'emailAddress',
        qs.parse(location.search).emailAddress
      )
    );
  }, [location, match]);

  React.useEffect(() => {
    if (!isAuthenticated && !isEmpty(orderNumber) && !isEmpty(emailAddress)) {
      (async () => {
        const config = {
          params: {
            emailAddress
          }
        };
        const url = `${guestOrderHistoryContextPath}/${orderNumber}`;

        try {
          const response = await lookupOrder(config, url);
          setOrder(response);
        } catch (err) {
          logError({
            ...err,
            when: `looking up order ${orderNumber}`
          });
        }
      })();
      return;
    }

    if (isEmpty(orderNumber) || isEmpty(emailAddress)) {
      setOrder(undefined);
    }
  }, [
    guestOrderHistoryContextPath,
    emailAddress,
    isAuthenticated,
    lookupOrder,
    orderNumber
  ]);
  const context = React.useMemo(
    () => ({
      emailAddress,
      orderNumber,
      order,
      setOrder,
      loading,
      error,
      notFound: error && exception.statusCode === 404
    }),
    [emailAddress, order, loading, error, exception, orderNumber]
  );

  if (isAuthenticated) {
    return (
      <Redirect
        to={`/my-account/orders${
          isEmpty(orderNumber) ? '' : `/${orderNumber}`
        }`}
      />
    );
  }

  return (
    <div className="flex-grow bg-gray-200">
      <OrderTrackingContext.Provider value={context}>
        {isEmpty(order) ? <OrderTracking /> : <OrderReview />}
      </OrderTrackingContext.Provider>
    </div>
  );
};

export default withRouter(OrderTrackingProvider);
export { OrderTracking };
