/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { isEmpty } from 'lodash';

import { DID_REDIRECT, REDIRECT } from './searchActionTypes';

const initialState = {
  push: true,
  to: undefined,
  type: null
};

const emptyAction = {
  push: true,
  query: undefined,
  type: null
};

function reducer(state = initialState, action = emptyAction) {
  const { push, query, type } = action;

  switch (type) {
    case DID_REDIRECT:
      return { ...state, shouldRedirect: false };
    case REDIRECT:
      return {
        ...state,
        push,
        shouldRedirect: true,
        to: {
          pathname: '/search',
          search: !isEmpty(query) ? `?q=${query}` : undefined,
          state: {
            referrer: window.location.pathname,
            query
          }
        }
      };
    default:
      return state;
  }
}

export { reducer, initialState };
