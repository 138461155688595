/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { Register } from 'app/auth/components';
import { AuthContext } from 'app/auth/contexts';
import { useValidationSchema } from 'app/auth/components/Register/hooks';
import {
  useCurrentApplication,
  useCurrentTenant,
  useFormatMessage
} from 'app/common/hooks';

import messages from './OrderReviewRegistration.messages';

/**
 * Render component for a guest to register an account when reviewing an order.
 */
const OrderReviewRegistration = ({
  emailAddress,
  fullName,
  firstName,
  lastName
}) => {
  const formatMessage = useFormatMessage();
  const { clientId } = React.useContext(AuthContext);
  const { id: applicationId, customerContextId } =
    useCurrentApplication() || {};
  const { id: tenantId } = useCurrentTenant() || {};
  const validationSchema = useValidationSchema();
  return (
    <article className="shadow-md">
      <header className="px-4 py-2 bg-white rounded-t">
        <h2 className="w-full capitalize text-gray-900 text-lg font-bold lg:text-xl">
          {formatMessage(messages.title)}
        </h2>
      </header>
      <section className="w-full bg-gray-100 rounded">
        <em className="block p-4 not-italic text-xs lg:text-sm">
          {formatMessage(messages.cta)}
        </em>
        <Formik
          initialValues={{
            clientId,
            email: emailAddress || '',
            fullName: fullName || '',
            firstName: firstName || '',
            lastName: lastName || '',
            password: ''
          }}
          onSubmit={(values, formikBag) =>
            Register.handleSubmit(
              { values, formikBag },
              { applicationId, customerContextId, tenantId }
            )
          }
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur
          enableReinitialize
        >
          {formikProps => <Register.Form formikProps={formikProps} />}
        </Formik>
      </section>
    </article>
  );
};

OrderReviewRegistration.propTypes = {
  /** Email address from the order with which to seed the registration form */
  emailAddress: PropTypes.string
};

export default OrderReviewRegistration;
export { OrderReviewRegistration };
