/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import classNames from 'classnames';
import { get, isEqual } from 'lodash';
import { useFormatMessage } from 'app/common/hooks';

import messages from './CartItemAlert.messages';
import React from 'react';
import { CartContext } from 'app/common/contexts';
import { isEmptyArray } from 'formik';

const CartItemAlert = ({ className, unitPriceType, unitPrice, attributes }) => {
  const { cart, resolving } = React.useContext(CartContext);
  const formatMessage = useFormatMessage();
  const originalPrice = get(attributes, 'priceInfo.originalPrice.amount');

  if (
    resolving ||
    isEmptyArray(get(cart, 'offerCodes')) ||
    !isEqual(unitPriceType, 'salePrice') ||
    isEqual(originalPrice, unitPrice.amount)
  ) {
    return null;
  }

  return (
    <div
      className={classNames('flex mt-2 text-yellow-700 font-medium', {
        [className]: !!className
      })}
    >
      {formatMessage(messages.bestPrice)}
    </div>
  );
};

export default CartItemAlert;
export { CartItemAlert };
