/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

import { useFormatMessage } from 'app/common/hooks';

import { ExpirationMonth, ExpirationYear } from './components';
import messages from './ExpirationDate.messages';

const ExpirationDate = ({ disabled = false }) => {
  const formatMessage = useFormatMessage();

  return (
    <fieldset className="flex flex-col items-start justify-start w-full mb-2 last:mb-0">
      <legend className="block mb-4 text-sm text-gray-700 font-bold leading-none">
        {formatMessage(messages.expirationDateLabel)}
      </legend>
      <div className="flex items-end">
        <ExpirationMonth disabled={disabled} />
        <div className="inline-block mx-2 pb-2 text-sm text-gray-900 lg:pb-3 lg:text-base">
          {'/'}
        </div>
        <ExpirationYear disabled={disabled} />
      </div>
      <ErrorMessage name="creditCardExpDateMonth">
        {msg => <div className="mt-1 text-sm text-red-600">{msg}</div>}
      </ErrorMessage>
      <ErrorMessage name="creditCardExpDateYear">
        {msg => <div className="mt-1 text-sm text-red-600">{msg}</div>}
      </ErrorMessage>
      <em className="block mt-1 text-xs text-gray-600 leading-tight not-italic">
        {formatMessage(messages.expirationDateHint)}
      </em>
    </fieldset>
  );
};

ExpirationDate.propTypes = {
  disabled: PropTypes.bool
};

export default ExpirationDate;
export { ExpirationDate };
