/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { find, intersection, isEmpty, values } from 'lodash';

import { ConfigurableItemContext } from 'app/product/contexts';

import { getAsset } from '../../utils/ChoiceUtils';

function useChoiceAsset(choice, isProduct) {
  const { activeOption } = React.useContext(ConfigurableItemContext);
  const primaryAsset = getAsset(choice);
  const [activeAsset, setActiveAsset] = React.useState(primaryAsset);
  const assets = isProduct
    ? isEmpty(choice.product)
      ? choice.assets
      : choice.product.assets
    : null;

  React.useEffect(() => {
    if (isEmpty(activeOption)) {
      setActiveAsset(primaryAsset);
      return;
    }

    const { optionValues } = activeOption;
    const valuesOnly = values(optionValues);

    // match by tags to values
    const assetForOption = find(assets, asset => {
      const { tags = [] } = asset;
      return !isEmpty(intersection(valuesOnly, tags));
    });

    if (isEmpty(assetForOption)) {
      setActiveAsset(primaryAsset);
      return;
    }

    setActiveAsset(assetForOption);
  }, [activeOption, assets, primaryAsset]);

  return activeAsset;
}

export default useChoiceAsset;
