const SET_SEARCH_RADIUS_ACTION = 'SET_SEARCH_RADIUS';
const SET_USING_GEO_LOCATION_ACTION = 'SET_USING_GEO_LOCATION';
const SET_USING_STORE_LOCATION_ACTION = 'SET_USING_STORE_LOCATION';
const SET_LAT_LONG_ACTION = 'SET_LAT_LONG';

/**
 * @typedef {Object} NearestInventoryCommonState
 * @property {number} searchRadius The search radius
 * @property {boolean} usingGeoLocation Are we using the user's geolocation as the search origin?
 * @property {boolean} usingStoreLocation Are we using the store's location as the search origin?
 * @property {Object} latLong The latitude and longitude of the search origin
 * @property {number} latLong.latitude
 * @property {number} latLong.longitude
 */

const commonInitialState = {
  searchRadius: 30,
  usingGeoLocation: false,
  usingStoreLocation: true,
  latLong: undefined
};

/**
 * A reducer function that handles global actions/state related to nearby inventory search. Used
 * in combination with nearestInventoryReducer, which handles state for individual nearby inventory modals.
 * @param {Object} state - The current state.
 * @param {Object} action - The action to be handled.
 * @param {string} action.type - The type of the action.
 * @param {Object} action.payload - The payload of the action.
 * @returns {Object} - The new state after handling the action.
 */
const commonReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SEARCH_RADIUS_ACTION:
    case SET_USING_GEO_LOCATION_ACTION:
    case SET_USING_STORE_LOCATION_ACTION:
    case SET_LAT_LONG_ACTION:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};

/**
 * Set the search radius for nearby stores
 * @param searchRadius The search radius
 * @return {{payload: {searchRadius}, type: string}}
 * @constructor
 */
const SET_SEARCH_RADIUS = searchRadius => ({
  type: SET_SEARCH_RADIUS_ACTION,
  payload: { searchRadius }
});

/**
 * Sets the latitude and longitude of the search origin
 * @param latLong The latitude and longitude of the search origin
 * @return {{payload: {latLong, error: {}}, type: string}}
 */
const SET_LAT_LONG = latLong => ({
  type: SET_LAT_LONG_ACTION,
  payload: { latLong, error: {} }
});

/**
 * Set whether we are using the user's geolocation as the search origin. This indicates
 * that we should use the user's lat/long as the nearby inventory search origin.
 *
 * This is always the inverse of usingStoreLocation.
 * @param usingGeoLocation
 * @return {{payload: {usingGeoLocation, usingStoreLocation: boolean}, type: string}}
 */
const SET_USING_GEO_LOCATION = usingGeoLocation => ({
  type: SET_USING_GEO_LOCATION_ACTION,
  payload: { usingGeoLocation, usingStoreLocation: !usingGeoLocation }
});

/**
 * Set whether we are using the store's location as the search origin. This indicates
 * that we should use the store's lat/long as the nearby inventory search origin.
 *
 * This is always the inverse of usingGeoLocation.
 * @param usingStoreLocation
 * @return {{payload: {usingGeoLocation: boolean, usingStoreLocation}, type: string}}
 */
const SET_USING_STORE_LOCATION = usingStoreLocation => ({
  type: SET_USING_STORE_LOCATION_ACTION,
  payload: { usingStoreLocation, usingGeoLocation: !usingStoreLocation }
});

export {
  commonInitialState,
  commonReducer,
  SET_SEARCH_RADIUS,
  SET_USING_GEO_LOCATION,
  SET_USING_STORE_LOCATION,
  SET_LAT_LONG
};
