/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  progressSubmitted: {
    id: 'OrderTracking.OrderReview.ProgressBar.submitted',
    defaultMessage: 'Submitted'
  },
  progressShipped: {
    id: 'OrderTracking.OrderReview.ProgressBar.shipped',
    defaultMessage: 'Shipped'
  },
  progressDelivered: {
    id: 'OrderTracking.OrderReview.ProgressBar.delivered',
    defaultMessage: 'Delivered'
  },
  progressPickUpReady: {
    id: 'OrderTracking.OrderReview.ProgressBar.pickUpReady',
    defaultMessage: 'Ready for Pick Up'
  },
  progressPickedUp: {
    id: 'OrderTracking.OrderReview.ProgressBar.pickedUp',
    defaultMessage: 'Picked Up'
  },
  progressCancelled: {
    id: 'OrderTracking.OrderReview.ProgressBar.cancelled',
    defaultMessage: 'Cancelled'
  },
  progressCancellationRequest: {
    id: 'OrderTracking.OrderReview.ProgressBar.cancellationRequest',
    defaultMessage: 'Cancellation Requested'
  }
});
