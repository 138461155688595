/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Checkout.Payment.title',
    defaultMessage: 'Payment Info'
  },
  confirmationError: {
    id: 'Checkout.Payment.confirmation-error',
    defaultMessage:
      'We were unable to confirm your payment information. Please re-enter it or use a different method and try checking out again.'
  },
  legend: {
    id: 'Checkout.Payment.legend',
    defaultMessage: 'Select payment method'
  },
  paypal: {
    id: 'Checkout.Payment.paypal',
    defaultMessage: 'PayPal'
  },
  stripe: {
    id: 'Checkout.Payment.stripe',
    defaultMessage: 'Credit Card'
  },
  payInStore: {
    id: 'Checkout.Payment.pay-in-store',
    defaultMessage: 'Pay In Store'
  }
});
