/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useContext, useMemo } from 'react';
import { get, isNil, merge } from 'lodash';

import { CartContext } from 'app/common/contexts';
import { Environment } from 'app/common/services';

import useCartInfo from '../useCartInfo';
import useModifyCartRequest from '../useModifyCartRequest';

/**
 * Hook to use to send requests about specific cart items.
 *
 * @param {string} itemId - ID of the cart item
 * @param {Object} config - Object containing additional request config such as
 *     the request params or request method
 * @param {boolean} sendImmediate - Whether to send the request immediately upon
 *     using the hook. If false, then the caller is responsible for triggering
 *     the `sendCallback`. Defaults to `true`.
 * @param {boolean} appendIdToUrl - Whether the `itemId` should be
 *     appended onto the base URL such as for updates and deletes. Default is
 *     true.
 *
 * @return {{error: bool, exception: Object, loading: bool, response: Object, sendCallback: function}}
 */
function useCartItemApi(
  itemId,
  config,
  sendImmediate = true,
  appendIdToUrl = true
) {
  const { cart, guestToken } = useContext(CartContext);
  const cartId = get(cart, 'id', undefined);
  const { baseUrl, itemsContextPath } = useCartInfo().operations;
  const fetchUrl = `${baseUrl}/${cartId}${itemsContextPath}${
    appendIdToUrl ? `/${itemId}` : ''
  }`;

  const cartVersionHeaderName = Environment.get(
    'cart.version.header',
    'X-Cart-Version'
  );
  const cartGuestTokenHeaderName = Environment.get(
    'cart.token.header',
    'X-Guest-Token'
  );

  const finalConfig = useMemo(() => {
    return merge(
      {},
      {
        headers: !isNil(guestToken)
          ? {
              [cartVersionHeaderName]: get(cart, 'version', 0),
              [cartGuestTokenHeaderName]: guestToken.tokenString
            }
          : {
              [cartVersionHeaderName]: get(cart, 'version', 0)
            }
      },
      config
    );
  }, [
    config,
    cartVersionHeaderName,
    cartGuestTokenHeaderName,
    cart,
    guestToken
  ]);

  return useModifyCartRequest(fetchUrl, finalConfig, sendImmediate);
}

export default useCartItemApi;
