/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useFormatMessage, useToggle } from 'app/common/hooks';
import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { includes, isString, toLower } from 'lodash';
import PropTypes from 'prop-types';
import messages from '../../../../ResultsBody.messages.js';

import { Icon } from 'app/common/components';

import { FilterContext } from '../../contexts';

/**
 * Render component for a single Filter/Facet value.
 *
 * @visibleName Browse and Search Results Filter Values
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const ResultsFilterValue = ({
  active = false,
  handleChange,
  multiSelect = true,
  name,
  quantity = 0,
  value
}) => {
  const { lockFilters } = useContext(FilterContext);
  const formatMessage = useFormatMessage();
  const [isActive, toggleIsActive] = useToggle(active && quantity > 0);
  const [valueToUse, label] =
    isString(value) && includes(value, '||')
      ? value.split('||')
      : [
          value,
          name === 'inventoryAvailableForApplicationScopes'
            ? formatMessage(messages.availableForPurchase)
            : value
        ];

  useEffect(() => {
    toggleIsActive(active);
  }, [active, toggleIsActive]);

  return (
    <li className="block px-2 text-sm w-full">
      <label
        className={classNames(
          'group relative flex items-center p-2 leading-none lg:px-0',
          {
            'hover:cursor-not-allowed': !!lockFilters,
            'hover:cursor-pointer': !lockFilters
          }
        )}
      >
        <input
          checked={isActive}
          className={classNames('absolute opacity-0', {
            'hover:cursor-not-allowed': !!lockFilters,
            'hover:cursor-pointer': !lockFilters
          })}
          disabled={lockFilters}
          name={multiSelect ? `${name}=${valueToUse}` : name}
          onChange={e => {
            if (lockFilters) {
              return;
            }

            toggleIsActive();
            return handleChange(e, valueToUse);
          }}
          type={multiSelect ? 'checkbox' : 'radio'}
        />
        <Icon
          className={classNames('mr-3 bg-white', {
            'text-gray-600 group-hover:text-gray-700':
              !isActive && !lockFilters,
            'text-gray-400 ': !isActive && lockFilters,
            'text-primary-500': isActive && !lockFilters,
            'text-primary-100': isActive && lockFilters
          })}
          name={
            isActive
              ? ['far', multiSelect ? 'check-square' : 'check-circle']
              : ['far', multiSelect ? 'square' : 'circle']
          }
        />
        <div
          className={classNames('capitalize', {
            'text-gray-600 group-hover:text-gray-700':
              !isActive && !lockFilters,
            'text-gray-400': !isActive && lockFilters,
            'text-gray-900': isActive && !lockFilters,
            'text-gray-600': isActive && lockFilters
          })}
        >
          {`${toLower(label)} (`}
          <span className="font-semibold">{quantity}</span>)
        </div>
      </label>
    </li>
  );
};

ResultsFilterValue.propTypes = {
  /** Whether this value is currently selected */
  active: PropTypes.bool,
  /**
   * Method to defer to for handling toggling the current filter instead of the
   * default.
   */
  handleChange: PropTypes.func,
  /** Whether multiple values can be selected at once */
  multiSelect: PropTypes.bool,
  /** The name identifying the parent Filter */
  name: PropTypes.string,
  /** The number of items in the result set that match this value */
  quantity: PropTypes.number,
  /** If not ranged, then this is the explicit value */
  value: PropTypes.string,
  /** Whether the value should be searchable by text */
  textSearchable: PropTypes.bool
};

export default ResultsFilterValue;
export { ResultsFilterValue };
