import {
  useCurrentApplication,
  useCurrentTenant,
  useRefreshEffect,
  useRestApi,
  useToggle
} from 'app/common/hooks';
import { get, isEmpty, isNil, merge } from 'lodash';
import { useEffect, useMemo } from 'react';
import { logError } from 'app/common/utils/ApiErrorUtils';
import { useBrowseRequestConfig } from 'app/search-and-browse/shared/hooks';
import useBrandsInfo from './useBrandsInfo.js';

/**
 * Populate filtered brand names based on the selected category and subcategory.
 * @param {MinCategory} selectedCategory
 * @param {MinCategory} selectedSubCat
 * @param {function} setFilteredBrandNames
 */
function useBrandsCatalogSearchApi(
  selectedCategory,
  selectedSubCat,
  setFilteredBrandNames
) {
  const { id: applicationId, customerContextId } =
    useCurrentApplication() || {};
  const { id: tenantId } = useCurrentTenant() || {};
  const [shouldRefresh, toggleRefresh] = useToggle(false);

  const categoryId = getCategoryId(selectedCategory, selectedSubCat);
  const searchConfig = useBrowseRequestConfig(categoryId, '', 1, [
    'attr-BRAND'
  ]);
  const { brandCatalogSearchPath } = useBrandsInfo();
  const contextConfig = useMemo(() => {
    return {
      applicationId: applicationId,
      customerContextId: customerContextId,
      tenantId: tenantId
    };
  }, [applicationId, customerContextId, tenantId]);

  const {
    error,
    exception,
    response,
    sendCallback: catalogSearch,
    requestStatus
  } = useRestApi(brandCatalogSearchPath, {}, false);

  useRefreshEffect(
    () => {
      toggleRefresh(false);
      const config = merge({}, searchConfig, contextConfig);
      catalogSearch(config);
    },
    [searchConfig, contextConfig, catalogSearch, shouldRefresh],
    () => shouldRefresh,
    false
  );

  useEffect(() => {
    if (isNil(selectedCategory) || isEmpty(selectedCategory)) {
      return;
    }
    toggleRefresh(true);
    // eslint-disable-next-line
  }, [selectedCategory, selectedSubCat]);

  useEffect(() => {
    if (error) {
      logError({
        ...exception,
        when: 'Filtering brands by category'
      });
      return;
    }

    if (response) {
      processResponse(response, setFilteredBrandNames);
    }
  }, [response, setFilteredBrandNames, error, exception]);
}

function getCategoryId(selectedCategory, selectedSubCat) {
  if (isEmpty(selectedCategory)) {
    return null;
  }

  return isEmpty(selectedSubCat) ? selectedCategory.id : selectedSubCat.id;
}

function processResponse(response, setFilteredBrands) {
  const brandFacet = get(response, 'facets', []).find(
    facet => facet.facet.name === 'attr-BRAND'
  );

  const brandNames = brandFacet.values.map(entry => entry.value);
  if (isEmpty(brandNames)) {
    brandNames.push('EMPTY_BRAND_RESULTS');
  }
  setFilteredBrands(brandNames);
}

export default useBrandsCatalogSearchApi;
