/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
/**
 * Indicates the whether the specified distance to/from an InventoryLocation is
 * in MILES or KILOMETERS.
 */
const InventoryDistanceType = Object.freeze({
  /**
   * Indicates distance is in Miles
   */
  MILES: 'MILES',
  /**
   * Indicates distance is in Kilometers
   */
  KILOMETERS: 'KILOMETERS'
});

export default InventoryDistanceType;
