/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useFormatNumber } from 'app/common/hooks';
import React from 'react';
import classNames from 'classnames';
import { get, isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';

import { Currency, Icon } from 'app/common/components';

import { AttributeOption } from './components';

const CartItemOptions = ({
  className,
  attributeChoices,
  dependentCartItems,
  product
}) => {
  const formatNumber = useFormatNumber();
  const productDescription = get(product, 'description');

  return (
    <section className={classNames({ [className]: !!className })}>
      {!isEmpty(productDescription) && (
        <p className="text-xs text-gray-600 leading-snug mb-2 md:text-sm">
          {productDescription}
        </p>
      )}
      {map(attributeChoices, (attributeChoiceValue, attributeName) => (
        <AttributeOption {...attributeChoiceValue} key={attributeName} />
      ))}
      {!isEmpty(dependentCartItems) && (
        <ul className="flex flex-col text-xs text-gray-600 md:text-sm list-none">
          {dependentCartItems.map(depCartItem => (
            <li key={depCartItem.id} className="flex flex-wrap items-center">
              <div>
                {depCartItem.name}
                {':'}
              </div>
              <Currency
                className="ml-1"
                {...depCartItem.unitPriceWithDependentItems}
              />
              <Icon className="ml-2 text-center" name="times" />
              <div className="ml-2">{formatNumber(depCartItem.quantity)}</div>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
};

CartItemOptions.propTypes = {
  attributeChoices: PropTypes.object,
  dependentCartItems: PropTypes.arrayOf(PropTypes.object)
};

export default CartItemOptions;
export { CartItemOptions };
