/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { CartContext } from 'app/common/contexts';
import { useDelayHover } from 'app/common/hooks';

import { MinicartDialog, MinicartIcon } from './components';

/**
 * Render component for the mini shopping cart icon and dialog.
 *
 * @visibleName Minicart
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const Minicart = ({ className, disabled = false }) => {
  const { isMinicartOpen, toggleMinicartOpen = noop } =
    React.useContext(CartContext);

  const { handleMouseEnter, handleMouseLeave } = useDelayHover(
    isMinicartOpen,
    toggleMinicartOpen
  );

  React.useEffect(() => {
    if (disabled) {
      toggleMinicartOpen(false);
    }
  }, [disabled, toggleMinicartOpen]);

  return (
    <div
      id="minicart"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={cx('flex relative flex-col', {
        [className]: !!className
      })}
    >
      <MinicartIcon />
      <MinicartDialog isOpen={isMinicartOpen && !disabled} />
    </div>
  );
};

Minicart.propTypes = {
  className: PropTypes.string,
  /** Controls whether the dropdown can be triggered */
  disabled: PropTypes.bool
};

export default Minicart;
export { Minicart };
