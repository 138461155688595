/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty, merge } from 'lodash';
import PropTypes from 'prop-types';

import { PriceContext } from 'app/common/contexts';
import {
  defaultRefreshEffectCondition,
  useCatalogInfo,
  usePriceContextHeader,
  usePriceInfoContextHeader,
  useRefreshEffect,
  useRestApi
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';
import { NotFound } from 'app/layout/components';

import DefaultPDP, { DefaultPDPSkeleton } from '../../components/DefaultPDP';

/**
 * Helper component used to both fetch the product matching the current
 * location and determine which product UI component to use to render the
 * data.
 *
 * @visibleName Category Renderer
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const ProductRenderer = ({ location }) => {
  const { pathname } = location;
  const {
    productInfo: { detailsUri, fetchUrl, routeBaseContext }
  } = useCatalogInfo();
  const detailsUrl = `${fetchUrl}${detailsUri}`;
  const offset = pathname.indexOf(routeBaseContext) + routeBaseContext.length;
  const productUris = pathname.substring(offset);
  const { hasLoadedPriceLists } = React.useContext(PriceContext);
  const priceContextHeader = usePriceContextHeader();
  const priceInfoContextHeader = usePriceInfoContextHeader();
  const config = React.useMemo(
    () =>
      merge({}, priceContextHeader, priceInfoContextHeader, {
        params: { productUris, includeTopBrand: true }
      }),
    [priceContextHeader, priceInfoContextHeader, productUris]
  );
  const {
    exception: fetchException,
    error: fetchError,
    loading: fetching,
    response: fetchResponse,
    sendCallback: getProductDetails
  } = useRestApi(detailsUrl, config, false);

  useRefreshEffect(
    getProductDetails,
    { hasLoadedPriceLists, config },
    refreshCondition,
    hasLoadedPriceLists
  );

  if (fetchError) {
    logError({
      ...fetchException,
      when: `fetching a Product matching the path ${productUris}`
    });

    return <NotFound />;
  }

  if (
    fetching ||
    fetchResponse === undefined ||
    fetchResponse.products === undefined
  ) {
    return <DefaultPDPSkeleton />;
  }

  if (isEmpty(fetchResponse) || isEmpty(fetchResponse.products)) {
    return <NotFound />;
  }

  switch (fetchResponse.displayTemplate) {
    default:
      return <DefaultPDP product={fetchResponse.products[0]} />;
  }
};

function refreshCondition(dependencies, prevDependencies) {
  return (
    defaultRefreshEffectCondition(dependencies, prevDependencies) &&
    dependencies.hasLoadedPriceLists
  );
}

ProductRenderer.propTypes = {
  /** Represents the current location of the app */
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    hash: PropTypes.string,
    status: PropTypes.object
  }).isRequired
};

export default ProductRenderer;
export { ProductRenderer };
