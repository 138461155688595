import classNames from 'classnames';
import React from 'react';

/**
 * Renders the GPS location pin icon.
 * @param props
 * @returns {Element}
 * @constructor
 */
const LocationPin = props => {
  const { className, containerProps, color } = props;
  return (
    <span
      className={classNames('inline-flex items-center justify-center', {
        [className]: !!className
      })}
      {...containerProps}
    >
      <svg
        viewBox="0 0 491.582 491.582"
        className={'svg-inline--fa'}
        fill={color}
      >
        <g>
          <g>
            <path
              d="M245.791,0C153.799,0,78.957,74.841,78.957,166.833c0,36.967,21.764,93.187,68.493,176.926
			c31.887,57.138,63.627,105.4,64.966,107.433l22.941,34.773c2.313,3.507,6.232,5.617,10.434,5.617s8.121-2.11,10.434-5.617
			l22.94-34.771c1.326-2.01,32.835-49.855,64.967-107.435c46.729-83.735,68.493-139.955,68.493-176.926
			C412.625,74.841,337.783,0,245.791,0z M322.302,331.576c-31.685,56.775-62.696,103.869-64.003,105.848l-12.508,18.959
			l-12.504-18.954c-1.314-1.995-32.563-49.511-64.007-105.853c-43.345-77.676-65.323-133.104-65.323-164.743
			C103.957,88.626,167.583,25,245.791,25s141.834,63.626,141.834,141.833C387.625,198.476,365.647,253.902,322.302,331.576z"
            />
            <path
              d="M245.791,73.291c-51.005,0-92.5,41.496-92.5,92.5s41.495,92.5,92.5,92.5s92.5-41.496,92.5-92.5
			S296.796,73.291,245.791,73.291z M245.791,233.291c-37.22,0-67.5-30.28-67.5-67.5s30.28-67.5,67.5-67.5
			c37.221,0,67.5,30.28,67.5,67.5S283.012,233.291,245.791,233.291z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

export { LocationPin };
