/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

import { useMemo } from 'react';
import { merge } from 'lodash';

import useRestApi from '../../useRestApi';
import usePersonalizationInfo from '../usePersonalizationInfo';

/**
 * Fetch content targeter data.
 * @param targeterContext - the context to use for the request
 * @param config - optional config to use for the request. This is used if fetching content for a specific application.
 * @param sendImmediate - Send the request immediately?
 * @return {{error: boolean, exception: Object, loading: boolean, response: Object, sendCallback: restApiCallback}|*}
 */
function useContentTargeterApi(targeterContext, config, sendImmediate = true) {
  const { contentTargeterInfo } = usePersonalizationInfo();
  const finalConfig = useMemo(
    () => merge({}, config, { data: targeterContext, method: 'post' }),
    [config, targeterContext]
  );

  return useRestApi(contentTargeterInfo.fetchUrl, finalConfig, sendImmediate);
}

export default useContentTargeterApi;
