/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';
import { get, map, values } from 'lodash';

import { Breadcrumbs } from 'app/common/components';
import { PriceType } from 'app/common/constants';
import { useCatalogInfo } from 'app/common/hooks';
import { PdpContext } from 'app/product/contexts';
import { ConfigurableItem } from 'app/product/helpers';

import {
  AlternateProducts,
  Assets,
  MerchandisingInfo,
  PdpHeader
} from './components';
import { Helmet } from './helpers';
import useProductDocuments from 'app/product/hooks/useProductDocuments/useProductDocuments';

/**
 * Render component for product detail pages.
 *
 * @visibleName Default Product Detail Page Template
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const DefaultPDP = ({ product }) => {
  const {
    categoryInfo: { routeBaseContext = '' }
  } = useCatalogInfo();
  let { breadcrumbs } = product;
  breadcrumbs = React.useMemo(
    () => map(breadcrumbs, b => ({ ...b, uri: routeBaseContext + b.uri })),
    [breadcrumbs, routeBaseContext]
  );
  const documents = useProductDocuments(get(product, 'id'));
  const [addToCartErrors, setAddToCartErrors] = React.useState(undefined);
  const productAttributes = get(product, 'attributes');
  const context = React.useMemo(
    () => ({
      addToCartErrors,
      breadcrumbs,
      setAddToCartErrors,
      product,
      documents
    }),
    [addToCartErrors, breadcrumbs, setAddToCartErrors, product, documents]
  );

  return (
    <ConfigurableItem item={product}>
      <PdpContext.Provider value={context}>
        <main className="relative flex-grow min-h-screen p-4 sm:py-8 sm:px-6 lg:px-8">
          <Helmet product={product} />
          <Breadcrumbs
            className="container"
            breadcrumbs={breadcrumbs}
            productView={true}
            hasPrimaryCategory={get(productAttributes, 'hasPrimaryCategory')}
          />
          <section className="container flex flex-col justify-start mt-2 sm:mt-5 sm:flex-row sm:flex-wrap">
            <PdpHeader className="sm:hidden" />
            <Assets />
            <MerchandisingInfo />
            <AlternateProducts />
            {/* Other Details as tabs */}
          </section>
        </main>
      </PdpContext.Provider>
    </ConfigurableItem>
  );
};

DefaultPDP.propTypes = {
  /** Product to display */
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string,
    name: PropTypes.string.isRequired,
    priceInfo: PropTypes.shape({
      price: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired
      }).isRequired,
      priceType: PropTypes.oneOf(values(PriceType)).isRequired,
      priceTypeDetails: PropTypes.shape(
        values(PriceType).reduce((acc, type) => {
          acc[type] = PropTypes.shape({
            bestPrice: PropTypes.shape({
              amount: PropTypes.number.isRequired,
              currency: PropTypes.string.isRequired
            }).isRequired,
            type: PropTypes.string.isRequired
          });
          return acc;
        }, {})
      )
    }),
    promotionalProducts: PropTypes.object,
    sku: PropTypes.string,
    upc: PropTypes.string
  }).isRequired
};

export default DefaultPDP;
export { DefaultPDP };
