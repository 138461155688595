/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { Button } from 'app/common/components';
import { useProductClick } from 'app/common/hooks/gtm';

import messages from './ItemHeader.messages';

/**
 * Render component for cart item's header info such as name.
 */
const ItemHeader = ({ name, url, gtmItem, attributes }) => {
  const sendProductClick = useProductClick(gtmItem);
  return (
    <header>
      <h5 className="mb-2 font-medium leading-tight">
        <Button
          className="capitalize text-gray-700 hover:text-gray-800 focus:text-gray-900 active:text-gray-500"
          to={loc => ({
            ...loc,
            pathname: url,
            state: {
              ...get(loc, 'state', {}),
              breadcrumbs: [
                { uri: loc.pathname, label: messages.breadcrumbLabel },
                { label: name }
              ]
            }
          })}
          onClick={() => {
            sendProductClick();
          }}
        >
          {name}
        </Button>
      </h5>
      <h6 className="mt-2 text-gray-700 text-sm leading-tight">
        {get(attributes, 'productDescription')}
      </h6>
    </header>
  );
};

ItemHeader.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default ItemHeader;
export { ItemHeader };
