/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import { usePossibleAttributeChoiceCombos } from 'app/product/hooks';
import {
  compileUnusedAttributeInfo,
  getActualVariantAttributeCombinations,
  getAttributeValueToRelatedCombinationsMap
} from 'app/product/utils/AttributeChoiceUtils';

/**
 * Returns the unused and invalid attribute combinations.
 *
 * @param {[{}]} options - List of a product's product-options
 * @param {[{}]} variants - List of a product's variants
 *
 * @return {{
 *   attributeValueToRelatedCombinationsMap: {},
 *   unusedAttributeNames: Set<String>,
 *   unusedAttributeValues: {}
 * }}
 */
function useAttributeOptionInfo(options, variants) {
  const { attributeChoiceOptions, possibleAttributeCombos } =
    usePossibleAttributeChoiceCombos(options);
  const [attrValToRelatedCombosMap, setAttrValToRelatedCombosMap] =
    React.useState({});
  const [unusedAttrNames, setUnusedAttrNames] = React.useState(new Set());
  const [unusedAttrValues, setUnusedAttrValues] = React.useState({});

  React.useEffect(() => {
    const actualCombos = getActualVariantAttributeCombinations(variants);
    setAttrValToRelatedCombosMap(
      getAttributeValueToRelatedCombinationsMap(actualCombos)
    );

    const {
      unusedAttributeNames: unusedNames,
      unusedAttributeValuesMap: unusedValues
    } = compileUnusedAttributeInfo(actualCombos, attributeChoiceOptions);

    setUnusedAttrNames(unusedNames);
    setUnusedAttrValues(unusedValues);
  }, [attributeChoiceOptions, possibleAttributeCombos, variants]);

  return React.useMemo(
    () => ({
      attributeValueToRelatedCombinationsMap: attrValToRelatedCombosMap,
      unusedAttributeNames: unusedAttrNames,
      unusedAttributeValues: unusedAttrValues
    }),
    [attrValToRelatedCombosMap, unusedAttrNames, unusedAttrValues]
  );
}

export default useAttributeOptionInfo;
