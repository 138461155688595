/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { default as qs } from 'query-string';
import { omit, pick } from 'lodash';

import {
  clear,
  setError,
  setInitialByCache,
  setInitialByParams,
  setInvalidated,
  setValidated,
  setValidating
} from 'app/common/actions/preview';
import { defaultPreviewState } from 'app/common/contexts';
import {
  useCurrentApplication,
  useCurrentTenant,
  useInterval,
  useHistory,
  useLocation
} from 'app/common/hooks';
import {
  Environment,
  LocalStorageCache,
  RequestService
} from 'app/common/services';

import reducer from '../reducers/preview';

/**
 * Hook used for provisioning and managing the PreviewContext state.
 *
 * @return {[PreviewState, Function]}
 */
export default function useProvidePreviewContext() {
  const application = useCurrentApplication();
  const tenant = useCurrentTenant();
  const params = usePreviewParams();
  const [getCache, setCache] = usePreviewCache();
  const initialState = useInitialState(params, getCache);
  const context = React.useReducer(reducer, initialState);
  const [state, dispatch] = context;

  React.useEffect(() => {
    if (
      tenant &&
      application &&
      state.isActive &&
      !state.isValidated &&
      !state.isValidating
    ) {
      (async () => {
        try {
          dispatch(setValidating(true));
          const response = await validatePreviewToken(
            state.token,
            tenant,
            application
          );
          if (response.valid) {
            dispatch(setValidated(response));
          } else {
            dispatch(setInvalidated(response));
          }
        } catch (error) {
          dispatch(setError(error));
        }
      })();
    }
  }, [
    application,
    dispatch,
    state.isActive,
    state.isValidated,
    state.isValidating,
    state.token,
    tenant
  ]);

  useCleanupParams(state);

  useClearOnExpiredToken(context);

  React.useEffect(() => {
    if (state.isActive) {
      setCache(state);
    } else {
      setCache(null);
    }
  }, [setCache, state]);

  return context;
}

function useInitialState(params, getCache) {
  return React.useMemo(() => {
    if (params.previewToken) {
      return reducer(
        defaultPreviewState,
        setInitialByParams(params.previewToken, params.sandboxId)
      );
    }

    const cachedState = getCache();
    if (!!cachedState) {
      if (!!params.sandboxId && !!cachedState.sandboxById[params.sandboxId]) {
        return reducer(
          defaultPreviewState,
          setInitialByCache({
            ...cachedState,
            sandboxId: params.sandboxId
          })
        );
      }
      return reducer(defaultPreviewState, setInitialByCache(cachedState));
    }

    return defaultPreviewState;
  }, [getCache, params.previewToken, params.sandboxId]);
}

/**
 * Parse and return the preview-related request parameters
 *
 * @returns {{ [previewToken]: string, [sandboxId]: string }}
 */
function usePreviewParams() {
  const location = useLocation();
  const params = qs.parse(location.search || '?');
  return pick(params, ['previewToken', 'sandboxId']);
}

/**
 * Clean up the preview request parameters after consumption was successful.
 *
 * @param {PreviewState} state
 */
function useCleanupParams(state) {
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    if (state.isValidated && !state.isValidating) {
      const params = qs.parse(location.search || '?');
      if (params.previewToken) {
        history.replace({
          ...location,
          search: qs.stringify(omit(params, ['previewToken', 'sandboxId']))
        });
      }
    }
  }, [history, location, state.isValidated, state.isValidating]);
}

/**
 * Checks every five seconds to see if the token is expected to be expired.
 * If the token is found to expired, this will clear the sandbox state.
 *
 * @param {PreviewState} state the state
 * @param {PreviewDispatch} dispatch the dispatch function
 */
function useClearOnExpiredToken([state, dispatch]) {
  useInterval(
    () => {
      const expirationTimeMs = new Date(state.expirationTime).getTime();
      const nowMs = Date.now();
      if (nowMs >= expirationTimeMs) {
        dispatch(clear());
      }
    },
    state.isActive && state.isValidated && !!state.expirationTime ? 1000 : null
  );
}

/**
 * The local storage cache for the preview state.
 *
 * @type {LocalStorageCache}
 */
const previewCache = new LocalStorageCache('previewCache', {
  ttl: 15 * 60 * 1000 // 15 minutes
});

/**
 * @callback
 * @typedef {Function} getPreviewCache
 * @return {PreviewState} the cached state
 */

/**
 * @callback
 * @typedef {Function} setPreviewCache
 * @param {PreviewState | null} state - the next state to cache
 */

/**
 * Returns the getter and setter for the preview state.
 *
 * @return {[getPreviewCache, setPreviewCache]}
 */
function usePreviewCache() {
  const getCache = React.useCallback(() => {
    return previewCache.get('state') || null;
  }, []);
  const setCache = React.useCallback(state => {
    if (!state) {
      previewCache.delete('state');
    } else {
      previewCache.put('state', state);
    }
  }, []);
  return [getCache, setCache];
}

/**
 * Attempts to validate the provided token string against the current tenant and
 * application with the sandbox service and returns the contents.
 *
 * @param {string} token the preview token string
 * @param {Tenant} tenant the current tenant
 * @param {Application} application the current application
 * @returns {Promise<{ valid: boolean, [content]: PreviewToken, [sandboxes]: Array.<Sandbox>, [reason]: string}>}
 */
async function validatePreviewToken(token, tenant, application) {
  const response = await RequestService.post(
    {
      headers: {
        'Content-Type': 'application/json'
      },
      data: token,
      secure: false,
      url: Environment.get(
        'sandbox.preview.token.url',
        '/sandbox/preview/validate'
      )
    },
    {
      applicationId: application.id,
      tenantId: tenant.id
    }
  );
  return response.data;
}
