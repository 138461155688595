import { FulfillmentFee } from './index';
import { isEmpty } from 'lodash';
import { Currency, Icon, Modal, PrimaryButton } from 'app/common/components';
import messages from '../FulfillmentInfoWithOptions.messages';
import React from 'react';
import { useFormatMessage } from 'app/common/hooks';

const FulfillmentFees = ({ option }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const modalRef = React.useRef(undefined);
  const formatMessage = useFormatMessage();

  if (isEmpty(option.productFulfillmentFees)) {
    return <></>;
  }

  return (
    <>
      {/* eslint-disable-next-line*/}
      <a
        href={'#'}
        className={'text-base text-red-800 hover:cursor-pointer'}
        onClick={e => {
          e.preventDefault();
          setModalOpen(true);
        }}
        aria-label={formatMessage(messages.unavailableAriaLabel)}
      >
        <span className={'text-red-800 box-border'}>
          <Icon name={'exclamation-circle'} className={'pl-1'} />
        </span>
      </a>
      <Modal
        isOpen={modalOpen}
        ref={modalRef}
        dialogClassName="top-1/4"
        size={Modal.Size.SMALL}
      >
        <Modal.Header>
          <Modal.Header.Title className="flex items-center">
            <span className="font-medium lg:text-xl">
              {formatMessage(messages.fulfillmentFeesTitle)}
            </span>
          </Modal.Header.Title>
        </Modal.Header>
        <Modal.Body className="text-gray-700 bg-gray-100">
          <div className="items-center mb-4 px-2 py-1 text-sm leading-snug border border-solid rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
            <table>
              <thead className={'font-bold text-base'}>
                {formatMessage(messages.fulfillmentFees)}
              </thead>
              <tbody className={'fee-table-section'}>
                <tr>
                  <td>{formatMessage(messages.fulfillmentFeesBase)}</td>
                  <td>
                    <Currency
                      amount={option.basePrice.amount}
                      currency={option.basePrice.currency}
                    />
                  </td>
                </tr>
              </tbody>
              <tbody className={'fee-table-section'}>
                <tr>
                  <th className={'text-left'}>
                    {formatMessage(messages.fulfillmentFeesLabel)}
                  </th>
                  <th></th>
                </tr>
                {option.productFulfillmentFees.map((fee, i) => {
                  return (
                    <FulfillmentFee
                      key={i}
                      fee={fee}
                      label={formatMessage(messages.fulfillmentFeesLabel)}
                    />
                  );
                })}
              </tbody>
              <tbody className={'fee-table-section'}>
                <tr className={'pt-4 font-bold'}>
                  <td>{formatMessage(messages.fulfillmentFeesFulTotal)}</td>
                  <td>
                    <Currency
                      amount={option.price.amount}
                      currency={option.price.currency}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center lg:justify-between">
          <PrimaryButton
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {formatMessage(messages.close)}
          </PrimaryButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FulfillmentFees;
export { FulfillmentFees };
