/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { Environment, LocalStorageCache } from 'app/common/services';

/**
 * LocalStorageCache for holding the last resolved default locale.
 *
 * @type {LocalStorageCache}
 */
const LocaleCache = new LocalStorageCache(
  Environment.get('locale.localstorage.cache.name', 'LocaleCache'),
  {
    enableLocal: true,
    enableSession: true,
    ttl: 0
  }
);

function getDefaultLocaleFromCache() {
  return LocaleCache.get(
    Environment.get(
      'locale.localstorage.cache.defaultLocale.key',
      'defaultLocale'
    )
  );
}

function putDefaultLocaleInCache(locale) {
  LocaleCache.put(
    Environment.get(
      'locale.localstorage.cache.defaultLocale.key',
      'defaultLocale'
    ),
    locale
  );
}

export default LocaleCache;
export { getDefaultLocaleFromCache, putDefaultLocaleInCache, LocaleCache };
