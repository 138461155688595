import messages from 'app/my-account/components/Orders/components/OrderSummary/OrderSummary.messages';
import { useFormatMessage } from 'app/common/hooks';
import { get } from 'lodash';
import React from 'react';

const PAYMENT_METHOD_LABEL_PREFIX = 'paymentGatewayType';
const PAY_IN_STORE = 'COD';
const PAYPAL = 'PAYPAL_CHECKOUT_V2';
const PAY_IN_STORE_LABEL = PAYMENT_METHOD_LABEL_PREFIX + PAY_IN_STORE;
const PAYPAL_LABEL = PAYMENT_METHOD_LABEL_PREFIX + PAYPAL;

const PaypalImage = () => {
  const formatMessage = useFormatMessage();
  const label = formatMessage(get(messages, PAYPAL_LABEL));
  return (
    <img
      alt={label}
      title={label}
      src={'/images/paypal_logo.png'}
      className={'h-5 pt-1'}
    />
  );
};

const getPaymentGatewayLabel = paymentGatewayType => {
  if (paymentGatewayType === PAYPAL) {
    return get(messages, PAYPAL_LABEL);
  }
  return get(messages, PAY_IN_STORE_LABEL);
};

const getPaymentGatewayType = order => {
  const fallback = get(
    order,
    'internalAttributes.paymentGatewayType',
    PAY_IN_STORE
  );
  return get(order, 'paymentGatewayType', fallback);
};

const getPaymentMethodLabel = () => {
  return messages.paymentMethod;
};

const isPayInStore = paymentGatewayType => {
  return paymentGatewayType === PAY_IN_STORE;
};

export {
  PaypalImage,
  getPaymentGatewayType,
  getPaymentGatewayLabel,
  getPaymentMethodLabel,
  isPayInStore
};
