/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

/**
 * Render component for a field's error messages passed as children.
 */
const FieldErrors = ({
  children,
  className,
  spacing = 'mt-2 lg:mt-0 lg:ml-2 lg:pt-px',
  textStyles = 'text-sm lg:text-base lg:leading-loose'
}) => (
  <div
    className={classNames('flex flex-col text-red-600 lg:inline-flex ', {
      [className]: !!className,
      [spacing]: !!spacing,
      [textStyles]: !!textStyles
    })}
  >
    {children}
  </div>
);

/**
 * Render component that wraps `<FieldErrors>` with formik's
 * [`<ErrorMessage>`](https://jaredpalmer.com/formik/docs/api/errormessage).
 * Additional props are passed to the underlying `<FieldErrors>`.
 */
const WrappedFieldErrors = ({ name, ...props }) => (
  <ErrorMessage name={name}>
    {msg => <FieldErrors {...props}>{msg}</FieldErrors>}
  </ErrorMessage>
);

WrappedFieldErrors.propTypes = {
  name: PropTypes.string
};

export default WrappedFieldErrors;
export { FieldErrors };
