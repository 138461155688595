/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';
import { get, isNil, map, omitBy } from 'lodash';

import { PriceType } from 'app/common/constants';

/**
 * Hook to use to turn an item such as a Product or CartItem into a LineItem
 * for use with promotion services API requests.
 *
 * @param {Object} item - Item to use to build the line item from
 * @param {string} itemType - the type of the item.
 *
 * @return {{ attributes: {}, discountable: boolean, isSubscription: boolean, lineNumber: string, quantity: number, salePrice: {}, standardPrice: {} }}
 */
function useLineItemBuilder(item, itemType) {
  return useMemo(() => {
    if (itemType === 'CART_ITEM') {
      return buildLineItemFromCartItem(item);
    }

    return buildLineItemFromProduct(item);
  }, [item, itemType]);
}

function useLineItemBuilderForItems(items, itemType) {
  return useMemo(() => {
    if (itemType === 'CART_ITEM') {
      return items.map(item => buildLineItemFromCartItem(item));
    }

    return items.map(item => buildLineItemFromProduct(item));
  }, [items, itemType]);
}

/**
 * Method to turn an CartItem into a LineItem.
 *
 * @param {Object} item - Item to use to build the line item from
 *
 * @return {{ attributes: {}, discountable: boolean, isSubscription: boolean, lineNumber: string, quantity: number, salePrice: {}, standardPrice: {} }}
 */
function buildLineItemFromCartItem(item) {
  const {
    attributes = {},
    discountable = true,
    id: lineNumber,
    unitPrice,
    unitPriceType,
    quantity
  } = item;
  return omitBy(
    {
      attributes,
      discountable,
      isSubscription: false, // todo, figure out how to determine this
      lineNumber,
      quantity,
      [unitPriceType]: unitPrice.amount
    },
    isNil
  );
}

/**
 * Method to turn an Product into a LineItem.
 *
 * @param {Object} item - Item to use to build the line item from
 *
 * @return {{ attributes: {}, discountable: boolean, isSubscription: boolean, lineNumber: string, quantity: number, salePrice: {}, standardPrice: {} }}
 */
function buildLineItemFromProduct(item) {
  const {
    discountable = true,
    priceInfo: { priceTypeDetails }
  } = item;

  const standardPrice = get(
    priceTypeDetails,
    `${PriceType.STANDARD_PRICE}.bestPrice.amount`,
    get(priceTypeDetails, `${PriceType.BASE_PRICE}.bestPrice.amount`)
  );
  const salePrice = get(
    priceTypeDetails,
    `${PriceType.SALE_PRICE}.bestPrice.amount`
  );

  return omitBy(
    {
      attributes: {
        productBrand: get(item, 'attributes.BRAND'),
        productDescription: item.description,
        itemName: item.name,
        itemSkuCode: item.sku,
        categoryIds: map(item.parentCategories, category => category.id),
        categoryNames: map(item.parentCategories, category => category.name)
      },
      discountable,
      isSubscription: false, // todo, figure out how to determine this
      quantity: 1,
      salePrice,
      standardPrice
    },
    isNil
  );
}

export default useLineItemBuilder;
export { useLineItemBuilder, useLineItemBuilderForItems };
