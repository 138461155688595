/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

import { useMenuInfo } from 'app/common/hooks';

import { FooterMenu, SiteSocialSharing } from './components';

/**
 * Render component for the site footer.
 *
 * @visibleName Site Footer
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const Footer = ({ menuName }) => {
  const { fetchUrl } = useMenuInfo();
  return (
    <footer className="block pb-8 border-t-2 border-solid border-white bg-gray-100 print:hidden">
      <nav className="container flex flex-col justify-center px-4 md:px-6 lg:px-8">
        <FooterMenu name={menuName} fetchUrl={fetchUrl} />
        <SiteSocialSharing />
      </nav>
    </footer>
  );
};

Footer.propTypes = {
  /** Name of the main footer menu to fetch and render */
  menuName: PropTypes.string.isRequired
};

export default Footer;
export { Footer };
