/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

import { HTMLWidget } from 'app/common/components';
import { useContentTargeterApi, useTargeterContext } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';
import { TextWidget, AssetWidget } from 'app/personalization/components';

const TargetedContentRenderer = ({
  contextAttributes,
  children,
  name,
  widgetProps
}) => {
  const targeterContext = useTargeterContext(name, contextAttributes);
  const { error, exception, loading, response } =
    useContentTargeterApi(targeterContext);

  if (error) {
    logError({
      ...exception,
      when: `fetching a Content Targeter / Item for the Targeter named ${name}`
    });

    return children || null;
  }

  if (loading || response === undefined) {
    return children || null;
  }

  const { type, content, asset } = response;
  switch (type) {
    case 'IMAGE':
      return <AssetWidget {...widgetProps} asset={asset} />;
    case 'HTML':
      return <HTMLWidget {...widgetProps} content={content} />;
    case 'TEXT':
      return <TextWidget {...widgetProps} content={content} />;
    default:
      return children || null;
  }
};

TargetedContentRenderer.propTypes = {
  /** Name of the ContentTargeter */
  name: PropTypes.string,
  /** Props to pass to the ContentTargeter's respective widget */
  widgetProps: PropTypes.object
};

export default TargetedContentRenderer;
export { TargetedContentRenderer };
