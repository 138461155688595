/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'app/common/components';

const SimpleModal = ({
  body,
  children,
  footer,
  header,
  title,
  ...modalProps
}) => {
  body = body || children;

  return (
    <Modal {...modalProps}>
      <Modal.Header>
        {!!header ? (
          header
        ) : (
          <Fragment>
            <Modal.Header.Title>{title}</Modal.Header.Title>
            {modalProps.onClose && <Modal.Close onClose={modalProps.onClose} />}
          </Fragment>
        )}
      </Modal.Header>
      {body && <Modal.Body>{body}</Modal.Body>}
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};

SimpleModal.propTypes = {
  ...Modal.propTypes,
  /**
   * slot for the body of the simple modal
   */
  body: PropTypes.any,
  /**
   * slot for the body of the simple modal; "body" takes priority
   */
  children: PropTypes.node,
  /**
   * slot for the footer of the simple modal
   */
  footer: PropTypes.node,
  /**
   * slot for the header of the simple modal; "title" will not be rendered
   */
  header: PropTypes.node,
  /**
   * the title that is rendered in the modal header. Should be a
   * {@link FormattedMessage} or a pre-localized string.
   */
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element
  ])
};

export default SimpleModal;
