/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  deliveryAddressLabel: {
    id: 'Checkout.Confirmation.Fulfillment.delivery.address.label.readOnly',
    defaultMessage: 'Contact Info'
  },
  pickupContactLabel: {
    id: 'Checkout.Confirmation.Fulfillment.pickup.contact.label.readOnly',
    defaultMessage: 'Pickup contact'
  },
  pickup: {
    id: 'Checkout.Confirmation.Fulfillment.pickup.location.label.readOnly',
    defaultMessage: 'Pickup from'
  },
  deliveryMethodLabel: {
    id: 'Checkout.Confirmation.Fulfillment.delivery.method.label.readOnly',
    defaultMessage: 'Deliver using'
  },
  deliveryMethodName: {
    id: 'Checkout.Confirmation.Fulfillment.delivery.method.name.readOnly',
    defaultMessage: '{name} Delivery'
  },
  deliveryMethodEta: {
    id: 'Checkout.Confirmation.Fulfillment.delivery.method.eta.readOnly',
    defaultMessage: 'Arriving by {date}'
  },
  primaryPhone: {
    id: 'Checkout.Confirmation.Fulfillment.delivery.method.primaryPhone.label.readOnly',
    defaultMessage: 'Primary ({kind}): {phone}'
  },
  secondaryPhone: {
    id: 'Checkout.Confirmation.Fulfillment.delivery.method.secondaryPhone.label.readOnly',
    defaultMessage: 'Secondary ({kind}): {phone}'
  },
  fax: {
    id: 'Checkout.Confirmation.Fulfillment.delivery.method.fax.label.readOnly',
    defaultMessage: 'Fax: {phone}'
  },
  orderInstructions: {
    id: 'Checkout.Confirmation.Fulfillment.delivery.method.orderInstructions',
    defaultMessage: 'Order Instructions'
  },
  communicationPreference: {
    id: 'Checkout.Confirmation.Fulfillment.delivery.method.communicationPreference',
    defaultMessage: 'Preferred to be contacted by'
  },
  dealerLabel: {
    id: 'Checkout.Confirmation.Fulfillment.delivery.dealerLabel',
    defaultMessage: 'Ordered from\n{dealerName}'
  },
  deliveryMethod: {
    id: 'Checkout.Confirmation.deliveryMethod',
    defaultMessage: 'Delivery Method'
  },
  deliveryMethodDeliver: {
    id: 'Checkout.Confirmation.deliveryMethod.deliver',
    defaultMessage: 'Deliver'
  },
  deliveryMethodPickup: {
    id: 'Checkout.Confirmation.deliveryMethod.pickup',
    defaultMessage: 'Pickup'
  },
  purchaseOrderNumber: {
    id: 'Checkout.Confirmation.purchaseOrderNumber',
    defaultMessage: 'PO Number'
  },
  serviceLevel: {
    id: 'Checkout.Confirmation.serviceLevel',
    defaultMessage: 'Service Level'
  },
  IN_STORE: {
    id: 'Checkout.Fulfillment.pickupFulfillment',
    defaultMessage: 'Pickup'
  },
  STANDARD: {
    id: 'Checkout.Fulfillment.standardFulfillment',
    defaultMessage: 'Standard Delivery'
  },
  SAME_DAY_DELIVERY: {
    id: 'Checkout.Fulfillment.sameDayDelivery',
    defaultMessage: 'Delivery'
  },
  NEXT_DAY: {
    id: 'Checkout.Fulfillment.nextDayDelivery',
    defaultMessage: 'Next Day Delivery'
  },
  CURBSIDE: {
    id: 'Checkout.Fulfillment.curbsidePickup',
    defaultMessage: 'Curbside Pickup'
  },
  EXPRESS: {
    id: 'Checkout.Fulfillment.expressDelivery',
    defaultMessage: 'Express Delivery'
  },
  EXPEDITED: {
    id: 'Checkout.Fulfillment.expeditedDelivery',
    defaultMessage: 'Expedited Delivery'
  },
  FREIGHT: {
    id: 'Checkout.Fulfillment.freightDelivery',
    defaultMessage: 'Freight'
  }
});
