/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { find, get, isEmpty, join } from 'lodash';

import { Icon, MarketingMessages, TertiaryButton } from 'app/common/components';
import { MarketingMessageLocationType } from 'app/common/constants';
import {
  useFormatMessage,
  useToggle,
  useWindowEventListener
} from 'app/common/hooks';
import { ConfigurableItemContext, PdpContext } from 'app/product/contexts';

import { Prices } from './components';
import messages from './PdpHeader.messages';

const PdpHeader = ({ className }) => {
  const formatMessage = useFormatMessage();
  const { product } = React.useContext(PdpContext);
  const { activeOption } = React.useContext(ConfigurableItemContext);
  const productArray = useMemo(() => [product], [product]);
  const sku = get(activeOption, 'sku', product.sku);
  const description = get(activeOption, 'description', product.description);
  const { brandName, brandPath } = findProductBrand(activeOption, product);

  return (
    <header className={classNames('mb-4', { [className]: !!className })}>
      <h1 className="block text-gray-700 text-xl font-medium leading-tight lg:text-3xl">
        {description}
      </h1>
      {!isEmpty(brandName) && (
        <h3 className="block mb-3 sm:mb-1">
          {brandPath ? (
            <a
              href={brandPath + '/part-search'}
              className="text-primary-500 font-medium text-sm capitalize"
            >
              {brandName}
            </a>
          ) : (
            <span className="text-primary-500 font-medium text-sm capitalize">
              {brandName}
            </span>
          )}
        </h3>
      )}
      <div className="flex flex-wrap">
        <div className="flex flex-col basis-full mb-2">
          <h2 className="text-lg">
            <span className="text-gray-600">
              {formatMessage(messages.partNumber)}
            </span>
            <span className="ml-1 text-gray-900">{sku}</span>
          </h2>
          <InterchangePartNumbers />
        </div>
      </div>
      <MarketingMessages
        className="flex flex-col -mt-3 mb-3 text-red-600"
        items={productArray}
        itemType={'PRODUCT'}
        locationTypes={[MarketingMessageLocationType.PRODUCT_DETAIL]}
      />
      <Prices />
      {!product.availableOnline && (
        <div className="flex items-center mt-4 mb-5 px-4 py-2 text-yellow-700 leading-snug border border-solid border-yellow-400 bg-yellow-100 rounded md:mb-0 md:mt-6 md:text-lg lg:leading-normal xl:mt-8">
          <Icon className="mr-3" name="exclamation-triangle" />
          <b className="font-medium">{formatMessage(messages.unavailable)}</b>
        </div>
      )}
    </header>
  );
};

const InterchangePartNumbers = () => {
  const formatMessage = useFormatMessage();
  const { product } = React.useContext(PdpContext);
  const { activeOption } = React.useContext(ConfigurableItemContext);
  const interchangePartNumbers = get(
    activeOption,
    'interchangePartNumbers',
    product.interchangePartNumbers
  );
  const [truncateInterchange, toggleTruncateInterchange] = useToggle(true);
  const interchangeNumbers = join(interchangePartNumbers, ', ');
  const detectOverflowRef = React.useRef(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);
  const updateIsOverflowing = React.useCallback(() => {
    const el = detectOverflowRef.current;
    if (!!el) {
      setIsOverflowing(el.offsetWidth < el.scrollWidth);
    }
  }, []);

  React.useEffect(() => {
    updateIsOverflowing();
  }, [updateIsOverflowing]);

  useWindowEventListener('resize', updateIsOverflowing, undefined);

  if (isEmpty(interchangePartNumbers)) {
    return null;
  }

  return (
    <h3>
      <span className="text-gray-600">
        {formatMessage(messages.interchangeParts)}
      </span>
      <span
        ref={detectOverflowRef}
        className={classNames('mr-2 ml-1 text-gray-900', {
          'inline-block align-bottom truncate w-32 max-w-full md:w-48 lg:w-96 xl:w-152':
            !!truncateInterchange,
          '': !truncateInterchange
        })}
      >
        {interchangeNumbers}
      </span>
      <TertiaryButton
        className={classNames({
          hidden: !isOverflowing
        })}
        onClick={() => {
          toggleTruncateInterchange();
        }}
        size={TertiaryButton.Size.SMALL}
      >
        {formatMessage(messages.toggleTruncateInterchangeParts, {
          truncateInterchange
        })}
      </TertiaryButton>
    </h3>
  );
};

/**
 * Returns the product's brand. If this is a top brand,
 * @param activeOption
 * @param product
 * @returns {{brandName: undefined}|null}
 */
function findProductBrand(activeOption, product) {
  let brand = null;
  const topBrandAttrPath = 'attributes._topBrand.value';
  if (!isEmpty(activeOption)) {
    brand = get(activeOption, topBrandAttrPath, get(product, topBrandAttrPath));
  } else {
    brand = get(product, topBrandAttrPath);
  }
  if (!isEmpty(brand)) {
    return {
      brandName: brand.brandName,
      brandPath: brand.brandPath
    };
  }
  const attributesPath = 'productAttributes';
  // Try to find brand name in the product's attributes
  const attributes = get(
    activeOption,
    attributesPath,
    get(product, attributesPath, {})
  );
  const brandAttr = find(attributes, attribute => {
    return get(attribute, 'productAttributeKey.key', undefined) === 'BRAND';
  });

  return { brandName: get(brandAttr, 'value', undefined) };
}

export default PdpHeader;
export { PdpHeader };
