/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { find, get, isEmpty, isString, join, map } from 'lodash';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

import { AuthContext } from 'app/auth/contexts';
import {
  Icon,
  Modal,
  PrimaryButton,
  SecondaryButton
} from 'app/common/components';
import {
  useCartInfo,
  useFormatMessage,
  useRestApi,
  useToggle
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import messages from './BulkAddToListButton.messages';

const BulkAddToListButton = ({
  autoClose = true,
  buttonComponent: ButtonComponent = SecondaryButton,
  buttonSize = ButtonComponent.Size.SMALL,
  className,
  disabled = false,
  handleSuccess,
  selectedItems,
  label
}) => {
  const formatMessage = useFormatMessage();
  const { isAuthenticated } = React.useContext(AuthContext);
  const [open, toggleOpen] = useToggle();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      <AddModal
        autoClose={autoClose}
        handleSuccess={handleSuccess}
        open={open}
        selectedItems={selectedItems}
        toggleOpen={toggleOpen}
      />
      <ButtonComponent
        className={classNames({
          [className]: !!className,
          'mb-2 sm:mb-0 sm:mr-4': !className
        })}
        disabled={disabled || isEmpty(selectedItems)}
        onClick={() => {
          if (disabled || isEmpty(selectedItems)) {
            return;
          }

          toggleOpen();
        }}
        size={buttonSize}
      >
        {isEmpty(label)
          ? formatMessage(messages.label)
          : isString(label)
          ? label
          : formatMessage(label)}
      </ButtonComponent>
    </>
  );
};

BulkAddToListButton.propTypes = {
  /** Component to use to render the button. Default is `<SecondaryButton>` */
  buttonComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.element
  ]),
  disabled: PropTypes.bool,
  handleSuccess: PropTypes.func,
  /** Optional override to the default label. Takes a string or message descriptor */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      defaultMessage: PropTypes.string.isRequired
    })
  ]),
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string.isRequired,
      productId: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      variantId: PropTypes.string
    })
  ).isRequired
};

const AddModal = ({
  autoClose,
  handleSuccess,
  selectedItems,
  open = false,
  toggleOpen
}) => {
  const formatMessage = useFormatMessage();
  const { user } = React.useContext(AuthContext);
  const [isSaveDisabled, toggleIsSaveDisabled] = useToggle(true);
  const customerRef = React.useMemo(
    () => ({
      customerId: get(user, 'serviceId'),
      username: get(user, 'username'),
      isRegistered: true,
      accountId: get(user, 'attributes[account_id]')
    }),
    [user]
  );
  const items = React.useMemo(
    () =>
      map(selectedItems, ({ productId, quantity, sku, variantId }) => ({
        quantity,
        attributes: {},
        itemSkuRef: {
          productId,
          sku,
          variantId
        }
      })),
    [selectedItems]
  );
  const {
    listOperations: { baseUrl, itemsBulkContextPath }
  } = useCartInfo();
  const createConfig = React.useMemo(
    () => ({ method: 'post', params: customerRef }),
    [customerRef]
  );
  const { sendCallback: handleCreateList } = useRestApi(
    baseUrl,
    createConfig,
    false,
    true
  );
  const updateConfig = React.useMemo(
    () => ({ method: 'post', params: customerRef }),
    [customerRef]
  );
  const { sendCallback: handleUpdateList } = useRestApi(
    undefined,
    updateConfig,
    false,
    true
  );
  const fetchConfig = React.useMemo(
    () => ({
      params: {
        ...customerRef,
        offset: 0,
        forward: true,
        size: 50
      }
    }),
    [customerRef]
  );
  const {
    error: fetchError,
    exception: fetchException,
    sendCallback: fetchLists
  } = useRestApi(baseUrl, fetchConfig, false);
  const [selectedList, setSelectedList] = React.useState(undefined);
  const [lists, setLists] = React.useState([]);
  const [success, setSuccess] = React.useState(undefined);
  const [error, setError] = React.useState(undefined);

  React.useEffect(() => {
    if (open) {
      fetchLists().then(data => {
        const listOptions = map(data.content, ({ id, name }) => ({
          value: id,
          label: name
        }));

        setLists(listOptions || []);
      });
    }
  }, [fetchLists, open]);

  React.useEffect(() => {
    if (fetchError) {
      logError({
        ...fetchException,
        when: 'fetching all of the lists'
      });
    }
  }, [fetchError, fetchException]);

  return (
    <Modal size={Modal.Size.MEDIUM} isOpen={open}>
      <Modal.Header>
        <Modal.Header.Title>
          <span className="lg:text-xl font-medium">
            {formatMessage(messages.title)}
          </span>
        </Modal.Header.Title>
        <Modal.Close
          onClose={() => {
            toggleOpen(false);
          }}
        />
      </Modal.Header>
      <Modal.Body className="bg-gray-100 text-gray-700">
        {!isEmpty(success) ? (
          <p className="text-green-700 whitespace-pre-line">{success}</p>
        ) : (
          <>
            {!isEmpty(error) && (
              <aside className="flex items-center mb-4 px-2 py-1 text-sm text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
                <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
                <span>
                  {formatMessage(error, {
                    listName: selectedList.name,
                    quantity: selectedItems.length
                  })}
                </span>
              </aside>
            )}
            <p className="mb-4 leading-normal">
              {formatMessage(messages.description)}
            </p>
            <div className="w-full lg:w-64">
              <CreatableSelect
                autoFocus
                aria-label={formatMessage(messages.createNewSelectPlaceholder)}
                isClearable
                isSearchable
                formatCreateLabel={newValue => {
                  return (
                    <span>
                      {formatMessage(messages.createNewSelectLabel, {
                        name: newValue
                      })}
                    </span>
                  );
                }}
                placeholder={formatMessage(messages.createNewSelectPlaceholder)}
                onChange={(newValue, meta) => {
                  if (newValue != null) {
                    toggleIsSaveDisabled(false);
                  } else {
                    toggleIsSaveDisabled(true);
                  }
                  const val = get(newValue, 'value', '');
                  if (meta.action === 'create-option') {
                    setSelectedList({ name: val });
                  } else {
                    setSelectedList({
                      id: val,
                      name: lists
                        .filter(({ value }) => value === val)
                        .map(({ label }) => label)[0]
                    });
                  }
                }}
                options={lists}
              />
            </div>
            <em className="block mt-4 leading-normal not-italic font-bold">
              {formatMessage(messages.caveat)}
            </em>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="flex items-center lg:justify-between">
        <SecondaryButton
          className="mr-4"
          onClick={() => {
            toggleOpen(false);
          }}
        >
          {formatMessage(messages.cancel)}
        </SecondaryButton>
        <PrimaryButton
          disabled={isSaveDisabled}
          onClick={async () => {
            if (isEmpty(selectedList)) {
              return;
            }

            if (isEmpty(selectedList.id)) {
              try {
                await handleCreateList({
                  data: {
                    name: selectedList.name,
                    attributes: {},
                    items: items
                  }
                });

                setSuccess(true);
                !!handleSuccess && handleSuccess();

                if (autoClose) {
                  setTimeout(() => {
                    toggleOpen(false);
                    setSuccess(false);
                  }, 2000);
                }
              } catch (err) {
                logError({
                  ...err,
                  when: 'adding items to a new list'
                });
                setError(true);
              }

              return;
            }

            try {
              const response = await handleUpdateList(
                { data: items },
                `${baseUrl}/${selectedList.id}${itemsBulkContextPath}`
              );
              const unaddedItems = items.filter(
                ({ itemSkuRef }) =>
                  find(response, re => {
                    return re.itemSkuRef.sku === itemSkuRef.sku;
                  }) === undefined
              );

              setSuccess(
                `${
                  unaddedItems.length > 0
                    ? formatMessage(messages.successDuplicatesIgnored, {
                        duplicates: join(
                          unaddedItems.map(({ itemSkuRef }) => itemSkuRef.sku),
                          ', '
                        ),
                        listName: selectedList.name,
                        quantity: unaddedItems.length
                      })
                    : ''
                }\n${formatMessage(messages.success, {
                  quantity: response.length,
                  listName: selectedList.name
                })}`
              );
              !!handleSuccess && handleSuccess();

              if (!unaddedItems.length && autoClose) {
                setTimeout(() => {
                  toggleOpen(false);
                  setSuccess(undefined);
                }, 2000);
              }
            } catch (err) {
              if (err.type === 'SIMILAR_ITEM_ALREADY_ADDED_ERROR') {
                setError(messages.duplicateError);
                return;
              }

              logError({
                ...err,
                when: 'adding items to an existing list'
              });

              setError(messages.error);
            }
          }}
        >
          {formatMessage(messages.submit)}
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

export default BulkAddToListButton;
export { BulkAddToListButton };
