/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { createContext } from 'react';
import { noop } from 'lodash';

/**
 * The context used to pass props to the children components of Dropdown.
 */
const DropdownContext = createContext({
  disabled: false,
  isOpen: false,
  toggleOpen: noop,
  /**
   * Allows handling special logic when the Trigger component is triggered.
   *
   * @callback handleTrigger
   * @param {{}} event - JS event related to an action like click or mouseEnter
   * @param {boolean} isOpenOverride - Value to set isOpen to instead of
   *    toggling it
   */
  handleTrigger: noop
});

export default DropdownContext;
