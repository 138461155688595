import { Environment } from 'app/common/services';
import { useRestApi } from 'app/common/hooks';

function useReadStoreDetails() {
  const { sendCallback, loading, response, error } = useRestApi(
    getStoreDetailsUrl({}),
    {},
    false
  );
  return {
    fetchDetails: ({ accountNumber, locationId }) =>
      sendCallback({}, getStoreDetailsUrl({ accountNumber, locationId })),
    loading,
    response,
    error
  };
}

function getStoreDetailsUrl({ accountNumber, locationId }) {
  const url = Environment.get(
    'tenant.store.details.url',
    '/tenant/resolver/application/'
  );
  return url + accountNumber + '/' + locationId;
}

export default useReadStoreDetails;
