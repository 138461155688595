/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React from 'react';

import { Currency, Icon } from 'app/common/components';
import { PdpContext } from 'app/product/contexts';
import { useFormatMessage } from 'app/common/hooks';
import { addParams } from 'app/common/utils/PathUtils';
import { determineIncludedItemPricing } from 'app/product/utils/PricingUtils';

import messages from './IncludedItems.messages';

const IncludedItems = () => {
  const formatMessage = useFormatMessage();
  const {
    product: { includedProducts = [] }
  } = React.useContext(PdpContext);

  if (isEmpty(includedProducts)) {
    return null;
  }

  return (
    <section className="w-full mb-4">
      <header className="w-full mb-2">
        <h3 className="text-gray-800 font-bold">
          {formatMessage(messages.header)}
        </h3>
      </header>
      <section className="w-full">
        <ol className="flex flex-col w-full list-none">
          {includedProducts.map((includedItem, i) => {
            const {
              asset,
              displayOrder,
              label,
              product,
              quantity,
              type,
              variant
            } = includedItem;
            const name = type === 'VARIANT' ? variant.name : product.name;
            const pricing = determineIncludedItemPricing(includedItem);
            return (
              <li
                key={i}
                className="flex flex-col items-center justify-between w-full mb-4 sm:flex-row"
                style={{ order: displayOrder }}
              >
                <div
                  className={classNames(
                    'flex items-center w-full mb-2 sm:w-3/5 sm:mb-0',
                    { 'mb-2': !isEmpty(asset), 'mb-4': isEmpty(asset) }
                  )}
                >
                  {!isEmpty(asset) && (
                    <figure className="block w-1/3 pr-4 lg:pr-2 xl:pr-4">
                      <img
                        className="block w-full h-auto mx-auto rounded"
                        src={addParams(asset.contentUrl, { thumbnail: true })}
                        alt={asset.altText}
                        title={asset.title}
                        height="96"
                        width="96"
                      />
                    </figure>
                  )}
                  <h4 className="flex-grow w-2/3 text-800 sm:pr-4 lg:pr-2 xl:pr-4">
                    {label || name}
                  </h4>
                </div>
                <div className="flex items-center justify-end w-full sm:w-2/5">
                  {pricing.isIncludedInParent ? (
                    <div className="text-gray-900">
                      {formatMessage(messages.includedInParent)}
                    </div>
                  ) : (
                    <Currency
                      className={classNames('block', {
                        'text-gray-900': !pricing.isSale,
                        'text-red-600': pricing.isSale
                      })}
                      {...pricing.price}
                    />
                  )}
                  <Icon className="mx-4 lg:mx-2 xl:mx-4" name="times" />
                  <div>{quantity}</div>
                </div>
              </li>
            );
          })}
        </ol>
      </section>
    </section>
  );
};

export default IncludedItems;
export { IncludedItems };
