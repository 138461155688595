/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { get, isEmpty, isNil, isString, map } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Icon, LoadingIcon } from 'app/common/components';
import { CartContext, TenantContext } from 'app/common/contexts';
import {
  useCartInfo,
  useFormatMessage,
  useHistory,
  useLocation,
  useModifyCartRequest,
  useRestApi,
  useToggle
} from 'app/common/hooks';
import { Environment } from 'app/common/services';
import { logError } from 'app/common/utils/ApiErrorUtils';

import Modal from '../../Modal';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import messages from './CheckoutButton.messages';

const CheckoutButton = ({
  children,
  className,
  component: Component = PrimaryButton,
  ...props
}) => {
  const {
    exception,
    loading,
    sendCallback: validate
  } = useCheckoutValidation();
  const [showModal, toggleModal] = useToggle(false);
  const handleCheckout = useHandleCheckout({ toggleModal, validate });
  const history = useHistory();

  return (
    <>
      <CheckoutValidationModal
        handleAcceptDelays={() => {
          history.push('/checkout');
        }}
        response={exception}
        open={showModal}
        toggleOpen={toggleModal}
      />
      <Component
        {...props}
        className={classNames('relative', { [className]: !!className })}
        disabled={loading}
        onClick={handleCheckout}
      >
        <div className={classNames({ 'text-transparent': loading })}>
          {children}
        </div>
        {loading && (
          <div className="absolute inset-0 flex justify-center items-center">
            <LoadingIcon className="text-blue-500" />
          </div>
        )}
      </Component>
    </>
  );
};

const CheckoutValidationModal = ({
  handleAcceptDelays,
  open = false,
  response,
  toggleOpen
}) => {
  const modalRef = React.useRef(null);
  const location = useLocation();
  const history = useHistory();
  const { cart, setCart, guestToken } = React.useContext(CartContext);
  const formatMessage = useFormatMessage();
  const { application } = React.useContext(TenantContext);
  const phoneNumber = get(application, 'phoneNumber');
  const { loading, sendCallback: updateCartInfo } = useCheckoutUpdateCart(
    cart,
    guestToken
  );
  return (
    <Modal isOpen={open} ref={modalRef} size={Modal.Size.LARGE}>
      <Modal.Header>
        <Modal.Header.Title className="flex items-center">
          <Icon
            className="text-yellow-700 mr-2 md:mr-4"
            name="exclamation-triangle"
          />
          <span className="text-yellow-700 font-medium lg:text-xl">
            {formatMessage(messages.modalTitle)}
          </span>
        </Modal.Header.Title>
        <Modal.Close
          onClose={() => {
            toggleOpen(false);
          }}
        />
      </Modal.Header>
      <Modal.Body className="text-gray-700 bg-gray-100">
        <div className="px-2 py-1 text-yellow-700 text-sm leading-snug bg-yellow-100 border border-solid border-yellow-400 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
          <FormattedMessage
            {...messages.inventoryUnavailable}
            values={{
              phoneNumber:
                phoneNumber === undefined ? (
                  'NA'
                ) : (
                  <a
                    className="font-bold hover:text-yellow-800 focus:text-yellow-800 active:text-yellow-600"
                    href={`tel:${phoneNumber}`}
                  >
                    {phoneNumber}
                  </a>
                ),
              breakLine: <br />,
              breakLineWide: <div />
            }}
          >
            {(...args) => (
              <div>
                {map(args, (arg, i) => {
                  if (isString(arg)) {
                    return <span key={i}>{arg}</span>;
                  }

                  if (arg.type === 'br') {
                    return <br key={i} />;
                  }

                  if (arg.type === 'div') {
                    return <div key={i} className="mb-2 lg:mb-3" />;
                  }

                  return <React.Fragment key={i}>{arg}</React.Fragment>;
                })}
              </div>
            )}
          </FormattedMessage>
          <ul className="flex flex-col pl-6 mt-1 leading-normal list-disc">
            {map(response && response.itemFailureMessages, partNumber => {
              return (
                <li key={partNumber} className="mt-1">
                  {formatMessage(messages.itemUnavailable, { partNumber })}
                </li>
              );
            })}
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center lg:justify-between">
        <SecondaryButton
          className="mr-2"
          disabled={loading}
          onClick={() => {
            if (location.pathname === '/cart') {
              return toggleOpen(false);
            }

            return history.push('/cart');
          }}
        >
          {formatMessage(messages.modifyCart)}
        </SecondaryButton>
        <PrimaryButton
          className="relative"
          disabled={loading}
          onClick={async () => {
            try {
              const response = await updateCartInfo({
                method: 'patch',
                data: {
                  attributes: {
                    ACCEPT_ITEM_AVAILABILITY: true
                  }
                }
              });
              setCart(response);
            } catch (err) {
              if (!isEmpty(err.cart)) {
                setCart(err.cart);
              }
              logError({
                ...err,
                when: 'Trying to set the accept-item-availability attribute to true and persist'
              });
            }

            return handleAcceptDelays();
          }}
        >
          <div className={classNames({ 'text-transparent': loading })}>
            {formatMessage(messages.checkoutAnyway)}
          </div>
          {loading && (
            <div className="absolute inset-0 flex justify-center items-center">
              <LoadingIcon className="text-blue-500" />
            </div>
          )}
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

function useCheckoutValidation() {
  const {
    checkoutOperations: { baseUrl, checkoutValidationContextPath }
  } = useCartInfo();
  const { cart, guestToken } = React.useContext(CartContext);
  const url = `${baseUrl}${checkoutValidationContextPath}/${cart.id}`;
  const acceptAvailability = get(
    cart,
    'attributes.ACCEPT_ITEM_AVAILABILITY',
    false
  );
  const cartVersionHeaderName = Environment.get(
    'cart.version.header',
    'X-Cart-Version'
  );
  const cartGuestTokenHeaderName = Environment.get(
    'cart.token.header',
    'X-Guest-Token'
  );

  const config = React.useMemo(
    () => ({
      params: { acceptAvailability },
      headers: !isNil(guestToken)
        ? {
            [cartVersionHeaderName]: cart.version,
            [cartGuestTokenHeaderName]: guestToken.tokenString
          }
        : {
            [cartVersionHeaderName]: cart.version
          }
    }),
    [
      acceptAvailability,
      cartVersionHeaderName,
      cartGuestTokenHeaderName,
      cart,
      guestToken
    ]
  );
  return useRestApi(url, config, false, true);
}

function useCheckoutUpdateCart(cart, guestToken) {
  const {
    checkoutOperations: { baseUrl }
  } = useCartInfo();
  const url = `${baseUrl}/${cart.id}`;

  const cartVersionHeaderName = Environment.get(
    'cart.version.header',
    'X-Cart-Version'
  );
  const cartGuestTokenHeaderName = Environment.get(
    'cart.token.header',
    'X-Guest-Token'
  );

  const config = {
    headers: !isNil(guestToken)
      ? {
          [cartVersionHeaderName]: cart.version,
          [cartGuestTokenHeaderName]: guestToken.tokenString
        }
      : {
          [cartVersionHeaderName]: cart.version
        }
  };

  return useModifyCartRequest(url, config, false, true);
}

function useHandleCheckout({
  redirectToCheckout = true,
  toggleModal,
  validate
}) {
  const history = useHistory();
  const { application } = React.useContext(TenantContext);
  const { setCart } = React.useContext(CartContext);
  return React.useCallback(async () => {
    try {
      if (!isEmpty(application) && application.id !== 'AURORA') {
        // can't check inventory if no location selected
        const response = await validate();
        setCart(response.cart);
      }

      if (redirectToCheckout) {
        return history.push('/checkout');
      }

      return true;
    } catch (err) {
      if (!isEmpty(err.cart)) {
        setCart(err.cart);
      }

      toggleModal(true);
      return false;
    }
  }, [
    application,
    history,
    redirectToCheckout,
    setCart,
    toggleModal,
    validate
  ]);
}

export default CheckoutButton;
export {
  CheckoutButton,
  CheckoutValidationModal,
  useCheckoutValidation,
  useHandleCheckout
};
