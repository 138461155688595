import { useFormatMessage } from 'app/common/hooks';
import { get, isEmpty, map } from 'lodash';
import { Currency, Icon } from 'app/common/components';
import messages from '../FulfillmentInfoWithOptions.messages';
import React from 'react';
import { getDimensionLimit, getWeightLimit } from './deliveryUnitUtil';

const DeliveryOptionRequirements = ({ deliveryValidation }) => {
  const formatMessage = useFormatMessage();
  return {
    modalTitle: formatMessage(messages.deliveryValidationInfoModalTitle),
    content: () => <ModalContent deliveryValidation={deliveryValidation} />
  };
};

const ModalContent = ({ deliveryValidation }) => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
      <span>{formatMessage(messages.fulfillmentCriteriaNotMet)}</span>
      <ul className="flex flex-col pl-6 mt-1 leading-normal list-disc">
        <DoesNotMeetOrderMinimum deliveryValidation={deliveryValidation} />
        <ItemNotEligibleForDelivery deliveryValidation={deliveryValidation} />
        <NotInDeliveryRadius deliveryValidation={deliveryValidation} />
        <RadiusValidationFailed deliveryValidation={deliveryValidation} />
      </ul>
    </>
  );
};

const DoesNotMeetOrderMinimum = ({ deliveryValidation }) => {
  const formatMessage = useFormatMessage();

  if (isMeetingOrderMinimum(deliveryValidation)) {
    return null;
  }
  const orderMinimum = get(
    deliveryValidation,
    'deliverySettings.orderMinimumAmount'
  );
  const orderMinimumUnit = get(
    deliveryValidation,
    'deliverySettings.currencyUnit'
  );
  return (
    <li className="mt-1">
      {formatMessage(messages.meetsOrderMinimumAmountMessage) + ' '}
      <Currency amount={orderMinimum} currency={orderMinimumUnit} />
    </li>
  );
};

const ItemIneligibleInfo = ({ item, weightLimit, dimensionLimit }) => {
  const formatMessage = useFormatMessage();
  const meetsWeight = get(item, 'meetsWeightCriteria', true);
  const meetsDimension = get(item, 'meetsDimensionCriteria', true);
  const sku = get(item, 'sku');
  return (
    <>
      {!meetsWeight && (
        <li key={sku + 'weight'}>
          <span>{sku} - </span>

          <span>
            {formatMessage(messages.exceedsWeightLimit, { weightLimit })}
          </span>
        </li>
      )}
      {!meetsDimension && (
        <li key={sku + 'dim'}>
          <span>{sku} - </span>
          <span>
            {formatMessage(messages.exceedsDimensionLimit, { dimensionLimit })}
          </span>
        </li>
      )}
    </>
  );
};

const ItemNotEligibleForDelivery = ({ deliveryValidation }) => {
  const items = get(deliveryValidation, 'items', []);
  const formatMessage = useFormatMessage();

  const weightLimit = getWeightLimit(deliveryValidation);
  const dimensionLimit = getDimensionLimit(deliveryValidation);
  const ineligibleItems = items.filter(
    item => !get(item, 'eligibleForDelivery')
  );
  if (isEmpty(ineligibleItems)) {
    return null;
  }
  return (
    <li className="mt-1">
      {formatMessage(messages.itemsEligibleForDelivery)}
      <ul className="flex flex-col pl-6 mt-1 leading-normal list-disc">
        {map(ineligibleItems, item => {
          return (
            <ItemIneligibleInfo
              item={item}
              weightLimit={weightLimit}
              dimensionLimit={dimensionLimit}
            />
          );
        })}
      </ul>
    </li>
  );
};

const NotInDeliveryRadius = ({ deliveryValidation }) => {
  if (isInDeliveryRadius(deliveryValidation)) {
    return null;
  }
  return <FailureReason message={messages.inDeliveryRadius} />;
};

/**
 * Renders radius validation failure message if unable to determine distance for delivery radius check
 */
const RadiusValidationFailed = ({ deliveryValidation }) => {
  if (!isRadiusValidationFailed(deliveryValidation)) {
    return null;
  }
  return <FailureReason message={messages.deliveryRadiusFailed} />;
};

const FailureReason = ({ message }) => {
  const formatMessage = useFormatMessage();
  return <li className="mt-1">{formatMessage(message)}</li>;
};

function isRadiusValidationFailed(deliveryValidation) {
  return get(
    deliveryValidation,
    'validationRadiusResponse.failedToDetermineDistance',
    false
  );
}

function isInDeliveryRadius(deliveryValidation) {
  const inDeliveryRadius = get(deliveryValidation, 'inDeliveryRadius', true);
  const preferredCustomer = get(
    deliveryValidation,
    'preferredDeliveryCustomer',
    false
  );
  if (preferredCustomer) {
    return true;
  }
  return inDeliveryRadius;
}

function isMeetingOrderMinimum(deliveryValidation) {
  return get(deliveryValidation, 'meetsOrderMinimumAmount', true);
}

export default DeliveryOptionRequirements;
