/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useFormatMessage, useToggle } from 'app/common/hooks';

import messages from './SliceHTMLWidget.messages';
import { styleHtml } from 'app/common/utils/HtmlUtils';

/**
 * Modified version of HTMLWidget that allows displaying the text at a truncated value that can be expanded to show the entire text.
 * Defaults slicing the text at a height of h-20 or 80px.
 */
const SliceHTMLWidget = ({
  className,
  content,
  readMoreClassName = '',
  cutoffHeight = 'h-20',
  readMoreTextClass = 'text-gray-500 hover:text-gray-700'
}) => {
  const formatMessage = useFormatMessage();
  const [readingMore, toggleReadingMore] = useToggle(false);

  const args = {
    html: content,
    options: {
      elementClasses: {
        ul: {
          topLevelClasses: ['pl-4']
        },
        ol: {
          topLevelClasses: ['pl-4']
        }
      }
    }
  };

  return (
    <>
      <section
        className={classNames('hidden pl-4 html-field lg:block', className)}
        dangerouslySetInnerHTML={{
          __html: styleHtml(args)
        }}
      />
      <div className={'flex flex-col lg:hidden'}>
        <section
          className={classNames('block pl-4 html-field overflow-hidden', {
            [cutoffHeight]: !readingMore
          })}
          dangerouslySetInnerHTML={{
            __html: styleHtml(args)
          }}
        />
        <button
          onClick={toggleReadingMore}
          className={classNames(
            'flex justify-start focus:outline-none',
            readMoreClassName,
            readMoreTextClass
          )}
        >
          {readingMore
            ? formatMessage(messages.showLess)
            : formatMessage(messages.readMore)}
        </button>
      </div>
    </>
  );
};

SliceHTMLWidget.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string.isRequired
};

export default SliceHTMLWidget;
export { SliceHTMLWidget };
