/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  billingAddressLabel: {
    id: 'Checkout.Confirmation.Payment.address.label.readOnly',
    defaultMessage: 'Billed to'
  },
  paymentMethodLabel: {
    id: 'Checkout.Confirmation.Payment.method.label.readOnly',
    defaultMessage: 'Payed using'
  },
  cardNumber: {
    id: 'Checkout.Confirmation.Payment.cardNumber',
    defaultMessage: 'Ending in {number}'
  },
  cardExpiration: {
    id: 'Checkout.Confirmation.Payment.cardExpiration',
    defaultMessage: 'Expires {expMonth}/{expYear}'
  },
  cardIconAlt: {
    id: 'Checkout.Confirmation.Payment.cardIconAlt',
    defaultMessage: 'Card',
    description:
      "If there's no matching card icon for the card number provided, then show this text instead"
  },
  totals: {
    id: 'Checkout.Confirmation.Payment.totals',
    defaultMessage: 'Payment Summary'
  },
  subtotal: {
    id: 'Checkout.Confirmation.Payment.subtotal',
    defaultMessage: 'Subtotal:'
  },
  adjustments: {
    id: 'Checkout.Confirmation.Payment.adjustments',
    defaultMessage: 'Savings:'
  },
  fulfillmentAdjustments: {
    id: 'Checkout.Confirmation.Payment.fulfillmentAdjustments',
    defaultMessage: 'Delivery Savings:'
  },
  delivery: {
    id: 'Checkout.Confirmation.Payment.delivery',
    defaultMessage: 'Delivery:'
  },
  taxes: {
    id: 'Checkout.Confirmation.Payment.taxes',
    defaultMessage: 'Taxes & Fees:'
  },
  total: {
    id: 'Checkout.Confirmation.Payment.total',
    defaultMessage: 'Total:'
  }
});
