/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'formik';
import { get, isEmpty, isString } from 'lodash';
import PropTypes from 'prop-types';

import { Icon, TertiaryButton } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

/**
 * Render component for an optional field that can be activated after clicking
 * a button. The field should be passed as `children`.
 */
const OptionalField = ({
  children,
  className,
  formik,
  name,
  triggerLabel,
  ...props
}) => {
  const formatMessage = useFormatMessage();
  const value = get(formik, `values.${name}`);
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    setActive(!isEmpty(value));
  }, [value]);

  if (isActive) {
    return children;
  }

  return (
    <TertiaryButton
      {...props}
      className={classNames('flex items-center mb-4', {
        [className]: !!className
      })}
      onClick={() => setActive(true)}
      size={TertiaryButton.Size.SMALL}
    >
      <Icon className="mr-2" name="plus" size="xs" />
      <span className="text-left">
        {isString(triggerLabel) ? triggerLabel : formatMessage(triggerLabel)}
      </span>
    </TertiaryButton>
  );
};

OptionalField.propTypes = {
  /** Name of the field */
  name: PropTypes.string.isRequired,
  /** Label for the activation button */
  triggerLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      defaultMessage: PropTypes.string.isRequired
    })
  ]).isRequired
};

export default connect(OptionalField);
export { OptionalField };
