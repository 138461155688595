/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { isNumber } from 'lodash';
import { default as qs } from 'query-string';
import PropTypes from 'prop-types';

import { SecondaryButton } from 'app/common/components';
import { useFormatMessage, useFormatNumber } from 'app/common/hooks';

import messages from './PageNumberControl.messages';

/**
 * Render component for a control used in browse and search pagination to show a
 * particular page in the results set.
 *
 * For a more generic pagination control such as to the next or previous page,
 * see `PaginationControl`.
 *
 * @visibleName Browse and Search Results Page Number Control
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const PageNumberControl = ({ current, value, onChange = () => {} }) => {
  const formatNumber = useFormatNumber();
  const formatMessage = useFormatMessage();
  return (
    <li
      className="ResultsPaginationPageNumberControl flex-grow basis-9 mx-1"
      style={{ minWidth: '2.25rem' }}
    >
      {!isNumber(value) && (
        <span
          className={classNames(
            'inline-flex items-center justify-center w-full',
            'p-2',
            'text-gray-600 capitalize font-bold leading-none',
            'bg-white border-gray-200 border border-solid rounded',
            'appearance-none select-none cursor-not-allowed'
          )}
        >
          {value}
        </span>
      )}
      {isNumber(value) && (
        <SecondaryButton
          aria-current={current ? 'page' : undefined}
          aria-label={formatMessage(messages.pageNumberLabel, {
            current,
            number: value
          })}
          backgroundColor={
            current
              ? 'bg-primary-500 hover:bg-primary-700 focus:bg-primary-700 active:bg-primary-700 disabled:bg-gray-400'
              : 'bg-white hover:bg-primary-100 focus:bg-primary-100 active:bg-primary-100 disabled:bg-white'
          }
          borderColor={
            current
              ? 'border-primary-500 hover:border-primary-700 disabled:border-gray-400'
              : 'border-primary-200 disabled:border-gray-200'
          }
          className={classNames('w-full', { 'is-current': current })}
          textColor={
            current
              ? 'text-white hover:text-white disabled:text-gray-700'
              : 'text-primary-500 hover:text-primary-700 disabled:text-gray-400'
          }
          size={SecondaryButton.Size.SMALL}
          onClick={onChange}
          to={({ search, ...location }) => {
            const params = qs.parse(search);
            return {
              ...location,
              search: qs.stringify({ ...params, page: value })
            };
          }}
        >
          {formatNumber(value)}
        </SecondaryButton>
      )}
    </li>
  );
};

PageNumberControl.propTypes = {
  /** Whether this is the current PageNumber */
  current: PropTypes.bool.isRequired,
  /**
   * Value to display. Usually the page number, but can also be something like
   * an ellipsis indicating a range of numbers between the first and current or
   * current and last pages.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default PageNumberControl;
export { PageNumberControl };
