/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';
import { ResolutionContextType } from 'app/common/constants';
import { TenantContext } from 'app/common/contexts';
import {
  useFormatMessage,
  useGtmPageView,
  useNationalSiteContext
} from 'app/common/hooks';
import { StoreLocation } from 'app/store-location/components';

import messages from './PreferredLocation.messages';
import {
  preferredStoreModalReducer,
  preferredStoreModalState
} from '../PreferredStoreModal/reducer';
import { ChoosePreferredButton } from './ChoosePreferredButton';
import { useHeaderMetadata } from 'app/core/components/App';
import { useFetchPreferredLocation } from './hooks';

const PreferredLocation = ({ wait = false }) => {
  const [state, dispatch] = useReducer(
    preferredStoreModalReducer,
    preferredStoreModalState
  );
  const formatMessage = useFormatMessage();
  const { siteTitle } = useHeaderMetadata();

  const [preferredApplication, setPreferredApplication] = useState(undefined);
  const [loadingPreferredApplication, setLoadingPreferredApplication] =
    useState(true);
  const [noDealerSelected, setNoDealerSelected] = useState(true);
  const { resolutionContext } = useContext(TenantContext);
  const { dealerNetwork } = useNationalSiteContext();
  const nameKey = dealerNetwork ? 'displayName' : 'name';

  useFetchPreferredLocation(
    setPreferredApplication,
    setLoadingPreferredApplication,
    loadingPreferredApplication
  );
  useEffect(() => {
    if (loadingPreferredApplication) {
      return;
    }
    if (isEmpty(preferredApplication)) {
      setNoDealerSelected(true);
    } else {
      setNoDealerSelected(false);
    }
  }, [preferredApplication, setNoDealerSelected, loadingPreferredApplication]);

  useGtmPageView('Preferred Location');

  if (resolutionContext === ResolutionContextType.DEALER) {
    return null;
  }

  return (
    <section>
      <Helmet titleTemplate={`%s - ${siteTitle}`}>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <header className="text-2xl text-bold flex justify-between pb-4">
        <h1>{formatMessage(messages.title)}</h1>
      </header>
      <section className="p-4 shadow rounded bg-white">
        {loadingPreferredApplication ? (
          <PreferredLocationSkeleton />
        ) : noDealerSelected ? (
          <>
            <p className="mb-4">{formatMessage(messages.noDealerCaveat)}</p>
            <ChoosePreferredButton state={state} dispatch={dispatch} />
          </>
        ) : (
          <>
            <StoreLocation.Address
              {...preferredApplication}
              companyName={get(preferredApplication, nameKey)}
            />
            <ChoosePreferredButton
              preferredLocation={preferredApplication}
              state={state}
              dispatch={dispatch}
              loadingPreferredApplication={loadingPreferredApplication}
            />
          </>
        )}
      </section>
    </section>
  );
};

const PreferredLocationSkeleton = () => {
  return (
    <>
      <div className="mb-2 w-20 font-bold lg:text-lg bg-gray-200">&nbsp;</div>
      <div className="mb-px w-20 bg-gray-200">&nbsp;</div>
      <div className="mb-px w-32 bg-gray-200">&nbsp;</div>
      <div className="mb-2 w-8 bg-gray-200">&nbsp;</div>
      <div className="mb-px w-24 bg-gray-200">&nbsp;</div>
      <div className="w-20 bg-gray-200">&nbsp;</div>
    </>
  );
};

PreferredLocation.Skeleton = () => <PreferredLocation wait />;

export default PreferredLocation;
export { PreferredLocation };
