/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Checkout.LocationInfo.title',
    defaultMessage: 'Dealer Location'
  },
  noDealerCaveat: {
    id: 'Checkout.LocationInfo.noDealerCaveat',
    defaultMessage:
      'You have not selected a location to order from. You will need to pick a location before you can place an order.'
  },
  selectDealer: {
    id: 'Checkout.LocationInfo.selectDealer',
    defaultMessage: 'Select Location'
  },
  changeDealer: {
    id: 'Checkout.LocationInfo.changeDealer',
    defaultMessage: 'Change Location'
  },
  nextStageButtonLabel: {
    id: 'Checkout.LocationInfo.nextStageButtonLabel',
    defaultMessage: 'Next'
  }
});
