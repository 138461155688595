/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  legend: {
    id: 'Address.legend',
    defaultMessage: `Enter {kind, select, 
                              FULFILLMENT {{isPickup, select, 
                                  true {Pickup Contact Info} 
                                  other {Contact Address}
                              }}
                              BILLING {Billing Address}
                              other {Address}
                           }`
  },
  nameLabel: {
    id: 'Address.name.label',
    defaultMessage: 'Full Name'
  },
  namePlaceholder: {
    id: 'Address.name.placeholder',
    defaultMessage: 'First Last'
  },
  firstNameLabel: {
    id: 'Address.firstName.label',
    defaultMessage: 'First Name'
  },
  firstNamePlaceholder: {
    id: 'Address.firstName.placeholder',
    defaultMessage: 'First'
  },
  lastNameLabel: {
    id: 'Address.lastName.label',
    defaultMessage: 'Last Name'
  },
  lastNamePlaceholder: {
    id: 'Address.lastName.placeholder',
    defaultMessage: 'Last'
  },
  address1Label: {
    id: 'Address.address1.label',
    defaultMessage: 'Address Line 1'
  },
  address1Placeholder: {
    id: 'Address.address1.placeholder',
    defaultMessage: '100 Street St'
  },
  address2Add: {
    id: 'Address.address2.add',
    defaultMessage: 'Add Address Line 2'
  },
  address2Label: {
    id: 'Address.address2.label',
    defaultMessage: 'Address Line 2'
  },
  address2Placeholder: {
    id: 'Address.address2.placeholder',
    defaultMessage: 'Apt 100'
  },
  address3Add: {
    id: 'Address.address3.add',
    defaultMessage: 'Add Address Line 3'
  },
  address3Label: {
    id: 'Address.address3.label',
    defaultMessage: 'Address Line 3'
  },
  address3Placeholder: {
    id: 'Address.address3.placeholder',
    defaultMessage: 'Market Quarter'
  },
  countryLabel: {
    id: 'Address.country.label',
    defaultMessage: 'Country'
  },
  stateLabel: {
    id: 'Address.state.label',
    defaultMessage:
      '{country, select, CA {Province} ES {Province} FR {Department} GB {County} MX {State} US {State} other {State} }'
  },
  cityLabel: {
    id: 'Address.city.label',
    defaultMessage:
      '{country, select, CA {Municipality} ES {Town} FR {Locality} GB {Post Town} MX {Municipality} US {City} other {City} }'
  },
  cityPlaceholder: {
    id: 'Address.city.placeholder',
    defaultMessage: 'Cityville'
  },
  companyLabel: {
    id: 'Address.company.label',
    defaultMessage: 'Company or Organization'
  },
  companyAdd: {
    id: 'Address.company.add',
    defaultMessage: 'Add Company or Organization'
  },
  companyPlaceholder: {
    id: 'Address.company.placeholder',
    defaultMessage: 'Company'
  },
  postalCodeLabel: {
    id: 'Address.postalCode.label',
    defaultMessage:
      '{country, select, CA {Post Code} ES {Post Code} FR {Post Code} GB {Post Code} MX {Post Code} US {Zip Code} other {Postal Code} }'
  },
  postalCodePlaceholder: {
    id: 'Address.postalCode.placeholder',
    defaultMessage:
      '{country, select, CA {A1A 1A1} ES {10100} FR {10100} GB {Ex: AA9A 9AA, A9 9AA, etc.} MX {10100} US {10100 or 10100-0000} other {10100} }'
  },
  postalCodeHint: {
    id: 'Address.postalCode.hint',
    defaultMessage:
      '{country, select, CA {Ex: A1A 1A1} ES {Ex: 10100} FR {Ex: 10100} GB {Ex: AA9A 9AA, A9 9AA, etc.} MX {Ex: 10100} US {Ex: 10100 or 10100-0000} other {10100} }'
  },
  contactPhoneLabel: {
    id: 'Address.contactPhone.label',
    defaultMessage: 'Contact Phone Number'
  },
  contactPhoneSecondaryLabel: {
    id: 'Address.contactPhone.secondary.label',
    defaultMessage: 'Secondary Phone Number'
  },
  contactPhoneSecondaryAdd: {
    id: 'Address.contactPhone.secondary.add',
    defaultMessage: 'Add Secondary Phone Number'
  },
  contactPhoneFaxLabel: {
    id: 'Address.contactPhone.fax.label',
    defaultMessage: 'Tertiary Phone Number'
  },
  contactPhoneFaxAdd: {
    id: 'Address.contactPhone.fax.add',
    defaultMessage: 'Add Tertiary Phone Number'
  },
  contactPhonePlaceholder: {
    id: 'Address.contactPhone.placeholder',
    defaultMessage:
      '{country, select, CA {100-100-1000} MX {+52-100-100-1000} US {100-100-1000} other {100-100-1000} }'
  },
  contactPhoneHint: {
    id: 'Address.contactPhone.hint',
    defaultMessage:
      '{country, select, CA {Ex: 100-100-1000, 1001001000} MX {Ex: +52-100-100-1000} US {Ex: 100-100-1000, 1001001000} other {Ex: 100-100-1000, 1001001000} }'
  },
  nameRequired: {
    id: 'Address.name.required',
    defaultMessage: 'Name is required'
  },
  firstNameRequired: {
    id: 'Address.firstName.required',
    defaultMessage: 'First name is required'
  },
  lastNameRequired: {
    id: 'Address.lastName.required',
    defaultMessage: 'Last name is required'
  },
  address1Required: {
    id: 'Address.address1.required',
    defaultMessage: 'Address Line 1 is required'
  },
  countryRequired: {
    id: 'Address.country.required',
    defaultMessage: 'Country is required'
  },
  stateRequired: {
    id: 'Address.state.required',
    defaultMessage: 'State is required'
  },
  cityRequired: {
    id: 'Address.city.required',
    defaultMessage: 'City is required'
  },
  contactPhoneRequired: {
    id: 'Address.contactPhone.required',
    defaultMessage: 'Contact Phone is required'
  },
  contactPhoneInvalid: {
    id: 'Address.contactPhone.invalid',
    defaultMessage: 'Number may only contain numbers, -, +, (, ), and spaces'
  },
  contactPhoneLong: {
    id: 'Address.contactPhone.long',
    defaultMessage: 'Number can only contain up to 12 digits'
  },
  useDeliveryAddress: {
    id: 'Address.useDeliveryAddress',
    defaultMessage: 'Same as delivery'
  },
  addressNameLabel: {
    id: 'Address.saved.addressName.label',
    defaultMessage: 'Give this address a name'
  },
  addressNamePlaceholder: {
    id: 'Address.saved.addressName.placeholder',
    defaultMessage: 'Home'
  }
});
