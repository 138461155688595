/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { isString } from 'lodash';
import PropTypes from 'prop-types';

import { useFormatMessage } from 'app/common/hooks';

const PLACEHOLDER_VALUE = '';

/**
 * Render component for a form select with options.
 */
const FormSelect = ({
  className,
  formId,
  hasErrors = false,
  touched = false,
  widths = 'w-full lg:w-64',
  value,
  valueOptions = [],
  placeholderLabel,
  resetLabel,
  ...props
}) => {
  let selected = value;
  if (!selected && placeholderLabel) {
    selected = PLACEHOLDER_VALUE;
  }
  return (
    <select
      className={classNames(
        'block p-2 text-sm text-gray-900 leading-tight appearance-none',
        'bg-white border border-solid rounded focus:shadow-outline',
        'focus:outline-none disabled:bg-gray-200 disabled:border-gray-300',
        'disabled:cursor-not-allowed lg:p-3 lg:text-base',
        'overflow-hidden',
        {
          [className]: !!className,
          [widths]: !!widths,
          'border-gray-400': !touched,
          'border-green-500': !hasErrors && touched,
          'border-red-500 field-error': hasErrors && touched
        }
      )}
      form={formId}
      value={selected}
      {...props}
    >
      {resetLabel && (
        <FormSelect.Option
          label={selected === PLACEHOLDER_VALUE ? placeholderLabel : resetLabel}
          value={PLACEHOLDER_VALUE}
        />
      )}
      {valueOptions.map(option => (
        <FormSelect.Option {...option} key={option.value} />
      ))}
    </select>
  );
};

FormSelect.propTypes = {
  hasErrors: PropTypes.bool,
  name: PropTypes.string.isRequired,
  touched: PropTypes.bool,
  value: PropTypes.string,
  /** Default value to select. Should match the `value` of a `valueOption` */
  valueDefault: PropTypes.string,
  valueOptions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          defaultMessage: PropTypes.string.isRequired
        })
      ]),
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  /**
   * Class name(s) to affect the select's width.
   *
   * Added because not styles can be overridden via the `className` prop.
   */
  widths: PropTypes.string
};

/**
 * Render component for a form select's option.
 */
const SelectOption = ({
  disabled = false,
  label,
  value,
  isoValue,
  className,
  ...props
}) => {
  const formatMessage = useFormatMessage();
  return (
    <option {...props} value={value} disabled={disabled}>
      {isString(label) ? label : formatMessage(label)}
    </option>
  );
};

const PlaceholderOption = ({ label, value }) => {
  return (
    <option value={value} disabled hidden selected>
      {label}
    </option>
  );
};

SelectOption.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      defaultMessage: PropTypes.string.isRequired
    })
  ]).isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string
};

FormSelect.Option = SelectOption;

export default FormSelect;
export { FormSelect };
