/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isFunction, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { Link } from 'app/common/components';

/**
 * Raw button with no class names applied. Takes additional class names to add
 * to the base ones and any props that would normally be applied to a button or link.
 * If a `to` prop is passed, the underlying component is a `<Link>`, otherwise,
 * a `<button>`.
 * Content like text and icons should be passed as children.
 */
const Button = ({ children, to, ...props }) => {
  if (isFunction(to) || !isEmpty(to)) {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <button
      className="appearance-none focus:outline-none"
      type="button"
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.shape({
      /** e.g., /search */
      pathname: PropTypes.string.isRequired,
      /** URL query params: ?sort=name */
      search: PropTypes.string,
      /** e.g., #section */
      hash: PropTypes.string,
      /** state to persist to the `location` */
      state: PropTypes.object
    }),
    PropTypes.string,
    /** Takes the same object as `to` */
    PropTypes.func
  ])
};

export default Button;
export { Button };
