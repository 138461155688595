/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { AuthService } from 'app/auth/services';
import { RequestService } from 'app/common/services';
import { Environment } from 'app/common/services';

/**
 * Singleton used for handling the registration of a new user.
 *
 * <p>
 * Supports extension of the handling of the registration request by using the
 * `#setHandler` method:
 *
 * ```js
 * UserRegistrationService.setHandler(async (userRegistration, context) => {
 *   // handle registering the user
 * });
 * ```
 */
class UserRegistrationService {
  constructor() {
    if (!UserRegistrationService.instance) {
      this._handler = this.defaultHandler;

      UserRegistrationService.instance = this;
    }

    return UserRegistrationService.instance;
  }

  setHandler(fn) {
    this._handler = fn;
  }

  async defaultHandler(userRegistration, context = {}) {
    return RequestService.post(
      {
        data: userRegistration,
        url: getRegisterUrl()
      },
      context
    );
  }

  /**
   * Registers the user and if successful, will redirect to login if enabled.
   *
   * @param userRegistration the user registration form json
   * @param context the parameters to pass as context, typically to the X-Context-Request header
   * @returns {Promise<AxiosPromise<any> | *>}
   */
  async register(userRegistration, context = {}) {
    const response = await this._handler(userRegistration, context);

    if (isLoginOnSuccess()) {
      AuthService.loginWithRedirect();
    }

    return response;
  }
}

function getRegisterUrl() {
  return Environment.get('user.registration.url', '/auth/register');
}

function isLoginOnSuccess() {
  return Environment.getBoolean('user.registration.loginonsuccess', true);
}

const instance = new UserRegistrationService();

export function getInstance() {
  return instance;
}

export default getInstance();
