/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useFormatMessage } from 'app/common/hooks';
import { get, isEqualWith, isString } from 'lodash';
import messages from '../../Orders.messages';
import Dropdown from 'app/common/components/Dropdown';
import React from 'react';
import classNames from 'classnames';

/**
 *
 * @param days
 * @param onChange
 * @param numOrders
 * @param {HistoricalOrderInfo} orderHistoricalInfo
 * @returns {JSX.Element|null}
 * @constructor
 */
const OrderDateFilter = ({
  selectedDays,
  selectedYear,
  onChange,
  numOrders,
  orderHistoricalInfo
}) => {
  const formatMessage = useFormatMessage();

  /** @type {Array.<OrderCountInfo>}} */
  const options = useOrderFilterOptions(
    selectedDays,
    selectedYear,
    orderHistoricalInfo,
    formatMessage
  );
  if (options.length === 0 || !numOrders || numOrders <= 0) {
    return null;
  }
  const activeOption = options.find(option => option.active) || options[0];
  const selectLabel = numOrders + ' ' + formatMessage(messages.ordersPlacedIn);
  return (
    <div className="pl-1 md:pl-3 w-full pt-1 md:pt-2">
      <div className={'flex flex-auto'}>
        <div id={'date-select-trigger'} className={''}>
          {selectLabel}
        </div>
        <Dropdown hoverable className={''}>
          <Dropdown.Menu.Trigger
            aria-labelledby="date-selector-label date-select-trigger"
            id="date-select-trigger"
            triggerClassName="pl-1 pr-2 text-primary-600 font-medium rounded hover:text-primary-800 active:text-primary-600 focus:outline-none focus:shadow-outline"
            label={
              isString(activeOption.label)
                ? activeOption.label
                : formatMessage(activeOption.label)
            }
          ></Dropdown.Menu.Trigger>
          <Dropdown.Menu aria-labelledby="date-selector-label">
            {options.map(option => {
              const { active, color, label, days, year } = option;
              const key = days ? days : year;
              return (
                <Dropdown.Menu.Item
                  isActive={active}
                  key={key}
                  style={{ minWidth: '130px' }}
                  onClick={() => onChange({ days, year, type: 'date' })}
                >
                  <span className={classNames({ [`text-${color}`]: !!color })}>
                    {isString(label) ? label : formatMessage(label)}
                  </span>
                </Dropdown.Menu.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

const useOrderFilterOptions = (
  selectedDays,
  selectedYear,
  orderHistoricalInfo,
  formatMessage
) => {
  if (get(orderHistoricalInfo, 'loading', true)) {
    return [];
  }
  const noneSelected = !selectedDays && !selectedYear;
  const historicalInfo = get(orderHistoricalInfo, 'orderInfo', []);
  return historicalInfo
    .map(option => {
      let label = '';
      let active = false;
      const { count, year, days } = option;
      if (year) {
        label = formatMessage(messages.year, { year });
      } else {
        label = formatMessage(messages[option.label]);
      }
      if (noneSelected && isPropsEqual(selectedDays, '30')) {
        active = true;
      } else if (isPropsEqual(days, selectedDays)) {
        active = true;
      } else if (isPropsEqual(year, selectedYear)) {
        active = true;
      }
      return {
        label,
        days,
        year,
        count,
        active
      };
    })
    .filter(result => result.count > 0);
};

/**
 * Compare two primitives with type coercion. This lets use compare
 * strings and numbers when we may not know the type of the value.
 * If both are undefined or null, returns false;
 */
const isPropsEqual = (p1, p2) => {
  if (!p1 && !p2) {
    return false;
  }
  // eslint-disable-next-line eqeqeq
  return isEqualWith(p1, p2, (x, y) => x == y);
};
export default OrderDateFilter;
