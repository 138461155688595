/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  deleteButton: {
    id: 'Addresses.AddressCard.deleteButton',
    defaultMessage: 'Delete'
  },
  editButton: {
    id: 'Addresses.AddressCard.editButton',
    defaultMessage: 'Edit'
  },
  isDefaultBilling: {
    id: 'Addresses.AddressCard.isDefaultBilling',
    defaultMessage: 'Default Billing'
  },
  isDefaultShipping: {
    id: 'Addresses.AddressCard.isDefaultShipping',
    defaultMessage: 'Default Shipping'
  },
  makeDefaultBilling: {
    id: 'Addresses.AddressCard.makeDefaultBilling',
    defaultMessage: 'Make default Billing'
  },
  makeDefaultShipping: {
    id: 'Addresses.AddressCard.makeDefaultShipping',
    defaultMessage: 'Make default Shipping'
  },
  phoneNumber: {
    id: 'Addresses.AddressCard.phoneNumber',
    defaultMessage: 'Phone number'
  },
  actions: {
    id: 'Addresses.AddressCard.actions',
    defaultMessage: 'Actions'
  }
});
