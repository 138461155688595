/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { SiteContext } from 'app/common/contexts';

const SiteProvider = ({ children }) => {
  const [openStoreDropdown, setOpenStoreDropdown] = useState(false);
  const context = useMemo(
    () => ({
      openStoreDropdown,
      setOpenStoreDropdown
    }),
    [openStoreDropdown]
  );

  return (
    <SiteContext.Provider value={context}>{children}</SiteContext.Provider>
  );
};

SiteProvider.propTypes = {
  openStoreDropdown: PropTypes.bool,
  setOpenStoreDropdown: PropTypes.func
};

export default SiteProvider;
export { SiteProvider };
