/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty, join } from 'lodash';
import PropTypes from 'prop-types';
import { Helmet as ReactHelmet } from 'react-helmet';
import { useHeaderMetadata } from '../../../../core/components/App';
import { TopBrandContext } from '../../../../common/contexts';
import {
  buildMetaDescription,
  buildMetaTitle
} from '../../utils/MetaTitleUtils';
import messages from '../../utils/MetaTitleUtils/MetaTitleUtils.messages';
import { useFormatMessage } from '../../../../common/hooks';

/**
 * Helper component that updates the `<head>` content with appropriate metadata.
 *
 * @visibleName Search and Browse Results Helmet
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const ResultsHelmet = ({
  description,
  follow = false,
  index = false,
  keywords = [],
  metaDescription,
  metaTitle,
  name: categoryName,
  pageNumber = 1,
  totalPages = 0,
  url
}) => {
  const { siteTitle } = useHeaderMetadata();
  const baseUrl = `${window.location.origin}${url}`;
  const canonical =
    pageNumber === 1 ? baseUrl : `${baseUrl}?page=${pageNumber}`;
  const hasPrev = pageNumber > 1;
  const hasNext = pageNumber < totalPages;

  const activeTopBrand = React.useContext(TopBrandContext).activeTopBrand;
  const title = buildMetaTitle(
    activeTopBrand,
    categoryName,
    metaTitle,
    siteTitle
  );

  const formatMessage = useFormatMessage();
  const descr = buildMetaDescription(
    activeTopBrand,
    categoryName,
    metaDescription,
    siteTitle,
    formatMessage(messages.metaDescriptionTemplate)
  );

  return (
    <ReactHelmet>
      <title>{title}</title>
      <meta name="description" content={descr} />
      <meta name="canonical" content={canonical} />
      <meta
        name="robots"
        content={`${index ? 'index' : 'noindex'},${
          follow ? 'follow' : 'nofollow'
        }`}
      />
      {!isEmpty(keywords) && (
        <meta name="keywords" content={join(keywords, ', ')} />
      )}
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={descr} />
      <link rel="canonical" href={canonical} />
      {hasPrev && (
        <link
          rel="prev"
          href={
            pageNumber === 1 ? baseUrl : `${baseUrl}?page=${pageNumber - 1}`
          }
        />
      )}
      {hasNext && (
        <link rel="next" href={`${baseUrl}?page=${pageNumber + 1}`} />
      )}
    </ReactHelmet>
  );
};

ResultsHelmet.propTypes = {
  /** The page's description. Used if no `metaDescription` provided. */
  description: PropTypes.string,
  /* Whether to set `follow` in the `robots` metatag. False means `nofollow` */
  follow: PropTypes.bool,
  /* Whether to set `index` in the `robots` metatag. False means `noindex` */
  index: PropTypes.bool,
  /** The page's meta keywords */
  keywords: PropTypes.arrayOf(PropTypes.string),
  /** The page's meta description */
  metaDescription: PropTypes.string,
  /** The page's meta title */
  metaTitle: PropTypes.string,
  /** The page's name. Used if no `metaTitle` provided. */
  name: PropTypes.string,
  /** The page's SEO URI */
  url: PropTypes.string.isRequired
};

export default ResultsHelmet;
export { ResultsHelmet };
