/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isString } from 'lodash';
import PropTypes from 'prop-types';

import { SecondaryButton } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from './OrderReviewHeader.messages';

const OrderReviewHeader = ({ children, order, orderNumber, title }) => {
  const formatMessage = useFormatMessage();
  const number = orderNumber || order.orderNumber;

  return (
    <header className="relative flex flex-col">
      <div className="flex items-center justify-between py-2 px-4  bg-white rounded-t">
        <h2 className="font-bold lg:text-xl">
          {isString(title) ? title : formatMessage(title)}
        </h2>
        <SecondaryButton
          className="hidden ml-2 lg:block print:hidden"
          size={SecondaryButton.Size.SMALL}
          onClick={() => {
            window.print();
          }}
        >
          {formatMessage(messages.print)}
        </SecondaryButton>
      </div>
      <div className="p-4 rounded-b lg:py-6">
        <strong className="flex flex-col flex-wrap items-baseline mb-4 lg:flex-row">
          <span className="block mr-2 text-sm lg:text-base">
            {formatMessage(messages.orderNumberLabel)}
          </span>
          <span className="block text-primary-600 font-medium lg:text-lg">
            {number}
          </span>
        </strong>
        {children}
      </div>
    </header>
  );
};

OrderReviewHeader.propTypes = {
  /** Children components to display below the order number */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func
  ]),
  order: PropTypes.shape({
    emailAddress: PropTypes.string,
    orderNumber: PropTypes.string
  }),
  orderNumber: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string,
      defaultMessage: PropTypes.string
    })
  ])
};

export default OrderReviewHeader;
export { OrderReviewHeader };
