import dompurify from 'dompurify';
import PropTypes from 'prop-types';

/**
 *
 * The default styles to apply to unstyled HTML, keyed by the HTML tag name.
 */
const DEFAULT_STYLES = {
  h1: 'block text-gray-700 text-xl font-medium leading-tight lg:text-3xl',
  h2: 'block text-gray-700 text-lg font-medium leading-tight lg:text-2xl',
  h3: 'block text-gray-700 text-base font-medium leading-tight lg:text-xl',
  h4: 'block text-gray-700 text-sm font-medium leading-tight lg:text-lg',
  h5: 'block text-gray-700 text-xs font-medium leading-tight lg:text-base',
  h6: 'block text-gray-700 text-xs font-medium leading-tight lg:text-base'
  //ul: 'list-disc',
  //ol: 'list-decimal'
};

function isSkipDefaults(tagName, options) {
  if (options.skipAllDefaults) {
    return true;
  }
  const tagOptions = getOptionsForTag(tagName, options);
  return tagOptions?.skipDefaults;
}

function getOptionsForTag(tagName, options) {
  if (options?.elementClasses && options.elementClasses[tagName]) {
    return options.elementClasses[tagName];
  }
  return undefined;
}

/**
 * Add styling for unstyled HTML. This is for certain areas like the product description
 * where the HTML is not styled by the CMS.
 * @param props
 */
function styleHtml({ html, options = {} }) {
  const parser = new DOMParser();
  const document = parser.parseFromString(
    dompurify.sanitize(html),
    'text/html'
  );
  const body = document.querySelector('body');

  // Apply default styles.
  for (const [tagName] of Object.entries(DEFAULT_STYLES)) {
    const elements = body.getElementsByTagName(tagName);
    const skipDefaults = isSkipDefaults(tagName, options);
    const defaultClasses = DEFAULT_STYLES[tagName];
    if (!skipDefaults && defaultClasses) {
      for (const element of elements) {
        const existingClasses = element.className
          ? element.className + ' '
          : '';
        element.className = existingClasses + defaultClasses;
      }
    }
  }

  // Apply custom styles.
  const elementOptions = options.elementClasses || {};
  for (const [tagName, tagOptions] of Object.entries(elementOptions)) {
    const elements = body.getElementsByTagName(tagName);
    for (const element of elements) {
      const isTopLevel = element.parentElement === body;
      if (tagOptions.classes) {
        element.classList.add(...tagOptions.classes);
      }
      if (isTopLevel && tagOptions.topLevelClasses) {
        element.classList.add(...tagOptions.topLevelClasses);
      }
    }
  }

  return body.innerHTML;
}

styleHtml.propTypes = {
  // The HTML to style.
  html: PropTypes.string.isRequired,
  // The options to use when styling the HTML.
  options: PropTypes.shape({
    // The classes to apply to each element, keyed by the HTML tag name.
    elementClasses: PropTypes.objectOf(
      PropTypes.shape({
        // The classes to apply to the element.
        classes: PropTypes.arrayOf(PropTypes.string).isRequired,
        // Whether to skip the default classes for this element.
        skipDefaults: PropTypes.bool,
        // The classes to apply to the top level elements
        topLevelClasses: PropTypes.arrayOf(PropTypes.string)
      })
    ),
    skipAllDefaults: PropTypes.bool
  })
};

export { styleHtml };
