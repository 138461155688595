/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty } from 'lodash';

import { useFormatMessage } from 'app/common/hooks';

import messages from '../../NearestAvailableButton.messages';
import SelectLocationButton from '../SelectLocationButton';

const NearbyLocation = ({
  inventoryLocation,
  handleSwitch,
  handleWarning,
  loadingLocations
}) => {
  const formatMessage = useFormatMessage();
  const {
    distance,
    name,
    address: {
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      stateProvinceRegion,
      country,
      postalCode
    }
  } = inventoryLocation;
  return (
    <li className="flex justify-between mb-4 px-4 py-4 shadow bg-white rounded">
      <address className="flex flex-col items-start text-gray-700 appearance-none not-italic">
        <h4 className="font-bold">{name}</h4>
        <div>{addressLine1}</div>
        {!isEmpty(addressLine2) && <div>{addressLine2}</div>}
        {!isEmpty(addressLine3) && <div>{addressLine3}</div>}
        <div className="flex flex-wrap">
          <span>
            {city}
            {!isEmpty(stateProvinceRegion)
              ? ',\u00A0'
              : !isEmpty(postalCode) && '\u00A0'}
          </span>
          {!isEmpty(stateProvinceRegion) && (
            <span>
              {stateProvinceRegion}
              {!isEmpty(postalCode) && '\u00A0'}
            </span>
          )}
          {!isEmpty(postalCode) && <span>{postalCode}</span>}
        </div>
        {!isEmpty(country) && <div>{country}</div>}
      </address>
      <div className="flex flex-col items-end justify-between ml-2">
        <div
          className="mb-2 font-bold"
          aria-label={formatMessage(messages.modalLocationDistance)}
        >
          {formatMessage(messages.modalSearchRadius, {
            radius: Number(distance.toPrecision(3))
          })}
        </div>
        <SelectLocationButton
          disabled={loadingLocations}
          inventoryLocation={inventoryLocation}
          handleSwitch={handleSwitch}
          handleWarning={handleWarning}
        />
      </div>
    </li>
  );
};

export default NearbyLocation;
export { NearbyLocation };
