/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { Form, Formik } from 'formik';

import {
  determineDefaultCountry,
  determineDefaultStateProvinceRegion
} from 'app/checkout/utils/AddressUtils';
import {
  AddressForm,
  PrimaryButton,
  TertiaryButton
} from 'app/common/components';
import {
  useFormatMessage,
  useReadCustomerAddressesApi
} from 'app/common/hooks';

import { UserBillingAddress } from './components';
import messages from './UserBillingAddresses.messages';
import { LocaleContext } from 'app/common/contexts';

const UserBillingAddresses = props => {
  const { setBillingAddress, onCancel } = props;
  const formatMessage = useFormatMessage();
  const [addresses, setAddresses] = useState(undefined);
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [isCustomAddress, setIsCustomAddress] = useState(false);

  const { currentLocale } = React.useContext(LocaleContext);
  const countryDefault = determineDefaultCountry(currentLocale);
  const stateProvinceRegionDefault =
    determineDefaultStateProvinceRegion(countryDefault);

  const { sendCallback: sendReadAddressCallback } =
    useReadCustomerAddressesApi();
  useEffect(() => {
    async function fetchSavedAddresses() {
      const { content = [] } = await sendReadAddressCallback();
      setAddresses(content);
    }
    fetchSavedAddresses();
  }, [sendReadAddressCallback]);

  return (
    <>
      {!isCustomAddress && (
        <>
          <div className="mb-4 text-gray-900 font-bold lg:text-lg">
            {formatMessage(messages.chooseBilling)}
          </div>

          {map(addresses, (customerAddress, index) => (
            <UserBillingAddress
              key={index}
              address={customerAddress}
              isSelected={selectedAddress === customerAddress}
              onSelected={setSelectedAddress}
            />
          ))}
          <button
            type="button"
            className="w-full mb-4 p-4 text-gray-900 bg-white text-left rounded shadow hover:bg-gray-200 focus:outline-none focus:shadow-outline"
            onClick={() => setIsCustomAddress(true)}
          >
            {formatMessage(messages.useDifferentAddress)}
          </button>

          <div className="flex justify-end w-full pt-4">
            <TertiaryButton
              className="mr-4"
              colorClassName="text-gray-600 hover:text-gray-700 focus:text-gray-700"
              onClick={onCancel}
            >
              {formatMessage(messages.cancelAddButton)}
            </TertiaryButton>
            <PrimaryButton
              disabled={!selectedAddress}
              onClick={() => setBillingAddress(selectedAddress)}
            >
              {formatMessage(messages.updateBilling)}
            </PrimaryButton>
          </div>
        </>
      )}

      {isCustomAddress && (
        <Formik
          initialValues={{
            addressName: formatMessage(messages.newAddressDefaultName),
            fullName: '',
            addressLine1: '',
            addressLine2: '',
            country: countryDefault,
            stateProvinceRegion: stateProvinceRegionDefault,
            city: '',
            companyName: '',
            postalCode: '',
            phonePrimary: ''
          }}
          onSubmit={values => {
            setBillingAddress({
              ...values,
              country: values.country,
              stateProvinceRegion: values.stateProvinceRegion,
              phonePrimary: { phoneNumber: values.phonePrimary }
            });
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <AddressForm type={AddressForm.Type.SAVED} />

              <div className="flex justify-end w-full pt-4">
                <TertiaryButton
                  className="mr-4"
                  colorClassName="text-gray-600 hover:text-gray-700 focus:text-gray-700"
                  onClick={() => setIsCustomAddress(false)}
                >
                  {formatMessage(messages.backButton)}
                </TertiaryButton>
                <PrimaryButton type="submit" disabled={isSubmitting}>
                  {formatMessage(messages.updateBilling)}
                </PrimaryButton>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

UserBillingAddresses.propTypes = {
  /** Function to set the current billing address **/
  setBillingAddress: PropTypes.func.isRequired,
  /** Function to cancel the update to the address **/
  onCancel: PropTypes.func.isRequired
};

export default UserBillingAddresses;
export { UserBillingAddresses };
