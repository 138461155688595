/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  csrMode: {
    id: 'CSRRibbon.csr.mode.title',
    defaultMessage: 'CSR Mode - Operating as {fullName} ({username})'
  },
  csrModeAnonymous: {
    id: 'CSRRibbon.csr.mode.anonymous.title',
    defaultMessage: 'CSR Mode - Operating as Guest'
  },
  checkout: {
    id: 'CSRRibbon.button.checkout.label',
    defaultMessage: 'Checkout'
  },
  exitCsr: {
    id: 'CSRRibbon.button.exit.label',
    defaultMessage: 'Exit CSR Mode'
  },
  cloneCustomerCart: {
    id: 'CSRRibbon.csr.button.cloneCustomerCart',
    defaultMessage: 'Clone Cart'
  },
  transferCartToCustomer: {
    id: 'CSRRibbon.csr.button.transferCartToCustomer',
    defaultMessage: 'Transfer Cart'
  }
});
