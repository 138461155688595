/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, sortBy } from 'lodash';

import {
  useNationalSiteContext,
  useReadRotatingBannerApi
} from 'app/common/hooks';
import Link from '../Link';

const Carousel = () => {
  const [currentBanner, setCurrentBanner] = React.useState(0);
  const { response } = useReadRotatingBannerApi();
  const banners = sortBy(get(response, 'content', []), 'displayOrder');
  const maxNum = banners.length - 1;
  const rotationDelay = 4000;
  const timeoutRef = React.useRef(null);
  const { isWhiteLabel } = useNationalSiteContext();
  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setCurrentBanner(() =>
          currentBanner === maxNum ? 0 : currentBanner + 1
        ),
      rotationDelay
    );

    return () => {
      resetTimeout();
    };
  }, [currentBanner, maxNum]);

  if (isWhiteLabel) {
    return null;
  }

  return (
    <div className="Carousel">
      <div className="Carousel-display">
        {banners.map((banner, index) => {
          return (
            <div className="relative justify-center items-center" key={index}>
              <input
                className="Carousel-radio"
                type="radio"
                id={'carousel-' + index}
                name={'carousel'}
                checked={index === currentBanner}
                aria-hidden
                hidden
                value={index}
                onChange={() => {
                  setCurrentBanner(index);
                }}
              />
              <div className="Carousel-item">
                {banner.link ? (
                  <Link to={banner.link}>
                    <img
                      src={banner.image.contentUrl}
                      alt={banner.name}
                      key={'carousel-' + index}
                    />
                  </Link>
                ) : (
                  <img
                    src={banner.image.contentUrl}
                    alt={banner.name}
                    key={'carousel-' + index}
                  />
                )}
              </div>
            </div>
          );
        })}
        <label
          htmlFor={
            'carousel-' + (currentBanner === 0 ? maxNum : currentBanner - 1)
          }
          className="Carousel-arrow prev"
        >
          ‹
        </label>
        <label
          htmlFor={
            'carousel-' + (currentBanner === maxNum ? 0 : currentBanner + 1)
          }
          className="Carousel-arrow next"
        >
          ›
        </label>
        <ol className="Carousel-bullets pointer-events-none">
          {banners.map((banner, index) => {
            return (
              <li className="pointer-events-auto" key={index}>
                <label
                  htmlFor={'carousel-' + index}
                  className={
                    currentBanner === index
                      ? 'Carousel-bullet selected'
                      : 'Carousel-bullet'
                  }
                >
                  •
                </label>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
};

export default Carousel;
export { Carousel };
