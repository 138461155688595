/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';
import { assign } from 'lodash';

const mainMessages = defineMessages({
  title: {
    id: 'CSRTransferAnonymousCart.title',
    defaultMessage: 'Transfer Cart'
  },
  subtitle: {
    id: 'CSRTransferAnonymousCart.subtitle',
    defaultMessage: 'Currently transferring the requested cart...'
  }
});

const error = defineMessages({
  generalError: {
    id: 'CSRTransferAnonymousCart.error.generalError',
    defaultMessage: 'Failed to transfer the cart'
  }
});

const returnHome = defineMessages({
  preamble: {
    id: 'CSRTransferAnonymousCart.returnHome.preamble',
    defaultMessage: 'Please visit our'
  },
  homepage: {
    id: 'CSRTransferAnonymousCart.returnHome.homepage',
    defaultMessage: 'homepage'
  },
  postamble: {
    id: 'CSRTransferAnonymousCart.returnHome.postamble',
    defaultMessage: 'to continue shopping!'
  }
});

export default assign({ returnHome, error }, mainMessages);
