import messages from './BrandsComponents.messages.js';
import { useFormatMessage, useLocation } from 'app/common/hooks';
import {
  useBrandsCatalogSearchApi,
  useMinCategoriesApi,
  useMinSubCatsApi
} from '../hooks';
import React, { useEffect, useMemo, useState } from 'react';
import Dropdown from 'app/common/components/Dropdown';
import { isEmpty, isNil, isString, set } from 'lodash';
import { Icon, TertiaryButton } from 'app/common/components';
import { useHistory } from 'react-router-dom';
import { useBrandsCategoryUrlParams } from '../hooks';

/**
 * @typedef {Object} MinCategory
 * @param {string} id The category ID
 * @param {string} name The category name
 * @param {string} url The category URL
 */

/**
 * @typedef {Object} BrandFacet
 * @param {string} value
 * @param {boolean} ranged
 * @param {number} quantity
 * @param {boolean} active
 */

/**
 * Show category and subcategory filters
 * @param {Array<String>} filteredBrands
 * @param setFilteredBrands
 * @returns {Element}
 * @constructor
 */
const BrandsCategoryFilters = ({ setFilteredBrands }) => {
  const subCategoryParamName = 'subcategory';
  const categoryParamName = 'category';

  const formatMessage = useFormatMessage();
  const location = useLocation();
  const history = useHistory();

  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedSubCat, setSelectedSubCat] = useState({});
  const minCategories = useMinCategoriesApi();
  const { minSubCats, clearMinSubCats } = useMinSubCatsApi(selectedCategory);
  const { categoryUrl, subcategoryUrl } = useBrandsCategoryUrlParams();

  useEffect(() => {
    if (categoryUrl) {
      for (let minCat of minCategories) {
        if (minCat.url === categoryUrl) {
          setSelectedCategory(minCat);
          clearMinSubCats();
          return;
        }
      }
    }
    // eslint-disable-next-line
  }, [categoryUrl, minCategories]);

  useEffect(() => {
    if (subcategoryUrl && !isEmpty(minSubCats)) {
      for (let minCat of minSubCats) {
        if (minCat.url === subcategoryUrl) {
          setSelectedSubCat(minCat);
          return;
        }
      }
    }
  }, [subcategoryUrl, minSubCats]);

  useBrandsCatalogSearchApi(
    selectedCategory,
    selectedSubCat,
    setFilteredBrands
  );

  const updateCategorySelection = function (categorySelection) {
    const params = new URLSearchParams(location.search);
    params.delete(subCategoryParamName);
    if (isEmpty(categorySelection)) {
      params.delete(categoryParamName);
    } else {
      params.set(categoryParamName, categorySelection.url.slice(1));
    }
    history.push({ search: params.toString() });

    setSelectedCategory(categorySelection);
    setSelectedSubCat({});
    clearMinSubCats();
  };

  const updateSubCategorySelection = function (subCategorySelection) {
    const params = new URLSearchParams(location.search);
    params.delete(subCategoryParamName);
    if (!isEmpty(subCategorySelection)) {
      params.set(subCategoryParamName, subCategorySelection.url.slice(1));
    }
    history.push({ search: params.toString() });
    setSelectedSubCat(subCategorySelection);
  };

  const removeCategoryFilter = function () {
    updateCategorySelection({});
    setFilteredBrands(null);
  };

  const removeSubCategoryFilter = function () {
    updateSubCategorySelection({});
  };

  return (
    <div className="flex flex-col flex-wrap md:items-start md:flex-row md:gap-x-8">
      <BrandCategoryFilterDropdown
        tag={'brand-category-selector'}
        selectedCategory={selectedCategory}
        minCategories={minCategories}
        updateSelection={updateCategorySelection}
        placeholder={messages.filterCategoryPlaceholder}
        formatMessage={formatMessage}
        removeFilter={removeCategoryFilter}
      />
      {minSubCats != null && minSubCats.length > 0 && (
        <BrandCategoryFilterDropdown
          tag={'brand-subcat-selector'}
          selectedCategory={selectedSubCat}
          minCategories={minSubCats}
          updateSelection={updateSubCategorySelection}
          placeholder={messages.filterSubCatPlaceholder}
          formatMessage={formatMessage}
          removeFilter={removeSubCategoryFilter}
        />
      )}
    </div>
  );
};

const BrandCategoryFilterDropdown = ({
  tag,
  selectedCategory,
  minCategories,
  updateSelection,
  placeholder,
  formatMessage,
  removeFilter
}) => {
  const labelId = tag + '-label';
  const triggerId = tag + '-trigger';
  return (
    <div className={'flex'}>
      <div id={labelId} className="mr-2 font-bold leading-loose inline-block">
        {formatMessage(messages.filterByLabel)}
      </div>
      <Dropdown className={'mt-1'}>
        <Dropdown.Menu.Trigger
          aria-labelledby={`${labelId} ${triggerId}`}
          id={triggerId}
          triggerClassName="pl-1 pr-2 text-primary-600 font-medium rounded hover:text-primary-800
            active:text-primary-600 focus:outline-none focus:shadow-outline"
        >
          <span>
            {isEmpty(selectedCategory)
              ? formatMessage(placeholder)
              : selectedCategory.name}
          </span>
        </Dropdown.Menu.Trigger>
        <Dropdown.Menu
          aria-labelledby={labelId}
          style={{
            maxHeight: '300px',
            overflowY: 'scroll'
          }}
          openTo={'LEFT'}
        >
          {minCategories !== null &&
            Object.values(minCategories).map(minCat => {
              const active =
                !isNil(selectedCategory) && selectedCategory.id === minCat.id;
              return (
                <Dropdown.Menu.Item
                  className="text-justify min-w-130px"
                  isActive={active}
                  key={minCat.id}
                  onClick={() => {
                    updateSelection(minCat);
                  }}
                >
                  <span>
                    {isString(minCat.name)
                      ? minCat.name
                      : formatMessage(minCat.name)}
                  </span>
                </Dropdown.Menu.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
      {!isEmpty(selectedCategory) && (
        <TertiaryButton
          onClick={() => {
            removeFilter();
          }}
        >
          <Icon className="ml-1 fa-lg" name="circle-xmark" />
        </TertiaryButton>
      )}
    </div>
  );
};

export default BrandsCategoryFilters;
export { BrandsCategoryFilters };
