/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty } from 'lodash';
import { Helmet as ReactHelmet } from 'react-helmet';

import {
  useFormatMessage,
  useGtmPageView,
  useNationalSiteContext,
  usePersonalizationInfo
} from 'app/common/hooks';
import { TargetedContentRenderer } from 'app/personalization/helpers';

import messages from './Contact.messages';
import { useHeaderMetadata } from '../../../core/components/App';

const Contact = () => {
  const formatMessage = useFormatMessage();
  const { useApplicationContent, partsToGo } = useNationalSiteContext();
  const { siteTitle } = useHeaderMetadata();
  const canonical = `${window.location.origin}/contact`;
  const title = formatMessage(messages.title);

  useGtmPageView('Contact Us');

  const defaultDescription = formatMessage(messages.description);
  const dealerDescription = formatMessage(messages.descriptionDealer, {
    dealerName: siteTitle
  });
  const description = partsToGo ? defaultDescription : dealerDescription;
  return (
    <main className="container relative flex-grow mx-auto px-4 py-8 text-gray-700 md:px-6 lg:px-8">
      <ReactHelmet titleTemplate={`%s - ${siteTitle}`}>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="canonical" content={canonical} />
        <meta name="robots" content="index,follow" />
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content={siteTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={description} />
        <link rel="canonical" href={canonical} />
      </ReactHelmet>
      {!partsToGo && useApplicationContent ? (
        <DealerContactContent />
      ) : (
        <DefaultContactContent />
      )}
    </main>
  );
};

const DefaultContactContent = () => {
  const personalizationInfo = usePersonalizationInfo();
  const targeterName = get(personalizationInfo, 'contact.targeterName');
  return (
    <>
      <TargetedContentRenderer
        name={targeterName}
        contextAttributes={HeadingContext}
      />
      <TargetedContentRenderer
        name={targeterName}
        contextAttributes={AddressContext}
      />
    </>
  );
};

const DealerContactContent = () => {
  const formatMessage = useFormatMessage();
  const { applicationThemeSource: application, resolving } =
    useNationalSiteContext();
  if (resolving) {
    return null;
  }
  const displayName = get(application, 'displayName');

  return (
    <>
      <header className="mb-8 pb-4 border-b border-gray-400">
        <h1 className="text-xl leading-none md:text-2xl lg:text-3xl">
          {formatMessage(messages.title)}
        </h1>
      </header>
      <section>
        <address className="not-italic appearance-none">
          <h2 className="mb-4 text-lg leading-none md:text-xl lg:text-2xl">
            {displayName}
          </h2>
          <div>{displayName}</div>
          <div>{application.line1}</div>
          {!isEmpty(application.line2) && <div>{application.line2}</div>}
          {!isEmpty(application.line3) && <div>{application.line3}</div>}
          <div>
            {application.city}, {application.state} {application.zip}
          </div>
          <div>{application.country}</div>
          <div>&nbsp;</div>
          <a
            className="block text-primary-500 hover:cursor-pointer hover:text-primary-600 focus:text-primary-700 active:text-primary-400"
            href={`tel:${application.phoneNumber}`}
          >
            {application.phoneNumber}
          </a>
          {!isEmpty(application.emailAddress) && (
            <a
              className="block text-primary-500 hover:text-primary-600 focus:text-primary-700 active:text-primary-400"
              href={`mailto:${application.emailAddress}`}
            >
              {application.emailAddress}
            </a>
          )}
        </address>
      </section>
    </>
  );
};

const HeadingContext = Object.freeze({ zone: { name: 'HEADING' } });
const AddressContext = Object.freeze({ zone: { name: 'INFO' } });

export default Contact;
export { Contact };
