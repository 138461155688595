/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { connect } from 'formik';
import { isEmpty, isInteger, isNumber, toNumber, toInteger } from 'lodash';

import { Field, TextAreaField } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from './InputAttribute.messages';

const InputAttribute = ({
  formik,
  handleBlur,
  name,
  required,
  type,
  ...props
}) => {
  const formatMessage = useFormatMessage();
  const additionalProps = InputAttribute.determineInputPropsForType(
    type,
    required,
    formatMessage
  );

  return (
    <Field
      {...props}
      {...additionalProps}
      handleBlur={e => {
        formik.handleBlur(e);
        handleBlur(e.currentTarget.value);
      }}
      name={name}
      autoComplete="off"
    />
  );
};

InputAttribute.determineInputPropsForType = (type, required, formatMessage) => {
  switch (type) {
    case 'TEXT_AREA':
      return {
        component: TextAreaField,
        validate: value => {
          if (required && isEmpty(value)) {
            return formatMessage(messages.required);
          }
        }
      };
    case 'DECIMAL':
      return {
        type: 'number',
        validate: value => {
          if (required && !value && value !== 0) {
            return formatMessage(messages.required);
          }

          if (!isEmpty(value) && !isNumber(toNumber(value))) {
            return formatMessage(messages.invalidDecimal);
          }
        }
      };
    case 'INTEGER':
      return {
        type: 'number',
        step: 1,
        validate: value => {
          if (required && !value && value !== 0) {
            return formatMessage(messages.required);
          }

          if (!isEmpty(value) && !isInteger(toInteger(value))) {
            return formatMessage(messages.invalidInteger);
          }
        },
        pattern: '^-?[\\d]+'
      };
    case 'DATE':
      // todo: support picker for IE 11 and Safari?
      //  this will work fine for Safari on iOS, Opera, Chrome, Firefox, and Edge
      return {
        type: 'date',
        validate: value => {
          if (required && !value) {
            return formatMessage(messages.required);
          }

          if (!value) {
            return;
          }

          if (
            (value.getTime && isNaN(value.getTime())) ||
            isNaN(new Date(value).getTime())
          ) {
            return formatMessage(messages.invalidDate);
          }
        }
      };
    default:
      return {
        validate: value => {
          if (required && isEmpty(value)) {
            return formatMessage(messages.required);
          }
        }
      };
  }
};

export default connect(InputAttribute);
export { InputAttribute };
