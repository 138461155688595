/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty } from 'lodash';

import { PaymentInfo } from 'app/checkout/components/CheckoutLayout/components';
import { parseToken } from 'app/common/utils/PaymentInfoUtils';
import { useFormatMessage } from 'app/common/hooks';

import messages from './OrderReviewPayment.messages';

const OrderReviewPayment = ({ order, truncateBillingAddress = false }) => {
  const paymentInfo = get(order, 'activePayments[0]') || {};
  const isPayInStore = get(order, 'attributes.PAY_IN_STORE', false);
  const formatMessage = useFormatMessage();

  if (isEmpty(paymentInfo) && !isPayInStore) {
    return null;
  }

  let attributes;
  let GatewayComponent;
  let type;

  if (isPayInStore) {
    type = 'payInStore';
  } else if (
    PaymentInfo.Passthrough.Editable.GatewayType === paymentInfo.gatewayType
  ) {
    const {
      paymentGatewayProperties: { paymentToken }
    } = paymentInfo;
    attributes = parseToken(paymentToken);
    GatewayComponent = PaymentInfo.Passthrough;
    type = 'passthrough';
  } else if (
    PaymentInfo.PayPal.Editable.GatewayType === paymentInfo.gatewayType
  ) {
    attributes = paymentInfo.attributes;
    GatewayComponent = PaymentInfo.PayPal;
    type = 'paypal';
  }

  return (
    <section className="flex flex-col w-full mb-4 md:flex-row md:justify-between">
      {isPayInStore ? (
        <div>
          <h3 className="mb-1 text-base text-gray-900 font-bold">
            {formatMessage(messages.payInStore)}
          </h3>
          <div>{get(order, 'contextState.application.name')}</div>
        </div>
      ) : (
        <>
          <GatewayComponent.ReadOnly.Method
            className="w-full text-sm md:w-1/2"
            messages={messages}
            attributes={attributes}
            type={type}
          />
          {!isEmpty(paymentInfo.billingAddress) && (
            <GatewayComponent.ReadOnly.Billing
              className="w-full text-sm md:w-1/2"
              messages={messages}
              billingAddress={paymentInfo.billingAddress}
              truncateAddress={truncateBillingAddress}
            />
          )}
        </>
      )}
    </section>
  );
};

OrderReviewPayment.Skeleton = ({ truncateBillingAddress = false }) => (
  <section className="relative flex flex-col my-4 border-t-2 border-solid border-gray-200 md:flex-row lg:py-6">
    <section className="w-full text-sm md:w-1/2">
      <h3 className="w-32 mt-2 mb-1 text-base bg-gray-200 font-bold md:mt-0">
        &nbsp;
      </h3>
      <div className="w-48 mb-1 bg-gray-200">&nbsp;</div>
    </section>
    <section className="w-full text-sm md:w-1/2 md:pr-1">
      <h3 className="w-32 mt-2 mb-1 text-base bg-gray-200 font-bold md:mt-0">
        &nbsp;
      </h3>
      <div className="w-20 mb-px bg-gray-200">&nbsp;</div>
      <div className="w-24 mb-px bg-gray-200">&nbsp;</div>
      {!truncateBillingAddress && (
        <>
          <div className="w-24 mb-px bg-gray-200">&nbsp;</div>
          <div className="w-12 mb-px bg-gray-200">&nbsp;</div>
          <div className="w-24 mb-px bg-gray-200">&nbsp;</div>
        </>
      )}
    </section>
  </section>
);

export default OrderReviewPayment;
export { OrderReviewPayment };
