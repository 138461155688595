import { useFormatMessage } from 'app/common/hooks';
import React from 'react';
import { ConfigurableItemContext, PdpContext } from 'app/product/contexts';
import { get, isEmpty, toLower } from 'lodash';
import messages from '../../MerchandisingInfo.messages';
import { ProductCollapsible } from './ProductCollapsible';
import { Icon } from 'app/common/components';

const ProductSpecs = ({ className }) => {
  return (
    <>
      <Specifications className={className} />
      <ProductDocuments className={className} />
    </>
  );
};

function ProductDocuments({ className }) {
  const formatMessage = useFormatMessage();
  const { documents } = React.useContext(PdpContext);

  let filteredDocuments = documents.filter(doc => doc.asset?.url);
  if (isEmpty(filteredDocuments)) {
    return null;
  }
  return (
    <ProductCollapsible
      title={formatMessage(messages.documents)}
      className={className}
    >
      <section className="p-4">
        <ul className={'part-document-container text-sm sm:text-base'}>
          {filteredDocuments.map(doc => (
            <li key={doc.id} className="pt-2 sm:pt-2">
              <a
                href={doc.asset.url}
                target="_blank"
                rel="noopener noreferrer"
                className={
                  'text-primary-500 hover:text-primary-700 focus:text-primary-700 active:text-primary-500'
                }
              >
                <span>{doc.title}</span>
                <Icon className="pl-2" name="external-link-alt" size="sm" />
              </a>
            </li>
          ))}
        </ul>
      </section>
    </ProductCollapsible>
  );
}

const Specifications = ({ className }) => {
  const formatMessage = useFormatMessage();
  const { product } = React.useContext(PdpContext);
  const { activeOption } = React.useContext(ConfigurableItemContext);

  /**
   * Group attributes by their attribute key, so multi-value attributes
   * display as a comma separated list.
   */
  const {
    response: attributes,
    hasWeight,
    hasDepth,
    hasHeight,
    hasWidth
  } = getAttributes(activeOption, product);
  const weight = get(activeOption, 'weight', product.weight);
  const dimensions = get(activeOption, 'dimension', product.dimension);

  if (isEmpty(attributes) && isEmpty(weight) && isEmpty(dimensions)) {
    return null;
  }
  return (
    <ProductCollapsible
      title={formatMessage(messages.specifications)}
      className={className}
    >
      <section className="p-4">
        <ul className="flex flex-col list-none text-sm sm:text-base">
          <AttributeSpecifications attributes={attributes} />
          {!isEmpty(dimensions) && (
            <>
              <Measurement
                unit={dimensions}
                unitPath="height"
                label="height"
                valueLabel="dimensionValue"
                alreadyRendered={hasHeight}
              />
              <Measurement
                unit={dimensions}
                unitPath="width"
                label="width"
                valueLabel="dimensionValue"
                alreadyRendered={hasWidth}
              />
              <Measurement
                unit={dimensions}
                unitPath="depth"
                label="depth"
                valueLabel="dimensionValue"
                alreadyRendered={hasDepth}
              />
            </>
          )}
          <Measurement
            unit={weight}
            unitPath="weight"
            label="weightLabel"
            valueLabel="weightValue"
            alreadyRendered={hasWeight}
          />
        </ul>
      </section>
    </ProductCollapsible>
  );
};

const Measurement = ({
  unit,
  unitPath,
  label,
  valueLabel,
  alreadyRendered
}) => {
  const formatMessage = useFormatMessage();
  if (alreadyRendered || isEmpty(unit) || isNaN(get(unit, unitPath))) {
    return null;
  }

  return (
    <li className="flex">
      <div className="font-semibold basis-1/2 sm:basis-48">
        {formatMessage(get(messages, label))}
      </div>
      <div>
        {formatMessage(get(messages, valueLabel), {
          value: get(unit, unitPath),
          units: get(unit, 'units')
        })}
      </div>
    </li>
  );
};

const AttributeSpecifications = ({ attributes }) => {
  return (
    <>
      {attributes.map(attr => (
        <li key={attr.productAttributeKey.key} className="flex">
          <div className="capitalize font-semibold basis-1/2 sm:basis-48">
            {toLower(
              attr.productAttributeKey.label == null
                ? attr.productAttributeKey.key
                : attr.productAttributeKey.label
            )}
            {':'}
          </div>
          <div className="basis-1/2 flex-grow-0 capitalize break-words">
            {toLower(attr.value)}
          </div>
        </li>
      ))}
    </>
  );
};

function getAttributes(activeOption, product) {
  const attributes = get(
    activeOption,
    'productAttributes',
    product.productAttributes
  );
  let groupedAttributes = [];
  let hasWeight = false;
  let hasWidth = false;
  let hasDepth = false;
  let hasHeight = false;
  for (let i = 0; i < attributes.length; i++) {
    const attr = attributes[i];
    const key = get(attr, 'productAttributeKey.key', '');
    const upperCase = key.toUpperCase();

    hasWeight = hasWeight || upperCase === 'WEIGHT';
    hasWidth = hasWidth || upperCase === 'WIDTH';
    hasDepth = hasDepth || upperCase === 'DEPTH';
    hasHeight = hasHeight || upperCase === 'HEIGHT';

    if (upperCase === 'SHORT_DESCRIPTION' || upperCase === 'LONG_DESCRIPTION') {
      continue;
    } else if (groupedAttributes[key]) {
      let existing = groupedAttributes[key];
      existing.value = existing.value + ', ' + attr.value;
    } else {
      groupedAttributes[key] = { ...attr };
    }
  }
  let response = [];
  for (let key in groupedAttributes) {
    if (groupedAttributes.hasOwnProperty(key)) {
      response.push(groupedAttributes[key]);
    }
  }
  return {
    response,
    hasWeight,
    hasWidth,
    hasDepth,
    hasHeight
  };
}

export { ProductSpecs };
