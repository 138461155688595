import { useMemo } from 'react';
import { Environment } from 'app/common/services';

function useBrandsInfo() {
  return useMemo(
    () => ({
      brandsRootPath: Environment.get('brands.path.root', '/brands'),
      allBrandsPath: Environment.get(
        'brands.path.all',
        'catalog-browse/top-brands/all-brands'
      ),
      featuredBrandsPath: Environment.get(
        'brands.path.featured',
        'catalog-browse/top-brands/featured-brands'
      ),
      singleBrandPath: Environment.get(
        'brands.path.single',
        'catalog-browse/top-brands/brand'
      ),
      brandCatalogSearchPath: Environment.get(
        'brands.path.search',
        'catalog-browse/search/catalog'
      ),
      minCategoriesPath: Environment.get(
        'brands.path.categories',
        'catalog/categories/min'
      )
    }),
    []
  );
}

export default useBrandsInfo;
