/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useState } from 'react';
import { isEmpty, isNil, join, map, reduce } from 'lodash';

import { Link } from 'app/common/components';
import {
  useCatalogInfo,
  useFetchPageApi,
  useFormatMessage,
  useRefreshEffect
} from 'app/common/hooks';
import { addParams } from 'app/common/utils/PathUtils';

import messages from './CategorySuggestions.messages';

const CategorySuggestions = ({ query, suggestions }) => {
  const formatMessage = useFormatMessage();
  const [categoriesByName, setCategoriesByName] = useState({});
  const {
    categoryInfo: { fetchUrl, routeBaseContext }
  } = useCatalogInfo();
  const config = React.useMemo(() => {
    let cq = join(
      suggestions.map(({ categoryNames }) => `name=='${categoryNames}'`),
      ','
    );

    if (suggestions.length > 1) {
      cq = `(${cq})`;
    }

    return { params: { cq, rootsOnly: false } };
  }, [suggestions]);
  const { sendCallback: getAllCategories } = useFetchPageApi(
    fetchUrl,
    config,
    false
  );

  useRefreshEffect(() => {
    if (!isEmpty(suggestions)) {
      getAllCategories().then(response => {
        setCategoriesByName(
          reduce(
            response.content,
            (result, category) => ({ ...result, [category.name]: category }),
            {}
          )
        );
      });
    }
  }, suggestions);

  return (
    <div className="py-2 border-t last:border-r-0 last:mr-0 lg:mr-4 lg:pr-4 lg:py-0 lg:border-t-0 lg:border-r">
      <div className="mb-4 text-2xl capitalize font-medium">
        {formatMessage(messages.categories)}
      </div>
      <ul className="block list-none" role="presentation">
        {map(suggestions, ({ categoryNames, numResults }) => {
          const category = categoriesByName[categoryNames];

          if (isNil(category)) {
            return null;
          }

          const categoryUrl = addParams(`${routeBaseContext}${category.url}`, {
            q: query
          });

          return (
            <li className="mb-2 w-64" key={categoryNames}>
              <Link
                to={categoryUrl}
                className="text-gray-700 hover:text-gray-900"
              >
                {categoryNames} ({numResults})
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CategorySuggestions;
