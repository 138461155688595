/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  addPhone: {
    id: 'Auth.Register.phone.add',
    defaultMessage: 'Add Phone Number'
  },
  exampleEmail: {
    id: 'Auth.Register.email.example',
    defaultMessage: 'email@example.com'
  },
  exampleName: {
    id: 'Auth.Register.name.example',
    defaultMessage: 'First Last'
  },
  exampleFirstName: {
    id: 'Auth.Register.firstName.example',
    defaultMessage: 'First'
  },
  exampleLastName: {
    id: 'Auth.Register.lastName.example',
    defaultMessage: 'Last'
  },
  examplePhone: {
    id: 'Auth.Register.phone.example',
    defaultMessage: '(123) 456-7890'
  },
  email: {
    id: 'Auth.Register.email',
    defaultMessage: 'Email'
  },
  name: {
    id: 'Auth.Register.name',
    defaultMessage: 'Full Name'
  },
  firstName: {
    id: 'Auth.Register.firstName',
    defaultMessage: 'First Name'
  },
  lastName: {
    id: 'Auth.Register.lastName',
    defaultMessage: 'Last Name'
  },
  phone: {
    id: 'Auth.Register.phone',
    defaultMessage: 'Phone Number'
  },
  submit: {
    id: 'Auth.Register.submit',
    defaultMessage: 'Register Account'
  },
  formExplanation: {
    id: 'Auth.Register.legend',
    defaultMessage:
      'The following information helps our dealers differentiate customers and lets you checkout faster.'
  },
  noCompany: {
    id: 'Auth.Register.noCompany',
    defaultMessage: 'I do not have a company'
  }
});
