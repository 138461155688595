/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { areAddressesEquivalent } from '@broadleaf/payment-js';
import { get, isEmpty } from 'lodash';

import { FulfillmentType } from 'app/common/constants';
import { PaymentContext } from 'app/common/contexts';
import { AddressForm } from 'app/common/components';

function useBillingAddress(cart) {
  const { payments } = React.useContext(PaymentContext);

  return React.useMemo(() => {
    const fulfillmentGroup = get(cart, 'fulfillmentGroups[0]', {});
    const { address: fulfillmentAddress = {}, type: fulfillmentType } =
      fulfillmentGroup;

    const payment = get(payments, 'content[0]', {});
    const billingAddress = get(
      payment,
      'addressByType.' + AddressForm.Type.BILLING
    );

    let address = billingAddress;
    let useFulfillmentAddress = false;
    let hasFulfillmentAddress = !isEmpty(fulfillmentAddress);

    if (
      fulfillmentType !== FulfillmentType.VIRTUAL &&
      hasFulfillmentAddress &&
      (isEmpty(billingAddress) ||
        areAddressesEquivalent(billingAddress, fulfillmentAddress))
    ) {
      address = fulfillmentAddress || {};
      useFulfillmentAddress = true;
    }
    return { address, useFulfillmentAddress, hasFulfillmentAddress };
  }, [cart, payments]);
}

export default useBillingAddress;
