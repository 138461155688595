/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

const DefaultPDPSkeleton = props => {
  return (
    <main className="relative flex-grow min-h-screen p-4 sm:py-8 sm:px-6 lg:px-8">
      <nav className="container w-full mb-4 sm:mb-8">
        <ol className="flex w-full text-xs sm:text-sm lg:text-base">
          <li className="flex-1 overflow-hidden text-left text-gray-700 whitespace-no-wrap">
            <span className="w-12 inline-block bg-gray-200 rounded">
              &nbsp;
            </span>
            <span className="inline-block mx-1 sm:mx-2 lg:mx-3">{'/'}</span>
            <span className="w-24 inline-block bg-gray-200 rounded">
              &nbsp;
            </span>
          </li>
        </ol>
      </nav>
      <section className="container flex flex-col justify-start mt-2 sm:mt-5 sm:flex-row sm:flex-wrap lg:flex-nowrap">
        <header className="mb-4 sm:hidden">
          <h1 className="block mb-2 text-lg font-medium leading-tight sm:text-xl lg:text-2xl bg-gray-200 rounded">
            &nbsp;
          </h1>
          <h2 className="block mb-1 w-24 bg-gray-200 rounded">&nbsp;</h2>
          <h3 className="block mb-4 w-48 bg-gray-200 rounded">&nbsp;</h3>
          <div className="block mb-4 w-64 bg-gray-200 rounded">&nbsp;</div>
          <div className="flex items-baseline justify-start">
            <div className="w-48 mr-2 text-3xl bg-gray-200 rounded">&nbsp;</div>
          </div>
        </header>
        <section className="py-4 sm:py-0 sm:pr-8 sm:flex-shrink sm:basis-1/2 md:basis-2/5 lg:basis-1/3 lg:pr-12">
          <figure className="w-full rounded">
            <picture className="block rounded w-full">
              <div
                className="h-auto w-full rounded bg-gray-200"
                style={{
                  paddingTop: '100%',
                  width: '100%'
                }}
              />
            </picture>
          </figure>
        </section>
        <section className="flex flex-col sm:flex-grow sm:basis-1/2 md:basis-3/5 lg:basis-2/3">
          <header className="mb-4 hidden sm:block">
            <h1 className="block mb-2 text-lg font-medium leading-tight sm:text-xl lg:text-2xl bg-gray-200 rounded">
              &nbsp;
            </h1>
            <h2 className="block mb-1 w-24 bg-gray-200 rounded">&nbsp;</h2>
            <h3 className="block mb-4 w-48 bg-gray-200 rounded">&nbsp;</h3>
            <div className="block mb-4 w-64 bg-gray-200 rounded">&nbsp;</div>
            <div className="flex items-baseline justify-start">
              <div className="w-48 mr-2 text-3xl bg-gray-200 rounded">
                &nbsp;
              </div>
            </div>
          </header>
        </section>
      </section>
    </main>
  );
};

export default DefaultPDPSkeleton;
export { DefaultPDPSkeleton };
