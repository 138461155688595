import {
  APP_LOADED,
  CLOSE_AUTO_COMPLETE,
  CLOSE_STORE_SEARCH,
  HANDLE_ADDRESS_SELECT,
  LOADING_LOCATIONS_ACTION,
  LOADING_LOCATIONS_COMPLETE_ACTION,
  OPEN_AUTO_COMPLETE,
  OPEN_STORE_SEARCH,
  RESET_ERRORS,
  SET_AUTO_COMPLETE_RESULTS,
  SET_CURRENT_STORE,
  SET_DEALER_NUMBER,
  SET_ERROR,
  SET_QUERY,
  SET_STATE,
  SET_STORES,
  SET_USING_LOC_COORDS
} from './storeDropdownActions';
import { LocalStorageCache } from 'app/common/services';
import { get } from 'lodash';

const StoreResultsCache = new LocalStorageCache('StoreResultsCache', {
  enableLocal: true,
  enableSession: true,
  // 10 minutes
  ttl: 1000 * 10 * 10
});

const STORE_RESULTS_KEY = `nearby_stores_${window.location.hostname}`;

const initialState = {
  // data
  /**
   * @type {{q: string, skipApiCall: boolean}}
   * The current query string in store search input
   */
  query: { q: '', skipApiCall: false },
  /**
   * @type {Array}
   * The list of stores to display in the store search dropdown
   */
  stores: undefined,
  /**
   * The currently selected store number
   * @type {string}
   */
  dealerNumber: undefined,
  /**
   * The autocomplete address suggestions
   * @type {Array}
   */
  autoCompleteResults: [],
  /**
   * The user's selected address, chosen from an autoCompleteResult.
   * @type {Object}
   */
  userSelectedAddress: null,
  // UI state
  /**
   * Is the store search dropdown open?
   * @type {boolean}
   */
  storeSearchOpen: false,
  /**
   * Is the autocomplete suggestions popover open?
   * @type {boolean}
   */
  autoCompleteOpen: false,
  // location data
  /**
   * The user's lat/long if using browser location
   * @type {{lat: number, lng: number}}
   */
  usingLocCoords: undefined,

  /**
   * The user's current store in a format that can be used by the store search dropdown.
   * Derived from the application object.
   * @type {Object}
   */
  currentStore: null,
  // resolving state
  /**
   * Is the store search dropdown loading?
   * @type {boolean}
   */
  isLoadingLocations: false,
  /**
   * Current dealer name
   */
  currentDealerName: null,

  /**
   * Has an error occurred in the store search?
   */
  error: false,

  /**
   * Error message to display
   */
  errorMessage: undefined,

  /**
   * Has the user clicked the select store button?
   */
  userClickedSelectStore: false
};

const reducer = (state, action) => {
  const { type, payload } = action;
  let cachedStores;
  switch (type) {
    case OPEN_STORE_SEARCH:
      if (get(state, 'stores', []).length === 0) {
        cachedStores = StoreResultsCache.get(STORE_RESULTS_KEY) || undefined;
      } else {
        cachedStores = state.stores;
      }
      return {
        ...state,
        stores: cachedStores || undefined,
        storeSearchOpen: true,
        ...resetErrorState
      };
    case CLOSE_STORE_SEARCH:
      return { ...state, ...payload, ...resetErrorState };
    case OPEN_AUTO_COMPLETE:
      // Possible workaround for mobile safari browser where
      // the popover extends beyond the screen width
      window.dispatchEvent(new Event('resize'));
      return { ...state, autoCompleteOpen: true };
    case CLOSE_AUTO_COMPLETE:
      return { ...state, autoCompleteOpen: false, ...resetErrorState };
    case SET_QUERY:
      return {
        ...state,
        query: { q: payload, skipApiCall: false },
        autoCompleteOpen: true,
        ...resetErrorState
      };
    case HANDLE_ADDRESS_SELECT:
      return {
        ...state,
        query: { q: payload.q, skipApiCall: true },
        userSelectedAddress: {
          latitude: payload.latitude,
          longitude: payload.longitude,
          suggestion: payload.q
        },
        ...resetErrorState,
        autoCompleteOpen: false
      };
    case SET_DEALER_NUMBER:
      return {
        ...state,
        dealerNumber: payload,
        userClickedSelectStore: true,
        ...resetErrorState
      };
    case SET_AUTO_COMPLETE_RESULTS:
      return { ...state, autoCompleteResults: payload };
    case SET_USING_LOC_COORDS:
      return {
        ...state,
        query: {},
        stores: undefined,
        usingLocCoords: payload,
        userSelectedAddress: payload,
        ...resetErrorState
      };
    case SET_CURRENT_STORE:
      return { ...state, currentStore: payload, ...resetErrorState };
    case LOADING_LOCATIONS_ACTION:
      return { ...state, isLoadingLocations: true, ...resetErrorState };
    case LOADING_LOCATIONS_COMPLETE_ACTION:
      return { ...state, isLoadingLocations: false };
    case RESET_ERRORS:
      return {
        ...state,
        ...resetErrorState
      };
    case SET_ERROR:
      return { ...state, errorMessage: payload, error: true };
    case APP_LOADED:
      const { currentStore: selectedStore } = payload;
      const { stores: currentStores } = state || undefined;
      const filteredStores = currentStores?.filter(
        s => s.locationNumber !== selectedStore?.locationNumber
      );
      return { ...state, ...payload, stores: filteredStores };
    case SET_STATE:
      return { ...state, ...payload };
    case SET_STORES:
      const { stores: toCache } = payload;
      StoreResultsCache.put(STORE_RESULTS_KEY, toCache);
      return { ...state, ...payload, ...resetErrorState };
    default:
      return state;
  }
};

const resetErrorState = {
  error: false,
  errorMessage: undefined
};

const LOADING_LOCATIONS = {
  type: LOADING_LOCATIONS_ACTION
};

const LOADING_LOCATIONS_COMPLETE = {
  type: LOADING_LOCATIONS_COMPLETE_ACTION
};

const STORE_SEARCH_OPEN = {
  type: OPEN_STORE_SEARCH
};
const STORE_SEARCH_CLOSE = {
  type: CLOSE_STORE_SEARCH,
  payload: {
    storeSearchOpen: false,
    autoCompleteOpen: false,
    isLoadingLocations: false
  }
};

const AUTO_COMPLETE_OPEN = {
  type: OPEN_AUTO_COMPLETE
};
const AUTO_COMPLETE_CLOSE = {
  type: CLOSE_AUTO_COMPLETE
};

const RESET_LOCATION_QUERY = {
  type: SET_STATE,
  payload: {
    userSelectedAddress: undefined,
    autoCompleteResults: []
  }
};

const STORE_LOOKUP_ERROR = errorMessage => {
  return {
    type: SET_ERROR,
    payload: {
      ...errorMessage
    },
    isLoadingLocations: false
  };
};
export {
  reducer,
  initialState,
  LOADING_LOCATIONS,
  LOADING_LOCATIONS_COMPLETE,
  STORE_SEARCH_OPEN,
  STORE_SEARCH_CLOSE,
  AUTO_COMPLETE_OPEN,
  AUTO_COMPLETE_CLOSE,
  RESET_LOCATION_QUERY,
  STORE_LOOKUP_ERROR
};
