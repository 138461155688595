/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty } from 'lodash';

import { PdpContext } from 'app/product/contexts';

const GlobalAddToCartErrors = () => {
  const { addToCartErrors } = React.useContext(PdpContext);
  const hasErrors =
    !isEmpty(addToCartErrors) && !isEmpty(addToCartErrors.globalConfigErrors);

  if (!hasErrors) {
    return null;
  }

  return (
    <ul className="flex flex-col mb-4 list-none">
      {addToCartErrors.globalConfigErrors.map(({ errorCode, errorMessage }) => (
        <li className="mb-2 text-red-600 last:mb-0" key={errorCode}>
          {errorMessage}
        </li>
      ))}
    </ul>
  );
};

export default GlobalAddToCartErrors;
export { GlobalAddToCartErrors };
