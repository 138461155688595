/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useProductClick } from 'app/common/hooks/gtm';
import React from 'react';
import { get, values } from 'lodash';
import PropTypes from 'prop-types';

import { Button } from 'app/common/components';
import { AssetType } from 'app/common/constants';
import { addParams } from 'app/common/utils/PathUtils';

import messages from '../../DialogItem.messages';

/**
 * Render component for the cart item's primary asset.
 */
const ItemAsset = ({ imageAsset, productName, productUrl, gtmItem }) => {
  const { altText, title, contentUrl: src } = imageAsset;
  const sendProductClick = useProductClick(gtmItem);
  return (
    <figure className="relative block flex-grow flex-shrink basis-3/10 mr-2 rounded">
      <Button
        to={location => ({
          ...location,
          pathname: productUrl,
          state: {
            ...get(location, 'state', {}),
            breadcrumbs: [
              {
                uri: '/cart',
                label: messages.cartBreadcrumbLabel
              },
              {
                label: productName
              }
            ]
          }
        })}
        onClick={() => {
          sendProductClick();
        }}
      >
        <img
          className="block h-auto w-full rounded"
          src={`${addParams(src, { thumbnail: true })}`}
          alt={altText || productName}
          title={title}
          height="96"
          width="96"
        />
      </Button>
    </figure>
  );
};

ItemAsset.propTypes = {
  primaryAsset: PropTypes.shape({
    altText: PropTypes.string,
    contentUrl: PropTypes.string,
    /** HTML to embed in place of having a source URL */
    embedCode: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.oneOf(values(AssetType))
  }),
  productName: PropTypes.string,
  productUrl: PropTypes.string.isRequired
};

export default ItemAsset;
export { ItemAsset };
