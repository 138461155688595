/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';

import { useFormatMessage } from 'app/common/hooks';

import { FormActions, PaymentForm, ReadOnlyBillingAddress } from './components';
import UserBillingAddresses from '../UserBillingAddresses';
import messages from './SavedPaymentForm.messages';

const SavedPaymentForm = props => {
  const { editPayment, isActive, paymentAccount, refetchPayments } = props;
  const formatMessage = useFormatMessage();
  const [billingAddress, setBillingAddress] = useState(undefined);
  const [editAddress, setEditAddress] = useState(false);

  useEffect(() => {
    if (paymentAccount) {
      setBillingAddress({
        fullName: paymentAccount.fullName,
        companyName: paymentAccount.companyName,
        addressLine1: paymentAccount.addressLine1,
        addressLine2: paymentAccount.addressLine2,
        city: paymentAccount.city,
        stateProvinceRegion: paymentAccount.stateProvinceRegion,
        country: paymentAccount.country,
        postalCode: paymentAccount.postalCode,
        phonePrimary: paymentAccount.phonePrimary
      });
    }
  }, [paymentAccount]);

  // if we're no longer active, cancel any edits
  useEffect(() => {
    if (!isActive) {
      setEditAddress(false);
    }
  }, [isActive]);

  const formattedExpMonth =
    !!paymentAccount &&
    paymentAccount.expirationMonth.toString().padStart(2, '0');

  return (
    <div className="w-full p-4 bg-gray-100">
      <Formik
        initialValues={
          !!paymentAccount
            ? {
                displayName: paymentAccount.displayName,
                creditCardNumber: paymentAccount.maskedAccountNumber,
                creditCardExpDateMonth: formattedExpMonth,
                creditCardExpDateYear: paymentAccount.expirationYear.toString(),
                creditCardSecCode: '',
                creditCardHolder: paymentAccount.nameOnAccount
              }
            : {
                displayName: '',
                creditCardNumber: '',
                creditCardExpDateMonth: '',
                creditCardExpDateYear: '',
                creditCardSecCode: '',
                creditCardHolder: ''
              }
        }
      >
        {({ values, resetForm }) => (
          <div>
            <div className="flex flex-col sm:flex-row">
              <div className="w-1/2">
                <Form className="flex flex-col">
                  <PaymentForm
                    isEdit={!!paymentAccount?.id}
                    disabled={editAddress}
                  />
                </Form>
              </div>

              <div className="w-1/2 mt-8 sm:mt-0">
                {!editAddress && billingAddress && (
                  <ReadOnlyBillingAddress
                    billingAddress={billingAddress}
                    changeBillingAddress={() => setEditAddress(true)}
                  />
                )}
                {!editAddress && !billingAddress && (
                  <>
                    <div className="mb-4 text-gray-900 font-bold lg:text-lg">
                      {formatMessage(messages.billingAddress)}
                    </div>
                    <button
                      type="button"
                      className="mt-4 px-4 py-2 text-sm text-gray-900 bg-white rounded shadow hover:bg-gray-200 focus:outline-none focus:shadow-outline"
                      onClick={() => setEditAddress(true)}
                    >
                      {formatMessage(messages.chooseBilling)}
                    </button>
                  </>
                )}
                {editAddress && (
                  <div className="w-full bg-gray-100">
                    <UserBillingAddresses
                      setBillingAddress={address => {
                        setBillingAddress(address);
                        setEditAddress(false);
                      }}
                      onCancel={() => setEditAddress(false)}
                    />
                  </div>
                )}
              </div>
            </div>

            {!editAddress && (
              <FormActions
                billingAddress={billingAddress}
                setBillingAddress={setBillingAddress}
                editPayment={editPayment}
                paymentAccount={paymentAccount}
                refetchPayments={refetchPayments}
                values={values}
                resetForm={resetForm}
              />
            )}
          </div>
        )}
      </Formik>
    </div>
  );
};

SavedPaymentForm.propTypes = {
  /** Function to trigger editing of the saved payment **/
  editPayment: PropTypes.func,
  /** Is the form active **/
  isActive: PropTypes.bool,
  /** The saved payment **/
  paymentAccount: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
    paymentToken: PropTypes.string,
    nameOnAccount: PropTypes.string,
    expirationMonth: PropTypes.number,
    expirationYear: PropTypes.number,
    maskedAccountNumber: PropTypes.string,
    fullName: PropTypes.string,
    companyName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    stateProvinceRegion: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    phonePrimary: PropTypes.shape({ phoneNumber: PropTypes.string }),
    createdDate: PropTypes.number,
    updatedDate: PropTypes.number,
    activePaymentAccount: PropTypes.string
  }),
  /** Function to refetch saved payments **/
  refetchPayments: PropTypes.func.isRequired
};

export default SavedPaymentForm;
export { SavedPaymentForm };
