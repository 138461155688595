/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { values } from 'lodash';
import PropTypes from 'prop-types';

import Button from '../Button';

/**
 * Generic tertiary button. Takes additional class names to add to the base ones
 * and any props that would normally be applied to a button or link.
 * Content like text and icons should be passed as children.
 */
const TertiaryButton = ({
  alignment = 'justify-center',
  children,
  className,
  colorClassName = 'text-primary-500 hover:text-primary-700 focus:text-primary-700 active:text-primary-500',
  textStyleClassName = 'font-bold',
  size = TertiaryButton.Size.BASE,
  ...props
}) => (
  <Button
    className={classNames(
      'inline-flex items-center',
      'appearance-none bg-transparent',
      'focus:outline-none disabled:text-gray-400 disabled:cursor-not-allowed',
      {
        [className]: !!className,
        [colorClassName]: !!colorClassName,
        [textStyleClassName]: !!textStyleClassName,
        [alignment]: !!alignment,
        'text-base md:text-lg lg:text-xl': size === TertiaryButton.Size.LARGE,
        'text-sm lg:text-base': size === TertiaryButton.Size.BASE,
        'text-xs lg:text-sm': size === TertiaryButton.Size.SMALL
      }
    )}
    {...props}
  >
    {children}
  </Button>
);

TertiaryButton.Size = Object.freeze({
  SMALL: 'sm',
  BASE: 'base',
  LARGE: 'lg',
  CUSTOM: 'custom'
});

TertiaryButton.propTypes = {
  size: PropTypes.oneOf(values(TertiaryButton.Size))
};

export default TertiaryButton;
export { TertiaryButton };
