/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useContext, useEffect, useMemo, useState } from 'react';
import { merge } from 'lodash';

import usePriceContextHeader from '../../pricing/usePriceContextHeader';
import usePriceInfoContextHeader from '../../pricing/usePriceInfoContextHeader';
import useRestApi from '../../useRestApi';
import useSearchInfo from '../useSearchInfo';
import { TopBrandContext } from 'app/common/contexts';

/**
 * Hook to use to send a request to the CatalogSearch API.
 *
 * @param {Object} config - Object containing additional request config such as
 *     the request params or request method
 * @param {boolean} sendImmediate - Whether to send the request immediately upon
 *     using the hook. If false, then the caller is responsible for triggering
 *     the `sendCallback`. Defaults to `true`.
 *
 * @return {{error: bool, exception: Object, loading: bool, response: Object, sendCallback: function}}
 *     The fetch response
 */
function useCatalogSearchApi(config, sendImmediate = true) {
  const priceContextHeader = usePriceContextHeader();
  const priceInfoContextHeader = usePriceInfoContextHeader();
  const { filterBrands } = useContext(TopBrandContext);
  const [stop, setStop] = useState(false);

  const { baseUrl, catalogUri } = useSearchInfo();
  const fetchUrl = `${baseUrl}${catalogUri}`;
  const finalConfig = useMemo(
    () => merge({}, priceContextHeader, priceInfoContextHeader, config),
    [config, priceContextHeader, priceInfoContextHeader]
  );

  const searchApiResponse = useRestApi(fetchUrl, finalConfig, sendImmediate);
  const { response, requestStatus } = searchApiResponse;
  useEffect(() => {
    if (stop) {
      return;
    }
    if (requestStatus === 'COMPLETE') {
      filterBrands(response);
      setStop(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, stop, setStop, requestStatus]);
  return searchApiResponse;
}

export default useCatalogSearchApi;
