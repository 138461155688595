/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

/**
 * Render component for a menu link.
 */
const MenuLink = ({
  children,
  className,
  expanded,
  href,
  external = false
}) => {
  const defaultClassName =
    'flex items-center w-full mt-px px-4 font-medium text-gray-700 border-b-2 border-transparent hover:text-gray-900 hover:border-gray-700 focus:outline-none focus:shadow-outline lg:focus:shadow-none lg:focus:bg-gray-100';
  return (
    <div
      className={cx('flex', {
        [className]: !!className
      })}
    >
      {!external ? (
        <NavLink
          to={href}
          className={cx(defaultClassName, { 'py-2': expanded })}
        >
          {children}
        </NavLink>
      ) : (
        <a
          href={href}
          target={'_blank'}
          rel={'noopener noreferrer'}
          className={defaultClassName}
        >
          {children}
        </a>
      )}
    </div>
  );
};

MenuLink.propTypes = {
  /** Content to render within the link */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.element
  ]),
  /** Class names to add to the component */
  className: PropTypes.string,
  /** Destination of link */
  href: PropTypes.string.isRequired,
  /** Whether the menu is an external link */
  external: PropTypes.bool,
  /** Whether the menu is expanded */
  expanded: PropTypes.bool
};

export default MenuLink;
export { MenuLink };
