/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { TenantContext } from 'app/common/contexts';
import React from 'react';
import { get } from 'lodash';

import { Link } from 'app/common/components';
import { useNationalSiteContext, useFormatMessage } from 'app/common/hooks';

import messages from './SiteLogo.messages';
import { useLocation } from 'react-router-dom';

/**
 * Render component for the main site logo.
 *
 * @visibleName Site Logo
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const SiteLogo = ({ widgetProps = {}, ...props }) => {
  const formatMessage = useFormatMessage();
  const { tenant } = React.useContext(TenantContext);
  const {
    resolving,
    useApplicationContent,
    applicationThemeSource: application
  } = useNationalSiteContext();
  const source = useApplicationContent ? application : tenant;
  const { pathname } = useLocation();
  const isHomepage = pathname === '/';

  // try 'source' but fallback to tenant just so there isn't a broken image
  const { contentUrl, altText, title } =
    get(source, 'logoAsset') || get(tenant, 'logoAsset', {});
  // If homepage, use h1 tag for SEO
  return (
    <Link {...props} to="/" title={formatMessage(messages.logoAlt)}>
      {isHomepage ? (
        <h1 className={widgetProps.className}>
          {!resolving && (
            <img
              {...widgetProps.innerProps}
              alt={altText || formatMessage(messages.logoAlt)}
              title={title}
              src={contentUrl}
            />
          )}
        </h1>
      ) : (
        <div className={widgetProps.className}>
          {!resolving && (
            <img
              {...widgetProps.innerProps}
              alt={altText || formatMessage(messages.logoAlt)}
              title={title}
              src={contentUrl}
            />
          )}
        </div>
      )}
    </Link>
  );
};

export default SiteLogo;
export { SiteLogo };
