/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Checkout.Confirmation.title',
    defaultMessage: 'Success! Thank you for your order.'
  },
  receiptCaveat: {
    id: 'Checkout.Confirmation.receiptCaveat',
    defaultMessage:
      "Your receipt will be sent to {emailAddress}. If you don't see it within the next 5 minutes, please check your spam folder. Otherwise, please contact customer service."
  },
  continueShopping: {
    id: 'Checkout.Confirmation.continueShopping',
    defaultMessage: 'Continue Shopping'
  }
});
