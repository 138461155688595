import { useRestApi } from 'app/common/hooks/index.js';
import { logError } from 'app/common/utils/ApiErrorUtils/index.js';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  COMPLETE,
  IN_PROGRESS,
  PENDING
} from 'app/common/hooks/useRestApi/RequestStatus.js';
import useBrandsInfo from './useBrandsInfo.js';

function useFeaturedBrandsApi() {
  const [featuredBrands, setFeaturedBrands] = useState([]);
  const { featuredBrandsPath } = useBrandsInfo();

  const {
    error,
    exception,
    response,
    sendCallback: fetchAllBrands,
    requestStatus
  } = useRestApi(featuredBrandsPath, {}, false);

  useEffect(() => {
    if (requestStatus === IN_PROGRESS || !isEmpty(featuredBrands)) {
      return;
    }

    if (error) {
      logError({ ...exception, when: 'Fetching Featured Brands' });
      return;
    }

    if (requestStatus === COMPLETE) {
      setFeaturedBrands(response);
      return;
    }

    if (requestStatus === PENDING) {
      fetchAllBrands();
    }
  }, [
    error,
    exception,
    response,
    requestStatus,
    featuredBrands,
    setFeaturedBrands,
    fetchAllBrands
  ]);

  return featuredBrands;
}

export default useFeaturedBrandsApi;
