/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { Form, Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';

import { DecoratedField, PrimaryButton } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import { OrderTrackingContext } from '../../contexts';
import messages from '../../OrderTracking.messages';

const GuestForm = ({ history }) => {
  const formatMessage = useFormatMessage();
  const { emailAddress, orderNumber, loading } =
    React.useContext(OrderTrackingContext);
  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        emailAddress: Yup.string()
          .email(formatMessage(messages.guestFormEmailInvalid))
          .required(formatMessage(messages.guestFormEmailRequired)),
        orderNumber: Yup.string().required(
          formatMessage(messages.guestFormOrderNumberRequired)
        )
      }),
    [formatMessage]
  );
  return (
    <Formik
      initialValues={{
        emailAddress: emailAddress || '',
        orderNumber: orderNumber || ''
      }}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        const location = history.location;
        history.push(
          location.pathname.includes(values.orderNumber)
            ? location.pathname
            : `/order-tracking/${values.orderNumber}`,
          {
            ...location.state,
            emailAddress: values.emailAddress,
            orderNumber: values.orderNumber
          }
        );
      }}
      enableReinitialize
      validateOnBlur
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form className="flex flex-col pt-4 px-4 pb-6 lg:pt-2 lg:px-6">
          <DecoratedField
            autoComplete="email"
            hint={formatMessage(messages.guestFormEmailHint)}
            id="email-address"
            label={formatMessage(messages.guestFormEmailLabel)}
            name="emailAddress"
            placeholder={formatMessage(messages.guestFormEmailPlaceholder)}
            required
            type="email"
            disabled={isSubmitting || loading}
          />
          <DecoratedField
            autoComplete="off"
            id="order-number"
            label={formatMessage(messages.guestFormOrderNumberLabel)}
            name="orderNumber"
            required
            disabled={isSubmitting || loading}
          />
          <div className="flex justify-end mt-4">
            {isSubmitting || loading ? (
              <div className="flex text-primary-600 font-medium">
                <div>{formatMessage(messages.loadingMessage)}</div>
                <div className="w-6 has-animated-ellipsis">&nbsp;</div>
              </div>
            ) : (
              <PrimaryButton type="submit" disabled={isSubmitting || loading}>
                {formatMessage(messages.guestFormSubmit)}
              </PrimaryButton>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(GuestForm);
export { GuestForm };
