/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

import useCatalogInfo from '../useCatalogInfo';

/**
 * Hook to use to build category URLs. By default, the category URIs need to
 * have a base context path appended onto them for correct routing, such as
 * `/browse`.
 *
 * @param {string} url - Category URL that may need a prefix
 *
 * @return {string} Completed product URL
 */
function useCategoryUrl(url) {
  const { categoryInfo = {} } = useCatalogInfo();
  const { routeBaseContext = '' } = categoryInfo;
  return routeBaseContext + url;
}

export default useCategoryUrl;
