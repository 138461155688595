/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { filter, get, isEmpty } from 'lodash';

import { ProductOptionType } from 'app/common/constants';
import { ConfigurableItemContext, PdpContext } from 'app/product/contexts';
import { logError } from 'app/common/utils/ApiErrorUtils';
import {
  VariantAttributeChoice,
  ItemAttributeChoice
} from 'app/product/components/options';

import { ItemChoice } from './components';

const ProductOptions = () => {
  const {
    product: { options }
  } = React.useContext(PdpContext);
  const { unusedAttributeNames } = React.useContext(ConfigurableItemContext);
  const optionsToRender = React.useMemo(
    () =>
      filter(
        options,
        option =>
          option.type !== ProductOptionType.VARIANT_DISTINGUISHING ||
          isEmpty(unusedAttributeNames) ||
          !unusedAttributeNames.has(
            get(option, 'attributeChoice.attributeName')
          )
      ),
    [options, unusedAttributeNames]
  );

  if (isEmpty(options)) {
    return null;
  }

  return (
    <section className="w-full mb-4">
      <ol className="flex flex-col w-full list-none">
        {optionsToRender.map((option, i) => {
          switch (option.type) {
            case ProductOptionType.VARIANT_DISTINGUISHING:
              return <VariantAttributeChoice key={i} option={option} />;
            case ProductOptionType.CART_ITEM_ATTRIBUTE:
              return <ItemAttributeChoice key={i} option={option} />;
            case ProductOptionType.ITEM_CHOICE:
              return <ItemChoice key={i} option={option} />;
            default:
              logError({
                when: `rendering unknown ProductOption type ${option.type}`
              });
              return null;
          }
        })}
      </ol>
    </section>
  );
};

export default ProductOptions;
export { ProductOptions };
