/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { head, isEmpty, isNil, omit } from 'lodash';
import { default as qs } from 'query-string';

import { CustomerContext, FleetContext } from 'app/common/contexts';
import { useHistory, useLocation } from 'app/common/hooks';
import { LocalStorageCache } from 'app/common/services';

const FleetProvider = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const { fleets } = React.useContext(CustomerContext);
  const [activeFleet, setActiveFleet] = React.useState(undefined);
  const context = React.useMemo(
    () => ({
      activeFleet,
      setActiveFleet
    }),
    [activeFleet]
  );

  React.useEffect(() => {
    const cachedFleet = FleetCache.get(cacheKey);
    if (isEmpty(activeFleet) && !isEmpty(cachedFleet)) {
      setActiveFleet(cachedFleet);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (isEmpty(fleets) && fleets !== undefined) {
      setActiveFleet(undefined);
      FleetCache.clear();
    }
  }, [fleets]);

  React.useEffect(() => {
    const params = qs.parse(location.search);

    if (!isNil(params.fleetId) && !isEmpty(fleets)) {
      const actFlt = head(fleets.filter(f => f.id === params.fleetId));
      setActiveFleet(actFlt);
      FleetCache.put(cacheKey, actFlt);
      history.replace({
        key: location.key,
        hash: location.hash,
        pathname: location.pathname,
        state: location.state,
        search: qs.stringify(omit(params, ['fleetId']))
      });
    }
  }, [
    fleets,
    history,
    location.key,
    location.hash,
    location.pathname,
    location.state,
    location.search
  ]);

  return (
    <FleetContext.Provider value={context}>{children}</FleetContext.Provider>
  );
};

const FleetCache = new LocalStorageCache('FleetCache', {
  enableLocal: true,
  enableSession: true,
  // 10 minutes
  ttl: 1000 * 60 * 10
});
const cacheKey = `fleet_resolver_${window.location.hostname}`;

export default FleetProvider;
export { FleetProvider };
