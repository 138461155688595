/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
const storageExist = isLocal => {
  const test = 'test';
  try {
    const storage = isLocal ? localStorage : sessionStorage;

    storage.setItem(test, test);
    storage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

const isLocalStorageExist = () => {
  return storageExist(true);
};

const isSessionStorageExist = () => {
  return storageExist(false);
};

const clearLocalStorage = () => {
  if (storageExist(true)) {
    localStorage.clear();
  }
};

const clearSessionStorage = () => {
  if (storageExist(false)) {
    sessionStorage.clear();
  }
};

export {
  clearLocalStorage,
  clearSessionStorage,
  isLocalStorageExist,
  isSessionStorageExist
};
