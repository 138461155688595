import {
  useCatalogInfo,
  usePriceContextHeader,
  usePriceInfoContextHeader,
  useRestApi
} from 'app/common/hooks';
import { useEffect } from 'react';
import { isEmpty, merge } from 'lodash';
import { logError } from 'app/common/utils/ApiErrorUtils';
import * as RequestStatus from 'app/common/hooks/useRestApi/RequestStatus';
import { Environment } from 'app/common/services';

function useProductPricingApi(items) {
  const priceContextHeader = usePriceContextHeader();
  const priceInfoContextHeader = usePriceInfoContextHeader();
  const {
    productInfo: { priceUri, fetchUrl }
  } = useCatalogInfo();
  const productPriceUri = `${fetchUrl}${priceUri}`;

  const {
    exception,
    error,
    response,
    requestStatus,
    sendCallback: fetchPricing
  } = useRestApi(productPriceUri, {}, false);

  const pricingTimeout = getPricingTimeout();

  useEffect(() => {
    if (requestStatus !== RequestStatus.PENDING || isEmpty(items)) {
      return;
    }

    const itemIds = items.map(item => item.id).join(',');

    const config = merge({}, priceContextHeader, priceInfoContextHeader, {
      params: { productIds: itemIds },
      timeout: pricingTimeout
    });

    fetchPricing(config);
  }, [
    items,
    requestStatus,
    fetchPricing,
    priceContextHeader,
    priceInfoContextHeader,
    pricingTimeout
  ]);

  const defaultPrices = {};
  if (error) {
    const defaultPricesArr = items.map(item => ({
      [item.id]: {
        productId: item.id,
        priceInfo: item.priceInfo
      }
    }));

    for (let obj of defaultPricesArr) {
      merge(defaultPrices, obj);
    }

    logError({
      ...exception,
      when: 'fetching prices for product'
    });
  }

  return isEmpty(response) ? defaultPrices : response;
}

function getPricingTimeout() {
  return Environment.get('pricing.fetch.timeout', 6000);
}

export default useProductPricingApi;
