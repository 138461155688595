/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import cx from 'classnames';

const DefaultBadge = props => {
  const { isBilling = false, isShipping = false } = props;
  return (
    <span
      className={cx(
        'inline-block ml-2 first:ml-0 mb-1 px-2 py-1 font-semibold uppercase tracking-wide text-xs rounded',
        {
          'bg-blue-200 text-blue-800': isBilling,
          'bg-green-200 text-green-800': isShipping,
          'bg-gray-200 text-gray-800': !isShipping && !isBilling
        }
      )}
    >
      {props.children}
    </span>
  );
};

export default DefaultBadge;
export { DefaultBadge };
