/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { createContext } from 'react';

/**
 * @typedef {Object} PaymentContext
 * @property {String} [avalaraAccount],
 * @property {Boolean} [taxEnabled=false],
 * @property {Boolean} [paypalEnabled=false],
 * @property {String} [paypalAccount]
 * @property {Boolean} [resolving]
 * @property {Object} [payments]
 * @property {Promise<Object>} [setPayments]
 * @property {Promise<Object>} [refetchPayments]
 * @property {Boolean} [isStale]
 * @property {Promise<Object>} [setIsStale]
 */

/**
 * @type {Readonly<PaymentContext>}
 */
const defaultValue = Object.freeze({
  paypalEnabled: false,
  taxEnabled: false,
  paypalAccount: undefined,
  avalaraAccount: undefined,
  resolving: false,
  payments: undefined,
  setPayments: () => {},
  refetchPayments: () => Promise.resolve(),
  isStale: false,
  setIsStale: () => {}
});

/**
 * @type {React.Context<PaymentContext>}
 */
const PaymentContext = createContext(defaultValue);

PaymentContext.defaultValue = defaultValue;

export default PaymentContext;
export { PaymentContext };
