/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { get, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { AuthContext } from 'app/auth/contexts';
import { OrderReviewRegistration } from 'app/common/components';
import { PaymentContext } from 'app/common/contexts';
import {
  CommunicationPreferenceType,
  FulfillmentType
} from 'app/common/constants';
import { useFormatMessage, useScrollToTop } from 'app/common/hooks';

import OrderReviewHeader from '../../../OrderReviewHeader';
import OrderReviewPayment from '../../../OrderReviewPayment';
import OrderReviewPricing from '../../../OrderReviewPricing';
import OrderReviewProgressBar from '../../../OrderReviewProgressBar';
import OrderReviewFulfillments from '../../../OrderReviewFulfillments';
import { OrderTrackingContext } from '../../contexts';
import messages from './OrderReview.messages';

const OrderReview = () => {
  useScrollToTop([]);

  const formatMessage = useFormatMessage();
  const { isAuthenticated } = React.useContext(AuthContext);
  const { order, orderNumber, emailAddress, loading } =
    React.useContext(OrderTrackingContext);
  const address = get(order, 'address', {});
  const type = get(order, 'fulfillmentType', {});
  const fullName = address.fullName;
  const communicationPreference = get(order, 'attributes[COMMUNICATION_PREF]');
  const dealerName = get(order, 'contextState.application.name');
  const { paypalEnabled } = React.useContext(PaymentContext);
  const orderItems = get(order, 'orderItems');
  const returnItems = get(order, 'returnItems');

  return (
    <main className="container relative flex flex-col flex-grow mx-auto px-2 py-4 md:px-6 lg:flex-row lg:px-8 lg:py-8">
      <div className={classNames('text-gray-900 lg:w-3/5')}>
        <section className="bg-gray-100 rounded shadow-md">
          <OrderReviewHeader
            order={order}
            orderNumber={orderNumber}
            title={messages.title}
          >
            {!isEmpty(emailAddress) && (
              <>
                {loading ? (
                  <div className="w-full bg-gray-200">&nbsp;</div>
                ) : (
                  <p className="text-sm">
                    <FormattedMessage
                      {...messages.receiptCaveat}
                      values={{
                        emailAddress: (
                          <b className="font-bold">{emailAddress}</b>
                        )
                      }}
                    />
                  </p>
                )}
              </>
            )}
            <OrderReviewProgressBar className="mt-8" order={order} />
          </OrderReviewHeader>
          <div id="confirmation-details" className="px-4">
            <section className="relative flex flex-col py-4 border-t-2 border-solid border-gray-200 md:flex-row lg:py-6">
              <p className="capitalize font-bold whitespace-pre-line">
                <FormattedMessage
                  {...messages.dealerLabel}
                  values={{
                    dealerName: (
                      <b className="block mt-1 text-sm font-normal">
                        {dealerName}
                      </b>
                    )
                  }}
                />
              </p>
            </section>
            {type === FulfillmentType.VIRTUAL ? null : (
              <section className="relative flex flex-col pb-4 md:flex-row lg:pb-6">
                <section className="w-full text-sm md:w-1/2 md:pr-1">
                  <h3 className="mb-1 text-base text-gray-900 font-bold">
                    {formatMessage(messages.contactAddressLabel)}
                  </h3>
                  {loading ? (
                    <div className="w-48 mb-1 bg-gray-200">&nbsp;</div>
                  ) : (
                    <div>{fullName}</div>
                  )}
                  {loading ? (
                    <div className="w-48 bg-gray-200">&nbsp;</div>
                  ) : (
                    <div>{address.addressLine1}</div>
                  )}
                  {loading ? (
                    <div className="w-48 bg-gray-200">&nbsp;</div>
                  ) : (
                    <div>{address.phonePrimary.phoneNumber}</div>
                  )}
                </section>
                <section className="w-full text-sm md:w-1/2">
                  <h3 className="mt-2 mb-1 text-base text-gray-900 font-bold md:mt-0">
                    {formatMessage(messages.preferredCommunicationMethod)}
                  </h3>
                  {loading ? (
                    <div className="w-48 bg-gray-200">&nbsp;</div>
                  ) : (
                    <div>
                      {formatMessage(
                        CommunicationPreferenceType[communicationPreference]
                          .label
                      )}
                    </div>
                  )}
                </section>
              </section>
            )}

            <OrderReviewFulfillments
              orderItems={orderItems}
              loading={loading}
            />

            {!isEmpty(returnItems) && (
              <OrderReviewFulfillments
                isReturns
                orderItems={returnItems}
                loading={loading}
              />
            )}
            <section className="relative py-4 border-t-2 border-solid border-gray-200 lg:py-6">
              <header className="mb-4">
                <h3 className="text-base text-gray-900 font-bold lg:text-lg">
                  {formatMessage(messages.totals)}
                </h3>
              </header>
              <section className="flex flex-col">
                {loading ? (
                  <OrderReviewPricing.Skeleton />
                ) : (
                  <OrderReviewPricing order={order} />
                )}
                {paypalEnabled ? (
                  loading ? (
                    <OrderReviewPayment.Skeleton truncateBillingAddress />
                  ) : (
                    <OrderReviewPayment order={order} truncateBillingAddress />
                  )
                ) : null}
              </section>
            </section>
          </div>
        </section>
      </div>
      {!isAuthenticated && (
        <div className="relative pt-4 lg:w-2/5 lg:pt-0 lg:pl-4 print:hidden">
          <OrderReviewRegistration
            emailAddress={emailAddress}
            fullName={fullName}
            loading={loading}
          />
        </div>
      )}
    </main>
  );
};

export default OrderReview;
export { OrderReview };
