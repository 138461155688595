/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';

import { Environment } from '../../../services';

function usePromotionInfo() {
  return useMemo(
    () => ({
      itemMarketingMessage: {
        fetchUrl: Environment.get(
          'PROMOTION_ITEM_MARKETING_MSG_ENDPOINT',
          'offer/retrieve-marketing-messages'
        )
      },
      offer: {
        marketingMessage: {
          fetchContextPath: Environment.get(
            'OFFER_MARKETING_MSG_ENDPOINT',
            'marketing-messages'
          )
        },
        fetchUrl: Environment.get('OFFER_ENDPOINT', 'offer/offers')
      },
      offerAudit: {
        fetchUrl: Environment.get(
          'OFFER_AUDIT_ENDPOINT',
          'offer/offer-audit-details'
        )
      },
      offerEngine: {
        baseUrl: Environment.get('OFFER_ENGINE_ENDPOINT', 'offer/offer-engine'),
        fetchCodeUsabilityUrl: Environment.get(
          'OFFER_ENGINE_CODE_USABILITY_ENDPOINT',
          'offer/offer-engine/code-use'
        ),
        applyUrl: Environment.get(
          'OFFER_ENGINE_APPLY_ENDPOINT',
          'offer/offer-engine/apply'
        )
      }
    }),
    []
  );
}

export default usePromotionInfo;
