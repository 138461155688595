/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { Form, Formik } from 'formik';
import { findIndex, get, isEmpty, map, noop, size } from 'lodash';

import {
  BulkAddToCartButton,
  BulkRemoveListItemButton,
  CheckboxField,
  Field,
  FieldErrors,
  Input,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton
} from 'app/common/components';
import {
  useCartInfo,
  useFormatMessage,
  useRestApi,
  useToggle
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';
import { ListDetailsContext } from 'app/my-account/components/ListDetails/contexts';

import { ListItem } from './components';
import messages from './ListItems.messages';

const ListItems = () => {
  const formatMessage = useFormatMessage();
  const {
    list,
    fetchList,
    listItems,
    setListItems,
    customerRef,
    setAddToCartState
  } = React.useContext(ListDetailsContext);
  const { id, name: listName, attributes } = list || {};
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [selectAll, toggleSelectAll] = useToggle(false);
  const {
    listOperations: { baseUrl }
  } = useCartInfo();
  const config = React.useMemo(
    () => ({ method: 'put', params: customerRef }),
    [customerRef]
  );
  const {
    error,
    exception,
    sendCallback: updateName
  } = useRestApi(`${baseUrl}/${id}`, config, false);
  const [editName, setEditName] = React.useState(false);

  React.useEffect(() => {
    if (selectAll) {
      setSelectedItems([...listItems]);
    } else {
      setSelectedItems([]);
    }
  }, [listItems, selectAll]);

  React.useEffect(() => {
    if (error) {
      logError({
        ...exception,
        when: `editing list ${id}'s name`
      });
    }
  }, [error, exception, id]);

  return (
    <section className="mb-6 bg-gray-100 rounded shadow lg:shadow-md">
      <header className="flex items-baseline p-4 bg-white rounded-t">
        {editName ? (
          <Formik
            initialValues={{ name: listName }}
            onSubmit={async (values, actions) => {
              await updateName({
                data: {
                  ...list,
                  attributes: list.attributes || {},
                  name: values.name
                }
              });

              actions.setSubmitting(false);

              fetchList();
            }}
            validateOnBlur={true}
            validateOnChange={false}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="flex">
                  <Field
                    id="list-name"
                    name="name"
                    size={Input.Size.SMALL}
                    widths="w-full lg:w-48"
                    required
                    validate={value => {
                      if (isEmpty(value)) {
                        return formatMessage(messages.editNameRequired);
                      }
                    }}
                  />
                  <SecondaryButton
                    className="mx-2"
                    size={SecondaryButton.Size.SMALL}
                    onClick={() => {
                      setEditName(false);
                    }}
                    disabled={isSubmitting}
                  >
                    {formatMessage(messages.editNameCancel)}
                  </SecondaryButton>
                  <PrimaryButton
                    type="submit"
                    size={PrimaryButton.Size.SMALL}
                    disabled={isSubmitting}
                  >
                    {formatMessage(messages.editNameSubmit)}
                  </PrimaryButton>
                </div>
                <FieldErrors name="name" />
              </Form>
            )}
          </Formik>
        ) : (
          <>
            <h2 className="text-gray-700 text-xl font-bold capitalize leading-none lg:text-2xl">
              {formatMessage(messages.heading, {
                listName,
                listQuantity: size(listItems) || get(attributes, 'itemCount', 0)
              })}
            </h2>
            <TertiaryButton
              className="ml-2"
              size={TertiaryButton.Size.SMALL}
              onClick={() => {
                setEditName(true);
              }}
            >
              {formatMessage(messages.editName)}
            </TertiaryButton>
          </>
        )}
      </header>
      <section>
        <header className="flex items-center px-4 py-2 bg-white border-t border-b border-gray-400">
          <CheckboxField.Field
            className="basis-1/12"
            checked={selectAll}
            disabled={isEmpty(listItems)}
            value="ALL"
            field={{
              onBlur: noop,
              onChange: () => {
                toggleSelectAll();
              },
              name: 'select-all',
              value: 'ALL'
            }}
          />
          <div className="flex flex-col basis-11/12 flex-grow sm:flex-row sm:basis-full">
            <BulkAddToCartButton
              selectedItems={selectedItems}
              handlePostSubmit={(success, response, selectedItems) => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                if (success) {
                  setAddToCartState({
                    success,
                    ignoredItems: map(
                      response.ignoredAddItemRequests,
                      ({ sku }) => sku
                    ),
                    quantity:
                      selectedItems.length -
                      size(response.ignoredAddItemRequests)
                  });
                  return;
                }

                if (!success) {
                  if (response.type === 'SIMILAR_ITEM_ALREADY_ADDED_ERROR') {
                    setAddToCartState({
                      success: true,
                      ignoredItems: selectedItems.map(
                        ({ itemSkuRef: { sku } }) => sku
                      ),
                      quantity: 0
                    });
                    return;
                  }

                  setAddToCartState({
                    success: false,
                    reason: response.type,
                    quantity: selectedItems.length
                  });
                }
              }}
            />
            <BulkRemoveListItemButton
              list={list}
              selectedItems={selectedItems}
              handlePostSubmit={() => {
                setListItems(
                  listItems.filter(
                    ({ id }) => !~findIndex(selectedItems, ['id', id])
                  )
                );
              }}
            />
          </div>
        </header>
        <section style={{ minHeight: '100px' }}>
          {isEmpty(listItems) || isEmpty(list) ? (
            <div className="flex items-center justify-center w-full mt-4 text-gray-600 text-lg font-medium capitalize leading-none sm:mt-6 sm:text-xl lg:mt-8">
              {formatMessage(messages.empty)}
            </div>
          ) : (
            map(listItems, listItem => {
              return (
                <ListItem
                  key={listItem.id}
                  listItem={listItem}
                  handleChecked={add => {
                    if (add) {
                      setSelectedItems([...selectedItems, listItem]);
                    } else {
                      setSelectedItems(
                        selectedItems.filter(({ id }) => id !== listItem.id)
                      );
                    }
                  }}
                  handlePostRemove={() => {
                    setListItems(
                      listItems.filter(({ id }) => id !== listItem.id)
                    );
                  }}
                  selected={!!~findIndex(selectedItems, ['id', listItem.id])}
                />
              );
            })
          )}
        </section>
      </section>
    </section>
  );
};

export default ListItems;
export { ListItems };
