/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useCallback, useState } from 'react';
import { isBoolean } from 'lodash';

/**
 * Hook used to provide utility for managing toggle state.
 * This hook returns the current state and a function to toggle it.
 * The function to toggle it can take a boolean value which is used to override
 * the value. Thus, if you pass `toggleActive(true)`, then `isActive` will be
 * `true` no matter what its value was before. If you pass nothing, then it will
 * simply toggle `isActive`.
 *
 * @param {boolean} initialValue Initial value to use for the toggle state.
 *     `true` for toggled-on or `false` for toggled off.
 * @return {[boolean, function]} The current state (`isActive`) and the function
 *     to toggle it (`toggleActive(boolean)`)
 */
function useToggle(initialValue = false) {
  const [isActive, setActive] = useState(initialValue);

  const toggleActive = useCallback(
    nextIsActive => {
      if (isBoolean(nextIsActive)) {
        setActive(nextIsActive);
      } else {
        // use the updater function to (1) get the previous value,
        // and (2) not require a dependency on the state variable isActive
        setActive(prevIsActive => !prevIsActive);
      }
    },
    // empty dependencies means we never have to recreate this callback in
    // reaction to state changes!
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isActive, setActive]
  );
  return [isActive, toggleActive];
}

export default useToggle;
