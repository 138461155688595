/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import { Icon } from 'app/common/components';

import { RadioInput } from './components';
import { noop } from 'lodash';

/**
 * Render component for a radio form field, taking the label content via the
 * `children` prop to allow more complex components. Wraps a `<RadioInput>` and
 * adds the label and custom radio icon rather than the native style.
 */
const RadioField = ({
  checked = false,
  children,
  disabled = false,
  field,
  required = false,
  value,
  skipHoverNotAllowed = false
}) => {
  const hoverAllowed = skipHoverNotAllowed || !disabled;
  return (
    <label
      className={classNames('group relative flex items-center mb-2 ', {
        'hover:cursor-not-allowed': !hoverAllowed,
        'hover:cursor-pointer': hoverAllowed
      })}
    >
      <RadioInput
        field={field}
        value={value}
        checked={checked}
        disabled={disabled}
        required={required}
        skipHoverNotAllowed={skipHoverNotAllowed}
      />
      <Icon
        className={classNames('mr-3', {
          'text-gray-600 group-hover:text-gray-700': !checked && !disabled,
          'text-gray-400 ': !checked && disabled,
          'text-primary-600': checked && !disabled,
          'text-primary-200': checked && disabled
        })}
        name={checked ? ['fas', 'dot-circle'] : ['far', 'circle']}
        size="xs"
      />
      <div
        className={classNames({
          'text-gray-600 group-hover:text-gray-700': !checked && !disabled,
          'text-gray-400': !checked && disabled,
          'text-gray-900': checked && !disabled,
          'text-gray-600': checked && disabled
        })}
      >
        {children}
      </div>
    </label>
  );
};

RadioField.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    /**
     * This is the value of the "checked" radio in the group. This may not match
     * the value for this radio because the other radios in the same group have
     * the same name.
     */
    value: PropTypes.string
  }).isRequired,
  required: PropTypes.bool,
  /** The value for this radio. This does not mean that this radio is checked */
  value: PropTypes.string.isRequired
};

/**
 * Helper component that wraps a `<RadioField>` in a formik `<Field>`.
 */
const ConnectedRadioField = ({
  name,
  value,
  onSelect = noop,
  skipHoverNotAllowed,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field }) => {
        const checked = field.value === value;
        const defaultOnChange = field.onChange;
        field.onChange = e => {
          defaultOnChange(e);
          if (e?.target?.value === value) {
            onSelect();
          }
        };
        return (
          <RadioField
            {...props}
            field={field}
            name={name}
            value={value}
            checked={checked}
            skipHoverNotAllowed={skipHoverNotAllowed}
          />
        );
      }}
    </Field>
  );
};

ConnectedRadioField.propTypes = {
  disabled: PropTypes.bool,
  /** The value for this radio. Other radios will have this same name. */
  name: PropTypes.string.isRequired,
  /** The value for this radio. This does not mean that this radio is checked */
  value: PropTypes.string.isRequired,
  /** The function to execute when the radio is selected */
  onSelect: PropTypes.func
};

export default ConnectedRadioField;
export { RadioField, RadioInput };
