/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import PropTypes from 'prop-types';

import { useDeferred } from 'app/common/hooks';

/**
 * Component that can be used to delay rendering of its children until an amount
 * of time has elapsed after mounting.
 *
 * @visibleName Deferred
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const Deferred = ({ children, delay = 1000 }) => {
  const deferred = useDeferred(delay);

  if (deferred) {
    // if we are deferring the render, we render nothing
    return null;
  }

  return children;
};

Deferred.propTypes = {
  /** Child components for which to delay rendering */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func
  ]),
  /** Duration of the delay. Default is 1000ms. */
  delay: PropTypes.number
};

export default Deferred;
export { Deferred };
