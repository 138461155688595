/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useReducer } from 'react';

import { TopBrandContext } from 'app/common/contexts';
import {
  FILTER_TOP_BRANDS,
  initialState,
  SET_ACTIVE_TOP_BRAND,
  SET_ALL_TOP_BRANDS,
  topBrandReducer
} from 'app/core/helpers/TopBrandProvider/topBrandReducer';

const TopBrandProvider = ({ children }) => {
  const [state, dispatch] = useReducer(topBrandReducer, initialState);

  const providerProps = getResponse(state, dispatch);
  return (
    <TopBrandContext.Provider value={{ ...providerProps }}>
      {children}
    </TopBrandContext.Provider>
  );
};

function getResponse(state, dispatch) {
  return {
    activeTopBrand: state.activeTopBrand,
    setActiveTopBrand: activeTopBrand =>
      dispatch(SET_ACTIVE_TOP_BRAND(activeTopBrand)),
    allTopBrands: state.allTopBrands,
    setAllTopBrands: allTopBrands => dispatch(SET_ALL_TOP_BRANDS(allTopBrands)),
    topBrands: state.topBrands,
    filterBrands: searchResponse => dispatch(FILTER_TOP_BRANDS(searchResponse))
  };
}

export default TopBrandProvider;
export { TopBrandProvider };
