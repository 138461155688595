import { CheckboxField, DecoratedField, Label } from 'app/common/components';
import React from 'react';
import messages from './RegisterForm.messages';
import { useFormatMessage } from 'app/common/hooks';
import classNames from 'classnames';

const CompanyField = ({
  disabled = false,
  id,
  label,
  name,
  placeholder,
  required,
  autoComplete,
  formik
}) => {
  const [noCompany, setNoCompany] = React.useState(false);
  const formatMessage = useFormatMessage();
  const [companyName, setCompanyName] = React.useState('');
  return (
    <div className="flex flex-col flex-grow w-full">
      <DecoratedField
        containerWidths="flex-grow w-full sm:w-auto lg:w-auto lg:flex-grow-0"
        containerSpacing={''}
        className={classNames(noCompany ? 'read-only-important' : '')}
        fieldClassName={classNames(
          'p-0',
          'disabled:text-gray-600',
          noCompany ? 'read-only-important' : ''
        )}
        id={id}
        label={label}
        name={name}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        onChange={e => {
          setCompanyName(e.target.value);
          formik.handleChange(e);
          if (noCompany) {
            formik.validateField('companyName');
          }
        }}
        value={companyName}
      />
      <div className={'flex mb-4 pt-0'}>
        <CompanyCheckbox
          disabled={disabled}
          noCompany={noCompany}
          setNoCompany={setNoCompany}
          className={'flex align-bottom pl-0.5'}
          setCompanyName={setCompanyName}
          formik={formik}
        />
        <Label
          field={{
            label: formatMessage(messages.noCompany),
            name: 'companyCheckbox',
            disabled: disabled
          }}
          textDefaultColor={'text-gray-600'}
          textSizes={'text-xs'}
          className={'flex align-center pt-2.5'}
        />
      </div>
    </div>
  );
};

const CompanyCheckbox = ({
  disabled = false,
  noCompany,
  setNoCompany,
  className,
  setCompanyName,
  formik
}) => {
  return (
    <CheckboxField
      name={'companyCheckbox'}
      value={noCompany ? 'true' : 'false'}
      onClick={() => {
        const companyName = !noCompany ? 'N/A' : '';
        const noCompanyChecked = !noCompany;
        setCompanyName(companyName);
        setNoCompany(noCompanyChecked);
        formik.setFieldValue('companyName', companyName);
        if (noCompanyChecked) {
          formik.setFieldTouched('companyName', false);
        }
      }}
      disabled={disabled}
      className={className}
      checkboxClassName="gray-200"
      checkboxSize="lg"
    />
  );
};

export default CompanyField;
