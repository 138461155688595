/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { get, isEmpty, size } from 'lodash';

import {
  DecoratedField,
  Modal,
  PrimaryButton,
  Prompt,
  SecondaryButton,
  TextAreaField
} from 'app/common/components';
import {
  useCartInfo,
  useFormatMessage,
  useFormatNumber,
  useModifyCartRequest
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import { CartContext, LocaleContext } from 'app/common/contexts';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import messages from '../../Cart.messages';

const CartOrderInstructions = () => {
  const { currentLocale } = React.useContext(LocaleContext);
  const formatMessage = useFormatMessage();
  const { cart, resolving, setCart } = React.useContext(CartContext);
  const orderInstructions = get(cart, 'attributes[ORDER_INSTRUCTIONS]');
  const {
    operations: { attributesContextPath, baseUrl }
  } = useCartInfo();
  const config = React.useMemo(
    () => ({ method: 'post', params: { price: false } }),
    []
  );
  const {
    error,
    exception,
    sendCallback: updateAttributes
  } = useModifyCartRequest(`${baseUrl}${attributesContextPath}`, config, false);
  const [blockNavigation, setBlockNavigation] = React.useState(false);
  const [showSavedConfirm, setShowSaveConfirm] = React.useState(false);
  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        orderInstructions: Yup.string()
          .max(1000, formatMessage(messages.orderInstructionsLengthInvalid))
          .nullable()
      }),
    //eslint-disable-next-line
    [currentLocale, formatMessage]
  );
  const formatNumber = useFormatNumber();

  if (error) {
    logError({
      ...exception,
      when: 'adding order instructions from cart'
    });
  }

  return (
    <aside className="mt-6 bg-gray-100 rounded shadow lg:shadow-md">
      <Prompt
        when={blockNavigation}
        render={({ cancel, confirm }) => (
          <Modal isOpen onClose={cancel} size={Modal.Size.MEDIUM}>
            <Modal.Header>
              <Modal.Header.Title>
                <span className="lg:text-xl font-medium">
                  {formatMessage(messages.orderInstructionsUnsavedWarningTitle)}
                </span>
              </Modal.Header.Title>
              <Modal.Close onClose={cancel} />
            </Modal.Header>
            <Modal.Body className="bg-gray-100">
              <p className="whitespace-pre-line">
                {formatMessage(messages.orderInstructionsUnsavedWarning)}
              </p>
            </Modal.Body>
            <Modal.Footer className="flex items-center lg:justify-between">
              <PrimaryButton className="mr-4" onClick={confirm}>
                {formatMessage(messages.orderInstructionsUnsavedWarningConfirm)}
              </PrimaryButton>
              <SecondaryButton onClick={cancel}>
                {formatMessage(messages.orderInstructionsUnsavedWarningCancel)}
              </SecondaryButton>
            </Modal.Footer>
          </Modal>
        )}
      />
      <Formik
        initialValues={{ orderInstructions: orderInstructions || '' }}
        onSubmit={async (values, actions) => {
          if (resolving) {
            return;
          }

          try {
            const response = await updateAttributes({
              data: {
                key: 'ORDER_INSTRUCTIONS',
                value: values.orderInstructions
              }
            });

            if (!isEmpty(response)) {
              setCart(response);
            }

            setBlockNavigation(false);
            setShowSaveConfirm(true);

            setTimeout(() => {
              setShowSaveConfirm(false);
            }, 3000);
          } finally {
            actions.setSubmitting(false);
          }
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ isSubmitting, values }) => (
          <Form className="flex flex-col">
            <fieldset
              className="flex flex-col"
              disabled={isSubmitting || resolving}
            >
              <legend className="w-full p-4 bg-white rounded-t">
                <h3 className="w-full text-gray-700 text-xl font-bold capitalize leading-none lg:text-2xl">
                  {formatMessage(messages.orderInstructionsLegend)}
                </h3>
              </legend>
              <DecoratedField
                className="p-4"
                containerWidths="w-full"
                id="order-instructions"
                name="orderInstructions"
                component={TextAreaField}
                label={formatMessage(messages.orderInstructionsLegend)}
                labelProps={{ className: 'sr-only' }}
                renderHint={() => (
                  <div className="flex items-start justify-between p-2 leading-snug">
                    <em className="block flex-grow text-xs text-gray-600 not-italic sm:text-sm">
                      <FormattedMessage
                        {...messages.orderInstructionsHint}
                        values={{
                          preamble: (
                            <b>
                              {formatMessage(
                                messages.orderInstructionsHintQuestion
                              )}
                            </b>
                          )
                        }}
                      />{' '}
                      <span className="italic">
                        {formatMessage(
                          messages.orderInstructionsRequirementsHint
                        )}
                      </span>
                    </em>
                    <div
                      className={classNames(
                        'flex flex-no-wrap items-center justify-end pl-1 text-xs font-bold sm:text-sm',
                        {
                          'text-green-700':
                            size(get(values, 'orderInstructions')) < 1000,
                          'text-yellow-600':
                            size(get(values, 'orderInstructions')) === 1000,
                          'text-red-700':
                            size(get(values, 'orderInstructions')) > 1000
                        }
                      )}
                    >
                      <div>
                        {formatNumber(size(get(values, 'orderInstructions')))}
                      </div>
                      <div className="mx-1">{'/'}</div>
                      <div>{formatNumber(1000)}</div>
                    </div>
                  </div>
                )}
                disabled={isSubmitting || resolving}
                autoComplete="off"
                widths="w-full h-24 lg:h-48"
                handleChange={e => {
                  setBlockNavigation(e.target.value.length > 0);
                }}
              />
            </fieldset>
            <div className="flex items-center justify-end p-4">
              {showSavedConfirm && (
                <span className="mr-4 text-green-600 font-bold">
                  {formatMessage(messages.orderInstructionsSavedMessage)}
                </span>
              )}
              <SecondaryButton
                type="submit"
                disabled={isSubmitting || resolving}
              >
                {formatMessage(messages.orderInstructionsUpdate)}
              </SecondaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </aside>
  );
};

export default CartOrderInstructions;
export { CartOrderInstructions };
