/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { chunk, get, isEmpty, isNil, join, map } from 'lodash';
import TagManager from 'app/common/tagmanager';

import { SecondaryButton } from 'app/common/components';
import { CartContext } from 'app/common/contexts';
import {
  useCartInfo,
  useFormatMessage,
  useModifyCartRequest
} from 'app/common/hooks';
import { Environment } from 'app/common/services';
import { logError } from 'app/common/utils/ApiErrorUtils';

import messages from './BulkRemoveButton.messages';

const BulkRemoveCartItemButton = ({ disabled = false, selectedItems }) => {
  const formatMessage = useFormatMessage();
  const { cart, setCart, guestToken } = React.useContext(CartContext);
  const {
    operations: { baseUrl, bulkDeleteContextPath }
  } = useCartInfo();
  const cartItemIds = React.useMemo(
    () => map(selectedItems, ({ id }) => id),
    [selectedItems]
  );
  const cartId = get(cart, 'id', undefined);
  const cartVersionHeaderName = Environment.get(
    'cart.version.header',
    'X-Cart-Version'
  );
  const cartGuestTokenHeaderName = Environment.get(
    'cart.token.header',
    'X-Guest-Token'
  );

  const config = React.useMemo(
    () => ({
      method: 'post',
      params: { price: true },
      data: cartItemIds,
      headers: !isNil(guestToken)
        ? {
            [cartVersionHeaderName]: get(cart, 'version', 0),
            [cartGuestTokenHeaderName]: guestToken.tokenString
          }
        : {
            [cartVersionHeaderName]: get(cart, 'version', 0)
          }
    }),
    [
      cartItemIds,
      guestToken,
      cartVersionHeaderName,
      cartGuestTokenHeaderName,
      cart
    ]
  );
  const {
    error,
    exception,
    sendCallback: removeItems
  } = useModifyCartRequest(
    `${baseUrl}/${cartId}${bulkDeleteContextPath}`,
    config,
    false
  );

  React.useEffect(() => {
    if (error) {
      logError({
        ...exception,
        when: 'removing items from cart'
      });
    }
  }, [error, exception]);

  return (
    <SecondaryButton
      disabled={disabled || isEmpty(cartItemIds)}
      onClick={async () => {
        if (disabled || isEmpty(cartItemIds)) {
          return;
        }

        const response = await removeItems();
        const batches = chunk(
          selectedItems,
          Environment.get('GTM_BULK_CART_OP_BATCH_SIZE', 50)
        );
        batches.forEach(items => {
          TagManager.dataLayer({
            event: 'removeFromCart',
            ecommerce: {
              currencyCode: get(cart, 'cartPricing.currency', 'USD'),
              remove: {
                products: items.map(item => {
                  const {
                    quantity,
                    sku,
                    unitPrice,
                    attributes: { categoryNames, description },
                    position
                  } = item;

                  return {
                    category: join(categoryNames, ', '),
                    id: sku,
                    name: description,
                    position,
                    price: get(unitPrice, 'amount', 0.0),
                    quantity
                  };
                })
              }
            }
          });
        });

        if (!isEmpty(response)) {
          setCart(response);
        }
      }}
      backgroundColor="bg-white hover:bg-red-100 focus:bg-red-100 active:bg-red-100 disabled:bg-white"
      borderColor="border-red-300 disabled:border-gray-200"
      textColor="text-red-700 hover:text-red-800 focus:text-red-800 disabled:text-gray-400"
      size={SecondaryButton.Size.SMALL}
    >
      {formatMessage(messages.label)}
    </SecondaryButton>
  );
};

export default BulkRemoveCartItemButton;
export { BulkRemoveCartItemButton };
