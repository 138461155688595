import { useFormatMessage } from '../../common/hooks';
import messages from '../StoreLocator.messages';

/**
 * Renders the "No Nearby Stores Found" message.
 * @param dropdownProps : StoreDropdownState
 * @returns {React.JSX.Element|null}
 * @constructor
 */
const NoLocationsFound = ({ state }) => {
  const { stores, isLoadingLocations } = state;
  const count = stores ? stores.length : undefined;
  const formatMessage = useFormatMessage();
  if (count === 0 && !isLoadingLocations) {
    return (
      <div className={'flex flex-row justify-center pt-4'}>
        <div className={'flex flex-col text-center'}>
          <div className={'flex text-gray-600 h-12 pt-2'}>
            {formatMessage(messages.noNearbyStoresFound)}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export { NoLocationsFound };
