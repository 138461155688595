/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from 'app/common/components';

/**
 * Layout element encapsulating the shared features of each checkout stage's
 * heading.
 *
 * @visibleName Checkout: Layout: Stage Heading
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const StageHeader = ({
  children,
  active = false,
  completed = false,
  number = 1
}) => {
  return (
    <header
      className={classNames(
        'px-4 py-2 bg-white rounded-t lg:w-1/3 lg:rounded-t-0 lg:rounded-l',
        {
          'md:py-4 lg:py-6': !completed || active,
          'lg:py-4': completed && !active
        }
      )}
    >
      <h2 className="flex items-center text-xl text-gray-700 font-bold lg:text-2xl">
        <StageHeader.CircleWithContent className="mr-2 border-gray-700">
          {number}
        </StageHeader.CircleWithContent>
        <span className="block capitalize">{children}</span>
      </h2>
      {completed && !active && (
        <StageHeader.CompletedIcon active={active} completed={completed} />
      )}
    </header>
  );
};

StageHeader.CompletedIcon = ({ completed, active }) => (
  <StageHeader.CircleWithContent
    className={classNames(
      'absolute text-white bg-primary-600 border-primary-600',
      {
        'top-2 right-4 lg:top-6 lg:right-6': !completed || active,
        'top-2 right-4 lg:top-4': completed && !active
      }
    )}
  >
    <Icon name="check" />
  </StageHeader.CircleWithContent>
);

StageHeader.CircleWithContent = ({ className, children }) => (
  <div
    className={classNames(
      'inline-flex flex-grow-0 flex-shrink-0 items-center justify-center',
      'w-8 h-8',
      'text-xl',
      'border-2 border-solid rounded-full',
      {
        [className]: !!className
      }
    )}
  >
    {children}
  </div>
);

StageHeader.propTypes = {
  /** Child components */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func
  ]),
  /** The stage's number (order) */
  number: PropTypes.number
};

export default StageHeader;
export { StageHeader };
