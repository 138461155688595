/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import { TenantContext } from 'app/common/contexts';
import { RouteStatus } from 'app/core/components';

import { useTenantState } from './hooks';

/**
 * Gateway used to resolve the current tenant and application information before
 * rendering the rest of the app.
 *
 * @author ncrum
 */
export const TenantGateway = ({ children }) => {
  const state = useTenantState();

  if (state.error) {
    return <RouteStatus.NotFound />;
  }

  return (
    <TenantContext.Provider value={state}>{children}</TenantContext.Provider>
  );
};

export default TenantGateway;
