/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { isEmpty } from 'lodash';

import { useFormatMessage } from 'app/common/hooks';

import messages from './AddressUtils.messages';

const PhoneRegexType = Object.freeze({
  ALLOWED_CHARS: /^[\d-+() .]*$/,
  ALLOWED_FORMATS: [
    /^\+?\d{11}$/, // +11001001000 or 11001001000
    /^\d{12}$/, // 521001001000
    /^\d{3}[- .]?\d{3}[- .]?\d{4}$/ //100-100-1000, 1001001000, 100.100.1000, 100 100 1000
  ],
  NORMALIZING_RULES: [
    { regex: /\+?52/, replacement: '52' }, // the phone number would be too long if + was left
    { regex: /[-() .]/g, replacement: '' } // remove all non-numerics or pluses
  ],
  NORMALIZE_PLUS: /\+/g, // remove all non-initial pluses,
  NORMALIZE_DASHES: /^(?=\d{10})(\d{3})(\d{3})(\d{4})$/g // find and split numeric only phone number
});

function useValidatePhone() {
  const formatMessage = useFormatMessage();

  return validatePhone(formatMessage);
}

function validatePhone(formatMessage) {
  return (phoneNumber, fieldName, isRequired = false) => {
    if (isRequired && isEmpty(phoneNumber)) {
      return formatMessage(messages.phoneRequired, { fieldName });
    }

    if (isEmpty(phoneNumber)) {
      return;
    }

    if (!PhoneRegexType.ALLOWED_CHARS.test(phoneNumber)) {
      return formatMessage(messages.phoneComposition, { fieldName });
    }

    const normalizedPhone = normalizePhoneNumber(phoneNumber);

    let anyMatch = false;

    for (const regex of PhoneRegexType.ALLOWED_FORMATS) {
      if (anyMatch) {
        break;
      }

      anyMatch = anyMatch || regex.test(normalizedPhone);
    }

    if (!anyMatch) {
      return formatMessage(messages.phoneFormat, { fieldName });
    }
  };
}

function normalizePhoneNumber(phoneNumber) {
  if (isEmpty(phoneNumber)) {
    return phoneNumber;
  }

  for (const rule of PhoneRegexType.NORMALIZING_RULES) {
    phoneNumber = phoneNumber.replace(rule.regex, rule.replacement);
  }

  phoneNumber =
    phoneNumber.charAt(0) +
    phoneNumber
      .substring(1, phoneNumber.length)
      .replace(PhoneRegexType.NORMALIZE_PLUS, '');

  return phoneNumber;
}

function normalizePhoneNumberWithDashes(phoneNumber) {
  phoneNumber = normalizePhoneNumber(phoneNumber);

  phoneNumber = phoneNumber.replace(
    PhoneRegexType.NORMALIZE_DASHES,
    '$1-$2-$3'
  );

  return phoneNumber;
}

export {
  normalizePhoneNumber,
  normalizePhoneNumberWithDashes,
  useValidatePhone,
  validatePhone
};
