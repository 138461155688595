const initialState = {
  year: undefined,
  days: undefined,
  applicationId: undefined,
  previousAppId: 'NONE',
  lastRequest: {},
  ordersPage: { loading: true },
  orderHistoricalInfo: { loading: true },
  page: 0
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_YEAR':
      return { ...state, year: payload };
    case 'SET_DAYS':
      return { ...state, days: payload };
    case 'SET_APPLICATION_ID':
      return { ...state, applicationId: payload };
    case 'SET_PREVIOUS_APP_ID':
      return { ...state, previousAppId: payload };
    case 'SET_LAST_REQUEST':
      return { ...state, lastRequest: payload };
    case 'SET_ORDERS_PAGE':
      return { ...state, ordersPage: payload };
    case 'SET_ORDER_HISTORICAL_INFO':
      return { ...state, orderHistoricalInfo: payload };
    case 'SET_PAGE':
      return { ...state, page: payload };
    case 'SET_STATE':
      return { ...state, ...payload };
    default:
      return state;
  }
};

const setYear = year => ({
  type: 'SET_YEAR',
  payload: year
});

const setDays = days => ({
  type: 'SET_DAYS',
  payload: days
});

const setApplicationId = applicationId => ({
  type: 'SET_APPLICATION_ID',
  payload: applicationId
});

const setPreviousAppId = previousAppId => ({
  type: 'SET_PREVIOUS_APP_ID',
  payload: previousAppId
});

const setLastRequest = lastRequest => ({
  type: 'SET_LAST_REQUEST',
  payload: lastRequest
});

const setOrdersPage = ordersPage => ({
  type: 'SET_ORDERS_PAGE',
  payload: ordersPage
});

const setOrderHistoricalInfo = orderHistoricalInfo => ({
  type: 'SET_ORDER_HISTORICAL_INFO',
  payload: orderHistoricalInfo
});

export {
  initialState as orderHistoryState,
  reducer as orderHistoryReducer,
  setYear,
  setDays,
  setApplicationId,
  setPreviousAppId,
  setLastRequest,
  setOrdersPage,
  setOrderHistoricalInfo
};
