/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import {
  CommunicationPreferenceType,
  FulfillmentType
} from 'app/common/constants';
import { FormattedMessage } from 'react-intl';

import messages from './FulfillmentSummary.messages';
import { getPaymentGatewayType } from 'app/checkout/utils/PaymentMethodUtil';
import {
  FulfillmentAddressReadOnly,
  FulfillmentInfoReadOnlyWithOptionsMisc
} from 'app/checkout/components/CheckoutLayout/components';

/**
 * Render components for summarizing fulfillment info for an order's
 * confirmation page.
 */
const FulfillmentSummary = ({ loading, order, addressPath, typePath }) => {
  const orderAddressPath = addressPath ? addressPath : 'address';
  const orderTypePath = typePath ? typePath : 'fulfillmentType';
  const address = get(order, orderAddressPath);
  const type = get(order, orderTypePath);
  const orderInstructions = get(order, 'attributes[ORDER_INSTRUCTIONS]');
  const communicationPreference = get(
    order,
    'attributes[COMMUNICATION_PREF]',
    CommunicationPreferenceType.Phone.value
  );
  const paymentGatewayType = getPaymentGatewayType(order);
  const dealerName = getDealerName(order);
  const purchaseOrderNumber = get(order, 'purchaseOrderNumber');
  const serviceLevel = get(
    order,
    'fulfillmentGroups[0].pricedFulfillmentOption.serviceLevel'
  );
  if (loading) {
    return <FulfillmentSummary.Skeleton />;
  }

  return (
    <>
      <section className="relative flex flex-col py-4 border-t-2 border-solid border-gray-200 md:flex-row lg:py-6">
        <p className="capitalize font-bold whitespace-pre-line">
          <FormattedMessage
            {...messages.dealerLabel}
            values={{
              dealerName: (
                <b className="block mt-1 text-sm font-normal">{dealerName}</b>
              )
            }}
          />
        </p>
      </section>
      {type !== FulfillmentType.VIRTUAL && (
        <section className="relative flex flex-col pb-4 md:flex-row lg:pb-6">
          <FulfillmentAddressReadOnly
            address={address}
            className="w-full text-sm md:w-1/2 md:pr-1"
            isPickup={type === FulfillmentType.PICKUP}
            messages={messages}
          />
          <FulfillmentInfoReadOnlyWithOptionsMisc
            communicationPreference={communicationPreference}
            orderInstructions={orderInstructions}
            messages={messages}
            isPickup={type === FulfillmentType.PICKUP}
            purchaseOrderNumber={purchaseOrderNumber}
            paymentGatewayType={paymentGatewayType}
            serviceLevel={serviceLevel}
          />
        </section>
      )}
    </>
  );
};

FulfillmentSummary.propTypes = {
  order: PropTypes.object.isRequired
};

FulfillmentSummary.Skeleton = () => (
  <section className="relative flex flex-col py-4 border-t-2 border-solid border-gray-200 md:flex-row lg:py-6">
    <section className="w-full text-sm md:w-1/2 md:pr-1">
      <h3 className="w-32 mt-2 mb-1 text-base bg-gray-200 font-bold md:mt-0">
        &nbsp;
      </h3>
      <div className="w-20 mb-px bg-gray-200">&nbsp;</div>
      <div className="w-24 mb-px bg-gray-200">&nbsp;</div>
      <div className="w-24 mb-px bg-gray-200">&nbsp;</div>
      <div className="w-12 mb-px bg-gray-200">&nbsp;</div>
      <div className="w-24 mb-px bg-gray-200">&nbsp;</div>
    </section>
    <section className="w-full text-sm md:w-1/2">
      <h3 className="w-32 mt-2 mb-1 text-base bg-gray-200 font-bold md:mt-0">
        &nbsp;
      </h3>
      <div className="w-48 mb-1 bg-gray-200">&nbsp;</div>
    </section>
  </section>
);

function getDealerName(order) {
  const defaultVal = get(order, 'contextState.application.name');
  return get(order, 'appDisplayName', defaultVal);
}

export default FulfillmentSummary;
export { FulfillmentSummary };
