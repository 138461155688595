/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useCallback, useState, useMemo } from 'react';
import classNames from 'classnames';
import { filter, get, isEmpty, isNil } from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import {
  useDebouncedCallback,
  useOnEventOutside,
  useRefreshEffect,
  useTypeAheadSuggestionApi
} from 'app/common/hooks';

import {
  CategorySuggestions,
  ProductSuggestions,
  TextSuggestions
} from './components';

/**
 * Shows the typeahead suggestions in the header-nav search-box.
 */
const TypeAheadSuggestions = props => {
  const { containerRef: parentContainerRef, location, query } = props;
  const [data, setData] = useState(undefined);
  const categorySuggestions = useMemo(
    () =>
      filter(
        get(data, 'suggestions.categories', []),
        category => !isNil(category.categoryNames)
      ),
    [data]
  );
  const products = get(data, 'suggestions.products');
  const suggestions = get(data, 'keyWords');
  const handleClickOutside = useCallback(
    e => {
      if (
        !!parentContainerRef.current &&
        !parentContainerRef.current.contains(e.target)
      ) {
        // click is outside the nav
        setData(undefined);
      }
    },
    [parentContainerRef]
  );
  const containerRef = useOnEventOutside(handleClickOutside);
  const { sendCallback: sendTypeAheadRequest } = useTypeAheadSuggestionApi();
  const getResults = useDebouncedCallback(async () => {
    const response = await sendTypeAheadRequest({ params: { query } });
    setData(response);
  }, 300);

  // send query
  useRefreshEffect(
    () => {
      if (isEmpty(query) || query.length < 2) {
        setData(undefined);
        return;
      }

      getResults();
    },
    { getResults, query }
  );

  useRefreshEffect(
    () => {
      setData(undefined);
    },
    location.pathname,
    undefined,
    false
  );

  if (
    isEmpty(suggestions) &&
    isEmpty(products) &&
    isEmpty(categorySuggestions)
  ) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      className={classNames(
        'overflow-auto',
        'absolute top-full left-auto left-0 z-50',
        'flex flex-col-reverse mt-px px-4 pb-8',
        'text-gray-700 bg-white rounded shadow',
        'lg:right-0 lg:left-auto lg:flex-row lg:pt-4'
      )}
      style={{ maxHeight: '70vh' }}
    >
      {!isEmpty(products) && <ProductSuggestions suggestions={products} />}
      {!isEmpty(categorySuggestions) && (
        <CategorySuggestions query={query} suggestions={categorySuggestions} />
      )}
      {!isEmpty(suggestions) && <TextSuggestions suggestions={suggestions} />}
    </div>
  );
};

TypeAheadSuggestions.propTypes = {
  /** the reference to the parent search input container */
  containerRef: PropTypes.object.isRequired,
  /** representation of the current browser location */
  location: PropTypes.object.isRequired,
  /** Searched query */
  query: PropTypes.string
};

export default withRouter(TypeAheadSuggestions);
export { TypeAheadSuggestions };
