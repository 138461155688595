import { PrimaryButton } from 'app/common/components';
import React from 'react';
import { SiteContext } from 'app/common/contexts';

const ChooseLocationButton = ({ label }) => {
  const { setOpenStoreDropdown } = React.useContext(SiteContext);

  return (
    <PrimaryButton
      size={PrimaryButton.Size.SMALL}
      onClick={() => {
        setOpenStoreDropdown(true);
      }}
      className={'mt-2'}
    >
      {label}
    </PrimaryButton>
  );
};

export { ChooseLocationButton };
