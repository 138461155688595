/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { CoreCost, Currency, Icon } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from './CheckoutItemPricing.messages';

const ZERO = { amount: 0.0 };

/**
 * Render component for cart item's pricing information used on the checkout screen
 */
const CheckoutItemPricing = ({
  adjustmentsTotal = ZERO,
  quantity,
  total,
  unitPrice,
  isSale = false,
  originalPrice = ZERO,
  coreCost
}) => {
  const formatMessage = useFormatMessage();
  const hasAdjustments = get(adjustmentsTotal, 'amount', 0) > 0;

  return (
    <section className="flex flex-col items-end justify-end w-full">
      <div className="flex flex-no-wrap items-center justify-end w-full">
        <div className="relative">
          <Currency
            className={classNames('unit-price font-medium text-base', {
              'text-red-600': isSale
            })}
            {...unitPrice}
          />
          {isSale && (
            <Currency
              className="ml-1 text-gray-500 text-sm line-through sm:absolute sm:top-5 sm:left-0 sm:ml-0"
              {...originalPrice}
              currency={unitPrice.currency}
            />
          )}
        </div>
        <div className="inline-flex items-center">
          <Icon className="mx-2 text-center" name="times" />
        </div>
        <span>{quantity}</span>
        <div className="ml-2 text-center inline-flex items-center">:</div>
        <Currency className="final-price ml-2 font-bold" {...total} />
      </div>
      <CoreCost
        className="ml-1 mt-2 text-gray-600"
        coreCost={coreCost}
        message={messages.coreCost}
      />
      {hasAdjustments && (
        <div className="flex items-baseline justify-between mt-2 text-red-600 sm:text-sm">
          <em
            className="flex-1 font-normal text-right not-italic"
            style={{ flex: '0 1 60%' }}
          >
            {formatMessage(messages.adjustmentsAmountLabel)}
          </em>
          <Currency
            className="adjustment-price flex-shrink ml-2 font-bold"
            style={{ flex: '0 1 40%' }}
            currency={unitPrice.currency}
            amount={-adjustmentsTotal.amount}
          />
        </div>
      )}
    </section>
  );
};

CheckoutItemPricing.propTypes = {
  /** total adjustments to apply against the subtotal */
  adjustmentsTotal: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired
  }),
  /** quantity of this item in the cart */
  quantity: PropTypes.number.isRequired,
  /** `(unitTotal x quantity) + adjustments` */
  total: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired
  }).isRequired,
  /** price per unit of the item */
  unitPrice: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired
  })
};

export default CheckoutItemPricing;
export { CheckoutItemPricing };
