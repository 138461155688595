import React, { useEffect } from 'react';
import { useRestApi, useToggle } from 'app/common/hooks';
import {
  COMPLETE,
  IN_PROGRESS,
  PENDING
} from 'app/common/hooks/useRestApi/RequestStatus.js';
import { logError } from 'app/common/utils/ApiErrorUtils';
import { TopBrandContext } from 'app/common/contexts';
import useBrandsInfo from './useBrandsInfo.js';

function useSingleBrandApi(isBrandsPath, brandUrl) {
  const { setActiveTopBrand } = React.useContext(TopBrandContext);
  const [fetching, toggleFetching] = useToggle(false);
  const { singleBrandPath } = useBrandsInfo();

  const url = singleBrandPath + brandUrl;
  const {
    error,
    exception,
    response,
    sendCallback: fetchSingleBrand,
    requestStatus
  } = useRestApi(url, {}, false);

  useEffect(() => {
    if (!isBrandsPath || !brandUrl) {
      return;
    }

    if (requestStatus === IN_PROGRESS) {
      return;
    }

    if (error) {
      logError({
        ...exception,
        when: `Fetching single brand from brand URL: ${brandUrl}`
      });
      return;
    }

    if (requestStatus === COMPLETE && fetching) {
      toggleFetching(false);
      if (response) {
        setActiveTopBrand(response);
      } else if (response === '') {
        setActiveTopBrand(null);
      }
    }

    if (requestStatus === PENDING) {
      toggleFetching(true);
      fetchSingleBrand();
    }
  }, [
    isBrandsPath,
    brandUrl,
    requestStatus,
    error,
    exception,
    response,
    fetchSingleBrand,
    fetching,
    toggleFetching,
    setActiveTopBrand
  ]);

  return { error };
}

export default useSingleBrandApi;
