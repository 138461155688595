/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';

import { Environment } from '../../../services';

function useCampaignInfo() {
  return useMemo(
    () => ({
      baseUrl: Environment.get('CAMPAIGN_BASE_ENDPOINT', '/campaign/campaigns'),
      jsUrl: Environment.get('CAMPAIGN_JS_ENDPOINT', '/api/campaign/'),
      findByUriPath: Environment.get(
        'CAMPAIGN_FIND_BY_URI_ENDPOINT',
        '/campaign/campaigns/uri'
      )
    }),
    []
  );
}

export default useCampaignInfo;
