/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useState } from 'react';
import axios from 'axios';
import { isEmpty, isNil } from 'lodash';

import { AuthContext } from 'app/auth/contexts';
import {
  useCartInfo,
  useCurrentApplication,
  useEventCallback,
  useNationalSiteContext,
  useRefreshEffect,
  useRestApi
} from 'app/common/hooks';
import { Environment, LocalStorageCache } from 'app/common/services';
import { CurrencyContext } from 'app/common/contexts';

export const CartCache = new LocalStorageCache('CartCache', {
  enableLocal: true,
  enableSession: true,
  ttl: 0
});

function useResolveCart() {
  const { resolving: resolvingTenant } = useNationalSiteContext();
  const application = useCurrentApplication();
  const { isAuthenticated, isAuthenticating, didAuthenticationCheck } =
    React.useContext(AuthContext);
  const { baseUrl, resolveCartPath, transferCartToDealerLocationContextPath } =
    useCartInfo().operations;

  const [resolvedCart, setCart] = useState(null);
  const [resolvedGuestToken, setGuestToken] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);
  const { sendCallback } = useRestApi('', null, false, true);
  const { currentCurrency } = React.useContext(CurrencyContext);

  const resolveCart = useEventCallback(async () => {
    if (
      (isAuthenticating || !didAuthenticationCheck) &&
      !isEmpty(application)
    ) {
      return;
    }

    let cart = null;
    try {
      setIsFetching(true);

      // grab the current cart id from local storage
      const cachedCartId = CartCache.get('cartId');
      const cachedCartVersion = CartCache.get('cartVersion');
      const cachedIsGuest = CartCache.get('isGuest');
      const cachedGuestToken = getValidGuestTokenFromCache(
        cachedCartId,
        cachedIsGuest
      );

      // if we have a cart id, try to get that cart
      if (!!cachedCartId && !isAuthenticating) {
        // if we're authenticated and the current cart is a guest cart, transfer it
        if (isAuthenticated && cachedIsGuest) {
          const cartVersionHeaderName = Environment.get(
            'cart.version.header',
            'X-Cart-Version'
          );
          const cartGuestTokenHeaderName = Environment.get(
            'cart.token.header',
            'X-Guest-Token'
          );
          const config = {
            method: 'post',
            headers: !isNil(cachedGuestToken)
              ? {
                  [cartVersionHeaderName]: cachedCartVersion,
                  [cartGuestTokenHeaderName]: cachedGuestToken.tokenString
                }
              : {
                  [cartVersionHeaderName]: cachedCartVersion
                },
            params: {
              currency: currentCurrency
            }
          };

          try {
            cart = await sendCallback(
              config,
              `${baseUrl}/${cachedCartId}${transferCartToDealerLocationContextPath}`
            );
          } catch (err) {
            // default to authenticated user's cart if we can't transfer
            cart = await sendCallback(null, `${resolveCartPath}`);
          }
        }
        // otherwise, try to get the cart by id
        else {
          const cartGuestTokenHeaderName = Environment.get(
            'cart.token.header',
            'X-Guest-Token'
          );
          const config = {
            header: isNil(cachedGuestToken?.tokenString)
              ? null
              : {
                  [cartGuestTokenHeaderName]: cachedGuestToken?.tokenString
                },
            params: {
              currency: currentCurrency
            }
          };

          cart = await sendCallback(config, `${baseUrl}/${cachedCartId}`);
        }
      }
      // otherwise, if we're authenticated, get the customer's cart
      else if (isAuthenticated) {
        cart = await sendCallback(null, `${resolveCartPath}`);
      }

      // if we have a cart, set it and add the id to local storage
      if (cart) {
        setCart(cart);
        CartCache.put('cartId', cart.id);
        CartCache.put('cartVersion', cart.version);
        CartCache.put('isGuest', !isAuthenticated);
      }

      if (cachedGuestToken) {
        setGuestToken(cachedGuestToken);
        CartCache.put('guestToken', cachedGuestToken);
      }

      setError(null);
      setIsFetching(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        // do nothing if cancelled
        return;
      }
      console.warn('useResolveCartClear', error.stack);
      CartCache.clear();
      setCart(null);
      setGuestToken(null);
      setError(error?.response?.data || error);
      setIsFetching(false);
    } finally {
      setIsFetching(false);
      setCart(cart);
    }
  }, [isAuthenticated, isAuthenticating]);

  useRefreshCart(
    isAuthenticated,
    isAuthenticating,
    resolvingTenant,
    didAuthenticationCheck,
    resolveCart,
    application
  );

  return {
    resolvedCart,
    resolvedGuestToken,
    isFetching,
    error,
    refetch: resolveCart
  };
}

/**
 * Handles refreshing the cart on page load, dealer location switching, and authentication changes.
 * @param isAuthenticated
 * @param isAuthenticating
 * @param resolvingTenant
 * @param didAuthenticationCheck
 * @param resolveCart
 */
function useRefreshCart(
  isAuthenticated,
  isAuthenticating,
  resolvingTenant,
  didAuthenticationCheck,
  resolveCart
) {
  const application = useCurrentApplication();
  useRefreshEffect(
    () => {
      if (
        !resolvingTenant &&
        !isAuthenticating &&
        didAuthenticationCheck &&
        !isEmpty(application)
      ) {
        resolveCart();
      }
    },
    {
      isAuthenticated,
      isAuthenticating,
      resolvingTenant,
      didAuthenticationCheck,
      resolveCart,
      application
    }
  );
}

function getValidGuestTokenFromCache(cachedCartId, cachedIsGuest) {
  const cachedGuestToken = CartCache.get('guestToken');
  if (!cachedIsGuest || cachedGuestToken?.cartId !== cachedCartId) {
    CartCache.delete('guestToken');
    return undefined;
  }
  return cachedGuestToken;
}

export default useResolveCart;
