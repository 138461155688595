/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import messages from './PhoneType.messages';

const PhoneType = Object.freeze({
  FAX: { value: 'FAX', label: messages.fax },
  LANDLINE: { value: 'LANDLINE', label: messages.landline },
  MOBILE: { value: 'MOBILE', label: messages.mobile }
});

export default PhoneType;
