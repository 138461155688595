/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { useFormatMessage, useInterval } from 'app/common/hooks';
import { padStart } from 'lodash';

import messages from './PreviewCountdown.messages';

/**
 * The component responsible for rendering the countdown to indicate when the preview session is
 * going to expire.
 */
const PreviewCountdown = ({ expirationTime }) => {
  const formatMessage = useFormatMessage();
  const [timeLeftSeconds, setTimeLeftSeconds] = React.useState(
    getTimeLeftSeconds(expirationTime)
  );

  useInterval(() => {
    setTimeLeftSeconds(getTimeLeftSeconds(expirationTime));
  }, 500);

  if (timeLeftSeconds >= 300) {
    return null;
  }

  const justMinutes = Math.floor(timeLeftSeconds / 60);
  const justSeconds = timeLeftSeconds % 60;
  const formattedMinutes = padStart(justMinutes.toFixed(0), 2, '0');
  const formattedSeconds = padStart(justSeconds.toFixed(0), 2, '0');
  const timestamp = `${formattedMinutes}:${formattedSeconds}`;
  return (
    <span
      className="w-10 text-sm font-semibold leading-loose tracking-wider"
      title={formatMessage(messages.expires, { timestamp })}
    >
      {timestamp}
    </span>
  );
};

/**
 * Returns the time in seconds until the date is reached from now.
 *
 * @param {string} expirationTime the timestamp
 * @return {number} time left in seconds
 */
function getTimeLeftSeconds(expirationTime) {
  if (!expirationTime) {
    return 0;
  }

  const expirationTimeMs = new Date(expirationTime).getTime();
  const nowMs = Date.now();
  return Math.max(Math.floor((expirationTimeMs - nowMs) / 1000), 0);
}

export default PreviewCountdown;
