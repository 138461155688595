/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import {
  useDeleteCustomerAddressApi,
  useFormatMessage,
  useWriteCustomerAddressApi
} from 'app/common/hooks';
import { Dropdown } from 'app/common/components';
import messages from '../AddressCard.messages.js';

const SavedAddressActions = props => {
  const { customerAddress, editAddress, refetchAddresses } = props;
  const formatMessage = useFormatMessage();
  const { sendCallback: sendDeleteCallback } = useDeleteCustomerAddressApi(
    customerAddress.id
  );
  const { sendCallback: makeDefaultBillingCallback } =
    useWriteCustomerAddressApi(
      {
        ...customerAddress,
        defaultBillingAddress: true
      },
      undefined,
      false,
      false
    );
  const { sendCallback: makeDefaultShippingCallback } =
    useWriteCustomerAddressApi(
      {
        ...customerAddress,
        defaultShippingAddress: true
      },
      undefined,
      false,
      false
    );
  const isDefaultShipping = customerAddress.defaultShippingAddress;

  return (
    <Dropdown className="self-end">
      <Dropdown.Menu.Trigger className="px-4 py-2 bg-white text-sm text-gray-800 rounded hover:bg-gray-200 focus:outline-none focus:shadow-outline">
        {formatMessage(messages.actions)}
      </Dropdown.Menu.Trigger>
      <Dropdown.Menu>
        <Dropdown.Menu.Item onClick={() => editAddress(customerAddress)}>
          {formatMessage(messages.editButton)}
        </Dropdown.Menu.Item>
        {!customerAddress.defaultBillingAddress && (
          <Dropdown.Menu.Item
            onClick={() =>
              makeDefaultBillingCallback().then(() => refetchAddresses())
            }
          >
            {formatMessage(messages.makeDefaultBilling)}
          </Dropdown.Menu.Item>
        )}
        {!customerAddress.defaultShippingAddress && (
          <Dropdown.Menu.Item
            onClick={() =>
              makeDefaultShippingCallback().then(() => refetchAddresses())
            }
          >
            {formatMessage(messages.makeDefaultShipping)}
          </Dropdown.Menu.Item>
        )}
        {!isDefaultShipping && (
          <Dropdown.Menu.Item
            onClick={() => sendDeleteCallback().then(() => refetchAddresses())}
          >
            {formatMessage(messages.deleteButton)}
          </Dropdown.Menu.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SavedAddressActions;
export { SavedAddressActions };
