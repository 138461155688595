import { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import {
  COMPLETE,
  IN_PROGRESS,
  PENDING
} from 'app/common/hooks/useRestApi/RequestStatus.js';
import { useRestApi } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils/index.js';

function useSearchSortPreferences() {
  const url = 'catalog-browse/browse/content-items';
  const [sortPrefs, setSortPrefs] = useState({});

  const {
    error,
    exception,
    response,
    sendCallback: fetchSortPrefs,
    requestStatus
  } = useRestApi(
    url,
    { params: { itemNames: 'Preferred Product Sort' } },
    false
  );

  useEffect(() => {
    if (requestStatus === IN_PROGRESS || !isEmpty(sortPrefs)) {
      return;
    }

    if (error) {
      logError({ ...exception, when: 'Fetching Sort Preferences' });
      return;
    }

    if (requestStatus === COMPLETE && isEmpty(sortPrefs)) {
      const results = response.results;
      if (isEmpty(results)) {
        return;
      }

      const resultPref = get(
        results[0],
        'fields.preferredProductSort.value.id'
      );
      setSortPrefs(resultPref);
      return;
    }

    if (requestStatus === PENDING) {
      fetchSortPrefs();
    }
  }, [error, exception, response, requestStatus, sortPrefs, fetchSortPrefs]);

  return sortPrefs;
}

export default useSearchSortPreferences;
