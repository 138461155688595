import { get, isEmpty } from 'lodash';
import {
  LOADING_LOCATIONS,
  LOADING_LOCATIONS_COMPLETE,
  STORE_LOOKUP_ERROR
} from '../reducer/storeDropdownReducer';
import { SET_USING_LOC_COORDS } from '../reducer/storeDropdownActions';
import messages from '../StoreDropdown.messages';
import { requestGeolocation } from 'app/common/utils/GeolocationUtil';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

/**
 * Attempt to use the browser's geolocation API to get the user's current lat/long.
 * If successful, set the dropdown state to use the current location.
 */
const getCurrentLocation = function (state, dispatch) {
  dispatch(LOADING_LOCATIONS);
  requestGeolocation({
    successCallback: position => {
      const { latitude, longitude } = position.coords;
      const { usingLocCoords, userSelectedAddress } = state;
      if (
        locationUnchanged(
          latitude,
          longitude,
          usingLocCoords,
          userSelectedAddress
        )
      ) {
        dispatch(LOADING_LOCATIONS_COMPLETE);
        return;
      }
      dispatch({
        type: SET_USING_LOC_COORDS,
        payload: { latitude, longitude }
      });
    },
    errorCallback: error => {
      dispatch(STORE_LOOKUP_ERROR(messages.useLocationError));
    },
    options: {
      timeout: 5000,
      // 60 seconds
      maximumAge: 60 * 1000
    }
  });
};

function locationUnchanged(latitude, longitude, comparison1, comparison2) {
  const locLatitude = get(comparison1, 'latitude', -1);
  const locLongitude = get(comparison1, 'longitude', -1);
  const userLatitude = get(comparison2, 'latitude', -1);
  const userLongitude = get(comparison2, 'longitude', -1);
  return (
    (latitude === locLatitude && longitude === locLongitude) ||
    (latitude === userLatitude && longitude === userLongitude)
  );
}

/**
 * Build a store address string for display in the store dropdown.
 * @param location - store location object
 * @returns {string} - formatted address string
 */
const buildStoreAddressForDisplay = location => {
  const { address1, address2, address3 } = location;
  let dealerAddress = `${address1}`;
  if (!isEmpty(address2)) {
    dealerAddress = dealerAddress + ` ${address2}`;
  }
  if (!isEmpty(address3)) {
    dealerAddress = dealerAddress + ` ${address3}`;
  }
  return dealerAddress;
};

/**
 * Convert an application object to a store row format. This is used to populate the current store
 * in the locator dropdown when we haven't made a call to the store locator API yet.
 * @param application
 * @returns {{displayName, locationName, phoneNumber, address3: *, address2, city, address1, postalCode, latitude: *, businessHours, state, locationNumber: *, longitude: *}|undefined}
 */
function getStoreRowFromApplication(application) {
  if (!application) {
    return undefined;
  }

  return {
    locationName: application.name,
    address1: application.line1,
    address2: application.line2,
    address3: application.line3,
    city: application.city,
    state: application.state,
    postalCode: application['zip'],
    locationNumber: application.identifierValue,
    phoneNumber: application.phoneNumber,
    businessHours: application.businessHours,
    latitude: application.latitude,
    longitude: application.longitude,
    displayName: application.displayName
  };
}

/**
 * Find the store hours for today given the store hours object. This uses the current day of the week
 * retrieved from the user's browser.
 * @param storeHours
 * @returns {string}
 */
const findStoreHoursToday = storeHours => {
  if (isEmpty(storeHours)) {
    return '';
  }
  const dayOfWeek = new Date().getDay();
  let dayString = '';
  switch (dayOfWeek) {
    case 0:
      dayString = 'sunday';
      break;
    case 1:
      dayString = 'monday';
      break;
    case 2:
      dayString = 'tuesday';
      break;
    case 3:
      dayString = 'wednesday';
      break;
    case 4:
      dayString = 'thursday';
      break;
    case 5:
      dayString = 'friday';
      break;
    case 6:
      dayString = 'saturday';
      break;
    default:
      dayString = 'none';
  }
  if (dayString === 'none') {
    return '';
  }
  const partsHours = get(storeHours, 'PARTS', {});
  const serviceHours = get(storeHours, 'SERVICE', {});
  let hoursToday = {};

  if (isEmpty(partsHours) && isEmpty(serviceHours)) {
    return '';
  } else if (isEmpty(partsHours)) {
    hoursToday = get(serviceHours, dayString, {});
  } else {
    hoursToday = get(partsHours, dayString, {});
  }

  if (isEmpty(hoursToday)) {
    return '';
  }
  const openTime = get(hoursToday, 'open', '');
  const closeTime = get(hoursToday, 'close', '');
  let openFormatted = '';
  let closeFormatted = '';
  if (!isEmpty(openTime) && !isEmpty(closeTime)) {
    dayjs.extend(customParseFormat);
    openFormatted = dayjs(openTime, 'HH:mm').format('h:mm A');
    closeFormatted = dayjs(closeTime, 'HH:mm').format('h:mm A');
    return `${openFormatted} - ${closeFormatted}`;
  }
  return '';
};

function updateDropdownContextAndReducer(
  dispatch,
  stateToUpdate,
  setOpenStoreDropdown
) {
  setOpenStoreDropdown && setOpenStoreDropdown(stateToUpdate.storeSearchOpen);
  dispatch(stateToUpdate);
}

export {
  getCurrentLocation,
  buildStoreAddressForDisplay,
  findStoreHoursToday,
  getStoreRowFromApplication,
  updateDropdownContextAndReducer,
  locationUnchanged
};
