/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useCallback, useEffect, useRef } from 'react';

import { RequestService } from '../../services';

/**
 * Hook that is used to initialize a cancel token in a ref that can be used for cancelling requests.
 * Any requests using cancel token will be cancelled on cleanup. See example below:
 *
 * ```
 * // get the cancelToken
 * const [getCancelToken] = useCancelTokenFactory();
 *
 * getCancelToken();
 * ```
 *
 * @return {Array.<Function, Object, Object>}
 */
export default function useCancelTokenFactory(cancelMessage = 'unmounted') {
  const messageRef = useRef(cancelMessage);
  const cancelRef = useRef(null);
  const tokenRef = useRef(null);

  useEffect(
    // this effect ensures the token is cancelled during cleanup
    () => {
      const message = messageRef.current;
      return () => {
        if (cancelRef.current) {
          cancelRef.current(message);
        }
      };
    },
    []
  );

  const getCancelToken = useCallback(() => {
    if (tokenRef.current && cancelRef.current) {
      cancelRef.current();
    }
    return (tokenRef.current = new RequestService.CancelToken(
      c => (cancelRef.current = c)
    ));
  }, [tokenRef, cancelRef]);

  return [getCancelToken, cancelRef, tokenRef];
}
