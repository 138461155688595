import { get, orderBy } from 'lodash';

const initialState = Object.freeze({
  activeTopBrand: undefined,
  topBrands: [],
  allTopBrands: [],
  brandFacet: undefined,
  doFilterTopBrand: false
});

const topBrandReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_ACTIVE_TOP_BRAND':
    case 'SET_BRAND_FACET':
    case 'SET_DO_FILTER_TOP_BRAND':
      return {
        ...state,
        ...payload
      };
    case 'SET_ALL_TOP_BRANDS':
      const all = payload.allTopBrands;
      const filtered = filterTopBrandsSearch(all, state.brandFacet);
      return {
        ...state,
        allTopBrands: all,
        topBrands: filtered
      };
    case 'SET_TOP_BRANDS':
      const filteredTopBrands = filterTopBrandsSearch(
        state.allTopBrands,
        payload.brandFacet
      );
      return {
        ...state,
        topBrands: filteredTopBrands,
        brandFacet: payload.brandFacet
      };
    default:
      return state;
  }
};

const SET_ACTIVE_TOP_BRAND = activeTopBrand => {
  return {
    type: 'SET_ACTIVE_TOP_BRAND',
    payload: {
      activeTopBrand
    }
  };
};

const SET_ALL_TOP_BRANDS = (allTopBrands = []) => {
  const orderedTopBrands = orderBy(allTopBrands, ['brandName'], ['asc']);
  return {
    type: 'SET_ALL_TOP_BRANDS',
    payload: {
      allTopBrands: orderedTopBrands
    }
  };
};

const FILTER_TOP_BRANDS = searchResponse => {
  const brandFacet = findBrandFacet(searchResponse);
  return {
    type: 'SET_TOP_BRANDS',
    payload: {
      brandFacet
    }
  };
};

export {
  topBrandReducer,
  initialState,
  SET_ACTIVE_TOP_BRAND,
  SET_ALL_TOP_BRANDS,
  FILTER_TOP_BRANDS
};

const findBrandFacet = searchResponse => {
  const facets = get(searchResponse, 'facets', []);
  const brandIndex = facets.findIndex(
    f => get(f, 'facet.name') === 'attr-BRAND'
  );
  if (brandIndex > -1) {
    return get(facets[brandIndex], 'values', []);
  }
  return [];
};

const filterTopBrandsSearch = (allTopBrands, brandFacet) => {
  if (brandFacet === undefined) {
    return allTopBrands;
  }
  // look at topBrands[*].brandName against values[*].value
  const brandValues = brandFacet.map(f => f.value.toUpperCase());
  return allTopBrands.filter(brand =>
    brandValues.includes(brand.brandName.toUpperCase())
  );
};
