/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { CartContext } from 'app/common/contexts';
import React from 'react';
import { chunk, get, isEmpty, join, map, merge, noop, sortBy } from 'lodash';
import { Helmet } from 'react-helmet';

import { AuthContext } from 'app/auth/contexts';
import {
  BulkAddToCartButton,
  BulkRemoveListItemButton,
  CartOrListErrors,
  CheckboxField,
  Icon,
  TertiaryButton
} from 'app/common/components';
import {
  useCartInfo,
  useCatalogInfo,
  useFetchPageApi,
  useFormatMessage,
  useGtmPageView,
  usePriceContextHeader,
  usePriceInfoContextHeader,
  useRestApi
} from 'app/common/hooks';
import { Environment } from 'app/common/services';
import { logError } from 'app/common/utils/ApiErrorUtils';

import { ListItems, ListSummary } from './components';
import { ListDetailsContext } from './contexts';
import messages from './ListDetails.messages';
import { useHeaderMetadata } from 'app/core/components/App';

const ListDetails = ({ id }) => {
  useGtmPageView('Saved List Details');
  const formatMessage = useFormatMessage();
  const { siteTitle } = useHeaderMetadata();
  const [listItems, setListItems] = React.useState([]);
  const [addToCartState, setAddToCartState] = React.useState(undefined);
  const { user } = React.useContext(AuthContext);

  const {
    listOperations: { baseUrl, itemsContextPath }
  } = useCartInfo();
  const customerRef = React.useMemo(
    () => ({
      customerId: get(user, 'serviceId'),
      username: get(user, 'username'),
      isRegistered: true,
      accountId: get(user, 'attributes[account_id]')
    }),
    [user]
  );
  const fetchItemsConfig = React.useMemo(
    () => ({
      params: customerRef
    }),
    [customerRef]
  );
  const fetchItemsState = useFetchPageApi(
    `${baseUrl}/${id}${itemsContextPath}`,
    fetchItemsConfig
  );
  const fetchListConfig = React.useMemo(
    () => ({ params: customerRef }),
    [customerRef]
  );
  const fetchListState = useRestApi(`${baseUrl}/${id}`, fetchListConfig, false);
  const [list, setList] = React.useState(fetchListState.response);
  const deleteAttribute = useDeleteListAttribute(id, setList);

  React.useEffect(() => {
    if (fetchItemsState.error) {
      logError({
        ...fetchItemsState.exception,
        when: `fetching list ${id}'s items`
      });
    }
  }, [fetchItemsState.error, fetchItemsState.exception, id]);

  React.useEffect(() => {
    if (fetchListState.error) {
      logError({
        ...fetchListState.exception,
        when: `fetching list ${id}`
      });
    }
  }, [fetchListState.error, fetchListState.exception, id]);

  React.useEffect(() => {
    if (!fetchListState.error && fetchListState.requestStatus === 'COMPLETE') {
      setList(fetchListState.response);
    }
  }, [fetchListState]);

  const {
    productInfo: { detailsUri, fetchUrl }
  } = useCatalogInfo();
  const priceContextHeader = usePriceContextHeader();
  const priceInfoContextHeader = usePriceInfoContextHeader();
  const fetchItemDetailsConfig = React.useMemo(
    () => merge(priceContextHeader, priceInfoContextHeader),
    [priceContextHeader, priceInfoContextHeader]
  );
  const { sendCallback: fetchItemDetails, ...fetchItemDetailsState } =
    useRestApi(
      `${fetchUrl}${detailsUri}?includePrices=true`,
      fetchItemDetailsConfig,
      false
    );

  React.useEffect(() => {
    if (
      !fetchItemsState.error &&
      !fetchItemsState.loading &&
      fetchItemsState.response !== undefined
    ) {
      (async () => {
        const batches = chunk(
          get(fetchItemsState, 'response.content', []),
          Environment.get('LIST_ITEM_DETAILS_FETCH_BATCH_SIZE', 100)
        );
        let results = [];

        for (const items of batches) {
          const productIds = items.map(
            ({ itemSkuRef: { productId } }) => productId
          );
          const configWithIds = {
            params: {
              productIds: join(productIds)
            }
          };
          const response = await fetchItemDetails(configWithIds);
          const products = get(response, 'products', []).filter(
            (product, index, self) =>
              productIds.includes(product.id) &&
              self.findIndex(p => p.id === product.id) === index
          );
          const x = map(products, product => {
            const matchingItem = items.filter(
              ({ itemSkuRef: { productId } }) => productId === product.id
            )[0];
            return {
              ...matchingItem,
              product
            };
          });

          results = [...results, ...x];
        }
        setListItems(results);
        await fetchListState.sendCallback();
      })();
    }
    // eslint-disable-next-line
  }, [
    fetchItemsState.error,
    fetchItemsState.loading,
    fetchItemsState.response,
    fetchItemDetails,
    fetchListState.sendCallback
  ]);

  React.useEffect(() => {
    if (fetchItemDetailsState.error) {
      logError({
        ...fetchItemDetailsState.exception,
        when: `fetching list ${id}'s item details`
      });
    }
  }, [fetchItemDetailsState.error, fetchItemDetailsState.exception, id]);
  const resolving =
    fetchListState.loading ||
    fetchListState.response === undefined ||
    fetchItemsState.loading ||
    fetchItemDetailsState.loading ||
    setListItems === undefined;

  const context = React.useMemo(
    () => ({
      listItems: sortBy(listItems, ['product.name']),
      setListItems,
      list,
      setList,
      fetchList: fetchListState.sendCallback,
      customerRef,
      addToCartState,
      setAddToCartState
    }),
    [
      list,
      listItems,
      setListItems,
      fetchListState.sendCallback,
      customerRef,
      addToCartState
    ]
  );

  if (resolving) {
    return <Skeleton />;
  }

  return (
    <ListDetailsContext.Provider value={context}>
      <Helmet titleTemplate={`%s - ${siteTitle}`}>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <section>
        <header className="text-bold flex justify-between pb-4">
          <h1 className="text-2xl">{formatMessage(messages.title)}</h1>
          <TertiaryButton to="/my-account/lists">
            <Icon className="mr-1" name="angle-left" />
            <span>{formatMessage(messages.backButton)}</span>
          </TertiaryButton>
        </header>
        <AddToCartState />
        <section className="flex flex-col flex-no-wrap justify-between lg:flex-row lg:items-start">
          <section className="w-full mb-6 lg:w-7/10 lg:mr-4 lg:mb-0 xl:w-3/4">
            <CartOrListErrors
              errors={get(context, 'list.attributes.errors')}
              id={id}
              clearErrors={deleteAttribute}
            />
            <ListItems />
          </section>
          <ListSummary />
        </section>
      </section>
    </ListDetailsContext.Provider>
  );
};

const AddToCartState = () => {
  const formatMessage = useFormatMessage();
  const { addToCartState, setAddToCartState } =
    React.useContext(ListDetailsContext);
  const success = get(addToCartState, 'success') === true;
  const ignoredItems = get(addToCartState, 'ignoredItems', []);
  const error = get(addToCartState, 'success') === false;
  const quantity = get(addToCartState, 'quantity', 0);
  const { setCartOperationException } = React.useContext(CartContext);

  if (!success && !error) {
    return null;
  }

  if (success) {
    return (
      <div className="flex">
        <section className="flex items-start justify-between flex-grow mb-4 px-2 py-1 text-sm text-green-600 leading-snug border border-solid border-green-300 bg-green-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal lg:w-7/10 lg:mr-4 xl:w-3/4">
          <div>
            {!isEmpty(ignoredItems) && (
              <div>
                {formatMessage(messages.successDuplicatesIgnored, {
                  duplicates: join(ignoredItems, ', '),
                  quantity: ignoredItems.length
                })}
              </div>
            )}
            <div>{formatMessage(messages.success, { quantity })}</div>
          </div>
          <TertiaryButton
            className="ml-4"
            colorClassName="text-green-600 hover:text-green-700 focus:text-green-700 active:text-green-500"
            onClick={() => {
              setCartOperationException(undefined);
              setAddToCartState(undefined);
            }}
            title={formatMessage(messages.successOrErrorMessageClose)}
          >
            <Icon name="times" />
          </TertiaryButton>
        </section>
        <div className="hidden lg:block lg:w-3/10 xl:w-1/4">&nbsp;</div>
      </div>
    );
  }

  return (
    <div className="flex">
      <section className="flex items-start justify-between flex-grow mb-4 px-2 py-1 text-sm text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal lg:w-7/10 lg:mr-4 xl:w-3/4">
        <div className="flex items-center">
          <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
          <div>{formatMessage(messages.error, { quantity })}</div>
        </div>
        <TertiaryButton
          className="ml-4"
          colorClassName="text-red-500 hover:text-red-700 focus:text-red-700 active:text-red-500"
          onClick={() => {
            setAddToCartState(undefined);
          }}
          title={formatMessage(messages.successOrErrorMessageClose)}
        >
          <Icon name="times" />
        </TertiaryButton>
      </section>
      <div className="hidden lg:block lg:w-3/10 xl:w-1/4">&nbsp;</div>
    </div>
  );
};

const Skeleton = () => {
  const formatMessage = useFormatMessage();

  return (
    <section>
      <header className="text-bold flex justify-between pb-4">
        <h1 className="text-2xl">{formatMessage(messages.title)}</h1>
        <TertiaryButton to="/my-account/lists">
          <Icon className="mr-1" name="angle-left" />
          <span>{formatMessage(messages.backButton)}</span>
        </TertiaryButton>
      </header>
      <section className="flex flex-col flex-no-wrap justify-between lg:flex-row lg:items-start">
        <section className="w-full mb-6 bg-gray-100 rounded shadow lg:w-7/10 lg:mr-4 lg:mb-0 xl:w-3/4 lg:shadow-md">
          <header className="p-4 bg-white rounded-t">
            <h2 className="w-48 bg-gray-200 text-xl font-bold capitalize leading-none lg:text-2xl">
              &nbsp;
            </h2>
          </header>
          <section>
            <header className="flex items-center px-4 py-2 bg-white border-t border-b border-gray-400">
              <CheckboxField.Field
                className="basis-1/12"
                checked={false}
                disabled
                value="ALL"
                field={{
                  onBlur: noop,
                  onChange: noop,
                  name: 'select-all',
                  value: 'ALL'
                }}
              />
              <div className="flex flex-col basis-11/12 flex-grow sm:flex-row sm:basis-full">
                <BulkAddToCartButton disabled selectedItems={[]} />
                <BulkRemoveListItemButton disabled selectedItems={[]} />
              </div>
            </header>
            <section style={{ minHeight: '100px' }}>
              <div className="has-animated-ellipsis flex items-center w-full mt-4 p-4 text-gray-600 text-lg leading-none sm:mt-6 sm:text-xl lg:mt-8">
                {formatMessage(messages.loadingItems)}
              </div>
            </section>
          </section>
        </section>
        <ListSummary resolving />
      </section>
    </section>
  );
};

ListDetails.Skeleton = Skeleton;

function useDeleteListAttribute(id, setList) {
  const {
    listOperations: { attributesContextPath, baseUrl }
  } = useCartInfo();
  const config = React.useMemo(
    () => ({ method: 'del', params: { price: false } }),
    []
  );
  const { sendCallback: deleteAttribute } = useRestApi(
    `${baseUrl}/${id}${attributesContextPath}/errors`,
    config,
    false
  );
  return React.useCallback(async () => {
    const response = await deleteAttribute();
    if (!isEmpty(response)) {
      setList(response);
    }
    return Promise.resolve();
  }, [deleteAttribute, setList]);
}

export default ListDetails;
export { ListDetails };
