/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { DecoratedField } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from './PostalCode.messages';

/**
 * Render component for the postal code field. Applies validation based on
 * `country`.
 */
const PostalCode = ({ country, ...props }) => {
  const formatMessage = useFormatMessage();
  return (
    <DecoratedField
      {...props}
      validate={value => {
        if (isEmpty(value)) {
          return formatMessage(messages.required);
        }

        if (!PostalCode.Regex[country].test(value)) {
          return formatMessage(messages.invalid);
        }
      }}
    />
  );
};

PostalCode.Regex = Object.freeze({
  CA: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ][ -]?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i,
  ES: /^([1-9]{2}|\d[1-9]|[1-9]\d)\d{3}$/,
  FR: /^(F-)?((2[A|B])|\d{2})\d{3}$/,
  GB: /^[A-Z]{1,2}[\dR][\dA-Z]?[ -]\d[ABD-HJLNP-UW-Z]{2}$/i,
  MX: /^\d{5}$/,
  US: /^\d{5}(?:[ -]\d{4})?$/
});

PostalCode.propTypes = {
  /** Value of the country field. Used to drive validation */
  country: PropTypes.string.isRequired
};

export default PostalCode;
export { PostalCode };
