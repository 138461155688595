/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import {
  get,
  join,
  toLower,
  map,
  memoize,
  replace,
  split,
  upperCase
} from 'lodash';

import messages from './LocaleUtils.messages';

/**
 * Retrieves the locale message descriptor given the provided `locale` argument.
 *
 * @param  {String} locale the locale code
 * @return {Object}        the message descriptor
 */
function getLocaleMessageDescriptor(locale) {
  const message = get(messages, locale);

  if (!!message) {
    return message;
  }
  // if we do not have a message defined locally, we will "guess" a message descriptor
  return guessLocaleMessageDescriptor(locale);
}

/**
 * Guesses the locale message description following the pattern: "locales.<localeCode>"
 *
 * @param {String}  locale the locale code
 * @return {Object}        the message descriptor
 */
const guessLocaleMessageDescriptor = memoize(locale => {
  return {
    id: `locales.${locale}`,
    defaultMessage: locale,
    description: `Auto-formatted message description for locale, "${locale}"`
  };
});

/**
 * Formats any locale code into the standard locale format set by IETF (see https://tools.ietf.org/rfc/bcp/bcp47.txt).
 *
 * @param  {String} unformattedLocale an unformatted locale code
 * @return {String}                   a formatted locale code
 */
function formatLocale(unformattedLocale) {
  if (!unformattedLocale) {
    return undefined;
  }

  const standardLocale = replace(unformattedLocale, '_', '-');

  // technically, en-US and EN-us mean the same thing, so we must format for consistency
  const [language, ...parts] = split(standardLocale, '-');
  const formattedLanguage = toLower(language);
  const formattedParts = map(parts, upperCase);

  return join([formattedLanguage, ...formattedParts], '-');
}
/**
 * Formats an Array of locale code into kebab-cased locale codes.
 *
 * @see `#formatLocale`
 * @param  {Array}  [unformattedLocales=[]] an Array of unformatted locale code
 * @return {Array}                          an Array of formatted locale code
 */
function formatLocales(unformattedLocales = []) {
  return map(unformattedLocales, formatLocale);
}

export { formatLocale, formatLocales, getLocaleMessageDescriptor };
