/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext } from 'react';
import classNames from 'classnames';
import { get, map } from 'lodash';
import AnimateHeight from 'react-animate-height';

import { Icon, TertiaryButton } from 'app/common/components';
import { CartContext } from 'app/common/contexts';
import { useFormatMessage, useToggle } from 'app/common/hooks';

import messages from './CheckoutItemSummary.messages';
import CheckoutItem from './components/CheckoutItem';

const CheckoutItemSummary = () => {
  const formatMessage = useFormatMessage();
  const [isActive, toggleActive] = useToggle(false);
  const { cart } = useContext(CartContext);
  const cartItems = get(cart, 'cartItems');

  return (
    <section
      className={classNames(
        'flex flex-col w-full mb-4 rounded shadow bg-white lg:shadow-md',
        {
          'is-active': isActive
        }
      )}
    >
      <button
        className="flex items-center justify-between w-full py-3 px-4 capitalize text-gray-600 text-xs text-center capitalize font-bold hover:text-gray-700 hover:underline focus:outline-none rounded"
        id="itemSummary-toggle-trigger"
        aria-expanded={isActive}
        type="button"
        onClick={() => toggleActive()}
      >
        <span>{formatMessage(messages.title)}</span>
        {!isActive && <Icon name="plus" size="xs" />}
        {isActive && <Icon name="minus" size="xs" />}
      </button>
      <AnimateHeight duration={200} height={isActive ? 'auto' : 0}>
        <div
          className="flex flex-col items-start px-4 py-2"
          aria-hidden={!isActive}
        >
          <ul
            className="flex flex-col w-full list-none overflow-y-auto"
            style={{ maxHeight: '30rem' }}
          >
            {map(cartItems, (cartItem, i) => (
              <CheckoutItem key={cartItem.id} {...cartItem} position={i} />
            ))}
          </ul>
          <TertiaryButton size={TertiaryButton.Size.SMALL} to="/cart">
            <Icon className="mr-2" name="angle-double-left" size="sm" />
            <span>{formatMessage(messages.viewCartLabel)}</span>
          </TertiaryButton>
        </div>
      </AnimateHeight>
    </section>
  );
};

export default CheckoutItemSummary;
export { CheckoutItemSummary };
