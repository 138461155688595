/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import {
  CartItemOptions,
  TertiaryButton,
  CartItemAlert
} from 'app/common/components';
import { useProductClick } from 'app/common/hooks';

import messages from '../../CartItem.messages';
import { CartItemActions } from './components';

/**
 * Render component for the cart item's primary information.
 *
 * @visibleName Cart: Item: Details
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const CartItemDetails = ({ cartItem, position, gtmItem }) => {
  const {
    attributeChoices,
    dependentCartItems,
    detailsUrl,
    name,
    unitPriceType,
    unitPriceWithDependentItems: unitPrice,
    attributes
  } = cartItem;
  const productDescription = get(cartItem, 'attributes.productDescription');
  const sendProductClick = useProductClick(gtmItem);
  return (
    <section className="w-full">
      <header>
        <h3 className="font-bold leading-none">
          <TertiaryButton
            className="text-base"
            colorClassName="text-gray-700 hover:text-gray-800 focus:text-gray-800 active:text-gray-600"
            size={TertiaryButton.Size.CUSTOM}
            to={location => ({
              ...location,
              pathname: detailsUrl,
              state: {
                ...get(location, 'state', {}),
                breadcrumbs: [
                  {
                    uri: '/cart',
                    label: messages.cartBreadcrumbLabel
                  },
                  {
                    label: cartItem.name
                  }
                ]
              }
            })}
            onClick={() => {
              sendProductClick();
            }}
          >
            {name}
          </TertiaryButton>
        </h3>
        <h4 className="mt-2 leading-tight">{productDescription}</h4>
      </header>
      {/* todo: reenable once there's time to debug why this causes requests to be sent when merely selecting a cart item or adding it to a list */}
      {/*<section className="mt-1 text-xs sm:text-sm">*/}
      {/*  <MarketingMessages*/}
      {/*    className="flex flex-col color-red-600"*/}
      {/*    item={cartItem}*/}
      {/*    type={'CART_ITEM'}*/}
      {/*    location={MarketingMessageLocationType.CART}*/}
      {/*  />*/}
      {/*</section>*/}
      <section className="mt-2 mb-4">
        <CartItemOptions
          attributeChoices={attributeChoices}
          dependentCartItems={dependentCartItems}
        />
      </section>
      <CartItemAlert
        unitPriceType={unitPriceType}
        unitPrice={unitPrice}
        attributes={attributes}
      />
      <CartItemActions cartItem={cartItem} position={position} />
    </section>
  );
};

CartItemDetails.propTypes = {
  cartItem: PropTypes.shape({
    /** URL of the item's details page */
    detailsUrl: PropTypes.string.isRequired,
    /** ID of the cart item */
    id: PropTypes.string.isRequired,
    /** Name of the cart item */
    name: PropTypes.string.isRequired
  }).isRequired
};

export default CartItemDetails;
export { CartItemDetails };
