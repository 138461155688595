/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, join } from 'lodash';
import PropTypes from 'prop-types';

import { CartItemOptions, CoreCost } from 'app/common/components';
import { useProductUrl } from 'app/common/hooks';

import { ItemAsset, ItemHeader, ItemPricing } from './components';
import messages from './OrderReviewItem.messages';

const OrderReviewItem = ({
  attributes,
  attributeChoices,
  dependentCartItems,
  imageAsset,
  sku,
  name,
  position,
  quantity = 1,
  unitPrice,
  uri
}) => {
  const url = useProductUrl(uri);
  const gtmItem = React.useMemo(
    () => ({
      brand: get(attributes, 'productBrand.value'),
      category: join(get(attributes, 'categoryNames', []), ', '),
      id: sku,
      name: get(attributes, 'productDescription'),
      price: get(unitPrice, 'amount', 0.0),
      position,
      quantity
    }),
    [attributes, sku, unitPrice, quantity, position]
  );
  const coreCost = get(get(attributes, 'priceInfo'), 'coreCost');

  return (
    <li className="flex justify-start py-2 border-b border-solid border-gray-400 last:border-b-0">
      <ItemAsset imageAsset={imageAsset} productName={name} productUrl={url} />
      <section className="flex flex-wrap flex-auto items-start justify-between basis-1/2 pr-2">
        <div className="mb-2 pr-4">
          <ItemHeader
            name={name}
            url={url}
            gtmItem={gtmItem}
            attributes={attributes}
          />
          <CartItemOptions
            attributeChoices={attributeChoices}
            dependentCartItems={dependentCartItems}
          />
        </div>
        {!!unitPrice && (
          <ItemPricing quantity={quantity} unitPrice={unitPrice} />
        )}
        <CoreCost
          className="text-sm text-gray-700"
          coreCost={coreCost}
          message={messages.coreCost}
        />
      </section>
    </li>
  );
};

OrderReviewItem.propTypes = {
  name: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  unitPrice: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired
  })
};

export default OrderReviewItem;
export { OrderReviewItem };
