/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { assign, find, findIndex, isEmpty, isNil, omitBy } from 'lodash';

function toggleCurrentFilter({
  /** Whether the current filter is being activated or deactivated. */
  checked,
  filters,
  maxValue,
  minValue,
  multiSelect,
  name,
  ranged,
  value
}) {
  const currentFilter = find(filters, ['name', name]);

  if (!checked && isEmpty(currentFilter)) {
    /*
     * Nothing to do since it's already removed
     *
     * This shouldn't happen
     */
    return filters;
  }

  if (!checked && !isEmpty(currentFilter)) {
    return removeFromExistingFilterEntry({
      currentFilter,
      filters,
      valueToRemove: value,
      maxToRemove: maxValue,
      minToRemove: minValue,
      multiSelect,
      ranged
    });
  }

  if (isEmpty(currentFilter)) {
    const newFilter = createNewFilterEntry({
      currentFilter,
      filters,
      newValue: value,
      newMax: maxValue,
      newMin: minValue,
      name,
      ranged
    });

    return [...filters, newFilter];
  }

  const updatedFilter = createFromExistingFilterEntry({
    currentFilter,
    newValue: value,
    newMax: maxValue,
    newMin: minValue,
    multiSelect,
    ranged
  });
  const index = findIndex(filters, currentFilter);

  return assign([], filters, { [index]: updatedFilter });
}

/**
 * The Filter to which this FilterValue belongs is brand new. We need to create
 * a new Filter to add to the list of params.
 *
 * @param {string} newValue - Optional. The current explicit values to add to
 *    the existing filter. Only applicable if `ranged !== true`.
 * @param {number} newMax - Optional. The new `maxValue` to add to the
 *     existing filter. Only applicable if `ranged === true`.
 * @param {number} newMin - Optional. The new `minValue` to add to the
 *     existing filter. Only applicable if `ranged === true`.
 * @param {string} name - The name identifying the parent Filter of the
 *     FilterValue.
 * @param {boolean} ranged - Whether this filter uses a range instead of
 *     explicit values. If so, then `newMax` and `newMin` apply rather than
 *     `newValue`.
 *
 * @return {{minValue: *, maxValue: *, name: *}|{values: *, name: *}} New Filter
 *     with the new FilterValue
 */
function createNewFilterEntry({ newValue, newMax, newMin, name, ranged }) {
  if (!ranged) {
    return { name, values: newValue };
  }

  return {
    name,
    ranges: [{ maxValue: newMax, minValue: newMin }]
  };
}

/**
 * Creates a new Filter that is derived from the existing one to which this
 * FilterValue belongs but with the new `maxValue`, `minValue`, or `value`.
 *
 * @param {Object} currentFilter - the existing filter to which to add the new
 *     values
 * @param {string} newValue - Optional. The current explicit values to add to
 *    the existing filter. Only applicable if `ranged !== true`.
 * @param {number} newMax - Optional. The new `maxValue` to add to the
 *     existing filter. Only applicable if `ranged === true`.
 * @param {number} newMin - Optional. The new `minValue` to add to the
 *     existing filter. Only applicable if `ranged === true`.
 * @param {boolean} multiSelect - Whether this filter allows multiple values.
 *     If not, then the new values will replace any pre-existing ones.
 * @param {boolean} ranged - Whether this filter uses a range instead of
 *     explicit values. If so, then `newMax` and `newMin` apply rather than
 *     `newValue`.
 *
 * @return {*|{minValue: *, maxValue: *}|{minValue: (*), maxValue: (*)}|{values: *}|{values: string}}
 *     New filter with the new FilterValue added.
 */
function createFromExistingFilterEntry({
  currentFilter,
  newValue,
  newMax,
  newMin,
  multiSelect,
  ranged
}) {
  if (!multiSelect && !ranged) {
    /*
     * Need to replace the existing `values` with the ones belonging to this
     * FilterValue
     */
    return {
      ...currentFilter,
      values: newValue
    };
  }

  if (!multiSelect) {
    /* Need to replace the existing `minValue` and `maxValue` with those
     * belonging to this FilterValue
     *
     * It doesn't matter what they were before since we can only have 1 range at
     * a time
     */
    return omitBy(
      {
        ...currentFilter,
        ranges: [{ maxValue: newMax, minValue: newMin }]
      },
      isNil
    );
  }

  if (!ranged) {
    /*
     * It's multiSelect, so we append this FilterValue's values rather than
     * replace the existing.
     */
    return {
      ...currentFilter,
      values: `${currentFilter.values},${newValue}`
    };
  }

  return {
    ...currentFilter,
    ranges: [...currentFilter.ranges, { maxValue: newMax, minValue: newMin }]
  };
}

/**
 * Removes the current FilterValue from being represented in the existing,
 * related Filter. If no other values were associated with the parent Filter,
 * then the filter is removed.
 *
 * @param {Object} currentFilter - the existing filter from which to remove the
 *     values
 * @param {Array} filters - List of existing filters represented by the URL
 *     search queries.
 * @param {string} valueToRemove - Optional. The current explicit values to
 *     remove from the existing filter. Only applicable if `ranged !== true`.
 * @param {number} maxToRemove - Optional. The `maxValue` to remove from the
 *     existing filter. Only applicable if `ranged === true`.
 * @param {number} minToRemove - Optional. The `minValue` to remove from the
 *     existing filter. Only applicable if `ranged === true`.
 * @param {boolean} multiSelect - Whether this filter allows multiple values.
 * @param {boolean} ranged - Whether this filter uses a range instead of
 *     explicit values. If so, then `maxToRemove` and `minToRemove` apply rather
 *     than `valueToRemove`.
 *
 * @return {*} New array with the `currentFilter` removed or updated to exclude
 *     the FilterValue
 */
function removeFromExistingFilterEntry({
  currentFilter,
  filters,
  valueToRemove,
  maxToRemove,
  minToRemove,
  multiSelect,
  ranged
}) {
  if (!multiSelect) {
    return removeNonMultiSelectFilter(filters, currentFilter);
  }

  if (!ranged) {
    return removeNonRangedFilter(filters, currentFilter, valueToRemove);
  }

  return removeMultiSelectRangedFilter(
    filters,
    currentFilter,
    maxToRemove,
    minToRemove
  );
}

/**
 * If it's not multiSelect, then this is the only value for the Filter, so the
 * whole Filter can be removed.
 */
function removeNonMultiSelectFilter(filters, currentFilter) {
  return filters.filter(f => f !== currentFilter);
}

/**
 * If it's not ranged, then we just need to remove the value from the string
 * of `values`. If it's the only value, we remove the entire filter.
 */
function removeNonRangedFilter(filters, currentFilter, valueToRemove) {
  const { values: currentVals } = currentFilter;
  const newValues = currentVals.replace(
    new RegExp(`${valueToRemove},?`, 'mg'),
    ''
  );

  if (isEmpty(newValues)) {
    return filters.filter(f => f !== currentFilter);
  }

  const updatedFilter = {
    ...currentFilter,
    values: newValues.replace(/,$/, '')
  };
  const i = findIndex(filters, currentFilter);

  return assign([], filters, { [i]: updatedFilter });
}

function removeMultiSelectRangedFilter(
  filters,
  currentFilter,
  maxToRemove,
  minToRemove
) {
  const { ranges } = currentFilter;

  if (ranges.length === 1) {
    // remove the filter since this was the only range
    return filters.filter(f => f !== currentFilter);
  }

  const updatedFilter = {
    ...currentFilter,
    ranges: ranges.filter(
      ({ maxValue, minValue }) =>
        maxValue !== maxToRemove && minValue !== minToRemove
    )
  };
  const i = findIndex(filters, currentFilter);

  return assign([], filters, { [i]: updatedFilter });
}

/**
 * Removes selected facets with zero quantity from the URL search query. This
 * is currently used when navigating category pages with brand filters active
 * to verify that the selected brands are still available in the new category.
 * @param facets The facets to check for zero quantity
 * @param location The current location object
 * @param history The history object from useHistory
 * @param facetName The name of the facet to check for zero quantity
 */
function removeZeroQuantitySelectedFacets(
  facets,
  location,
  history,
  facetName
) {
  if (!location.search || !facets || !facetName) {
    return;
  }
  const validFacetsValues = facets
    .filter(facet => facet.facet.name === facetName)
    .map(facet => facet.values)
    .reduce((acc, val) => acc.concat(val), [])
    .filter(val => val.quantity > 0)
    .map(val => val.value);
  if (validFacetsValues.length === 0) {
    return;
  }

  const searchParams = new URLSearchParams(location.search);
  let index = 0;
  let updated = false;
  for (const [key, value] of searchParams) {
    if (!key.startsWith('filters') && !key.endsWith('name')) {
      continue;
    }
    const isMatch = key === `filters[${index}].name` && value === facetName;
    if (!isMatch) {
      index++;
      continue;
    }
    const selectedValues = searchParams
      .get(`filters[${index}].values`)
      .split(',');
    let valuesToRemove = new Set(selectedValues);
    if (selectedValues.length === 0) {
      break;
    }
    for (let i = 0; i < selectedValues.length; i++) {
      if (validFacetsValues.indexOf(selectedValues[i]) >= 0) {
        valuesToRemove.delete(selectedValues[i]);
      }
    }
    if (valuesToRemove.size === selectedValues.length) {
      searchParams.delete(`filters[${index}].name`);
      searchParams.delete(`filters[${index}].values`);
      updated = true;
    } else if (valuesToRemove.size > 0) {
      searchParams.set(
        `filters[${index}].values`,
        selectedValues.filter(val => !valuesToRemove.has(val)).join(',')
      );
      searchParams.set(`filters[${index}].name`, facetName);
      updated = true;
    }
    // we found the filter we were looking for, so we can break
    break;
  }
  if (updated) {
    history.replace({
      search: searchParams.toString()
    });
  }
}

export {
  toggleCurrentFilter,
  createNewFilterEntry,
  createFromExistingFilterEntry,
  removeFromExistingFilterEntry,
  removeZeroQuantitySelectedFacets
};
