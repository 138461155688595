import { delay, get } from 'lodash';

/**
 * Go to a specific checkout step.
 * @param history - The history object from react-router
 * @param nextUri - The URI to navigate to. See CheckoutPaths.js
 * @param nextState - Any state parameters to pass to the next step
 * @param wait - The number of milliseconds to wait before navigating
 * @param modalName - The name of the modal to open if applicable
 * @return {(function(): void)|*} - A function to navigate to a specified checkout step. See
 * CheckoutPaths.js
 */
const goToCheckoutStep = function (
  history,
  nextUri,
  nextState = {},
  wait = 100,
  modalName = null
) {
  return () => {
    const prevState = get(history, 'location.state', {});
    nextState = { ...prevState, ...nextState };
    let urlParams = new URLSearchParams(history.location.search);
    if (modalName) {
      urlParams.set('modal', modalName);
    }

    delay(() => {
      history.push({
        pathname: nextUri,
        state: nextState,
        search: urlParams.toString()
      });
    }, wait);
  };
};

export default goToCheckoutStep;
