/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty, reduce, set } from 'lodash';

import { CoreCost, Currency } from 'app/common/components';
import {
  useDetermineActiveOptionPricing,
  useFormatMessage
} from 'app/common/hooks';
import { determineIncludedItemPricing } from 'app/product/utils/PricingUtils';
import { ConfigurableItemContext, PdpContext } from 'app/product/contexts';

import messages from './DefaultPrice.messages';
import { PriceType } from 'app/common/constants';

const DefaultPrice = () => {
  const formatMessage = useFormatMessage();
  const { product } = React.useContext(PdpContext);
  const { includedProducts, unitOfMeasurement, coreCost } = product;
  const { activeOption, itemChoices } = React.useContext(
    ConfigurableItemContext
  );

  const increaseFromIncludedProducts = React.useMemo(
    () =>
      reduce(
        includedProducts,
        (sum, item) => {
          const itemPricing = determineIncludedItemPricing(item);
          const itemPrice = get(itemPricing, 'price', { amount: 0.0 });
          return sum + itemPrice.amount * item.quantity;
        },
        0.0
      ),
    [includedProducts]
  );
  const increaseFromItemChoices = React.useMemo(
    () =>
      reduce(
        itemChoices,
        (sum, choice) =>
          sum +
          reduce(
            choice,
            (subSum, specificChoice) =>
              subSum + specificChoice.priceIncrease || 0.0,
            0.0
          ),
        0.0
      ),
    [itemChoices]
  );

  const activePricing = useDetermineActiveOptionPricing(product, activeOption);

  if (isEmpty(activePricing)) {
    return (
      <div className="w-48 mr-2 mt-2 text-3xl bg-gray-200 rounded">&nbsp;</div>
    );
  }

  const addToParentAmount =
    increaseFromIncludedProducts + increaseFromItemChoices;

  const price = get(activePricing, 'price', {});
  const isSale = get(activePricing, 'isSale', false);
  const originalPrice = get(activePricing, 'originalPrice', {});
  const standardPrice =
    get(activePricing, 'priceType') === PriceType.STANDARD_PRICE;
  const salePrice = (isSale || !isEmpty(originalPrice)) && !standardPrice;
  set(price, 'amount', get(price, 'amount', 0.0));

  const originalPriceAmount = get(originalPrice, 'amount', undefined);
  const priceAmount = get(price, 'amount', undefined);
  let discounted = salePrice;
  if (originalPriceAmount && priceAmount) {
    discounted = originalPriceAmount > priceAmount;
  }

  return (
    <div className="flex flex-col items-baseline justify-start mt-2">
      <div className="flex w-full items-baseline leading-tight">
        {discounted ? (
          <div className="flex items-baseline">
            <Currency
              className="mr-2 text-3xl text-red-600 leading-tight"
              amount={price.amount + addToParentAmount}
              currency={price.currency}
            />
            {!isEmpty(originalPrice) && (
              <Currency
                className="ml-2 text-xl text-gray-500 line-through leading-tight"
                amount={originalPrice.amount + addToParentAmount}
                currency={originalPrice.currency}
              />
            )}
          </div>
        ) : (
          !isEmpty(price) && (
            <Currency
              className="text-3xl text-gray-900"
              amount={price.amount + addToParentAmount}
              currency={price.currency}
            />
          )
        )}
        <div className="ml-1 text-gray-700">
          {formatMessage(messages.uom, { uom: unitOfMeasurement })}
        </div>
      </div>
      <div className="tw-text-xs text-gray-700 leading-none">
        {formatMessage(messages.taxCaveat)}
      </div>
      <br />
      <CoreCost
        className="text-2xl text-gray-700"
        coreCost={activePricing.coreCost ? activePricing.coreCost : coreCost}
        message={messages.coreCost}
      />
    </div>
  );
};

export default DefaultPrice;
export { DefaultPrice };
