/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext } from 'react';
import cx from 'classnames';
import { findIndex, map } from 'lodash';

import { Dropdown } from 'app/common/components';
import { LocaleContext } from 'app/common/contexts';
import { useFormatMessage } from 'app/common/hooks';
import {
  formatLocale,
  formatLocales,
  getLocaleMessageDescriptor
} from 'app/common/utils/LocaleUtils';

import France from './img/France.png';
import Mexico from './img/Mexico.png';
import Spain from './img/Spain.png';
import UnitedKingdom from './img/United-Kingdom.png';
import UnitedStates from './img/United-States.png';

/**
 * Render component for the site locale selector.
 *
 * @visibleName Site Locale Selector
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const LocaleSelector = ({ className }) => {
  const formatMessage = useFormatMessage();
  const { allowedLocales, currentLocale, setCurrentLocale } =
    useContext(LocaleContext);
  const localesInfo = LocaleSelector.getLocalesInfo(
    formatMessage,
    allowedLocales
  );
  const [label, setLabel] = React.useState(
    <div className="w-6 h-5 bg-primary-800 rounded">&nbsp;</div>
  );

  React.useEffect(() => {
    const currentLocaleIcon = LocaleSelector.getLocaleIcon(currentLocale);
    const currentLocaleLabel = formatMessage(
      getLocaleMessageDescriptor(currentLocale)
    );

    setLabel(
      !!currentLocaleIcon ? (
        <div className="flex items-center justify-start">
          <img
            className="mr-2 lg:mr-0"
            src={currentLocaleIcon}
            alt={currentLocaleLabel}
          />
          <span className="text-sm lg:hidden">{currentLocaleLabel}</span>
        </div>
      ) : (
        currentLocaleLabel
      )
    );
  }, [currentLocale, formatMessage]);

  return (
    <Dropdown className={cx('lg:mr-1', className)} hoverable>
      <Dropdown.Menu.Trigger
        className="w-full lg:w-auto"
        triggerClassName="justify-between w-full px-4 py-2 lg:px-2 focus:outline-none focus:shadow-outline lg:w-auto lg:rounded"
      >
        {label}
      </Dropdown.Menu.Trigger>
      <Dropdown.Menu
        className="w-full text-primary-900 lg:w-48 lg:mt-1"
        openTo={Dropdown.Menu.OpenTo.RIGHT}
      >
        {localesInfo.map(locale => (
          <Dropdown.Menu.Item
            isActive={locale.value === currentLocale}
            className="flex"
            key={locale.value}
            onClick={() => setCurrentLocale(locale.value)}
          >
            <img className="mr-2" src={locale.icon} alt={locale.label} />
            <span>{locale.label}</span>
          </Dropdown.Menu.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

LocaleSelector.getLocalesInfo = (formatMessage, allowedLocales = []) => {
  const locales = formatLocales(allowedLocales);
  return map(locales, locale => getLocaleInfo(locale, formatMessage));
};

function getLocaleInfo(locale, formatMessage) {
  return {
    label: formatMessage(getLocaleMessageDescriptor(locale)),
    value: locale,
    icon: LocaleSelector.getLocaleIcon(locale)
  };
}

LocaleSelector.getLocaleIcon = locale => {
  switch (locale) {
    case 'fr':
    case 'fr-FR':
      return France;
    case 'es':
    case 'es-MX':
      return Mexico;
    case 'es-ES':
      return Spain;
    case 'en-GB':
      return UnitedKingdom;
    case 'en':
    case 'en-US':
      return UnitedStates;
    default:
      return '';
  }
};

LocaleSelector.addCurrentLocaleIfMissing = (
  localesInfo,
  currentLocale,
  formatMessage
) => {
  let currentLocaleIndex = findIndex(localesInfo, [
    'value',
    formatLocale(currentLocale)
  ]);

  if (currentLocaleIndex < 0) {
    localesInfo.unshift(getLocaleInfo(currentLocale, formatMessage));
    currentLocaleIndex = 0;
  }

  return currentLocaleIndex;
};

export default LocaleSelector;
export { LocaleSelector };
