/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty, join, map } from 'lodash';

import { AuthContext } from 'app/auth/contexts';
import { SecondaryButton } from 'app/common/components';
import { useCartInfo, useFormatMessage, useRestApi } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import { ListsContext } from '../../contexts';
import messages from '../../Lists.messages';

const BulkDeleteButton = () => {
  const formatMessage = useFormatMessage();
  const { lists, setLists, selectedLists } = React.useContext(ListsContext);
  const { user } = React.useContext(AuthContext);
  const customerRef = React.useMemo(
    () => ({
      customerId: get(user, 'serviceId'),
      username: get(user, 'username'),
      isRegistered: true,
      accountId: get(user, 'attributes[account_id]')
    }),
    [user]
  );
  const selectedListIds = React.useMemo(
    () => map(selectedLists, ({ id }) => id),
    [selectedLists]
  );
  const deleteConfig = React.useMemo(
    () => ({
      method: 'del',
      params: {
        ...customerRef,
        listIds: join(selectedListIds)
      }
    }),
    [customerRef, selectedListIds]
  );
  const {
    listOperations: { baseUrl, bulkContextPath }
  } = useCartInfo();
  const { sendCallback: deleteLists } = useRestApi(
    `${baseUrl}${bulkContextPath}`,
    deleteConfig,
    false,
    true
  );

  return (
    <SecondaryButton
      className="mr-2"
      disabled={isEmpty(selectedLists)}
      onClick={async () => {
        if (isEmpty(selectedLists)) {
          return;
        }

        try {
          await deleteLists();

          setLists(
            lists.filter(({ id }) => {
              return !selectedListIds.includes(id);
            })
          );
        } catch (err) {
          logError({
            ...err,
            when: `deleting saved lists ${join(selectedListIds, ', ')}`
          });
        }
      }}
      backgroundColor="bg-white hover:bg-red-100 focus:bg-red-100 active:bg-red-100 disabled:bg-white"
      borderColor="border-red-300 disabled:border-gray-200"
      textColor="text-red-700 hover:text-red-800 focus:text-red-800 disabled:text-gray-400"
    >
      {formatMessage(messages.deleteAll)}
    </SecondaryButton>
  );
};

export default BulkDeleteButton;
export { BulkDeleteButton };
