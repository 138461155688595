/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  details: {
    id: 'Orders.OrderSummary.details',
    defaultMessage: 'View Details'
  },
  orderDate: {
    id: 'Orders.OrderSummary.orderDate',
    defaultMessage: 'Date Placed'
  },
  orderNumber: {
    id: 'Orders.OrderSummary.orderNumber',
    defaultMessage: 'Order Number'
  },
  orderPrice: {
    id: 'Orders.OrderSummary.orderPrice',
    defaultMessage: 'Total'
  },
  statusLabel: {
    id: 'Orders.OrderSummary.status.label',
    defaultMessage: 'Status'
  },
  status: {
    id: 'Orders.OrderSummary.status',
    defaultMessage:
      '{finalStatus, select, SUBMITTED {Submitted} ACKNOWLEDGED {Submitted} SHIPPED {Shipped} DELIVERED {Delivered} PICK_UP_READY {Ready for Pickup} PICKED_UP {Picked Up} CANCEL_REQUESTED {Cancellation Requested} CANCELLED {Cancelled} OPEN {Processing} COMPLETE {Completed} other {Submitted}}'
  },
  seeMoreItems: {
    id: 'Orders.OrderSummary.state',
    defaultMessage: 'Plus {quantity} more...'
  },
  purchaseOrderNumber: {
    id: 'Orders.OrderSummary.purchaseOrderNumber',
    defaultMessage: 'PO Number'
  },

  paymentGatewayTypeCOD: {
    id: 'Orders.OrderSummary.paymentGatewayTypeCOD',
    defaultMessage: 'Pay in Store'
  },
  paymentGatewayTypePAYPAL_CHECKOUT_V2: {
    id: 'Orders.OrderSummary.paymentGatewayTypePAYPAL_CHECKOUT_V2',
    defaultMessage: 'PayPal'
  },
  paymentMethod: {
    id: 'Orders.OrderSummary.paymentMethod',
    defaultMessage: 'Payment Method'
  }
});
