/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { Form, Formik } from 'formik';
import { get, isArray, isEmpty, map, mapValues } from 'lodash';
import { Helmet } from 'react-helmet';

import {
  DecoratedField,
  Icon,
  Input,
  PrimaryButton
} from 'app/common/components';
import { CustomerContext } from 'app/common/contexts';
import {
  useCustomerService,
  useFormatDate,
  useFormatMessage,
  useGtmPageView,
  useRestApi,
  useTenantInfo
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import messages from './Fleets.messages';
import { useHeaderMetadata } from 'app/core/components/App';

const Fleets = () => {
  const { siteTitle } = useHeaderMetadata();
  const formatMessage = useFormatMessage();
  const formatDate = useFormatDate();
  const { customer, parts, setParts, fetchFleets, setFleets } =
    React.useContext(CustomerContext);
  const customerId = get(customer, 'id');
  const { baseUrl, fleetCustomersContextPath } = useCustomerService();
  const { baseUrl: fleetsBaseUrl, fleetUri, fleetParam } = useTenantInfo();
  const config = React.useMemo(() => ({ method: 'post' }), []);
  const { sendCallback: authorize } = useRestApi(
    undefined,
    config,
    false,
    true
  );
  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const hasError = !isEmpty(errorMessage);

  useGtmPageView('Fleet Associations');

  return (
    <section>
      <Helmet titleTemplate={`%s - ${siteTitle}`}>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <header className="text-2xl text-bold flex justify-between mb-6">
        <h1>{formatMessage(messages.title)}</h1>
      </header>
      <section>
        <section className="mb-4">
          {hasError && (
            <aside className="flex items-center mb-4 px-2 py-1 text-sm text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
              <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
              <span>{formatMessage(errorMessage)}</span>
            </aside>
          )}
          <Formik
            initialValues={{ authorizationCode: '' }}
            onSubmit={async (values, actions) => {
              try {
                const newPart = await authorize(
                  {
                    data: {
                      ...values,
                      type: 'PARTS'
                    }
                  },
                  `${baseUrl}/${customerId}${fleetCustomersContextPath}`
                );

                const response = await fetchFleets(
                  { params: { [fleetParam]: true } },
                  `${fleetsBaseUrl}${fleetUri}/${newPart.fleetId}?customer=true`
                );
                const newFleet = isArray(response.content)
                  ? response.content[0]
                  : response;

                setFleets(fleets => [...fleets, newFleet]);
                setParts(prevParts => [
                  ...prevParts,
                  { ...newPart, fleet: newFleet }
                ]);
                setErrorMessage(undefined);
                actions.resetForm({ authorizationCode: '' });
              } catch (err) {
                const reason = err.type;

                if ('FLEET_CUSTOMER_ALREADY_AUTHORIZED' === reason) {
                  setErrorMessage(messages.authCodeAlreadyAuthed);
                } else if (
                  'FLEET_CUSTOMER_AUTHORIZATION_INVALID' === reason ||
                  'NOT_PERMITTED' === reason
                ) {
                  setErrorMessage(messages.authCodeInvalid);
                } else if (!isEmpty(err.fieldErrors)) {
                  const errors = mapValues(err.fieldErrors, errorArray =>
                    formatMessage(messages[errorArray[0].code])
                  );
                  actions.setErrors(errors);
                } else {
                  logError({
                    ...err,
                    when: `authorizing fleet association for ${customerId}`
                  });
                  setErrorMessage(messages.authCodeGenericError);
                }
              } finally {
                actions.setSubmitting(false);
              }
            }}
            validateOnChange={false}
            validateOnBlur={true}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col w-full">
                <DecoratedField
                  disabled={isSubmitting}
                  id="fleet-auth-code"
                  label={formatMessage(messages.authCodeLabel)}
                  name="authorizationCode"
                  placeholder={formatMessage(messages.authCodePlaceholder)}
                  required
                  size={Input.Size.SMALL}
                  validate={value => {
                    if (isEmpty(value)) {
                      return formatMessage(messages.authCodeRequired);
                    }
                  }}
                />
                <div className="flex justify-end w-full lg:w-64">
                  <PrimaryButton
                    type="submit"
                    disabled={isSubmitting}
                    size={PrimaryButton.Size.SMALL}
                  >
                    {formatMessage(messages.authCodeSubmit)}
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </Formik>
        </section>
        {isEmpty(parts) ? (
          <div className="w-full text-bold text-gray-500 text-xl text-center sm:text-left sm:mt-8 sm:ml-8 sm:text-2xl">
            {formatMessage(messages.noFleets)}
          </div>
        ) : (
          <section>
            <header className="flex p-4 text-sm leading-none font-medium">
              <div className="basis-1/2 pr-1">
                {formatMessage(messages.fleetName)}
              </div>
              <div className="basis-1/2 pl-1">
                {formatMessage(messages.authedDate)}
              </div>
            </header>
            <section>
              <ul className="list-none">
                {map(parts, part => {
                  const { fleet = {} } = part;
                  return (
                    <li
                      className="flex my-4 p-4 shadow rounded bg-white first:mt-0"
                      key={part.id}
                    >
                      <div className="basis-1/2 pr-1">{fleet.name}</div>
                      <div className="basis-1/2 pl-1">
                        {formatDate(get(part, 'dateAuthorized', '2020-06-09'), {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric'
                        })}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </section>
          </section>
        )}
      </section>
    </section>
  );
};

export default Fleets;
export { Fleets };
