/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';

/**
 * Render component for a loading icon.
 *
 * @visibleName Loading Icon
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const LoadingIcon = ({ className, color = 'border-blue-500' }) => {
  return (
    <div
      className={classNames('LoadingIcon', {
        [className]: !!className,
        [color]: !!color
      })}
    />
  );
};

export default LoadingIcon;
export { LoadingIcon };
