/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  signInButton: {
    id: 'Homepage.signIn.button',
    defaultMessage: 'Sign In'
  },
  signUpButton: {
    id: 'Homepage.signUp.button',
    defaultMessage: 'Sign Up'
  },
  guestButton: {
    id: 'Homepage.guest.button',
    defaultMessage: 'Continue as Guest'
  },
  signInHeader: {
    id: 'Homepage.signIn.header',
    defaultMessage: 'Already have a Parts To Go account?'
  },
  signUpHeader: {
    id: 'Homepage.signUp.header',
    defaultMessage:
      'Register to save your information and set your default service provider. You will also receive marketing promotions and access to the complete Aurora catalog.'
  },
  guestHeader: {
    id: 'Homepage.guest.header',
    defaultMessage:
      "If you continue as guest, the Parts To Go site won't have your preferences saved and the catalog will be limited to our most popular parts."
  },
  or: {
    id: 'Homepage.or',
    defaultMessage: 'or'
  },
  loginPrompt: {
    id: 'Homepage.loginPrompt',
    defaultMessage: 'Please login into the site for the best experience.'
  },
  alreadyRegistered: {
    id: 'Homepage.alreadyRegistered',
    defaultMessage: 'Already registered?'
  },
  signInMessage: {
    id: 'Homepage.signInMessage',
    defaultMessage: 'Sign in for faster checkout.'
  },
  additionalSignIn: {
    id: 'Homepage.additionalSignIn',
    defaultMessage:
      'We recommend signing into your account to see your specific preferences and pricing.'
  },
  forgotPassword: {
    id: 'Homepage.forgotPassword',
    defaultMessage: 'Forgot your password?'
  },
  createAccount: {
    id: 'Homepage.createAccount',
    defaultMessage: 'Create account'
  },
  emailAddress: {
    id: 'Homepage.emailAddress',
    defaultMessage: 'Email address'
  },
  password: {
    id: 'Homepage.password',
    defaultMessage: 'Password'
  },
  loginFailedGeneric: {
    id: 'auth.login-failed.generic',
    defaultMessage: 'Invalid username and password combination'
  },
  accountLockedInactivity: {
    id: 'auth.login-failed.account-locked-inactivity',
    defaultMessage: 'Your account has been locked due to inactivity'
  }
});
