/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from 'app/common/components';
import { useFormatMessage, useProductUrl } from 'app/common/hooks';

import messages from './Sharing.messages';

const Sharing = ({ uri }) => {
  const formatMessage = useFormatMessage();
  const url = useProductUrl(uri);
  const shareUrl = `${window.location.origin}${url}`;
  return (
    <footer className="flex w-full mt-8">
      <ul className="flex flex-wrap w-full items-center">
        <ListItem>
          <div id="fb-root" />
          <script
            async
            defer
            crossOrigin="anonymous"
            src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.3"
          />
          <div
            className="fb-share-button"
            data-href={shareUrl}
            data-layout="button"
            data-size="small"
          >
            <SocialButton
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                shareUrl
              )}&amp;src=sdkpreparse`}
              className="fb-xfbml-parse-ignore bg-facebook-500 hover:bg-facebook-700 focus:bg-facebook-700 active:bg-facebook-300"
            >
              <SocialIcon name={['fab', 'facebook-f']} />
              <span>{formatMessage(messages.facebook)}</span>
            </SocialButton>
          </div>
        </ListItem>
        <ListItem>
          <SocialButton
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/share?ref_src=twsrc%5Etfw"
            className="bg-twitter-500 hover:bg-twitter-700 focus:bg-twitter-700 active:bg-twitter-300"
            data-show-count="false"
          >
            <SocialIcon name={['fab', 'twitter']} />
            <span>{formatMessage(messages.twitter)}</span>
          </SocialButton>
          <script async src="https://platform.twitter.com/widgets.js" />
        </ListItem>
        <ListItem>
          <script
            type="text/javascript"
            async
            defer
            src="https://assets.pinterest.com/js/pinit.js"
          />
          <SocialButton
            target="_blank"
            rel="noopener noreferrer"
            className="bg-pinterest-500 hover:bg-pinterest-700 focus:bg-pinterest-700 active:bg-pinterest-300"
            data-pin-do="buttonBookmark"
            href="https://www.pinterest.com/pin/create/button/"
          >
            <SocialIcon name={['fab', 'pinterest-p']} />
            <span>{formatMessage(messages.pinterest)}</span>
          </SocialButton>
        </ListItem>
        <ListItem>
          <SocialButton
            target="_blank"
            rel="noopener noreferrer"
            className="bg-blue-500 hover:bg-blue-600 focus:bg-blue-600 active:bg-blue-400"
            href={`mailto:?subject=Found something interesting!&amp;body=Check out this product ${shareUrl}`}
          >
            <SocialIcon name={['fas', 'envelope']} />
            <span>{formatMessage(messages.email)}</span>
          </SocialButton>
        </ListItem>
      </ul>
    </footer>
  );
};

const ListItem = ({ children, ...props }) => (
  <li className="mb-2 mr-3 last:mr-0" {...props}>
    {children}
  </li>
);

const SocialButton = ({ children, className, ...props }) => (
  <a
    className={classNames(
      'fb-xfbml-parse-ignore',
      'inline-flex items-center justify-center',
      'p-2',
      'capitalize text-white text-sm font-bold leading-none',
      'appearance-none rounded shadow',
      'hover:text-white',
      'focus:outline-none focus:shadow-outline',
      'disabled:bg-gray-400 disabled:cursor-not-allowed',
      { [className]: className }
    )}
    {...props}
  >
    {children}
  </a>
);

const SocialIcon = props => <Icon className="mr-1" {...props} />;

Sharing.propTypes = {
  uri: PropTypes.string.isRequired
};

export default Sharing;
export { Sharing };
