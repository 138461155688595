/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { Icon } from 'app/common/components';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { noop, range, toInteger } from 'lodash';
import PropTypes from 'prop-types';

import { useFormatMessage, useFormatNumber, useToggle } from 'app/common/hooks';

import messages from './QuantitySelect.messages';

const QuantitySelect = ({
  maximumQuantity: max,
  minimumQuantity: min = 0,
  handleChange = noop,
  initialQuantity = 0
}) => {
  const formatNumber = useFormatNumber();
  const formatMessage = useFormatMessage();
  // need to mock useMemo for shallow rendering in test to work
  const options = React.useMemo(() => {
    const opts = QuantitySelect.options(max, min, formatNumber);

    // show up to 10, if more than 10 then show a switch-to-input option
    if (!max || max > 10) {
      opts.pop();
      opts.push({
        label: `${formatNumber(10)}+`,
        value: QuantitySelect.OVER_TEN
      });
    }

    return opts;
  }, [formatNumber, max, min]);
  const [value, setValue] = useState(initialQuantity);
  const [isUseSelect, toggleUseSelect] = useToggle(initialQuantity <= 10);

  useEffect(() => {
    toggleUseSelect(initialQuantity <= 10);
  }, [initialQuantity, toggleUseSelect]);

  return (
    <div className="relative block">
      {isUseSelect ? (
        <>
          <select
            className={classNames(
              'block',
              'w-12 p-1',
              'text-sm text-gray-900 leading-tight',
              'appearance-none bg-white border border-solid border-gray-400 rounded',
              'hover:cursor-pointer focus:shadow-outline focus:outline-none disabled:bg-gray-200 disabled:border-gray-300 disabled:cursor-not-allowed',
              'lg:text-base'
            )}
            onChange={e => {
              let qty = e.target.value;

              if (qty === QuantitySelect.OVER_TEN) {
                toggleUseSelect(true);
                return;
              }

              qty = toInteger(qty);

              if (isNaN(qty)) {
                // todo: log that it's not valid
                return;
              }

              setValue(qty);
              handleChange(qty);
            }}
            title={formatMessage(messages.label)}
            value={value}
          >
            {options.map(({ label, value: val }) => (
              <option key={val} value={val}>
                {label}
              </option>
            ))}
          </select>
          <div className="absolute flex items-center justify-center inset-y-0 right-0 w-6 pt-px pl-px bg-white border border-l-0 border-solid border-gray-400 rounded-r pointer-events-none">
            <Icon name="angle-down" size="sm" />
          </div>
        </>
      ) : (
        <>
          <input
            autoComplete="off"
            className={classNames(
              'p-2',
              'text-sm text-gray-900 placeholder-gray-500 leading-none',
              'appearance-none bg-white border border-solid border-gray-400 rounded',
              'focus:shadow-outline focus:outline-none disabled:bg-gray-200 disabled:border-gray-300 disabled:cursor-not-allowed',
              'lg:text-base'
            )}
            min={0}
            max={1e9}
            step={1}
            name="quantity"
            onChange={e => {
              const qty = toInteger(e.target.value);

              if (isNaN(qty)) {
                // todo: log that it's not valid
                return;
              }

              setValue(qty);
              handleChange(qty);
            }}
            value={value}
            type="number"
          />
          <em className="block mt-1 text-xs text-gray-600 leading-tight not-italic">
            {formatMessage(messages.hintMin({ min }))}
            {!!max ? ' ' + formatMessage(messages.hintMax({ max })) : ''}
          </em>
        </>
      )}
    </div>
  );
};

QuantitySelect.OVER_TEN = '10+';

QuantitySelect.options = (max = 10, min = 0, formatNumber = noop) => {
  return range(min, max + 1).map(q => ({
    label: formatNumber(q),
    value: q
  }));
};

QuantitySelect.propTypes = {
  maximumQuantity: PropTypes.number,
  minimumQuantity: PropTypes.number,
  handleChange: PropTypes.func,
  initialQuantity: PropTypes.number
};

export default QuantitySelect;
export { QuantitySelect };
