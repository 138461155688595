/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { find, get } from 'lodash';
import PropTypes from 'prop-types';

import { ConfigurableItemContext, PdpContext } from 'app/product/contexts';
import { variantAttributeChoice as attributeChoiceAction } from 'app/product/helpers/ConfigurableItem';
import { getAllowableValuesInCombinationWithAlreadySelected } from 'app/product/utils/AttributeChoiceUtils';

import { ChoiceGroup } from '../attributeTypes';
import ProductOption from '../ProductOption';

const VariantAttributeChoice = ({ option }) => {
  const {
    activeOption,
    variantAttributeChoices,
    attributeValueToRelatedCombinationsMap,
    dispatch,
    unusedAttributeValues
  } = React.useContext(ConfigurableItemContext);
  const { attributeChoice, displayOrder, label } = option;
  const {
    allowedValues = [],
    attributeName,
    type,
    required = true
  } = attributeChoice;
  const [activeChoice, setActiveChoice] = React.useState(undefined);

  React.useEffect(() => {
    const defaultValue = get(activeOption, `optionValues.${attributeName}`);
    const defChoice = find(allowedValues, { value: defaultValue });
    setActiveChoice(defChoice);
  }, [activeOption, allowedValues, attributeName]);

  const allowedWithOtherSelectedAttrs = React.useMemo(
    () =>
      getAllowableValuesInCombinationWithAlreadySelected(
        attributeName,
        variantAttributeChoices,
        attributeValueToRelatedCombinationsMap
      ),
    [
      attributeName,
      variantAttributeChoices,
      attributeValueToRelatedCombinationsMap
    ]
  );
  const usableAllowedValues = React.useMemo(
    () =>
      allowedValues
        // don't render any completely unused values
        .filter(({ value }) => {
          const unused = unusedAttributeValues[attributeName];
          return !unused || !unused.has(value);
        })
        // mark values that aren't used in combination with the currently
        // selected attributes as disabled since they're invalid
        .map(allowedValue => ({
          ...allowedValue,
          disabled:
            !!allowedWithOtherSelectedAttrs &&
            !allowedWithOtherSelectedAttrs.has(allowedValue.value)
        })),
    [
      allowedValues,
      allowedWithOtherSelectedAttrs,
      attributeName,
      unusedAttributeValues
    ]
  );
  const error = get(
    React.useContext(PdpContext),
    `addToCartErrors.attributeConfigErrors.${attributeName}[0].errorMessage`
  );
  const [fieldError, setFieldError] = React.useState(error);

  React.useEffect(() => {
    setFieldError(error);
  }, [error]);

  if (option.label === 'UOM') {
    const eachAllowedValues = usableAllowedValues.filter(allowedValue => {
      return allowedValue.value === 'each';
    });
    const noEachUsableAllowedValues = usableAllowedValues.filter(
      allowedValue => {
        return allowedValue.value !== 'each';
      }
    );

    return (
      <div>
        <ProductOption
          activeChoice={activeChoice}
          displayOrder={displayOrder}
          error={fieldError}
          label={'Available Default Unit'}
          required={required}
          type={type}
        >
          <ChoiceGroup
            activeChoice={activeChoice}
            allowedValues={eachAllowedValues}
            handleSelect={(label, value) => {
              setActiveChoice({ label, value });
              dispatch(attributeChoiceAction(attributeName, value));
              setFieldError(undefined);
            }}
            type={type}
          />
        </ProductOption>
        <ProductOption
          activeChoice={activeChoice}
          displayOrder={displayOrder}
          error={fieldError}
          label={'Available Alternate Units'}
          required={required}
          type={type}
        >
          <ChoiceGroup
            activeChoice={activeChoice}
            allowedValues={noEachUsableAllowedValues}
            handleSelect={(label, value) => {
              setActiveChoice({ label, value });
              dispatch(attributeChoiceAction(attributeName, value));
              setFieldError(undefined);
            }}
            type={type}
          />
        </ProductOption>
      </div>
    );
  }

  return (
    <ProductOption
      activeChoice={activeChoice}
      displayOrder={displayOrder}
      error={fieldError}
      label={label}
      required={required}
      type={type}
    >
      <ChoiceGroup
        activeChoice={activeChoice}
        allowedValues={usableAllowedValues}
        handleSelect={(label, value) => {
          setActiveChoice({ label, value });
          dispatch(attributeChoiceAction(attributeName, value));
          setFieldError(undefined);
        }}
        type={type}
      />
    </ProductOption>
  );
};

VariantAttributeChoice.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string.isRequired,
    displayOrder: PropTypes.number,
    attributeChoice: PropTypes.shape({
      attributeName: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      required: PropTypes.bool,
      allowedValues: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          displayOrder: PropTypes.number
        })
      ).isRequired
    }).isRequired
  }).isRequired
};

export default VariantAttributeChoice;
export { VariantAttributeChoice };
