/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
/**
 * Returns true if the color is an rgb, hsl, hex, or CSS-named color.
 * @param  {string}  color an rgb, hsl, hex, or CSS-named color.
 * @return {boolean} whether the color is an rgb, hsl, hex, or CSS-named color.
 */
export function isValidColor(color) {
  return !!convertColorToRgb(color);
}

/**
 * If the color is an rgb, hsl, hex, or CSS-named color, returns an RGB object for that color.
 * Otherwise, null.
 * This is accomplished by attaching the color to a temporary dom element and reading the compute color.
 *
 * @param  {string} color an rgb, hsl, hex, or CSS-named color.
 * @return {Object || null} If the color is an rgb, hsl, hex, or CSS-named color, returns an RGB object for that color. Otherwise, null.
 */
export function convertColorToRgb(color) {
  const tmpElement = document.createElement('div');
  document.body.appendChild(tmpElement);
  tmpElement.style.color = color;

  if (!tmpElement.style.color) {
    // always remove the element
    document.body.removeChild(tmpElement);
    // the color was not valid so the browser didn't set it on the element
    // do this to avoid any inherited color in a live app producing a false positive
    return null;
  }

  const rgbString = window.getComputedStyle(tmpElement).color;
  document.body.removeChild(tmpElement);

  return parseRgbStringToObject(rgbString);
}

function parseRgbStringToObject(rgbString) {
  const parsedRgbString =
    /^rgb\(([\d]{1,3}),\s?([\d]{1,3}),\s?([\d]{1,3})\)$/i.exec(rgbString);

  return parsedRgbString
    ? {
        r: parseInt(parsedRgbString[1], 10),
        g: parseInt(parsedRgbString[2], 10),
        b: parseInt(parsedRgbString[3], 10)
      }
    : null;
}

/**
 * Determines whether dark or light is a better foreground color given a background color based on brightness calculation.
 * See https://www.w3.org/TR/AERT/#color-contrast.
 *
 * @param  {String, Object} background background color. Can be rgb string (rgb(0, 0, 0)), hex (#000000), hsl string (hsl(0, 100%, 50%)), CSS named color (e.g., black, white, green, blue), or rgb object ({r: 0, g: 0, b: 0})
 * @return {String} dark or light, whichever is more visible on the background color provided
 */
export function getForegroundColor(background) {
  const bg = convertColorToRgb(background);
  if (!convertColorToRgb(background)) {
    return 'dark';
  }

  return getBrightness({ ...bg }) > 160 ? 'dark' : 'light';
}

function getBrightness({ r, g, b }) {
  return (r * 299 + g * 587 + b * 114) / 1000;
}
