/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import * as Yup from 'yup';

import { LocaleContext } from 'app/common/contexts';
import { useFormatMessage } from 'app/common/hooks';

/**
 * Hook to to use to generate the Yup validation schema for the AccountInfo
 * form.
 *
 * @param {{}} messages - react-intl messages from the component
 *
 * @return {{}} Yup validation schema
 */
function useValidationSchema(messages) {
  const { currentLocale } = React.useContext(LocaleContext);
  const formatMessage = useFormatMessage();

  return React.useMemo(
    () =>
      !!currentLocale &&
      Yup.object().shape({
        firstName: Yup.string().required(
          formatMessage(messages.firstNameRequired)
        ),
        lastName: Yup.string().required(
          formatMessage(messages.lastNameRequired)
        ),
        email: Yup.string()
          .email(formatMessage(messages.emailInvalid))
          .required(formatMessage(messages.emailRequired))
      }),
    [currentLocale, formatMessage, messages]
  );
}

export default useValidationSchema;
