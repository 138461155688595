import { useRefreshEffect, useRestApi, useToggle } from 'app/common/hooks';
import { useEffect, useState } from 'react';
import { logError } from 'app/common/utils/ApiErrorUtils';
import { isEmpty } from 'lodash';
import { IN_PROGRESS } from 'app/common/hooks/useRestApi/RequestStatus.js';
import useBrandsInfo from './useBrandsInfo.js';

function useMinSubCatsApi(parentCategory) {
  const [minSubCats, setMinSubCats] = useState([]);
  const [fetching, toggleFetching] = useToggle(false);
  const { minCategoriesPath } = useBrandsInfo();

  const clearMinSubCats = () => {
    setMinSubCats(null);
  };

  const {
    error,
    exception,
    response,
    sendCallback: fetchMinCategories,
    requestStatus
  } = useRestApi(minCategoriesPath, {}, false);

  useRefreshEffect(
    () => {
      const config = {
        params: { parentCategoryId: parentCategory.id }
      };
      toggleFetching(true);
      fetchMinCategories(config);
    },
    [requestStatus, parentCategory],
    // sub-categories could be empty, rely on null to tell if we need to fetch
    () =>
      minSubCats === null &&
      !isEmpty(parentCategory) &&
      requestStatus !== IN_PROGRESS &&
      !fetching,
    false
  );

  useEffect(() => {
    if (error) {
      logError({ ...exception, when: 'Fetching Sub-Categories' });
      return;
    }

    if (response) {
      setMinSubCats(response);
      toggleFetching(false);
    }
    // eslint-disable-next-line
  }, [response, error, exception]);

  return { minSubCats, clearMinSubCats };
}

export default useMinSubCatsApi;
