/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { CartEmailForm } from 'app/checkout/components';

import { ReviewContext } from '../../contexts';
import messages from './ReviewInfoEditable.messages';

/**
 * Render component to allow upating the cart email address during checkout's
 * review stage.
 */
const ReviewInfoEditable = ({ disabled = false }) => {
  const { setEditing } = useContext(ReviewContext);
  return (
    <CartEmailForm
      disabled={disabled}
      finishEditing={() => setEditing(false)}
      submitLabel={messages.updateLabel}
      review
    />
  );
};

ReviewInfoEditable.propTypes = {
  disabled: PropTypes.bool
};

export default ReviewInfoEditable;
export { ReviewInfoEditable };
