/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { noop } from 'lodash';

/**
 * @type {Readonly<{
 *   setCustomer: function,
 *   discounts: []|undefined,
 *   setDiscounts: function,
 *   fleets: []|undefined,
 *   setFleets: function,
 *   parts: []|undefined,
 *   setParts: function,
 *   customer: {
 *     id: string,
 *     firstName: string|undefined,
 *     lastName: string|undefined,
 *     fullName: string|undefined,
 *     email: string,
 *     username: string,
 *     preferredCommunicationMethod: string
 *   }|undefined
 * }>}
 */
const defaultContext = Object.freeze({
  customer: undefined,
  setCustomer: noop,
  parts: undefined,
  setParts: noop,
  discounts: undefined,
  setDiscounts: noop,
  /** part fleets */
  fleets: undefined,
  setFleets: noop
});

/**
 * After authentication, this context should be populated from the
 * CustomerService since AuthServices will be missing some of the more detailed
 * Customer info.
 *
 * @type {React.Context<Readonly<{
 *   setCustomer: Function,
 *   discounts: (*[]|undefined),
 *   setDiscounts: Function,
 *   fleets: (*[]|undefined),
 *   setFleets: Function,
 *   parts: (*[]|undefined),
 *   setParts: Function,
 *   customer: ({
 *     id: string,
 *     firstName: (string|undefined),
 *     lastName: (string|undefined),
 *     fullName: (string|undefined),
 *     email: string,
 *     username: string,
 *     preferredCommunicationMethod: string
 *   }|undefined
 * )}>>}
 */
const CustomerContext = React.createContext(defaultContext);

CustomerContext.defaultContext = defaultContext;

export default CustomerContext;
