/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

import { useFormatMessage, useGtmPageView } from 'app/common/hooks';

import messages from './RegisterHelmet.messages';
import { useHeaderMetadata } from '../../../../../core/components/App';

const RegisterHelmet = () => {
  const { siteTitle } = useHeaderMetadata();
  const formatMessage = useFormatMessage();
  const title = formatMessage(messages.title);

  useGtmPageView('Register Account');

  return (
    <ReactHelmet titleTemplate={`%s - ${siteTitle}`}>
      <title>{title}</title>
      <meta name="robots" content="noindex,nofollow" />
    </ReactHelmet>
  );
};

export default RegisterHelmet;
export { RegisterHelmet };
