/**
 * @typedef {Object} NearestInventoryState
 * @property {number} resultsNum The number of results to display
 * @property {Object} error The error object
 * @property {Object} selectedLocation The selected location
 * @property {boolean} loadingGeolocation Are we currently loading the geolocation?
 * @property {boolean} loadingStores Are we currently loading nearby stores?
 * @property {Array} stores The nearby stores
 * @property {Array} unavailableItems The unavailable items
 * @property {boolean} shouldLoadStores Should we load nearby stores? Defaults to true, which will trigger a fetch when the modal is opened.
 */

/**
 * @type {NearestInventoryState}
 */
const initialState = {
  resultsNum: 5,
  error: {},
  selectedLocation: undefined,
  loadingGeolocation: false,
  loadingStores: false,
  stores: [],
  unavailableItems: [],
  shouldLoadStores: true
};

const SET_RESULTS_NUM_ACTION = 'SET_RESULTS_NUM';
const SET_ERROR_ACTION = 'SET_ERROR';
const SET_SELECTED_LOCATION_ACTION = 'SET_SELECTED_LOCATION';
const SET_LOADING_GEOLOCATION_ACTION = 'SET_LOADING_GEOLOCATION';
const SET_LOADING_STORES_ACTION = 'SET_LOADING_STORES';
const SET_STORES_ACTION = 'SET_STORES';
const SET_STATE = 'SET_STATE';

/**
 * A reducer for handling state of an individual nearby inventory modal. See also
 * nearestInventoryCommonReducer.
 * @param {Object} state - The current state.
 * @param {Object} action - The action object containing information about the action.
 * @param {string} action.type - The type of the action.
 * @param {*} action.payload - The payload associated with the action.
 * @returns {Object} - The updated state.
 */
const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_RESULTS_NUM_ACTION:
    case SET_ERROR_ACTION:
    case SET_SELECTED_LOCATION_ACTION:
    case SET_LOADING_GEOLOCATION_ACTION:
    case SET_LOADING_STORES_ACTION:
    case SET_STORES_ACTION:
    case SET_STATE:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};

/**
 * Set the number of inventory results to display.
 * @param resultsNum
 * @return {{payload: {resultsNum: number}, type: string}}
 * @constructor
 */
const SET_RESULTS_NUM = (resultsNum = 5) => ({
  type: SET_RESULTS_NUM_ACTION,
  payload: { resultsNum }
});

/**
 * Set the error field. This will also set the loadingGeolocation and loadingStores fields to false.
 * @param error
 * @return {{payload: {loadingGeolocation: boolean, error, loadingStores: boolean}, type: string}}
 * @constructor
 */
const SET_ERROR = error => ({
  type: SET_ERROR_ACTION,
  payload: { error, loadingGeolocation: false, loadingStores: false }
});

/**
 * Set the chosen inventory location
 * @param selectedLocation The inventory location
 * @return {{payload: {selectedLocation}, type: string}}
 * @constructor
 */
const SET_SELECTED_LOCATION = selectedLocation => ({
  type: SET_SELECTED_LOCATION_ACTION,
  payload: { selectedLocation }
});

/**
 * Action to indicate that we are currently loading the user's geolocation
 * @type {{payload: {loadingGeolocation: boolean}, type: string}}
 */
const LOADING_GEOLOCATION_START = {
  type: SET_LOADING_GEOLOCATION_ACTION,
  payload: { loadingGeolocation: true }
};

/**
 * Action to indicate that we have completed loading the user's geolocation
 * @type {{payload: {loadingGeolocation: boolean}, type: string}}
 */
const LOADING_GEOLOCATION_COMPLETE = {
  type: SET_LOADING_GEOLOCATION_ACTION,
  payload: { loadingGeolocation: false }
};

/**
 * Action to indicate that we are currently loading nearby stores
 * @param loadingStores Are we currently loading nearby stores?
 * @return {{payload: {loadingStores}, type: string}}
 */
const LOADING_STORES = loadingStores => ({
  type: SET_LOADING_STORES_ACTION,
  payload: { loadingStores }
});

/**
 * Sets the stores and indicates that the store fetch has completed
 * @param stores The nearby stores
 * @return {{payload: {stores, loadingStores: boolean}, type: string}}
 */
const SET_STORES = stores => ({
  type: SET_STORES_ACTION,
  payload: { stores, loadingStores: false }
});

/**
 * Resets the nearest inventory state to the initial state. This is used when the modal is closed.
 * @return {{payload: {loadingGeolocation: boolean, resultsNum: number, error: {}, selectedLocation: undefined, loadingStores: boolean, unavailableItems: *[]}, type: string}}
 */
const RESET_ON_CLOSE = () => {
  return {
    type: SET_STATE,
    payload: {
      resultsNum: 5,
      error: {},
      selectedLocation: undefined,
      unavailableItems: [],
      loadingGeolocation: false,
      loadingStores: false,
      shouldLoadStores: true
    }
  };
};

/**
 * Sets the unavailable items
 * @param unavailableItems
 * @return {{payload: {unavailableItems}, type: string}}
 * @constructor
 */
const SET_UNAVAILABLE_ITEMS = unavailableItems => ({
  type: SET_STATE,
  payload: { unavailableItems }
});

/**
 * Action to trigger a fetch of nearby stores
 * @type {{payload: {shouldLoadStores: boolean}, type: string}}
 */
const START_FETCH_STORES = {
  type: SET_STATE,
  payload: { shouldLoadStores: true }
};

/**
 * Action to indicate that the store fetch has completed
 * @type {{payload: {shouldLoadStores: boolean}, type: string}}
 */
const FETCH_STORES_COMPLETE = {
  type: SET_STATE,
  payload: { shouldLoadStores: false }
};

const CLEAR_ERRORS = {
  type: SET_STATE,
  payload: { error: {} }
};

export {
  reducer,
  initialState,
  SET_RESULTS_NUM,
  SET_ERROR,
  SET_SELECTED_LOCATION,
  LOADING_GEOLOCATION_START,
  LOADING_GEOLOCATION_COMPLETE,
  LOADING_STORES,
  SET_STORES,
  RESET_ON_CLOSE,
  SET_UNAVAILABLE_ITEMS,
  START_FETCH_STORES,
  FETCH_STORES_COMPLETE,
  CLEAR_ERRORS
};
