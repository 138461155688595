/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext } from 'react';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { get, isEmpty, isNil, isString, mapValues, noop } from 'lodash';
import PropTypes from 'prop-types';

import { AuthService } from 'app/auth/services';
import {
  DecoratedField,
  Icon,
  LoadingIcon,
  PrimaryButton,
  SecondaryButton
} from 'app/common/components';
import { CartContext } from 'app/common/contexts';
import { useFormatMessage, useCartContactInfoApi } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import { useCartEmailFormValidationSchema } from './hooks';
import messages from './CartEmailForm.messages';
import { AuthContext } from '../../../auth/contexts';
import { Environment } from '../../../common/services';

/**
 * Render component for a form to update the cart's emailAddress for checkout.
 */
const CartEmailForm = ({
  className,
  disabled = false,
  finishEditing = noop,
  submitLabel,
  review = false,
  guestTokenCallback = undefined
}) => {
  const formatMessage = useFormatMessage();
  const {
    setCart,
    cart = {},
    setGuestToken,
    guestToken
  } = useContext(CartContext);
  const emailAddress = cart.emailAddress || '';
  const validationSchema = useCartEmailFormValidationSchema(formatMessage);
  const { error, exception, sendCallback } = useCartContactInfoApi();
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Formik
      initialValues={{ cartEmail: emailAddress }}
      onSubmit={(values, actions) => {
        if (!disabled) {
          CartEmailForm.handleSubmit(
            { values, actions },
            {
              sendCallback,
              setCart,
              finishEditing
            },
            isAuthenticated,
            { guestTokenCallback, setGuestToken, guestToken },
            review
          );
        }
      }}
      validateOnBlur
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form
          className={classNames('flex flex-col', {
            [className]: !!className
          })}
        >
          {error && !isSubmitting && (
            <div className="mb-4 px-2 py-1 text-sm text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
              <div className="flex items-center w-full">
                <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
                <span>
                  {exception.type !== 'VALIDATION'
                    ? formatMessage(messages.generalError)
                    : get(exception, 'fieldErrors.emailAddress[0].reason')}
                </span>
              </div>
              {review &&
                get(exception, 'fieldErrors.emailAddress[0].code') ===
                  'aurora.validation.guestUsingRegisteredEmail' && (
                  <div className="flex justify-end w-full">
                    <PrimaryButton
                      backgroundColor="bg-red-700 border border-red-700 hover:bg-red-800 hover:border-red-800 focus:bg-red-800 focus:border-red-800 active:bg-red-500 active:border-red-500"
                      onClick={() => AuthService.loginWithRedirect()}
                    >
                      {formatMessage(messages.signIn)}
                    </PrimaryButton>
                  </div>
                )}
            </div>
          )}
          <DecoratedField
            id="cart-email"
            hint={formatMessage(messages.emailHint)}
            label={formatMessage(messages.emailLabel)}
            name="cartEmail"
            placeholder={formatMessage(messages.emailPlaceholder)}
            required
            type="email"
            disabled={disabled}
            autoComplete="email"
            showErrors={false}
          />
          <div className="flex justify-end mt-4">
            {!!finishEditing && finishEditing !== noop && (
              <SecondaryButton
                className="mr-2"
                disabled={isSubmitting}
                onClick={finishEditing}
              >
                {formatMessage(messages.cancel)}
              </SecondaryButton>
            )}
            <PrimaryButton
              className="relative"
              type="submit"
              disabled={disabled || isSubmitting}
            >
              <div className={classNames({ 'text-transparent': isSubmitting })}>
                {isString(submitLabel)
                  ? submitLabel
                  : formatMessage(submitLabel)}
              </div>
              {isSubmitting && (
                <div className="absolute inset-0 flex justify-center items-center">
                  <LoadingIcon className="text-blue-500" />
                </div>
              )}
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

CartEmailForm.handleSubmit = async (
  { values, actions },
  { sendCallback, setCart, finishEditing },
  isAuthenticated,
  { guestTokenCallback, setGuestToken, guestToken },
  review
) => {
  const cartGuestTokenHeaderName = Environment.get(
    'cart.token.header',
    'X-Guest-Token'
  );

  try {
    let resolvedGuestToken;
    if (!isAuthenticated && !guestToken && !review) {
      try {
        resolvedGuestToken = await guestTokenCallback();
        setGuestToken(get(resolvedGuestToken, 'token'));
      } catch (error) {
        console.error('There was an error retrieving a guest token', error);
      }
    }

    const newGuestToken = get(resolvedGuestToken, 'token')?.tokenString;
    const data = await sendCallback(
      isNil(newGuestToken)
        ? {
            method: 'post',
            data: { emailAddress: values.cartEmail }
          }
        : {
            method: 'post',
            data: { emailAddress: values.cartEmail },
            headers: { [cartGuestTokenHeaderName]: newGuestToken }
          }
    );

    if (!isEmpty(data)) {
      setCart(data);
    }

    finishEditing();
  } catch (err) {
    const fieldErrors = err.fieldErrors;
    if (isEmpty(fieldErrors)) {
      logError({
        ...err,
        when: 'submitting update contact info request'
      });
      return;
    }
    const errors = mapValues(fieldErrors, errorArray => errorArray[0].reason);
    actions.setErrors({ cartEmail: errors['emailAddress'] });
  } finally {
    actions.setSubmitting(false);
  }
};

CartEmailForm.propTypes = {
  /** Whether the form is disabled */
  disabled: PropTypes.bool,
  /** Method to call after submission is completed or on cancel */
  finishEditing: PropTypes.func,
  /** Label for the submit form button. Can be a string or message descriptor */
  submitLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      defaultMessage: PropTypes.string.isRequired
    })
  ]).isRequired
};

export default CartEmailForm;
export { CartEmailForm };
