/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect } from 'react';
import { isEmpty, find, intersection, size, values } from 'lodash';

import { ConfigurableItemContext, PdpContext } from 'app/product/contexts';

import { AlternateAssets, PrimaryAsset } from './components';
import { AssetsHelmet } from './helpers';

/**
 * Render component for a PDP's asset.
 *
 * @visibleName Product Detail Page: Asset
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const Assets = () => {
  const { product } = React.useContext(PdpContext);
  const { activeOption } = React.useContext(ConfigurableItemContext);
  const { primaryAsset, assets } = product;
  const [activeAsset, setActiveAsset] = React.useState(primaryAsset);

  useEffect(() => {
    if (isEmpty(activeOption)) {
      setActiveAsset(primaryAsset);
      return;
    }

    const { optionValues } = activeOption;
    const valuesOnly = values(optionValues);
    // match by tags to values
    const assetForOption = find(assets, asset => {
      const { tags = [] } = asset;
      return !isEmpty(intersection(valuesOnly, tags));
    });

    if (isEmpty(assetForOption)) {
      setActiveAsset(primaryAsset);
      return;
    }

    setActiveAsset(assetForOption);
  }, [activeOption, assets, primaryAsset]);

  return (
    <section className="py-4 sm:py-0 sm:pr-8 sm:flex-shrink sm:basis-1/2 md:basis-2/5 lg:basis-1/3 lg:pr-12">
      <AssetsHelmet {...primaryAsset} />
      <PrimaryAsset {...activeAsset} />
      {size(assets) > 1 && (
        <AlternateAssets
          activeAsset={activeAsset ? activeAsset.id : 'NOT_FOUND'}
          setActiveAsset={setActiveAsset}
          assets={assets}
        />
      )}
    </section>
  );
};

export default Assets;
export { Assets };
