/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
class HiddenIframe {
  constructor(options) {
    this.url = options.url;
    this.id = options.id;
    this.callback = options.callback;
    this.validateEvent = options.validateEvent || (() => true);
    this.timeout = options.timeout || 5000; // default to 5 seconds
    this.timeoutCallback = options.timeoutCallback || null;
    this.iframe = null;
    this.timeoutID = null;
    this.window = options.window || window;

    this.initialize = this.initialize.bind(this);
    this.eventListener = this.eventListener.bind(this);
    this.handleTimeout = this.handleTimeout.bind(this);
    this.destroy = this.destroy.bind(this);
  }

  initialize() {
    this.iframe = this.window.document.createElement('iframe');
    this.iframe.style.display = 'none';
    this.iframe.id = this.id;

    this.window.addEventListener('message', this.eventListener, false);

    this.window.document.body.appendChild(this.iframe);

    this.iframe.src = this.url;

    this.timeoutID = setTimeout(() => {
      this.handleTimeout();
    }, this.timeout);
  }

  eventListener(event) {
    if (!this.validateEvent(event)) {
      return;
    }

    this.destroy();
    this.callback(event);
  }

  handleTimeout() {
    this.destroy();
    if (this.timeoutCallback != null) {
      this.timeoutCallback();
    }
  }

  destroy() {
    clearTimeout(this.timeoutID);

    this.window.removeEventListener('message', this.eventListener, false);
    this.window.document.body.removeChild(this.iframe);
  }
}

export default HiddenIframe;
