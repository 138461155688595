/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';
import { assign } from 'lodash';

const reasons = defineMessages({
  removed: {
    id: 'NotFound.reasons.removed',
    defaultMessage: 'The page no longer exists'
  },
  error: {
    id: 'NotFound.reasons.error',
    defaultMessage: 'There was an error'
  },
  neverExisted: {
    id: 'NotFound.reasons.neverExisted',
    defaultMessage: 'It never existed'
  },
  other: {
    id: 'NotFound.reasons.other',
    defaultMessage: 'You just like our 404/error page!'
  }
});

const returnHome = defineMessages({
  preamble: {
    id: 'NotFound.returnHome.preamble',
    defaultMessage: 'Please visit our'
  },
  homepage: {
    id: 'NotFound.returnHome.homepage',
    defaultMessage: 'homepage'
  },
  postamble: {
    id: 'NotFound.returnHome.postamble',
    defaultMessage: "to find what you're looking for!"
  }
});

const mainMessages = defineMessages({
  title: {
    id: 'NotFound.title',
    defaultMessage: 'The page you seek is'
  },
  titleMessage: {
    id: 'NotFound.titleMessage',
    defaultMessage: 'not found!'
  },
  subtitle: {
    id: 'NotFound.subtitle',
    defaultMessage: 'This could be for several reasons...'
  }
});

export default assign({ reasons, returnHome }, mainMessages);
