/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';
import { get, merge } from 'lodash';

import { useCartInfo, useModifyCartRequest } from 'app/common/hooks';
import { getCartHeaders } from 'app/common/utils/RequestUtils';

function useCheckoutApi(
  additionalContextPath = '',
  sendImmediate = false,
  rejectOnError = true,
  config = undefined,
  cart
) {
  const { baseUrl } = useCartInfo().checkoutOperations;
  const url = `${baseUrl}${additionalContextPath}`;

  const cartHeaders = getCartHeaders(get(cart, 'version'));

  const finalConfig = useMemo(() => {
    return merge(
      {},
      {
        headers: cartHeaders
      },
      config
    );
  }, [config, cartHeaders]);

  return useModifyCartRequest(url, finalConfig, sendImmediate, rejectOnError);
}

export default useCheckoutApi;
