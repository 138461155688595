/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  nameRequired: {
    id: 'Auth.Register.nameRequired',
    defaultMessage: 'Name is required'
  },
  firstNameRequired: {
    id: 'Auth.Register.firstNameRequired',
    defaultMessage: 'First Name is required'
  },
  lastNameRequired: {
    id: 'Auth.Register.lastNameRequired',
    defaultMessage: 'Last Name is required'
  },
  emailRequired: {
    id: 'Auth.Register.emailRequired',
    defaultMessage: 'Email is required'
  },
  addressLineOneRequired: {
    id: 'Auth.Register.addressLineOneRequired',
    defaultMessage: 'Address Line 1 is required'
  },
  stateRequired: {
    id: 'Auth.Register.stateRequired',
    defaultMessage: 'State is required'
  },
  countryRequired: {
    id: 'Auth.Register.countryRequired',
    defaultMessage: 'Country is required'
  },
  postalRequired: {
    id: 'Auth.Register.postalRequired',
    defaultMessage: 'Zip is required'
  },
  cityRequired: {
    id: 'Auth.Register.cityRequired',
    defaultMessage: 'City is required'
  },
  phoneNumberRequired: {
    id: 'Auth.Register.phoneNumberRequired',
    defaultMessage: 'Phone number is required'
  },
  passwordRequired: {
    id: 'Auth.Register.passwordRequired',
    defaultMessage: 'Password is required'
  },
  passwordLength: {
    id: 'Auth.Register.password.length',
    defaultMessage: 'At least 8 characters'
  },
  passwordUppercase: {
    id: 'Auth.Register.password.uppercase',
    defaultMessage: 'At least 1 uppercase'
  },
  passwordLowercase: {
    id: 'Auth.Register.password.lowercase',
    defaultMessage: 'At least 1 lowercase'
  },
  passwordDigit: {
    id: 'Auth.Register.password.digit',
    defaultMessage: 'At least 1 digit'
  },
  passwordSymbol: {
    id: 'Auth.Register.password.symbol',
    defaultMessage: 'At least 1 symbol'
  },
  passwordWhitespace: {
    id: 'Auth.Register.password.whitespace',
    defaultMessage: 'No whitespace'
  },
  validEmail: {
    id: 'Auth.Register.validEmail',
    defaultMessage: 'Must be a valid Email'
  },
  companyNameRequired: {
    id: 'Auth.Register.companyNameRequired',
    defaultMessage:
      'Company name is required: Please check the box below or enter “N/A” '
  }
});
