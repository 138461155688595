import React from 'react';
import TagManager from 'app/common/tagmanager';
import { get, join } from 'lodash';

const useGtmFulfillmentOptions = function (active, items) {
  const gtmPushed = React.useRef(false);
  React.useEffect(() => {
    if (gtmPushed.current || !active) {
      return;
    }

    TagManager.dataLayer({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: 1
          },
          products: items.map((item, i) => {
            const {
              quantity,
              sku,
              unitPrice,
              attributes: { categoryNames, productDescription }
            } = item;
            const brand = get(item, 'attributes.productBrand.value');

            return {
              brand,
              category: join(categoryNames, ', '),
              id: sku,
              name: productDescription,
              position: i,
              price: get(unitPrice, 'amount', 0.0),
              quantity
            };
          })
        }
      }
    });
    gtmPushed.current = true;
  }, [active, items]);
};

export default useGtmFulfillmentOptions;
