/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { findIndex, get, isEmpty, isNil, join, toPairs } from 'lodash';
import TagManager from 'app/common/tagmanager';

import {
  ItemQuantitySelect,
  LoadingIcon,
  PrimaryButton
} from 'app/common/components';
import {
  CartContext,
  CurrencyContext,
  LocaleContext
} from 'app/common/contexts';
import {
  useAddToCartApi,
  useCurrentApplication,
  useCurrentTenant,
  useFormatMessage,
  useProductUrl
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import messages from 'app/product/components/DefaultPDP/components/MerchandisingInfo/MerchandisingInfo.messages';

const MiniProductAddToCart = props => {
  const { product, sendProductClick, breadcrumbs } = props;
  const formatMessage = useFormatMessage();
  const url = useProductUrl(product.uri);
  const { currentLocale: locale } = React.useContext(LocaleContext);
  const applicationId = get(useCurrentApplication(), 'id');
  const tenantId = get(useCurrentTenant(), 'id');
  const { setCart, cart, toggleMinicartOpen, isLocked, setIsLocked } =
    React.useContext(CartContext);
  const [addToCartErrors, setAddToCartErrors] = React.useState(undefined);
  const [quantity, setQuantity] = React.useState(1);
  const { sendCallback } = useAddToCartApi(null, null, false, true);
  const hasNoVariants = isEmpty(product.variants);
  const { currentCurrency: currency } = React.useContext(CurrencyContext);

  return (
    <Formik
      initialValues={{}}
      onSubmit={async (values, actions) => {
        if (isLocked) {
          return;
        }

        const item = product;
        const catalogId = get(item, 'contextState.catalog.contextId');

        const addItemRequest = {
          catalogId,
          currency,
          dependentCartItems: [],
          itemAttributes: undefined,
          locale,
          productId: item.id,
          quantity,
          applicationId,
          tenantId,
          sku: item.sku,
          variantId: undefined
        };

        try {
          setIsLocked(true);
          const response = await sendCallback({
            data:
              isNil(cart) || isEmpty(cart)
                ? { addItemRequest, priceCartRequest: { locale, currency } }
                : addItemRequest,
            params: { price: true }
          });

          if (!isEmpty(response)) {
            const cartItemPos = findIndex(response.cartItems, [
              'sku',
              item.sku
            ]);
            const cartItem = get(response, `cartItems[${cartItemPos}]`, {});

            TagManager.dataLayer({
              event: 'addToCart',
              ecommerce: {
                currencyCode: currency,
                add: {
                  products: [
                    {
                      brand: get(item, 'attributes.BRAND.value'),
                      category: join(
                        get(cartItem, 'attributes.categoryNames', []),
                        ', '
                      ),
                      id: cartItem.sku,
                      name: item.description,
                      position: cartItemPos,
                      price: get(cartItem, 'unitPrice.amount', 0.0),
                      quantity
                    }
                  ]
                }
              }
            });

            setCart(response);
            toggleMinicartOpen(true);
          }

          setAddToCartErrors(undefined);

          return response;
        } catch (exception) {
          if (isEmpty(exception)) {
            return [];
          }

          logError({
            ...exception,
            when: `adding ${product.id} to cart`
          });

          if (exception.type === 'SIMILAR_ITEM_ALREADY_ADDED_ERROR') {
            setAddToCartErrors({
              globalConfigErrors: [
                {
                  errorCode: exception.statusCode,
                  errorMessage: formatMessage(messages.duplicateItemError)
                }
              ]
            });
            return [];
          }

          if (exception.type !== 'ADD_TO_CART') {
            setAddToCartErrors({
              globalConfigErrors: [
                {
                  errorCode: exception.statusCode,
                  errorMessage: formatMessage(messages.addToCartError)
                }
              ]
            });
            return [];
          }

          // handle add to cart error
          const {
            attributeConfigErrors,
            dependentItemConfigErrors,
            globalConfigErrors
          } = exception;
          setAddToCartErrors({
            attributeConfigErrors,
            dependentItemConfigErrors,
            globalConfigErrors
          });

          if (!isEmpty(exception.attributeConfigErrors)) {
            actions.setErrors(
              toPairs(exception.attributeConfigErrors).reduce(
                (acc, [attributeName, errors]) => {
                  acc[attributeName] = errors[0].errorMessage;
                  return acc;
                },
                {}
              )
            );
          }

          return [];
        } finally {
          actions.setSubmitting(false);
          setIsLocked(false);
        }
      }}
      validateOnBlur={true}
      validateOnChange={false}
      enableReinitialize={true}
    >
      {({ isSubmitting }) => (
        <Form className="relative mt-4">
          <div
            className={classNames('flex justify-end', {
              'opacity-0':
                !isEmpty(addToCartErrors) &&
                !isEmpty(addToCartErrors.globalConfigErrors),
              hidden: !hasNoVariants
            })}
          >
            <ItemQuantitySelect
              className="basis-1/4 mr-2"
              quantity={quantity}
              setQuantity={setQuantity}
            />
            <PrimaryButton
              className="relative"
              type="submit"
              disabled={isSubmitting || isLocked}
            >
              <div className={classNames({ 'text-transparent': isSubmitting })}>
                {formatMessage(messages.addToCartLabel)}
              </div>
              {isSubmitting && (
                <div className="absolute inset-0 flex justify-center items-center">
                  <LoadingIcon className="text-blue-500" />
                </div>
              )}
            </PrimaryButton>
          </div>
          <div
            className={classNames('flex justify-center', {
              'opacity-0':
                !isEmpty(addToCartErrors) &&
                !isEmpty(addToCartErrors.globalConfigErrors),
              hidden: hasNoVariants
            })}
          >
            <PrimaryButton
              className="relative w-full"
              type="button"
              to={location => ({
                ...location,
                pathname: url,
                state: {
                  ...get(location, 'state', {}),
                  breadcrumbs: breadcrumbs.concat([{ label: product.name }])
                }
              })}
              onClick={() => {
                sendProductClick();
              }}
            >
              <div>{formatMessage(messages.selectOptionLabel)}</div>
            </PrimaryButton>
          </div>
          {!isEmpty(addToCartErrors) &&
            !isEmpty(addToCartErrors.globalConfigErrors) && (
              <ul className="absolute top-0 left-0 bottom-0 right-0 flex flex-col items-center justify-center p-1 text-sm leading-tight">
                {addToCartErrors.globalConfigErrors.map(
                  ({ errorCode, errorMessage }) => (
                    <li className="mb-2 text-red-600 last:mb-0" key={errorCode}>
                      {errorMessage}
                    </li>
                  )
                )}
              </ul>
            )}
        </Form>
      )}
    </Formik>
  );
};

export default MiniProductAddToCart;
export { MiniProductAddToCart };
