/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { Icon } from 'app/common/components';

import { FormSelect } from './components';

/**
 * Render component for a form select field.
 */
const SelectField = ({
  field,
  form = { errors: [], touched: [] },
  value,
  className,
  valueOptions = [],
  placeholderLabel,
  resetLabel,
  ...props
}) => {
  return (
    <div className="relative w-full lg:w-auto">
      <FormSelect
        className="pr-8 hover:cursor-pointer"
        hasErrors={!isEmpty(get(form.errors, field.name))}
        touched={!!get(form.touched, field.name)}
        value={value}
        valueOptions={valueOptions}
        placeholderLabel={placeholderLabel}
        resetLabel={resetLabel}
        {...field}
        {...props}
      />
      <div className="absolute flex items-center justify-center inset-y-0 right-0 w-8 pt-px pl-px pointer-events-none lg:w-10">
        <Icon name="angle-down" size="sm" />
      </div>
    </div>
  );
};

SelectField.propTypes = {
  className: PropTypes.string,
  field: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string
  }).isRequired,
  form: PropTypes.object,
  placeholderLabel: PropTypes.string,
  resetLabel: PropTypes.string,
  valueOptions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          defaultMessage: PropTypes.string.isRequired
        })
      ]),
      value: PropTypes.string.isRequired
    })
  ).isRequired
};

export default SelectField;
export { SelectField, FormSelect };
