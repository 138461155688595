/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { v4 as uuid } from 'uuid';

import { Environment, LocalStorageCache } from 'app/common/services';

const AuthStateCache = new LocalStorageCache('AuthStateCache', {
  ttl: Environment.getInteger('auth.state.cache.ttl', 0)
});

const generateUniqueState = function (scope, state = {}) {
  return encode({
    ...state,
    scope,
    referrer: window.location.pathname + window.location.search,
    nonce: uuid()
  });
};

const encode = function (stateObject) {
  return btoa(JSON.stringify(stateObject));
};

const decode = function (state) {
  return JSON.parse(atob(state));
};

const validateGlobalState = function (state) {
  try {
    const { scope } = decode(state);

    if (AuthStateCache.get(scope) === undefined) {
      return false;
    }

    return AuthStateCache.get(scope) === state;
  } catch (parseError) {
    return false;
  }
};

const putGlobalState = function (scope, state = {}) {
  return AuthStateCache.put(scope, generateUniqueState(scope, state)).get(
    scope
  );
};

const deleteGlobalState = function (state) {
  try {
    const decodedState = decode(state);
    return AuthStateCache.delete(decodedState.scope);
  } catch (e) {
    return undefined;
  }
};

const authStateExports = {
  encode,
  decode,
  deleteGlobalState,
  putGlobalState,
  validateGlobalState,
  generateUniqueState,
  AuthStateCache
};
export default authStateExports;
