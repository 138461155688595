import messages from './NearestAvailableButton.messages';
import React from 'react';
import { useFormatMessage } from 'app/common/hooks';

/**
 * Returns a geolocation error component.
 *
 * @param {string} GeoLocationError.code - The error code indicating the type of error.
 *
 * @returns {JSX.Element} - The corresponding error message element.
 */
const GeoLocationError = ({ code }) => {
  const formatMessage = useFormatMessage();
  switch (code) {
    case 'GEOLOCATION_PERMISSION_DENIED':
      return (
        <span>{formatMessage(messages.modalGeolocationPermissionError)}</span>
      );
    case 'GEOLOCATION_POSITION_UNAVAILABLE':
      return (
        <span>{formatMessage(messages.modalGeolocationUnavailableError)}</span>
      );
    case 'GEOLOCATION_TIMEOUT':
      return (
        <span>{formatMessage(messages.modalGeolocationTimeoutError)}</span>
      );
    default:
      return <span>{formatMessage(messages.modalGenericError)}</span>;
  }
};

export { GeoLocationError };
