/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    id: 'MyAccount.Lists.AddToListModal.cancel',
    defaultMessage: 'Cancel'
  },
  submit: {
    id: 'MyAccount.Lists.AddToListModal.submit',
    defaultMessage: 'Save'
  },
  description: {
    id: 'MyAccount.Lists.AddToListModal.label',
    defaultMessage: 'Create a New List by typing in your list name'
  },
  title: {
    id: 'MyAccount.Lists.AddToListModal.title',
    defaultMessage: 'Create List'
  },
  nameRequired: {
    id: 'MyAccount.Lists.AddToListModal.nameRequired',
    defaultMessage: 'Name is required'
  }
});
