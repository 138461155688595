/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';
import { merge } from 'lodash';

import useRestApi from '../../useRestApi';
import useCustomerService from '../useCustomerService';

/**
 * Hook to use to write a customer's addresses.
 *
 * @param {{
 *   defaultBillingAddress: boolean,
 *   defaultShippingAddress: boolean,
 *   name: string,
 *   addressLine1: string,
 *   addressLine2: string,
 *   addressLine3: string,
 *   city: string,
 *   stateProvinceRegion: string,
 *   postalCode: string,
 *   country: string,
 *   phonePrimary: { phoneNumber: string },
 *   phoneSecondary: { phoneNumber: string }|undefined,
 *   phoneFax: { phoneNumber: string }|undefined,
 *   fullName: string
 * }} customerAddress - The new customer address payload
 * @param  {Object}  [config] - Object containing additional request config such as
 *     the request params or request method
 * @param  {Boolean} [sendImmediate=false] - Whether to send the request immediately upon
 *     using the hook. If false, then the caller is responsible for triggering
 *     the `sendCallback`. Defaults to `false`.
 * @param {boolean} [rejectOnError=false] - Whether to reject the "send"
 *     promise on error response. Either way, the error will be part of a
 *     separate dispatch event.
 *
 * @return {{error: bool, exception: Object, loading: bool, response: Object, sendCallback: function}}
 */
function useWriteCustomerAddressApi(
  customerAddress,
  config,
  sendImmediate = false,
  rejectOnError = true
) {
  const { baseUrl, addressesBasePath } = useCustomerService();

  const fetchUrl = baseUrl + addressesBasePath + `/${customerAddress.id}`;
  const finalConfig = useMemo(() => {
    return merge(
      {},
      {
        method: 'put',
        data: customerAddress
      },
      config
    );
  }, [customerAddress, config]);

  return useRestApi(fetchUrl, finalConfig, sendImmediate, rejectOnError);
}

export default useWriteCustomerAddressApi;
