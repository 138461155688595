import {
  useAddToCartApi,
  useApplicationParameter,
  useCartInfo,
  useCurrentApplication,
  useCurrentTenant,
  useModifyCartRequest
} from 'app/common/hooks';
import React from 'react';
import {
  CartContext,
  CurrencyContext,
  LocaleContext
} from 'app/common/contexts';
import { find, get, isEmpty, isNil, join, map } from 'lodash';
import { Environment } from 'app/common/services';
import TagManager from 'app/common/tagmanager';

function useHandleSwitch(product, handleError) {
  const {
    operations: { baseUrl, bulkItemsContextPath }
  } = useCartInfo();
  const { sendCallback: removeItems } = useModifyCartRequest(
    `${baseUrl}${bulkItemsContextPath}`,
    undefined,
    false,
    true
  );
  const { currentLocale: locale } = React.useContext(LocaleContext);
  const { cart: currentCart, setCart } = React.useContext(CartContext);
  const currentApplication = useCurrentApplication();
  const applicationId = get(currentApplication, 'id');
  const applicationName = get(currentApplication, 'displayName');
  const tenantId = get(useCurrentTenant(), 'id');
  const { sendCallback: addToCart } = useAddToCartApi(null, null, false, true);
  const [, setApplicationParam] = useApplicationParameter();
  const { currentCurrency: currency } = React.useContext(CurrencyContext);
  return React.useCallback(
    async ({ inventoryLocation, itemsToRemove }) => {
      const item = product;
      const catalogId = get(item, 'contextState.catalog.contextId');

      let cart = currentCart;

      // check if it's already in the cart, if so, skip add request
      if (
        !!currentCart &&
        !!find(currentCart.cartItems, cartItem => cartItem.sku === item.sku)
      ) {
        cart = currentCart;
      } else {
        try {
          const addItemRequest = {
            catalogId,
            currency,
            dependentCartItems: [],
            itemAttributes: undefined,
            locale,
            productId: item.id,
            quantity: 1,
            applicationId,
            tenantId,
            sku: item.sku,
            variantId: undefined
          };

          cart = await addToCart({
            data:
              isNil(cart) || isEmpty(cart)
                ? { addItemRequest, priceCartRequest: { locale, currency } }
                : { ...addItemRequest },
            params: { price: true }
          });
          setCart(cart);
        } catch (err) {
          // can ignore duplicate item error
          if (err.type !== 'SIMILAR_ITEM_ALREADY_ADDED_ERROR') {
            handleError(err, 'SWITCH_LOCATIONS_ADD_TO_CART');
            return;
          }
        }
      }

      if (!isEmpty(itemsToRemove)) {
        try {
          const ids = map(itemsToRemove, ({ id }) => id);
          const cartVersionHeaderName = Environment.get(
            'cart.version.header',
            'X-Cart-Version'
          );
          const config = {
            method: 'del',
            params: { price: true, cartItemIds: join(ids) },
            headers: {
              [cartVersionHeaderName]: cart.version
            }
          };

          await removeItems(config);
        } catch (err) {
          handleError(err, 'SWITCH_LOCATIONS_REMOVE_UNAVAILABLE');
          return;
        }
      }

      TagManager.dataLayer({
        event: 'partAvailabilityLocationSwitch',
        locationSwitch: {
          from: applicationName,
          to: inventoryLocation.name,
          for: item.sku
        }
      });

      setApplicationParam(inventoryLocation.locationNumber);
    },
    [
      addToCart,
      applicationId,
      applicationName,
      currentCart,
      handleError,
      locale,
      product,
      removeItems,
      tenantId,
      setCart,
      setApplicationParam,
      currency
    ]
  );
}

export { useHandleSwitch };
