/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useContext, useMemo } from 'react';
import { get } from 'lodash';

import { AuthContext } from 'app/auth/contexts';
import { Environment } from 'app/common/services';

function usePaymentInfo() {
  const { user, isAuthenticated } = useContext(AuthContext);
  const customerId = get(user, 'serviceId');

  return useMemo(
    () => ({
      paymentTransaction: {
        baseUrl: Environment.get(
          'PAYMENT_TRANSACTION_BASE_ENDPOINT',
          '/payments/customer-payments'
        )
      },
      customer: {
        baseUrl: Environment.get(
          'PAYMENT_BASE_ENDPOINT',
          '/customer/customers'
        ),
        paymentAccountsBasePath: Environment.get(
          'PAYMENT_ACCOUNT_ENDPOINT',
          `${isAuthenticated ? `/${customerId}` : ''}/payment-accounts`
        )
      },
      merchant: {
        properties: {
          baseUrl: Environment.get(
            'PAYMENT_MERCHANT_PROPERTIES_BASE_ENDPOINT',
            '/order-operations'
          ),
          paypalAccountContextPath: Environment.get(
            'PAYMENT_MERCHANT_PROPERTIES_PAYPAL_CHECKOUT_URL',
            '/paypal/referrals/shop'
          )
        }
      }
    }),
    [isAuthenticated, customerId]
  );
}

export default usePaymentInfo;
