/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'app/common/components';
import { useProductClick } from 'app/common/hooks';

import messages from '../../DialogItem.messages';

/**
 * Render component for cart item's header info such as name.
 */
const ItemHeader = ({ name, url, gtmItem }) => {
  const sendProductClick = useProductClick(gtmItem, 'MiniCart Dropdown');
  return (
    <header>
      <h5>
        <Button
          className="text-gray-700 font-medium"
          to={location => ({
            ...location,
            pathname: url,
            state: {
              ...get(location, 'state', {}),
              breadcrumbs: [
                {
                  uri: '/cart',
                  label: messages.cartBreadcrumbLabel
                },
                {
                  label: name
                }
              ]
            }
          })}
          onClick={() => {
            sendProductClick();
          }}
        >
          {name}
        </Button>
      </h5>
    </header>
  );
};

ItemHeader.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default ItemHeader;
export { ItemHeader };
