/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useOnEventOutside, useToggle } from 'app/common/hooks';

/**
 * Render component for a menu dropdown.
 *
 * @todo Test deeply nested submenus: https://github.com/BroadleafCommerce/MicroPM/issues/1020
 */
const MenuDropdown = props => {
  const { children, expanded, href, label } = props;
  const [isActive, toggleActive] = useToggle();
  const onClickOutside = React.useCallback(() => {
    toggleActive(false);
  }, [toggleActive]);
  const containerRef = useOnEventOutside(onClickOutside);

  return (
    <div className="group relative flex flex-col" ref={containerRef}>
      <div className="flex items-center h-full mt-px border-b-2 border-transparent group-hover:text-gray-900 group-hover:border-gray-900">
        <a
          aria-expanded={isActive}
          aria-haspopup="true"
          className="flex items-center px-4 py-2 h-full font-medium text-gray-700 lg:py-0"
          onClick={toggleActive}
          href={href}
        >
          {label}
        </a>
      </div>
      <div className="relative ml-4 lg:ml-0">
        <ul
          className={cx('whitespace-no-wrap', {
            'absolute hidden py-2 rounded-b bg-white shadow group-hover:block':
              !expanded
          })}
        >
          {children}
        </ul>
      </div>
    </div>
  );
};

MenuDropdown.defaultProps = {
  href: '#'
};

MenuDropdown.propTypes = {
  /** Content to render within the link */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.element
  ]),
  /** Destination of link */
  href: PropTypes.string,
  /** Label for the dropdown trigger */
  label: PropTypes.string.isRequired
};

export default MenuDropdown;
export { MenuDropdown };
