/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { values } from 'lodash';
import PropTypes from 'prop-types';

import { AssetType } from 'app/common/constants';
import { addParams } from 'app/common/utils/PathUtils';

/**
 * Render component for Alternate Assets.
 *
 * @visibleName Product Detail Page: Assets: Alternates: Alternate
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const AlternateAsset = ({ asset, isActive = false, setActiveAsset }) => {
  const { altText, contentUrl: src, id, title } = asset;
  return (
    <li
      aria-hidden={isActive}
      id={`asset-${id}`}
      className={classNames(
        'flex-shrink basis-1/3 p-1 md:basis-1/5 lg:basis-1/6',
        {
          hidden: !!isActive
        }
      )}
    >
      <button
        className="w-full rounded focus:shadow-outline focus:outline-none active:shadow-outline"
        onClick={() => setActiveAsset(asset)}
        type="button"
      >
        <img
          alt={altText}
          className="w-h-auto w-full rounded pdp-img-alt"
          src={`${addParams(src, { thumbnail: true })}`}
          title={title}
          height="56"
          width="56"
        />
      </button>
    </li>
  );
};

AlternateAsset.propTypes = {
  /** The Asset DTO containing the altText, src, etc. */
  asset: PropTypes.shape({
    altText: PropTypes.string,
    contentUrl: PropTypes.string.isRequired,
    /** HTML to embed in place of having a source URL */
    embedCode: PropTypes.string,
    id: PropTypes.string.isRequired,
    productId: PropTypes.string,
    primary: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.oneOf(values(AssetType))
  }).isRequired,
  /** Whether this Asset is currently being displayed like it's the primary */
  isActive: PropTypes.bool,
  /** Method to set this Asset as the active one */
  setActiveAsset: PropTypes.func.isRequired
};

export default AlternateAsset;
export { AlternateAsset };
