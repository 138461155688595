import {
  useCurrentApplication,
  useRestApi,
  useTenantInfo
} from 'app/common/hooks';
import { get, isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { CustomerContext } from 'app/common/contexts';
import { logError } from 'app/common/utils/ApiErrorUtils';

function useFetchPreferredLocation(
  setPreferredApplication,
  setLoadingPreferredApplication,
  loadingPreferredApplication
) {
  const application = useCurrentApplication();
  const applicationIdentifier = get(application, 'identifierValue', undefined);
  const { customer } = useContext(CustomerContext);
  const [needsFetch, setNeedsFetch] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const preferredLocation = get(customer, 'preferredLocation');

  const { loading, response, error, exception, fetchPreferredLocation } =
    useFetchPreferredApi();

  useEffect(() => {
    if (customer && isEmpty(preferredLocation)) {
      setLoadingPreferredApplication(false);
    } else if (applicationIdentifier === preferredLocation) {
      setPreferredApplication(application);
      setLoadingPreferredApplication(false);
    } else if (!isEmpty(preferredLocation)) {
      setNeedsFetch(true);
    }
  }, [
    setNeedsFetch,
    applicationIdentifier,
    setLoadingPreferredApplication,
    setPreferredApplication,
    application,
    customer,
    preferredLocation
  ]);

  useEffect(() => {
    if (!needsFetch) {
      return;
    }
    if (loadingPreferredApplication) {
      try {
        fetchPreferredLocation({
          params: {
            size: 1,
            offset: 0,
            forward: true,
            cq: `identifierValue==${preferredLocation}`
          }
        });
        setNeedsFetch(false);
      } finally {
        setHasFetched(true);
      }
    }
  }, [
    needsFetch,
    fetchPreferredLocation,
    setNeedsFetch,
    loadingPreferredApplication,
    preferredLocation,
    loading,
    response,
    setPreferredApplication,
    setHasFetched
  ]);

  useEffect(() => {
    if (!hasFetched) {
      return;
    }
    if (!error) {
      setPreferredApplication(response?.content?.[0]);
      setLoadingPreferredApplication(false);
    }
  }, [
    error,
    setLoadingPreferredApplication,
    response,
    setPreferredApplication,
    hasFetched
  ]);

  useEffect(() => {
    if (error) {
      logError({
        ...exception,
        when: `when fetching preferred location ${preferredLocation}`
      });
      setLoadingPreferredApplication(false);
    }
  }, [error, preferredLocation, exception, setLoadingPreferredApplication]);
}

function useFetchPreferredApi() {
  const { baseUrl, applicationUri } = useTenantInfo();
  const {
    error,
    exception,
    loading,
    response,
    sendCallback: fetchPreferredLocation
  } = useRestApi(`${baseUrl}${applicationUri}`, undefined, false);
  return {
    loading,
    response,
    error,
    exception,
    fetchPreferredLocation
  };
}

export { useFetchPreferredLocation };
