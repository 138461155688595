/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  en: {
    id: 'locales.en',
    defaultMessage: 'English'
  },
  'en-US': {
    id: 'locales.en-US',
    defaultMessage: 'English (US)'
  },
  'en-GB': {
    id: 'locales.en-GB',
    defaultMessage: 'English (Great Britain)'
  },
  'en-CA': {
    id: 'locales.en-CA',
    defaultMessage: 'English (Canada)'
  },
  es: {
    id: 'locales.es',
    defaultMessage: 'Español'
  },
  'es-ES': {
    id: 'locales.es-ES',
    defaultMessage: 'Español (España)'
  },
  'es-MX': {
    id: 'locales.es-MX',
    defaultMessage: 'Español (México)'
  },
  fr: {
    id: 'locales.fr',
    defaultMessage: 'Française'
  },
  'fr-FR': {
    id: 'locales.fr-FR',
    defaultMessage: 'Française (France)'
  },
  'fr-CA': {
    id: 'locales.fr-CA',
    defaultMessage: 'Française (Canada)'
  },
  de: {
    id: 'locales.de',
    defaultMessage: 'Deutsch'
  },
  it: {
    id: 'locales.it',
    defaultMessage: 'Italiano'
  },
  ja: {
    id: 'locales.ja',
    defaultMessage: '日本語'
  },
  ko: {
    id: 'locales.ko',
    defaultMessage: '한국어'
  },
  zh: {
    id: 'locales.zh',
    defaultMessage: '繁体中文'
  },
  'zh-CN': {
    id: 'locales.zh-CN',
    defaultMessage: '简体中文 (中国)'
  },
  'zh-TW': {
    id: 'locales.zh-TW',
    defaultMessage: '繁体中文 (台灣)'
  }
});
