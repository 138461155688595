/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
function useTopBrandState() {
  const findTopBrandForPath = (pathname, topBrands) => {
    if (!topBrands || !pathname.startsWith('/brand')) {
      return undefined;
    }
    return topBrands.filter(b => pathname.startsWith(b.brandPath + '/'))[0];
  };

  const topBrandMatchesPath = (pathName, activeTopBrand) => {
    const isBrandPath = pathName.startsWith('/brand');
    if (!isBrandPath) {
      return false;
    }
    if (isBrandPath && !activeTopBrand) {
      return false;
    }
    return pathName.startsWith(activeTopBrand.brandPath + '/');
  };

  const stripBrandFromPath = pathName => {
    const match = pathName.match(/(\/brand\/[^/]+)(.*)/);
    if (match && match[1] && match[1].startsWith('/brand')) {
      pathName = pathName.replace(match[1], '');
    }
    return pathName;
  };

  const isTopBrandPath = pathName => {
    return pathName.startsWith('/brand');
  };

  const getTopBrandCategoryLink = (categoryLink, pathName) => {
    if (isTopBrandPath(pathName)) {
      return getTopBrandPath(pathName) + categoryLink;
    }
    return categoryLink;
  };

  const getTopBrandPath = pathName => {
    if (!isTopBrandPath(pathName)) {
      return undefined;
    }
    const match = pathName.match(/(\/brand\/[^/]+)(.*)/);
    if (match && match[1] && match[1].startsWith('/brand')) {
      return match[1];
    }
    return undefined;
  };

  return {
    findTopBrandForPath,
    topBrandMatchesPath,
    stripBrandFromPath,
    isTopBrandPath,
    getTopBrandCategoryLink
  };
}

export default useTopBrandState;
