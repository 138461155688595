import React from 'react';
import { map } from 'lodash';
import classNames from 'classnames';

/**
 * @typedef {Object} SuggestionsAutoCompleteProps
 * @property {function} clickOutsideSuggestionsContainerRef The ref to the click outside suggestions container
 * @property {boolean} autoCompleteOpen Is the autocomplete open?
 * @property {Object} autoCompleteResults The autocomplete results
 * @property {function} onSelect The callback to handle address selection. This will receive four arguments: event, address, latitude, longitude
 * @property {function} suggestionContainerRef The ref to the suggestion container
 */

/**
 * Renders location suggestions based on the response from the user's input in {@link LocationQuery}.
 * @param {SuggestionsAutoCompleteProps} props
 */
const SuggestionAutoComplete = ({
  clickOutsideSuggestionsContainerRef,
  autoCompleteOpen,
  autoCompleteResults,
  onSelect
}) => {
  const shouldOpen = autoCompleteResults?.count > 0 && autoCompleteOpen;
  return shouldOpen ? (
    <div
      className={classNames(
        'flex w-9/10 location-autocomplete-container overflow-hidden shadow-lg z-70  shadow-outline',
        document.getElementsByClassName('store-details').length === 0
          ? 'relative'
          : 'absolute'
      )}
      ref={clickOutsideSuggestionsContainerRef}
    >
      <ul
        className={'flex flex-col w-full z-70 p-1 bg-white align-middle-text'}
        style={{ top: 0, left: 0, bottom: 0, right: 0 }}
      >
        {map(autoCompleteResults?.suggestions, (result, index) => {
          return (
            <AddressSuggestion
              result={result}
              key={index}
              handleAddressSelect={onSelect}
            />
          );
        })}
      </ul>
    </div>
  ) : null;
};

/**
 * Renders a single address suggestion in the {@link SuggestionAutoComplete} component.
 * @param result
 * @param dropdownProps
 * @returns {Element}
 * @constructor
 */
const AddressSuggestion = ({ result, handleAddressSelect }) => {
  const { suggestion, latLong, key } = result;
  const { latitude, longitude } = latLong;
  return (
    <li className={'flex p-2 suggested-address'} key={key}>
      <button
        tabIndex={0}
        aria-label={suggestion}
        onClick={e => handleAddressSelect(e, suggestion, latitude, longitude)}
        className={'suggested-address'}
      >
        {suggestion}
      </button>
    </li>
  );
};

export { SuggestionAutoComplete };
