/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { isEmpty } from 'lodash';

import log from 'app/common/utils/Log';

const logger = log.getLogger('common.utils.ApiErrorUtils');

/**
 * Logs an exception in a uniform manner.
 *
 * @param {string}when - Message to use to state when the exception occurred
 *     such as "when fetching products", "when resolving the site", etc.
 * @param {string} exception - Exception class name from an ApiError
 * @param {string} title - Title or message from the exception
 * @param {string} [loggerName] - Name of the logger to use to log
 */
function logError({ when, exception, title, loggerName }) {
  if (!isEmpty(loggerName)) {
    log.getLogger(loggerName);
    return;
  }

  logger.error(
    `${title || 'Error'} occurred ${when}${!!exception ? ': ' + exception : ''}`
  );
}

export { logError };
