/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEqual } from 'lodash';
import { ResolutionContextType } from 'app/common/constants';
import { TenantContext } from 'app/common/contexts';
import { useFormatMessage, useNationalSiteContext } from 'app/common/hooks';

import messages from './LocationNotResolvedCallout.messages';

const LocationNotResolvedCallout = () => {
  const formatMessage = useFormatMessage();
  const { requestedActive, requested } = React.useContext(TenantContext);
  const { resolving, resolutionContext, nationalSiteOrDealerNetworkContext } =
    useNationalSiteContext();
  const shouldNotRender =
    resolving ||
    (isEqual(requestedActive, true) && nationalSiteOrDealerNetworkContext) ||
    resolutionContext !== ResolutionContextType.DEFAULT;
  if (shouldNotRender) {
    return null;
  }
  return (
    <aside className="bg-red-600 text-white">
      <div className="container flex flex-wrap flex-col items-center justify-center px-4 py-2 text-sm leading-loose lg:flex-row">
        <b className="font-normal">
          {formatMessage(messages.message, { requested })}
        </b>
      </div>
    </aside>
  );
};

export default LocationNotResolvedCallout;
export { LocationNotResolvedCallout };
