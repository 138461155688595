/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty, isNil, map } from 'lodash';
import Slider from 'react-slick';

import { Button, Currency, Icon } from 'app/common/components';
import {
  useFormatMessage,
  usePriceProducts,
  useProductClick,
  useProductUrl
} from 'app/common/hooks';
import notFound from 'app/common/img/image-not-found.jpg';
import { addParams } from 'app/common/utils/PathUtils';
import { PdpContext } from 'app/product/contexts';

import messages from './AlternateProducts.messages';
import ItemAvailability from 'app/common/components/ItemAvailability';

const AlternateProducts = () => {
  const formatMessage = useFormatMessage();
  const {
    product: {
      promotionalProducts: { ALTERNATE_PART: alternateProducts }
    }
  } = React.useContext(PdpContext);
  const settings = React.useMemo(
    () => ({
      dots: true,
      infinite: false,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      speed: 500,
      slidesToShow: 6,
      initialSlide: 0,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1279,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 639,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    }),
    []
  );

  const prices = usePriceProducts(
    isEmpty(alternateProducts)
      ? {}
      : alternateProducts.map(ap => ap.relatedProduct)
  );

  if (isEmpty(alternateProducts)) {
    return null;
  }

  return (
    /* need to keep min-w-0 because of a weird thing with flex in chromium */
    <aside className="flex flex-col min-w-0 mx-auto py-8 px-2 w-full sm:px-6">
      <header className="px-2 mb-2 sm:px-3">
        <h3 className="capitalize text-xl font-medium text-gray-700">
          {formatMessage(messages.title)}
        </h3>
      </header>
      <section className="relative">
        <Slider {...settings}>
          {map(sortAlternateProducts(alternateProducts), (promoProduct, i) => (
            <AlternateProduct
              key={i}
              product={promoProduct.relatedProduct}
              position={i}
              pricing={prices.get(promoProduct.relatedProduct.id)}
            />
          ))}
        </Slider>
      </section>
    </aside>
  );
};

const ProParLineCodes = Object.freeze(new Set(['PP', 'P2', 'P3', 'P4']));

/**
 * Sorts the alternate products by whether the lineCode matches one of the
 * Pro-Par brand prefixes then by description.
 *
 * @param {[{ relatedProduct: { description: string, lineCode: string } }]}
 *     products - Products to sort
 *
 * @return {[{ relatedProduct: {} }]} sorted products
 */
function sortAlternateProducts(products) {
  products.sort(({ relatedProduct: a }, { relatedProduct: b }) => {
    if (a.lineCode === b.lineCode || isNil(a.lineCode) || isNil(b.lineCode)) {
      return a.description < b.description
        ? -1
        : a.description > b.description
        ? 1
        : 0;
    }

    const aIsProPar = ProParLineCodes.has(a.lineCode.substring(0, 2));
    const bIsProPar = ProParLineCodes.has(b.lineCode.substring(0, 2));

    if (aIsProPar && bIsProPar) {
      return a.description < b.description
        ? -1
        : a.description > b.description
        ? 1
        : 0;
    }

    if (aIsProPar) {
      return -1;
    }

    if (bIsProPar) {
      return 1;
    }

    return a.description < b.description
      ? -1
      : a.description > b.description
      ? 1
      : 0;
  });

  return products;
}

const AlternateProduct = ({ product, position, pricing }) => {
  const formatMessage = useFormatMessage();
  const { description, name, sku, uri } = product;
  const primaryAsset = get(product, 'primaryAsset', { contentUrl: notFound });
  const url = useProductUrl(uri);
  const gtmItem = React.useMemo(
    () => ({
      brand: get(product, 'attributes.BRAND.value'),
      category: get(product, 'parentCategories[0].name'),
      id: product.sku,
      name: product.description,
      price: get(product, 'priceInfo.price.amount', 0.0),
      position,
      quantity: 1
    }),
    [product, position]
  );
  const { breadcrumbs, product: parentProduct } = React.useContext(PdpContext);
  const sendProductClick = useProductClick(
    gtmItem,
    `${parentProduct.name}'s Alternate Parts`
  );
  const salePriceDetails = getSalePriceDetails(
    pricing,
    get(product, 'priceInfo', {})
  );
  return (
    <div className="basis-full p-2 sm:flex sm:flex-col sm:justify-end sm:p-3">
      <Image
        breadcrumbs={breadcrumbs}
        name={name}
        parentProduct={parentProduct}
        primaryAsset={primaryAsset}
        productUrl={url}
        sendProductClick={sendProductClick}
      />
      <section className="flex flex-col w-3/5 ml-auto sm:flex-grow sm:flex-shrink-0 sm:basis-0 sm:w-full">
        <h4 className="mb-2 font-semibold leading-snug text-base text-gray-700 flex-grow flex-shrink-0 basis-0">
          <Button
            className="text-gray-700 appearance-none group-hover:text-gray-700 focus:text-gray-700 focus:outline-none"
            to={location => ({
              ...location,
              pathname: url,
              state: {
                ...get(location, 'state', {}),
                breadcrumbs: breadcrumbs
                  .slice(0, breadcrumbs.length - 1)
                  .concat([{ uri: url, label: parentProduct.name }])
              }
            })}
            onClick={() => {
              sendProductClick();
            }}
          >
            {description}
          </Button>
        </h4>
        <div className="flex mb-2 leading-snug text-sm">
          <span className="text-gray-600">
            {formatMessage(messages.partNumber)}
          </span>
          <h5 className="ml-1 text-gray-900">{sku}</h5>
        </div>
      </section>
      <section className="flex flex-col w-3/5 ml-auto sm:w-full">
        <div className="flex flex-wrap flex-grow items-center">
          {/*
           * We want to show that there was a discount if there's an override of
           * the original price.
           */}
          {isEmpty(pricing) ? (
            <div className="w-48 mr-2 mt-2 text-2xl bg-gray-200 rounded">
              &nbsp;
            </div>
          ) : salePriceDetails.isSale ? (
            <>
              <Currency
                className="text-red-600"
                amount={pricing.price.amount}
                currency={pricing.price.currency}
              />
              {!isEmpty(salePriceDetails.originalPrice) && (
                <Currency
                  className="ml-2 text-xs text-gray-500 line-through"
                  amount={salePriceDetails.originalPrice.amount}
                  currency={salePriceDetails.originalPrice.currency}
                />
              )}
            </>
          ) : (
            !isEmpty(pricing.price) && (
              <Currency
                className="text-gray-900 font-medium"
                amount={pricing.price.amount}
                currency={pricing.price.currency}
              />
            )
          )}
          <ItemAvailability product={product} />
        </div>
      </section>
      <Placeholder />
    </div>
  );
};

const getSalePriceDetails = function (pricing, priceInfo) {
  if (isEmpty(pricing)) {
    return {
      isSale: false
    };
  }
  let originalPrice = get(pricing, 'originalPrice');
  const basePrice = get(priceInfo, 'price');
  const price = get(pricing, 'price');
  let isSale = false;

  if (!isEmpty(originalPrice)) {
    isSale = get(price, 'amount') < get(originalPrice, 'amount');
  }
  if (!isSale && !isEmpty(basePrice) && !isEmpty(originalPrice)) {
    if (get(basePrice, 'amount') > get(originalPrice, 'amount')) {
      originalPrice = basePrice;
    }
  }
  isSale = get(originalPrice, 'amount') > get(price, 'amount');

  return {
    isSale,
    originalPrice
  };
};

const Image = ({
  breadcrumbs,
  name,
  parentProduct,
  primaryAsset,
  productUrl: url,
  sendProductClick
}) => {
  const [height, setHeight] = React.useState('auto');
  const [width, setWidth] = React.useState('100%');
  const { altText, title, contentUrl: src = notFound } = primaryAsset;

  return (
    <section className="relative float-left w-2/5 pr-4 sm:w-full sm:pr-0">
      <figure className="relative block h-48 mb-4 rounded overflow-hidden lg:h-56">
        <Button
          className="flex items-start justify-center h-full w-full appearance-none focus:outline-none sm:items-center"
          to={location => ({
            ...location,
            pathname: url,
            state: {
              ...get(location, 'state', {}),
              breadcrumbs: breadcrumbs.slice(0, breadcrumbs.length - 1).concat([
                {
                  uri: window.location.pathname,
                  label: parentProduct.name
                },
                { label: name }
              ])
            }
          })}
          onClick={() => {
            sendProductClick();
          }}
        >
          <img
            className="block rounded"
            onLoad={({ target: img }) => {
              const h = img.offsetHeight;
              const w = img.offsetWidth;
              setHeight(w > h ? 'auto' : '100%');
              setWidth(w > h ? '100%' : 'auto');
            }}
            src={`${addParams(src, { browse: true })}`}
            alt={altText || name}
            title={title}
            height="432"
            width="432"
            style={{ height, width }}
          />
        </Button>
      </figure>
    </section>
  );
};

/**
 * Exists to prevent purgecss from purging these styles
 */
const Placeholder = () => <div className="slick-track slick-slide" />;

const NextArrow = props => {
  const { onClick } = props;

  if (onClick === null) {
    return null;
  }

  return (
    <div className="absolute top-0 bottom-0 -right-4 flex items-center sm:-right-8">
      <button
        className="flex items-center text-gray-700 p-2 text-sm appearance-none hover:text-gray-900 focus:text-gray-900 focus:outline-none active:outline-none sm:text-base"
        onClick={onClick}
        type="button"
      >
        <Icon
          className="hidden sm:block"
          name="chevron-right"
          size={Icon.Sizes[1]}
        />
        <Icon className="sm:hidden" name="chevron-right" />
      </button>
    </div>
  );
};

const PrevArrow = props => {
  const { onClick } = props;

  if (onClick === null) {
    return null;
  }

  return (
    <div className="absolute top-0 bottom-0 -left-4 flex items-center sm:-left-8">
      <button
        className="flex items-center text-gray-700 p-2 appearance-none text-sm hover:text-gray-900 focus:text-gray-900 focus:outline-none active:outline-none sm:text-base"
        onClick={onClick}
        type="button"
      >
        <Icon
          className="hidden sm:block"
          name="chevron-left"
          size={Icon.Sizes[1]}
        />
        <Icon className="sm:hidden" name="chevron-left" />
      </button>
    </div>
  );
};

AlternateProducts.isAvailable = () =>
  Math.floor(Math.random() * Math.floor(10)) % 2 === 0;

export default AlternateProducts;
export { AlternateProducts };
