/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  billingAddressLabel: {
    id: 'Checkout.Payment.address.label.readOnly',
    defaultMessage: 'Bill to'
  },
  paymentMethodLabel: {
    id: 'Checkout.Payment.method.label.readOnly',
    defaultMessage: 'Pay using'
  },
  edit: {
    id: 'Checkout.Payment.edit',
    defaultMessage: 'Edit'
  },
  cardNumber: {
    id: 'Checkout.Payment.cardNumber',
    defaultMessage: 'Ending in {number}'
  },
  cardExpiration: {
    id: 'Checkout.Payment.cardExpiration',
    defaultMessage: 'Expires {expMonth}/{expYear}'
  },
  cardIconAlt: {
    id: 'Checkout.Payment.cardIconAlt',
    defaultMessage: 'Card',
    description:
      "If there's no matching card icon for the card number provided, then show this text instead"
  },
  payPal: {
    id: 'Checkout.Payment.paymentMethod.payPal',
    defaultMessage: 'PayPal'
  }
});
