/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import cx from 'classnames';
import { get, isEmpty, map } from 'lodash';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useCatalogInfo, useMenuApi } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import { MenuDropdown, MenuLink } from './components';

/**
 * Render component for the main site nav menu.
 *
 * @visibleName Site Nav Menu
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const Menu = props => {
  const { className, expanded = false, fetchUrl, name } = props;
  const { exception, error, response: menu = {} } = useMenuApi(fetchUrl, name);
  const { categoryInfo, productInfo } = useCatalogInfo();
  const categoryPrefix = get(categoryInfo, 'routeBaseContext');
  const productPrefix = get(productInfo, 'routeBaseContext');

  if (error) {
    logError({ ...exception, when: `fetching Menu ${name}` });
    return null;
  }

  const { submenu = [] } = menu;

  return (
    <div
      className={cx('flex h-full', {
        [className]: !!className
      })}
    >
      {!!submenu.length &&
        map(submenu, menuItem => {
          return Menu.renderMenuItem({
            ...menuItem,
            categoryPrefix,
            expanded,
            productPrefix
          });
        })}
    </div>
  );
};

const SubMenuLink = ({ label, level, href, expanded, external }) => {
  const subMenuClass =
    'flex items-center py-2 px-4 text-gray-700 hover:text-gray-900 hover:bg-gray-100 ' +
    'focus:outline-none focus:shadow-outline lg:focus:shadow-none lg:focus:bg-gray-100';

  if (external) {
    return (
      <a
        href={href}
        target={'_blank'}
        rel={'noopener noreferrer'}
        className={subMenuClass}
      >
        {label}
      </a>
    );
  } else {
    return (
      <NavLink
        key={label + level}
        to={href}
        className={cx(subMenuClass, {
          'pb-2': expanded
        })}
      >
        {label}
      </NavLink>
    );
  }
};

/**
 * Renders the menu item either as `MenuDropdown`, if it has a submenu, or a
 * `MenuLink`.
 *
 * @return {Node} A `MenuDropdown`, if submenu is not empty, or a `MenuLink`
 * @param props
 */
Menu.renderMenuItem = props => {
  const {
    categoryPrefix,
    expanded,
    label,
    productPrefix,
    submenu,
    type,
    url,
    level = 0
  } = props;
  let href;
  let external = false;
  switch (type) {
    case 'CATEGORY':
      href = categoryPrefix + url;
      break;
    case 'PRODUCT':
      href = productPrefix + url;
      break;
    case 'EXTERNAL_LINK':
      external = true;
      href = url;
      break;
    default:
      href = url;
  }

  if (isEmpty(submenu)) {
    if (level > 0) {
      return (
        <SubMenuLink
          label={label}
          level={level}
          href={href}
          expanded={expanded}
          external={external}
        />
      );
    }
    return (
      <MenuLink expanded={expanded} href={href} key={label} external={external}>
        {label}
      </MenuLink>
    );
  }

  return (
    <MenuDropdown expanded={expanded} href={href} key={label} label={label}>
      {map(submenu, subMenuItem => {
        return Menu.renderMenuItem({
          ...subMenuItem,
          categoryPrefix,
          expanded,
          productPrefix,
          level: level + 1
        });
      })}
    </MenuDropdown>
  );
};

Menu.propTypes = {
  /** class names to add to the component */
  className: PropTypes.string,
  /** Whether the menu is expanded in mobile view */
  isActive: PropTypes.bool,
  /** Name of the Menu to fetch */
  name: PropTypes.string.isRequired
};

export default Menu;
export { Menu, MenuDropdown, MenuLink };
