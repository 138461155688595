import { useRestApi } from 'app/common/hooks/index.js';
import { useEffect, useState } from 'react';
import { logError } from 'app/common/utils/ApiErrorUtils/index.js';
import { get, isEmpty } from 'lodash';
import {
  COMPLETE,
  IN_PROGRESS,
  PENDING
} from 'app/common/hooks/useRestApi/RequestStatus.js';
import useBrandsInfo from './useBrandsInfo.js';

function useAllBrandsApi() {
  const [brandsMap, setBrandsMap] = useState([]);
  const { allBrandsPath } = useBrandsInfo();

  const {
    error,
    exception,
    response,
    sendCallback: fetchAllBrands,
    requestStatus
  } = useRestApi(allBrandsPath, {}, false);

  useEffect(() => {
    if (requestStatus === IN_PROGRESS || !isEmpty(brandsMap)) {
      return;
    }

    if (error) {
      logError({ ...exception, when: 'Fetching Brands' });
      return;
    }

    if (requestStatus === COMPLETE) {
      setBrandsMap(get(response, 'brands'));
      return;
    }

    if (requestStatus === PENDING) {
      fetchAllBrands();
    }
  }, [
    error,
    exception,
    response,
    requestStatus,
    brandsMap,
    setBrandsMap,
    fetchAllBrands
  ]);

  return brandsMap;
}

export default useAllBrandsApi;
