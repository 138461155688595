/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useMemo } from 'react';
import { get } from 'lodash';

import { AuthContext } from 'app/auth/contexts';
import { CSRContext } from 'app/csr/context';

function usePaymentAuthState() {
  const { csrMode, csrAnonymous } = React.useContext(CSRContext);
  const { isAuthenticated, isAuthenticating, user, getAccessToken } =
    React.useContext(AuthContext);

  return useMemo(() => {
    const customerEmail = get(user, 'email');
    const customerId = get(user, 'customerContextId');
    const accountId = get(user, 'acct_id');
    return {
      isAuthenticated,
      isAuthenticating,
      getAccessToken,
      customerEmail,
      accountId,
      customerId,
      isCsr: csrMode,
      isAnonymousCsr: csrAnonymous
    };
  }, [
    csrAnonymous,
    csrMode,
    getAccessToken,
    isAuthenticated,
    isAuthenticating,
    user
  ]);
}

export default usePaymentAuthState;
