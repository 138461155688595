/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    id: 'Checkout.CartEmailForm.button.cancel',
    defaultMessage: 'Cancel'
  },
  emailRequired: {
    id: 'Checkout.CartEmailForm.Email.required',
    defaultMessage: 'Email is required'
  },
  emailInvalid: {
    id: 'Checkout.CartEmailForm.Email.invalid',
    defaultMessage: 'Email must be formatted correctly'
  },
  emailHint: {
    id: 'Checkout.CartEmailForm.Email.hint',
    defaultMessage: 'Example: email@example.com'
  },
  emailPlaceholder: {
    id: 'Checkout.CartEmailForm.Email.placeholder',
    defaultMessage: 'email@example.com'
  },
  emailLabel: {
    id: 'Checkout.CartEmailForm.Email.label',
    defaultMessage: 'Where should we send the receipt?'
  },
  generalError: {
    id: 'Checkout.CartEmailForm.generalError',
    defaultMessage:
      'There was an error processing your request. Please check your email address and try again.'
  },
  signIn: {
    id: 'Checkout.CartEmailForm.error.signIn',
    defaultMessage: 'Sign In.'
  }
});
