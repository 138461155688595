import { get, isNil } from 'lodash';
import { useDebouncedCallback, useRefreshEffect } from 'app/common/hooks';
import { useFindStoreApi } from './useSuggestLocationApi';

/**
 * Find nearby stores given the supplied latitude, longitude, and dealer number.
 * @param {function} onStartRequest Called when the request starts
 * @param {Object} params
 * @param {number || undefined} params.maxDistanceMiles Default 500
 * @param {number || undefined} params.pageSize Default 25
 * @param {number || undefined} params.page Default 0
 * @param {Object} params.latLong Object with latitude and longitude properties
 * @param {string} params.dealerNumber Additional dealer number to include in the results
 * @param {number} params.maxDistanceMiles The max distance in miles to search for stores
 * @param {function} onSuccess Called if the request succeeds
 * @param {Object} onSuccess.currentLocation The dealer associated with the dealerNumber arg
 * @param {Array} onSuccess.stores The list of nearby stores
 * @param {function} onFailure Called if the request fails
 * @param {function} onComplete Called after onSuccess or onFailure
 * @param {function || undefined} runCondition Function that returns a boolean to determine if the request should be sent
 */
const useFindStores = function ({
  onStartRequest,
  onSuccess,
  onFailure,
  runCondition,
  onComplete,
  params
}) {
  const { sendCallback: sendFindStores } = useFindStoreApi();
  const {
    maxDistanceMiles = 500,
    pageSize = 25,
    page = 0,
    latLong = {},
    dealerNumber = undefined
  } = params;

  const getStoreResults = useDebouncedCallback(async () => {
    onStartRequest();
    if (runCondition && !runCondition()) {
      return;
    }
    try {
      const { latitude, longitude } = latLong;
      const response = await sendFindStores({
        params: {
          lat: latitude,
          lng: longitude,
          currentDealerNumber: dealerNumber,
          maxDistanceMiles,
          pageSize,
          page
        }
      });

      const currentLocation = get(response, 'currentLocation', null);
      const stores = get(response, 'nearbyStoreLocations', []);
      onSuccess({ currentLocation, stores });
    } catch (e) {
      onFailure(e);
    } finally {
      onComplete();
    }
  }, 300);

  // send query
  useRefreshEffect(
    () => {
      if (
        isNil(latLong) ||
        isNil(latLong.latitude) ||
        isNil(latLong.longitude)
      ) {
        return;
      }

      getStoreResults();
    },
    { getStoreResults, latLong }
  );
};

export { useFindStores };
