/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { AuthContext } from 'app/auth/contexts';
import { PriceContext, TenantContext } from 'app/common/contexts';
import { usePriceListApi, usePricingInfo } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

/**
 * Helper component that sets up the global `PriceContext`.
 *
 * @visibleName Pricing Provider
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const PricingProvider = ({ children }) => {
  const { resolving: resolvingTenant } = React.useContext(TenantContext);
  const { isAuthenticated, isAuthenticating } = useContext(AuthContext);
  const { priceListInfo } = usePricingInfo();
  const { fetchAllUrl } = priceListInfo;
  const {
    exception,
    error,
    loading,
    response,
    sendCallback: fetchPriceLists
  } = usePriceListApi(fetchAllUrl, null, false);
  const hasLoadedPriceLists = !loading && (response !== undefined || error);

  useEffect(() => {
    if (!resolvingTenant && !isAuthenticating) {
      fetchPriceLists();
    }
  }, [isAuthenticated, isAuthenticating, resolvingTenant, fetchPriceLists]);

  if (error) {
    logError({
      ...exception,
      when: 'fetching price lists for the current context'
    });
  }

  return (
    <PriceContextProvider
      hasLoadedPriceLists={hasLoadedPriceLists}
      initialPriceLists={get(response, 'content', [])}
    >
      {children}
    </PriceContextProvider>
  );
};

PricingProvider.propTypes = {
  /** Child components to render within the component  */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func
  ])
};

/**
 * Helper component split out from `PricingProvider` for the sake of using hooks
 * conditionally if there was an error or the `defaultPriceLists` is being loaded.
 */
const PriceContextProvider = ({
  children,
  hasLoadedPriceLists,
  initialPriceLists
}) => {
  const [priceLists, setPriceLists] = useState(initialPriceLists || []);
  // ensures that when initialPriceLists changes then the state is updated
  useEffect(() => setPriceLists(initialPriceLists || []), [initialPriceLists]);
  const context = useMemo(
    () => ({
      hasLoadedPriceLists,
      priceLists,
      setPriceLists: newLists => {
        return setPriceLists(newLists);
      }
    }),
    [hasLoadedPriceLists, priceLists]
  );

  return (
    <PriceContext.Provider value={context}>{children}</PriceContext.Provider>
  );
};

PriceContextProvider.propTypes = {
  /** Child components to render within the component  */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func
  ]),
  /**
   * List of price lists returned from the API that apply to the given web
   * context.
   */
  initialPriceLists: PropTypes.array
};

export default PricingProvider;
export { PricingProvider, PriceContextProvider };
