/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

/**
 * Generic render prop component for handling a location blocking prompt. This
 * is primarily useful for dirty form states to ensure that the user doesn't
 * accidentally lose important form data.
 *
 * Note: This component MUST be rendered within a Router.
 *
 * @visibleName Prompt
 */
const Prompt = ({ children, history, location, render, when }) => {
  const [isPrompting, setIsPrompting] = React.useState(false);
  const [nextLocation, setNextLocation] = React.useState(undefined);
  const unblock = React.useRef(undefined);
  const cancel = React.useCallback(() => {
    setIsPrompting(false);
    setNextLocation(undefined);
  }, []);
  const disableBlocking = React.useCallback(() => {
    if (!!unblock.current) {
      unblock.current();
      unblock.current = undefined;
    }
  }, []);
  const confirm = React.useCallback(() => {
    disableBlocking();
    history.push(nextLocation);
    setIsPrompting(false);
    setNextLocation(undefined);
  }, [disableBlocking, history, nextLocation]);

  React.useEffect(() => {
    if (!!when) {
      if (!!unblock.current) {
        unblock.current();
      }

      unblock.current = history.block(next => {
        setIsPrompting(true);
        setNextLocation(next);

        return false;
      });
    } else {
      disableBlocking();
    }

    return disableBlocking;
  }, [disableBlocking, history, location, when]);

  if (!isPrompting) {
    return null;
  }

  const renderPrompt = render || children;

  return renderPrompt({ cancel, confirm });
};

Prompt.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.element
  ]),
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object
    }).isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  render: PropTypes.func,
  when: PropTypes.bool.isRequired
};

export default withRouter(Prompt);
export { Prompt };
