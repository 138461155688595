/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'MyAccount.Discounts.title',
    defaultMessage: 'Discount Group Associations'
  },
  authCodeLabel: {
    id: 'MyAccount.Discounts.authCode.label',
    defaultMessage: 'Authorization Code'
  },
  authCodePlaceholder: {
    id: 'MyAccount.Discounts.authCode.placeholder',
    defaultMessage: 'A123'
  },
  authCodeSubmit: {
    id: 'MyAccount.Discounts.authCode.submit',
    defaultMessage: 'Apply'
  },
  authCodeRequired: {
    id: 'MyAccount.Discounts.authCode.required',
    defaultMessage: 'Authorization code is required'
  },
  programCodeLabel: {
    id: 'MyAccount.Discounts.programCode.label',
    defaultMessage: 'Fleet Discount Program'
  },
  programCodePlaceholder: {
    id: 'MyAccount.Discounts.programCode.placeholder',
    defaultMessage: 'Aurora Parts to Go'
  },
  programCodeSubmit: {
    id: 'MyAccount.Discounts.programCode.submit',
    defaultMessage: 'Apply'
  },
  programCodeRequired: {
    id: 'MyAccount.Discounts.programCode.required',
    defaultMessage: 'Program name is required'
  },
  authOrProgramCodeInvalid: {
    id: 'MyAccount.Fleets.authOrProgramCode.invalid',
    defaultMessage:
      'Fleet Discount Program not found for given code. Please contact Fleet to ensure you have the correct codes.'
  },
  authOrProgramCodeAlreadyAuthed: {
    id: 'MyAccount.Fleets.authCode.alreadyAuthed',
    defaultMessage: 'You are already associated with that Discount Program.'
  },
  authOrProgramCodeGenericError: {
    id: 'MyAccount.Fleets.authCode.genericError',
    defaultMessage:
      'We encountered an error trying to authorize your association. Please try again later.'
  },
  revokationGenericError: {
    id: 'MyAccount.Fleets.revokation.genericError',
    defaultMessage:
      'We encountered an error trying to remove your association. Please try again later.'
  },
  alreadyInAnotherProgram: {
    id: 'MyAccount.Fleets.authCode.alreadyInAnotherProgram',
    defaultMessage:
      'You may only participate in one discount program group at a given time. If you wish to apply another discount group or authorization code, first remove the current one.'
  },
  authedDate: {
    id: 'MyAccount.Discounts.authedDate',
    defaultMessage: 'Applied On:'
  },
  fleetName: {
    id: 'MyAccount.Discounts.fleetName',
    defaultMessage: 'Fleet:'
  },
  programCode: {
    id: 'MyAccount.Discounts.programCode',
    defaultMessage: 'Discount Program:'
  },
  authCode: {
    id: 'MyAccount.Discounts.authCode',
    defaultMessage: 'Authorization Code:'
  },
  removeProgram: {
    id: 'MyAccount.Discounts.removeProgram',
    defaultMessage: 'Remove'
  },
  noDiscounts: {
    id: 'MyAccount.Discounts.noDiscounts',
    defaultMessage: 'No Discount Program currently applied'
  }
});
