/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { toUpper } from 'lodash';
import PropTypes from 'prop-types';

import { CreditCardIcons } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from '../../PaymentFormReadOnly.messages';

const Method = props => {
  const formatMessage = useFormatMessage();
  const { attributes, className, type, messages: messagesOverride } = props;
  const isPayPal = type === PayPal.Type;

  return (
    <section className={className}>
      <h3 className="mb-1 text-base text-gray-900 font-bold">
        {formatMessage(
          !!messagesOverride
            ? messagesOverride.paymentMethodLabel
            : messages.paymentMethodLabel
        )}
      </h3>

      {!isPayPal && <CreditCard attributes={attributes} />}
      {isPayPal && <PayPal attributes={attributes} />}
    </section>
  );
};

const CreditCard = ({ attributes }) => {
  const formatMessage = useFormatMessage();
  const {
    creditCardNumber = '',
    creditCardExpDateMonth = '',
    creditCardExpDateYear = '',
    creditCardType = ''
  } = attributes;

  const CardIcon =
    creditCardType && CreditCardIcons.Types[toUpper(creditCardType)];

  return (
    <div className="flex items-start">
      {!!CardIcon ? (
        <CardIcon active={true} />
      ) : (
        <div className="mr-1">{formatMessage(messages.cardIconAlt)}</div>
      )}
      <div>
        <div>
          {formatMessage(messages.cardNumber, {
            number: creditCardNumber
          })}
        </div>
        <div>
          {formatMessage(messages.cardExpiration, {
            expMonth: creditCardExpDateMonth,
            expYear: creditCardExpDateYear
          })}
        </div>
      </div>
    </div>
  );
};

const PayPal = ({ attributes }) => {
  const formatMessage = useFormatMessage();
  const email = attributes.email;

  return (
    <div className="mt-4">
      <div className="mb-2">
        <img
          src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png"
          alt={formatMessage(messages.payPal)}
        />
      </div>
      <div>{email}</div>
    </div>
  );
};

PayPal.Type = 'paypal';

Method.CreditCard = CreditCard;
Method.PayPal = PayPal;

Method.propTypes = {
  attributes: PropTypes.shape({
    creditCardNumber: PropTypes.string,
    creditCardExpDateMonth: PropTypes.string,
    creditCardExpDateYear: PropTypes.string,
    creditCardType: PropTypes.string,
    paymentMethod: PropTypes.string,
    email: PropTypes.string
  }).isRequired
};

export default Method;
export { Method };
