/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  guestFormTitle: {
    id: 'OrderTracking.GuestForm.title',
    defaultMessage: "Don't have an account?"
  },
  guestFormSubtitle: {
    id: 'OrderTracking.GuestForm.subtitle',
    defaultMessage: 'Complete the form below to lookup your order.'
  },
  guestFormEmailLabel: {
    id: 'OrderTracking.GuestForm.email.label',
    defaultMessage: 'Email Address'
  },
  guestFormEmailPlaceholder: {
    id: 'OrderTracking.GuestForm.email.placeholder',
    defaultMessage: 'email@example.com'
  },
  guestFormEmailHint: {
    id: 'OrderTracking.GuestForm.email.hint',
    defaultMessage: 'Example: email@example.com'
  },
  guestFormEmailRequired: {
    id: 'OrderTracking.GuestForm.email.required',
    defaultMessage: 'Email is required'
  },
  guestFormEmailInvalid: {
    id: 'OrderTracking.GuestForm.email.invalid',
    defaultMessage: 'Email must be valid'
  },
  guestFormOrderNumberLabel: {
    id: 'OrderTracking.GuestForm.orderNumber.label',
    defaultMessage: 'Order Number'
  },
  guestFormOrderNumberRequired: {
    id: 'OrderTracking.GuestForm.orderNumber.required',
    defaultMessage: 'Order number is required'
  },
  guestFormSubmit: {
    id: 'OrderTracking.GuestForm.submit',
    defaultMessage: 'Lookup'
  },
  signInFormTitle: {
    id: 'OrderTracking.SignInForm.title',
    defaultMessage: 'Already have a Parts To Go account?'
  },
  signInFormSubtitle: {
    id: 'OrderTracking.SignInForm.subtitle',
    defaultMessage: 'Sign in to view your orders.'
  },
  signInFormSubmit: {
    id: 'OrderTracking.SignInForm.submit',
    defaultMessage: 'Sign In'
  },
  notFound: {
    id: 'OrderTracking.GuestForm.notFound',
    defaultMessage:
      'Order could not be found matching the given email and order number.'
  },
  generalError: {
    id: 'OrderTracking.GuestForm.generalError',
    defaultMessage:
      'We encountered an error trying to retrieve your order. Please try again later. If the problem persists, contact customer support.'
  },
  loadingMessage: {
    id: 'OrderTracking.GuestForm.loadingMessage',
    defaultMessage: 'Retrieving Order'
  }
});
