/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useContext, useMemo } from 'react';
import { get, isNil, merge } from 'lodash';

import { CartContext } from 'app/common/contexts';
import { Environment } from 'app/common/services';

import useModifyCartRequest from '../useModifyCartRequest';
import useCartInfo from '../useCartInfo';

/**
 * Hook to use to send an add-item-to-cart request to the cart operations API.
 * Response should contain the up-to-date, in-process cart.
 *
 * @param {Object} addItemRequest - `AddItemRequest` payload
 * @param {Object} config - Object containing additional request config such as
 *     the request params or request method
 * @param {boolean} [sendImmediate=false] - Whether to send the request immediately upon
 *     using the hook. If false, then the caller is responsible for triggering
 *     the `sendCallback`. Defaults to `false`.
 * @param {boolean} [rejectOnError=true] - Whether to reject the "send"
 *     promise on error response. Either way, the error will be part of a
 *     separate dispatch event.
 *
 * @return {{error: bool, exception: Object, loading: bool, response: Object, sendCallback: function}}
 */
function useAddToCartApi(
  addItemRequest,
  config,
  sendImmediate = false,
  rejectOnError = true
) {
  const { cart, guestToken } = useContext(CartContext);
  const { baseUrl, itemsContextPath } = useCartInfo().operations;
  const cartId = get(cart, 'id', undefined);
  const cartVersionHeaderName = Environment.get(
    'cart.version.header',
    'X-Cart-Version'
  );
  const cartGuestTokenHeaderName = Environment.get(
    'cart.token.header',
    'X-Guest-Token'
  );
  const fetchUrl = isNil(cartId)
    ? `${baseUrl}`
    : `${baseUrl}/${cartId}${itemsContextPath}`;

  const finalConfig = useMemo(() => {
    return merge(
      {},
      {
        data: addItemRequest,
        method: 'post',
        headers: !isNil(guestToken)
          ? {
              [cartVersionHeaderName]: get(cart, 'version', 0),
              [cartGuestTokenHeaderName]: guestToken.tokenString
            }
          : {
              [cartVersionHeaderName]: get(cart, 'version', 0)
            }
      },
      config
    );
  }, [
    addItemRequest,
    config,
    cartVersionHeaderName,
    cartGuestTokenHeaderName,
    cart,
    guestToken
  ]);

  return useModifyCartRequest(
    fetchUrl,
    finalConfig,
    sendImmediate,
    rejectOnError
  );
}

export default useAddToCartApi;
