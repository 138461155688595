/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  ariaLabel: {
    id: 'Header.Search.ariaLabel',
    defaultMessage: 'Search Catalog Content'
  },
  placeholder: {
    id: 'Header.Search.placeholder',
    defaultMessage: 'Search by part number, keyword, or cross reference'
  },
  submitLabel: {
    id: 'Header.Search.submitLabel',
    defaultMessage: 'Submit Query'
  },
  clearSearch: {
    id: 'Header.Search.clearSearch',
    defaultMessage: 'Clear Search'
  }
});
