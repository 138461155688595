/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useEffect } from 'react';

/**
 * Hook to use to create a window event listener with the given `eventType` and
 * `callback`. This will add the listener when the component mounts and remove
 * it upon dismounting.
 *
 * @param {string} eventType - the type of event to listen for
 * @param {function} callback - callback to use on event triggering
 * @param {Object} options - Optional. Options to pass when adding the listener.
 */
function useWindowEventListener(eventType, callback, options) {
  useEffect(() => {
    window.addEventListener(eventType, callback, !!options && options);
    return () => {
      window.removeEventListener(eventType, callback);
    };
  }, [callback, eventType, options]);
}

export default useWindowEventListener;
