import classNames from 'classnames';
import { Icon, TertiaryButton } from 'app/common/components';
import React from 'react';
import { useToggle } from 'app/common/hooks';
import AnimateHeight from 'react-animate-height';

/**
 *
 * @param children Collapsible children
 * @param title Collapsible title
 * @param open Collapsible default open state
 * @param className Additional class names to apply to collapsible
 * @param props Additional props
 */
const ProductCollapsible = ({
  children,
  title,
  open = false,
  className,
  ...props
}) => {
  const [show, toggleShow] = useToggle(open);

  return (
    <section
      className={classNames('basis-full text-gray-700', {
        [className]: !!className
      })}
    >
      <header className="w-full">
        <h3>
          <TertiaryButton
            alignment="justify-start"
            className="w-full py-2 px-4 rounded shadow"
            aria-expanded={show}
            onClick={() => {
              toggleShow();
            }}
          >
            <span className="block mr-2">{title}</span>
            {!show && <Icon name="plus" size="xs" />}
            {show && <Icon name="minus" size="xs" />}
          </TertiaryButton>
        </h3>
      </header>
      <AnimateHeight duration={200} height={show ? 'auto' : 0}>
        {children}
      </AnimateHeight>
    </section>
  );
};

export { ProductCollapsible };
