/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import {
  Currency,
  DecoratedField,
  Modal,
  PrimaryButton,
  SelectField,
  SimpleModal,
  TertiaryButton,
  TextAreaField
} from 'app/common/components';
import { CartContext, LocaleContext } from 'app/common/contexts';
import { useFormatMessage } from 'app/common/hooks';

import messages from './CSREditPrice.messages';

const DEFAULT_REASONS = [
  { value: 'PRICE_MATCH', label: messages.reasonsPriceMatchLabel },
  { value: 'OTHER', label: messages.reasonsOtherLabel }
];

const CSREditPrice = ({
  className,
  originalPrice,
  overrideReasons = DEFAULT_REASONS,
  ...props
}) => {
  const formatMessage = useFormatMessage();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={className}>
      <TertiaryButton
        size={TertiaryButton.Size.SMALL}
        onClick={() => setIsOpen(true)}
      >
        {formatMessage(messages.editPriceButton)}
      </TertiaryButton>
      <SimpleModal
        title={formatMessage(messages.overridePriceTitle)}
        body={
          <React.Fragment>
            <div className="flex justify-between px-4">
              <div className="mr-8">
                {formatMessage(messages.originalPrice)}
              </div>
              <Currency className="OriginalPrice" {...originalPrice} />
            </div>

            <CSREditPriceForm
              className="flex justify-end py-2"
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              originalPrice={originalPrice}
              overrideReasons={overrideReasons}
              {...props}
            />
          </React.Fragment>
        }
        isOpen={isOpen}
        size={Modal.Size.SMALL}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

const CSREditPriceForm = ({
  initialValues,
  isOpen,
  handleSubmit,
  originalPrice,
  setIsOpen,
  overrideReasons
}) => {
  const formatMessage = useFormatMessage();
  const { currentLocale } = useContext(LocaleContext);
  const { setCart } = useContext(CartContext);
  const validationSchema = React.useMemo(() => {
    return (
      !!currentLocale &&
      Yup.object().shape({
        overridePrice: Yup.number()
          .typeError(formatMessage(messages.overridePriceNaN))
          .min(0, formatMessage(messages.overridePriceNegative))
          .required(formatMessage(messages.overridePriceRequired))
      })
    );
  }, [currentLocale, formatMessage]);

  if (!isOpen) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={
        initialValues || {
          overridePrice: originalPrice == null ? 0 : originalPrice.amount,
          reason: overrideReasons[0].value
        }
      }
      onSubmit={async (values, actions) => {
        try {
          const response = await handleSubmit(values, actions);

          if (!isEmpty(response)) {
            setCart(response);
          }
        } catch (err) {
          let message = get(
            err,
            'message',
            formatMessage(messages.overridePriceInvalid)
          );

          actions.setFieldError('overridePrice', message);
        } finally {
          setIsOpen(false);
          actions.setSubmitting(false);
        }
      }}
      validateOnBlur={true}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {formik => (
        <Form className="flex flex-col p-4">
          <DecoratedField
            disabled={formik.isSubmitting}
            id="overridePrice"
            label={formatMessage(messages.overridePrice)}
            name="overridePrice"
            placeholder={formatMessage(messages.overridePricePlaceholder)}
            required
            step="0.01"
            type="number"
            widths="w-full"
          />
          <DecoratedField
            disabled={formik.isSubmitting}
            id="reason"
            label={formatMessage(messages.reason)}
            name="reason"
            required
            component={SelectField}
            valueOptions={overrideReasons}
          />
          <DecoratedField
            component={TextAreaField}
            disabled={formik.isSubmitting}
            id="comment"
            label={formatMessage(messages.comment)}
            name="comment"
          />
          <PrimaryButton
            className="self-end"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {formatMessage(messages.submitButton)}
          </PrimaryButton>
        </Form>
      )}
    </Formik>
  );
};

CSREditPrice.propTypes = {
  className: PropTypes.string,
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  originalPrice: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string
  }).isRequired,
  validationSchema: PropTypes.object
};

export default CSREditPrice;
