/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { Form, Formik } from 'formik';
import { includes, isEmpty, isString, map, noop } from 'lodash';
import { default as qs } from 'query-string';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import {
  Button,
  CheckboxField,
  DecoratedField,
  Icon,
  Input,
  PrimaryButton,
  SecondaryButton
} from 'app/common/components';
import { useFormatMessage, useGtmPageView } from 'app/common/hooks';

import { AddToListModal, BulkDeleteButton, List } from './components';
import { ListsContext } from './contexts';
import { ListsProvider } from './helpers';
import messages from './Lists.messages';
import { useHeaderMetadata } from 'app/core/components/App';

const Lists = ({ history, location }) => {
  const formatMessage = useFormatMessage();
  const {
    isAdding,
    setIsAdding,
    lists,
    setSelectedLists,
    selectAll,
    toggleSelectAll,
    refetchLists,
    resolvingLists
  } = React.useContext(ListsContext);
  const [hasFilter, setHasFilter] = React.useState(
    !isEmpty(qs.parse(location.search).q)
  );
  const { siteTitle } = useHeaderMetadata();

  React.useEffect(() => {
    setHasFilter(!isEmpty(qs.parse(location.search).q));
  }, [location.search]);

  useGtmPageView('Saved Lists');

  return (
    <section>
      <Helmet titleTemplate={`%s - ${siteTitle}`}>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <AddToListModal
        handlePostSubmit={() => {
          setIsAdding(false);
          refetchLists();
        }}
        open={isAdding}
        setOpen={setIsAdding}
      />
      <header className="flex flex-col mb-6 sm:justify-between sm:items-center sm:flex-row">
        <h1 className="text-2xl text-bold">{formatMessage(messages.title)}</h1>
        <div className="flex flex-grow items-center justify-end mt-2 sm:mt-0">
          <BulkDeleteButton />
          <PrimaryButton
            onClick={() => {
              setSelectedLists([]);
              setIsAdding(true);
            }}
          >
            {formatMessage(messages.addButton)}
          </PrimaryButton>
        </div>
      </header>
      {isEmpty(lists) && !resolvingLists && !hasFilter ? (
        <section className="w-full text-bold text-gray-500 text-xl mt-4 sm:mt-10 sm:ml-10 sm:text-2xl lg:mt-20 lg:ml-20">
          {formatMessage(messages.noLists)}
        </section>
      ) : (
        <section>
          <section className="mb-4">
            <Formik
              initialValues={{ name: qs.parse(location.search).q || '' }}
              onSubmit={async (values, actions) => {
                const params = qs.parse(location.search);
                const search = { ...params };
                search.q = values.name;
                history.push({
                  ...location,
                  search: qs.stringify(search)
                });
                actions.setSubmitting(false);
              }}
              validateOnChange={false}
              validateOnBlur={true}
            >
              {({ isSubmitting }) => (
                <Form className="flex flex-col w-full">
                  <DecoratedField
                    disabled={isSubmitting}
                    id="name-search"
                    label={formatMessage(messages.searchLabel)}
                    name="name"
                    placeholder={formatMessage(messages.searchPlaceholder)}
                    size={Input.Size.SMALL}
                    autoComplete="off"
                  />
                  <div className="flex justify-end w-full lg:w-64">
                    <SecondaryButton
                      type="submit"
                      disabled={isSubmitting}
                      size={PrimaryButton.Size.SMALL}
                    >
                      <Icon className="mr-2" name="search" />
                      <span>{formatMessage(messages.searchSubmit)}</span>
                    </SecondaryButton>
                  </div>
                </Form>
              )}
            </Formik>
          </section>
          <header className="flex items-center justify-start w-full mt-4 py-2 px-4 text-gray-700 text-sm font-bold first:my-0 sm:mb-4 sm:justify-between sm:text-base">
            <CheckboxField.Field
              className="basis-1/12 flex-shrink"
              checked={selectAll}
              value="ALL"
              field={{
                onBlur: noop,
                onChange: () => {
                  toggleSelectAll();
                },
                name: 'select-all',
                value: 'ALL'
              }}
              title={formatMessage(messages.selectAll)}
            />
            <div className="flex-grow leading-none basis-4/12">
              <ColumnSort alpha name="name" />
            </div>
            <div className="flex-grow leading-none basis-4/12">
              <ColumnSort name="updatedDate" />
            </div>
            <div className="flex flex-col items-end flex-grow sm:flex-row sm:justify-end sm:items-center sm:basis-3/12" />
          </header>
          <section>
            <ul className="list-none">
              {map(lists, list => (
                <List key={list.id} list={list} />
              ))}
            </ul>
          </section>
        </section>
      )}
    </section>
  );
};

const ColumnSort = withRouter(({ alpha = false, history, location, name }) => {
  const formatMessage = useFormatMessage();
  const [descendNext, setDescendNext] = React.useState(
    // makes the transitions smoother
    includes(location.search, `${name},asc`) ||
      includes(location.search, `${name}%2Casc`)
  );
  const [ascendNext, setAscendNext] = React.useState(
    // makes the transitions smoother
    !includes(location.search, `${name},asc`) &&
      !includes(location.search, `${name}%2Casc`) &&
      !includes(location.search, `${name},desc`) &&
      !includes(location.search, `${name}%2Cdesc`)
  );

  React.useEffect(() => {
    const params = qs.parse(location.search);
    let sort = params.sort || [];

    if (isString(sort)) {
      sort = [sort];
    }

    if (sort.includes(`${name},asc`)) {
      setDescendNext(true);
      setAscendNext(false);
    } else if (sort.includes(`${name},desc`)) {
      setDescendNext(false);
      setAscendNext(false);
    } else {
      setAscendNext(true);
      setDescendNext(false);
    }
  }, [name, location.search]);

  return (
    <Button
      className="flex w-full items-center justify-between p-2 text-gray-700 text-sm font-bold sm:text-base"
      onClick={() => {
        const params = qs.parse(location.search);
        let sort = params.sort || [];

        if (isString(sort)) {
          sort = [sort];
        }

        sort = sort.filter(
          s => s !== `${name},${descendNext ? 'asc' : !ascendNext && 'desc'}`
        );

        if (descendNext || ascendNext) {
          sort.push(`${name},${descendNext ? 'desc' : 'asc'}`);
        }

        history.push({
          ...location,
          search: qs.stringify({ ...params, sort }, { indices: false })
        });
      }}
    >
      <span>{formatMessage(messages[name])}</span>
      {descendNext ? (
        <Icon
          className="ml-2"
          name={alpha ? 'sort-alpha-down' : 'sort-numeric-down'}
        />
      ) : ascendNext ? (
        <Icon
          className="ml-2"
          name="exchange-alt"
          iconProps={{ rotation: 90 }}
        />
      ) : (
        <Icon
          className="ml-2"
          name={alpha ? 'sort-alpha-up' : 'sort-numeric-up'}
        />
      )}
    </Button>
  );
});

const ListsSkeleton = () => {
  const formatMessage = useFormatMessage();
  const { siteTitle } = useHeaderMetadata();
  return (
    <section>
      <Helmet titleTemplate={`%s - ${siteTitle}`}>
        <title>{formatMessage(messages.title)}</title>
      </Helmet>
      <header className="text-2xl text-bold flex justify-between pb-4">
        <h1>{formatMessage(messages.title)}</h1>
      </header>
      <section className="w-full text-bold text-gray-500 text-xl sm:mt-10 sm:ml-10 sm:text-2xl lg:mt-20 lg:ml-20">
        {formatMessage(messages.noLists)}
      </section>
    </section>
  );
};

Lists.Skeleton = ListsSkeleton;

const ListsWithProvider = props => {
  return (
    <ListsProvider>
      <Lists {...props} />
    </ListsProvider>
  );
};

export default withRouter(ListsWithProvider);
export { ListsWithProvider, Lists };
