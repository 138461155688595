/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { TextArea } from 'app/common/components';

/**
 * Render component for a form text area field.
 */
const TextAreaField = ({
  field,
  form = { errors: [], touched: [] },
  ...props
}) => (
  <TextArea
    {...props}
    {...field}
    hasErrors={!isEmpty(get(form.errors, field.name))}
    touched={!!get(form.touched, field.name)}
  />
);

TextAreaField.propTypes = {
  field: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string
  }).isRequired,
  form: PropTypes.object
};

export default TextAreaField;
export { TextAreaField };
