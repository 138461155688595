/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * An Item in the Dashboard LeftNav.
 */
const NavItem = ({ label, url, first, last }) => {
  return (
    <li className="flex flex-wrap flex-grow items-center h-8 overflow-hidden lg:h-12 lg:flex-grow-0 lg:last:rounded-b lg:first:rounded-t">
      <Link
        className={classNames(
          'w-full h-full flex justify-center items-center px-2 font-bold text-sm border-b-2 text-center focus:outline-none lg:border-r-4 lg:border-b-0 lg:px-0',
          'lg:text-md',
          {
            'text-gray-900 border-primary-500 bg-gray-100':
              window.location.pathname === url,
            'text-gray-600 hover:text-gray-700 border-gray-400 hover:bg-gray-100 hover:border-primary-500 focus:bg-gray-100 focus:border-primary-500':
              window.location.pathname !== url
          }
        )}
        to={url}
      >
        {label}
      </Link>
    </li>
  );
};

NavItem.propTypes = {
  // The label displayed for this nav item.
  label: PropTypes.string.isRequired,
  // The redirect url for the selected dashboard page.
  url: PropTypes.string.isRequired
};

export default NavItem;
export { NavItem };
