/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  accountInfo: {
    id: 'MyAccount.LeftNav.accountInfo',
    defaultMessage: 'Profile Details'
  },
  preferredLocation: {
    id: 'MyAccount.LeftNav.preferredLocation',
    defaultMessage: 'Preferred Location'
  },
  orders: {
    id: 'MyAccount.LeftNav.orders',
    defaultMessage: 'Orders'
  },
  addresses: {
    id: 'MyAccount.LeftNav.addresses',
    defaultMessage: 'Addresses'
  },
  lists: {
    id: 'MyAccount.LeftNav.lists',
    defaultMessage: 'Lists'
  },
  fleets: {
    id: 'MyAccount.LeftNav.fleets',
    defaultMessage: 'Fleet Associations'
  },
  discounts: {
    id: 'MyAccount.LeftNav.discounts',
    defaultMessage: 'Discount Group Associations'
  },
  paymentInfo: {
    id: 'MyAccount.LeftNav.paymentInfo',
    defaultMessage: 'Payment Info'
  }
});
