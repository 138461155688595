/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { Icon } from 'app/common/components';
import { ConfigurableItemContext } from 'app/product/contexts';

import {
  ChoiceAsset,
  ChoiceOptions,
  ChoicePrice,
  QuantitySelect
} from '../../../';
import {
  calculatePriceIncrease,
  getId,
  getInitialQuantity,
  getName,
  getSku,
  isProduct as checkIsProduct
} from '../../../../utils/ChoiceUtils';
import { useChoiceAsset, useDeterminePricing } from '../../../../hooks';

const Choice = ({ choice, handleChange, itemChoice, selectedItemChoice }) => {
  const {
    activeOption,
    variantAttributeChoices,
    cartItemAttributeChoices,
    requiresConfiguration
  } = React.useContext(ConfigurableItemContext);
  const { choiceKey, maximumQuantity: maxQty } = itemChoice;
  const isProduct = checkIsProduct(itemChoice.targetType);
  const asset = useChoiceAsset(choice, isProduct);
  const name = getName(choice);
  const pricing = useDeterminePricing(choice, itemChoice);
  const [quantity, setQuantity] = React.useState(
    getInitialQuantity(0, selectedItemChoice)
  );

  React.useEffect(() => {
    if (requiresConfiguration) {
      return;
    }

    if (isEmpty(activeOption)) {
      handleChange({
        choiceKey,
        id: getId(choice),
        isProduct,
        isMulti: true,
        itemAttributeChoices: cartItemAttributeChoices || {},
        priceIncrease: calculatePriceIncrease(pricing, quantity),
        productId: isProduct ? undefined : choice.variant.productId,
        quantity,
        sku: getSku(choice)
      });

      return;
    }

    handleChange({
      choiceKey,
      id: activeOption.id,
      isConfigurable: true,
      isMulti: true,
      isProduct: false,
      itemAttributeChoices: {
        ...(variantAttributeChoices || {}),
        ...(cartItemAttributeChoices || {})
      },
      priceIncrease: calculatePriceIncrease(pricing, quantity),
      productId: activeOption.productId,
      quantity,
      sku: activeOption.sku
    });
  }, [
    activeOption,
    variantAttributeChoices,
    cartItemAttributeChoices,
    choice,
    choiceKey,
    handleChange,
    isProduct,
    pricing,
    quantity,
    requiresConfiguration
  ]);

  return (
    <li className="flex flex-col w-full mb-4">
      <div className="flex flex-col items-center justify-between w-full sm:flex-row">
        <div
          className={classNames(
            'flex items-center w-full mb-2 sm:w-3/5 sm:mb-0',
            { 'mb-2': !isEmpty(asset), 'mb-4': isEmpty(asset) }
          )}
        >
          {!isEmpty(asset) && <ChoiceAsset asset={asset} />}
          <h4 className="flex-grow w-2/3 text-800 sm:pr-4 lg:pr-2 xl:pr-4">
            {name}
          </h4>
        </div>
        <div className="flex items-center justify-end w-full sm:w-2/5">
          <ChoicePrice pricing={pricing} />
          <Icon className="block mx-4 lg:mx-2 xl:mx-4" name="times" />
          <QuantitySelect
            handleChange={setQuantity}
            initialQuantity={quantity}
            // the max is actually spread across all choices
            maximumQuantity={maxQty}
            // choose multiple does not require each choice to have quantity
            minimumQuantity={0}
          />
        </div>
      </div>
      {isProduct && (
        <ChoiceOptions
          options={
            isEmpty(choice.product) ? choice.options : choice.product.options
          }
        />
      )}
    </li>
  );
};

Choice.propTypes = {
  /** A function that takes the selected quantity */
  handleChange: PropTypes.func.isRequired
};

export default Choice;
export { Choice };
