/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { get, isEmpty } from 'lodash';

import { Image, SliceHTMLWidget } from 'app/common/components';

const TopBrandHeader = ({ activeTopBrand }) => {
  const bannerAsset = get(activeTopBrand, 'bannerAsset');
  const topBrandDescription = get(activeTopBrand, 'description');

  const bannerEmpty = isEmpty(bannerAsset);
  const descriptionEmpty = isEmpty(topBrandDescription);

  const classes = descriptionEmpty ? 'place-content-center' : 'text-left';
  return (
    <div
      className={classNames(
        'flex flex-col w-full lg:flex-row',
        { 'place-content-center': descriptionEmpty },
        classes
      )}
    >
      {!bannerEmpty && (
        <div
          className={classNames('flex', {
            'w-full max-w-400px': descriptionEmpty,
            'lg:basis-1/3': !descriptionEmpty
          })}
        >
          <Image
            className={'shadow-none'}
            name={get(activeTopBrand, 'brandName')}
            primaryAsset={bannerAsset}
          />
        </div>
      )}
      {!descriptionEmpty && (
        <div
          className={classNames('flex', {
            'lg:ml-4 lg:basis-2/3': !bannerEmpty
          })}
        >
          <SliceHTMLWidget
            content={topBrandDescription}
            className={'w-full'}
            readMoreClassName={'p-4 pt-0 lg:p-0 lg:pt-0'}
            readMoreTextClass={'text-primary-700 hover:text-primary-700 '}
          />
        </div>
      )}
    </div>
  );
};

export default TopBrandHeader;
export { TopBrandHeader };
