/**
 * @typedef {Object} ProductAsset
 * @property {String} id - The id of the asset
 * @property {String} productId - The id of the product associated with the asset
 * @property {String} url - The url of the asset
 * @property {String} type - The type of the asset
 * @property {String} title - The title of the asset
 * @property {String} altText - The alt text of the asset
 */

/**
 * @typedef {Object} ProductDocument
 * @property {String} id - The id of the document
 * @property {String} sku - The sku of the product
 * @property {String} languageCode - The language code of the document
 * @property {String} title - The title of the document
 * @property {String} description - The description of the document
 * @property {String} productId - The id of the product associated with the document
 * @property {String} assetId - The asset id of the document
 * @property {ProductAsset} asset - The asset object of the document
 */

import { useMemo } from 'react';
import { useRestApi } from 'app/common/hooks';
import { isEmpty } from 'lodash';

/**
 * Hook to use to fetch a product's documents.
 * @param productId - The id of the product
 * @param sendImmediate - Whether to send the request immediately upon using the hook. If false, then the caller is responsible for triggering the `sendCallback`. Defaults to `false`.
 * @returns {{error: boolean, exception: Object, loading: boolean, response: ProductDocument[], sendCallback: function}}
 */

const documentUrl = '/catalog/products/documents/';
function useProductDocuments(productId) {
  const url = documentUrl + productId;
  const finalConfig = useMemo(() => {
    return {
      method: 'get'
    };
    // eslint-disable-next-line
  }, [productId]);
  const { error, exception, loading, response, sendCallback, requestStatus } =
    useRestApi(url, finalConfig, false);
  return useMemo(() => {
    if (requestStatus === 'COMPLETE') {
      return response || [];
    }
    if (requestStatus === 'PENDING' && !isEmpty(productId)) {
      sendCallback();
    }
    if (isEmpty(productId) || loading || requestStatus !== 'COMPLETE') {
      return [];
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    error,
    exception,
    loading,
    response,
    requestStatus,
    sendCallback,
    productId
  ]);
}

export default useProductDocuments;
