/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'PreferredLocation.title',
    defaultMessage: 'Preferred Location'
  },
  changePreferred: {
    id: 'PreferredLocation.changePreferred',
    defaultMessage:
      '{hasSelected, select, true {Change} other {Select}} Preferred Location'
  },
  selectedDealer: {
    id: 'PreferredLocation.selected.title',
    defaultMessage: 'Your preferred location for ordering parts is'
  },
  noDealerCaveat: {
    id: 'PreferredLocation.noDealerCaveat',
    defaultMessage:
      'You have not selected a preferred location to order from. Select one to checkout faster.'
  },
  myStore: {
    id: 'PreferredLocation.myStore',
    defaultMessage: 'My Store'
  },
  setAsStore: {
    id: 'PreferredLocation.setAsStore',
    defaultMessage: 'Set as Store'
  },
  setPreferredStoreError: {
    id: 'PreferredLocation.setPreferredStoreError',
    defaultMessage:
      'An error occurred while setting your preferred store. Please try again.'
  }
});
