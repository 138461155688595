/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button } from 'app/common/components';

const ColorAttributeChoice = ({
  allowedValue,
  activeChoice = {},
  handleSelect
}) => {
  const { label, value, displayOrder, disabled = false } = allowedValue;
  const activeValue = activeChoice.value;

  return (
    <li
      className={classNames(
        'inline-flex m-1 p-px border-2 border-solid rounded-full',
        {
          'border-transparent hover:border-gray-500 focus:border-gray-500 active:border-gray-400':
            !disabled && activeValue !== value,
          'border-transparent': disabled
        }
      )}
      style={{
        order: displayOrder,
        borderColor: activeValue === value ? value : undefined,
        opacity: disabled ? '0.5' : undefined
      }}
    >
      <Button
        className={classNames(
          'inline-flex items-center justify-center',
          'p-4 lg:p-3',
          'appearance-none border border-solid border-gray-400 rounded-full',
          'focus:outline-none focus:border-outline'
        )}
        style={{
          backgroundColor: value
        }}
        onClick={() => {
          handleSelect(label, value);
        }}
        title={label}
      />
    </li>
  );
};

ColorAttributeChoice.propTypes = {
  allowedValue: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    displayOrder: PropTypes.number,
    disabled: PropTypes.bool
  }).isRequired,
  /** The currently selected value */
  activeChoice: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any
  }),
  /** Method to call when this value is selected. Takes the label and value */
  handleSelect: PropTypes.func.isRequired
};

export default ColorAttributeChoice;
export { ColorAttributeChoice };
