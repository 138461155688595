/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import { PreviewContext } from 'app/common/contexts';
import { PreviewLayout } from 'app/core/components';

import useProvidePreviewContext from './hooks/useProvidePreviewContext';

const PreviewProvider = ({ children }) => {
  const context = useProvidePreviewContext();
  const [state] = context;
  if (state.isActive && !state.isValidated) {
    return null;
  }
  if (!state.isActive && !state.isValidated) {
    return children;
  }
  return (
    <PreviewContext.Provider value={context}>
      <PreviewLayout>{children}</PreviewLayout>
    </PreviewContext.Provider>
  );
};

export default PreviewProvider;
