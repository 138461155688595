/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { find, get, isEmpty, isNil } from 'lodash';

import { ItemChoiceTargetType } from 'app/common/constants';
import { logError } from 'app/common/utils/ApiErrorUtils';

/**
 * Gets any asset on the given choice. The choice can be a product or a specific
 * item choice with a product or a variant.
 *
 * @param {{}} choice - The choice
 *
 * @return {{}|null} The asset of the choice or null if none
 */
function getAsset(choice) {
  if (choice.type === 'VARIANT') {
    return get(choice, 'asset', get(choice, 'variant.primaryAsset', null));
  }

  if (choice.type === 'PRODUCT') {
    return get(choice, 'asset', get(choice, 'product.primaryAsset', null));
  }

  return get(choice, 'primaryAsset', null);
}

/**
 * Gets the specific choices (products or `SpecificItemChoices`) from an
 * `ItemChoice`.
 *
 * @param {{}} itemChoice - The `ItemChoice` from which to get the specific
 *     choices.
 *
 * @return {[]}
 */
function getChoices(itemChoice) {
  switch (itemChoice.targetType) {
    case ItemChoiceTargetType.CATEGORY:
      return itemChoice.category.products.content;
    case ItemChoiceTargetType.SPECIFIC_PRODUCTS:
    case ItemChoiceTargetType.SPECIFIC_VARIANTS:
      return itemChoice.specificChoices;
    default:
      logError({
        when: `determining the choices for item choice '${itemChoice.choiceKey}' of unknown target type ${itemChoice.targetType}`
      });
      return [];
  }
}

/**
 * Gets the default choice from an `ItemChoice`. This is determined by the
 * `targetType`.
 *
 * @param {{}} itemChoice - `ItemChoice` for which to get the default choice
 * @param {{}|undefined} [selectedItemChoice] - The already selected choice if any
 *
 * @return {{}} The default choice or null if none.
 * @throws Error If the item choice is of an unknown type
 */
function getDefaultChoice(itemChoice, selectedItemChoice) {
  if (!isEmpty(selectedItemChoice)) {
    if (itemChoice.targetType === ItemChoiceTargetType.CATEGORY) {
      return find(
        itemChoice.category.products.content,
        choice =>
          (choice.id === selectedItemChoice.id &&
            !isNil(selectedItemChoice.id)) ||
          (choice.sku === selectedItemChoice.sku &&
            !isNil(selectedItemChoice.sku))
      );
    }

    if (itemChoice.targetType === ItemChoiceTargetType.SPECIFIC_PRODUCTS) {
      return find(
        itemChoice.specificChoices,
        choice =>
          (choice.product.id === selectedItemChoice.id &&
            !isNil(selectedItemChoice.id)) ||
          (choice.product.sku === selectedItemChoice.sku &&
            !isNil(selectedItemChoice.sku))
      );
    }

    if (itemChoice.targetType === ItemChoiceTargetType.SPECIFIC_VARIANTS) {
      return find(
        itemChoice.specificChoices,
        choice =>
          (choice.variant.id === selectedItemChoice.id &&
            !isNil(selectedItemChoice.id)) ||
          (choice.variant.sku === selectedItemChoice.sku &&
            !isNil(selectedItemChoice.sku))
      );
    }
  }

  if (itemChoice.targetType === ItemChoiceTargetType.CATEGORY) {
    return (
      itemChoice.defaultProductInCategory ||
      get(itemChoice, 'category.products.content[0]')
    );
  }

  if (itemChoice.targetType === ItemChoiceTargetType.SPECIFIC_PRODUCTS) {
    return itemChoice.defaultProduct || get(itemChoice, 'specificChoices[0]');
  }

  if (itemChoice.targetType === ItemChoiceTargetType.SPECIFIC_VARIANTS) {
    return itemChoice.defaultVariant || get(itemChoice, 'specificChoices[0]');
  }

  throw new Error(
    `Encountered item choice with unknown target type ${itemChoice.targetType}.
     Modify ChoiceUtils#getDefaultChoice to handle this new type.`
  );
}

/**
 * Gets any id on the given choice. The choice can be a product or a specific
 * item choice with a product or a variant.
 *
 * @param {{}} choice - The choice
 *
 * @return {string} The id of the choice
 */
function getId(choice) {
  if (choice.type === 'VARIANT') {
    return get(choice, 'variant.id');
  }

  if (choice.type === 'PRODUCT') {
    return get(choice, 'product.id');
  }

  return get(choice, 'id');
}

/**
 * Gets the initial quantity for a choice (product or `SpecificItemChoice`).
 * This will look on `selectedItemChoices` for any quantity previously given for
 * a choice. Otherwise, the `minimumQuantity` will be used.
 *
 * @param {number} minimumQuantity - The minimum quantity than can be selected
 *     for this choice.
 * @param {{}|undefined} selectedItemChoice - The previously selected choice.
 *
 * @return {number}
 */
function getInitialQuantity(minimumQuantity, selectedItemChoice) {
  return get(selectedItemChoice, 'quantity', minimumQuantity);
}

function getName(choice) {
  if (choice.type === 'VARIANT') {
    return get(choice, 'nameOverride', get(choice, 'variant.name'));
  }

  if (choice.type === 'PRODUCT') {
    return get(choice, 'nameOverride', get(choice, 'product.name'));
  }

  return get(choice, 'name');
}

/**
 * Gets any sku code on the given choice. The choice can be a product or a
 * specific item choice with a product or a variant.
 *
 * @param {{}} choice - The choice
 *
 * @return {string|null} The sku code of the choice or null if none
 */
function getSku(choice) {
  if (choice.type === 'VARIANT') {
    return get(choice, 'variant.sku');
  }

  if (choice.type === 'PRODUCT') {
    return get(choice, 'product.sku');
  }

  return get(choice, 'sku');
}

/**
 * Whether the given `targetType` means that the choice is ultimately a product.
 *
 * @param {string} targetType - The target type of an `ItemChoice`
 *
 * @return {boolean} Whether the given `targetType` means that the choice is
 *     ultimately a product.
 */
function isProduct(targetType) {
  return (
    targetType === ItemChoiceTargetType.CATEGORY ||
    targetType === ItemChoiceTargetType.SPECIFIC_PRODUCTS
  );
}

/**
 * Calculates the price increase to the parent product after the selection of a
 * choice.
 *
 * @param {{
 *  price: {amount: number, currency: string}|undefined,
 *  isSale: boolean,
 *  isIncludedInParent: boolean
 * }} pricing - Pricing info for an item choice
 * @param {number} quantity - Quantity of the choice selected
 *
 * @return {number} the price increase to the parent product
 */
function calculatePriceIncrease(pricing, quantity) {
  return pricing.isIncludedInParent ? 0.0 : pricing.price.amount * quantity;
}

export {
  calculatePriceIncrease,
  getAsset,
  getChoices,
  getDefaultChoice,
  getId,
  getInitialQuantity,
  getName,
  getSku,
  isProduct
};
