/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useContext, useMemo } from 'react';
import { merge } from 'lodash';

import { AuthContext } from 'app/auth/contexts';
import { LocaleContext } from 'app/common/contexts';

/**
 * Returns the current context to use
 *
 * @param {string|null} [targeterName=null] - Name of the content targeter
 * @param {{}} [attributes] - Additional attributes to pass along
 */
function useTargeterContext(targeterName = null, attributes = undefined) {
  const { isAuthenticated } = useContext(AuthContext);
  const { currentLocale } = useContext(LocaleContext);

  return useMemo(
    () => ({
      name: targeterName,
      locale: currentLocale,
      customer: {
        authenticated: isAuthenticated
      },
      attributes: merge({}, attributes, {
        webRequest: {
          fullUrl: window.location.href,
          pathname: window.location.pathname,
          userAgentType: window.navigator.userAgent,
          secure: window.location.protocol === 'https:',
          searchKeywords: window.location.search
        }
      })
    }),
    [attributes, currentLocale, isAuthenticated, targeterName]
  );
}

export default useTargeterContext;
