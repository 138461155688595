/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { Currency } from 'app/common/components';

/**
 * Render component for Product prices.
 *
 * @visibleName MiniProductGrid: MiniProduct: Prices
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const MiniProductPrices = ({ pricing }) => {
  if (isEmpty(pricing)) {
    return (
      <div className="w-24 mr-2 mt-2 text-lg bg-gray-200 rounded">&nbsp;</div>
    );
  }
  const { isSale, originalPrice, isStandard } = pricing;
  const specialPrice = isSale || (!isStandard && !isEmpty(originalPrice));
  return (
    <div className="flex flex-wrap flex-grow items-center my-1">
      {specialPrice ? (
        <>
          <Currency
            className="text-red-600 text-lg"
            amount={pricing.price.amount}
            currency={pricing.price.currency}
          />
          {!isEmpty(pricing.originalPrice) && (
            <Currency
              className="ml-2 text-sm text-gray-500 line-through"
              amount={pricing.originalPrice.amount}
              currency={pricing.originalPrice.currency}
            />
          )}
        </>
      ) : (
        !isEmpty(pricing.price) && (
          <Currency
            className="text-gray-900 text-lg font-medium"
            amount={pricing.price.amount}
            currency={pricing.price.currency}
          />
        )
      )}
    </div>
  );
};

MiniProductPrices.propTypes = {
  pricing: PropTypes.shape({
    price: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      currency: PropTypes.string
    }).isRequired,
    isSale: PropTypes.bool,
    originalPrice: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      currency: PropTypes.string
    })
  })
};

export default MiniProductPrices;
export { MiniProductPrices };
