/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

import { useFormatMessage, useGtmPageView } from 'app/common/hooks';

import messages from './CartHelmet.messages';
import { useHeaderMetadata } from '../../../core/components/App';

/**
 * Helper component that updates the `<head>` content with appropriate metadata.
 *
 * @visibleName Cart Page: Helmet
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const CartHelmet = ({ loading = false }) => {
  const { siteTitle, siteDescription } = useHeaderMetadata();
  const formatMessage = useFormatMessage();
  const title = formatMessage(messages.title);

  useGtmPageView('Cart', undefined, loading);

  return (
    <ReactHelmet titleTemplate={`%s - ${siteTitle}`}>
      <title>{title}</title>
      <meta name="robots" content="noindex,nofollow" />
      <meta name="description" content={siteDescription} />
    </ReactHelmet>
  );
};

export default CartHelmet;
export { CartHelmet };
