/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';

const MiniProductSkeleton = ({ usingGrid = true }) => (
  <li
    className={classNames(
      'MiniProduct group flex flex-wrap justify-end flex-grow-0 basis-0 mb-4 pb-4 border-b border-solid border-gray-300 last:border-b-0 sm:p-3',
      {
        'sm:flex-col sm:basis-1/2 sm:border-b-0 md:basis-1/3': usingGrid,
        'sm:flex-row sm:flex-no-wrap sm:items-start sm:border-b sm:border-solid sm:last:border-b-0':
          !usingGrid
      }
    )}
  >
    <div
      className={classNames('relative float-left w-2/5 pr-4', {
        'sm:w-full': usingGrid,
        'sm:w-1/5': !usingGrid
      })}
    >
      <figure className="relative block rounded">
        <div
          className={classNames('block w-full h-auto rounded bg-gray-200', {
            'sm:my-0 sm:mx-auto': usingGrid
          })}
          style={{ width: '100%', paddingTop: '100%' }}
        />
      </figure>
    </div>
    <header
      className={classNames('flex flex-col flex-grow w-3/5', {
        'flex-grow flex-shrink-0 basis-0 sm:w-full sm:mt-4': usingGrid,
        'sm:w-2/5 sm:pr-2': !usingGrid
      })}
    >
      <h4 className="w-24 mb-2 font-bold text-base bg-gray-200 rounded">
        &nbsp;
      </h4>
      <h5 className="w-48 mb-2 font-bold text-base bg-gray-200 rounded">
        &nbsp;
      </h5>
    </header>
    <footer
      className={classNames('flex flex-col w-3/5', {
        'sm:w-full': usingGrid,
        'sm:w-1/3': !usingGrid
      })}
    >
      <div className="flex flex-wrap flex-grow items-center my-1">
        <div className="w-16 text-lg bg-gray-200 rounded">&nbsp;</div>
      </div>
      <div className="flex flex-wrap items-center justify-end my-3" />
    </footer>
  </li>
);

export default MiniProductSkeleton;
export { MiniProductSkeleton };
