/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { ErrorMessage } from 'formik';

import { RadioField } from 'app/common/components';
import { CommunicationPreferenceType } from 'app/common/constants';
import { useFormatMessage } from 'app/common/hooks';

import messages from './CommunicationPreferences.messages';

const CommunicationPreferences = ({
  className,
  disabled = false,
  legendClassName = 'mb-4 text-gray-900 font-bold lg:text-lg',
  required = true
}) => {
  const formatMessage = useFormatMessage();
  return (
    <fieldset
      className={classNames('flex flex-col items-start justify-start mb-4', {
        [className]: !!className
      })}
      disabled={disabled}
    >
      <legend className={classNames({ [legendClassName]: !!legendClassName })}>
        <span>{formatMessage(messages.communicationPreference)}</span>
        {required && <span className="ml-1 text-red-500">*</span>}
      </legend>
      <ErrorMessage name="preferredCommunicationMethod">
        {message => (
          <div className="mt-2 text-sm text-red-600 lg:text-base">
            {message}
          </div>
        )}
      </ErrorMessage>
      <RadioField
        disabled={disabled}
        name="preferredCommunicationMethod"
        required={required}
        value={CommunicationPreferenceType.Phone.value}
      >
        {formatMessage(CommunicationPreferenceType.Phone.label)}
      </RadioField>
      <RadioField
        disabled={disabled}
        name="preferredCommunicationMethod"
        required={required}
        value={CommunicationPreferenceType.Text.value}
      >
        {formatMessage(CommunicationPreferenceType.Text.label)}
      </RadioField>
      <RadioField
        disabled={disabled}
        name="preferredCommunicationMethod"
        required={required}
        value={CommunicationPreferenceType.Email.value}
      >
        {formatMessage(CommunicationPreferenceType.Email.label)}
      </RadioField>
    </fieldset>
  );
};

export default CommunicationPreferences;
export { CommunicationPreferences };
