/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { createContext } from 'react';

import { initialState } from 'app/auth/services/AuthService';

/**
 * The AuthContext used for providing the user and authentication state to
 * the application components.
 *
 * The user information can be accessed using:
 *
 * ```js
 * const { user } = useContext(AuthContext);
 *
 * user.username
 * user.email
 * user.fullName
 * ```
 *
 * @type {Context<AuthServiceState>}
 */
export default createContext(initialState);
