/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useEffect } from 'react';

import useEventCallback from 'app/common/hooks/useEventCallback';

/**
 * Hook that allows a callback to be executed on an interval.
 *
 * This using a self-adjusting timer to properly handle delays in browser
 * activity due to events such as browser inactivity.
 *
 * @param {Function} callback the callback executed each interval
 * @param {number} delay the delay between each interval
 */
function useInterval(callback, delay) {
  const internalCallback = useEventCallback(callback, []);

  useEffect(() => {
    if (delay !== null) {
      let start = Date.now();
      let time = 0;
      let timeoutId;
      function instance() {
        time += delay;

        internalCallback();

        const drift = Date.now() - start - time;
        timeoutId = setTimeout(instance, delay - drift);
      }
      timeoutId = setTimeout(instance, delay);
      return () => clearTimeout(timeoutId);
    }
  }, [delay, internalCallback]);
}

export default useInterval;
