import { Environment } from '../../common/services';
import { useMemo } from 'react';

function useSuggestLocationInfo() {
  return useMemo(
    () => ({
      suggestStores: {
        baseUrl: Environment.get('INVENTORY_BASE_ENDPOINT', 'inventory'),
        addressSuggest: Environment.get(
          'INVENTORY_ADDRESS_SUGGEST_PATH',
          '/dealer-locator/address-suggest'
        ),
        findStores: Environment.get(
          'INVENTORY_FIND_STORES_PATH',
          '/dealer-locator'
        )
      }
    }),
    []
  );
}

export default useSuggestLocationInfo;
