/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { map } from 'lodash';

import { Link } from 'app/common/components';
import {
  useCatalogInfo,
  usePriceProducts,
  useFormatMessage
} from 'app/common/hooks';
import { addParams } from 'app/common/utils/PathUtils';

import { ProductSuggestionPrices } from './components';
import messages from './ProductSuggestions.messages';

const ProductSuggestions = ({ suggestions }) => {
  const formatMessage = useFormatMessage();
  const {
    productInfo: { routeBaseContext }
  } = useCatalogInfo();

  const prices = usePriceProducts(suggestions);

  return (
    <div className="py-2 border-t last:border-r-0 last:mr-0 lg:mr-4 lg:pr-4 lg:py-0 lg:border-t-0 lg:border-r">
      <div className="mb-4 text-2xl capitalize font-medium">
        {formatMessage(messages.products)}
      </div>
      <ul className="block list-none" role="presentation">
        {map(suggestions, suggestion => {
          const productUri = `${routeBaseContext}${suggestion.uri}`;

          return (
            <li
              key={suggestion.uri}
              className="w-64 mb-4 pb-4 border-b last:mb-0 last:pb-0 last:border-b-0"
            >
              <Link to={productUri} className="group flex">
                <figure className="basis-1/3 mr-2 rounded">
                  <img
                    className="w-full h-auto rounded"
                    src={`${addParams(suggestion.primaryAssetContentUrl, {
                      thumbnail: true
                    })}`}
                    alt={suggestion.primaryAssetAltText || suggestion.name}
                  />
                </figure>
                <div className="flex flex-col basis-2/3">
                  <div className="text-gray-700 group-hover:text-gray-900">
                    {suggestion.name}
                  </div>
                  <ProductSuggestionPrices
                    pricing={prices.get(suggestion.id)}
                  />
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ProductSuggestions;
