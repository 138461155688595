/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useReducer } from 'react';
import { withRouter } from 'react-router-dom';

import { AuthContext } from 'app/auth/contexts';
import { AuthService } from 'app/auth/services';
import {
  Button,
  Icon,
  Minicart,
  SiteLogo,
  TertiaryButton
} from 'app/common/components';
import { useFormatMessage, useMenuInfo } from 'app/common/hooks';

import {
  AccountDropdown,
  CourtesyDropdown,
  CurrencySelector,
  LocaleSelector,
  LocationNotResolvedCallout,
  MaintenanceBanner,
  Menu,
  MobileNav,
  Search
} from './components';
import messages from './Header.messages';
import { MobileSearchInput } from 'app/layout/components/Header/components/Search';
import * as PropTypes from 'prop-types';
import {
  initialState as searchInitialState,
  reducer as searchReducer
} from 'app/layout/components/Header/components/Search/searchReducer';
import StoreDropdown from './components/StoreDropdown';

const Header = props => {
  const { history } = props;
  const { fetchUrl: menuFetchUrl, headerMenuName } = useMenuInfo();
  const { isAuthenticated, isAuthenticating } = React.useContext(AuthContext);
  const [isMenuOpen, setMenuIsOpen] = React.useState(false);

  // Search properties
  const [isMobileSearchOpen, setMobileSearchOpen] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const searchReducerProps = useReducer(searchReducer, searchInitialState);

  React.useEffect(() => {
    return history.listen(() => {
      setMenuIsOpen(false);
    });
  }, [history]);

  return (
    <>
      <div className="flex flex-col shadow">
        <TopHeader
          {...{
            setMenuIsOpen,
            menuFetchUrl,
            headerMenuName,
            isAuthenticating,
            isAuthenticated,
            query,
            setQuery,
            setMobileSearchOpen,
            isMobileSearchOpen,
            searchReducerProps
          }}
        />
        <BottomHeader
          {...{
            query,
            setQuery,
            setMobileSearchOpen,
            isMobileSearchOpen,
            searchReducerProps
          }}
        />
        <LocationNotResolvedCallout />
        <MaintenanceBanner />

        <MobileSearchInput
          {...{
            query,
            setQuery,
            isMobileSearchOpen,
            setMobileSearchOpen,
            searchReducerProps
          }}
        />
      </div>
      {isMenuOpen && (
        <MobileNav
          isAuthenticated={isAuthenticated}
          isAuthenticating={isAuthenticating}
          isMenuOpen={isMenuOpen}
          setMenuIsOpen={setMenuIsOpen}
        />
      )}
    </>
  );
};

function MobileNavButton(props) {
  return (
    <div className="mobile-nav lg:hidden">
      <Button onClick={props.onClick}>
        <Icon name="bars" />
      </Button>
    </div>
  );
}

const BottomHeader = props => {
  const {
    query,
    setQuery,
    setMobileSearchOpen,
    isMobileSearchOpen,
    searchReducerProps
  } = props;

  return (
    <div className="flex -mt-px py-1 text-white bg-primary-900 h-15 sm:h-20 lg:py-0">
      <div className="container flex items-center w-full px-4">
        <StoreDropdown />
        <div className="items-center hidden lg:flex w-3/5 justify-end">
          <Search
            {...{
              desktop: true,
              query,
              setQuery,
              setMobileSearchOpen,
              isMobileSearchOpen,
              searchReducerProps
            }}
          />
        </div>
      </div>
    </div>
  );
};

const TopHeader = props => {
  const {
    setMenuIsOpen,
    menuFetchUrl,
    headerMenuName,
    isAuthenticating,
    isAuthenticated,
    query,
    setQuery,
    setMobileSearchOpen,
    isMobileSearchOpen,
    searchReducerProps
  } = props;
  const formatMessage = useFormatMessage();

  return (
    <nav
      role="navigation"
      aria-label={formatMessage(messages.navAriaLabel)}
      className="container flex items-center h-16 sm:h-20 pl-4 lg:pr-4"
    >
      <MobileNavButton onClick={() => setMenuIsOpen(true)} />
      <div className="w-2/10 flex-1 lg:flex-initial pr-7 lg:pr-0 xl:pr-7">
        <SiteLogo
          className="flex items-center h-full justify-center focus:outline-none focus:shadow-outline lg:justify-start"
          widgetProps={{
            className: 'block',
            innerProps: {
              className: 'block w-auto h-7 sm:h-10 md:h-16 mx-auto ',
              height: 101,
              width: 299
            }
          }}
        />
      </div>
      <div className="flex-1 h-full w-1/2 hidden lg:flex lg:w-11/20 xl:w-1/2 ">
        <Menu fetchUrl={menuFetchUrl} isActive={true} name={headerMenuName} />
      </div>
      <div className="flex items-center justify-end lg:w-9/20 xl:w-2/5 h-full">
        <div className="items-center hidden h-full lg:flex">
          {!isAuthenticating ? (
            <>
              {!isAuthenticated && (
                <TertiaryButton
                  className="h-full px-2 border-b-2 border-transparent hover:border-primary-700 focus:border-primary-700 whitespace-no-wrap"
                  onClick={() => AuthService.loginWithRedirect()}
                  textStyleClassName="font-medium"
                >
                  {formatMessage(messages.signIn)}
                </TertiaryButton>
              )}
              {!isAuthenticated && (
                <TertiaryButton
                  className="h-full px-2 border-b-2 border-transparent hover:border-gray-700 focus:border-gray-700 whitespace-no-wrap"
                  colorClassName="text-gray-700 hover:text-gray-900 focus:text-gray-800"
                  textStyleClassName="font-normal"
                  to="/register"
                >
                  {formatMessage(messages.register)}
                </TertiaryButton>
              )}
              {isAuthenticated && <AccountDropdown />}
            </>
          ) : (
            <div className="w-48 bg-gray-100">&nbsp;</div>
          )}
        </div>
        <Search
          {...{
            mobile: true,
            query,
            setQuery,
            setMobileSearchOpen,
            isMobileSearchOpen,
            searchReducerProps
          }}
        />
        <Minicart className="h-full" />
        <LocaleSelector className={'hidden lg:inline-block'} />
        <CurrencySelector className={'hidden lg:inline-block'} />
        <CourtesyDropdown />
      </div>
    </nav>
  );
};

MobileNavButton.propTypes = { onClick: PropTypes.func };

export default withRouter(Header);

export { BottomHeader };
