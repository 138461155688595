/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'MyAccount.Fleets.title',
    defaultMessage: 'Fleet Associations'
  },
  authCodeLabel: {
    id: 'MyAccount.Fleets.authCode.label',
    defaultMessage: 'Authorization Code'
  },
  authCodePlaceholder: {
    id: 'MyAccount.Fleets.authCode.placeholder',
    defaultMessage: 'A123'
  },
  authCodeSubmit: {
    id: 'MyAccount.Fleets.authCode.submit',
    defaultMessage: 'Apply'
  },
  authCodeRequired: {
    id: 'MyAccount.Fleets.authCode.required',
    defaultMessage: 'Authorization code is required'
  },
  authCodeInvalid: {
    id: 'MyAccount.Fleets.authCode.invalid',
    defaultMessage:
      'Authorization code not found. Please contact Fleet to obtain a new code.'
  },
  authCodeAlreadyAuthed: {
    id: 'MyAccount.Fleets.authCode.alreadyAuthed',
    defaultMessage: 'You are already associated with that Fleet.'
  },
  authCodeGenericError: {
    id: 'MyAccount.Fleets.authCode.genericError',
    defaultMessage:
      'We encountered an error trying to authorize your association. Please try again later.'
  },
  authedDate: {
    id: 'MyAccount.Fleets.authedDate',
    defaultMessage: 'Authorized On'
  },
  fleetName: {
    id: 'MyAccount.Fleets.fleetName',
    defaultMessage: 'Fleets'
  },
  noFleets: {
    id: 'MyAccount.Fleets.noFleets',
    defaultMessage: 'No Fleets'
  }
});
