/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, values } from 'lodash';
import PropTypes from 'prop-types';

import {
  AssetType,
  ItemChoicePricingModel,
  ItemChoiceSelectionType,
  PriceType
} from 'app/common/constants';
import { logError } from 'app/common/utils/ApiErrorUtils';
import { ProductOption } from 'app/product/components/options';
import { ConfigurableItemContext, PdpContext } from 'app/product/contexts';

import { ChooseMultiple, ChooseOne } from './components';

const ItemChoice = ({ option }) => {
  const { dispatch, itemChoices: selectedItemChoices } = React.useContext(
    ConfigurableItemContext
  );
  const { displayOrder, itemChoice, label } = option;
  const { minimumQuantity = 0 } = itemChoice;
  const required = minimumQuantity > 0;
  const error = get(
    React.useContext(PdpContext),
    `addToCartErrors.dependentItemConfigErrors.${itemChoice.choiceKey}[0].errorMessage`
  );

  return (
    <ProductOption
      displayOrder={displayOrder}
      error={error}
      label={label}
      required={required}
    >
      {ItemChoice.renderChoices(dispatch, itemChoice, selectedItemChoices)}
    </ProductOption>
  );
};

ItemChoice.renderChoices = (
  dispatchChoice,
  itemChoice,
  selectedItemChoices
) => {
  switch (itemChoice.selectionType) {
    case ItemChoiceSelectionType.CHOOSE_MULTIPLE:
      return (
        <ChooseMultiple
          dispatchChoice={dispatchChoice}
          itemChoice={itemChoice}
          selectedItemChoices={selectedItemChoices}
        />
      );
    case ItemChoiceSelectionType.CHOOSE_ONE:
      return (
        <ChooseOne
          dispatchChoice={dispatchChoice}
          itemChoice={itemChoice}
          selectedItemChoices={selectedItemChoices}
        />
      );
    default:
      logError({
        when: `rendering the choices for item choice '${itemChoice.choiceKey}' of unknown selection type ${itemChoice.selectionType}`
      });
      return null;
  }
};

ItemChoice.propTypes = {
  option: PropTypes.shape({
    displayOrder: PropTypes.number,
    itemChoice: PropTypes.shape({
      category: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        products: PropTypes.shape({
          content: PropTypes.array.isRequired
        }).isRequired
      }),
      choiceKey: PropTypes.string,
      defaultProduct: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }),
      defaultProductInCategory: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }),
      defaultVariant: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }),
      discountAllowed: PropTypes.bool,
      maximumQuantity: PropTypes.number,
      minimumQuantity: PropTypes.number,
      priceInfo: PropTypes.shape({
        price: PropTypes.shape({
          amount: PropTypes.number.isRequired,
          currency: PropTypes.string.isRequired
        }).isRequired,
        priceType: PropTypes.oneOf(values(PriceType)).isRequired,
        priceTypeDetails: PropTypes.shape(
          values(PriceType).reduce((acc, type) => {
            acc[type] = PropTypes.shape({
              bestPrice: PropTypes.shape({
                amount: PropTypes.number.isRequired,
                currency: PropTypes.string.isRequired
              }).isRequired,
              type: PropTypes.string.isRequired
            });
            return acc;
          }, {})
        )
      }),
      pricingModel: PropTypes.oneOf(values(ItemChoicePricingModel)),
      selectionType: PropTypes.oneOf(values(ItemChoiceSelectionType))
        .isRequired,
      specificChoices: PropTypes.arrayOf(
        PropTypes.shape({
          asset: PropTypes.shape({
            altText: PropTypes.string,
            /** HTML to embed in place of having a source URL */
            embedCode: PropTypes.string,
            title: PropTypes.string,
            type: PropTypes.oneOf(values(AssetType)),
            contentUrl: PropTypes.string
          }),
          discountAllowed: PropTypes.bool,
          nameOverride: PropTypes.string,
          priceInfo: PropTypes.shape({
            price: PropTypes.shape({
              amount: PropTypes.number.isRequired,
              currency: PropTypes.string.isRequired
            }).isRequired,
            priceType: PropTypes.oneOf(values(PriceType)).isRequired,
            priceTypeDetails: PropTypes.shape(
              values(PriceType).reduce((acc, type) => {
                acc[type] = PropTypes.shape({
                  bestPrice: PropTypes.shape({
                    amount: PropTypes.number.isRequired,
                    currency: PropTypes.string.isRequired
                  }).isRequired,
                  type: PropTypes.string.isRequired
                });
                return acc;
              }, {})
            )
          }),
          pricingModel: PropTypes.oneOf(values(ItemChoicePricingModel)),
          product: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
          }),
          type: PropTypes.oneOf(['PRODUCT', 'VARIANT']).isRequired,
          variant: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
          })
        })
      )
    }).isRequired,
    label: PropTypes.string
  }).isRequired
};

export default ItemChoice;
export { ItemChoice };
