/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import axios, { HttpStatusCode } from 'axios';
import qs from 'query-string';

import { Environment } from 'app/common/services';
import log from 'app/common/utils/Log';
import { isAbsoluteUrl, join } from 'app/common/utils/PathUtils';

import AuthState from './AuthState';
import SilentAuthRenewal from './SilentAuthRenewal';
import { getDefaultLocaleFromCache } from 'app/core/helpers/I18nProvider/services/LocaleCache';
import { isEmpty } from 'lodash';

const logger = log.getLogger('services.providers.DefaultOAuthProvider');

/**
 * {@link AuthServiceProvider} singleton used for authenticating using the Broadleaf default OAuth flows.
 *
 * @author [Nick Crum](https://github.com/ncrum)
 */
class DefaultOAuthProvider {
  constructor() {
    if (!DefaultOAuthProvider.instance) {
      // the clientId is initialized within the #initialize function
      this._clientId = null;

      // the serverId is initialized within the #initialize function
      this._serverId = null;

      this._clientIdAvailable = false;

      DefaultOAuthProvider.instance = this;
    }
    return DefaultOAuthProvider.instance;
  }

  /**
   * Initializes the provider by handling redirect callbacks, checking the user's
   * session, and reading the user information.
   *
   * @param {InitializationConfig} [config={}] the InitializationConfig
   * @param {string} [userScope] the USER scope
   * @return {Promise.<{ access_token: string, expires_in: number, [referrer]: string }>}
   */
  async initialize(config = {}, userScope = this.getUserScope()) {
    const { clientId, serverId } = config;
    this._clientId = clientId;
    this._serverId = serverId;
    this._clientIdAvailable = true;
    if (!this._clientId) {
      throw new Error('Must provide a client ID.');
    }

    if (
      window &&
      window.location &&
      getAuthorizeCallbackUrl().endsWith(window.location.pathname)
    ) {
      return await handleRedirectCallback(this._clientId, window.location);
    }

    return await this.getToken(userScope);
  }

  /**
   * <p>
   * Authorizes the current user for the given scope and retrieves a token.
   *
   * <p>
   * This function will execute the silent authentication flow,
   * which involves using a hidden iframe to attempt to authorize the user against
   * the authentication service. See {@link #getTokenSilently} for
   * how this method of authentication is handled.
   *
   * @return {Promise.<{ access_token: string, expires_in: number }>}
   */
  async getToken(scope) {
    return await getTokenSilently(this._clientId, scope);
  }

  /**
   * Returns a Promise that resolves with the user information.
   *
   * @return {Promise.<{ fullName: string, firstName: string, lastName: string, username: string, email: string }>}
   */
  async getUser() {
    return await getUser(this._clientId);
  }

  /**
   * Returns a Promise that resolves with the user expiration information.
   *
   * @returns {Promise<{exp: string, max: string}>}
   */
  async getUserExpiration() {
    return await getUserExpiration(this._clientId);
  }

  /**
   * Returns the user scope, typically `USER`.
   *
   * @returns {String}
   */
  getUserScope() {
    return getUserScope();
  }

  /**
   * Returns whether or not login is required. Typically this is true.
   *
   * @returns {Boolean}
   */
  isLoginRequired() {
    return isLoginRequired();
  }

  /**
   * Redirect the user's browser for login.
   */
  loginWithRedirect() {
    this.waitFor(() => this._clientIdAvailable === true).then(() => {
      const params = {
        client_id: this._clientId,
        blLocale: getDefaultLocaleFromCache(),
        redirect_uri: getAuthorizeCallbackUrl(),
        scope: getUserScope(),
        response_type: getAuthorizeResponseType(),
        state: AuthState.putGlobalState(getUserScope())
      };
      window.location.href = `${getAuthorizeUrl()}?${qs.stringify(params)}`;
    });
  }

  async loginWithCredentials(request, setSubmitting, setLoginErrorMessage) {
    this.waitFor(() => this._clientIdAvailable === true).then(async () => {
      const clientId = this._clientId;

      const params = {
        client_id: clientId,
        blLocale: getDefaultLocaleFromCache(),
        redirect_uri: getAuthorizeCallbackUrl(),
        scope: getUserScope(),
        response_type: getAuthorizeResponseType(),
        state: AuthState.putGlobalState(getUserScope())
      };
      const lockedParams = {
        client_id: clientId,
        blLocale: getDefaultLocaleFromCache()
      };
      await axios({
        method: 'post',
        data: qs.stringify(request),
        params: {
          client_id: clientId
        },
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: getEmbeddedLoginUrl()
      })
        .then(() => {
          handleRedirectCallback(clientId, window.location);
          setSubmitting(false);
          window.location.href = `${getAuthorizeUrl()}?${qs.stringify(params)}`;
        })
        .catch(error => {
          // If status is locked, just redirect to the locked page
          if (error.response.status === HttpStatusCode.Locked) {
            window.location.href = `${getAccountLockedUrl()}?${qs.stringify(
              lockedParams
            )}`;
            return;
          }
          if (!isEmpty(error)) {
            setLoginErrorMessage(error.response.data.message);
          }
          setSubmitting(false);
        });
    });
  }

  waitFor(condition) {
    const poll = execFunction => {
      if (condition()) {
        execFunction();
      } else {
        setTimeout(f => poll(execFunction), 200);
      }
    };
    return new Promise(poll);
  }

  /**
   * Executes the logout flow for the user and resolves when complete.
   *
   * @param {boolean} [redirectToLogin=false] - Whether to redirect to login
   *     rather than reloading the location.
   *
   * @return {Promise}
   */
  async logout(redirectToLogin = false) {
    try {
      await axios({
        method: 'GET',
        params: {
          client_id: this._clientId
        },
        secure: false,
        withCredentials: true,
        url: getLogoutUrl()
      });
    } catch (error) {
      logger.error('Unable reach service to logout: ' + error);
    } finally {
      if (redirectToLogin) {
        this.loginWithRedirect();
      } else if (window.location && window.location.reload) {
        window.location.href = `/?${getApplicationParameter()}=default`;
      }
    }
  }

  changePassword() {
    const params = {
      client_id: this._clientId
    };
    window.location.href = `${getChangePasswordUrl()}?${qs.stringify(params)}`;
  }

  forgotPassword() {
    const params = {
      client_id: this._clientId
    };
    window.location.href = `${getForgotPasswordUrl()}?${qs.stringify(params)}`;
  }
}

/**
 * Processes the callback after the browser is redirected back from {@link #login}.
 *
 * <p>
 * In certain cases, specifically when the user is redirected back to the application
 * after successfully logging in, this function will execute the authentication
 * callback flow, which involves parsing the authorization code and state parameter
 * from the browser's location, and then using this information to retrieve the user's
 * access token. This will only take place when the browser's location matches the
 * {@link #getAuthorizeCallbackUrl}. See {@link #handleRedirectCallback} for
 * how this method of authentication is handled.
 * Authorizes the current user.
 *
 * @param  {string} clientId the client ID
 * @param  {Location} location the current location
 * @return {Promise.<{ access_token: string, expires_in: number, referrer: string }>} resolves or rejects when finished
 */
async function handleRedirectCallback(clientId, location) {
  const { code, state, error, error_description } = qs.parse(location.search);

  if (!AuthState.validateGlobalState(state)) {
    AuthState.deleteGlobalState(state);
    return Promise.reject({
      error: 'invalid_state',
      error_description: 'Invalid state parameter provided'
    });
  }

  if (code === undefined && error !== undefined) {
    return Promise.reject({ error, error_description });
  }

  const { scope } = AuthState.decode(state);
  AuthState.deleteGlobalState(state);
  const payload = await requestAccessToken(
    clientId,
    scope,
    code,
    getAuthorizeCallbackUrl()
  );

  const { referrer = '/' } = AuthState.decode(state);
  return {
    ...payload,
    referrer
  };
}

/**
 * <p>
 * This helper function attempts to authorize for the user scope and return
 * an authorization code to be exchanged for access tokens.
 *
 * <p>
 * This is the most common way of retrieving an access token for an authenticated
 * user. If the user is logging in, then the {@link #handleRedirectCallback} will
 * be used for processing the authorization callback.
 *
 * @return {Promise.<{ access_token: string, expires_in: number}>} a Promise with the code or error response
 */
async function getTokenSilently(clientId, scope) {
  const code = await executeSilentAuthorization(clientId, scope);
  return await requestAccessToken(
    clientId,
    scope,
    code,
    getSilentCallbackUrl()
  );
}

function executeSilentAuthorization(clientId, scope) {
  return new Promise((resolve, reject) => {
    try {
      const renewal = new SilentAuthRenewal({
        client_id: clientId,
        errorCallback: reject,
        eventType: getSilentEventType(),
        redirect_uri: getSilentCallbackUrl(),
        scope: scope,
        successCallback: resolve,
        timeout: getSilentTimeout(),
        url: getAuthorizeUrl()
      });

      renewal.execute();
    } catch (err) {
      reject(err);
    }
  });
}

/**
 * <p>
 * This helper function attempts to retrieve an access token.
 *
 * <p>
 * This is used by the {@link #getTokenSilently} and the
 * {@link #handleRedirectCallback} in order to retrieve an access token for an
 * authorization code when using th authorization_code flow.
 *
 * @return {Promise.<{ access_token: string, expires_in: number }>} a Promise with the token or error response
 */
async function requestAccessToken(clientId, scope, code, redirectUri) {
  const response = await axios({
    method: 'post',
    params: {
      client_id: clientId,
      redirect_uri: redirectUri,
      grant_type: 'authorization_code',
      scope: scope,
      code: code
    },
    secure: false,
    withCredentials: true,
    url: getTokenUrl()
  });

  return response.data;
}

/**
 * This function will return a Promise that resolves with the user information
 * for the provided clientId.
 *
 * @param clientId the clientId the user is associated with
 * @returns {Promise<*>}
 */
async function getUser(clientId) {
  const response = await axios({
    method: 'get',
    params: {
      client_id: clientId
    },
    secure: false,
    withCredentials: true,
    url: getUserUrl()
  });

  return response.data;
}

/**
 * This function will return a Promise that resolves with the user expiration
 * for the provided clientId.
 *
 * @param clientId the clientId the user is associated with
 * @returns {Promise<{ exp: string, max: string }>}
 */
async function getUserExpiration(clientId) {
  const response = await axios({
    method: 'get',
    params: {
      client_id: clientId
    },
    secure: false,
    withCredentials: true,
    url: getUserUrl()
  });

  const exp = response.headers[getExpirationHeader()];
  const max = response.headers[getMaxExpirationHeader()];
  return { exp, max };
}

function getAuthorizeUrl() {
  return Environment.get(
    'auth.provider.oauth.authorizeurl',
    '/auth/oauth/authorize'
  );
}

function getTokenUrl() {
  return Environment.get('auth.provider.oauth.tokenurl', '/oauth/token');
}

function isLoginRequired() {
  return Environment.getBoolean('auth.provider.oauth.loginrequired', false);
}

function getLogoutUrl() {
  return Environment.get('auth.provider.oauth.logouturl', '/auth/logout');
}

function getChangePasswordUrl() {
  return Environment.get(
    'auth.provider.oauth.changepasswordurl',
    '/auth/change-password'
  );
}

function getForgotPasswordUrl() {
  return Environment.get(
    'auth.provider.oauth.forgotpasswordurl',
    '/auth/request-password-reset'
  );
}

function getAccountLockedUrl() {
  return Environment.get(
    'auth.provider.oauth.accountlockedurl',
    '/auth/account-locked-inactivity'
  );
}

function getUserUrl() {
  return Environment.get('auth.provider.oauth.userurl', '/auth/user');
}

function getUserScope() {
  return Environment.get('auth.provider.oauth.userscope', 'USER CUSTOMER_USER');
}

function getExpirationHeader() {
  return Environment.get('auth.provider.oauth.headers.expiration', 'exp');
}

function getMaxExpirationHeader() {
  return Environment.get('auth.provider.oauth.headers.maxexpiration', 'max');
}

function getAuthorizeCallbackUrl() {
  const callbackUrl = Environment.get(
    'auth.provider.oauth.callbackurl',
    '/callback'
  );
  if (isAbsoluteUrl(callbackUrl)) {
    return callbackUrl;
  }

  if (!callbackUrl.startsWith('/')) {
    return prependOrigin(`/${callbackUrl}`);
  }

  return prependOrigin(callbackUrl);
}

function getSilentCallbackUrl() {
  const silentCallbackUrl = Environment.get(
    'auth.provider.oauth.silentcallbackurl',
    '/silent-callback.html'
  );
  if (isAbsoluteUrl(silentCallbackUrl)) {
    return silentCallbackUrl;
  }

  if (!silentCallbackUrl.startsWith('/')) {
    return prependOrigin(`/${silentCallbackUrl}`);
  }

  return prependOrigin(silentCallbackUrl);
}

function getEmbeddedLoginUrl() {
  return Environment.get(
    'auth.provider.oauth.embeddedloginurl',
    '/auth/embedded/login'
  );
}

function prependOrigin(url) {
  const publicUrl = Environment.get('public.url');
  return join(window.location.origin, publicUrl, url);
}

function getAuthorizeResponseType() {
  return Environment.get('auth.provider.oauth.responsetype', 'code');
}

function getSilentEventType() {
  return Environment.get('auth.provider.oauth.eventtype', 'authorization_code');
}

function getSilentTimeout() {
  return Environment.get('auth.provider.oauth.silenttimeout', 7000);
}

function getApplicationParameter() {
  return Environment.get(
    'tenant.resolver.application.parameter',
    'application'
  );
}

const instance = new DefaultOAuthProvider();

export function getInstance() {
  return instance;
}

export default instance;
