/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty, join, map, size } from 'lodash';

import { Icon, LoadingIcon } from 'app/common/components';
import { useFormatMessage, useToggle } from 'app/common/hooks';

import messages from './CartOrListErrors.messages';

const CartOrListErrors = ({ errors, isCart = false, clearErrors }) => {
  const formatMessage = useFormatMessage();
  const [dismissed, toggleDismissed] = useToggle();
  const [dismissing, toggleDismissing] = useToggle();

  if (isEmpty(errors) || dismissed) {
    return null;
  }

  return (
    <aside className="flex items-center w-full mb-4 px-2 py-2 text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-3 lg:text-lg">
      <Icon className="mr-3 md:mr-4" name="exclamation-circle" />
      <ul className="flex flex-col">
        {map(errors, (value, type) => {
          if (type === 'NON_STOCKING') {
            const partNumbers = isCart ? value.partNumbers : value;
            const requiresRemoval = isCart && value.requiresRemoval;
            return (
              <li className="flex w-full" key={type}>
                {formatMessage(messages.nonStocking, {
                  isCart,
                  parts: join(partNumbers, "', '"),
                  quantity: size(partNumbers),
                  requiresRemoval
                })}
              </li>
            );
          }

          return (
            <li className="flex w-full" key={type}>
              {formatMessage(messages.generalError)}
            </li>
          );
        })}
      </ul>
      <div className="flex justify-end flex-grow ml-4">
        <button
          aria-label={formatMessage(messages.dismiss)}
          className="inline-flex items-start focus:outline-none focus:shadow-outline"
          onClick={async () => {
            if (dismissing) {
              return;
            }

            toggleDismissing();

            if (!!clearErrors) {
              await clearErrors();
            }

            toggleDismissing();
            toggleDismissed();
          }}
          title={formatMessage(messages.dismiss)}
          type="button"
        >
          {!dismissing ? (
            <Icon name="times" />
          ) : (
            <LoadingIcon color="border-red-500" />
          )}
        </button>
      </div>
    </aside>
  );
};

export default CartOrListErrors;
export { CartOrListErrors };
