/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { Environment, LocalStorageCache } from 'app/common/services';

/**
 * LocalStorageCache for holding the last resolved default currency.
 *
 * @type {LocalStorageCache}
 */
const CurrencyCache = new LocalStorageCache(
  Environment.get('currency.localstorage.cache.name', 'CurrencyCache'),
  {
    enableLocal: true,
    enableSession: true,
    ttl: 0
  }
);

function getDefaultCurrencyFromCache() {
  return CurrencyCache.get(
    Environment.get(
      'currency.localstorage.cache.defaultCurrency.key',
      'defaultCurrency'
    )
  );
}

function putDefaultCurrencyInCache(currency) {
  CurrencyCache.put(
    Environment.get(
      'currency.localstorage.cache.defaultCurrency.key',
      'defaultCurrency'
    ),
    currency
  );
}

export default CurrencyCache;
export {
  getDefaultCurrencyFromCache,
  putDefaultCurrencyInCache,
  CurrencyCache
};
