/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, merge } from 'lodash';
import qs from 'query-string';

import { useLocation } from 'app/common/hooks';
import { Environment } from 'app/common/services';
import { addParams } from 'app/common/utils/PathUtils';

/**
 * @callback
 * @typedef {Function} setApplicationParam
 * @param {String} [value] - New value to which to update the application parameter
 */

/**
 * Returns the current state of the application parameter along with a method to update its value.
 *
 * @return {Array.<String|undefined, setApplicationParam>}
 */
function useApplicationParameter() {
  const params = qs.parse(useLocation().search);
  const applicationParam = Environment.get(
    'tenant.resolver.application.parameter',
    'application'
  );
  const [param, setParam] = React.useState(get(params, applicationParam));
  const setApplicationParam = React.useCallback(
    value => {
      window.location.search = addParams(
        undefined,
        merge(params, { [applicationParam]: value })
      );
    },
    [params, applicationParam]
  );

  React.useEffect(() => {
    setParam(get(params, applicationParam));
  }, [params, applicationParam]);

  return [param, setApplicationParam];
}

export default useApplicationParameter;
