/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import { CartContext } from 'app/common/contexts';
import { logError } from 'app/common/utils/ApiErrorUtils';
import { PrimaryButton } from 'app/common/components';
import { useCartInfo, useRestApi } from 'app/common/hooks';
import { useFormatMessage } from 'app/common/hooks';

import messages from '../../CSRRibbon.messages';

const TransferCartToCustomer = props => {
  const formatMessage = useFormatMessage();
  const { cart, setCart } = React.useContext(CartContext);

  const { baseUrl, transferCartToCustomerContextPath } =
    useCartInfo().operations;
  const transferCartToCustomerUrl = `${baseUrl}${transferCartToCustomerContextPath}`;
  const config = React.useMemo(() => ({ method: 'put', scope: 'CSR' }), []);
  const {
    error,
    exception,
    sendCallback: transferCartToCustomer
  } = useRestApi(transferCartToCustomerUrl, config, false, true);

  React.useEffect(() => {
    if (error) {
      logError({
        ...exception,
        when: 'transferring cart to customer'
      });
    }
  }, [error, exception]);

  if (!cart || cart.status !== 'CSR_OWNED') {
    return null;
  }

  return (
    <PrimaryButton
      className={props.className}
      size={PrimaryButton.Size.SMALL}
      onClick={() => {
        transferCartToCustomer({
          data: cart
        }).then(() => {
          setCart();
        });
      }}
    >
      {formatMessage(messages.transferCartToCustomer)}
    </PrimaryButton>
  );
};

export default TransferCartToCustomer;
