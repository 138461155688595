/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';

function useStateProvinceRegionSelectisoValues() {
  return useMemo(
    () => ({
      CA: [
        { value: 'AB', isoValue: 'CA-AB', label: 'Alberta' },
        {
          value: 'BC',
          isoValue: 'CA-BC',
          label: 'British Columbia (Colombie-Britannique)'
        },
        { value: 'MB', isoValue: 'CA-MB', label: 'Manitoba' },
        {
          value: 'NB',
          isoValue: 'CA-NB',
          label: 'New Brunswick (Nouveau-Brunswick)'
        },
        {
          value: 'NL',
          isoValue: 'CA-NL',
          label: 'Newfoundland and Labrador (Terre-Neuve)'
        },
        {
          value: 'NS',
          isoValue: 'CA-NS',
          label: 'Nova Scotia (Nouvelle-Écosse)'
        },
        { value: 'ON', isoValue: 'CA-ON', label: 'Ontario' },
        {
          value: 'PE',
          isoValue: 'CA-PE',
          label: 'Prince Edward Island (Île-du-Prince-Édouard)'
        },
        { value: 'QC', isoValue: 'CA-QC', label: 'Quebec (Québec)' },
        { value: 'SK', isoValue: 'CA-SK', label: 'Saskatchewan' },
        {
          value: 'NT',
          isoValue: 'CA-NT',
          label: 'Northwest Territories (Territoires du Nord-Ouest)'
        },
        { value: 'NU', isoValue: 'CA-NU', label: 'Nunavut' },
        {
          value: 'YT',
          isoValue: 'CA-YT',
          label: 'Yukon Territory (Teritoire du Yukon)'
        }
      ],
      ES: [
        { value: 'AN', isoValue: 'ES-AN', label: 'Andalucía' },
        { value: 'AL', isoValue: 'ES-AL', label: 'Almería' },
        { value: 'CA', isoValue: 'ES-CA', label: 'Cádiz' },
        { value: 'CO', isoValue: 'ES-CO', label: 'Córdoba' },
        { value: 'GR', isoValue: 'ES-GR', label: 'Granada' },
        { value: 'H', isoValue: 'ES-H', label: 'Huelva' },
        { value: 'J', isoValue: 'ES-J', label: 'Jaén' },
        { value: 'MA', isoValue: 'ES-MA', label: 'Málaga' },
        { value: 'SE', isoValue: 'ES-SE', label: 'Sevilla' },
        { value: 'AR', isoValue: 'ES-AR', label: 'Aragón' },
        { value: 'HU', isoValue: 'ES-HU', label: 'Huesca' },
        { value: 'TE', isoValue: 'ES-TE', label: 'Teruel' },
        { value: 'Z', isoValue: 'ES-Z', label: 'Zaragoza' },
        { value: 'O', isoValue: 'ES-O', label: 'Asturias' },
        { value: 'CN', isoValue: 'ES-CN', label: 'Canarias' },
        { value: 'GC', isoValue: 'ES-GC', label: 'Las Palmas' },
        { value: 'TF', isoValue: 'ES-TF', label: 'Santa Cruz De Tenerife' },
        { value: 'S', isoValue: 'ES-S', label: 'Cantabria' },
        { value: 'CM', isoValue: 'ES-CM', label: 'Castilla-La Mancha' },
        { value: 'AB', isoValue: 'ES-AB', label: 'Albacete' },
        { value: 'CR', isoValue: 'ES-CR', label: 'Ciudad Real' },
        { value: 'CU', isoValue: 'ES-CU', label: 'Cuenca' },
        { value: 'GU', isoValue: 'ES-GU', label: 'Guadalajara' },
        { value: 'TO', isoValue: 'ES-TO', label: 'Toledo' },
        { value: 'CL', isoValue: 'ES-CL', label: 'Castilla y León' },
        { value: 'AV', isoValue: 'ES-AV', label: 'Ávila' },
        { value: 'BU', isoValue: 'ES-BU', label: 'Burgos' },
        { value: 'LE', isoValue: 'ES-LE', label: 'León' },
        { value: 'P', isoValue: 'ES-P', label: 'Palencia' },
        { value: 'SA', isoValue: 'ES-SA', label: 'Salamanca' },
        { value: 'SG', isoValue: 'ES-SG', label: 'Segovia' },
        { value: 'SO', isoValue: 'ES-SO', label: 'Soria' },
        { value: 'VA', isoValue: 'ES-VA', label: 'Valladolid' },
        { value: 'ZA', isoValue: 'ES-ZA', label: 'Zamora' },
        { value: 'CT', isoValue: 'ES-CT', label: 'Cataluña' },
        { value: 'B', isoValue: 'ES-B', label: 'Barcelona' },
        { value: 'GE', isoValue: 'ES-GE', label: 'Gerona' },
        { value: 'L', isoValue: 'ES-L', label: 'Lérida' },
        { value: 'T', isoValue: 'ES-T', label: 'Tarragona' },
        { value: 'EX', isoValue: 'ES-EX', label: 'Extremadura' },
        { value: 'BA', isoValue: 'ES-BA', label: 'Badajoz' },
        { value: 'CC', isoValue: 'ES-CC', label: 'Cáceres' },
        { value: 'GA', isoValue: 'ES-GA', label: 'Galicia' },
        { value: 'C', isoValue: 'ES-C', label: 'La Coruña' },
        { value: 'LU', isoValue: 'ES-LU', label: 'Lugo' },
        { value: 'OR', isoValue: 'ES-OR', label: 'Orense' },
        { value: 'PO', isoValue: 'ES-PO', label: 'Pontevedra' },
        { value: 'PM', isoValue: 'ES-PM', label: 'Baleares' },
        { value: 'LO', isoValue: 'ES-LO', label: 'La Rioja' },
        { value: 'M', isoValue: 'ES-M', label: 'Madrid' },
        { value: 'MU', isoValue: 'ES-MU', label: 'Murcia' },
        { value: 'NA', isoValue: 'ES-NA', label: 'Navarra' },
        { value: 'PV', isoValue: 'ES-PV', label: 'País Vasco' },
        { value: 'VI', isoValue: 'ES-VI', label: 'Álava' },
        { value: 'SS', isoValue: 'ES-SS', label: 'Guipúzcoa' },
        { value: 'BI', isoValue: 'ES-BI', label: 'Vizcaya' },
        { value: 'VC', isoValue: 'ES-VC', label: 'Valenciana, Comunidad' },
        { value: 'A', isoValue: 'ES-A', label: 'Alicante' },
        { value: 'CS', isoValue: 'ES-CS', label: 'Castellón' },
        { value: 'V', isoValue: 'ES-V', label: 'Valencia' }
      ],
      FR: [
        { value: 'A', isoValue: 'FR-A', label: 'Alsace' },
        { value: '67', isoValue: 'FR-67', label: 'Bas-Rhin' },
        { value: '68', isoValue: 'FR-68', label: 'Haut-Rhin' },
        { value: 'B', isoValue: 'FR-B', label: 'Aquitaine' },
        { value: '79', isoValue: 'FR-79', label: 'Deux-Sèvres' },
        { value: '24', isoValue: 'FR-24', label: 'Dordogne' },
        { value: '33', isoValue: 'FR-33', label: 'Gironde' },
        { value: '40', isoValue: 'FR-40', label: 'Landes' },
        { value: '47', isoValue: 'FR-47', label: 'Lot-et-Garonne' },
        { value: '64', isoValue: 'FR-64', label: 'Pyrénées-Atlantiques' },
        { value: 'C', isoValue: 'FR-C', label: 'Auvergne' },
        { value: '03', isoValue: 'FR-03', label: 'Allier' },
        { value: '15', isoValue: 'FR-15', label: 'Cantal' },
        { value: '43', isoValue: 'FR-43', label: 'Haute-Loire' },
        { value: '63', isoValue: 'FR-63', label: 'Puy-de-Dôme' },
        { value: 'P', isoValue: 'FR-P', label: 'Basse-Normandie' },
        { value: '14', isoValue: 'FR-14', label: 'Calvados' },
        { value: '50', isoValue: 'FR-50', label: 'Manche' },
        { value: '61', isoValue: 'FR-61', label: 'Orne' },
        { value: 'D', isoValue: 'FR-D', label: 'Bourgogne' },
        { value: '21', isoValue: 'FR-21', label: "Côte-d'Or" },
        { value: '58', isoValue: 'FR-58', label: 'Nièvre' },
        { value: '71', isoValue: 'FR-71', label: 'Saône-et-Loire' },
        { value: '89', isoValue: 'FR-89', label: 'Yonne' },
        { value: 'E', isoValue: 'FR-E', label: 'Bretagne' },
        { value: '22', isoValue: 'FR-22', label: "Cotes-d'Armor" },
        { value: '29', isoValue: 'FR-29', label: 'Finistère' },
        { value: '35', isoValue: 'FR-35', label: 'Ille-et-Vilaine' },
        { value: '56', isoValue: 'FR-56', label: 'Morbihan' },
        { value: 'F', isoValue: 'FR-F', label: 'Centre' },
        { value: '18', isoValue: 'FR-18', label: 'Cher' },
        { value: '28', isoValue: 'FR-28', label: 'Eure-et-Loir' },
        { value: '36', isoValue: 'FR-36', label: 'Indre' },
        { value: '37', isoValue: 'FR-37', label: 'Indre-et-Loire' },
        { value: '41', isoValue: 'FR-41', label: 'Loir-et-Cher' },
        { value: '45', isoValue: 'FR-45', label: 'Loiret' },
        { value: 'G', isoValue: 'FR-G', label: 'Champagne-Ardenne' },
        { value: '08', isoValue: 'FR-08', label: 'Ardennes' },
        { value: '10', isoValue: 'FR-10', label: 'Aube' },
        { value: '52', isoValue: 'FR-52', label: 'Haute-Marne' },
        { value: '51', isoValue: 'FR-51', label: 'Marne' },
        { value: 'H', isoValue: 'FR-H', label: 'Corse' },
        { value: '2A', isoValue: 'FR-2A', label: 'Corse-du-Sud' },
        { value: '2B', isoValue: 'FR-2B', label: 'Haute-Corse' },
        { value: 'I', isoValue: 'FR-I', label: 'Franche-Comté' },
        { value: '25', isoValue: 'FR-25', label: 'Doubs' },
        { value: '70', isoValue: 'FR-70', label: 'Haute-Saône' },
        { value: '39', isoValue: 'FR-39', label: 'Jura' },
        { value: '90', isoValue: 'FR-90', label: 'Territoire de Belfort' },
        { value: 'Q', isoValue: 'FR-Q', label: 'Haute-Normandie' },
        { value: '27', isoValue: 'FR-27', label: 'Eure' },
        { value: '76', isoValue: 'FR-76', label: 'Seine-Maritime' },
        { value: 'J', isoValue: 'FR-J', label: 'Île-de-France' },
        { value: '91', isoValue: 'FR-91', label: 'Essonne' },
        { value: '92', isoValue: 'FR-92', label: 'Hauts-de-Seine' },
        { value: '75', isoValue: 'FR-75', label: 'Paris' },
        { value: '77', isoValue: 'FR-77', label: 'Seine-et-Marne' },
        { value: '93', isoValue: 'FR-93', label: 'Seine-Saint-Denis' },
        { value: '94', isoValue: 'FR-94', label: 'Val-de-Marne' },
        { value: '95', isoValue: 'FR-95', label: "Val-d'Oise" },
        { value: '78', isoValue: 'FR-78', label: 'Yvelines' },
        { value: 'K', isoValue: 'FR-K', label: 'Languedoc-Roussillon' },
        { value: '11', isoValue: 'FR-11', label: 'Aude' },
        { value: '30', isoValue: 'FR-30', label: 'Gard' },
        { value: '34', isoValue: 'FR-34', label: 'Hérault' },
        { value: '48', isoValue: 'FR-48', label: 'Lozère' },
        { value: '66', isoValue: 'FR-66', label: 'Pyrénées-Orientales' },
        { value: 'L', isoValue: 'FR-L', label: 'Limousin' },
        { value: '19', isoValue: 'FR-19', label: 'Corrèze' },
        { value: '23', isoValue: 'FR-23', label: 'Creuse' },
        { value: '87', isoValue: 'FR-87', label: 'Haute-Vienne' },
        { value: 'M', isoValue: 'FR-M', label: 'Lorraine' },
        { value: '54', isoValue: 'FR-54', label: 'Meurthe-et-Moselle' },
        { value: '55', isoValue: 'FR-55', label: 'Meuse' },
        { value: '57', isoValue: 'FR-57', label: 'Moselle' },
        { value: '88', isoValue: 'FR-88', label: 'Vosges' },
        { value: 'N', isoValue: 'FR-N', label: 'Midi-Pyrénées' },
        { value: '09', isoValue: 'FR-09', label: 'Ariège' },
        { value: '12', isoValue: 'FR-12', label: 'Aveyron' },
        { value: '32', isoValue: 'FR-32', label: 'Gers' },
        { value: '31', isoValue: 'FR-31', label: 'Haute-Garonne' },
        { value: '65', isoValue: 'FR-65', label: 'Hautes-Pyrénées' },
        { value: '46', isoValue: 'FR-46', label: 'Lot' },
        { value: '81', isoValue: 'FR-81', label: 'Tarn' },
        { value: '82', isoValue: 'FR-82', label: 'Tarn-et-Garonne' },
        { value: 'O', isoValue: 'FR-O', label: 'Nord-Pas-de-Calais' },
        { value: '59', isoValue: 'FR-59', label: 'Nord' },
        { value: '62', isoValue: 'FR-62', label: 'Pas-de-Calais' },
        { value: 'R', isoValue: 'FR-R', label: 'Pays de la Loire' },
        { value: '44', isoValue: 'FR-44', label: 'Loire-Atlantique' },
        { value: '49', isoValue: 'FR-49', label: 'Maine-et-Loire' },
        { value: '53', isoValue: 'FR-53', label: 'Mayenne' },
        { value: '72', isoValue: 'FR-72', label: 'Sarthe' },
        { value: '85', isoValue: 'FR-85', label: 'Vendée' },
        { value: 'S', isoValue: 'FR-S', label: 'Picardie' },
        { value: '02', isoValue: 'FR-02', label: 'Aisne' },
        { value: '60', isoValue: 'FR-60', label: 'Oise' },
        { value: '80', isoValue: 'FR-80', label: 'Somme' },
        { value: 'T', isoValue: 'FR-T', label: 'Poitou-Charentes' },
        { value: '16', isoValue: 'FR-16', label: 'Charente' },
        { value: '17', isoValue: 'FR-17', label: 'Charente-Maritime' },
        { value: '86', isoValue: 'FR-86', label: 'Vienne' },
        { value: 'U', isoValue: 'FR-U', label: "Provence-Alpes-Côte d'Azur" },
        { value: '04', isoValue: 'FR-04', label: 'Alpes-de-Haute-Provence' },
        { value: '06', isoValue: 'FR-06', label: 'Alpes-Maritimes' },
        { value: '13', isoValue: 'FR-13', label: 'Bauches-du-Rhône' },
        { value: '05', isoValue: 'FR-05', label: 'Hautes-Alpes' },
        { value: '83', isoValue: 'FR-83', label: 'Var' },
        { value: '84', isoValue: 'FR-84', label: 'Vaucluse' },
        { value: 'V', isoValue: 'FR-V', label: 'Rhône-Alpes' },
        { value: '01', isoValue: 'FR-01', label: 'Ain' },
        { value: '07', isoValue: 'FR-07', label: 'Ardèche' },
        { value: '26', isoValue: 'FR-26', label: 'Drôme' },
        { value: '74', isoValue: 'FR-74', label: 'Haute-Savoie' },
        { value: '38', isoValue: 'FR-38', label: 'Isère' },
        { value: '42', isoValue: 'FR-42', label: 'Loire' },
        { value: '69', isoValue: 'FR-69', label: 'Rhône' },
        { value: '73', isoValue: 'FR-73', label: 'Savoie' },
        { value: 'GP', isoValue: 'FR-GP', label: 'Guadeloupe' },
        { value: 'GF', isoValue: 'FR-GF', label: 'Guyane (francaise)' },
        { value: 'MQ', isoValue: 'FR-MQ', label: 'Martinique' },
        { value: 'RE', isoValue: 'FR-RE', label: 'Réunion' },
        { value: 'YT', isoValue: 'FR-YT', label: 'Mayotte' },
        { value: 'PM', isoValue: 'FR-PM', label: 'Saint-Pierre-et-Miquelon' },
        { value: 'NC', isoValue: 'FR-NC', label: 'Nouvelle-Calédonie' },
        { value: 'PF', isoValue: 'FR-PF', label: 'Polynésie française' },
        { value: 'TF', isoValue: 'FR-TF', label: 'Terres Australes' },
        { value: 'WF', isoValue: 'FR-WF', label: 'Wallis et Futuna' }
      ],
      GB: [
        { value: 'CHA', isoValue: 'GB-CHA', label: 'Channel Islands' },
        { value: 'GSY', isoValue: 'GB-GSY', label: 'Guernsey [Guernesey]' },
        { value: 'JSY', isoValue: 'GB-JSY', label: 'Jersey' },
        { value: 'ENG', isoValue: 'GB-ENG', label: 'England' },
        { value: 'BDG', isoValue: 'GB-BDG', label: 'Barking and Dagenham' },
        { value: 'BNE', isoValue: 'GB-BNE', label: 'Barnet' },
        { value: 'BNS', isoValue: 'GB-BNS', label: 'Barnsley' },
        {
          value: 'BAS',
          isoValue: 'GB-BAS',
          label: 'Bath and North East Somerset'
        },
        { value: 'BDF', isoValue: 'GB-BDF', label: 'Bedfordshire' },
        { value: 'BEX', isoValue: 'GB-BEX', label: 'Bexley' },
        { value: 'BIR', isoValue: 'GB-BIR', label: 'Birmingham (City of)' },
        { value: 'BBD', isoValue: 'GB-BBD', label: 'Blackburn with Darwen' },
        { value: 'BPL', isoValue: 'GB-BPL', label: 'Blackpool' },
        { value: 'BOL', isoValue: 'GB-BOL', label: 'Bolton' },
        { value: 'BMH', isoValue: 'GB-BMH', label: 'Bournemouth' },
        { value: 'BRC', isoValue: 'GB-BRC', label: 'Bracknell Forest' },
        { value: 'BRD', isoValue: 'GB-BRD', label: 'Bradford (City of)' },
        { value: 'BEN', isoValue: 'GB-BEN', label: 'Brent' },
        { value: 'BNH', isoValue: 'GB-BNH', label: 'Brighton and Hove' },
        { value: 'BST', isoValue: 'GB-BST', label: 'Bristol, City of' },
        { value: 'BRY', isoValue: 'GB-BRY', label: 'Bromley' },
        { value: 'BKM', isoValue: 'GB-BKM', label: 'Buckinghamshire' },
        { value: 'BUR', isoValue: 'GB-BUR', label: 'Bury' },
        { value: 'CLD', isoValue: 'GB-CLD', label: 'Calderdale' },
        { value: 'CAM', isoValue: 'GB-CAM', label: 'Cambridgeshire' },
        { value: 'CMD', isoValue: 'GB-CMD', label: 'Camden' },
        { value: 'CHS', isoValue: 'GB-CHS', label: 'Cheshire' },
        { value: 'CON', isoValue: 'GB-CON', label: 'Cornwall' },
        { value: 'COV', isoValue: 'GB-COV', label: 'Coventry (City of)' },
        { value: 'CRY', isoValue: 'GB-CRY', label: 'Croydon' },
        { value: 'CMA', isoValue: 'GB-CMA', label: 'Cumbria' },
        { value: 'DAL', isoValue: 'GB-DAL', label: 'Darlington' },
        { value: 'DER', isoValue: 'GB-DER', label: 'Derby (City of)' },
        { value: 'DBY', isoValue: 'GB-DBY', label: 'Derbyshire' },
        { value: 'DEV', isoValue: 'GB-DEV', label: 'Devon' },
        { value: 'DNC', isoValue: 'GB-DNC', label: 'Doncaster' },
        { value: 'DOR', isoValue: 'GB-DOR', label: 'Dorset' },
        { value: 'DUD', isoValue: 'GB-DUD', label: 'Dudley' },
        { value: 'DUR', isoValue: 'GB-DUR', label: 'Durharn' },
        { value: 'EAL', isoValue: 'GB-EAL', label: 'Ealing' },
        { value: 'ERY', isoValue: 'GB-ERY', label: 'East Riding of Yorkshire' },
        { value: 'ESX', isoValue: 'GB-ESX', label: 'East Sussex' },
        { value: 'ENF', isoValue: 'GB-ENF', label: 'Enfield' },
        { value: 'ESS', isoValue: 'GB-ESS', label: 'Essex' },
        { value: 'GAT', isoValue: 'GB-GAT', label: 'Gateshead' },
        { value: 'GLS', isoValue: 'GB-GLS', label: 'Gloucestershire' },
        { value: 'GRE', isoValue: 'GB-GRE', label: 'Greenwich' },
        { value: 'HCK', isoValue: 'GB-HCK', label: 'Hackney' },
        { value: 'HAL', isoValue: 'GB-HAL', label: 'Haiton' },
        { value: 'HMF', isoValue: 'GB-HMF', label: 'Hammersmith and Fulham' },
        { value: 'HAM', isoValue: 'GB-HAM', label: 'Hampshire' },
        { value: 'HRY', isoValue: 'GB-HRY', label: 'Haringey' },
        { value: 'HRW', isoValue: 'GB-HRW', label: 'Harrow' },
        { value: 'HPL', isoValue: 'GB-HPL', label: 'Hartlepool' },
        { value: 'HAV', isoValue: 'GB-HAV', label: 'Havering' },
        { value: 'HEF', isoValue: 'GB-HEF', label: 'Herefordshire, County of' },
        { value: 'HRT', isoValue: 'GB-HRT', label: 'Hertfordshire' },
        { value: 'HIL', isoValue: 'GB-HIL', label: 'Hillingdon' },
        { value: 'HNS', isoValue: 'GB-HNS', label: 'Hounslow' },
        { value: 'IOW', isoValue: 'GB-IOW', label: 'Isle of Wight' },
        { value: 'IOS', isoValue: 'GB-IOS', label: 'Isles of Scilly' },
        { value: 'ISL', isoValue: 'GB-ISL', label: 'Islington' },
        { value: 'KEC', isoValue: 'GB-KEC', label: 'Kensington and Chelsea' },
        { value: 'KEN', isoValue: 'GB-KEN', label: 'Kent' },
        {
          value: 'KHL',
          isoValue: 'GB-KHL',
          label: 'Kingston upon Hull, City of'
        },
        { value: 'KTT', isoValue: 'GB-KTT', label: 'Kingston upon Thames' },
        { value: 'KIR', isoValue: 'GB-KIR', label: 'Kirklees' },
        { value: 'KWL', isoValue: 'GB-KWL', label: 'Knowsley' },
        { value: 'LBH', isoValue: 'GB-LBH', label: 'Lambeth' },
        { value: 'LAN', isoValue: 'GB-LAN', label: 'Lancashire' },
        { value: 'LDS', isoValue: 'GB-LDS', label: 'Leeds (City of)' },
        { value: 'LCE', isoValue: 'GB-LCE', label: 'Leitester (City of)' },
        { value: 'LEC', isoValue: 'GB-LEC', label: 'Leicestershire' },
        { value: 'LEW', isoValue: 'GB-LEW', label: 'Lewisham' },
        { value: 'LIN', isoValue: 'GB-LIN', label: 'Lincolnshire' },
        { value: 'LIV', isoValue: 'GB-LIV', label: 'Liverpool (City of)' },
        { value: 'LND', isoValue: 'GB-LND', label: 'London, City of' },
        { value: 'LUT', isoValue: 'GB-LUT', label: 'Luton' },
        { value: 'MAN', isoValue: 'GB-MAN', label: 'Manchester (City of)' },
        { value: 'MDW', isoValue: 'GB-MDW', label: 'Medway' },
        { value: 'MRT', isoValue: 'GB-MRT', label: 'Merton' },
        { value: 'MDB', isoValue: 'GB-MDB', label: 'Middlesbrough' },
        { value: 'MIK', isoValue: 'GB-MIK', label: 'Milton Keynes' },
        {
          value: 'NET',
          isoValue: 'GB-NET',
          label: 'Newcastle upon Tyne (City of)'
        },
        { value: 'NWM', isoValue: 'GB-NWM', label: 'Newham' },
        { value: 'NFK', isoValue: 'GB-NFK', label: 'Norfolk' },
        { value: 'NEL', isoValue: 'GB-NEL', label: 'North East Lincolnshire' },
        { value: 'NLN', isoValue: 'GB-NLN', label: 'North Lincolnshire' },
        { value: 'NSM', isoValue: 'GB-NSM', label: 'North Somerset' },
        { value: 'NTY', isoValue: 'GB-NTY', label: 'North Tyneside' },
        { value: 'NYK', isoValue: 'GB-NYK', label: 'North Yorkshire' },
        { value: 'NTH', isoValue: 'GB-NTH', label: 'Northamptonshire' },
        { value: 'NBL', isoValue: 'GB-NBL', label: 'Northumberland' },
        { value: 'NGM', isoValue: 'GB-NGM', label: 'Nottingham (City of)' },
        { value: 'NTT', isoValue: 'GB-NTT', label: 'Nottinghamshire' },
        { value: 'OLD', isoValue: 'GB-OLD', label: 'Oldham' },
        { value: 'OXF', isoValue: 'GB-OXF', label: 'Oxfordshire' },
        { value: 'PTE', isoValue: 'GB-PTE', label: 'Peterborough' },
        { value: 'PLY', isoValue: 'GB-PLY', label: 'Plymouth (City of)' },
        { value: 'POL', isoValue: 'GB-POL', label: 'Poole' },
        { value: 'POR', isoValue: 'GB-POR', label: 'Portsmouth (City of)' },
        { value: 'RDG', isoValue: 'GB-RDG', label: 'Reading' },
        { value: 'RDB', isoValue: 'GB-RDB', label: 'Redbridge' },
        { value: 'RCC', isoValue: 'GB-RCC', label: 'Redcar and Cleveland' },
        { value: 'RIC', isoValue: 'GB-RIC', label: 'Richmond upon Thames' },
        { value: 'RCH', isoValue: 'GB-RCH', label: 'Rochdale' },
        { value: 'ROT', isoValue: 'GB-ROT', label: 'Rotherharn' },
        { value: 'RUT', isoValue: 'GB-RUT', label: 'Rutland' },
        { value: 'SHN', isoValue: 'GB-SHN', label: 'St. Helens' },
        { value: 'SLF', isoValue: 'GB-SLF', label: 'Salford (City of)' },
        { value: 'SAW', isoValue: 'GB-SAW', label: 'Sandweil' },
        { value: 'SFT', isoValue: 'GB-SFT', label: 'Sefton' },
        { value: 'SHF', isoValue: 'GB-SHF', label: 'Sheffield (City of)' },
        { value: 'SHR', isoValue: 'GB-SHR', label: 'Shropshire' },
        { value: 'SLG', isoValue: 'GB-SLG', label: 'Slough' },
        { value: 'SOL', isoValue: 'GB-SOL', label: 'Solihull' },
        { value: 'SOM', isoValue: 'GB-SOM', label: 'Somerset' },
        { value: 'SGC', isoValue: 'GB-SGC', label: 'South Gloucestershire' },
        { value: 'STY', isoValue: 'GB-STY', label: 'South Tyneside' },
        { value: 'STH', isoValue: 'GB-STH', label: 'Southampton (City of)' },
        { value: 'SOS', isoValue: 'GB-SOS', label: 'Southend-on-Sea' },
        { value: 'SWK', isoValue: 'GB-SWK', label: 'Southwark' },
        { value: 'STS', isoValue: 'GB-STS', label: 'Staffordshire' },
        { value: 'SKP', isoValue: 'GB-SKP', label: 'Stockport' },
        { value: 'STT', isoValue: 'GB-STT', label: 'Stockton-On-Tees' },
        { value: 'STE', isoValue: 'GB-STE', label: 'Stoke-on-Trent (City of)' },
        { value: 'SFK', isoValue: 'GB-SFK', label: 'Suffolk' },
        { value: 'SND', isoValue: 'GB-SND', label: 'Sunderland (City of)' },
        { value: 'SRY', isoValue: 'GB-SRY', label: 'Surrey' },
        { value: 'STN', isoValue: 'GB-STN', label: 'Sutton' },
        { value: 'SWD', isoValue: 'GB-SWD', label: 'Swindon' },
        { value: 'TAM', isoValue: 'GB-TAM', label: 'Tameside' },
        { value: 'TFW', isoValue: 'GB-TFW', label: 'Telford and Wrekin' },
        { value: 'THR', isoValue: 'GB-THR', label: 'Thurrock' },
        { value: 'TOB', isoValue: 'GB-TOB', label: 'Torbay' },
        { value: 'TWH', isoValue: 'GB-TWH', label: 'Tower Hamlets' },
        { value: 'TRF', isoValue: 'GB-TRF', label: 'Trafford' },
        { value: 'WKF', isoValue: 'GB-WKF', label: 'Wakefield (City of)' },
        { value: 'WLL', isoValue: 'GB-WLL', label: 'Walsall' },
        { value: 'WFT', isoValue: 'GB-WFT', label: 'Waltham Forest' },
        { value: 'WND', isoValue: 'GB-WND', label: 'Wandsworth' },
        { value: 'WRT', isoValue: 'GB-WRT', label: 'Warrington' },
        { value: 'WAR', isoValue: 'GB-WAR', label: 'Warwickshire' },
        { value: 'WBK', isoValue: 'GB-WBK', label: 'West Berkshire' },
        { value: 'WSX', isoValue: 'GB-WSX', label: 'West Sussex' },
        { value: 'WSM', isoValue: 'GB-WSM', label: 'Westminster (City of)' },
        { value: 'WGN', isoValue: 'GB-WGN', label: 'Wigan' },
        { value: 'WIL', isoValue: 'GB-WIL', label: 'Wiltshire' },
        { value: 'WNM', isoValue: 'GB-WNM', label: 'Windsor and Maidenhead' },
        { value: 'WRL', isoValue: 'GB-WRL', label: 'Wirral' },
        { value: 'WOK', isoValue: 'GB-WOK', label: 'Wokingham' },
        { value: 'WLV', isoValue: 'GB-WLV', label: 'Wolverhampton' },
        { value: 'WOR', isoValue: 'GB-WOR', label: 'Worcestershire' },
        { value: 'YOR', isoValue: 'GB-YOR', label: 'York (City of)' },
        { value: 'IOM', isoValue: 'GB-IOM', label: 'Isle of Man' },
        { value: 'NIR', isoValue: 'GB-NIR', label: 'Northern Ireland' },
        { value: 'ANT', isoValue: 'GB-ANT', label: 'Antrim' },
        { value: 'ARD', isoValue: 'GB-ARD', label: 'Ards' },
        { value: 'ARM', isoValue: 'GB-ARM', label: 'Armagh' },
        { value: 'BLA', isoValue: 'GB-BLA', label: 'Ballymena' },
        { value: 'BLY', isoValue: 'GB-BLY', label: 'Ballymoney' },
        { value: 'BNB', isoValue: 'GB-BNB', label: 'Banbridge' },
        { value: 'BFS', isoValue: 'GB-BFS', label: 'Belfast (City of)' },
        { value: 'CKF', isoValue: 'GB-CKF', label: 'Carrickfergus' },
        { value: 'CSR', isoValue: 'GB-CSR', label: 'Castlereagh' },
        { value: 'CLR', isoValue: 'GB-CLR', label: 'Coleraine' },
        { value: 'CKT', isoValue: 'GB-CKT', label: 'Cookstown' },
        { value: 'CGV', isoValue: 'GB-CGV', label: 'Craigavon' },
        { value: 'DRY', isoValue: 'GB-DRY', label: 'Derry (City of)' },
        { value: 'DOW', isoValue: 'GB-DOW', label: 'Down' },
        { value: 'DGN', isoValue: 'GB-DGN', label: 'Dungannon' },
        { value: 'FER', isoValue: 'GB-FER', label: 'Fermanagh' },
        { value: 'LRN', isoValue: 'GB-LRN', label: 'Larne' },
        { value: 'LMV', isoValue: 'GB-LMV', label: 'Limavady' },
        { value: 'LSB', isoValue: 'GB-LSB', label: 'Lisburn' },
        { value: 'MFT', isoValue: 'GB-MFT', label: 'Magherafelt' },
        { value: 'MYL', isoValue: 'GB-MYL', label: 'Moyle' },
        { value: 'NYM', isoValue: 'GB-NYM', label: 'Newry and Mourne' },
        { value: 'NTA', isoValue: 'GB-NTA', label: 'Newtownabbey' },
        { value: 'NDN', isoValue: 'GB-NDN', label: 'North Down' },
        { value: 'OMH', isoValue: 'GB-OMH', label: 'Omagh' },
        { value: 'STB', isoValue: 'GB-STB', label: 'Strabane' },
        { value: 'SCT', isoValue: 'GB-SCT', label: 'Scotland' },
        { value: 'ABE', isoValue: 'GB-ABE', label: 'Aberdeen City' },
        { value: 'ABD', isoValue: 'GB-ABD', label: 'Aberdeenshire' },
        { value: 'ANS', isoValue: 'GB-ANS', label: 'Angus' },
        { value: 'AGB', isoValue: 'GB-AGB', label: 'Argyll and Bute' },
        { value: 'CLK', isoValue: 'GB-CLK', label: 'Clackmannanshire' },
        { value: 'DGY', isoValue: 'GB-DGY', label: 'Dumfries and Galloway' },
        { value: 'DND', isoValue: 'GB-DND', label: 'Dundee City' },
        { value: 'EAY', isoValue: 'GB-EAY', label: 'East Ayrshire' },
        { value: 'EDU', isoValue: 'GB-EDU', label: 'East Dunbartonshire' },
        { value: 'ELN', isoValue: 'GB-ELN', label: 'East Lothian' },
        { value: 'ERW', isoValue: 'GB-ERW', label: 'East Renfrewshire' },
        { value: 'EDH', isoValue: 'GB-EDH', label: 'Edinburgh, City of' },
        { value: 'ELS', isoValue: 'GB-ELS', label: 'Eilean Siar' },
        { value: 'FAL', isoValue: 'GB-FAL', label: 'Falkirk' },
        { value: 'FIF', isoValue: 'GB-FIF', label: 'Fife' },
        { value: 'GLG', isoValue: 'GB-GLG', label: 'Glasgow City' },
        { value: 'HLD', isoValue: 'GB-HLD', label: 'Highland' },
        { value: 'IVC', isoValue: 'GB-IVC', label: 'Inverclyde' },
        { value: 'MLN', isoValue: 'GB-MLN', label: 'Midlothian' },
        { value: 'MRY', isoValue: 'GB-MRY', label: 'Moray' },
        { value: 'NAY', isoValue: 'GB-NAY', label: 'North Ayrshire' },
        { value: 'NLK', isoValue: 'GB-NLK', label: 'North Lanarkshire' },
        { value: 'ORK', isoValue: 'GB-ORK', label: 'Orkney Islands' },
        { value: 'PKN', isoValue: 'GB-PKN', label: 'Perth and Kinross' },
        { value: 'RFW', isoValue: 'GB-RFW', label: 'Renfrewshire' },
        { value: 'SCB', isoValue: 'GB-SCB', label: 'Scottish Borders, The' },
        { value: 'ZET', isoValue: 'GB-ZET', label: 'Shetland Islands' },
        { value: 'SAY', isoValue: 'GB-SAY', label: 'South Ayrshire' },
        { value: 'SLK', isoValue: 'GB-SLK', label: 'South Lanarkshire' },
        { value: 'STG', isoValue: 'GB-STG', label: 'Stirling' },
        { value: 'WDU', isoValue: 'GB-WDU', label: 'West Dunbartonshire' },
        { value: 'WLN', isoValue: 'GB-WLN', label: 'West Lothian' },
        { value: 'WLS', isoValue: 'GB-WLS', label: 'Wales [Cymru]' },
        { value: 'BGW', isoValue: 'GB-BGW', label: 'Blaenau Gwent' },
        {
          value: 'BGE',
          isoValue: 'GB-BGE',
          label: 'Bridgend [Pen-y-bont ar Ogwr GB-POG]'
        },
        {
          value: 'CAY',
          isoValue: 'GB-CAY',
          label: 'Caerphilly [Caerffili GB-CAF]'
        },
        {
          value: 'CRF',
          isoValue: 'GB-CRF',
          label: 'Cardiff (City of) [Caerdydd GB-CRD]'
        },
        {
          value: 'CMN',
          isoValue: 'GB-CMN',
          label: 'Carmarthenshire [Sir Gaerfyrddin GB-GFY]'
        },
        {
          value: 'CGN',
          isoValue: 'GB-CGN',
          label: 'Ceredigion [Sir Ceredigion]'
        },
        { value: 'CWY', isoValue: 'GB-CWY', label: 'Conwy' },
        {
          value: 'DEN',
          isoValue: 'GB-DEN',
          label: 'Denbighshire [Sir Ddinbych GB-DDB]'
        },
        {
          value: 'FLN',
          isoValue: 'GB-FLN',
          label: 'Flintshire [Sir y Fflint GB-FFL]'
        },
        { value: 'GWN', isoValue: 'GB-GWN', label: 'Gwynedd' },
        {
          value: 'AGY',
          isoValue: 'GB-AGY',
          label: 'Isle of Anglesey [Sir Ynys Man GB-YNM]'
        },
        {
          value: 'MTY',
          isoValue: 'GB-MTY',
          label: 'Merthyr Tydfil [Merthyr Tudful GB-MTU]'
        },
        {
          value: 'MON',
          isoValue: 'GB-MON',
          label: 'Monmouthshire [Sir Fynwy GB-FYN]'
        },
        {
          value: 'NTL',
          isoValue: 'GB-NTL',
          label: 'Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]'
        },
        {
          value: 'NWP',
          isoValue: 'GB-NWP',
          label: 'Newport [Casnewydd GB-CNW]'
        },
        {
          value: 'PEM',
          isoValue: 'GB-PEM',
          label: 'Pembrokeshire [Sir Benfro CB-BNF]'
        },
        { value: 'POW', isoValue: 'GB-POW', label: 'Powys' },
        {
          value: 'RCT',
          isoValue: 'GB-RCT',
          label: 'Rhondda, Cynon, Taff [Rhondda, Cynon, Taf]'
        },
        {
          value: 'SWA',
          isoValue: 'GB-SWA',
          label: 'Swansea (City of) [Abertawe GB-ATA]'
        },
        { value: 'TOF', isoValue: 'GB-TOF', label: 'Torfaen [Tor-faen]' },
        {
          value: 'VGL',
          isoValue: 'GB-VGL',
          label: 'Vale of Glamorgan, The [Bro Morgannwg GB-BMG]'
        },
        { value: 'WRX', isoValue: 'GB-WRX', label: 'Wrexham [Wrecsam GB-WRC]' }
      ],
      MX: [
        { value: 'DF', isoValue: 'MX-DIF', label: 'Distrito Federal' },
        { value: 'AG', isoValue: 'MX-AGU', label: 'Aguascalientes' },
        { value: 'BC', isoValue: 'MX-BCN', label: 'Baja California' },
        { value: 'BS', isoValue: 'MX-BCS', label: 'Baja California Sur' },
        { value: 'CM', isoValue: 'MX-CAM', label: 'Campeche' },
        { value: 'CO', isoValue: 'MX-COA', label: 'Coahuila' },
        { value: 'CL', isoValue: 'MX-COL', label: 'Colima' },
        { value: 'CS', isoValue: 'MX-CHP', label: 'Chiapas' },
        { value: 'CH', isoValue: 'MX-CHH', label: 'Chihuahua' },
        { value: 'DG', isoValue: 'MX-DUR', label: 'Durango' },
        { value: 'GR', isoValue: 'MX-GRO', label: 'Guerrero' },
        { value: 'GT', isoValue: 'MX-GUA', label: 'Guanajuato' },
        { value: 'HG', isoValue: 'MX-HID', label: 'Hidalgo' },
        { value: 'JA', isoValue: 'MX-JAL', label: 'Jalisco' },
        { value: 'EM', isoValue: 'MX-MEX', label: 'México' },
        { value: 'MI', isoValue: 'MX-MIC', label: 'Michoacán' },
        { value: 'MO', isoValue: 'MX-MOR', label: 'Morelos' },
        { value: 'NA', isoValue: 'MX-NAY', label: 'Nayarit' },
        { value: 'NL', isoValue: 'MX-NLE', label: 'Nuevo León' },
        { value: 'OA', isoValue: 'MX-OAX', label: 'Oaxaca' },
        { value: 'PU', isoValue: 'MX-PUE', label: 'Puebla' },
        { value: 'QT', isoValue: 'MX-QUE', label: 'Queretaro' },
        { value: 'QR', isoValue: 'MX-ROO', label: 'Quintana Roo' },
        { value: 'SL', isoValue: 'MX-SLP', label: 'San Luis Potosí' },
        { value: 'SI', isoValue: 'MX-SIN', label: 'Sinaloa' },
        { value: 'SO', isoValue: 'MX-SON', label: 'Sonora' },
        { value: 'TB', isoValue: 'MX-TAB', label: 'Tabasco' },
        { value: 'TM', isoValue: 'MX-TAM', label: 'Tamaulipas' },
        { value: 'TL', isoValue: 'MX-TLA', label: 'Tlaxcala' },
        { value: 'VE', isoValue: 'MX-VER', label: 'Veracruz' },
        { value: 'YU', isoValue: 'MX-YUC', label: 'Yucatán' },
        { value: 'ZA', isoValue: 'MX-ZAC', label: 'Zacatecas' }
      ],
      US: [
        { value: 'AL', isoValue: 'US-AL', label: 'Alabama' },
        { value: 'AK', isoValue: 'US-AK', label: 'Alaska' },
        { value: 'AZ', isoValue: 'US-AZ', label: 'Arizona' },
        { value: 'AR', isoValue: 'US-AR', label: 'Arkansas' },
        { value: 'CA', isoValue: 'US-CA', label: 'California' },
        { value: 'CO', isoValue: 'US-CO', label: 'Colorado' },
        { value: 'CT', isoValue: 'US-CT', label: 'Connecticut' },
        { value: 'DE', isoValue: 'US-DE', label: 'Delaware' },
        { value: 'FL', isoValue: 'US-FL', label: 'Florida' },
        { value: 'GA', isoValue: 'US-GA', label: 'Georgia' },
        { value: 'HI', isoValue: 'US-HI', label: 'Hawaii' },
        { value: 'ID', isoValue: 'US-ID', label: 'Idaho' },
        { value: 'IL', isoValue: 'US-IL', label: 'Illinois' },
        { value: 'IN', isoValue: 'US-IN', label: 'Indiana' },
        { value: 'IA', isoValue: 'US-IA', label: 'Iowa' },
        { value: 'KS', isoValue: 'US-KS', label: 'Kansas' },
        { value: 'KY', isoValue: 'US-KY', label: 'Kentucky' },
        { value: 'LA', isoValue: 'US-LA', label: 'Louisiana' },
        { value: 'ME', isoValue: 'US-ME', label: 'Maine' },
        { value: 'MD', isoValue: 'US-MD', label: 'Maryland' },
        { value: 'MA', isoValue: 'US-MA', label: 'Massachusetts' },
        { value: 'MI', isoValue: 'US-MI', label: 'Michigan' },
        { value: 'MN', isoValue: 'US-MN', label: 'Minnesota' },
        { value: 'MS', isoValue: 'US-MS', label: 'Mississippi' },
        { value: 'MO', isoValue: 'US-MO', label: 'Missouri' },
        { value: 'MT', isoValue: 'US-MT', label: 'Montana' },
        { value: 'NE', isoValue: 'US-NE', label: 'Nebraska' },
        { value: 'NV', isoValue: 'US-NV', label: 'Nevada' },
        { value: 'NH', isoValue: 'US-NH', label: 'New Hampshire' },
        { value: 'NJ', isoValue: 'US-NJ', label: 'New Jersey' },
        { value: 'NM', isoValue: 'US-NM', label: 'New Mexico' },
        { value: 'NY', isoValue: 'US-NY', label: 'New York' },
        { value: 'NC', isoValue: 'US-NC', label: 'North Carolina' },
        { value: 'ND', isoValue: 'US-ND', label: 'North Dakota' },
        { value: 'OH', isoValue: 'US-OH', label: 'Ohio' },
        { value: 'OK', isoValue: 'US-OK', label: 'Oklahoma' },
        { value: 'OR', isoValue: 'US-OR', label: 'Oregon' },
        { value: 'PA', isoValue: 'US-PA', label: 'Pennsylvania' },
        { value: 'RI', isoValue: 'US-RI', label: 'Rhode Island' },
        { value: 'SC', isoValue: 'US-SC', label: 'South Carolina' },
        { value: 'SD', isoValue: 'US-SD', label: 'South Dakota' },
        { value: 'TN', isoValue: 'US-TN', label: 'Tennessee' },
        { value: 'TX', isoValue: 'US-TX', label: 'Texas' },
        { value: 'UT', isoValue: 'US-UT', label: 'Utah' },
        { value: 'VT', isoValue: 'US-VT', label: 'Vermont' },
        { value: 'VA', isoValue: 'US-VA', label: 'Virginia' },
        { value: 'WA', isoValue: 'US-WA', label: 'Washington' },
        { value: 'WV', isoValue: 'US-WV', label: 'West Virginia' },
        { value: 'WI', isoValue: 'US-WI', label: 'Wisconsin' },
        { value: 'WY', isoValue: 'US-WY', label: 'Wyoming' },
        { value: 'DC', isoValue: 'US-DC', label: 'District of Columbia' },
        { value: 'AS', isoValue: 'US-AS', label: 'American Samoa' },
        { value: 'GU', isoValue: 'US-GU', label: 'Guam' },
        { value: 'MP', isoValue: 'US-MP', label: 'Northern Mariana Islands' },
        { value: 'PR', isoValue: 'US-PR', label: 'Puerto Rico' },
        {
          value: 'UM',
          isoValue: 'US-UM',
          label: 'United States Minor Outlying Islands'
        },
        { value: 'VI', isoValue: 'US-VI', label: 'Virgin Islands, U.S.' }
      ]
    }),
    []
  );
}

export default useStateProvinceRegionSelectisoValues;
