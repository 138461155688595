/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { Icon } from 'app/common/components';
import {
  useNationalSiteContext,
  useFormatMessage,
  useMenuApi,
  useMenuInfo
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import messages from './SiteSocialSharing.messages';

/**
 * Render component for displaying links to social accounts.
 *
 * @visibleName Site Footer Links to Social Accounts
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const SiteSocialSharing = () => {
  const { useApplicationContent, partsToGo } = useNationalSiteContext();

  if (!partsToGo && useApplicationContent) {
    return <DealerMenu />;
  }

  return <DefaultMenu />;
};

const DefaultMenu = () => {
  const { fetchUrl, socialSharingMenuName } = useMenuInfo();
  const {
    exception,
    error,
    loading,
    response: menu
  } = useMenuApi(fetchUrl, socialSharingMenuName);

  if (error) {
    logError({ ...exception, when: `fetching Menu ${socialSharingMenuName}` });
    return null;
  }

  if (isEmpty(menu) || loading) {
    return null;
  }

  const { label, submenu = [] } = menu || {};

  if (isEmpty(label) && isEmpty(submenu)) {
    return null;
  }

  return <SocialMediaMenu label={label} submenu={submenu} />;
};

const DealerMenu = () => {
  const formatMessage = useFormatMessage();
  const applicationMenuOverride = useSubmenuFromApplication();
  if (isEmpty(applicationMenuOverride)) {
    return null;
  }

  return (
    <SocialMediaMenu
      label={formatMessage(messages.heading)}
      submenu={applicationMenuOverride}
    />
  );
};

const SocialMediaMenu = ({ label, submenu }) => (
  <div className="flex w-full pt-4 border-solid border-white md:pt-6 lg:justify-end lg:pt-8">
    <div className="flex flex-col items-center w-full">
      <h4 className="pb-2 text-lg text-center font-bold lg:text-left">
        {label}
      </h4>
      <ul className="flex items-start justify-center w-full list-none">
        {submenu.map(({ socialLinkType, url }) =>
          getButtonForType(socialLinkType, url)
        )}
      </ul>
    </div>
  </div>
);

function useSubmenuFromApplication() {
  const { applicationThemeSource: application, resolving } =
    useNationalSiteContext();
  return React.useMemo(() => {
    return resolving
      ? []
      : [
          {
            socialLinkType: 'FACEBOOK',
            url: get(application, 'facebookUrl')
          },
          {
            socialLinkType: 'INSTAGRAM',
            url: get(application, 'instagramUrl')
          },
          {
            socialLinkType: 'LINKED_IN',
            url: get(application, 'linkedInUrl')
          },
          {
            socialLinkType: 'TWITTER',
            url: get(application, 'twitterUrl')
          },
          {
            socialLinkType: 'YOU_TUBE',
            url: get(application, 'youTubeUrl')
          }
        ].filter(menuItem => !isEmpty(menuItem.url));
  }, [application, resolving]);
}

function getButtonForType(type, to) {
  switch (type) {
    case 'FACEBOOK':
      return <Facebook key={type} to={to} />;
    case 'INSTAGRAM':
      return <Instagram key={type} to={to} />;
    case 'LINKED_IN':
      return <LinkedIn key={type} to={to} />;
    case 'TWITTER':
      return <Twitter key={type} to={to} />;
    case 'YOU_TUBE':
      return <YouTube key={type} to={to} />;
    case 'GOOGLE_PLUS':
      return <GooglePlus key={type} to={to} />;
    default:
      return null;
  }
}

const SocialShareButton = ({ colors, iconName, type, to }) => {
  const formatMessage = useFormatMessage();
  return (
    <li>
      <a
        href={to}
        className={`inline-flex items-center justify-start
                    p-2 ${colors} appearance-none outline-none md:px-4 lg:px-2`}
        title={formatMessage(messages.buttonTitle, { type })}
      >
        <Icon className="text-xl" name={iconName} />
      </a>
    </li>
  );
};

const Facebook = ({ to }) => (
  <SocialShareButton
    colors="text-facebook-500 hover:text-facebook-700 focus:text-facebook-700 active:text-facebook-300"
    iconName={['fab', 'facebook']}
    type="Facebook"
    to={to}
  />
);

const Instagram = ({ to }) => (
  <SocialShareButton
    colors="text-instagram-500 hover:text-instagram-700 focus:text-instagram-700 active:text-instagram-300"
    iconName={['fab', 'instagram']}
    type="Instagram"
    to={to}
  />
);
const GooglePlus = ({ to }) => (
  <SocialShareButton
    colors="text-google-500 hover:text-google-700 focus:text-google-700 active:text-google-300"
    iconName={['fab', 'google-plus-g']}
    type="Google+"
    to={to}
  />
);
const LinkedIn = ({ to }) => (
  <SocialShareButton
    colors="text-linkedIn-500 hover:text-linkedIn-700 focus:text-linkedIn-700 active:text-linkedIn-300"
    iconName={['fab', 'linkedin-in']}
    type="LinkedIn"
    to={to}
  />
);
const Twitter = ({ to }) => (
  <SocialShareButton
    colors="text-twitter-500 hover:text-twitter-700 focus:text-twitter-700 active:text-twitter-300"
    iconName={['fab', 'twitter']}
    type="Twitter"
    to={to}
  />
);
const YouTube = ({ to }) => (
  <SocialShareButton
    colors="text-youtube-500 hover:text-youtube-700 focus:text-youtube-700 active:text-youtube-300"
    iconName={['fab', 'youtube']}
    type="YouTube"
    to={to}
  />
);

SocialShareButton.propTypes = {
  colors: PropTypes.string.isRequired,
  iconName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  type: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default SiteSocialSharing;
export { SiteSocialSharing };
