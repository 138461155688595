/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defaultPreviewState } from 'app/common/contexts';
import { ActionTypes } from 'app/common/actions/preview';

/**
 * Reducer for managing the preview state.
 *
 * @param {PreviewState} state the state
 * @param {{ payload: *, type: string }} action the action
 * @returns {PreviewState}
 */
export default function reducer(state = defaultPreviewState, action) {
  switch (action.type) {
    case ActionTypes.SET_INITIAL_BY_PARAMS: {
      if (!action.payload.token) {
        return state;
      }
      return {
        ...state,
        isActive: true,
        isValidated: false,
        token: action.payload.token,
        sandboxId: action.payload.sandboxId
      };
    }
    case ActionTypes.SET_INITIAL_BY_CACHE: {
      return action.payload;
    }
    case ActionTypes.SET_VALIDATING: {
      return {
        ...state,
        isValidating: action.payload
      };
    }
    case ActionTypes.SET_VALIDATED: {
      const {
        content: { author, expirationTime },
        sandboxes
      } = action.payload;
      return {
        ...state,
        isActive: true,
        isValidated: true,
        isValidating: false,
        author,
        sandboxId: state.sandboxId || sandboxes[0].id,
        sandboxById: sandboxes.reduce(
          (byId, sandbox) => ({ ...byId, [sandbox.id]: sandbox }),
          {}
        ),
        previewDate: null,
        expirationTime
      };
    }
    case ActionTypes.SET_INVALIDATED: {
      return {
        ...defaultPreviewState,
        isValidating: false,
        errorMessage: action.payload.reason
      };
    }
    case ActionTypes.SET_ERROR: {
      return {
        ...defaultPreviewState,
        isValidating: false,
        errorMessage: action.payload.message
      };
    }
    case ActionTypes.SET_SANDBOX_ID: {
      if (!state.isActive || !state.isValidated) {
        return state;
      }
      return {
        ...state,
        sandboxId: action.payload
      };
    }
    case ActionTypes.SET_PREVIEW_DATE: {
      if (!state.isActive || !state.isValidated) {
        return state;
      }
      return {
        ...state,
        previewDate: action.payload
      };
    }
    case ActionTypes.CLEAR: {
      if (typeof action.payload === 'object') {
        return action.payload;
      }
      return defaultPreviewState;
    }
    default:
      return state;
  }
}
