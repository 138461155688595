/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useContext, useMemo } from 'react';
import { get } from 'lodash';

import { AuthContext } from 'app/auth/contexts';

import { Environment } from '../../../services';

function useCustomerService() {
  const { user, isAuthenticated } = useContext(AuthContext);
  const customerId = get(user, 'serviceId');

  return useMemo(
    () => ({
      baseUrl: Environment.get('CUSTOMER_BASE_ENDPOINT', '/customer/customers'),
      customerBasePath: Environment.get(
        'CUSTOMER_ENDPOINT',
        `${isAuthenticated ? `/${customerId}` : ''}`
      ),
      addressesBasePath: Environment.get(
        'CUSTOMER_ADDRESSES_ENDPOINT',
        `${isAuthenticated ? `/${customerId}` : ''}/addresses`
      ),
      fleetCustomersContextPath: Environment.get(
        'FLEET_CUSTOMER_BASE_ENDPOINT',
        '/fleets'
      )
    }),
    [isAuthenticated, customerId]
  );
}

export default useCustomerService;
