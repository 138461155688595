/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';

import { Environment } from '../../../services';

function useCartInfo() {
  return useMemo(
    () => ({
      baseUrl: Environment.get('CART_BASE_ENDPOINT', 'cart/carts'),
      operations: {
        baseUrl: Environment.get(
          'CART_OPS_BASE_ENDPOINT',
          'cart-operations/cart'
        ),
        cartHistoryContextPath: Environment.get(
          'CART_OPS_HISTORY_ENDPOINT',
          '/cart-operations/cart-history'
        ),
        attributesContextPath: Environment.get(
          'CART_OPS_ATTRIBUTES_ENDPOINT',
          '/attributes'
        ),
        fulfillmentGroupsContextPath: Environment.get(
          'CART_OPS_FULFILLMENT_GROUPS_ENDPOINT',
          '/fulfillment-groups'
        ),
        itemsContextPath: Environment.get('CART_OPS_ITEMS_ENDPOINT', '/items'),
        bulkItemsContextPath: Environment.get(
          'CART_OPS_BULK_ITEMS_ENDPOINT',
          '/bulk-items'
        ),
        bulkDeleteContextPath: Environment.get(
          'CART_OPS_BULK_DELETE_ITEMS_ENDPOINT',
          '/bulk-items/delete'
        ),
        offerCodesContextPath: Environment.get(
          'CART_OPS_ADD_OFFER_CODES_ENDPOINT',
          '/offer-codes'
        ),
        overridePriceContextPath: Environment.get(
          'CART_OPS_ADD_OVERRIDE_ITEM_PRICE_ENDPOINT',
          '/overridePrice'
        ),
        overrideFulfillmentPriceContextPath: Environment.get(
          'CART_OPS_ADD_OVERRIDE_FULFILLMENT_PRICE_ENDPOINT',
          '/overrideFulfillmentPrice'
        ),
        paymentsContextPath: Environment.get(
          'CART_OPS_PAYMENTS_ENDPOINT',
          '/payments'
        ),
        pricingContextPath: Environment.get(
          'CART_OPS_PRICING_ENDPOINT',
          '/pricing'
        ),
        resolveCartPath: Environment.get(
          'RESOLVE_CART_ENDPOINT',
          '/cart-operations/resolve-cart'
        ),
        checkoutContextPath: Environment.get(
          'CART_OPS_CHECKOUT_ENDPOINT',
          '/cart-operations/checkout'
        ),
        checkoutDeliveryValidationContextPath: Environment.get(
          'CART_OPS_CHECKOUT_DELIVERY_VALIDATION_ENDPOINT',
          '/checkout/delivery/validation'
        ),
        checkoutValidationContextPath: Environment.get(
          'CART_OPS_CHECKOUT_VALIDATION_ENDPOINT',
          '/checkout/validation'
        ),
        contactInfoPath: Environment.get(
          'CART_OPS_CONTACT_INFO_ENDPOINT',
          '/contact-info'
        ),
        fulfillmentOptionsContextPath: Environment.get(
          'CART_OPS_FULFILLMENT_OPTIONS_ENDPOINT',
          '/fulfillment-options'
        ),
        transferCartToDealerLocationContextPath: Environment.get(
          'CART_OPS_TRANSFER_CART_TO_DEALER_LOCATION_ENDPOINT',
          '/transfer'
        ),
        cloneCartFromCustomerContextPath: Environment.get(
          'CART_OPS_CLONE_CART_FROM_CUSTOMER_ENDPOINT',
          '/csr/clone-from-customer'
        ),
        transferCartToCustomerContextPath: Environment.get(
          'CART_OPS_TRANSFER_CART_TO_CUSTOMER_ENDPOINT',
          '/csr/transfer-to-customer'
        ),
        customerInProcessCart: Environment.get(
          'CART_OPS_CUSTOMER_IN_PROCESS_CART_EXIST_ENDPOINT',
          '/csr/customer-in-process-cart'
        ),
        transferAnonymousCartUrl: Environment.get(
          'CART_OPS_TRANSFER_ANONYMOUS_CART_ENDPOINT',
          '/csr/transfer-anonymous'
        ),
        paypal: {
          createOrderContextPath: Environment.get(
            'CART_OPS_PAYPAL_CREATE_ORDER_ENDPOINT',
            '/paypal/order'
          )
        }
      },
      checkoutOperations: {
        baseUrl: Environment.get(
          'CART_OPS_LISTS_BASE_ENDPOINT',
          'cart-operations/checkout'
        ),
        processCheckoutPath: Environment.get(
          'CART_OPS_LISTS_BASE_ENDPOINT',
          '/process'
        ),
        contactInfoPath: Environment.get(
          'CART_OPS_CONTACT_INFO_ENDPOINT',
          '/contact-info'
        ),
        guestTokenPath: Environment.get(
          'CART_OPS_CONTACT_INFO_ENDPOINT',
          '/guest-token'
        ),
        checkoutValidationContextPath: Environment.get(
          'CART_OPS_CHECKOUT_VALIDATION_ENDPOINT',
          '/validation'
        ),
        fulfillmentOptionsContextPath: Environment.get(
          'CART_OPS_FULFILLMENT_OPTIONS_ENDPOINT',
          '/fulfillment-options'
        ),
        fulfillmentGroupsContextPath: Environment.get(
          'CART_OPS_FULFILLMENT_GROUPS_ENDPOINT',
          '/fulfillment-groups'
        ),
        checkoutDeliveryValidationContextPath: Environment.get(
          'CART_OPS_CONTACT_INFO_ENDPOINT',
          '/delivery/validation'
        ),
        fetchDeliveryOptions: Environment.get(
          'FULFILLMENT_FETCH_DELIVERY_OPTIONS_ENDPOINT',
          'fulfillment/options'
        )
      },
      listOperations: {
        baseUrl: Environment.get(
          'CART_OPS_LISTS_BASE_ENDPOINT',
          'cart-operations/item-list-ops'
        ),
        bulkContextPath: Environment.get(
          'CART_OPS_LIST_BULK_ENDPOINT',
          '/bulk-item-lists'
        ),
        itemsContextPath: Environment.get(
          'CART_OPS_LIST_ITEMS_ENDPOINT',
          '/items'
        ),
        itemsBulkContextPath: Environment.get(
          'CART_OPS_LIST_ITEMS_BULK_ENDPOINT',
          '/bulk-items'
        ),
        bulkDeleteContextPath: Environment.get(
          'CART_OPS_LIST_ITEMS_BULK_DELETE_ENDPOINT',
          '/bulk-items/delete'
        ),
        listsBulkAddContextPath: Environment.get(
          'CART_OPS_LISTS_BULK_ADD_ENDPOINT',
          '/add-to-cart'
        ),
        attributesContextPath: Environment.get(
          'CART_OPS_LISTS_ATTRIBUTES_ENDPOINT',
          '/attributes'
        )
      }
    }),
    []
  );
}

export default useCartInfo;
