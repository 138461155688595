/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';

import { MiniProductGrid } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';
import { ResultsLayoutContext } from 'app/search-and-browse/shared/contexts';

import messages from './FeaturedProducts.messages';

/**
 * Render component for a category's promotional/featured products.
 *
 * @visibleName Default Category Browse: Feature Products
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const FeaturedProducts = ({ categoryName, featuredProducts }) => {
  const formatMessage = useFormatMessage();
  const { breadcrumbs } = React.useContext(ResultsLayoutContext);

  if (isEmpty(featuredProducts)) {
    return null;
  }

  const products = map(featuredProducts, fp => {
    return {
      ...fp.promotionalProduct,
      promotionMessage: fp.promotionMessage
    };
  });

  return (
    <article className="mt-8 pt-8 border-t border-gray-400 sm:pt-10">
      <header>
        <h3 className="mb-8 text-center text-gray-700 font-medium text-2xl sm:text-3xl sm:mb-10 md:mb-12">
          {formatMessage(messages.title)}
        </h3>
      </header>
      <section>
        <MiniProductGrid
          breadcrumbs={breadcrumbs}
          containerName={`${categoryName} Featured Parts`}
          products={products}
        />
      </section>
    </article>
  );
};

FeaturedProducts.propTypes = {
  featuredProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      promotionMessage: PropTypes.string,
      promotionalProduct: PropTypes.object.isRequired
    })
  ).isRequired
};

export default FeaturedProducts;
export { FeaturedProducts };
