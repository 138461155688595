/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get } from 'lodash';

import { AuthContext } from 'app/auth/contexts';
import { LocaleContext, CurrencyContext } from 'app/common/contexts';
import { usePricingInfo } from 'app/common/hooks';

/**
 * Adds the `X-Price-Context` header to the user-provided request `config`.
 *
 * @return {{headers: {}}}
 */
function usePriceContextHeader() {
  const { currentLocale } = React.useContext(LocaleContext);
  const { currentCurrency } = React.useContext(CurrencyContext);
  const { priceContextHeader } = usePricingInfo();
  const { user } = React.useContext(AuthContext);

  return React.useMemo(
    () => ({
      headers: {
        [priceContextHeader]: JSON.stringify({
          locale: currentLocale,
          currency: currentCurrency,
          userTargets: [
            { targetValue: get(user, 'serviceId'), targetType: 'CUSTOMER' }
          ],
          attributes: {
            webRequest: {
              fullUrl: window.location.href,
              pathname: window.location.pathname,
              userAgentType: window.navigator.userAgent,
              secure: window.location.protocol === 'https:',
              searchKeywords: window.location.search
            }
          }
        })
      }
    }),
    [currentLocale, currentCurrency, user, priceContextHeader]
  );
}

export default usePriceContextHeader;
