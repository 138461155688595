/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';

import { Environment } from '../../../services';

function useInventoryInfo() {
  return useMemo(
    () => ({
      location: {
        baseUrl: Environment.get(
          'INVENTORY_LOCATION_BASE_ENDPOINT',
          'inventory/inventory-location'
        ),
        nearCoordinatesContextPath: Environment.get(
          'INVENTORY_LOCATION_NEAR_COORDINATES_ENDPOINT',
          '/store-locations/within-area'
        )
      },
      availability: {
        baseUrl: Environment.get(
          'INVENTORY_AVAILABILITY_BASE_ENDPOINT',
          'inventory/shop-sku-inventory/availability'
        ),
        forLocationContextPath: Environment.get(
          'INVENTORY_AVAILABILITY_FOR_LOCATION_ENDPOINT',
          '/inventory-location/'
        )
      }
    }),
    []
  );
}

export default useInventoryInfo;
