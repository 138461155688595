/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { get, isEmpty, set } from 'lodash';

import { SecondaryButton } from 'app/common/components';
import { CartContext } from 'app/common/contexts';
import { CheckoutContext } from 'app/checkout/contexts';

import { useCartOfferCodeApi, useFormatMessage } from 'app/common/hooks';

import messages from './AddPromoCodeForm.messages';

/**
 * Render component for adding a promotional code to the current cart.
 *
 * @visibleName Cart Page: Promotions: Add Form
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const AddPromoCodeForm = () => {
  const formatMessage = useFormatMessage();
  const { setCart } = useContext(CartContext);
  const { setPromoCodeUpdated } = useContext(CheckoutContext);
  const inputRef = useRef(null);
  const config = useMemo(
    () => ({ method: 'post', params: { price: true } }),
    []
  );
  const { error, exception, loading, sendCallback } = useCartOfferCodeApi(
    '',
    config,
    false,
    false
  );

  let errorMessage;

  if (error) {
    const errorType = get(exception, 'type');
    if (errorType === 'MAX_USES') {
      errorMessage = formatMessage(messages.maxUsesError);
    } else if (errorType === 'NO_OFFERS_MATCH') {
      errorMessage = formatMessage(messages.invalidError);
    } else {
      errorMessage = formatMessage(messages.otherError);
    }
  }

  return (
    <form aria-label={formatMessage(messages.ariaLabel)} className="mb-6 px-4">
      <div>
        <label
          className="hidden mb-2 capitalize text-sm text-gray-700 font-bold"
          htmlFor="promotion-code-input"
        >
          {formatMessage(messages.placeholder)}
        </label>
        <div className="flex flex-col">
          <div className="flex w-full">
            <input
              className={classNames(
                'block w-full min-w-0 px-4 py-2',
                'text-sm text-gray-900 placeholder-gray-500 leading-none',
                'appearance-none bg-white border rounded-l',
                'focus:shadow-outline focus:outline-none disabled:bg-gray-200 disabled:border-gray-300 disabled:cursor-not-allowed',
                'lg:p-3 lg:text-base',
                {
                  'border-gray-400': !error,
                  'border-red-500': !!error
                }
              )}
              id="promotion-code-input"
              name="offerCode"
              placeholder={formatMessage(messages.placeholder)}
              ref={inputRef}
              type="text"
            />
            <SecondaryButton
              className="rounded-l-none"
              backgroundColor="bg-primary-100 hover:bg-primary-200 focus:bg-primary-200 active:bg-white disabled:bg-white"
              id="promotion-code-submit"
              onClick={e => {
                e.preventDefault();
                const code = get(inputRef, 'current.value');

                if (!isEmpty(code) && !loading) {
                  sendCallback({ data: { code } }).then(data => {
                    if (!isEmpty(data)) {
                      // TODO 1.7.0-GA: The data now sent back has a 'message' that can potentially be displayed. Not important but is a feature that can be added.
                      setCart(get(data, 'cart'));
                      setPromoCodeUpdated(true);
                      set(inputRef, 'current.value', null);
                    }
                  });
                }
              }}
              type="submit"
            >
              {formatMessage(messages.submitLabel)}
            </SecondaryButton>
          </div>
          {!!error && (
            <em className="block mt-2 text-sm text-red-500 leading-tight not-italic">
              {errorMessage}
            </em>
          )}
        </div>
      </div>
    </form>
  );
};

export default AddPromoCodeForm;
export { AddPromoCodeForm };
