import { useSuggestAddress } from '../hooks';
import { Icon } from 'app/common/components';
import React from 'react';
import { SuggestionAutoComplete } from './SuggestionAutoComplete';
import { LocationPin } from './LocationPin';
import { useFormatMessage } from 'app/common/hooks';
import messages from 'app/layout/components/Header/components/StoreDropdown/StoreDropdown.messages';
import { LocationQueryInput } from './LocationQueryInput';

/**
 * Renders the location query component. This component is used to search for stores by zip code, city, state, etc.
 * Also renders the "Use My Location" button which attempts to find stores using the browser location API if available.
 * @param {LocationQueryInputProps} locationQueryInputProps The props for the location query input component
 * @param {SuggestAddressProps} suggestAddressProps The props for the suggest address component
 * @param {boolean} isModalInput Is this component being used in a modal?
 * @param {function} getLocationOnClick The callback to handle the "Use My Location" button click
 * @param {function} closeButtonOnClick The callback to handle the close button click
 * @param {SuggestionsAutoCompleteProps} suggestionAutoCompleteProps The props for the suggestion autocomplete component
 * @returns {Element}
 * @constructor
 */
const LocationQuery = ({
  locationQueryInputProps,
  suggestAddressProps,
  isModalInput = false,
  getLocationOnClick,
  closeButtonOnClick,
  suggestionAutoCompleteProps
}) => {
  useSuggestAddress(suggestAddressProps);
  const formatMessage = useFormatMessage();
  return (
    <div className={'flex-row pb-4'}>
      <div className={'flex flex-col'}>
        <div className={'flex flex-row pb-4 pt-3'}>
          <div className={'flex w-9/10 sm:w-4/5'}>
            <div className={'flex pr-2 font-bold'}>
              {formatMessage(messages.findOtherStores)}
            </div>
            <div className={'flex pr-2'}>|</div>
            <div className={'flex flex-row text-primary-500'}>
              <button onClick={getLocationOnClick} className={'flex'}>
                <div className={'flex h-full'}>
                  <LocationPin className={'pr-2'} color={'#1A6E99'} />
                </div>
                <div className={'flex text-primary-500'}>
                  {formatMessage(messages.useMyLocation)}
                </div>
              </button>
            </div>
          </div>
          <div className={'flex items-center justify-end w-1/10 sm:w-1/5 pr-3'}>
            {!isModalInput && (
              <button
                className={'flex items-center justify-end w-1/5 pr-3'}
                onClick={closeButtonOnClick}
                aria-label={formatMessage(messages.closeLocationSearch)}
                title={formatMessage(messages.closeLocationSearch)}
              >
                <Icon name={'times'} className={'fa-xl'} />
              </button>
            )}
          </div>
        </div>
        <div
          className={'flex-row pr-2'}
          ref={suggestionAutoCompleteProps.suggestionContainerRef}
        >
          <LocationQueryInput {...locationQueryInputProps} />
          <SuggestionAutoComplete {...suggestionAutoCompleteProps} />
        </div>
      </div>
    </div>
  );
};

export { LocationQuery };
