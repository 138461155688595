/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useFormatMessage } from 'app/common/hooks';
import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { MiniProductGrid, MiniProductSkeleton } from 'app/common/components';
import { ResultsLayoutContext } from 'app/search-and-browse/shared/contexts';

import {
  ResultsFilterPanel,
  ResultsPagination,
  ResultsSortSelector
} from './components';

import messages from './ResultsBody.messages';

/**
 * Render component for the body of browse and search results views.
 *
 * @visibleName Browse and Search Results Body
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const ResultsBody = ({
  browse = false,
  categoryList = false,
  loading = false,
  searchResponse,
  name
}) => {
  const formatMessage = useFormatMessage();
  const { breadcrumbs, useGrid } = React.useContext(ResultsLayoutContext);
  const [showAvailabilitySort, setAvailabilitySort] = React.useState(false);

  if (searchResponse === undefined || loading) {
    return <ResultsBody.Loading useGrid={useGrid} />;
  }

  const {
    content: products = [],
    facets = [],
    number: pageNumber = 0,
    sorts = [],
    totalPages = 0
  } = searchResponse || {};

  return (
    <section className="relative flex flex-col justify-between lg:flex-row lg:px-2">
      <aside className="pb-2 lg:w-1/4 lg:pb-0">
        {!isEmpty(sorts) && (
          <ResultsSortSelector
            browse={browse}
            sorts={sorts}
            showAvailabilitySort={showAvailabilitySort}
          />
        )}
        {!isEmpty(facets) && (
          <ResultsFilterPanel
            categoryList={categoryList}
            browse={browse}
            filters={facets}
            setAvailabilitySort={setAvailabilitySort}
          />
        )}
      </aside>
      <section className="flex flex-col mb-6 lg:w-3/4 lg:flex-grow lg:flex-shrink lg:ml-8 lg:mb-0 xl:ml-12">
        <div className="flex justify-start">
          {isEmpty(products) ? (
            <span className="text-gray-600">
              {formatMessage(messages.noResults)}
            </span>
          ) : (
            <MiniProductGrid
              breadcrumbs={breadcrumbs}
              containerName={name}
              products={products}
              useGrid={useGrid}
            />
          )}
        </div>
        {totalPages > 0 && (
          <ResultsPagination currentPage={pageNumber} totalPages={totalPages} />
        )}
      </section>
    </section>
  );
};

ResultsBody.Loading = ({ useGrid = true }) => (
  <section className="relative flex flex-col justify-between mb-4 lg:flex-row sm:mb-6 lg:px-2">
    <aside className="pb-2 lg:basis-1/4 lg:pb-0">
      <div className="flex flex-wrap items-center justify-start mb-4 md:mb-6">
        <div className="w-48 mr-2 bg-gray-200 leading-loose rounded">
          &nbsp;
        </div>
      </div>
      <div>
        <div className="w-48 mb-4 text-xl bg-gray-200 rounded">&nbsp;</div>
      </div>
    </aside>
    <section className="flex flex-col mb-6 lg:basis-3/4 lg:flex-grow lg:flex-shrink lg:ml-12 lg:mb-0">
      <div className="flex justify-start">
        <ul
          className={classNames('w-full sm:flex', {
            'flex-wrap sm:-m-3 ': useGrid,
            'flex-col': !useGrid
          })}
        >
          <MiniProductSkeleton usingGrid={useGrid} />
          <MiniProductSkeleton usingGrid={useGrid} />
        </ul>
      </div>
    </section>
  </section>
);

ResultsBody.propTypes = {
  /**
   * Indicates whether this is the body of a categoryList results instead of
   * product list or search. This impacts whether the category filter is hidden.
   */
  categoryList: PropTypes.bool,
  searchResponse: PropTypes.shape({
    facets: PropTypes.array,
    /** The number of the current page in the result set */
    pageNumber: PropTypes.number,
    /** The array of products to display */
    content: PropTypes.arrayOf(PropTypes.object),
    /** Array of sort options applicable to the browse or search results */
    sorts: PropTypes.arrayOf(
      PropTypes.shape({
        /** Whether this is the currently active sort option */
        active: PropTypes.bool,
        /** Whether the option is being used to sort in descending order */
        descending: PropTypes.bool,
        sortOption: PropTypes.shape({
          /** Display label for the option */
          displayLabel: PropTypes.string.isRequired,
          /** Value to use on API requests when the option is selected */
          name: PropTypes.string.isRequired,
          sortBehavior: PropTypes.string.isRequired,
          labelBehavior: PropTypes.string.isRequired
        }).isRequired
      })
    ),
    /** The total number of pages in the result set */
    totalPages: PropTypes.number
  })
};

export default ResultsBody;
export { ResultsBody };
