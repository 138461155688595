/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useContext, useMemo } from 'react';
import { get, isNil, merge } from 'lodash';

import { CartContext } from 'app/common/contexts';
import { Environment } from 'app/common/services';

import useModifyCartRequest from '../useModifyCartRequest';
import useCartInfo from '../useCartInfo';

/**
 * Hook to use to send a request ot the cart contactInfo API.
 */
function useCartContactInfoApi(
  emailAddress,
  config,
  sendImmediate = false,
  rejectOnError = true
) {
  const { cart, guestToken } = useContext(CartContext);
  const cartId = get(cart, 'id');
  const { baseUrl, contactInfoPath } = useCartInfo().checkoutOperations;
  const url = `${baseUrl}/${cartId}${contactInfoPath}`;

  const cartVersionHeaderName = Environment.get(
    'cart.version.header',
    'X-Cart-Version'
  );
  const cartGuestTokenHeaderName = Environment.get(
    'cart.token.header',
    'X-Guest-Token'
  );

  const finalConfig = useMemo(
    () =>
      merge({}, config, {
        method: 'post',
        data: { emailAddress },
        headers: !isNil(guestToken)
          ? {
              [cartVersionHeaderName]: cart.version,
              [cartGuestTokenHeaderName]: guestToken?.tokenString
            }
          : {
              [cartVersionHeaderName]: cart.version
            }
      }),
    [
      config,
      emailAddress,
      cartVersionHeaderName,
      cart,
      cartGuestTokenHeaderName,
      guestToken
    ]
  );
  return useModifyCartRequest(url, finalConfig, sendImmediate, rejectOnError);
}

export default useCartContactInfoApi;
