/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { noop } from 'lodash';

import useIntl from '../useIntl';

/**
 * Useful hook when translating messages in a functional component.
 *
 * <pre>
 * {@code
 * const formatTime = useFormatTime();
 * const num = formatTime(Date.now(), {
 *   hour: 'numeric',
 *   minute: 'numeric',
 * });
 * }
 * </pre>
 *
 * @TODO: refactor when https://github.com/yahoo/react-intl/pull/1186 is merged in react-intl
 * @return {Function} the intl formatDate function, or a noop
 */
function useFormatTime() {
  const intl = useIntl();
  return intl.formatTime || noop;
}

export default useFormatTime;
