/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty, isEqual } from 'lodash';
import { Redirect } from 'react-router-dom';

import { Stage } from 'app/checkout/elements';
import { CartContext, PaymentContext } from 'app/common/contexts';
import { FulfillmentType, SelectedPaymentType } from 'app/common/constants';
import { useFormatMessage, useNationalSiteContext } from 'app/common/hooks';

import {
  PassthroughPaymentInfoStage,
  PayPalPaymentInfoStage
} from './components';
import messages from './PaymentInfo.messages';
import SelectPaymentButton from 'app/checkout/components/CheckoutLayout/components/PaymentInfo/components/SelectPaymentButton';
import environment from 'app/common/services/Environment/Environment';
import {
  FULFILLMENT_INFO_PATH,
  FULFILLMENT_OPTIONS_PATH,
  LOCATION_INFO_PATH,
  REVIEW_PATH
} from 'app/checkout/components/CheckoutLayout/CheckoutPaths';

/**
 * Render component for checkout's payment info stage. This includes billing and
 * payment method infos.
 */
const PaymentInfo = ({ active, stageNumber = 3 }) => {
  const { cart, resolving } = React.useContext(CartContext);
  const { dealerSelected } = useNationalSiteContext();
  const {
    paypalEnabled,
    payments,
    resolving: resolvingPayment
  } = React.useContext(PaymentContext);
  const {
    address: fulfillmentAddress = {},
    fulfillmentOption = {},
    type: fulfillmentType,
    pricedFulfillmentOption = {}
  } = get(cart, 'fulfillmentGroups[0]', {});
  const resolved = !resolving && cart !== undefined;
  const isVirtualFulfillment = fulfillmentType === FulfillmentType.VIRTUAL;
  const [isPayInStore, setIsPayInStore] = React.useState(
    get(cart, 'attributes.PAY_IN_STORE', false)
  );
  const formatMessage = useFormatMessage();
  const activePayments = get(payments, 'content');
  const [selectedPaymentType, setSelectedPaymentType] = React.useState(
    isPayInStore
      ? SelectedPaymentType.PAY_IN_STORE
      : isEmpty(activePayments)
      ? ''
      : get(activePayments, '[0].gatewayType')
  );
  const needsPricedOption =
    isFulfillmentServiceEnabled() && isEmpty(pricedFulfillmentOption);
  if (active && resolved && !resolvingPayment) {
    if (!dealerSelected) {
      return <Redirect to={LOCATION_INFO_PATH} push />;
    }

    if (!isVirtualFulfillment && isEmpty(fulfillmentAddress)) {
      return <Redirect to={FULFILLMENT_INFO_PATH} push />;
    }
    if (!isEmpty(fulfillmentAddress) && needsPricedOption) {
      return <Redirect to={FULFILLMENT_OPTIONS_PATH} push />;
    }

    if (paypalEnabled !== undefined && !paypalEnabled) {
      return <Redirect to={REVIEW_PATH} push />;
    }
  }

  let completed = !isEmpty(activePayments);

  if (isPayInStore) {
    completed = isPayInStore;
  } else if (selectedPaymentType === SelectedPaymentType.PAYPAL_CHECKOUT_V2) {
    const paymentGatewayProperties = get(
      activePayments,
      '[0].paymentGatewayProperties',
      {}
    );
    completed = completed && !isEmpty(paymentGatewayProperties);
  } else {
    const hasAddress = !isEmpty(fulfillmentAddress);
    const hasFulfillmentOption =
      isVirtualFulfillment || !isEmpty(fulfillmentOption) || !needsPricedOption;
    completed = completed && hasAddress && hasFulfillmentOption;
  }

  const paypalSelected =
    !isPayInStore &&
    isEqual(selectedPaymentType, SelectedPaymentType.PAYPAL_CHECKOUT_V2);

  let PaymentInfoComponent = '';

  if (!paypalEnabled) {
    PaymentInfoComponent = null;
  } else if (paypalSelected) {
    PaymentInfoComponent = PaymentInfo.PayPal;
  } else {
    PaymentInfoComponent = PaymentInfo.Passthrough;
  }

  return PaymentInfoComponent === null ? null : (
    <Stage
      active={active}
      completed={completed}
      heading={formatMessage(messages.title)}
      number={isVirtualFulfillment ? stageNumber - 1 : stageNumber}
    >
      {active && (
        <>
          <h3 className="mb-4 text-gray-900 font-bold lg:text-lg">
            {formatMessage(messages.legend)}
          </h3>
          <div className="flex items-center">
            <SelectPaymentButton
              className="mr-2"
              isEnabled={paypalEnabled}
              isSelected={paypalSelected}
              onClickEffect={() => {
                setIsPayInStore(false);
                setSelectedPaymentType(SelectedPaymentType.PAYPAL_CHECKOUT_V2);
              }}
              displayLabel={messages.paypal}
            />
            <SelectPaymentButton
              className="ml-2"
              isSelected={isPayInStore}
              onClickEffect={() => {
                setIsPayInStore(true);
                setSelectedPaymentType(SelectedPaymentType.PAY_IN_STORE);
              }}
              displayLabel={messages.payInStore}
            />
          </div>
        </>
      )}
      <PaymentInfoComponent
        active={active}
        completed={completed}
        isPayInStore={isPayInStore}
      />
    </Stage>
  );
};

function isFulfillmentServiceEnabled() {
  return environment.get('FULFILLMENT_SERVICE_ENABLED', 'true') === 'true';
}

PaymentInfo.Passthrough = PassthroughPaymentInfoStage;
PaymentInfo.PayPal = PayPalPaymentInfoStage;

export default PaymentInfo;
export { PaymentInfo };
