/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { createContext } from 'react';
import { noop } from 'lodash';

import Environment from 'app/common/services/Environment';

const CurrencyContext = createContext({
  /**
   * The set of allowed currencies.
   *
   * @type {Array}
   */
  allowedCurrencies: [],
  /**
   * The currently selected currency.
   *
   * @type {string}
   */
  currentCurrency: Environment.get('DEFAULT_CURRENCY', 'USD'),
  /**
   * Sets the array of allowed currencies to the one provided.
   * @param {Array} allowedCurrencies - new array of allowed currencies
   */
  setAllowedCurrencies: noop,
  /**
   * Sets the current currency to the provided currency.
   * @param {string} currency - the new currency code
   */
  setCurrentCurrency: noop
});

export default CurrencyContext;
export { CurrencyContext };
