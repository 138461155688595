/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

/**
 * Specifies the types of fulfillment for order items.
 *
 * @type {{
 *   SHIP: string,
 *   PICKUP: string,
 *   VIRTUAL: string,
 *   IN_STORE: string
 * }}
 */
const FulfillmentType = Object.freeze({
  /**
   * The fulfillment group contains goods that need to be physically shipped in order to be
   * fulfilled.
   */
  SHIP: 'SHIP',
  /**
   * The fulfillment group contains goods that need to be picked up at a store or other location
   * in order to be fulfilled.
   */
  PICKUP: 'PICKUP',
  /**
   * The fulfillment group contains goods that are to be virtually fulfilled.
   */
  VIRTUAL: 'VIRTUAL',
  /**
   * The in store priced fulfillment type.
   */
  IN_STORE: 'IN_STORE'
});

export default FulfillmentType;
