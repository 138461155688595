/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  selectDealer: {
    id: 'MiniProductGrid.MiniProduct.selectDealer',
    defaultMessage: 'Set a location to see availability'
  },
  availability: {
    id: 'MiniProductGrid.MiniProduct.availability',
    defaultMessage:
      'Available {isAvailable, select, true {today} other {in 2-5 Days}}'
  },
  availabilityOverride: {
    id: 'MiniProductGrid.MiniProduct.availability.override',
    defaultMessage: 'Available Next Day Air'
  },
  coreCost: {
    id: 'MiniProductGrid.MiniProduct.coreCost',
    defaultMessage: 'Core Cost:'
  }
});
