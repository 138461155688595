/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'OrderTracking.OrderReview.title',
    defaultMessage: 'Track Order'
  },
  orderNumberLabel: {
    id: 'OrderTracking.OrderReview.orderNumber',
    defaultMessage: 'Order #'
  },
  receiptCaveat: {
    id: 'OrderTracking.OrderReview.receiptCaveat',
    defaultMessage:
      'Your receipt was sent to {emailAddress}. If you never received it, please contact customer service.'
  },
  continueShopping: {
    id: 'OrderTracking.OrderReview.continueShopping',
    defaultMessage: 'Continue Shopping'
  },
  print: {
    id: 'OrderTracking.OrderReview.print',
    defaultMessage: 'Print'
  },
  fulfillmentAddressLabel: {
    id: 'OrderTracking.OrderReview.fulfillment.addressLabel',
    defaultMessage: 'Delivery Address'
  },
  fulfillmentMethodLabel: {
    id: 'OrderTracking.OrderReview.fulfillment.methodLabel',
    defaultMessage: 'Delivery Method'
  },
  contactAddressLabel: {
    id: 'OrderTracking.OrderReview.contact.addressLabel',
    defaultMessage: 'Your Contact Info'
  },
  preferredCommunicationMethod: {
    id: 'OrderTracking.OrderReview.contact.preferredCommunicationMethod',
    defaultMessage: 'Preferred to be contacted by'
  },
  fulfillmentMethod: {
    id: 'OrderTracking.OrderReview.fulfillment.method',
    defaultMessage: '{method} Delivery'
  },
  totals: {
    id: 'OrderTracking.OrderReview.Payment.totals',
    defaultMessage: 'Payment Summary'
  },
  dealerLabel: {
    id: 'OrderTracking.OrderReview.fulfillment.dealerLabel',
    defaultMessage: 'Ordered from\n{dealerName}'
  },
  heading: {
    id: 'OrderTracking.OrderReview.itemsSummary.heading',
    defaultMessage: 'Items Summary'
  }
});
