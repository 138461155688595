/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';

import useRestApi from '../useRestApi';

/**
 * Hook that fetches the menuItems for a menu matching the given name.
 *
 * @param {string} fetchUrl - url of the menu fetch endpoint
 * @param {string} name - name of the menu for which to fetch items
 *
 * @return {{error: bool, exception: Object, loading: bool, menuItems: Array}}
 *     Menu fetch response
 */
function useMenuApi(fetchUrl, name) {
  const config = useMemo(() => ({ params: { menuName: name } }), [name]);

  return useRestApi(fetchUrl, config);
}

export default useMenuApi;
export { useMenuApi };
