/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  adjustments: {
    id: 'Cart.Summary.adjustments',
    defaultMessage: 'Total Savings'
  },
  subtotal: {
    id: 'Cart.Summary.subtotal',
    defaultMessage: 'Subtotal'
  },
  total: {
    id: 'Cart.Summary.total',
    defaultMessage: 'Estimated Total'
  },
  totalDisclaimer: {
    id: 'Cart.Summary.totalDisclaimer',
    defaultMessage:
      'Estimated total does not yet include applicable tax or shipping and handling charges.'
  },
  title: {
    id: 'Cart.Summary.title',
    defaultMessage: 'Summary'
  },
  checkout: {
    id: 'Cart.Summary.checkout',
    defaultMessage: 'Checkout'
  }
});
