/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { get, isEmpty, isString, map, orderBy } from 'lodash';
import { default as qs } from 'query-string';

import { CustomerContext, FleetContext } from 'app/common/contexts';
import { useFormatMessage, useHistory, useLocation } from 'app/common/hooks';

import Dropdown from '../Dropdown';
import messages from './FleetFilter.messages';

const FleetFilter = () => {
  const formatMessage = useFormatMessage();
  const location = useLocation();
  const history = useHistory();
  const { activeFleet } = React.useContext(FleetContext);
  const { fleets } = React.useContext(CustomerContext);
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    let opts = orderBy(
      map(fleets, fleet => {
        const active = fleet.id === get(activeFleet, 'id');

        return {
          active,
          label: fleet.name,
          value: fleet.id
        };
      }),
      ['label'],
      ['asc']
    );

    if (!isEmpty(activeFleet)) {
      opts = opts.concat([
        {
          active: false,
          color: 'red-700',
          label: messages.noFleetOptionLabel,
          value: ''
        }
      ]);
    }

    setOptions(opts);
  }, [activeFleet, fleets]);

  if (isEmpty(fleets)) {
    return null;
  }

  return (
    <aside className="flex items-center justify-start sm:my-2 lg:mx-2">
      <div id="fleet-selector-label" className="mr-2 font-bold leading-loose">
        {formatMessage(messages.label)}:
      </div>
      <Dropdown hoverable>
        <Dropdown.Menu.Trigger
          aria-labelledby="fleet-selector-label fleet-select-trigger"
          id="fleet-select-trigger"
          triggerClassName="pl-1 pr-2 text-primary-600 font-medium rounded hover:text-primary-800 active:text-primary-600 focus:outline-none focus:shadow-outline"
        >
          <span>
            {isEmpty(activeFleet)
              ? formatMessage(messages.placeholder)
              : activeFleet.name}
          </span>
        </Dropdown.Menu.Trigger>
        <Dropdown.Menu aria-labelledby="fleet-selector-label">
          {options.map(option => {
            const { active, color, label, value } = option;

            return (
              <Dropdown.Menu.Item
                isActive={active}
                key={value}
                onClick={() => {
                  const params = qs.parse(location.search);
                  params.fleetId = value;

                  history.push({ ...location, search: qs.stringify(params) });
                }}
                style={{ minWidth: '130px' }}
              >
                <span className={classNames({ [`text-${color}`]: !!color })}>
                  {isString(label) ? label : formatMessage(label)}
                </span>
              </Dropdown.Menu.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </aside>
  );
};

export default FleetFilter;
export { FleetFilter };
