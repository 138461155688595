/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { values } from 'lodash';
import React from 'react';

import { AssetType } from 'app/common/constants';

/**
 * Helper component for a modifying the page head with metadata concerning the
 * product's assets such as for Open Graph.
 *
 * @visibleName Product Detail Page: Assets: Helmet
 * @author [Nathan Moore](https://github.com/nathandmoore)
 *
 * @todo: handle more than just images
 * @todo: handle multiple resolution versions ?
 */
const AssetsHelmet = ({ altText, url }) => {
  return (
    <Helmet>
      <meta property="og:image" content={url} />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content={900} />
      <meta property="og:image:height" content={900} />
      <meta property="og:image:alt" content={altText} />
    </Helmet>
  );
};

AssetsHelmet.propTypes = {
  altText: PropTypes.string,
  /** HTML to embed in place of having a source URL */
  embedCode: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  type: PropTypes.oneOf(values(AssetType)),
  url: PropTypes.string
};

export default AssetsHelmet;
export { AssetsHelmet };
