/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { createContext } from 'react';
import { noop } from 'lodash';

/**
 * @typedef Product
 * @property {string} id
 * @property {string} sku
 * @property {string} name
 * @property {string} uri
 * @property {string} description
 * @property {MonetaryAmount} defaultPrice
 * @property {string} metaTitle
 * @property {string} metaDescription
 * @property {boolean} online
 * @property {boolean} availableOnline
 * @property {boolean} stock
 * @property {Array} interchangePartNumbers
 * @property {string} title
 * @property {string} longDescription
 */

/**
 * @type {Readonly<{
 *   product: ({}|undefined),
 *   addToCartErrors: ({}|undefined),
 *   setAddToCartErrors: Function,
 *   documents: Array<ProductDocument>
 * }>}
 */
const initialContext = Object.freeze({
  addToCartErrors: undefined,
  setAddToCartErrors: noop,
  product: undefined,
  documents: []
});

/**
 * Context containing state relevant for the many subcomponents of the PDP.
 *
 * @type {React.Context<Readonly<{
 *   product: (Product|undefined),
 *   addToCartErrors: ({}|undefined),
 *   setAddToCartErrors: Function,
 *   documents: Array<ProductDocument>
 * }>>}
 */
const PdpContext = createContext(initialContext);

PdpContext.defaultValue = initialContext;

export default PdpContext;
