/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { Field as FormikField } from 'formik';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import Input from '../Input';

/**
 * Render component that combines an input and formik props.
 */
const Field = ({
  field,
  form = { errors: [], touched: [] },
  showErrors = true,
  ...props
}) => {
  return (
    <Input
      hasErrors={!isEmpty(get(form.errors, field.name))}
      touched={!!get(form.touched, field.name)}
      {...field}
      {...props}
    />
  );
};

Field.propTypes = {
  field: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  form: PropTypes.object,
  /** Whether to display errors automatically */
  showErrors: PropTypes.bool
};

/**
 * Render component that wraps a `<Field>` with formik's
 * [`<Field>`](https://jaredpalmer.com/formik/docs/api/field).
 */
const WrappedField = ({ component = Field, ...props }) => (
  <FormikField {...props} component={component} />
);

WrappedField.propTypes = {
  /** Component to render with the `<FormikField>`. Default is `<Field>` */
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
    PropTypes.func
  ]),
  name: PropTypes.string.isRequired
};

export default WrappedField;
export { Field };
