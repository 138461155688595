/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  totals: {
    id: 'OrderTracking.OrderReview.Pricing.totals',
    defaultMessage: 'Payment Summary'
  },
  subtotal: {
    id: 'OrderTracking.OrderReview.Pricing.subtotal',
    defaultMessage: 'Subtotal:'
  },
  adjustments: {
    id: 'OrderTracking.OrderReview.Pricing.adjustments',
    defaultMessage: 'Savings:'
  },
  fulfillmentAdjustments: {
    id: 'OrderTracking.OrderReview.Pricing.fulfillmentAdjustments',
    defaultMessage: 'Delivery Savings:'
  },
  delivery: {
    id: 'OrderTracking.OrderReview.Pricing.delivery',
    defaultMessage: 'Delivery:'
  },
  taxes: {
    id: 'OrderTracking.OrderReview.Pricing.taxes',
    defaultMessage: 'Taxes & Fees:'
  },
  total: {
    id: 'OrderTracking.OrderReview.Pricing.total',
    defaultMessage: 'Total:'
  },
  coreCost: {
    id: 'OrderTracking.OrderReview.Pricing.coreCostTotal',
    defaultMessage: 'Core Cost Total:'
  }
});
