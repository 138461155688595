/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { get, map } from 'lodash';

/**
 * Takes a `fulfillmentGroup` and returns an object containing the total
 * adjustments amount and currency for that group.
 *
 * @param {Object} fulfillmentGroup - A fulfillment group from a cart
 *
 * @return {{amount: number, currency: string}|{}}
 */
function calculateFulfillmentGroupAdjustmentsTotal(fulfillmentGroup) {
  const priceBeforeAdjustments = get(
    fulfillmentGroup,
    'groupFulfillmentPriceBeforeAdjustments.amount',
    0.0
  );
  const itemsSubtotal = get(
    fulfillmentGroup,
    'fulfillmentItemsSubtotal.amount',
    0.0
  );
  const totalFulfillmentPrice = handleDecimalSubtraction(
    get(fulfillmentGroup, 'totalFulfillmentPrice.amount', 0.0),
    itemsSubtotal
  );
  const itemAdjustmentsTotal = get(fulfillmentGroup, 'fulfillmentItems', [])
    .map(fi => get(fi, 'fulfillmentAdjustmentsTotal.amount', 0.0))
    .reduce((sum, adjustment) => sum + adjustment, 0.0);

  if (
    totalFulfillmentPrice >= priceBeforeAdjustments &&
    itemAdjustmentsTotal === 0.0
  ) {
    return {};
  }

  const currency = get(
    fulfillmentGroup,
    'groupFulfillmentPriceBeforeAdjustments.currency'
  );
  const groupAdjustmentsTotal = handleDecimalSubtraction(
    priceBeforeAdjustments,
    totalFulfillmentPrice
  );

  return {
    amount: groupAdjustmentsTotal + itemAdjustmentsTotal,
    currency
  };
}

function handleDecimalSubtraction(...args) {
  return map(args, arg => parseFloat(arg.toFixed(4))).reduce((diff, arg, i) => {
    if (i === 0) {
      return arg;
    }

    return (diff * 10000 - arg * 10000) / 10000;
  }, parseFloat('0.0000'));
}

export { calculateFulfillmentGroupAdjustmentsTotal };
