/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { createContext } from 'react';
import { noop } from 'lodash';

import Environment from '../../services/Environment';

const LocaleContext = createContext({
  /**
   * The set of allowed locales.
   *
   * @type {Array}
   */
  allowedLocales: [],
  /**
   * The currently selected locale code.
   *
   * @type {string}
   */
  currentLocale: Environment.get('DEFAULT_LOCALE', 'en'),
  /**
   * Sets the array of allowed locales to the one provided.
   * @param {Array} allowedLocales - new array of allowed locales
   */
  setAllowedLocales: noop,
  /**
   * Sets the current locale to the provided locale code.
   * @param {string} locale - the new locale code
   */
  setCurrentLocale: noop
});

export default LocaleContext;
export { LocaleContext };
