/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Card = ({ active = false, name, small = false }) => (
  <span
    className={classNames('inline-flex items-center justify-center mr-2', {
      'text-gray-300': !active,
      'text-black': active,
      'text-2xl': !small,
      'text-base': small
    })}
  >
    <i className={`pf ${name}`} />
  </span>
);

const CreditCardIcons = ({ activeName }) => (
  <div className="flex items-center justify-start w-full -mt-2 mb-4">
    <CreditCardIcons.Types.AMEX active={activeName === 'AMEX'} />
    <CreditCardIcons.Types.DISCOVER active={activeName === 'DISCOVER'} />
    <CreditCardIcons.Types.MASTERCARD active={activeName === 'MASTERCARD'} />
    <CreditCardIcons.Types.VISA active={activeName === 'VISA'} />
  </div>
);

CreditCardIcons.Types = Object.freeze({
  AMEX: ({ active, small }) => (
    <Card active={active} name="pf-american-express" small={small} />
  ),
  DISCOVER: ({ active, small }) => (
    <Card active={active} name="pf-discover" small={small} />
  ),
  MASTERCARD: ({ active, small }) => (
    <Card active={active} name="pf-mastercard-alt" small={small} />
  ),
  VISA: ({ active, small }) => (
    <Card active={active} name="pf-visa" small={small} />
  )
});

CreditCardIcons.propTypes = {
  activeName: PropTypes.string
};

export default CreditCardIcons;
export { CreditCardIcons };
