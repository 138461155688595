/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { get, isEmpty, map, take } from 'lodash';
import PropTypes from 'prop-types';

import { MarketingMessageLocationType } from 'app/common/constants';
import {
  useItemMarketingMessageApi,
  useItemMarketingMessageRequest,
  usePromotionInfo
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

/**
 * Render component that fetches offer marketing messages for an item (e.g.,
 * Product) and displays them.
 *
 * @visibleName Offer Marketing Messages
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const MarketingMessages = ({
  className,
  items,
  maxMessagesToShow = 1,
  locationTypes,
  itemType,
  categoryId = undefined
}) => {
  const request = useItemMarketingMessageRequest({
    items,
    itemType,
    maxMessageCount: maxMessagesToShow,
    categoryId
  });
  const { itemMarketingMessage } = usePromotionInfo();
  const { fetchUrl: fetchMarketingMessageUrl } = itemMarketingMessage;
  const { error, exception, loading, response } = useItemMarketingMessageApi(
    fetchMarketingMessageUrl,
    request
  );

  if (error) {
    logError({
      ...exception,
      when: `fetching marketing messages for items: ${items.map(
        item => item.id
      )}`
    });
  }

  if (error || isEmpty(response) || loading) {
    return null;
  }
  const messageForTypes = findMessagesForTypes(response, locationTypes);

  const prioritizedMessages = take(messageForTypes, maxMessagesToShow);

  return (
    <div
      className={classNames('flex flex-col text-red-600', {
        [className]: !!className
      })}
    >
      {map(prioritizedMessages, message => {
        if (isEmpty(message.text)) {
          return null;
        }

        return <MarketingMessage key={message.id} {...message} />;
      })}
    </div>
  );
};

/**
 *
 * @typedef {Object} MarketingMessagesResponse
 * @property {Object[]} cartMessages - The messages for the cart
 * @property {Object[]} browseMessages - The messages for browsing
 * @property {Object[]} cartItemMessages - The messages for the cart items
 * @property {Object[]} browseItemMessages - The messages for browsing items
 * @property {Object[]} productDetailMessages - The messages for product details
 */
/**
 * Find the messages for the types specified.
 * @param {MarketingMessagesResponse} response - The response from the server
 * @param {string[]} types - The types of messages to find
 * @returns {Object[]} The messages for the types specified
 */
function findMessagesForTypes(response, types) {
  let messages = [];
  if (types.includes(MarketingMessageLocationType.CART)) {
    messages = messages.concat(response.cartMessages);
  }
  if (types.includes(MarketingMessageLocationType.BROWSE)) {
    messages = messages.concat(response.browseMessages);
  }
  if (types.includes(MarketingMessageLocationType.CART_ITEM)) {
    messages = messages.concat(response.cartItemMessages);
  }
  if (types.includes(MarketingMessageLocationType.BROWSE_ITEM)) {
    messages = messages.concat(response.browseItemMessages);
  }
  if (types.includes(MarketingMessageLocationType.PRODUCT_DETAIL)) {
    messages = messages.concat(response.productDetailMessages);
  }
  // Sort by priority property. Lower numbers are higher priority.
  return messages.sort(
    (a, b) =>
      get(a, 'priority', Number.MAX_SAFE_INTEGER) -
      get(b, 'priority', Number.MAX_SAFE_INTEGER)
  );
}

MarketingMessages.propTypes = {
  /** Class name to add to the component. Defaults to OfferMarketingMessages. */
  className: PropTypes.string,
  /** The item for which to find messages */
  items: PropTypes.array.isRequired,
  /** The location where the messages will appear */
  locationTypes: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(MarketingMessageLocationType))
  ).isRequired,
  /** How many matching messages to show. Defaults to 1. */
  maxMessagesToShow: PropTypes.number,
  /** The type of the target item. Either CART_ITEM or PRODUCT. */
  itemType: PropTypes.oneOf(Object.values(['CART_ITEM', 'PRODUCT'])).isRequired,
  /**
   * The category ID for marketing messages.
   */
  categoryId: PropTypes.string
};

/**
 * Render component for a single marketing message.
 */
const MarketingMessage = ({ text }) => {
  return <em className="mb-2 last:mb-0">{text}</em>;
};

MarketingMessage.propTypes = {
  /** The text to display */
  text: PropTypes.string.isRequired
};

export default MarketingMessages;
export { MarketingMessages, MarketingMessage };
