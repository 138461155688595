/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import { Environment } from 'app/common/services';

function useTenantInfo() {
  return React.useMemo(
    () => ({
      baseUrl: Environment.get('TENANT_BASE_URL', 'tenant'),
      applicationUri: Environment.get(
        'TENANT_APPLICATION_ENDPOINT',
        '/applications'
      ),
      fleetUri: Environment.get('TENANT_FLEET_ENDPOINT', '/fleets'),
      fleetParam: Environment.get('TENANT_FLEET_ENDPOINT_PARAM', 'customer')
    }),
    []
  );
}

export default useTenantInfo;
