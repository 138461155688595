/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useContext } from 'react';
import { I18nContext } from 'app/common/contexts';

/**
 * @TODO: refactor `useIntl` when https://github.com/yahoo/react-intl/pull/1186 is merged in react-intl
 * @return {Intl} the intl context
 */
function useIntl() {
  return useContext(I18nContext);
}

export default useIntl;
