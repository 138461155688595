/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get } from 'lodash';
import { Helmet as ReactHelmet } from 'react-helmet';

import {
  useFormatMessage,
  useGtmPageView,
  useNationalSiteContext,
  usePersonalizationInfo
} from 'app/common/hooks';
import { TargetedContentRenderer } from 'app/personalization/helpers';

import messages from './Privacy.messages';
import { useHeaderMetadata } from '../../../core/components/App';

const Privacy = () => {
  const formatMessage = useFormatMessage();
  const personalizationInfo = usePersonalizationInfo();
  const { partsToGo } = useNationalSiteContext();
  const targeterName = get(personalizationInfo, 'privacy.targeterName');
  const { siteTitle } = useHeaderMetadata();
  const canonical = `${window.location.origin}/privacy`;
  const title = formatMessage(messages.title);

  useGtmPageView('Privacy Policy');

  const defaultDescription = formatMessage(messages.description);
  const dealerDescription = formatMessage(messages.descriptionDealer, {
    dealerName: siteTitle
  });
  const description = partsToGo ? defaultDescription : dealerDescription;
  return (
    <main className="container relative flex-grow mx-auto p-4 sm:py-8 md:px-6 lg:px-8">
      <ReactHelmet titleTemplate={`%s - ${siteTitle}`}>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="canonical" content={canonical} />
        <meta name="robots" content="index,follow" />
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content={siteTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={description} />
        <link rel="canonical" href={canonical} />
      </ReactHelmet>
      <TargetedContentRenderer name={targeterName} />
    </main>
  );
};

export default Privacy;
export { Privacy };
