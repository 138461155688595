/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { AuthContext } from 'app/auth/contexts';
import { get } from 'lodash';
import React from 'react';

import { TertiaryButton } from 'app/common/components';
import { useCartInfo, useFormatMessage, useRestApi } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import messages from './RemoveListItem.messages';

const RemoveListItem = ({ disabled = false, listId, id, handlePostRemove }) => {
  const formatMessage = useFormatMessage();
  const {
    listOperations: { baseUrl, itemsContextPath }
  } = useCartInfo();
  const { user } = React.useContext(AuthContext);
  const customerRef = React.useMemo(
    () => ({
      customerId: get(user, 'serviceId'),
      username: get(user, 'username'),
      isRegistered: true,
      accountId: get(user, 'attributes[account_id]')
    }),
    [user]
  );
  const config = React.useMemo(
    () => ({
      method: 'del',
      params: customerRef
    }),
    [customerRef]
  );
  const {
    error,
    exception,
    sendCallback: deleteItems
  } = useRestApi(
    `${baseUrl}/${listId}${itemsContextPath}/${id}`,
    config,
    false
  );

  React.useEffect(() => {
    if (error) {
      logError({
        ...exception,
        when: `removing list item ${id}`
      });
    }
  }, [error, exception, id]);

  return (
    <TertiaryButton
      disabled={disabled}
      onClick={async () => {
        if (disabled) {
          return;
        }

        await deleteItems();
        handlePostRemove();
      }}
      colorClassName="text-red-700 hover:text-red-800 focus:text-red-800 active:text-red-600 disabled:text-gray-400"
      size={TertiaryButton.Size.SMALL}
    >
      {formatMessage(messages.label)}
    </TertiaryButton>
  );
};

export default RemoveListItem;
export { RemoveListItem };
