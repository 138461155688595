/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { get, isNil } from 'lodash';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import { useCheckoutApi } from 'app/checkout/hooks';
import { Icon } from 'app/common/components';
import {
  CartContext,
  CurrencyContext,
  PaymentContext
} from 'app/common/contexts';
import { useCartInfo } from 'app/common/hooks/cart';

const PayPalButton = props => {
  const {
    funding,
    handleApprove,
    handlePreCreateOrder,
    shippingInfo,
    showButton,
    style
  } = props;
  const [error, setError] = useState(undefined);
  const [paypal, setPaypal] = React.useState(
    !!window && !!window.paypal ? window.paypal : null
  );
  const { cart } = React.useContext(CartContext);

  React.useEffect(() => {
    setTimeout(() => {
      setPaypal(!!window && !!window.paypal ? window.paypal : null);
    }, 100);
  }, []);

  const Button = useMemo(() => {
    return paypal ? paypal.Buttons.driver('react', { React, ReactDOM }) : null;
  }, [paypal]);
  const styles = useMemo(
    () => ({ ...PayPalButton.defaultStyle, ...style }),
    [style]
  );
  const createOrder = useCreateOrder(shippingInfo, handlePreCreateOrder);
  const [enablePayPal, setEnablePayPal] = useState(
    get(cart, 'attributes.ACCEPT_ITEM_AVAILABILITY')
  );
  const [payPalActions, setPayPalActions] = useState(undefined);

  useEffect(() => {
    setEnablePayPal(get(cart, 'attributes.ACCEPT_ITEM_AVAILABILITY'));
  }, [cart]);

  return (
    <>
      {showButton && Button !== null && (
        <Button
          fundingSource={funding}
          createOrder={createOrder}
          funding={funding}
          onApprove={handleApprove}
          onInit={(data, actions) => {
            if (!enablePayPal) {
              actions.disable();
            }
            setPayPalActions(actions);
          }}
          onClick={() => {
            !!handlePreCreateOrder && handlePreCreateOrder(payPalActions);
          }}
          onError={error => {
            if (error.message === 'Expected an order id to be passed') {
              return;
            }

            setError(error.message);
          }}
          style={styles}
        />
      )}
      {(error || props.error) && (
        <div className="flex items-center mb-4 px-2 py-1 text-sm text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
          <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
          <span>{error || props.error}</span>
        </div>
      )}
    </>
  );
};

function useCreateOrder(shippingInfo) {
  const { createOrderContextPath } = useCartInfo().operations.paypal;
  const { paypalAccount } = React.useContext(PaymentContext);
  const { cart } = React.useContext(CartContext);
  const { currentCurrency } = React.useContext(CurrencyContext);
  const cartId = get(cart, 'id');

  const config = React.useMemo(
    () => ({
      method: 'post',
      data: {
        merchantId: paypalAccount,
        shippingPreference: shippingInfo.shippingPreference,
        shippingAddress: shippingInfo.address,
        currency: currentCurrency
      }
    }),
    [paypalAccount, shippingInfo, currentCurrency]
  );

  if (isNil(cartId)) {
    console.error('There was an error getting a cart ID to resolve paypal');
  }

  const { sendCallback } = useCheckoutApi(
    `${createOrderContextPath}/${cartId}`,
    undefined,
    undefined,
    config,
    cart
  );

  return useCallback(async () => {
    const order = await sendCallback();
    return order.id;
  }, [sendCallback]);
}

PayPalButton.defaultStyle = Object.freeze({
  color: 'gold',
  label: 'paypal',
  layout: 'vertical',
  shape: 'rect'
});

PayPalButton.Shipping = Object.freeze({
  NO_SHIPPING: 'NO_SHIPPING',
  GET_FROM_FILE: 'GET_FROM_FILE',
  SET_PROVIDED_ADDRESS: 'SET_PROVIDED_ADDRESS'
});

PayPalButton.propTypes = {
  onApprove: PropTypes.func,
  showButton: PropTypes.bool,
  shippingInfo: PropTypes.shape({
    address: PropTypes.object,
    shippingPreference: PropTypes.oneOf([
      'NO_SHIPPING',
      'GET_FROM_FILE',
      'SET_PROVIDED_ADDRESS'
    ])
  }),
  style: PropTypes.object
};

PayPalButton.defaultProps = {
  commit: false,
  intent: 'authorize',
  shippingInfo: { shippingPreference: PayPalButton.Shipping.NO_SHIPPING },
  showButton: true,
  style: PayPalButton.defaultStyle
};

export default PayPalButton;
export { PayPalButton };
