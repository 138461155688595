/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { concat, get, isEmpty, map } from 'lodash';
import AnimateHeight from 'react-animate-height';

import { Icon } from 'app/common/components';
import { CartContext } from 'app/common/contexts';
import { useFormatMessage, useToggle } from 'app/common/hooks';

import { AddPromoCodeForm, PromoCode } from './components';
import messages from './CartPromotions.messages';

/**
 * Render component for promotional code management in the Cart view.
 *
 * @visibleName Cart Page: Promotions
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const CartPromotions = ({ disabled = false }) => {
  const formatMessage = useFormatMessage();
  const { cart } = React.useContext(CartContext);
  const campaignRefs = get(cart, 'campaignRefs', []);
  const offerCodes = get(cart, 'offerCodes', []);
  const codes = concat(
    offerCodes,
    map(campaignRefs, campaign => campaign.campaignCode)
  );
  const [isActive, toggleActive] = useToggle(!isEmpty(codes));

  return (
    <section
      className={classNames(
        'flex flex-col w-full mt-4 rounded shadow bg-white lg:shadow-md',
        {
          'is-active': isActive
        }
      )}
    >
      <button
        className="flex items-center justify-between w-full py-3 px-4 capitalize text-gray-600 text-xs text-center capitalize font-bold hover:text-gray-700 hover:underline focus:underline focus:outline-none rounded"
        id="promotions-toggle-trigger"
        aria-expanded={isActive}
        type="button"
        onClick={() => {
          if (disabled) {
            return;
          }

          toggleActive();
        }}
        disabled={disabled}
      >
        <span>{formatMessage(messages.title)}</span>
        {/* TODO: fade out and rotate when switching */}
        {!isActive && <Icon name="plus" size="xs" />}
        {isActive && <Icon name="minus" size="xs" />}
      </button>
      <AnimateHeight duration={200} height={isActive ? 'auto' : 0}>
        <div className="pt-2" aria-hidden={!isActive}>
          <AddPromoCodeForm />
          <ul className="flex flex-wrap mb-2 px-4 list-none">
            {map(codes, (code, index) => (
              <PromoCode key={`code-${index}`} code={code} />
            ))}
          </ul>
        </div>
      </AnimateHeight>
    </section>
  );
};

export default CartPromotions;
export { CartPromotions };
