/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { map, isEmpty } from 'lodash';

import { useFormatMessage } from 'app/common/hooks';

import { AddressCard } from '../';
import messages from './UserAddresses.messages';

const UserAddresses = ({
  addresses,
  refetchAddresses,
  editAddress,
  selectedId
}) => {
  const formatMessage = useFormatMessage();
  return (
    <section>
      {isEmpty(addresses) ? (
        <div className="w-full text-bold text-gray-500 text-xl sm:mt-10 sm:ml-10 sm:text-2xl lg:mt-20 lg:ml-20">
          {formatMessage(messages.addressesEmptyPrompt)}
        </div>
      ) : (
        <ul className="block list-none">
          {map(addresses, customerAddress => (
            <AddressCard
              key={customerAddress.id}
              customerAddress={customerAddress}
              refetchAddresses={refetchAddresses}
              editAddress={editAddress}
              selected={customerAddress.id === selectedId}
            />
          ))}
        </ul>
      )}
    </section>
  );
};

export default UserAddresses;
