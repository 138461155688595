import messages from './BrandsComponents.messages.js';
import { useFormatMessage } from 'app/common/hooks';
import { Link } from 'app/common/components';
import { capitalizeBrand } from '../utils';
import { useBrandsCategoryUrlParams } from '../hooks';

/**
 * @typedef {Object} MinimalBrand
 * @property {string} id
 * @property {string} brandName
 * @property {string} brandPath
 * @property {string} brandsPath
 * @property {TopBrandBannerAsset} bannerAsset
 */

/**
 * @typedef {Object} TopBrandBannerAsset
 * @property {string} applicationId
 * @property {string} tenantId
 * @property {string} type
 * @property {string} provider
 * @property {string} url
 * @property {string} contentUrl
 * @property {string} embedCode
 * @property {string} altText
 * @property {string} title
 * @property {Array<string>} tags
 */

/**
 * Render the featured brands
 *
 * @param {Array<MinimalBrand>} featuredBrands
 * @returns {JSX.Element}
 * @constructor
 */
const FeaturedBrands = ({ featuredBrands }) => {
  const formatMessage = useFormatMessage();
  return (
    <section className={'mt-6'}>
      <h2 className={'text-primary-500 text-center text-2xl'}>
        {formatMessage(messages.featuredBrands)}
      </h2>
      <section
        className={
          'grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-2 sm:gap-x-4 md:gap-x-6 lg:gap-x-8 gap-y-2 sm:gap-y-4 md:gap-y-8 lg:gap-y-12 mt-6'
        }
      >
        {Object.values(featuredBrands).map((brand, key) => (
          <FeaturedBrand brand={brand} key={key} />
        ))}
      </section>
    </section>
  );
};

/**
 * Render a single Featured Brand
 *
 * @param {MinimalBrand} brand
 * @returns {JSX.Element}
 * @constructor
 */
const FeaturedBrand = ({ brand }) => {
  const { categoryUrl, subcategoryUrl } = useBrandsCategoryUrlParams();
  return (
    <div className={'inline-block'}>
      <Link
        to={`${brand.brandsPath}${
          subcategoryUrl ? subcategoryUrl : categoryUrl
        }`}
      >
        <div className={'block p-8 border-solid border-2 border-blue-900'}>
          <img
            src={brand.bannerAsset?.contentUrl}
            alt={brand.bannerAsset?.altText}
            className={'mx-auto h-8'}
          />
        </div>
        <h3
          className={
            'text-center w-full mt-4 text-gray-700 text-xl hidden md:inline-block'
          }
        >
          {capitalizeBrand(brand.brandName)}
        </h3>
      </Link>
    </div>
  );
};

export default FeaturedBrands;
export { FeaturedBrands };
