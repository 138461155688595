/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'StoreLocation.title',
    defaultMessage: 'Store Locator'
  },
  selectedDealer: {
    id: 'StoreLocation.selected.title',
    defaultMessage: 'You are ordering parts from {name}'
  },
  noDealerSelected: {
    id: 'StoreLocation.noDealerSelected.title',
    defaultMessage: 'You have not selected a location'
  },
  makeDealerPrimary: {
    id: 'StoreLocation.makeDealerPrimary',
    defaultMessage: 'Make Preferred Location'
  },
  isDealerPrimary: {
    id: 'StoreLocation.isDealerPrimary',
    defaultMessage: 'This is your preferred location'
  },
  changeLocation: {
    id: 'StoreLocation.changeLocation',
    defaultMessage: 'Change Location'
  },
  noDealerCaveat: {
    id: 'StoreLocation.noDealerCaveat',
    defaultMessage:
      'You have not selected a location to order from. You will need to pick a location before you can place an order.'
  },
  selectDealer: {
    id: 'StoreLocation.selectDealer',
    defaultMessage: 'Select Location'
  },
  description: {
    id: 'StoreLocation.description',
    defaultMessage: 'Select a store location to order from.'
  },
  primaryDealerError: {
    id: 'StoreLocation.primaryDealer.error',
    defaultMessage:
      'We were unable to make {dealer} your preferred location. Please try again later.'
  },
  primaryDealerInactive: {
    id: 'StoreLocation.primaryDealer.inactive',
    defaultMessage: 'Location no longer active!'
  }
});
