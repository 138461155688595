/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  notFoundTitle: {
    id: 'route.status.notFound.title',
    defaultMessage: '404'
  },
  notFoundSubtitle: {
    id: 'route.status.notFound.subtitle',
    defaultMessage: 'Not Found'
  },
  notFoundBody: {
    id: 'route.status.notFound.body',
    defaultMessage: 'We are unable to find a page at the requested location.'
  },
  errorTitle: {
    id: 'route.status.error.title',
    defaultMessage: 'Error'
  },
  errorSubtitle: {
    id: 'route.status.error.subtitle',
    defaultMessage: 'Sorry! Something must have gone wrong.'
  }
});
