import { useLocation } from 'app/common/hooks';
import { useMemo } from 'react';
import { set } from 'lodash';

/**
 * Get the Category URL and Sub-Category URL from the Brands page URL parameters
 *
 * @returns {{categoryUrl: string, subcategoryUrl: string}}
 */
function useBrandsCategoryUrlParams() {
  const location = useLocation();
  const { category: categoryUrl = '', subcategory: subcategoryUrl = '' } =
    useMemo(() => {
      return location.search
        .slice(1)
        .split('&')
        .map(param => {
          let [key, value] = param.split('=');
          value = '/' + value;
          return { key, value };
        })
        .reduce((accumulator, { key, value }) => {
          set(accumulator, key, value);
          return accumulator;
        }, {});
    }, [location.search]);

  return { categoryUrl, subcategoryUrl };
}

export default useBrandsCategoryUrlParams;
