/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  communicationPreference: {
    id: 'CommunicationPreferences.legend',
    defaultMessage: 'Communication Preference'
  },
  callPreference: {
    id: 'CommunicationPreferences.call',
    defaultMessage: 'Call'
  },
  textPreference: {
    id: 'CommunicationPreferences.text',
    defaultMessage: 'Text (SMS)'
  },
  emailPreference: {
    id: 'CommunicationPreferences.email',
    defaultMessage: 'Email'
  },
  unknown: {
    id: 'CommunicationPreferences.unknown',
    defaultMessage: 'Unset'
  }
});
