/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isNil } from 'lodash';

import { AuthContext } from 'app/auth/contexts';
import { CheckoutButton, PrimaryButton } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';
import { CartContext } from 'app/common/contexts';
import { CSRContext } from 'app/csr/context';

import {
  CloneCustomerCart,
  GenerateTransferAnonymousCart,
  TransferCartToCustomer
} from './components';
import messages from './CSRRibbon.messages';

const CSRRibbon = () => {
  const { user } = React.useContext(AuthContext);
  const { csrMode, exitCsrMode } = React.useContext(CSRContext);
  const { cart } = React.useContext(CartContext);
  const formatMessage = useFormatMessage();

  if (!csrMode) {
    return null;
  }

  const title = isNil(user)
    ? formatMessage(messages.csrModeAnonymous)
    : formatMessage(messages.csrMode, {
        fullName: user.fullName || `${user.firstName} ${user.lastName}`,
        username: user.username
      });

  const quantity = get(cart, 'quantity', 0);

  return (
    <div className="CsrRibbon flex bg-gray-800 w-full px-3 py-2">
      <div className="text-white flex-grow self-center">{title}</div>
      <div className="CsrRibbon__Actions flex flex-wrap">
        {!isNil(user) && <TransferCartToCustomer className="mx-1" />}
        {!isNil(user) && <CloneCustomerCart className="mx-1" />}
        {isNil(user) && <GenerateTransferAnonymousCart className="mx-1" />}

        {quantity > 0 && (
          <CheckoutButton
            className="CsrRibbon__Actions-checkout mx-1"
            size={PrimaryButton.Size.SMALL}
          >
            {formatMessage(messages.checkout)}
          </CheckoutButton>
        )}

        <PrimaryButton
          className="CsrRibbon__Actions-exitCsr mx-1"
          onClick={exitCsrMode}
          size={PrimaryButton.Size.SMALL}
        >
          {formatMessage(messages.exitCsr)}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default CSRRibbon;
