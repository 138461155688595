/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import {
  CartPromotions,
  CartTotals,
  CheckoutButton,
  PrimaryButton
} from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from './CartSummary.messages';

/**
 * Render component for the cart's pricing summary and basic actions (e.g.,
 * checkout, convert to quote, etc.).
 *
 * @visibleName Cart Page: Summary
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const CartSummary = () => {
  const formatMessage = useFormatMessage();

  return (
    <section className="relative lg:sticky lg:top-32">
      <section className="bg-gray-100 rounded shadow lg:shadow-md">
        <header className="mb-4 p-4 bg-white rounded-t">
          <h2 className="text-gray-700 text-xl font-bold capitalize leading-none lg:text-2xl">
            {formatMessage(messages.title)}
          </h2>
        </header>
        <section>
          <CartTotals />
        </section>
      </section>
      <section className="my-4">
        <CheckoutButton
          className="w-full p-3 text-base md:shadow-md"
          size={PrimaryButton.Size.CUSTOM}
        >
          {formatMessage(messages.checkout)}
        </CheckoutButton>
      </section>
      <CartPromotions />
    </section>
  );
};

const Skeleton = () => {
  const formatMessage = useFormatMessage();

  return (
    <section className="relative lg:sticky lg:top-16">
      <section className="bg-gray-100 rounded shadow lg:shadow-md">
        <header className="mb-4 p-4 bg-white rounded-t">
          <h2 className="text-gray-700 text-xl font-bold capitalize leading-none lg:text-2xl">
            {formatMessage(messages.title)}
          </h2>
        </header>
        <section>
          <CartTotals />
        </section>
      </section>
      <section className="my-4">
        <PrimaryButton
          className="w-full p-3 text-base md:shadow-md"
          size={PrimaryButton.Size.CUSTOM}
          disabled
        >
          {formatMessage(messages.checkout)}
        </PrimaryButton>
      </section>
      <CartPromotions disabled />
    </section>
  );
};

CartSummary.Skeleton = Skeleton;

export default CartSummary;
export { CartSummary };
