/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { map } from 'lodash';

import { HTMLWidget, Link } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from './TextSuggestions.messages';

const TextSuggestions = ({ suggestions }) => {
  const formatMessage = useFormatMessage();
  return (
    <div className="py-2 lg:py-0">
      <div className="hidden mb-4 text-2xl capitalize font-medium lg:block">
        {formatMessage(messages.suggestions)}
      </div>
      <ul className="block list-none" role="presentation">
        {map(suggestions, ({ highlightedSuggestion, suggestion }) => (
          <li key={suggestion} className="w-64 mb-2">
            <Link
              to={`/search?q=${suggestion}`}
              className="text-gray-700 hover:text-gray-900"
            >
              <HTMLWidget content={highlightedSuggestion} />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TextSuggestions;
