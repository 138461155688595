/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  transferAnonymousCartTitle: {
    id: 'GenerateTransferAnonymousCart.transferAnonymousCartTitle',
    defaultMessage: 'Transfer Cart'
  },
  generationError: {
    id: 'GenerateTransferAnonymousCart.generationError',
    defaultMessage: 'An error occurred generating the transfer cart URL'
  },
  generateAnonymousCartTransferUrlHelp: {
    id: 'GenerateTransferAnonymousCart.generateAnonymousCartTransferUrlHelp',
    defaultMessage:
      'Generate a URL which the customer may use to receive a copy of your current cart.'
  },
  submitButton: {
    id: 'GenerateTransferAnonymousCart.submitButton',
    defaultMessage: 'Generate Cart Transfer URL'
  },
  transferAnonymousCart: {
    id: 'GenerateTransferAnonymousCart.transferAnonymousCart',
    defaultMessage: 'Transfer Cart'
  },
  anonymousCartTransferUrl: {
    id: 'GenerateTransferAnonymousCart.anonymousCartTransferUrl',
    defaultMessage: 'URL To Transfer Cart'
  },
  anonymousCartTransferUrlHelp: {
    id: 'GenerateTransferAnonymousCart.anonymousCartTransferUrlHelp',
    defaultMessage:
      'The customer can use this URL to transfer the cart to their control.'
  },
  copyUrl: {
    id: 'GenerateTransferAnonymousCart.copyUrl',
    defaultMessage: 'Copy'
  },
  copiedUrl: {
    id: 'GenerateTransferAnonymousCart.copiedUrl',
    defaultMessage: 'URL copied to clipboard!'
  }
});
