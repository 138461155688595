/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { Environment } from 'app/common/services';

/**
 * Names of the priceable fields that should be added to
 * `PriceableTarget#priceableFields` for `PriceInfo` requests.
 *
 * @type {{baseCost: string, basePrice: string}}
 */
const PriceInfoRequestPriceableFieldName = Object.freeze({
  /** Cost of manufacture */
  baseCost: Environment.get(
    'PRICE_INFO_REQUEST_BASE_COST_FIELD_NAME',
    'baseCost'
  ),
  /** Price to sell at: Retail price, MSRP, etc. */
  basePrice: Environment.get(
    'PRICE_INFO_REQUEST_BASE_PRICE_FIELD_NAME',
    'basePrice'
  )
});

export default PriceInfoRequestPriceableFieldName;
