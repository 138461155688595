/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  changePassword: {
    id: 'AccountInfo.changePassword',
    defaultMessage: 'Change Password'
  },
  email: {
    id: 'AccountInfo.email',
    defaultMessage: 'Email'
  },
  emailWarning: {
    id: 'AccountInfo.email.warning',
    defaultMessage:
      'Changing your email will cause you to need to sign in again.'
  },
  emailRequired: {
    id: 'AccountInfo.email.require',
    defaultMessage: 'Email is required'
  },
  emailInvalid: {
    id: 'AccountInfo.email.invalid',
    defaultMessage: 'Must be valid email'
  },
  emailPlaceholder: {
    id: 'AccountInfo.email.placeholder',
    defaultMessage: 'email@example.com'
  },
  name: {
    id: 'AccountInfo.name',
    defaultMessage: 'Name'
  },
  nameRequired: {
    id: 'AccountInfo.name.require',
    defaultMessage: 'Name is required'
  },
  namePlaceholder: {
    id: 'AccountInfo.name.placeholder',
    defaultMessage: 'First Last'
  },
  firstName: {
    id: 'AccountInfo.firstName',
    defaultMessage: 'First Name'
  },
  firstNameRequired: {
    id: 'AccountInfo.firstName.require',
    defaultMessage: 'First Name is required'
  },
  firstNamePlaceholder: {
    id: 'AccountInfo.firstName.placeholder',
    defaultMessage: 'First'
  },
  lastName: {
    id: 'AccountInfo.lastName',
    defaultMessage: 'Last Name'
  },
  lastNameRequired: {
    id: 'AccountInfo.lastName.require',
    defaultMessage: 'Last Name is required'
  },
  lastNamePlaceholder: {
    id: 'AccountInfo.lastName.placeholder',
    defaultMessage: 'Last'
  },
  save: {
    id: 'AccountInfo.save',
    defaultMessage: 'Save'
  },
  edit: {
    id: 'AccountInfo.edit',
    defaultMessage: 'Edit Info'
  },
  title: {
    id: 'AccountInfo.title',
    defaultMessage: 'Profile Details'
  },
  password: {
    id: 'AccountInfo.password',
    defaultMessage: 'Password'
  },
  passwordWarning: {
    id: 'AccountInfo.password.warning',
    defaultMessage:
      'Changing your password will cause you to need to sign in again.'
  },
  cancel: {
    id: 'AccountInfo.cancel',
    defaultMessage: 'Cancel'
  },
  preferredCommunicationMethod: {
    id: 'AccountInfo.preferredCommunicationMethod',
    defaultMessage: 'Preferred Communication Method'
  }
});
