/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import {
  filter,
  findIndex,
  flatMap,
  get,
  isEmpty,
  isNil,
  join,
  map,
  toPairs
} from 'lodash';
import TagManager from 'app/common/tagmanager';

import {
  AddToListButton,
  ItemQuantitySelect,
  LoadingIcon,
  PrimaryButton,
  TertiaryButton
} from 'app/common/components';
import { ResolutionContextType } from 'app/common/constants';
import {
  CartContext,
  CurrencyContext,
  LocaleContext,
  TenantContext
} from 'app/common/contexts';
import {
  useAddToCartApi,
  useCurrentApplication,
  useCurrentTenant,
  useFormatMessage
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';
import { ConfigurableItemContext, PdpContext } from 'app/product/contexts';
import { cartItemAttributeChoice } from 'app/product/helpers/ConfigurableItem';
import { useProductRequiresConfiguration } from 'app/product/hooks';

import PdpHeader from '../PdpHeader';
import {
  GlobalAddToCartErrors,
  IncludedItems,
  ProductOptions
} from './components';
import { useItem } from './hooks';
import messages from './MerchandisingInfo.messages';
import ItemAvailability from 'app/common/components/ItemAvailability';
import {
  ProductDescription,
  ProductSpecs
} from './components/ProductDetailsCollapse';

/**
 * Render component for a product's merchandising info like name, price,
 * variants, and actions.
 *
 * @visibleName Product Detail Page: Merchandising Info
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const MerchandisingInfo = () => {
  const formatMessage = useFormatMessage();
  const { currentLocale: locale } = React.useContext(LocaleContext);
  const { id: applicationId } = useCurrentApplication() || {};
  const { id: tenantId } = useCurrentTenant() || {};
  const { cart, setCart, toggleMinicartOpen } = React.useContext(CartContext);
  const { product, setAddToCartErrors } = React.useContext(PdpContext);
  const { resolutionContext } = React.useContext(TenantContext);
  const isDealerContext = resolutionContext === ResolutionContextType.DEALER;
  const { currentCurrency: currency } = React.useContext(CurrencyContext);
  const {
    activeOption,
    variantAttributeChoices,
    cartItemAttributeChoices,
    itemChoices
  } = React.useContext(ConfigurableItemContext);
  const { options = [] } = product;
  const [quantity, setQuantity] = React.useState(1);
  const { sendCallback } = useAddToCartApi(null, null, false, true);
  const initialValues = React.useMemo(
    () =>
      options
        .filter(option => option.type === 'CART_ITEM_ATTRIBUTE')
        .reduce(
          (acc, option) => {
            acc[option.attributeChoice.attributeName] = '';
            return acc;
          },
          { quantity }
        ),
    [options, quantity]
  );
  const requiresConfiguration = useProductRequiresConfiguration(
    product,
    activeOption,
    itemChoices,
    isDealerContext
  );
  const item = useItem(activeOption, product, quantity, requiresConfiguration);
  const { dispatch } = React.useContext(ConfigurableItemContext);

  React.useEffect(
    () =>
      options
        .filter(option => option.type === 'CART_ITEM_ATTRIBUTE')
        .forEach(option => {
          dispatch(
            cartItemAttributeChoice(option.attributeChoice.attributeName, '')
          );
        }), // eslint-disable-next-line
    []
  );

  return (
    <>
      <section className="flex flex-col sm:flex-grow sm:basis-1/2 md:basis-3/5 lg:basis-2/3">
        <PdpHeader className="hidden sm:block" />
        {product.availableOnline && (
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, actions) => {
              if (requiresConfiguration) {
                actions.setSubmitting(false);
                return;
              }

              const catalogId = get(item, 'contextState.catalog.contextId');

              try {
                const addItemRequest = {
                  catalogId,
                  currency,
                  dependentCartItems: flatMap(itemChoices, itemChoice => {
                    // Don't want to submit any items with non-positive quantity
                    const positiveQuantityChoices = filter(
                      itemChoice,
                      choice => choice.quantity > 0
                    );
                    return map(positiveQuantityChoices, specificChoice => ({
                      catalogId,
                      currency,
                      locale,
                      productId: specificChoice.isProduct
                        ? specificChoice.id
                        : specificChoice.productId,
                      quantity: specificChoice.quantity,
                      applicationId,
                      tenantId,
                      sku: specificChoice.sku,
                      variantId: specificChoice.isProduct
                        ? undefined
                        : specificChoice.id,
                      itemAttributeChoices: specificChoice.itemAttributeChoices,
                      itemChoiceKey: specificChoice.choiceKey
                    }));
                  }),
                  itemAttributeChoices: {
                    ...(variantAttributeChoices || {}),
                    ...(cartItemAttributeChoices || {})
                  },
                  locale,
                  productId: item.productId,
                  quantity: item.quantity,
                  applicationId,
                  tenantId,
                  sku: item.sku,
                  variantId: item.variantId
                };

                const response = await sendCallback({
                  data: isNil(cart)
                    ? { addItemRequest, priceCartRequest: { locale, currency } }
                    : addItemRequest,
                  params: { price: true }
                });

                if (!isEmpty(response)) {
                  const cartItemPos = findIndex(response.cartItems, [
                    'sku',
                    item.sku
                  ]);
                  const cartItem = get(
                    response,
                    `cartItems[${cartItemPos}]`,
                    {}
                  );

                  TagManager.dataLayer({
                    event: 'addToCart',
                    ecommerce: {
                      currencyCode: currency,
                      add: {
                        products: [
                          {
                            brand: get(item, 'attributes.BRAND.value'),
                            category: join(
                              get(cartItem, 'attributes.categoryNames', []),
                              ', '
                            ),
                            id: cartItem.sku,
                            name: item.description,
                            position: cartItemPos,
                            price: get(cartItem, 'unitPrice.amount', 0.0),
                            quantity: item.quantity
                          }
                        ]
                      }
                    }
                  });

                  setCart(response);
                  toggleMinicartOpen(true);
                }

                setAddToCartErrors(undefined);

                return response;
              } catch (exception) {
                if (isEmpty(exception)) {
                  return [];
                }

                logError({
                  ...exception,
                  when: `adding ${
                    !isEmpty(activeOption) ? activeOption.id : product.id
                  } to cart`
                });

                if (exception.type === 'SIMILAR_ITEM_ALREADY_ADDED_ERROR') {
                  setAddToCartErrors({
                    globalConfigErrors: [
                      {
                        errorCode: exception.statusCode,
                        errorMessage: formatMessage(messages.duplicateItemError)
                      }
                    ]
                  });
                  setTimeout(() => {
                    setAddToCartErrors(undefined);
                  }, 5000);
                  return [];
                }

                if (exception.type !== 'ADD_TO_CART') {
                  setAddToCartErrors({
                    globalConfigErrors: [
                      {
                        errorCode: exception.statusCode,
                        errorMessage: formatMessage(messages.addToCartError)
                      }
                    ]
                  });
                  setTimeout(() => {
                    setAddToCartErrors(undefined);
                  }, 5000);
                  return [];
                }

                // handle add to cart error
                const {
                  attributeConfigErrors,
                  dependentItemConfigErrors,
                  globalConfigErrors
                } = exception;
                setAddToCartErrors({
                  attributeConfigErrors,
                  dependentItemConfigErrors,
                  globalConfigErrors
                });

                if (!isEmpty(exception.attributeConfigErrors)) {
                  actions.setErrors(
                    toPairs(exception.attributeConfigErrors).reduce(
                      (acc, [attributeName, errors]) => {
                        acc[attributeName] = errors[0].errorMessage;
                        return acc;
                      },
                      {}
                    )
                  );
                }

                setTimeout(() => {
                  setAddToCartErrors(undefined);
                }, 5000);

                return [];
              } finally {
                actions.setSubmitting(false);
              }
            }}
            validateOnBlur={true}
            validateOnChange={false}
            enableReinitialize={true}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col w-full">
                <GlobalAddToCartErrors />
                <ProductOptions />
                <IncludedItems />
                <div className="flex justify-end mt-4">
                  <div className="inline-flex flex-col h-full">
                    <div className="mt-2 sm:mt-3 mb-3 sm:mb-4">
                      <ItemAvailability
                        product={product}
                        textSize={'text-base'}
                        nearbyButtonSize={TertiaryButton.Size.BASE}
                      />
                    </div>
                    <div className="flex">
                      <ItemQuantitySelect
                        className="w-32 mr-2"
                        quantity={quantity}
                        setQuantity={setQuantity}
                      />
                      <PrimaryButton
                        className="relative"
                        type="submit"
                        disabled={isSubmitting || requiresConfiguration}
                      >
                        <div
                          className={classNames({
                            'text-transparent': isSubmitting
                          })}
                        >
                          {formatMessage(messages.addToCartLabel)}
                        </div>
                        {isSubmitting && (
                          <div className="absolute inset-0 flex justify-center items-center">
                            <LoadingIcon className="text-blue-500" />
                          </div>
                        )}
                      </PrimaryButton>
                    </div>
                    <div className="mt-2 sm:mt-3">
                      <AddToListButton item={item} />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
        <ProductDescription className="mt-8 mb-4 hidden lg:block" open={true} />
        <ProductSpecs className="mb-4 hidden lg:block" />
      </section>
      <ProductDescription className="mt-8 mb-4 lg:hidden" open={true} />
      <ProductSpecs className="mb-4 lg:hidden" />
    </>
  );
};

export default MerchandisingInfo;
export { MerchandisingInfo };
