/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import { Link } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from '../OrderSummary.messages';
import { useLocation } from 'react-router-dom';

const OrderSummaryActions = props => {
  const { order } = props;
  const formatMessage = useFormatMessage();
  const search = useLocation().search;
  let to = `/my-account/orders/${order.id}`;
  if (search) {
    to += search;
  }

  return (
    <div className="text-center md:text-right">
      <Link
        to={to}
        className="text-sm text-primary-600 hover:text-primary-700 focus:text-primary-700"
      >
        {formatMessage(messages.details)}
      </Link>
    </div>
  );
};

export default OrderSummaryActions;
export { OrderSummaryActions };
