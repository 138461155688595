/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { find, findIndex, get, isEmpty, join, map } from 'lodash';

import { LoadingIcon, PrimaryButton } from 'app/common/components';
import { CartContext } from 'app/common/contexts';
import {
  useFormatMessage,
  useInventoryInfo,
  useRestApi
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import messages from '../../NearestAvailableButton.messages';

const SelectLocationButton = ({
  disabled = false,
  inventoryLocation,
  handleSwitch,
  handleWarning
}) => {
  const { cart } = React.useContext(CartContext);
  const formatMessage = useFormatMessage();
  const cartItems = get(cart, 'cartItems');
  const config = React.useMemo(
    () => ({
      params: {
        skuCode: join(map(cartItems, item => item.sku)),
        skuQuantity: join(map(cartItems, item => item.quantity))
      }
    }),
    [cartItems]
  );
  const { baseUrl, forLocationContextPath } = useInventoryInfo().availability;

  const url = `${baseUrl}${forLocationContextPath}` + inventoryLocation.id;
  const { sendCallback: checkAvailability } = useRestApi(
    url,
    config,
    false,
    true
  );
  const [loading, setLoading] = React.useState(false);

  return (
    <PrimaryButton
      className="relative"
      disabled={disabled}
      onClick={async () => {
        if (isEmpty(cartItems)) {
          handleSwitch();
          return;
        }

        try {
          const summaries = await checkAvailability();
          const unavailables = summaries
            .filter(summary => !summary.available)
            .map(unavailable =>
              find(cartItems, {
                sku: unavailable.skuRef.skuCode
              })
            )
            .concat(
              cartItems.filter(
                cartItem =>
                  !~findIndex(
                    summaries,
                    summary => cartItem.sku === summary.skuRef.skuCode
                  )
              )
            );

          if (isEmpty(unavailables)) {
            setLoading(true);
            handleSwitch();
            return;
          }

          handleWarning(
            unavailables.map(cartItem => ({
              attributes: { sku: cartItem.sku },
              id: cartItem.id,
              productId: cartItem.productId,
              quantity: cartItem.quantity,
              variantId: cartItem.variantId
            }))
          );
        } catch (err) {
          logError({
            ...err,
            when: `Checking cart item part availability before switching locations to ${inventoryLocation.locationNumber}`
          });
          // go ahead and just do the switch if can't check availability
          setLoading(true);
          handleSwitch();
        }
      }}
    >
      {loading && (
        <div className="absolute inset-0 flex justify-center items-center">
          <LoadingIcon className="text-blue-500" />
        </div>
      )}
      <span className={classNames({ 'text-transparent': loading })}>
        {formatMessage(messages.modalSelectLocation)}
      </span>
    </PrimaryButton>
  );
};

export default SelectLocationButton;
export { SelectLocationButton };
