/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import { AuthService } from 'app/auth/services';
import { Button, Dropdown, Icon } from 'app/common/components';
import { ResolutionContextType } from 'app/common/constants';
import { TenantContext } from 'app/common/contexts';
import { useFormatMessage } from 'app/common/hooks';

import messages from './AccountDropdown.messages';

/**
 * Render component for the dropdown containing the Account settings.
 *
 * @visibleName Nav Account Dropdown
 */
const AccountDropdown = () => {
  const formatMessage = useFormatMessage();
  const { resolutionContext } = React.useContext(TenantContext);

  return (
    <Dropdown className="h-full w-full lg:w-auto" hoverable>
      <Dropdown.Menu.Trigger
        className="h-full w-full lg:w-auto"
        triggerClassName="justify-between h-full w-full px-4 py-2 hover:bg-gray-100 focus:outline-none focus:shadow-outline lg:w-auto lg:py-0 lg:px-2"
      >
        {formatMessage(messages.label)}
      </Dropdown.Menu.Trigger>
      <Dropdown.Menu className="w-full text-primary-900 lg:w-56 lg:mt-1">
        <Dropdown.Menu.Item padding="">
          <Button className="block px-4 py-2" to="/my-account">
            {formatMessage(messages.dashboard)}
          </Button>
        </Dropdown.Menu.Item>
        <Dropdown.Menu.Item padding="">
          <Button className="block px-4 py-2" to="/my-account/orders">
            {formatMessage(messages.orders)}
          </Button>
        </Dropdown.Menu.Item>
        <Dropdown.Menu.Item padding="">
          <Button className="block px-4 py-2" to="/my-account/addresses">
            {formatMessage(messages.addresses)}
          </Button>
        </Dropdown.Menu.Item>
        <Dropdown.Menu.Item padding="">
          <Button className="block px-4 py-2" to="/my-account/lists">
            {formatMessage(messages.lists)}
          </Button>
        </Dropdown.Menu.Item>
        {resolutionContext !== ResolutionContextType.DEALER && (
          <Dropdown.Menu.Item padding="">
            <Button
              className="block px-4 py-2"
              to="/my-account/preferred-location"
            >
              {formatMessage(messages.preferredLocation)}
            </Button>
          </Dropdown.Menu.Item>
        )}
        <Dropdown.Menu.Divider />
        <Dropdown.Menu.Item
          onClick={() => {
            AuthService.changePassword();
          }}
        >
          {formatMessage(messages.changePassword)}
          <Icon className="pl-2" name="external-link-alt" size="sm" />
        </Dropdown.Menu.Item>
        <Dropdown.Menu.Item
          onClick={() => {
            AuthService.logout();
          }}
        >
          {formatMessage(messages.logout)}
        </Dropdown.Menu.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AccountDropdown;
export { AccountDropdown };
