/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty } from 'lodash';

import { useFormatMessage } from 'app/common/hooks';

import { PaymentCard } from '../';
import messages from './UserPayments.messages';

const UserPayments = props => {
  const {
    defaultPaymentAccount,
    payments,
    refetchPayments,
    editPayment,
    selectedId
  } = props;
  const formatMessage = useFormatMessage();
  return (
    <>
      {map(payments, (paymentAccount, index) => (
        <PaymentCard
          key={index}
          paymentAccount={paymentAccount}
          refetchPayments={refetchPayments}
          editPayment={editPayment}
          selected={paymentAccount.id === selectedId}
          isDefault={paymentAccount.id === defaultPaymentAccount}
        />
      ))}
      {isEmpty(payments) && (
        <div className="w-56 mt-16 mx-20 text-2xl text-bold text-gray-400">
          {formatMessage(messages.paymentsEmptyPrompt)}
        </div>
      )}
    </>
  );
};

UserPayments.propTypes = {
  /** Array of all saved payments**/
  payments: PropTypes.array,
  /** Function to refetch saved payments **/
  refetchPayments: PropTypes.func.isRequired,
  /** Function to trigger editing of a payment **/
  editPayment: PropTypes.func.isRequired,
  /** The id of the currently selected payment **/
  selectedId: PropTypes.string
};

export default UserPayments;
