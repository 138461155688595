/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import MenuLink from '../MenuLink';
import Submenu from '../Submenu';

const MenuItem = menuItem => {
  const { label, submenu, type } = menuItem;

  if (isEmpty(submenu)) {
    if (type === 'TEXT') {
      return (
        <li className="mb-1 last:mb-0 md:mb-0 md:mr-4 md:last:mr-0">{label}</li>
      );
    }

    return <MenuLink {...menuItem} />;
  }

  return <Submenu {...menuItem} />;
};

MenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  submenu: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string.isRequired
};

export default MenuItem;
export { MenuItem };
