const smallWords = ['AND', 'OF'];

/**
 * Capitalize Brand Names. In general, this capitalizes the first letter of each word, and lowercases the rest.
 * Also includes logic for special cases:
 * Words with 2 characters or fewer use all capitals,
 * words from the "smallWords" list are not capitalized at all,
 * the first alphabetic character after a non-alphabetic character is capitalized,
 * and brand names that are 1 word with 3 or fewer letters are capitalized.
 *
 * @param {string} brandName The brand name string. No particular case is assumed.
 * @returns {string} The sentence-case capitalized brand name.
 */
function capitalizeBrand(brandName) {
  const words = brandName.split(' ');
  if (words.length === 1 && words[0].length < 4) {
    return brandName.toUpperCase();
  }

  let result = '';
  let first = true;
  for (let word of words) {
    if (first) {
      first = false;
    } else {
      result += ' ';
    }

    let modWord = '';

    if (smallWords.includes(word)) {
      modWord += word.toLowerCase();
    } else if (word.length < 3) {
      modWord = word.toUpperCase();
    } else {
      let capNext = true;
      for (let char of word) {
        if (!isAlphaChar(char)) {
          modWord += char;
          capNext = true;
          continue;
        }

        if (capNext) {
          modWord += char.toUpperCase();
          capNext = false;
        } else {
          modWord += char.toLowerCase();
        }
      }
    }

    result += modWord;
  }
  return result;
}

function isAlphaChar(ch) {
  return ch >= 'A' && ch <= 'z';
}

export { capitalizeBrand };
