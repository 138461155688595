/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { findIndex, get, noop } from 'lodash';

import { AuthContext } from 'app/auth/contexts';
import {
  CheckboxField,
  Dropdown,
  Icon,
  Button,
  SecondaryButton,
  TertiaryButton
} from 'app/common/components';
import {
  useCartInfo,
  useFormatDate,
  useFormatMessage,
  useFormatNumber,
  useRestApi
} from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import { ListsContext } from '../../contexts';
import messages from '../../Lists.messages';

const List = ({ list }) => {
  const formatMessage = useFormatMessage();
  const formatNumber = useFormatNumber();
  const formatDate = useFormatDate();
  const { selectedLists, setSelectedLists } = React.useContext(ListsContext);
  const selected = !!~findIndex(selectedLists, ['id', list.id]);
  const handleChange = () => {
    if (selected) {
      setSelectedLists(selectedLists.filter(({ id }) => id !== list.id));
    } else {
      setSelectedLists([...selectedLists, list]);
    }
  };
  const updatedDate = get(list, 'updatedDate');
  return (
    <li className="flex items-stretch justify-start flex-wrap my-4 p-4 text-gray-700 bg-white rounded shadow first:my-0 sm:flex-no-wrap sm:justify-between">
      <CheckboxField.Field
        className="mr-2 text-sm basis-1/12 sm:flex-shrink"
        checked={selected}
        field={{
          onBlur: noop,
          onChange: handleChange,
          name: 'selected-list',
          value: list.id
        }}
        value={list.id}
      />
      <h3
        className="flex items-center flex-grow mr-2 text-sm leading-none font-medium basis-4/12"
        title={formatMessage(messages.name)}
      >
        {list.name}
      </h3>
      <div
        className="flex items-center flex-grow mr-2 text-sm leading-none basis-4/12"
        title={formatMessage(messages.updatedDate)}
      >
        {!!updatedDate &&
          formatDate(updatedDate, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
          })}
      </div>
      <div className="hidden items-center justify-end basis-full mt-4 sm:flex sm:basis-3/12 sm:mt-0">
        <div className="mr-4">
          <SecondaryButton
            className="relative"
            size={SecondaryButton.Size.SMALL}
            to={`/my-account/lists/${list.id}`}
          >
            <span>{formatMessage(messages.edit)}</span>
            <span className="hidden ml-1 sm:block">
              ({formatNumber(get(list, 'attributes[itemCount]', 0))})
            </span>
          </SecondaryButton>
        </div>
        <div className="flex items-center justify-center sm:w-auto">
          <DeleteButton list={list} />
        </div>
      </div>
      <div className="flex justify-end flex-grow sm:hidden">
        <Dropdown className="flex items-center">
          <Dropdown.Menu.Trigger
            showIcon={false}
            title={formatMessage(messages.listActions)}
          >
            <Icon className="px-1" name="ellipsis-v" />
          </Dropdown.Menu.Trigger>
          <Dropdown.Menu openTo={Dropdown.Menu.OpenTo.RIGHT}>
            <Dropdown.Menu.Item>
              <Button
                className="flex items-center font-bold text-primary-500 text-sm appearance-none hover:text-primary-700 focus:text-primary-700 focus:outline-none disabled:text-gray-400"
                to={`/my-account/lists/${list.id}`}
              >
                <span>{formatMessage(messages.edit)}</span>
                <span className="ml-1">
                  ({formatNumber(get(list, 'attributes[itemCount]', 0))})
                </span>
              </Button>
            </Dropdown.Menu.Item>
            <Dropdown.Menu.Item>
              <DeleteButton list={list} mobileOnly />
            </Dropdown.Menu.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </li>
  );
};

const DeleteButton = ({ list, mobileOnly = false }) => {
  const formatMessage = useFormatMessage();
  const { user } = React.useContext(AuthContext);
  const customerRef = React.useMemo(
    () => ({
      customerId: get(user, 'serviceId'),
      username: get(user, 'username'),
      isRegistered: true,
      accountId: get(user, 'attributes[account_id]')
    }),
    [user]
  );
  const deleteConfig = React.useMemo(
    () => ({
      method: 'del',
      params: customerRef
    }),
    [customerRef]
  );
  const {
    listOperations: { baseUrl }
  } = useCartInfo();
  const {
    error,
    exception,
    sendCallback: deleteList
  } = useRestApi(`${baseUrl}/${list.id}`, deleteConfig, false);
  const { lists, setLists } = React.useContext(ListsContext);

  React.useEffect(() => {
    if (error) {
      logError({
        ...exception,
        when: `deleting saved list ${list.id}`
      });
    }
  }, [error, exception, list]);

  if (mobileOnly) {
    return (
      <Button
        className="font-bold text-red-700 text-sm hover:text-red-800 focus:text-red-800 active:text-red-600 disabled:text-gray-400"
        onClick={async () => {
          await deleteList();
          setLists(lists.filter(({ id }) => id !== list.id));
        }}
      >
        {formatMessage(messages.delete)}
      </Button>
    );
  }

  return (
    <TertiaryButton
      className="leading-none"
      colorClassName="text-red-700 hover:text-red-800 focus:text-red-800 active:text-red-600 disabled:text-gray-400"
      size={TertiaryButton.Size.SMALL}
      onClick={async () => {
        await deleteList();
        setLists(lists.filter(({ id }) => id !== list.id));
      }}
    >
      {formatMessage(messages.delete)}
    </TertiaryButton>
  );
};

export default List;
export { List };
