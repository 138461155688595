/*
 * Component to push events to Google Tag Manager.
 */

const dataLayer = function (event) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
  return event;
};

const TagManager = {
  dataLayer
};

export default TagManager;
