import { useCallback, useRef } from 'react';
import { AUTO_COMPLETE_CLOSE } from '../reducer/preferredStoreReducer';
import { useOnEventOutside } from 'app/common/hooks';

const usePreferredStoreModalRefs = (state, dispatch) => {
  const suggestionContainerRef = useRef(null);

  const suggestionClickOutside = useSuggestionClickOutside(
    suggestionContainerRef,
    dispatch
  );

  const clickOutsideSuggestionsContainerRef = useOnEventOutside(
    suggestionClickOutside
  );

  return { suggestionContainerRef, clickOutsideSuggestionsContainerRef };
};

const useSuggestionClickOutside = function (suggestionContainerRef, dispatch) {
  return useCallback(
    e => {
      if (
        !!suggestionContainerRef.current &&
        !suggestionContainerRef.current.contains(e.target)
      ) {
        dispatch(AUTO_COMPLETE_CLOSE);
      }
    },
    [suggestionContainerRef, dispatch]
  );
};

export { usePreferredStoreModalRefs };
