/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { DecoratedField, SelectField } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import { useCardExpirationYearValues } from './hooks';
import messages from './ExpirationYear.messages';

/**
 * Render component for displaying the payment method's expiration date's year.
 *
 * @visibleName Checkout: Payment Info Form: Expiration Date: Year
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const ExpirationYear = ({ disabled = false }) => {
  const formatMessage = useFormatMessage();
  const expirationYears = useCardExpirationYearValues();

  return (
    <DecoratedField
      containerWidths="w-auto"
      component={SelectField}
      valueOptions={expirationYears}
      id="creditCardExpDateYear"
      label={formatMessage(messages.expirationYearLabel)}
      labelProps={{
        textSizes: 'text-xs'
      }}
      name="creditCardExpDateYear"
      widths="w-20"
      disabled={disabled}
      required
      showErrors={false}
      validate={value => {
        if (isEmpty(value)) {
          return formatMessage(messages.expirationYearRequired);
        }
      }}
      autoComplete="cc-exp-year"
    />
  );
};

ExpirationYear.propTypes = {
  disabled: PropTypes.bool
};

export default ExpirationYear;
export { ExpirationYear };
