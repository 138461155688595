/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button } from 'app/common/components/buttons';

const DefaultAttributeChoice = ({
  allowedValue,
  activeChoice = {},
  handleSelect
}) => {
  const { label, value, displayOrder, disabled = false } = allowedValue;
  const activeValue = activeChoice.value;

  return (
    <li
      className="flex basis-1/5 p-1"
      style={{ order: displayOrder, opacity: disabled ? '0.5' : undefined }}
    >
      <Button
        className={classNames(
          'flex items-center justify-center',
          'w-full px-1 py-3',
          'capitalize font-bold leading-none items-center',
          'appearance-none border border-solid rounded',
          'focus:outline-none',
          {
            'text-white bg-gray-800 border-gray-800': activeValue === value,
            'bg-white': activeValue !== value,
            'border-gray-500 hover:border-gray-700 focus:border-gray-700 active:border-gray-600':
              activeValue !== value,
            'text-gray-700 hover:text-gray-900 focus:text-gray-900 active:text-gray-800':
              activeValue !== value
          }
        )}
        onClick={() => {
          handleSelect(label, value);
        }}
      >
        {label}
      </Button>
    </li>
  );
};

DefaultAttributeChoice.propTypes = {
  allowedValue: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    displayOrder: PropTypes.number,
    disabled: PropTypes.bool
  }).isRequired,
  /** The currently selected value */
  activeChoice: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any
  }),
  /** Method to call when this value is selected. Takes the label and value */
  handleSelect: PropTypes.func.isRequired
};

export default DefaultAttributeChoice;
export { DefaultAttributeChoice };
