/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { getForegroundColor, isValidColor } from 'app/common/utils/ColorUtils';

/**
 * Returns the background and foreground color for the sandbox.
 *
 * @param {Sandbox} sandbox the sandbox
 */
export default function useSandboxColors(sandbox) {
  return React.useMemo(() => {
    const backgroundPrimary = isValidColor(sandbox.color)
      ? sandbox.color
      : '#1a202c';

    const isTextDark = getForegroundColor(backgroundPrimary) === 'dark';
    const textPrimary = isTextDark ? '#000000' : '#ffffff';
    return {
      background: {
        primary: backgroundPrimary
      },
      text: {
        primary: textPrimary
      }
    };
  }, [sandbox.color]);
}
