/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { get } from 'lodash';

import {
  LOCALE_CHANGED,
  RESOLVE,
  RESOLVED,
  RESOLVING
} from './tenantStateActionTypes';

const initialState = {
  identifier: undefined,
  isLegacy: false,
  shouldResolve: false,
  usingDefaultLocale: false,
  resolving: false
};

function reducer(state = initialState, action) {
  const type = get(action, 'type');
  switch (type) {
    case LOCALE_CHANGED:
      return {
        ...state,
        identifier: action.identifier,
        isLegacy: false,
        shouldResolve: !state.usingDefaultLocale,
        usingDefaultLocale: false
      };
    case RESOLVE:
      return {
        ...state,
        identifier: action.identifier,
        isLegacy: action.isLegacy,
        shouldResolve: true
      };
    case RESOLVING:
      return {
        ...state,
        shouldResolve: false,
        resolving: true
      };
    case RESOLVED:
      return {
        ...state,
        shouldResolve: false,
        resolving: false
      };
    default:
      return state;
  }
}

export { reducer, initialState };
