/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { isEmpty, isString, omit, pick } from 'lodash';
import PropTypes from 'prop-types';

import { useFormatMessage } from 'app/common/hooks';

import { Field, FieldErrors, FieldTip, Label } from '../index';

/**
 * Render component to wrap a field with label, errors, and other decorations.
 * Extra props are passed to the underlying `<Field>`.
 */
const DecoratedField = ({
  className,
  containerWidths = 'w-full',
  containerSpacing = 'mb-4 last:mb-0',
  hint,
  renderHint,
  label,
  labelProps = {},
  name,
  showErrors = true,
  tip,
  fieldClassName = '',
  ...props
}) => {
  const formatMessage = useFormatMessage();
  return (
    <div
      className={classNames('flex flex-col items-start justify-start', {
        [className]: !!className,
        [containerSpacing]: !!containerSpacing,
        [containerWidths]: !!containerWidths
      })}
    >
      {!isEmpty(label) && (
        <Label
          {...labelProps}
          field={{
            label,
            name,
            ...pick(props, ['disabled', 'id', 'required'])
          }}
        />
      )}
      <div className="flex flex-col items-start justify-start w-full lg:flex-row lg:flex-wrap lg:items-baseline">
        {!!tip ? (
          <div className="flex">
            <Field
              name={name}
              className={fieldClassName}
              {...omit(props, ['required'])}
            />
            <FieldTip>{tip}</FieldTip>
          </div>
        ) : (
          <Field
            name={name}
            className={fieldClassName}
            {...omit(props, ['required'])}
          />
        )}
        {showErrors && <FieldErrors name={name} />}
      </div>
      {!isEmpty(hint) ? (
        <em className="block mt-1 text-xs text-gray-600 leading-tight not-italic sm:text-sm">
          {isString(hint) ? hint : formatMessage(hint)}
        </em>
      ) : (
        !!renderHint && renderHint()
      )}
    </div>
  );
};

DecoratedField.propTypes = {
  /** Class names affecting field's container's width. */
  containerWidths: PropTypes.string,
  /** Class names affecting field's container's margins and padding. */
  containerSpacing: PropTypes.string,
  /** Whether the field should be disabled */
  disabled: PropTypes.bool,
  /** Field's hint */
  hint: PropTypes.string,
  /** ID attribute of the field's underlying element */
  id: PropTypes.string,
  /** The field's display label */
  label: PropTypes.string,
  /** The field's name */
  name: PropTypes.string.isRequired,
  /** Whether the field is required */
  required: PropTypes.bool,
  /** Classnames passed to the field */
  fieldClassName: PropTypes.string
};

export default DecoratedField;
export { DecoratedField };
