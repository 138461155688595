/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useMemo, useRef, useState } from 'react';

import { CartContext } from 'app/common/contexts';
import {
  Button,
  Icon,
  Label,
  Modal,
  PrimaryButton,
  SimpleModal,
  TextArea
} from 'app/common/components';
import {
  useCartInfo,
  useEventCallback,
  useFormatMessage,
  useRestApi
} from 'app/common/hooks';
import { Environment } from 'app/common/services';

import messages from './GenerateTransferAnonymousCart.messages';
import { isEmpty } from 'lodash';
import { Form, Formik } from 'formik';

const GenerateTransferAnonymousCart = props => {
  const formatMessage = useFormatMessage();
  const { cart } = React.useContext(CartContext);
  const [isOpen, setIsOpen] = useState(false);
  const [transferAnonymousCartToken, setTransferAnonymousCartToken] =
    useState(undefined);

  if (!cart) {
    return null;
  }

  return (
    <div className={props.className}>
      <PrimaryButton
        size={PrimaryButton.Size.SMALL}
        onClick={() => setIsOpen(true)}
      >
        {formatMessage(messages.transferAnonymousCart)}
      </PrimaryButton>
      <SimpleModal
        title={formatMessage(messages.transferAnonymousCartTitle)}
        body={
          <React.Fragment>
            {!transferAnonymousCartToken && (
              <GenerateTransferAnonymousCartForm
                cart={cart}
                setTransferAnonymousCartToken={setTransferAnonymousCartToken}
                {...props}
              />
            )}
            {!!transferAnonymousCartToken && (
              <TransferAnonymousCartInfo
                transferAnonymousCartToken={transferAnonymousCartToken}
                {...props}
              />
            )}
          </React.Fragment>
        }
        isOpen={isOpen}
        size={Modal.Size.SMALL}
        onClose={() => {
          setIsOpen(false);
          setTransferAnonymousCartToken(undefined);
        }}
      />
    </div>
  );
};

const GenerateTransferAnonymousCartForm = ({
  cart,
  setTransferAnonymousCartToken
}) => {
  const formatMessage = useFormatMessage();
  const { baseUrl, transferAnonymousCartUrl } = useCartInfo().operations;
  const url = `${baseUrl}${transferAnonymousCartUrl}/${cart.id}`;
  const config = useMemo(() => ({ scope: 'CSR' }), []);
  const { error, sendCallback } = useRestApi(url, config, false);

  return (
    <Formik
      onSubmit={async (values, actions) => {
        await GenerateTransferAnonymousCartForm.handleSubmit(
          { values, actions },
          {
            sendCallback,
            setTransferAnonymousCartToken
          }
        );
      }}
      initialValues={{}}
    >
      {formik => (
        <Form className="flex flex-col p-4">
          <div className="flex">
            {formatMessage(messages.generateAnonymousCartTransferUrlHelp)}
          </div>
          {error && (
            <div className="flex items-center mb-4 px-2 py-1 text-sm text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
              <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
              <span>{formatMessage(messages.generationError)}</span>
            </div>
          )}
          <PrimaryButton
            className="self-center mt-4"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {formatMessage(messages.submitButton)}
          </PrimaryButton>
        </Form>
      )}
    </Formik>
  );
};

GenerateTransferAnonymousCartForm.handleSubmit = async (
  { values, actions },
  { sendCallback, setTransferAnonymousCartToken }
) => {
  actions.setSubmitting(true);
  try {
    const data = await sendCallback();
    if (!isEmpty(data)) {
      setTransferAnonymousCartToken(data);
    }
  } finally {
    actions.setSubmitting(false);
  }
};

const TransferAnonymousCartInfo = ({ transferAnonymousCartToken }) => {
  const formatMessage = useFormatMessage();
  const [showCopied, setShowCopied] = useState(false);
  const textAreaRef = useRef(null);
  const transferAnonymousCartUrl = getTransferAnonymousCartUrl(
    transferAnonymousCartToken
  );

  const copyToClipboard = useEventCallback(() => {
    selectTextArea(textAreaRef);
    document.execCommand('copy');
    setShowCopied(true);
  }, []);

  return (
    <div className="mt-2">
      <Button
        className="CopyTransferAnonymousCartUrl float-right"
        onClick={copyToClipboard}
        title={formatMessage(messages.copyUrl)}
      >
        <Icon className="w-6 h-6" name={['far', 'copy']} />
      </Button>

      <div>
        <Label
          field={{
            label: formatMessage(messages.anonymousCartTransferUrl),
            name: 'transferAnonymousCartUrl'
          }}
        />
        <TextArea
          name="transferAnonymousCartUrl"
          readOnly={true}
          className="h-auto"
          widths="w-full"
          ref={textAreaRef}
          value={transferAnonymousCartUrl}
        />
      </div>
      {showCopied && (
        <div className="text-sm text-gray-700">
          {formatMessage(messages.copiedUrl)}
        </div>
      )}
      <div className="flex">
        {formatMessage(messages.anonymousCartTransferUrlHelp)}
      </div>
    </div>
  );
};

function selectTextArea(ref) {
  if (document.selection) {
    // IE
    const range = document.body.createTextRange();
    range.moveToElementText(ref.current);
    range.select();
  } else if (window.getSelection) {
    const range = document.createRange();
    range.selectNode(ref.current);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }
}

function getTransferAnonymousCartUrl(transferAnonymousCartToken) {
  if (transferAnonymousCartToken.token == null) {
    return null;
  }
  const path = Environment.get(
    'CSR_TRANSFER_ANONYMOUS_CART_PAGE_URL',
    '/csr/transfer-cart'
  );
  return `${window.location.origin}${path}?transferCartToken=${transferAnonymousCartToken.token}`;
}

export default GenerateTransferAnonymousCart;
export {
  GenerateTransferAnonymousCart,
  GenerateTransferAnonymousCartForm,
  TransferAnonymousCartInfo
};
