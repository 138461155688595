/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'app/common/components';
import { useCatalogInfo } from 'app/common/hooks';

const MenuLink = ({ label, type, url }) => {
  const { categoryInfo, productInfo } = useCatalogInfo();
  const { routeBaseContext: categoryPrefix } = categoryInfo;
  const { routeBaseContext: productPrefix } = productInfo;

  let to = url;

  if (type === 'CATEGORY') {
    to = categoryPrefix + url;
  } else if (type === 'PRODUCT') {
    to = productPrefix + url;
  }

  return (
    <li className="mb-1 last:mb-0 md:mb-0 md:mr-4 md:last:mr-0">
      <Link
        className="block text-gray-700 text-center hover:text-primary-400 focus:text-primary-600 active:text-primary-300 focus:outline-none focus:shadow-outline lg:text-left"
        to={to}
      >
        {label}
      </Link>
    </li>
  );
};

MenuLink.propTypes = {
  /** Label of the item */
  label: PropTypes.string.isRequired,
  /** Type of the menu item */
  type: PropTypes.string.isRequired,
  /** Destination of link */
  url: PropTypes.string.isRequired
};

export default MenuLink;
export { MenuLink };
