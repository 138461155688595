import { defineMessages } from 'react-intl';

export default defineMessages({
  filterByLabel: {
    id: 'Brands.filterByLabel',
    defaultMessage: 'Filter By:'
  },
  featuredBrands: {
    id: 'Brands.featuredBrands',
    defaultMessage: 'Featured Brands'
  },
  filterCategoryPlaceholder: {
    id: 'Brands.filterCategoryPlaceholder',
    defaultMessage: 'Select a Category'
  },
  filterSubCatPlaceholder: {
    id: 'Brands.filterSubCatPlaceholder',
    defaultMessage: 'Select a Sub-Category'
  },
  backToTop: {
    id: 'Brands.backToTop',
    defaultMessage: 'Back to Top'
  }
});
