/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get } from 'lodash';

import { Button, Icon } from 'app/common/components';
import { CartContext } from 'app/common/contexts';
import { useFormatMessage } from 'app/common/hooks';

import messages from './MinicartIcon.messages';

/**
 * Render component for the mini shopping cart icon.
 *
 * @visibleName Minicart: Icon
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const MinicartIcon = () => {
  const formatMessage = useFormatMessage();
  const { cart = {} } = React.useContext(CartContext);
  const quantity = get(cart, 'quantity', 0);

  return (
    <Button
      to="/cart"
      className="flex self-end items-center justify-end flex-1 px-4 lg:px-2 xl:px-4 text-gray-700 hover:text-gray-800 hover:bg-gray-100"
      title={formatMessage(messages.iconTitle)}
      aria-haspopup="true"
      aria-controls="minicart-dialog"
    >
      <Icon name="shopping-cart" containerProps={{ 'aria-hidden': true }} />
      {quantity > 0 && (
        <span className="flex items-center justify-around w-5 h-5 ml-1 bg-gray-700 font-medium text-white text-xs rounded-full">
          {quantity}
        </span>
      )}
    </Button>
  );
};

export default MinicartIcon;
export { MinicartIcon };
