import { useProductPricingApi } from 'app/common/hooks';
import { get } from 'lodash';
import { determineProductOrVariantPricing } from 'app/product/utils/PricingUtils';

function useDetermineActiveOptionPricing(product, activeOption) {
  let productPricing = {};
  let activeOptionPricing = {};
  const priceInfoResponse = useProductPricingApi([product]);

  if (priceInfoResponse && get(priceInfoResponse, product.id)) {
    const { priceInfo } = get(priceInfoResponse, product.id);
    productPricing = determineProductOrVariantPricing(product, priceInfo);
    activeOptionPricing = determineProductOrVariantPricing(
      activeOption,
      priceInfo
    );
  }

  return (activeOptionPricing ? activeOptionPricing : productPricing) || {};
}

export default useDetermineActiveOptionPricing;
