/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';

import { useFormatMessage } from 'app/common/hooks';

import { PageNumberControl, PaginationControl } from './components';
import { usePageNumbers } from './hooks';
import messages from './ResultsPagination.messages';

/**
 * Render component for pagination controls for browse and search results.
 *
 * @visibleName Browse and Search Results Pagination Controls
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const ResultsPagination = ({
  currentPage = 0,
  totalPages = 1,
  onChange = page => {}
}) => {
  const formatMessage = useFormatMessage();
  const pageNumbers = usePageNumbers(currentPage + 1, totalPages);

  return (
    <nav
      className="flex flex-col items-end justify-center self-end mt-12 mb-4 -mx-1 sm:items-center sm:flex-row text-center text-sm"
      role="navigation"
      aria-label={formatMessage(messages.label)}
    >
      <ol className="flex list-none">
        {map(pageNumbers, (value, i) => {
          return (
            <PageNumberControl
              current={value - 1 === currentPage}
              key={i}
              value={value}
              onChange={() => onChange(value - 1)}
            />
          );
        })}
      </ol>
      <div className="mt-4 sm:mt-0">
        <PaginationControl
          currentPage={currentPage + 1}
          disabled={currentPage === 0}
          type={PaginationControl.Types.PREVIOUS}
          onChange={() => onChange(currentPage - 1)}
        />
        <PaginationControl
          currentPage={currentPage + 1}
          disabled={currentPage === totalPages - 1}
          type={PaginationControl.Types.NEXT}
          onChange={() => onChange(currentPage + 1)}
        />
      </div>
    </nav>
  );
};

ResultsPagination.propTypes = {
  /** The number of the current page (starting at 0) */
  currentPage: PropTypes.number,
  /** The total number of pages in the results set */
  totalPages: PropTypes.number
};

ResultsPagination.PaginationControl = PaginationControl;
ResultsPagination.PageNumberControl = PageNumberControl;

export default ResultsPagination;
export { ResultsPagination };
