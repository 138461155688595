/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { IsoCountries } from 'app/common/constants';

function determineDefaultCountry(currentLocale) {
  switch (currentLocale) {
    case 'es':
    case 'es-MX':
      return IsoCountries.MX.alpha2;
    case 'fr':
    case 'en-CA':
      return IsoCountries.CA.alpha2;
    case 'en':
    case 'en-US':
    default:
      return IsoCountries.US.alpha2;
  }
}

function determineDefaultStateProvinceRegion(country) {
  switch (country) {
    case 'CA':
      return 'AB';
    case 'ES':
      return 'AN';
    case 'FR':
      return 'A';
    case 'GB':
      return 'CHA';
    case 'MX':
      return 'DIF';
    case 'US':
    default:
      return 'AL';
  }
}

export { determineDefaultCountry, determineDefaultStateProvinceRegion };
