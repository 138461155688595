/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  navAriaLabel: {
    id: 'Header.ariaLabel',
    defaultMessage: 'Main Site Navigation'
  },
  register: {
    id: 'Header.register',
    defaultMessage: 'Register'
  },
  aboutUs: {
    id: 'Header.aboutUs',
    defaultMessage: 'About Us'
  },
  siteHelp: {
    id: 'Header.siteHelp',
    defaultMessage: 'Site Help'
  },
  customerService: {
    id: 'Header.customerService',
    defaultMessage: 'Customer Service'
  },
  serviceAndRewards: {
    id: 'Header.serviceAndRewards',
    defaultMessage: 'Services & Rewards'
  },
  signIn: {
    id: 'Header.label',
    defaultMessage: 'Sign In'
  },
  noDealerCallout: {
    id: 'Header.dealerCallout.none',
    defaultMessage: 'No Dealer Selected'
  },
  dealerCallout: {
    id: 'Header.dealerCallout',
    defaultMessage: 'Selected Dealer: {dealer}'
  }
});
