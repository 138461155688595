import useSuggestLocationApi from './useSuggestLocationApi';
import { useDebouncedCallback, useRefreshEffect } from 'app/common/hooks';
import { isEmpty } from 'lodash';

/**
 * @typedef {Object} SuggestAddressProps
 * @property {string} q - The query to search for.
 * @property {boolean} skipApiCall - Should we skip the API call?
 * @property {function} onSuggestError - Callback to call on error.
 * @property {function} onSuggestSuccess - Callback to call on success.
 * @property {function} clearSuggestions - Callback to clear suggestions.
 */

/**
 * Call the suggest address API based on the supplied query in the StoreDropdownState.
 * @param {SuggestAddressProps} props
 */
const useSuggestAddress = function ({
  q = '',
  skipApiCall = false,
  onSuggestError,
  onSuggestSuccess,
  clearSuggestions
}) {
  const { sendCallback: sendSuggestionLocation } = useSuggestLocationApi();

  const getLocationResults = useDebouncedCallback(async () => {
    if (skipApiCall || isEmpty(q)) {
      return;
    }
    const response = await sendSuggestionLocation({ params: { q } });
    if (response?.error) {
      onSuggestError();
    } else {
      onSuggestSuccess(response);
    }
  }, 750);

  // send query
  useRefreshEffect(
    () => {
      if (isEmpty(q) || q.length < 2) {
        clearSuggestions();
        return;
      }

      getLocationResults();
    },
    { getLocationResults, q, skipApiCall }
  );
};

export { useSuggestAddress };
