/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

import { ConfigurableItemContext } from 'app/product/contexts';
import { useProductRequiresConfiguration } from 'app/product/hooks';

import { initialState, reducer } from './configurableItemReducer';
import { useActiveOption, useAttributeOptionInfo } from './hooks';

const ConfigurableItem = ({ children, item }) => {
  const { options, sku, variants } = item;
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    attributeValueToRelatedCombinationsMap,
    unusedAttributeNames,
    unusedAttributeValues
  } = useAttributeOptionInfo(options, variants);
  const activeOption = useActiveOption(
    variants,
    state.variantAttributeChoices,
    sku
  );
  const requiresConfiguration = useProductRequiresConfiguration(
    item,
    activeOption,
    state.itemChoices
  );

  const context = React.useMemo(
    () => ({
      ...state,
      activeOption,
      dispatch,
      attributeValueToRelatedCombinationsMap,
      requiresConfiguration,
      unusedAttributeNames,
      unusedAttributeValues
    }),
    [
      activeOption,
      dispatch,
      attributeValueToRelatedCombinationsMap,
      requiresConfiguration,
      state,
      unusedAttributeNames,
      unusedAttributeValues
    ]
  );

  return (
    <ConfigurableItemContext.Provider value={context}>
      {children}
    </ConfigurableItemContext.Provider>
  );
};

ConfigurableItem.propTypes = {
  item: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.object),
    variants: PropTypes.arrayOf(PropTypes.object)
  }).isRequired
};

export default ConfigurableItem;
export { ConfigurableItem };
