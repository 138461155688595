/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, merge, noop } from 'lodash';
import PropTypes from 'prop-types';
import { default as qs } from 'query-string';
import { IntlProvider, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { I18nContext, LocaleContext } from 'app/common/contexts';
import { Environment } from 'app/common/services';

import {
  getDefaultLocaleFromCache,
  putDefaultLocaleInCache
} from './services/LocaleCache';

/**
 * Helper component that sets up the global `LocaleContext` and `I18nContext`.
 *
 * @visibleName Internationalization Provider
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const I18nProvider = ({
  defaultAllowedLocales,
  children,
  defaultLocale,
  history,
  location,
  messages
}) => {
  defaultLocale = defaultLocale || getDefaultLocale(location);
  const search = get(location, 'search');
  const historyPush = get(history, 'push', noop);
  const [currentLocale, setCurrentLocale] = React.useState(defaultLocale);
  const [allowedLocales, setAllowedLocales] = React.useState(
    defaultAllowedLocales
  );
  const [currentLanguage, setCurrentLanguage] = React.useState(
    currentLocale.toLowerCase().split(/[_-]+/)[0]
  );
  // derive the current messages
  const currentLocaleMessages = get(
    messages,
    currentLocale,
    get(messages, currentLanguage, get(messages, 'en', {}))
  );
  const setCurrentLocaleCallback = React.useCallback(
    locale => {
      const params = merge({}, qs.parse(search), {
        [BL_LOCALE_PARAM()]: locale
      });
      historyPush({ search: qs.stringify(params) });
      putDefaultLocaleInCache(locale);
      return setCurrentLocale(locale);
    },
    [historyPush, search]
  );
  const context = React.useMemo(() => {
    return {
      allowedLocales,
      currentLocale,
      messages,
      setAllowedLocales,
      setCurrentLocale: setCurrentLocaleCallback
    };
  }, [allowedLocales, currentLocale, messages, setCurrentLocaleCallback]);

  React.useEffect(() => {
    setCurrentLanguage(currentLocale.toLowerCase().split(/[_-]+/)[0]);
  }, [currentLocale]);

  React.useEffect(() => {
    // update "html" tag with current language
    document.documentElement.lang = currentLanguage;
  }, [currentLanguage]);

  return (
    <LocaleContext.Provider value={context}>
      <IntlProvider
        key={currentLocale}
        locale={currentLocale}
        messages={currentLocaleMessages}
      >
        <I18nContextProvider>{children}</I18nContextProvider>
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

I18nProvider.propTypes = {
  /** Child components to render within the component  */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func
  ]),
  /**
   * Array of default possible locales supported by the app into which to
   * translate content.
   */
  defaultAllowedLocales: PropTypes.arrayOf(PropTypes.string),
  /** Default locale for the app */
  defaultLocale: PropTypes.string,
  /** Object containing translated static text to use throughout the app */
  messages: PropTypes.object
};

I18nProvider.defaultProps = {
  defaultAllowedLocales: Environment.get('ALLOWED_LOCALES', 'en').split(','),
  messages: {}
};

/**
 * This is a workaround to inject `intl` into a new React context so that hooks
 * such as `useIntl` and `useFormatMessage` work.
 *
 * @TODO remove once https://github.com/yahoo/react-intl/pull/1186 is merged in react-intl
 * @see `common/hooks/useIntl`
 * @see `common/hooks/useFormatMessage`
 */
export const I18nContextProvider = injectIntl(({ intl, children }) => {
  return <I18nContext.Provider value={intl}>{children}</I18nContext.Provider>;
});

function getDefaultLocale(location = {}) {
  // first look to see if the user has a manual override as a query parameter
  const localeFromLocation =
    !!location.search && qs.parse(location.search)[BL_LOCALE_PARAM()];

  if (!!localeFromLocation) {
    putDefaultLocaleInCache(localeFromLocation);
    return localeFromLocation;
  }

  // second, look at the last locale cached based on a query parameter
  const cachedLocale = getDefaultLocaleFromCache();

  if (!!cachedLocale) {
    return cachedLocale;
  }

  const navigator = window.navigator;
  // third look at the browser navigator's language preferences
  const localeFromNavigator =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;

  if (!!localeFromNavigator) {
    return localeFromNavigator;
  }

  // lastly default to english
  return Environment.get('DEFAULT_LOCALE', 'en');
}

const BL_LOCALE_PARAM = function () {
  return Environment.get('LOCALE_PARAM', 'blLocale');
};

export default withRouter(I18nProvider);
export { I18nProvider, getDefaultLocale };
