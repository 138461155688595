/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { Formik, Form } from 'formik';
import { get, isEmpty } from 'lodash';

import { AuthContext } from 'app/auth/contexts';
import {
  DecoratedField,
  Modal,
  PrimaryButton,
  SecondaryButton
} from 'app/common/components';
import { useCartInfo, useFormatMessage, useRestApi } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import messages from './AddToListModal.messages';

const AddToListModal = ({ handlePostSubmit, open = false, setOpen }) => {
  const formatMessage = useFormatMessage();
  const { user } = React.useContext(AuthContext);
  const customerRef = React.useMemo(
    () => ({
      customerId: get(user, 'serviceId'),
      username: get(user, 'username'),
      isRegistered: true,
      accountId: get(user, 'attributes[account_id]')
    }),
    [user]
  );
  const {
    listOperations: { baseUrl }
  } = useCartInfo();
  const createConfig = React.useMemo(
    () => ({ method: 'post', params: customerRef }),
    [customerRef]
  );
  const {
    error: createError,
    exception: createException,
    sendCallback: handleCreateList
  } = useRestApi(baseUrl, createConfig, false);

  React.useEffect(() => {
    if (createError) {
      logError({
        ...createException,
        when: 'creating a new list'
      });
    }
  }, [createError, createException]);

  return (
    <Modal
      size={Modal.Size.MEDIUM}
      isOpen={open}
      validateOnBlur={true}
      validateOnChange={false}
    >
      <Formik
        initialValues={{ name: '', attributes: {} }}
        onSubmit={async (values, actions) => {
          await handleCreateList({ data: values });
          actions.setSubmitting(false);
          handlePostSubmit();
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Modal.Header>
              <Modal.Header.Title>
                <span className="lg:text-xl font-medium">
                  {formatMessage(messages.title)}
                </span>
              </Modal.Header.Title>
              <Modal.Close
                onClose={() => {
                  setOpen(false);
                }}
              />
            </Modal.Header>
            <Modal.Body className="bg-gray-100 text-gray-700">
              <DecoratedField
                name="name"
                id="name"
                label={formatMessage(messages.description)}
                required
                disabled={isSubmitting}
                validate={value => {
                  if (isEmpty(value)) {
                    return formatMessage(messages.nameRequired);
                  }
                }}
              />
            </Modal.Body>
            <Modal.Footer className="flex items-center lg:justify-between">
              <SecondaryButton
                className="mr-4"
                disabled={isSubmitting}
                onClick={() => {
                  setOpen(false);
                }}
              >
                {formatMessage(messages.cancel)}
              </SecondaryButton>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                {formatMessage(messages.submit)}
              </PrimaryButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddToListModal;
export { AddToListModal };
