/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect, useState } from 'react';
import { intersection, isEmpty, isNil, omitBy, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { TenantContext } from 'app/common/contexts';
import { Icon, TertiaryButton } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';
import {
  parseSearch,
  stringifyParams
} from 'app/search-and-browse/shared/utils/FilterUtils';
import TopBrandContext from 'app/common/contexts/TopBrandContext';

import { ResultsFilter } from './components';
import { FilterContext } from './contexts';
import messages from './ResultsFilterPanel.messages';
import { Environment } from 'app/common/services';

const categoryListVisibleFilters = featuredBrandsEnabled()
  ? ['attr-BRAND']
  : ['categoryIds'];

/**
 * Render component for browse and search Filters/Facets.
 *
 * @visibleName Browse and Search Results Filters
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const ResultsFilterPanel = ({
  categoryList = false,
  filters,
  history,
  setAvailabilitySort,
  searchableFacets = [],
  selectedFirst,
  scrollLimit = undefined,
  showHeader = true,
  facetValueLimit
}) => {
  const formatMessage = useFormatMessage();
  const [activeFilters, setActiveFilters] = useState([]);
  const [lockFilters, setLockFilters] = useState(false);
  const [showClearAllFilters, setShowClearAllFilters] = React.useState(false);
  useEffect(() => {
    const newActiveFilters = filters.reduce(
      (accumulator, { facet, values }) => {
        const { name } = facet;
        const activeValues = values
          .filter(({ active = false }) => !!active)
          .map(({ maxValue, minValue, multiSelect, ranged, value }) =>
            omitBy(
              {
                maxValue,
                minValue,
                multiSelect,
                name,
                ranged,
                value
              },
              isNil
            )
          );

        return accumulator.concat(activeValues);
      },
      []
    );
    // update active filters
    setActiveFilters(newActiveFilters);
    // unlock filters
    setLockFilters(false);
  }, [filters]);

  const visibleFilters = useVisibleFilters(
    filters,
    categoryList,
    setAvailabilitySort
  );
  const context = React.useMemo(
    () => ({ activeFilters, lockFilters, setLockFilters }),
    [activeFilters, lockFilters]
  );

  useEffect(() => {
    const activeFilterNames = activeFilters.map(({ name }) => name);
    const visibleFilterNames = visibleFilters.map(({ facet }) => facet.name);
    setShowClearAllFilters(
      !isEmpty(intersection(visibleFilterNames, activeFilterNames))
    );
  }, [visibleFilters, activeFilters]);

  if (isEmpty(visibleFilters)) {
    return null;
  }

  return (
    <section
      className="ResultsFilterPanel relative mb-4 border-b
    border-gray-400 lg:mb-0 lg:border-b-0"
    >
      <FilterContext.Provider value={context}>
        <div>
          <div
            className="flex items-baseline justify-between w-full mb-3
          font-bold lg:flex-col sm:mb-5 sm:text-lg md:text-xl"
          >
            {showHeader && (
              <div className="mr-4 leading-none lg:mr-0 lg:mb-4">
                {formatMessage(messages.label)}
              </div>
            )}
            {showClearAllFilters && (
              <TertiaryButton
                onClick={() => {
                  if (lockFilters) {
                    return;
                  }

                  setLockFilters(true);

                  const { location, push } = history;
                  const params = parseSearch(location.search);
                  params.filters = [];
                  // need to reset the page number when changing facets so we don't end up at a nonexistent page
                  params.page = 1;

                  push({
                    ...location,
                    search: stringifyParams(params)
                  });

                  setLockFilters(false);
                }}
                size={TertiaryButton.Size.SMALL}
              >
                <Icon className="mr-2" name="times" />
                <div className="leading-none">
                  {formatMessage(messages.clearFilters)}
                </div>
              </TertiaryButton>
            )}
          </div>
          <ul
            className="pb-3 overflow-x-auto whitespace-no-wrap list-none
          lg:whitespace-normal lg:overflow-x-hidden lg:block"
          >
            {visibleFilters.map((facet, i) => (
              <ResultsFilterPanel.Filter
                {...facet}
                isSearchable={searchableFacets.includes(facet.facet.name)}
                selectedFirst={selectedFirst}
                key={`${facet.facet.name}-${i}`}
                scrollLimit={scrollLimit}
                facetValueLimit={facetValueLimit}
              />
            ))}
          </ul>
        </div>
      </FilterContext.Provider>
    </section>
  );
};

function useVisibleFilters(filters, categoryList, setAvailabilitySort) {
  const { application } = React.useContext(TenantContext);
  const { activeTopBrand } = React.useContext(TopBrandContext);
  return React.useMemo(() => {
    return ResultsFilterPanel.getVisibleFilters(
      filters,
      categoryList,
      application,
      activeTopBrand,
      setAvailabilitySort
    );
  }, [filters, categoryList, application, activeTopBrand, setAvailabilitySort]);
}

ResultsFilterPanel.getVisibleFilters = (
  filters,
  categoryList,
  application,
  activeTopBrand,
  setAvailabilitySort
) => {
  const topBrandActive = !isEmpty(activeTopBrand);
  const visible = filters
    .filter(({ facet, values }) =>
      filterVisible(facet, values, application, categoryList, topBrandActive)
    )
    .map(filter => {
      return {
        ...filter,
        values: sortBy(filter.values, ({ value }) => {
          if (categoryList && filter.facet.name === 'categoryIds') {
            const p = value.split('||');
            return p[1];
          }

          return value;
        }).filter(({ value }) => {
          // Check for inventory availability facets for current dealer
          if (filter.facet.name === 'inventoryAvailableForApplicationScopes') {
            const hasAvailability = application.id === value;
            setAvailabilitySort(hasAvailability);
            return hasAvailability;
          }
          return true;
        })
      };
    });

  return visible;
};

const filterVisible = function (
  facet,
  facetValues,
  application,
  isCategoryListView,
  isTopBrandActive
) {
  const { name: facetName } = facet;
  // If we're in category view, only show category facet
  const categoryIdsFacet = facetName === 'categoryIds';
  if (isCategoryListView) {
    return categoryListVisibleFilters.includes(facetName);
  }
  //Omit the category filter otherwise
  if (!isCategoryListView && categoryIdsFacet) {
    return false;
  }

  // Filter facets with no values
  const hasValues = !isEmpty(facetValues);
  if (!hasValues) {
    return false;
  }
  // Filter if no quantity
  const hasQuantity = facetValues.some(value => value.quantity > 0);
  if (!hasQuantity) {
    return false;
  }
  // Filter brand facet if we're in a top brand context
  const isBrandFacet = facetName === 'attr-BRAND';
  if (isTopBrandActive && isBrandFacet) {
    return false;
  }
  // Filter inventory facet if no inventory in view for current dealer
  const isInventoryFacet =
    facetName === 'inventoryAvailableForApplicationScopes';
  if (isInventoryFacet) {
    return facetValues.some(value => value.value === application.id);
  }
  return true;
};

function featuredBrandsEnabled() {
  return Environment.get('FEATURED_BRANDS_ENABLED') === 'true';
}

ResultsFilterPanel.propTypes = {
  /**
   * Indicates whether this is the body of a category list results instead of
   * product list or search. This impacts whether the category filter is hidden.
   */
  categoryList: PropTypes.bool,
  /** Array of filters to display */
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      /** General info about a filter */
      facet: PropTypes.shape({
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        /** Whether multiple values can be selected at once */
        multiSelect: PropTypes.bool,
        /** Whether the filter uses a range demarcations rather than discrete values */
        ranged: PropTypes.bool
      }).isRequired,
      /** Possible values for the filter to display */
      values: PropTypes.arrayOf(
        PropTypes.shape({
          /** Whether this value is currently selected */
          active: PropTypes.bool,
          /**
           * If this value belongs to a ranged Filter, then this is the max value for
           * this segment of the range.
           */
          maxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          /**
           * If this value belongs to a ranged Filter, then this is the min value for
           * this segment of the range.
           */
          minValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          /** The number of items in the result set that match this value */
          quantity: PropTypes.number,
          /** If not ranged, then this is the explicit value */
          value: PropTypes.string
        })
      ).isRequired
    })
  ).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object
    }).isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  /**
   * An array of text-searchable facets.
   */
  searchableFacets: PropTypes.arrayOf(PropTypes.string),
  selectedFirst: PropTypes.bool
};

ResultsFilterPanel.Filter = ResultsFilter;

export default withRouter(ResultsFilterPanel);
export { ResultsFilterPanel };
