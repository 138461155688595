/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import TagManager from 'app/common/tagmanager';

import { useLocation } from 'app/common/hooks';

function useProductClick(item, originListOverride) {
  const location = useLocation();
  const originList =
    originListOverride || item.containerName || getOriginList(location);
  return React.useCallback(() => {
    TagManager.dataLayer({
      event: 'productClickTo',
      ecommerce: {
        click: {
          actionField: {
            list: originList
          },
          products: [item]
        }
      }
    });
  }, [originList, item]);
}

function getOriginList(location) {
  if (location.pathname.startsWith('/search')) {
    return 'Search';
  }

  if (location.pathname.startsWith('/part-search')) {
    return 'Browse';
  }

  if (location.pathname.startsWith('/cart')) {
    return 'Cart';
  }

  if (location.pathname.startsWith('/checkout')) {
    if (location.pathname.includes('/confirmation')) {
      return 'Order Confirmation';
    }

    return 'Checkout';
  }

  if (location.pathname.startsWith('/my-account/orders')) {
    return 'Order History';
  }

  if (location.pathname.startsWith('/order-tracking')) {
    return 'Order Tracking';
  }

  if (location.pathname === '/') {
    return 'Homepage';
  }

  return 'Aurora Parts to Go';
}

export default useProductClick;
