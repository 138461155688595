import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import { get, isEmpty, map } from 'lodash';
import storeDropdownMessages from './StoreDropdown.messages';
import messages from './StoreDropdown.messages';
import classNames from 'classnames';
import { Icon } from 'app/common/components';
import {
  useFindStores,
  useStoreSearchContainerRefs
} from 'app/store-locator/hooks';
import {
  useApplicationParameter,
  useFormatMessage,
  useNationalSiteContext,
  useTenantContext
} from 'app/common/hooks';
import {
  LocationQuery,
  NearbyStoreRowsSkeleton,
  NoLocationsFound,
  StoreLocationRow
} from 'app/store-locator/components';
import { storeDropdownReducer, storeDropdownState } from './reducer';
import {
  APP_LOADED,
  HANDLE_ADDRESS_SELECT,
  SET_AUTO_COMPLETE_RESULTS,
  SET_DEALER_NUMBER,
  SET_QUERY,
  SET_STORES
} from './reducer/storeDropdownActions';
import {
  AUTO_COMPLETE_OPEN,
  LOADING_LOCATIONS,
  LOADING_LOCATIONS_COMPLETE,
  RESET_LOCATION_QUERY,
  STORE_LOOKUP_ERROR,
  STORE_SEARCH_CLOSE,
  STORE_SEARCH_OPEN
} from './reducer/storeDropdownReducer';
import {
  getCurrentLocation,
  getStoreRowFromApplication
} from './util/storeDropdownUtil';
import { SiteContext } from 'app/common/contexts';
import { updateDropdownContextAndReducer } from './util';

/**
 * Renders the store dropdown. This is the main component for the store dropdown that is displayed in the site header.
 * @returns {Element}
 * @constructor
 */
const StoreDropdown = () => {
  const formatMessage = useFormatMessage();
  const { openStoreDropdown, setOpenStoreDropdown } = useContext(SiteContext);

  const { application, resolving } = useTenantContext() || {};
  const { nationalSiteOrDealerNetworkContext, dealerNetwork } =
    useNationalSiteContext();
  const [state, dispatch] = useReducer(
    storeDropdownReducer,
    storeDropdownState
  );
  const dropdownContainerRefs = useStoreSearchContainerRefs(
    state,
    dispatch,
    setOpenStoreDropdown
  );

  useEffect(() => {
    if (openStoreDropdown) {
      dispatch(STORE_SEARCH_OPEN);
    }
  }, [openStoreDropdown, state.storeSearchOpen]);

  useEffect(() => {
    if (resolving) {
      return;
    }
    let currentStore = null;
    let currentDealerName = null;
    if (!application.nationalSiteOrDealerNetwork) {
      currentStore = getStoreRowFromApplication(application);
      currentDealerName = get(currentStore, 'locationName');
      if (dealerNetwork && !isEmpty(get(currentStore, 'displayName'))) {
        currentDealerName = get(currentStore, 'displayName');
      }
    }
    const dealerNumber = currentStore ? currentStore.locationNumber : null;

    dispatch({
      type: APP_LOADED,
      payload: { currentStore, currentDealerName, dealerNumber }
    });
    // eslint-disable-next-line
  }, [application, resolving]);
  const currentDealerName = state.currentDealerName
    ? state.currentDealerName
    : formatMessage(messages.enterZip);
  return !nationalSiteOrDealerNetworkContext ? (
    <WhiteLabelDropdown currentDealerName={currentDealerName} />
  ) : (
    <FullStoreDropdown
      currentDealerName={currentDealerName}
      dropdownContainerRefs={dropdownContainerRefs}
      state={state}
      dispatch={dispatch}
      setOpenStoreDropdown={setOpenStoreDropdown}
    />
  );
};

/**
 * Return fully functional dropdown with store selector.
 * @param currentDealerName
 * @param dropdownContainerRefs
 * @param state
 * @param dispatch
 * @param formatMessage
 * @returns {Element}
 * @constructor
 */
const FullStoreDropdown = ({
  currentDealerName,
  dropdownContainerRefs,
  state,
  dispatch,
  setOpenStoreDropdown
}) => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <div className="w-full h-24 lg:w-3/5 md:h-auto flex">
        <div
          className={
            'flex flex-row items-center w-full md:w-3/5 text-sm sm:text-base'
          }
          ref={dropdownContainerRefs.parentContainerRef}
        >
          <div>
            <div className={'flex flex-row sm:flex-col'}>
              <div
                className={
                  'hidden sm:flex sm:w-full font-bold text-gray-500 text-sm'
                }
              >
                {formatMessage(storeDropdownMessages.selectedLocation)}
              </div>
              <div
                className={
                  'items-center flex sm:hidden font-bold text-gray-500 text-sm pr-2'
                }
              >
                {formatMessage(storeDropdownMessages.selectedLocationSmall)}
              </div>
              <div className={'locator-store-name sm:w-full'}>
                {currentDealerName}
              </div>
            </div>
          </div>
          <div className={'pl-6'}>
            <button
              onClick={() => {
                state.storeSearchOpen
                  ? updateDropdownContextAndReducer(
                      dispatch,
                      STORE_SEARCH_CLOSE,
                      setOpenStoreDropdown
                    )
                  : dispatch(STORE_SEARCH_OPEN);
              }}
            >
              <Icon
                name={state.storeSearchOpen ? 'chevron-up' : 'chevron-down'}
                className={'fa-xl'}
              />
            </button>
          </div>

          <StoreDropdownMenu
            state={state}
            dispatch={dispatch}
            dropdownContainerRefs={dropdownContainerRefs}
            setOpenStoreDropdown={setOpenStoreDropdown}
          />
        </div>
      </div>
    </>
  );
};

/**
 * Return static dropdown. This only displays the current store name.
 * @param currentDealerName
 */
const WhiteLabelDropdown = ({ currentDealerName }) => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <div className="w-full h-24 lg:w-3/5 md:h-auto flex">
        <div
          className={
            'flex flex-row items-center w-full md:w-3/5 text-sm sm:text-base'
          }
        >
          <div>
            <div className={'flex flex-row sm:flex-col'}>
              <div
                className={
                  'hidden sm:flex sm:w-full font-bold text-gray-500 text-sm'
                }
              >
                {formatMessage(storeDropdownMessages.selectedLocation)}
              </div>
              <div
                className={
                  'items-center flex sm:hidden font-bold text-gray-500 text-sm pr-2'
                }
              >
                {formatMessage(storeDropdownMessages.selectedLocationSmall)}
              </div>
              <div className={'locator-store-name sm:w-full'}>
                {currentDealerName}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

/**
 * Renders the store dropdown menu. This is displayed when the user opens the store dropdown.
 * @param dropdownProps : StoreDropdownState
 * @returns {React.JSX.Element|null}
 * @constructor
 */
const StoreDropdownMenu = ({
  state,
  dispatch,
  dropdownContainerRefs,
  setOpenStoreDropdown
}) => {
  const { storeSearchOpen, currentStore, isLoadingLocations } = state;
  const { clickOutsideContainerRef } = dropdownContainerRefs;
  const { dealerNetwork } = useNationalSiteContext();
  useFindStores(
    findStoresArgs({
      state,
      dispatch,
      maxDistanceMiles: dealerNetwork ? 5000 : undefined
    })
  );
  useSetAsStore(state, dispatch);
  const inputProps = locationQueryInputProps({ state, dispatch });
  const suggestProps = suggestAddressProps({ state, dispatch });
  const suggestionAutoCompleteProps = suggestAutoCompleteProps(
    state,
    dispatch,
    dropdownContainerRefs
  );

  return !storeSearchOpen ? null : (
    <div
      ref={clickOutsideContainerRef}
      id="store-dropdown-menu"
      className={classNames(
        'overflow-y-scroll flex flex-col absolute top-full',
        'right-0 md:right-auto',
        'z-50',
        'w-full max-w-screen md:w-3/4 lg:w-2/3 xl:w-1/2',
        'max-h-screen-3/4 sm:max-h-136',
        'mt-px px-4 pb-4 pt-4 ',
        'text-gray-700 bg-white rounded shadow-lg'
      )}
    >
      <div className="flex flex-col store-dropdown-container">
        <div className="flex flex-col store-dropdown-row divide-y-2">
          <LocationQuery
            getLocationOnClick={() => getCurrentLocation(state, dispatch)}
            closeButtonOnClick={() =>
              updateDropdownContextAndReducer(
                dispatch,
                STORE_SEARCH_CLOSE,
                setOpenStoreDropdown
              )
            }
            locationQueryInputProps={inputProps}
            suggestAddressProps={suggestProps}
            suggestionAutoCompleteProps={suggestionAutoCompleteProps}
          />
          <CurrentStoreRow
            currentStore={currentStore}
            state={state}
            dispatch={dispatch}
          />
          {isLoadingLocations ? (
            <NearbyStoreRowsSkeleton />
          ) : (
            <NearbyStoreRows {...{ dropdownContainerRefs, state, dispatch }} />
          )}
          <NoLocationsFound state={state} />
        </div>
      </div>
    </div>
  );
};

/**
 * Renders the current store row. This is the store that is currently selected.
 * @returns {React.JSX.Element|null}
 * @constructor
 */
const CurrentStoreRow = ({ state, dispatch }) => {
  const { currentStore } = state;
  if (!currentStore) {
    return null;
  }
  return (
    <StoreLocationRow
      state={state}
      dispatch={dispatch}
      location={currentStore}
      selected={true}
      currentStoreButtonLabel={messages.myStore}
      showStoreDetailsLink={true}
      storeDetailsOnClick={() => dispatch(STORE_SEARCH_CLOSE)}
    />
  );
};

/**
 * @return {LocationQueryInputProps}
 */
const locationQueryInputProps = ({ state, dispatch }) => {
  const { query, isLoadingLocations, error, errorMessage } = state;
  return {
    id: 'locationQueryInput',
    formId: 'locationQueryForm',
    query: query?.q || '',
    disabled: isLoadingLocations,
    onChange: e => clearAddress(e, state, dispatch),
    onClickOrFocus: () => dispatch(AUTO_COMPLETE_OPEN),
    onSubmit: e => handleLocationQuerySubmit(e, state, dispatch),
    error: error,
    errorMessage: errorMessage
  };
};

const findStoresArgs = ({ state, dispatch, maxDistanceMiles }) => {
  const { userSelectedAddress, dealerNumber } = state;
  const onStartRequest = () => {
    dispatch(LOADING_LOCATIONS);
  };
  const onSuccess = ({ currentLocation, stores }) => {
    dispatch({
      type: SET_STORES,
      payload: {
        currentLocation,
        stores,
        autoCompleteOpen: false,
        autoCompleteResults: undefined,
        isLoadingLocations: false
      }
    });
  };
  const onFailure = () => {
    dispatch(STORE_LOOKUP_ERROR(messages.storeLookupError));
  };
  const onComplete = () => {
    dispatch(LOADING_LOCATIONS_COMPLETE);
  };
  const params = {
    latLong: userSelectedAddress,
    dealerNumber,
    maxDistanceMiles
  };
  return {
    onStartRequest,
    onSuccess,
    onFailure,
    onComplete,
    params
  };
};

/**
 * @return {SuggestAddressProps}
 */
const suggestAddressProps = ({ state, dispatch }) => {
  const { query } = state;
  return {
    q: query?.q || '',
    skipApiCall: query?.skipApiCall || false,
    onSuggestError: () =>
      dispatch(STORE_LOOKUP_ERROR(storeDropdownMessages.addressLookupError)),
    onSuggestSuccess: payload =>
      dispatch({ type: SET_AUTO_COMPLETE_RESULTS, payload }),
    clearSuggestions: () =>
      dispatch({ type: SET_AUTO_COMPLETE_RESULTS, payload: [] })
  };
};

/**
 * @return {SuggestionsAutoCompleteProps}
 */
const suggestAutoCompleteProps = (state, dispatch, dropdownContainerRefs) => {
  const { autoCompleteResults, autoCompleteOpen } = state;
  const { clickOutsideSuggestionsContainerRef, suggestionContainerRef } =
    dropdownContainerRefs;
  return {
    autoCompleteOpen,
    autoCompleteResults,
    clickOutsideSuggestionsContainerRef,
    suggestionContainerRef,
    onSelect: (e, suggestion, latitude, longitude) =>
      handleAddressSelect(e, suggestion, latitude, longitude, dispatch)
  };
};

/**
 * Renders the nearby store rows.
 * @returns {React.JSX.Element|null}
 * @constructor
 */
const NearbyStoreRows = ({ state, dispatch }) => {
  const { stores, dealerNumber } = state;
  const filteredStores = useMemo(
    () =>
      isEmpty(stores)
        ? []
        : stores.filter(s => s.locationNumber !== dealerNumber),
    [dealerNumber, stores]
  );
  if (isEmpty(filteredStores)) {
    return null;
  }

  return (
    <>
      {map(filteredStores, (location, index) => {
        const { locationNumber } = location;
        return (
          <StoreLocationRow
            state={state}
            dispatch={dispatch}
            location={location}
            key={index}
            currentStoreButtonLabel={messages.myStore}
            selectStoreButtonLabel={messages.setAsStore}
            selectStoreOnClick={e => {
              e.preventDefault();
              selectCurrentDealer(locationNumber, state, dispatch);
            }}
            showStoreDetailsLink={true}
            storeDetailsOnClick={() => dispatch(STORE_SEARCH_CLOSE)}
          />
        );
      })}
    </>
  );
};

/**
 * Sets the current dealer number in the store dropdown state. This state change will trigger a location change.
 * See useSetAsStore in {@link StoreDropdown}
 */
const selectCurrentDealer = function (locationNumber, state, dispatch) {
  dispatch({ type: SET_DEALER_NUMBER, payload: locationNumber });
};

/**
 * Sets the selected store. This is used when the user clicks the "Set as Store" button.
 * The store is set using the dealerLocation url param.
 */
const useSetAsStore = (state, dispatch) => {
  const { dealerNumber, userClickedSelectStore } = state;
  const [, setApplicationParam] = useApplicationParameter();
  const { application, resolving } = useTenantContext();
  useEffect(() => {
    if (resolving || !userClickedSelectStore) {
      return;
    }
    const identifierValue = get(application, 'identifierValue', null);
    if (!isEmpty(dealerNumber) && dealerNumber !== identifierValue) {
      setApplicationParam(dealerNumber);
    }
  }, [
    dealerNumber,
    application,
    resolving,
    setApplicationParam,
    userClickedSelectStore
  ]);
};

/**
 * Handles the selection of an address suggestion in the {@link SuggestionAutoComplete} component.
 * When the user selects an address suggestion, the query input field is also updated with the selected address.
 * @param e
 * @param suggestion
 * @param latitude
 * @param longitude
 * @param dispatch
 */
const handleAddressSelect = function (
  e,
  suggestion,
  latitude,
  longitude,
  dispatch
) {
  e.preventDefault();
  dispatch({
    type: HANDLE_ADDRESS_SELECT,
    payload: {
      q: suggestion,
      latitude,
      longitude
    }
  });
};

/**
 * Handles the submission of the location query form in the {@link LocationQuery} component.
 * @param e
 * @param state
 * @param dispatch
 */
const handleLocationQuerySubmit = function (e, state, dispatch) {
  e.preventDefault();
  const { autoCompleteResults } = state;
  const { suggestions } = autoCompleteResults || {};
  if (suggestions && suggestions.length > 0) {
    const { suggestion, latLong } = suggestions[0];
    const { latitude, longitude } = latLong;
    handleAddressSelect(e, suggestion, latitude, longitude, dispatch);
  }
  document.getElementById('locationQueryInput')?.blur();
};

const clearAddress = function (e, state, dispatch) {
  dispatch({
    type: SET_QUERY,
    payload: e.target.value
  });
  const { query } = state;
  if (isEmpty(query?.q)) {
    dispatch(RESET_LOCATION_QUERY);
  }
};

export default StoreDropdown;
export { StoreDropdown };
