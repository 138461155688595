/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/

import useCatalogInfo from '../useCatalogInfo';

/**
 * Hook to use to build product URLs. By default, the product URIs need to have
 * a base context path appended onto them for correct routing, such as
 * `/details`.
 *
 * @param {string} uri - Product URI that may need a prefix
 *
 * @return {string} Completed product URL
 */
function useProductUrl(uri) {
  const { productInfo = {} } = useCatalogInfo();
  const { routeBaseContext = '' } = productInfo;
  return routeBaseContext + uri;
}

export default useProductUrl;
