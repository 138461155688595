/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { noop } from 'lodash';

/**
 * @type {Readonly<{
 *   selectAll: boolean,
 *   lists: [],
 *   toggleSelectAll: function,
 *   isAdding: boolean,
 *   setIsAdding: function,
 *   selectedLists: [],
 *   setLists: function,
 *   setSelectedLists: function}>
 * }
 */
const defaultContext = Object.freeze({
  isAdding: false,
  setIsAdding: noop,
  lists: [],
  setLists: noop,
  selectAll: false,
  toggleSelectAll: noop,
  selectedLists: [],
  setSelectedLists: noop,
  resolvingLists: true
});

const ListsContext = React.createContext(defaultContext);
ListsContext.defaultContext = defaultContext;

export default ListsContext;
