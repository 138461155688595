/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Render component for a field's label.
 */
const Label = ({
  children,
  className,
  field,
  textSizes = 'text-sm',
  textWeights = 'font-bold',
  textTransformations = 'capitalize',
  textDefaultColor = 'text-gray-700',
  textDisabledColor = 'text-gray-500',
  ...props
}) => (
  <label
    className={classNames('flex mb-2 leading-none', {
      [className]: !!className,
      [textSizes]: !!textSizes,
      [textWeights]: !!textWeights,
      [textTransformations]: !!textTransformations,
      [textDefaultColor]: !field.disabled,
      [textDisabledColor]: !!field.disabled
    })}
    htmlFor={field.id || field.name}
    {...props}
  >
    <div>{field.label}</div>
    {!!field.required && (
      <div
        className={classNames('ml-1', {
          'text-red-500': !field.disabled,
          'text-red-300': !!field.disabled
        })}
      >
        {'*'}
      </div>
    )}
  </label>
);

Label.propTypes = {
  field: PropTypes.shape({
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    required: PropTypes.bool
  }).isRequired,
  /**
   * Class name(s) to affect the text size of the label.
   *
   * Added because not styles can be overridden via the `className` prop.
   */
  textSizes: PropTypes.string,
  /**
   * Class name(s) to affect the text transformation of the label.
   *
   * Added because not styles can be overridden via the `className` prop.
   */
  textTransformations: PropTypes.string,
  /**
   * Class name(s) to affect the text weight of the label.
   *
   * Added because not styles can be overridden via the `className` prop.
   */
  textWeights: PropTypes.string
};

export default Label;
export { Label };
