import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const ScrollToError = () => {
  const formik = useFormikContext();
  const { isSubmitting, errors } = formik;
  useEffect(() => {
    if (!isSubmitting) return;
    const errors = document.querySelector('.field-error');
    if (errors) {
      errors.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      errors.focus({ preventScroll: true });
    }
  }, [isSubmitting, errors]);
  return null;
};

export default ScrollToError;
export { ScrollToError };
