/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { find, get, isEmpty, noop, omit, reduce, set } from 'lodash';

import {
  CartItemOptions,
  CheckboxField,
  Currency,
  Icon,
  Link,
  TertiaryButton
} from 'app/common/components';
import { useProductUrl } from 'app/common/hooks';
import { addParams } from 'app/common/utils/PathUtils';
import {
  determineIncludedItemPricing,
  determineProductOrVariantPricing
} from 'app/product/utils/PricingUtils';
import notFound from 'app/common/img/image-not-found.jpg';

import { ListItemQuantity, RemoveListItem } from './components';

const ListItem = ({
  listItem,
  selected = false,
  handleChecked,
  handlePostRemove
}) => {
  const {
    id: itemId,
    itemListId: listId,
    product,
    quantity,
    itemSkuRef: { sku: itemSku, variantId },
    attributes
  } = listItem;
  const {
    name,
    variants,
    primaryAsset = {
      contentUrl: notFound
    },
    includedProducts,
    uri
  } = product;
  const detailsUrl = useProductUrl(uri);
  const productPricing = determineProductOrVariantPricing(
    product,
    get(product, 'priceInfo')
  );
  const selectedVariant = find(variants, ({ id, sku }) => {
    return id === variantId || sku === itemSku;
  });
  const selectedVariantPricing = determineProductOrVariantPricing(
    selectedVariant,
    get(selectedVariant, 'priceInfo')
  );
  const additionalAmount = React.useMemo(
    () =>
      reduce(
        includedProducts,
        (sum, item) => {
          const { p = { amount: 0.0 } } = determineIncludedItemPricing(item);
          return sum + p.amount * item.quantity;
        },
        0.0
      ),
    [includedProducts]
  );
  const price =
    (isEmpty(selectedVariantPricing)
      ? productPricing.price
      : selectedVariantPricing.price) || {};
  set(price, 'amount', get(price, 'amount', 0.0));

  return (
    <li className="flex flex-wrap items-start justify-between w-full p-4 border-b border-solid border-gray-400 last:border-b-0 sm:flex-no-wrap sm:items-start">
      <CheckboxField.Field
        className="basis-full sm:basis-1/12"
        checked={selected}
        field={{
          onBlur: noop,
          onChange: () => {
            if (handleChecked) {
              handleChecked(!selected);
            }
          },
          name: 'list-item',
          value: itemId
        }}
        value={itemId}
      />
      {!isEmpty(primaryAsset) && (
        <div className="flex items-center basis-3/10 pr-4 sm:items-start sm:basis-1/5">
          <ListItemAsset
            {...primaryAsset}
            detailsUrl={detailsUrl}
            itemName={name}
          />
        </div>
      )}
      <div
        className={classNames('sm:pr-4', {
          'basis-6/10 flex-grow sm:basis-2/5': !isEmpty(primaryAsset),
          'basis-10/12 flex-grow sm:basis-3/5': isEmpty(primaryAsset)
        })}
      >
        <ListItemDetails
          listId={listId}
          itemId={itemId}
          product={product}
          attributes={attributes}
          name={name}
          handlePostRemove={handlePostRemove}
          detailsUrl={detailsUrl}
        />
      </div>
      <div className="w-full mt-4 sm:basis-2/5 sm:mt-0">
        <ListItemPricing
          quantity={quantity}
          unitPrice={price}
          id={itemId}
          additionalAmount={additionalAmount}
          product={product}
        />
      </div>
    </li>
  );
};

const ListItemAsset = ({
  altText,
  contentUrl: src,
  detailsUrl,
  itemName,
  title
}) => {
  return (
    <figure className="block w-full">
      <Link to={detailsUrl}>
        <img
          className="block mx-auto"
          alt={altText || `${itemName} Image`}
          title={title}
          src={`${addParams(src, { 'product-xs': true })}`}
          height="240"
          width="240"
        />
      </Link>
    </figure>
  );
};

const ListItemDetails = ({
  itemId,
  listId,
  product,
  attributes,
  detailsUrl,
  name,
  handlePostRemove
}) => {
  return (
    <section className="w-full">
      <header>
        <h3 className="font-bold leading-none">
          <TertiaryButton
            className="text-base"
            colorClassName="text-gray-700 hover:text-gray-800 focus:text-gray-800 active:text-gray-600"
            size={TertiaryButton.Size.CUSTOM}
            to={detailsUrl}
          >
            {name}
          </TertiaryButton>
        </h3>
      </header>
      <section className="mt-2 mb-4">
        <CartItemOptions
          cartItemAttributes={omit(attributes, ['dependentCartItems'])}
          dependentCartItems={get(attributes, 'dependentCartItems')}
          product={product}
        />
      </section>
      <footer className="flex items-center justify-start mt-4">
        <RemoveListItem
          id={itemId}
          listId={listId}
          handlePostRemove={handlePostRemove}
        />
      </footer>
    </section>
  );
};

const ListItemPricing = ({
  id,
  quantity,
  unitPrice,
  additionalAmount = 0.0,
  product,
  isSale = false,
  overriddenPrice
}) => {
  const total = (unitPrice.amount + additionalAmount) * quantity;
  return (
    <section className="flex flex-col items-end justify-end w-full">
      <div className="flex flex-no-wrap items-center justify-end w-full">
        <div className="relative">
          <Currency
            className={classNames('unit-price font-medium text-base', {
              'text-red-600': isSale
            })}
            {...unitPrice}
          />
          {isSale && (
            <Currency
              className="ml-1 text-gray-500 text-sm line-through sm:absolute sm:top-5 sm:left-0 sm:ml-0"
              {...overriddenPrice}
            />
          )}
        </div>
        <div className="inline-flex items-center">
          <Icon className="mx-2 text-center" name="times" />
        </div>
        <ListItemQuantity
          currentQuantity={quantity}
          id={id}
          product={product}
        />
        <Currency
          className="final-price ml-2 font-bold"
          amount={total}
          currency={unitPrice.currency}
        />
      </div>
    </section>
  );
};

export default ListItem;
export { ListItem };
