/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import { AuthContext } from 'app/auth/contexts';
import { ResolutionContextType } from 'app/common/constants';
import { TenantContext } from 'app/common/contexts';
import {
  Addresses,
  Discounts,
  Fleets,
  ListDetails,
  Lists,
  Orders,
  PreferredLocation
} from 'app/my-account/components';

import { AccountInfo, AccountInfoSkeleton, LeftNav } from '../';

/**
 * This renders the routing logic for the account management pages, and the
 * Left Nav that controls them.
 */
const Layout = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const { resolutionContext } = React.useContext(TenantContext);
  return (
    <main className="flex-grow w-full px-4 py-12 bg-gray-200 border-b-1">
      <div className="container w-full lg:flex lg:items-start lg:justify-center">
        <LeftNav />
        <div className="lg:w-4/5">
          {isAuthenticated && (
            <Switch>
              <Route exact path="/my-account" component={AccountInfo} />
              {resolutionContext !== ResolutionContextType.DEALER && (
                <Route
                  exact
                  path="/my-account/preferred-location"
                  component={PreferredLocation}
                />
              )}
              <Route
                exact
                path="/my-account/orders/:id"
                component={Orders.Details}
              />
              <Route exact path="/my-account/orders" component={Orders} />
              <Route exact path="/my-account/addresses" component={Addresses} />
              <Route exact path="/my-account/lists" component={Lists} />
              <Route
                exact
                path="/my-account/lists/:id"
                component={props => <ListDetails id={props.match.params.id} />}
              />
              <Route exact path="/my-account/fleets" component={Fleets} />
              <Route exact path="/my-account/discounts" component={Discounts} />
            </Switch>
          )}
        </div>
      </div>
    </main>
  );
};

const LayoutSkeleton = () => {
  const { resolutionContext } = React.useContext(TenantContext);
  return (
    <main className="flex-grow w-full px-4 py-12 bg-gray-200 border-b-1">
      <div className="container w-full lg:flex lg:items-start lg:justify-center">
        <LeftNav />
        <div className="lg:w-4/5">
          <Switch>
            <Route exact path="/my-account" component={AccountInfoSkeleton} />
            {resolutionContext !== ResolutionContextType.DEALER && (
              <Route
                exact
                path="/my-account/preferred-location"
                component={PreferredLocation.Skeleton}
              />
            )}
            <Route
              exact
              path="/my-account/orders/:id"
              component={Orders.Details.Skeleton}
            />

            <Route
              exact
              path="/my-account/addresses"
              component={Addresses.Skeleton}
            />
            <Route exact path="/my-account/lists" component={Lists.Skeleton} />
            <Route
              exact
              path="/my-account/lists/:id"
              component={ListDetails.Skeleton}
            />
            <Route exact path="/my-account/fleets" component={Fleets} />
            <Route exact path="/my-account/discounts" component={Discounts} />
          </Switch>
        </div>
      </div>
    </main>
  );
};

export default Layout;
export { Layout, LayoutSkeleton };
