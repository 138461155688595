import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import {
  DID_FETCH,
  FETCH_COMPLETE,
  FETCH_START,
  INITIATE_FETCH,
  NO_FETCH_NEEDED
} from 'app/about/components/About/reducer/aboutUsReducer';
import { useNationalSiteContext } from 'app/common/hooks';
import { useReadStoreDetails } from 'app/about/components/About/hooks';

function useDetermineDealerForDisplay(state, dispatch) {
  const { fetchDetails, loading, response } = useReadStoreDetails();
  const { resolving, dealerSelected, currentApplication, defaultApplication } =
    useNationalSiteContext();

  const {
    accountNumber,
    locationId,
    isFetching,
    shouldFetch,
    noFetch,
    fetchComplete
  } = state;

  const {
    accountNumber: accountNumberFromPath,
    locationId: locationIdFromPath,
    isStoreDetailsPath
  } = useAccountAndLocationNumber();

  // Initiate if accountNumberAndLocId changes
  useEffect(() => {
    dispatch(INITIATE_FETCH(accountNumberFromPath, locationIdFromPath));
    // eslint-disable-next-line
  }, [accountNumberFromPath, locationIdFromPath]);

  // Do the fetch, or set the addressHoursSource to the dealer or default application
  // if no fetch is needed
  useEffect(() => {
    if (!shouldFetch || isFetching || loading || resolving) {
      return;
    }
    dispatch(FETCH_START);
    if (!isStoreDetailsPath) {
      let source;

      if (dealerSelected) {
        source = currentApplication;
      } else {
        source = isEmpty(defaultApplication)
          ? currentApplication
          : defaultApplication;
      }
      dispatch(NO_FETCH_NEEDED({ storeAddressHoursSource: source }));
    } else {
      fetchDetails({ accountNumber, locationId });
      dispatch(DID_FETCH);
    }
    // eslint-disable-next-line
  }, [
    isFetching,
    isStoreDetailsPath,
    dealerSelected,
    loading,
    currentApplication,
    defaultApplication,
    accountNumber,
    locationId,
    shouldFetch,
    resolving
  ]);

  // Set the storeAddressHoursSource to the response if we've received it
  useEffect(() => {
    if (isEmpty(response) || isFetching || noFetch || fetchComplete) {
      return;
    }
    dispatch(FETCH_COMPLETE({ storeAddressHoursSource: response }));
  }, [response, dispatch, isFetching, noFetch, fetchComplete]);
}

function useAccountAndLocationNumber() {
  const { accountNumber, locationId } = useParams();
  const isStoreDetailsPath = !isEmpty(accountNumber) && !isEmpty(locationId);
  return { accountNumber, locationId, isStoreDetailsPath };
}

export default useDetermineDealerForDisplay;
export { useAccountAndLocationNumber };
