/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

import notFound from 'app/common/img/image-not-found.jpg';
import { addParams } from 'app/common/utils/PathUtils';
import classNames from 'classnames';

const Image = ({ name, primaryAsset, className }) => {
  const [height, setHeight] = React.useState('auto');
  const [width, setWidth] = React.useState('100%');
  const { value: altText = '', contentUrl: src = notFound } = primaryAsset;

  return (
    <figure
      className={classNames('mb-6 rounded-md overflow-hidden shadow-lg', {
        [className]: !!className
      })}
    >
      <img
        className="block rounded"
        onLoad={({ target: img }) => {
          const h = img.offsetHeight;
          const w = img.offsetWidth;
          setHeight(w > h ? 'auto' : '100%');
          setWidth(w > h ? '100%' : 'auto');
        }}
        src={`${addParams(src)}`}
        alt={altText || name + ' Banner Image'}
        style={{ height, width }}
      />
    </figure>
  );
};

Image.propTypes = {
  /** Label(Name) of the image */
  name: PropTypes.string,
  /** The imageObject which would contain the contentUrl of the image, label, value, etc. */
  primaryAsset: PropTypes.object
};

export default Image;
export { Image };
