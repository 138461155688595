/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty } from 'lodash';

import { SecondaryButton } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from '../../AuthenticatedAddressForm.messages';
import thisMessages from './AddressFormLegend.messages';

const AddressFormLegend = props => {
  const { customerAddresses, setUseSavedAddress, type } = props;
  const formatMessage = useFormatMessage();
  return (
    <legend className="mb-4 text-gray-900 font-bold lg:text-lg">
      {formatMessage(messages.legend, { type })}
      {!isEmpty(customerAddresses) && (
        <span className="ml-2 font-normal text-sm lg:text-base">
          {formatMessage(thisMessages.enterOrChoose)}
          <SecondaryButton
            className="ml-2"
            onClick={() => {
              setUseSavedAddress(true);
            }}
          >
            {formatMessage(messages.chooseSaved, { type })}
          </SecondaryButton>
        </span>
      )}
    </legend>
  );
};

export default AddressFormLegend;
export { AddressFormLegend };
