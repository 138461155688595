/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { AddToListButton } from 'app/common/components';

import { RemoveItemButton } from './components';

/**
 * Render component for cart item actions such as remove or edit.
 *
 * @visibleName Cart: Item: Actions
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const CartItemActions = ({ className, cartItem, position }) => {
  const { id } = cartItem;
  return (
    <footer
      className={classNames('flex items-center justify-start mt-4', {
        [className]: !!className
      })}
    >
      <CartItemActions.AddToListButton item={cartItem} />
      <CartItemActions.RemoveItemButton
        cartItem={cartItem}
        id={id}
        position={position}
      />
    </footer>
  );
};

CartItemActions.propTypes = {
  /** Additional class names to add to the component */
  className: PropTypes.string,
  /** ID of the cart item to which the actions apply */
  cartItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    productId: PropTypes.string.isRequired,
    variantId: PropTypes.string,
    attributes: PropTypes.shape({
      sku: PropTypes.string
    })
  }).isRequired
};

CartItemActions.RemoveItemButton = RemoveItemButton;
CartItemActions.AddToListButton = AddToListButton;

export default CartItemActions;
export { CartItemActions };
