/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useEffect, useRef } from 'react';
import { isEqual as isDeepEqual } from 'lodash';

import usePrevious from '../usePrevious';

/**
 * Effect used for implementing fetch behavior on mount or in reaction to changes in dependencies.
 *
 * @param {Function} callback - the callback that executes when the dependencies
 *     change or the `condition` is met
 * @param {Object} dependencies - the dependencies for this fetch effect, by
 *     default re-fetch will trigger when this value does not deep equal the
 *     previous value
 * @param {Function} [condition=defaultCondition] - the condition function that
 *     compares the current and previous dependencies to understand if a re-
 *     fetch should happen
 * @param {boolean} [refreshOnMount=true] - Whether to refresh on mount
 */
function useRefreshEffect(
  callback,
  dependencies,
  condition = defaultCondition,
  refreshOnMount = true
) {
  const hasRefreshed = useRef(false);
  const prevDependencies = usePrevious(dependencies);
  useEffect(() => {
    if (
      (refreshOnMount && !hasRefreshed.current) ||
      (prevDependencies && condition(dependencies, prevDependencies))
    ) {
      hasRefreshed.current = true;
      callback(dependencies);
    }
  }, [callback, condition, dependencies, prevDependencies, refreshOnMount]);
}

function defaultCondition(dependencies, prevDependencies) {
  return !isDeepEqual(dependencies, prevDependencies);
}

export default useRefreshEffect;
export { useRefreshEffect, defaultCondition };
