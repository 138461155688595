/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { Formik } from 'formik';
import { filter, get, isEmpty, noop, values } from 'lodash';
import PropTypes from 'prop-types';

import { ProductOptionType } from 'app/common/constants';
import log from 'app/common/utils/Log';
import { ConfigurableItemContext } from 'app/product/contexts';
import {
  VariantAttributeChoice,
  ItemAttributeChoice
} from 'app/product/components/options';

const logger = log.getLogger(
  'product.DefaultPDP.MerchandisingInfo.ProductOptions.ItemChoice.ChoiceOptions'
);

const ChoiceOptions = ({ options }) => {
  const { unusedAttributeNames } = React.useContext(ConfigurableItemContext);
  const optionsToRender = React.useMemo(
    () =>
      filter(
        options,
        option =>
          option.type !== ProductOptionType.VARIANT_DISTINGUISHING ||
          isEmpty(unusedAttributeNames) ||
          !unusedAttributeNames.has(
            get(option, 'attributeChoice.attributeName')
          )
      ),
    [options, unusedAttributeNames]
  );
  const initialValues = React.useMemo(
    () =>
      options
        .filter(option => option.type === ProductOptionType.CART_ITEM_ATTRIBUTE)
        .reduce((acc, option) => {
          acc[option.attributeChoice.attributeName] = '';
          return acc;
        }, {}),
    [options]
  );

  if (isEmpty(options)) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={noop}
      validateOnBlur={true}
      validateOnChange={false}
      enableReinitialize={true}
    >
      <section className="w-full mt-4 pl-8 border-l-4 border-gray-400">
        <ol className="flex flex-col w-full list-none">
          {optionsToRender.map((option, i) => {
            switch (option.type) {
              case ProductOptionType.VARIANT_DISTINGUISHING:
                return <VariantAttributeChoice key={i} option={option} />;
              case ProductOptionType.CART_ITEM_ATTRIBUTE:
                return <ItemAttributeChoice key={i} option={option} />;
              default:
                logger.warn(
                  `Option of type ${option.type} unsupported for ItemChoices`
                );
                return null;
            }
          })}
        </ol>
      </section>
    </Formik>
  );
};

ChoiceOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      attributeChoice: PropTypes.shape({
        attributeName: PropTypes.string.isRequired
      }),
      itemChoice: PropTypes.object,
      type: PropTypes.oneOf(values(ProductOptionType)).isRequired
    })
  ).isRequired
};

export default ChoiceOptions;
export { ChoiceOptions };
