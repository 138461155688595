/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty } from 'lodash';

import { CartHelmet } from 'app/cart/helpers';
import { CartOrListErrors, PrimaryButton } from 'app/common/components';
import { CartContext } from 'app/common/contexts';
import {
  useCartInfo,
  useFormatMessage,
  useModifyCartRequest
} from 'app/common/hooks';

import { CartItems, CartOrderInstructions, CartSummary } from './components';
import messages from './Cart.messages';

/**
 * Render component for the full cart.
 *
 * @visibleName Cart Page
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const Cart = () => {
  const formatMessage = useFormatMessage();
  const { cart, cartOperationException, resolving, setCartOperationException } =
    React.useContext(CartContext);
  const quantity = get(cart, 'quantity', 0);
  const deleteAttribute = useDeleteCartAttribute();
  const cartErrors = get(cart, 'attributes.errors');

  if (resolving || cart === undefined) {
    return <Cart.Skeleton />;
  }

  if (quantity < 1 && isEmpty(cartErrors)) {
    return <Cart.Empty formatMessage={formatMessage} />;
  }

  return (
    <main className="flex-grow px-2 py-8 bg-gray-200 md:px-6 lg:px-8">
      <CartHelmet />
      <header className="container">
        <h2 className="mb-4 text-gray-700 text-lg font-medium capitalize leading-none md:mb-6 md:text-xl lg:mb-8 lg:text-2xl">
          {formatMessage(messages.cartTitle)}
        </h2>
      </header>
      <section className="container flex flex-col flex-no-wrap justify-between lg:flex-row lg:items-start">
        <section className="w-full mb-6 lg:w-7/10 lg:mr-4 lg:mb-0 xl:w-3/4">
          {/*
           * this could potentially be designed to have multiple groups of
           * items, particularly if you already know some info about the
           * fulfillment groups based off special product flags (like large
           * items are shipped separately), inventory info (like
           * knowing that different inventory comes from different warehouses
           * so will be shipped separately), or fulfillment type info (like
           * digital items will be fulfilled separately from physical items).
           */}
          <CartOrListErrors
            errors={
              !isEmpty(cartOperationException)
                ? cartOperationException
                : cartErrors
            }
            isCart
            clearErrors={
              !isEmpty(cartOperationException)
                ? () => setCartOperationException(undefined)
                : deleteAttribute
            }
          />
          <CartItems />
          <CartOrderInstructions />
        </section>
        <section className="lg:w-3/10 xl:w-1/4">
          <CartSummary />
        </section>
      </section>
    </main>
  );
};

const Skeleton = () => {
  const formatMessage = useFormatMessage();
  return (
    <main className="flex-grow px-2 py-8 bg-gray-200 md:px-6 lg:px-8">
      <CartHelmet loading />
      <header className="container">
        <h2 className="mb-4 text-gray-700 text-lg font-medium capitalize leading-none md:mb-6 md:text-xl lg:mb-8 lg:text-2xl">
          {formatMessage(messages.cartTitle)}
        </h2>
      </header>
      <section className="container flex flex-col flex-no-wrap justify-between lg:flex-row lg:items-start">
        <section className="w-full mb-6 lg:w-7/10 lg:mr-4 lg:mb-0 xl:w-3/4">
          <CartItems />
          <CartOrderInstructions />
        </section>
        <section className="lg:w-3/10 xl:w-1/4">
          <CartSummary.Skeleton />
        </section>
      </section>
    </main>
  );
};

Cart.Skeleton = Skeleton;

/**
 * Render component for the content of an empty cart.
 */
Cart.Empty = ({ formatMessage }) => {
  return (
    <main
      className="flex-grow px-2 py-8 bg-gray-200 md:px-6 lg:px-8"
      style={{ minHeight: '50vh' }}
    >
      <CartHelmet />
      <header className="container flex flex-col items-center">
        <h2 className="mb-8 text-gray-700 text-xl font-medium capitalize leading-none md:text-2xl lg:text-3xl">
          {formatMessage(messages.empty)}
        </h2>
        <PrimaryButton size={PrimaryButton.Size.LARGE} to="/">
          {formatMessage(messages.continueShopping)}
        </PrimaryButton>
      </header>
    </main>
  );
};

function useDeleteCartAttribute() {
  const {
    operations: { attributesContextPath, baseUrl }
  } = useCartInfo();
  const config = React.useMemo(
    () => ({ method: 'del', params: { price: false }, data: {} }),
    []
  );
  const { sendCallback: deleteAttribute } = useModifyCartRequest(
    `${baseUrl}${attributesContextPath}/errors`,
    config,
    false
  );
  const { setCart } = React.useContext(CartContext);
  return React.useCallback(async () => {
    const result = await deleteAttribute();
    if (!isEmpty(result)) {
      setCart(result);
    }
    return Promise.resolve();
  }, [deleteAttribute, setCart]);
}

export default Cart;
export { Cart };
