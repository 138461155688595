/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';

const ProductOptionHeader = ({ children, label, required }) => {
  return (
    <header className="mb-2">
      <h3 className="text-gray-800 font-bold">
        <span>{label}</span>
        {required && <span className="text-red-500">{'*'}</span>}
        {children}
      </h3>
    </header>
  );
};

ProductOptionHeader.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool
};

export default ProductOptionHeader;
export { ProductOptionHeader };
