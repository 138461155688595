/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty, join } from 'lodash';
import PropTypes from 'prop-types';

import { useProductUrl } from 'app/common/hooks';
import notFound from 'app/common/img/image-not-found.jpg';

import { ItemActions, ItemAsset, ItemHeader, ItemPricing } from './components';

/**
 * Render component for a single cart item in the mini shopping cart dialog.
 *
 * @visibleName Minicart: Dialog: Item
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const DialogItem = ({ cartItem, position }) => {
  const {
    id,
    name,
    quantity = 1,
    unitPriceWithDependentItems: unitPrice,
    uri
  } = cartItem;
  const imageAsset = cartItem.imageAsset || { contentUrl: notFound };
  const url = useProductUrl(uri);
  const gtmItem = React.useMemo(
    () => ({
      brand: get(cartItem, 'attributes.productBrand.value'),
      category: join(get(cartItem, 'attributes.categoryNames', []), ', '),
      id: cartItem.sku,
      name: get(cartItem, 'attributes.productDescription'),
      price: get(cartItem, 'unitPrice.amount', 0.0),
      position,
      quantity: cartItem.quantity
    }),
    [cartItem, position]
  );
  return (
    <li className="flex items-center py-2">
      {!isEmpty(imageAsset) && (
        <ItemAsset
          imageAsset={imageAsset}
          productName={name}
          productUrl={url}
          gtmItem={gtmItem}
        />
      )}
      <div className="flex flex-col basis-7/10 leading-snug">
        <ItemHeader name={name} url={url} gtmItem={gtmItem} />
        {!!unitPrice && (
          <ItemPricing quantity={quantity} unitPrice={unitPrice} />
        )}
        <ItemActions id={id} cartItem={cartItem} position={position} />
      </div>
    </li>
  );
};

DialogItem.propTypes = {
  cartItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    imageAsset: PropTypes.shape({
      altText: PropTypes.string,
      contentUrl: PropTypes.string,
      title: PropTypes.string
    }),
    quantity: PropTypes.number,
    unitPriceWithDependentItems: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired
    }),
    uri: PropTypes.string
  }).isRequired
};

export default DialogItem;
export { DialogItem, ItemActions, ItemAsset, ItemHeader, ItemPricing };
