/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useEffect, useMemo, useReducer } from 'react';
import { isEmpty, map } from 'lodash';
import { Helmet } from 'react-helmet';
import {
  useFormatMessage,
  useGtmPageView,
  useReadCustomerOrders,
  useReadHistoricalCustomerInfo,
  useScrollToTop
} from 'app/common/hooks';

import {
  OrderDateFilter,
  OrderDetailsView,
  OrderLocationFilter,
  OrderSummary
} from './components';
import messages from './Orders.messages';
import { ResultsPagination } from 'app/search-and-browse/shared/components/ResultsBody/components';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { useHeaderMetadata } from 'app/core/components/App';
import { isNotCompleteStatus } from 'app/common/hooks/useRestApi/RequestStatus.js';
import {
  orderHistoryReducer,
  orderHistoryState,
  setApplicationId,
  setOrderHistoricalInfo,
  setOrdersPage
} from 'app/my-account/components/Orders/orderHistoryReducer.js';
import qs from 'query-string';

const Orders = ({ location }) => {
  const [state, dispatch] = useReducer(
    orderHistoryReducer,
    orderHistoryState,
    initialState => {
      return {
        ...initialState,
        ...findInitialParams(location)
      };
    }
  );
  const history = useHistory();
  useScrollToTop([state.days, state.year, state.page, state.applicationId]);
  useGtmPageView('Order History');

  const formatMessage = useFormatMessage();
  const {
    sendCallback: readHistoricalInfoSendCallback,
    requestStatus: historicalStatus
  } = useReadHistoricalCustomerInfo(state.applicationId);

  // Read the historical info first
  useReadOrderInfo(readHistoricalInfoSendCallback, state, dispatch);

  // Use the historical info above to determine which orders to read.
  // If we don't have orders from the last 30 days but have them for 2020,
  // for example, we want to read orders from that year instead of the
  // default last 30 days view.
  const config = useCurrentPageInfo(state, dispatch);
  const {
    sendCallback: readOrderSendCallback,
    requestStatus: orderReadStatus
  } = useReadCustomerOrders(config);
  useReadOrders(config, readOrderSendCallback, state, dispatch);

  const historyFilterChange = onHistoryFilterChange({
    history
  });

  const {
    content: orders,
    totalPages,
    number: currentPage,
    totalElements
  } = state.ordersPage || {};
  const hasPages = totalPages && totalPages > 1;
  const isLoading =
    isNotCompleteStatus(historicalStatus) ||
    isNotCompleteStatus(orderReadStatus);

  return (
    <>
      <HelmetTitle />
      <section>
        <header className="text-2xl text-bold flex justify-between pt-2 basis-full">
          <h1>{formatMessage(messages.listViewTitle)}</h1>
        </header>
        {isLoading && <Skeleton message={messages.loading} />}
        {!isLoading && (
          <>
            <div className={'pb-2 pt-2'}>
              <Filters
                orderHistoricalInfo={state.orderHistoricalInfo}
                days={state.days}
                year={state.year}
                historyFilterChange={historyFilterChange}
                totalElements={totalElements}
                applicationId={state.applicationId}
                setApplicationId={appId => dispatch(setApplicationId(appId))}
                setOrdersPage={page => dispatch(setOrdersPage(page))}
              />
            </div>
            <OrderSummaryList
              orders={orders}
              currentPage={currentPage}
              totalPages={totalPages}
              hasPages={hasPages}
              dispatch={dispatch}
            />
          </>
        )}
      </section>
    </>
  );
};

const OrderSummaryList = ({
  orders,
  currentPage,
  totalPages,
  hasPages,
  dispatch
}) => {
  return (
    <section>
      {isEmpty(orders) ? (
        <>
          <Skeleton message={messages.empty} />
        </>
      ) : (
        <>
          <ul className="list-none">
            {map(orders, order => (
              <li className="my-8 first:my-0" key={order.id}>
                <OrderSummary order={order} />
              </li>
            ))}
          </ul>
        </>
      )}
      <OrderResultsPagination
        currentPage={currentPage}
        totalPages={totalPages}
        hasPages={hasPages}
        dispatch={dispatch}
      />
    </section>
  );
};

const Filters = ({
  orderHistoricalInfo,
  days,
  year,
  historyFilterChange,
  totalElements,
  applicationId,
  setApplicationId
}) => {
  return (
    <div className={'col-span-1 flex flex-col md:flex-row'}>
      <div className={'basis-auto'}>
        <OrderLocationFilter
          orderHistoricalInfo={orderHistoricalInfo}
          onChange={props => {
            historyFilterChange(props);
          }}
          applicationId={applicationId}
          setApplicationId={setApplicationId}
          numOrders={totalElements}
        />
      </div>
      <div className={'basis-auto md:pt-0'}>
        <OrderDateFilter
          selectedDays={days}
          selectedYear={year}
          numOrders={totalElements}
          orderHistoricalInfo={orderHistoricalInfo}
          onChange={props => {
            historyFilterChange(props);
          }}
        />
      </div>
    </div>
  );
};

const OrderResultsPagination = ({
  currentPage,
  totalPages,
  hasPages,
  dispatch
}) => {
  if (hasPages) {
    return (
      <ResultsPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onChange={page =>
          dispatch({
            type: 'SET_STATE',
            payload: {
              page: page
            }
          })
        }
      />
    );
  }
  return null;
};

const OrdersSkeleton = () => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <div className="text-2xl text-bold flex justify-between pb-4">
        <span>{formatMessage(messages.listViewTitle)}</span>
      </div>
      <div className="w-full text-bold text-gray-500 text-xl sm:mt-8 sm:ml-10 sm:text-2xl lg:mt-18 lg:ml-20">
        {formatMessage(messages.loading)}
      </div>
    </>
  );
};

const Skeleton = ({ message }) => {
  const formatMessage = useFormatMessage();
  return (
    <div className="w-full text-bold text-gray-500 text-xl sm:mt-8 sm:ml-10 sm:text-2xl lg:mt-18 lg:ml-20">
      {formatMessage(message)}
    </div>
  );
};

const HelmetTitle = () => {
  const { siteTitle } = useHeaderMetadata();
  const formatMessage = useFormatMessage();
  return (
    <Helmet titleTemplate={`%s - ${siteTitle}`}>
      <title>{formatMessage(messages.listViewTitle)}</title>
    </Helmet>
  );
};

function onHistoryFilterChange({ history }) {
  let newSearch = {};
  const currentSearch = qs.parse(history.location.search);
  return ({ days, year, applicationId, type }) => {
    if (type === 'date') {
      if (days) {
        newSearch = { ...currentSearch, days, page: 1 };
        delete newSearch.year;
      } else if (year) {
        newSearch = { ...currentSearch, year, page: 1 };
        delete newSearch.days;
      }
    } else if (type === 'appFilter') {
      newSearch = { ...currentSearch, applicationId, page: 1 };
    }
    history.push({
      ...history.location,
      search: qs.stringify(newSearch)
    });
  };
}

function useReadOrders(config, readOrderSendCallback, state, dispatch) {
  const { orderHistoricalInfo, page, days, year, applicationId, lastRequest } =
    state;
  useEffect(() => {
    const readOrders = async () => {
      if (orderHistoricalInfo.loading) {
        return;
      }
      if (noParametersChanged(lastRequest, config)) {
        return;
      }
      dispatch(setOrdersPage({}));
      const ordersPageResult = await readOrderSendCallback();
      dispatch({
        type: 'SET_STATE',
        payload: {
          ordersPage: { ...ordersPageResult },
          lastRequest: {
            days: config.params.days,
            year: config.params.year,
            applicationId: config.params.applicationId,
            page: config.params.page
          }
        }
      });
    };
    readOrders().then(() => {});
    // useState setters are guaranteed to be stable, so we can safely exclude them
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderHistoricalInfo,
    readOrderSendCallback,
    config,
    applicationId,
    year,
    days,
    page,
    lastRequest
  ]);
}

function useCurrentPageInfo(state, dispatch) {
  const location = useLocation();
  const { orderHistoricalInfo, page, year, days, applicationId } = state;
  return useMemo(() => {
    const getParams = () => {
      const urlParams = new URLSearchParams(location.search);
      let _page = urlParams.get('page') ? urlParams.get('page') - 1 : page || 0;
      _page = _page < 0 ? 0 : _page;
      const _year = urlParams.get('year') || undefined;
      const _days = !_year ? urlParams.get('days') : undefined;
      const _applicationId = urlParams.get('applicationId') || 'NONE';
      const size = 10;
      let requestParams = { page: _page, size };
      if (_applicationId && _applicationId !== 'NONE') {
        requestParams = { ...requestParams, applicationId: _applicationId };
      }
      if (orderHistoricalInfo?.orderInfo?.length > 0) {
        //see if current parameters match any of the historical info
        if (_days || _year) {
          const found = orderHistoricalInfo.orderInfo.find(
            info => info.days == _days || (info.year || -1) == _year
          );
          if (found) {
            return _days
              ? {
                  params: { ...requestParams, days: _days }
                }
              : {
                  params: { ...requestParams, year: _year }
                };
          }
        }
        // otherwise try to find the first entry that has orders
        const historyEntry = orderHistoricalInfo.orderInfo[0];
        if (historyEntry?.year) {
          return {
            params: { ...requestParams, year: historyEntry.year }
          };
        } else if (historyEntry?.days) {
          return {
            params: { ...requestParams, days: historyEntry.days }
          };
        }
      }
      return {
        params: { ...requestParams, days: '30' }
      };
    };
    const params = getParams();
    dispatch({
      type: 'SET_STATE',
      payload: {
        page: params.params.page,
        year: params.params.year,
        days: params.params.days,
        applicationId: params.params.applicationId
      }
    });
    return params;
    // dispatch is stable, so excluded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderHistoricalInfo, page, year, days, applicationId, location.search]);
}

function useReadOrderInfo(readHistoricalInfoSendCallback, state, dispatch) {
  const { applicationId, previousAppId } = state;
  useEffect(() => {
    const readHistoricalOrderInfo = async () => {
      if (previousAppId === applicationId) {
        return;
      }
      if (applicationId !== 'NONE') {
        dispatch({
          type: 'SET_STATE',
          payload: {
            year: undefined,
            days: undefined,
            applicationId
          }
        });
      }
      dispatch(setOrderHistoricalInfo({ loading: true }));
      const historicalInfo = await readHistoricalInfoSendCallback();
      dispatch({
        type: 'SET_STATE',
        payload: {
          orderHistoricalInfo: { ...historicalInfo, loading: false },
          previousAppId: applicationId
        }
      });
    };
    readHistoricalOrderInfo();
    // useState setters are guaranteed to be stable, so we can safely exclude them
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readHistoricalInfoSendCallback, previousAppId, applicationId]);
}

function noParametersChanged(lastRequest, config) {
  const params = config?.params || {};
  return (
    lastRequest.days === params.days &&
    lastRequest.year === params.year &&
    lastRequest.applicationId === params.applicationId &&
    lastRequest.page === params.page
  );
}

function findInitialParams(location) {
  if (location && location.search) {
    const params = qs.parse(location.search);
    if (params.page) {
      params.page = parseInt(params.page, 10) - 1;
    }
    return params;
  }
  return {};
}

Orders.Skeleton = OrdersSkeleton;
Orders.Details = OrderDetailsView;

export default withRouter(Orders);
export { Orders, OrdersSkeleton };
