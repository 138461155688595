import classNames from 'classnames';
import { isEmpty } from 'lodash';
import messages from 'app/layout/components/Header/components/StoreDropdown/StoreDropdown.messages';
import React from 'react';
import { Icon } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

/**
 * @typedef {Object} LocationQueryInputProps
 * @property {string} id The id of the input.
 * @property {string} formId The id of the form.
 * @property {string} query The query string
 * @property {boolean} disabled Is the input disabled?
 * @property {function} onChange The onChange callback
 * @property {function} onClickOrFocus The onClick and onFocus callback
 * @property {function} onSubmit The onSubmit callback
 * @property {boolean} error Has an error occurred?
 * @property {string} errorMessage The error message to display
 */

/**
 *
 * @param {LocationQueryInputProps} props
 */
const LocationQueryInput = ({
  id,
  formId,
  query,
  disabled,
  onChange,
  onClickOrFocus,
  onSubmit,
  error,
  errorMessage
}) => {
  const formatMessage = useFormatMessage();
  return (
    <form id={formId} onSubmit={onSubmit}>
      <div className={'flex relative'}>
        <input
          name={'q'}
          id={id}
          className={classNames(
            'text-gray-900 placeholder-gray-500 leading-none appearance-none bg-white',
            'border border-solid rounded',
            'focus:shadow-outline focus:outline-none disabled:bg-gray-200 disabled:border-gray-300',
            'disabled:cursor-not-allowed w-full p-2 text-sm sm:p-3',
            'lg:text-base border-green-500 h-full'
          )}
          autoComplete={'off'}
          autoFocus={isEmpty(query)}
          placeholder={formatMessage(messages.enterZipCode)}
          disabled={disabled}
          type={'text'}
          aria-details={formatMessage(messages.findNearbyStoresAriaLabel)}
          value={query}
          onChange={onChange}
          onClick={onClickOrFocus}
          onFocus={onClickOrFocus}
        />
        <div
          className={classNames(
            'absolute flex items-center pr-2 pl-3 location-magnifier ',
            'text-gray-700 z-10 cursor-default right-2'
          )}
        >
          <Icon name="search" containerSize="medium" />
        </div>
      </div>
      {error && (
        <div className="flex flex-row w-full text-red-600 lg:inline-flex mt-2 lg:mt-0 lg:ml-2 lg:pt-px text-sm lg:text-base lg:leading-loose">
          {formatMessage(errorMessage ? errorMessage : messages.unknownError)}
        </div>
      )}
    </form>
  );
};

export { LocationQueryInput };
