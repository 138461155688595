/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { get, isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import { AuthContext } from 'app/auth/contexts';
import {
  Icon,
  OrderReviewItems,
  OrderReviewRegistration,
  OrderTracking
} from 'app/common/components';
import { useFormatMessage, useScrollToTop } from 'app/common/hooks';
import { logError } from 'app/common/utils/ApiErrorUtils';

import {
  ConfirmationHeader,
  FulfillmentSummary,
  PaymentSummary
} from './components';
import { useSubmittedCartRequest } from './hooks';

import messages from './Confirmation.messages';
import { useHeaderMetadata } from '../../../../../core/components/App';

const Confirmation = ({ location, match }) => {
  const formatMessage = useFormatMessage();
  const orderNumber = get(match, 'params.orderNumber', {});
  const { isAuthenticated } = React.useContext(AuthContext);
  const { error, exception, loading, response } = useSubmittedCartRequest(
    location,
    orderNumber
  );
  const order = response || { orderNumber };
  const firstName = get(
    order,
    'activePayments[0].billingAddress.firstName',
    ''
  );
  const lastName = get(order, 'activePayments[0].billingAddress.lastName', '');
  const fullName = get(order, 'activePayments[0].billingAddress.fullName', '');
  const { siteTitle } = useHeaderMetadata();

  useScrollToTop([]);

  if (error) {
    logError({
      ...exception,
      when: `fetching confirmation for order ${orderNumber}`
    });
  }

  if (!error && !loading && isEmpty(response)) {
    return <OrderTracking />;
  }

  return (
    <main className="container relative flex flex-col flex-grow mx-auto px-2 py-4 md:px-6 lg:flex-row lg:px-8 lg:py-8">
      <Helmet titleTemplate={`%s - ${siteTitle}`}>
        <title>{formatMessage(messages.title)}</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div
        className={classNames('text-gray-900 lg:w-3/5', {
          'mx-auto': isAuthenticated || error
        })}
      >
        <section className="bg-gray-100 rounded shadow-md">
          <ConfirmationHeader
            order={order}
            orderNumber={orderNumber}
            loading={loading}
          />
          {error && (
            <div
              id="confirmation-error"
              className="flex items-center mt-0 mb-4 mx-4 px-2 py-1 text-sm text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2 lg:mb-6 lg:text-base lg:leading-normal"
            >
              <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
              <span>{formatMessage(messages.generalError)}</span>
            </div>
          )}
          {!isEmpty(response) && (
            <div id="confirmation-details" className="px-4">
              <FulfillmentSummary
                order={order}
                loading={loading}
                addressPath={'fulfillmentGroups[0].address'}
                typePath={'fulfillmentGroups[0].type'}
              />
              <section className="relative flex flex-col pb-8 border-t-2 border-solid border-gray-200 rounded-b">
                <header className="mb-4 pt-4 lg:pt-6">
                  <h3 className="flex items-center capitalize font-bold lg:text-lg">
                    {formatMessage(messages.heading)}
                  </h3>
                </header>
                <OrderReviewItems orderItems={order.cartItems} status="NEW" />
              </section>
              <PaymentSummary
                order={order}
                loading={loading}
                pricingPath="cartPricing"
              />
            </div>
          )}
        </section>
      </div>
      {!isAuthenticated && !error && (
        <div className="relative pt-4 lg:w-2/5 lg:pt-0 lg:pl-4 print:hidden">
          <OrderReviewRegistration
            emailAddress={order.emailAddress}
            fullName={fullName}
            firstName={firstName}
            lastName={lastName}
            loading={loading}
          />
        </div>
      )}
    </main>
  );
};

export default withRouter(Confirmation);
export { Confirmation };
