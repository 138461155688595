/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useContext, useMemo } from 'react';
import { get, isEmpty, merge } from 'lodash';

import { AuthContext } from 'app/auth/contexts';
import { useCartInfo, useRestApi } from 'app/common/hooks';

function useSubmittedCartRequest(location, orderNumber, config) {
  const { isAuthenticated } = useContext(AuthContext);
  const submittedCart = get(location, 'state.submittedCart', {});
  const sendImmediate = isEmpty(submittedCart) && isAuthenticated;
  const { cartHistoryContextPath } = useCartInfo().operations;
  const fetchUrl = `${cartHistoryContextPath}/${orderNumber}`;
  const finalConfig = useMemo(() => {
    if (isAuthenticated) {
      return merge({}, config);
    }

    return merge(
      { params: { emailAddress: get(submittedCart, 'emailAddress') } },
      config
    );
  }, [config, isAuthenticated, submittedCart]);

  const state = useRestApi(fetchUrl, finalConfig, sendImmediate);

  if (sendImmediate) {
    return state;
  }

  return { ...state, response: submittedCart };
}

export default useSubmittedCartRequest;
