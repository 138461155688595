/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext, useMemo } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { Button, Icon } from 'app/common/components';
import { CartContext } from 'app/common/contexts';
import { CheckoutContext } from 'app/checkout/contexts';
import { useCartOfferCodeApi, useFormatMessage } from 'app/common/hooks';

import messages from './PromoCode.messages';

/**
 * Render component for a promotional code in the current cart.
 *
 * @visibleName Cart Page: Promotions: Promo Code
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const PromoCode = ({ code }) => {
  const formatMessage = useFormatMessage();
  const { setCart } = useContext(CartContext);
  const { setPromoCodeUpdated } = useContext(CheckoutContext);
  const config = useMemo(
    () => ({ method: 'del', params: { price: true } }),
    []
  );
  const { loading, sendCallback } = useCartOfferCodeApi(
    code,
    config,
    false,
    true
  );

  return (
    <li className="flex mr-3 mb-4 text-primary-600 bg-primary-100 border border-solid border-primary-200 rounded-full last:mr-0">
      <span className="block pl-3 pr-1 py-1 text-sm font-medium break-all">
        {code}
      </span>
      <Button
        className="flex pl-2 pr-3 py-1 rounded-r-full hover:bg-primary-200 focus:bg-primary-200 active:bg-white"
        onClick={() => {
          if (!loading) {
            sendCallback().then(data => {
              if (!isEmpty(data)) {
                setCart(data);
                setPromoCodeUpdated(true);
              }
            });
          }
        }}
        title={formatMessage(messages.codeRemoveTip)}
      >
        <Icon name="times" size="sm" />
      </Button>
    </li>
  );
};

PromoCode.propTypes = {
  /** Code to represent with this component */
  code: PropTypes.string.isRequired
};

export default PromoCode;
export { PromoCode };
